/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";

import { get_mesas } from "../services/mesas.services";

const useTables = () => {
  const [dataTable, setDataTable] = React.useState({
    data: [],
    load: false,
    err: false,
  });

  React.useEffect(() => {
    async function fetchData() {
      try {
        setDataTable({ load: true, data: [], err: false });
        const response = await get_mesas();
        const newResponse = response.map((el) => ({
          label: el.codigo_mesa,
          value: el.id_mesa,
        }));

        setDataTable({
          load: false,
          data: newResponse,
          err: false,
        });
      } catch (err) {
        setDataTable({ load: false, data: [], err: true });
      }
    }

    fetchData();

    return null;
  }, []);

  return {
    dataTable,
  };
};

export default useTables;
