import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React from "react";

import FormTicketBenefit from "./form.ticketBenefit";
import { useAppContext } from "./state.ticketBenefit";

const TicketBenefitModal = () => {
  const state = useAppContext();

  const formRef = React.createRef();

  const textButton = state.defaultValues.id ? "Actualizar" : "Guardar";
  const titleModal = state.defaultValues.id ? "Editar" : "Agregar";

  const handleExternalButtonClick = () => {
    if (formRef.current) {
      formRef.current.requestSubmit();
      formRef.current.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
      });
    }
  };

  return (
    <Dialog
      open={state.openModal}
      keepMounted
      onClose={() => state.handleModal(false)}
      fullWidth
      maxWidth={"sm"}
      aria-describedby="dialog-benefit"
    >
      <DialogTitle>
        {titleModal} ticket del beneficio
        <IconButton
          aria-label="close"
          onClick={() => state.handleModal(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <br />

        <FormTicketBenefit
          ref={formRef}
          action={state.handleAction}
          defaultValues={state.defaultValues}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="inherit"
          variant="text"
          onClick={() => {
            state.handleModal(false);
          }}
        >
          Salir
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleExternalButtonClick}
        >
          {textButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default TicketBenefitModal;
