import {
  Add,
  ArrowDropDown,
  ArrowDropUp,
  Delete,
  Check,
} from "@mui/icons-material";
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import InputDefault from "../../atomic/atoms/input.atoms";
import {
  add_group,
  delete_group,
  modify_group,
} from "../../services/reservas.services";

const TableGroupReserva = (props) => {
  const { loading, data, onAddNew, onDelete, onChange, onChangeText } = props;

  return (
    <Paper variant="outlined">
      <Table aria-label="simple table" size="small">
        <TableBody>
          {data.map((row, idx) => (
            <TableRow
              key={idx}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                style={{
                  width: "100%",
                  padding: "2px 8px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <IconButton
                    variant="contained"
                    color="error"
                    size="small"
                    style={{ marginRight: 10 }}
                    onClick={() => onDelete(row.id_rservagrupa)}
                    disabled={loading}
                  >
                    <Delete />
                  </IconButton>
                  <InputDefault
                    onChange={(e) => onChangeText(e, row.id_rservagrupa)}
                    value={row.name_rservagrupa}
                    type="text"
                    disabled={loading}
                  />
                </div>
              </TableCell>
              <TableCell style={{ padding: "2px 8px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => onChange(row.id_rservagrupa)}
                  disabled={loading}
                >
                  <Check />
                </Button>
              </TableCell>
            </TableRow>
          ))}

          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell colSpan={2} style={{ textAlign: "center" }}>
              <Button size="medium" onClick={onAddNew}>
                Nuevo <Add />
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

const GroupReserva = ({ data, setData, value, onChange }) => {
  const [activeData, setActiveData] = useState(false);

  const [loading, setLoading] = useState(false);

  const onAddNew = async () => {
    const lastIdisEmpty = data[data.length - 1]?.id_rservagrupa === "";

    if (lastIdisEmpty) return;

    try {
      setLoading(true);
      const response = await add_group();
      setLoading(false);

      setData([
        ...data,
        {
          id_rservagrupa: response?.id,
          name_rservagrupa: "",
        },
      ]);
    } catch (err) {}
  };

  const onDelete = async (id) => {
    try {
      setLoading(true);
      await delete_group(id);
      setLoading(false);

      const newData = data.filter((item) => item?.id_rservagrupa !== id);
      setData(newData);
    } catch (err) {}
  };

  const onChangeText = async (e, id) => {
    try {
      const newData = data.map((item) => {
        if (item.id_rservagrupa === id) {
          return {
            ...item,
            name_rservagrupa: e.target.value,
          };
        }

        return item;
      });

      setData(newData);

      await modify_group({
        id_rservagrupa: id,
        name_rservagrupa: e.target.value,
      });
    } catch (err) {}
  };

  const onSelected = (id) => {
    onChange(id);
    setActiveData(false);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={11} sm={11} lg={11} onClick={() => setActiveData(true)}>
        <InputDefault
          disabled={loading}
          readOnly
          value={value}
          type="text"
          title="Segmentación"
        />
      </Grid>
      <Grid item xs={1} sm={1} lg={1}>
        <IconButton
          disabled={loading}
          size="small"
          onClick={() => setActiveData(!activeData)}
        >
          {!activeData ? <ArrowDropDown /> : <ArrowDropUp />}
        </IconButton>
      </Grid>

      {activeData && (
        <Grid
          item
          xs={12}
          sm={12}
          lg={12}
          style={{
            marginTop: "-5px",
          }}
        >
          <TableGroupReserva
            onAddNew={onAddNew}
            onDelete={onDelete}
            onChange={onSelected}
            onChangeText={onChangeText}
            data={data}
            loading={loading}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default GroupReserva;
