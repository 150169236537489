import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import ContainerInput from "../../components/ContainerInput";
import Template from "../../components/Template";

import { getUser, checkToken } from "../../services/auth.services";

const Report = (props) => {
  let history = useHistory();

  useEffect(() => {
    if (!checkToken()) {
      history.push("/");
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Template title="Reporte" style={{ position: "relative" }}>
      <ContainerInput style={{ padding: 0 }}>
        <iframe
          title={getUser().empresa.nom_emp}
          style={{
            width: "100%",
            height: "80vh",
            display: "block",
            border: "none",
            borderRadius: 14,
          }}
          src="https://app.powerbi.com/view?r=eyJrIjoiMzMxZjgxNDctYWQyNC00NGM5LWI5NzctNmI0ZmQwZjUzZGJiIiwidCI6IjdiM2Q4NzNkLWI5ODYtNDgxMy1hOGFiLTg4NzhjNzY5YWRhNiJ9"
          frameBorder="0"
          allowFullScreen={true}
        ></iframe>
      </ContainerInput>
    </Template>
  );
};

export default Report;
