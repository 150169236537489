/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { useHistory, useParams } from "react-router-dom";

import { get_carta } from "../../../services/carta.services";

import {
  Breadcrumbs,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

import { ArrowBack } from "@mui/icons-material";

import ListProducts from "./ListProducts";

import Template from "../../../components/Template/TemplateCarta";

// import { addProduct } from "../../../redux/carta/actions";

import { useDispatch, useSelector } from "react-redux";
import { actionTypes } from "../../../redux/carta/actions";

import useEmpresa from "../../../hooks/useEmpresa";

import { formatMoney } from "../../../functions/formatMoney";
import "./product.style.scss";

const ItemRow = (props) => {
  // let { idx, el, handleAddProduct, productSession } = props;
  // let isActive = productSession.some((el2) => el2.id_plato === el.id_plato);

  let { idx, el, dataEmpresa } = props;

  return (
    <TableRow
      key={idx}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell align="left">
        <Typography
          variant="overline"
          component="h6"
          style={{
            color: dataEmpresa.color_secondary,
            fontWeight: "600",
            lineHeight: "inherit",
          }}
        >
          {el.nombre_plato} {el.totAmount}
        </Typography>
        <br />

        {el.descripcion_plato ? (
          <>
            <Typography variant="inherit" component="p">
              {el.descripcion_plato}
            </Typography>
            <br />
          </>
        ) : null}

        <strong style={{ fontSize: 15 }}>{formatMoney(el.precio_plato)}</strong>
      </TableCell>

      {/* <TableCell align="left">
        <IconButton
          color="secondary"
          // style={{ background: "green", color: "white" }}
          onClick={() => handleAddProduct(el)}
          disabled={isActive}
        >
          <AddShoppingCart />
        </IconButton>
      </TableCell> */}
    </TableRow>
  );
};

const Products = () => {
  let history = useHistory();
  let { empresa, id } = useParams();

  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.reducer_carta);

  const dataEmpresa = useEmpresa(empresa, "alias");

  const [dataProducts, setDataProducts] = useState({
    load: false,
    err: false,
    data: [],
  });

  const onAddProduct = (row, count) => {
    dispatch({
      type: actionTypes.addProduct,
      payload: { row, count },
    });
  };

  const [category, setCategory] = useState("");

  const handleBack = () => {
    history.goBack(-1);
  };

  const handleAddProduct = (product) => {
    onAddProduct(product, 1);
  };

  useEffect(() => {
    const handleGetCarta = async () => {
      setDataProducts({ load: true, err: false, data: [] });
      try {
        const response = await get_carta(dataEmpresa.data.id_empsede);

        const newResponse = response.find(
          (el) => el.id_categoriapla === parseInt(id)
        );

        setCategory(newResponse);

        setDataProducts({ load: false, err: false, data: newResponse.platos });
      } catch (err) {
        setDataProducts({ load: false, err: true, data: [] });
        console.log(err);
      }
    };

    if (dataEmpresa.data.id_empsede) {
      handleGetCarta();
    }
  }, [dataEmpresa]);

  return (
    <Template empresa={empresa} title="Carta">
      <ListProducts
        data={dataProducts}
        nav={
          <div className="navProduct">
            <Breadcrumbs
              aria-label="breadcrumb"
              style={{ padding: "20px 5px 20px 5px" }}
            >
              <IconButton onClick={() => handleBack()} size="small">
                <ArrowBack />
              </IconButton>

              <Typography color="text.primary">
                {category.nombre_categoriapla}
              </Typography>
            </Breadcrumbs>
          </div>
        }
        render={
          <Table aria-label="simple table">
            <TableBody>
              {dataProducts.data.map((el, idx) => (
                <ItemRow
                  key={idx}
                  el={el}
                  idx={idx}
                  productSession={products}
                  handleAddProduct={handleAddProduct}
                  dataEmpresa={dataEmpresa.data}
                />
              ))}
            </TableBody>
          </Table>
        }
      />
    </Template>
  );
};

export default Products;
