import React from "react";

import Template from "../../components/Template";
// import ContainerInput from "../../components/ContainerInput";

import { Box, Tab, Tabs } from "@mui/material";

import "./style.menu.scss";

import TabPanel from "../../components/TabPanel";
import MenuMain from "./menuMain.menu";
import MenuSecondary from "./menuSecondary.menu";

const Menu = () => {
  const [value, setValue] = React.useState(0);

  const handleChangeValue = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Template title="Menú">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChangeValue}
          aria-label="menu tabas"
        >
          <Tab label="Menú principal" />
          <Tab label="Menu Secundario" />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <MenuMain />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MenuSecondary />
      </TabPanel>
    </Template>
  );
};

export default Menu;
