// const Excel = require('exceljs');

import { createContext, useContext, useEffect, useState } from "react";

import {
  getHistoryClient,
  getHistoryClientById,
  searchHistoryClient,
} from "../../services/historialCliente.services";

import "moment/locale/es";
import dayjs from "dayjs";

// import { useDebounce } from "../../hooks/useDebounce";

const AppContext = createContext({
  /* Values */
  data: [],
  dataMenuScan: [],
  dataPedidos: [],
  dataReservas: [],
  idSelected: "",
  isLoadData: false,
  isLoadDialog: false,
  dataExcel: [],
  openDataClient: false,
  /* Methods */
  search: (value) => {},
  onChangeOpenDataClient: () => {},
  onIdSelected: () => {},
});

export default function ClientHistoryState({ children }) {
  const [data, setData] = useState([]);
  const [dataMenuScan, setDataMenuScan] = useState([]);
  const [dataPedidos, setDataPedidos] = useState([]);
  const [dataReservas, setDataReservas] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [openDataClient, setOpenDataClient] = useState(false);
  const [idSelected, setIdSelected] = useState("");

  const dataSet = data;

  function onIdSelected(id) {
    setIdSelected(id);
  }

  async function onChangeOpenDataClient() {
    setOpenDataClient(!openDataClient);
  }

  async function handleSearch(params) {
    setLoading(true);
    const response = await getHistoryClient();
    setLoading(false);

    let filterData = response;

    if (params?.name) {
      filterData = filterData.filter((el) => {
        if (!el?.fullname_cliente) {
          return false;
        }

        return el.fullname_cliente
          .toLowerCase()
          .includes(params?.name.toLowerCase());
      });
    }

    if (params?.clasification) {
      filterData = filterData.filter((el) => {
        if (!el?.clasificacion_cliente) {
          return false;
        }

        return (
          el.clasificacion_cliente.toLowerCase() ===
          params?.clasification.toLowerCase()
        );
      });
    }

    if (params.dateRange.length > 0) {
      filterData = filterData.filter((el) => {
        const date = dayjs(el.last_visite).utc();
        return (
          date.isAfter(dayjs(params.dateRange[0]).utc()) &&
          date.isBefore(dayjs(params.dateRange[1]).utc())
        );
      });
    }

    if (params?.group) {
      filterData = filterData.filter((el) => {
        if (!el?.id_rservagrupa) {
          return false;
        }

        return Number(el.id_rservagrupa) === Number(params?.group);
      });
    }

    if (params?.pograma) {
      filterData = filterData.filter((el) => {
        if (!el?.pograma_reserva) {
          return false;
        }

        return el.pograma_reserva.toLowerCase() === params?.pograma.toLowerCase();
      });
    }

    setData(filterData);
  }

  async function obtain() {
    setLoading(true);
    const response = await getHistoryClient();
    setData(response || []);
    setLoading(false);
  }

  async function obtainHistoryClient() {
    setLoadingDialog(true);
    const { menuScan, pedidos, reservas } = await getHistoryClientById(
      idSelected
    );

    setDataMenuScan(menuScan || []);
    setDataPedidos(pedidos || []);
    setDataReservas(reservas || []);

    setLoadingDialog(false);
  }

  useEffect(() => {
    if (openDataClient) {
      obtainHistoryClient();
    }
  }, [openDataClient]);

  useEffect(() => {
    obtain();
  }, []);

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <AppContext.Provider
      value={{
        data,
        dataMenuScan,
        dataPedidos,
        dataReservas,
        idSelected,
        isLoadData: loading,
        isLoadDialog: loadingDialog,
        dataExcel: dataSet,
        openDataClient,
        search: handleSearch,
        onChangeOpenDataClient,
        onIdSelected,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
