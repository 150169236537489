import axios from "axios";
import { config } from "../config";

import { axiosHeader } from "../functions/axiosHeader";

const { baseurl } = config;

export const get_mesas = () => {
  const url = `${baseurl}/api/mesas/get/`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const add_mesas = (params) => {
  const url = `${baseurl}/api/mesas/add/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const update_mesas = (params, id) => {
  const url = `${baseurl}/api/mesas/update/${id}/`;

  return axios
    .put(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const delete_mesas = (id) => {
  const url = `${baseurl}/api/mesas/delete/${id}/`;

  return axios
    .delete(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_mesas_by_id = (id) => {
  const url = `${baseurl}/api/mesas/get/${id}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_mesa_x_day = (params) => {
  const url = `${baseurl}/api/mesas/get_mesa_x_day/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_mesa_garzon_today = () => {
  const url = `${baseurl}/api/mesas/get_mesa_garzon/`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_mesa_garzon = () => {
  const url = `${baseurl}/api/mesas/get_garzon/`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const delete_mesa_garzon = (id) => {
  const url = `${baseurl}/api/mesas/delete_garzon/${id}`;

  return axios
    .delete(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const add_mesa_garzon = (params) => {
  const url = `${baseurl}/api/mesas/add_garzon/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};
