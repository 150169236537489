import * as React from "react";

import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import "moment/locale/es";
import { useAppContext } from "./state.clientHistory";

import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import dayjs from "dayjs";
import NotFound from "../../atomic/organisms/NotFound.organisms";
import CustomTable from "../../atomic/organisms/Table.organisms";
import { formatMoney } from "../../functions/formatMoney";
import { nameCamarero } from "../../functions/nameCamarero";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref || false} {...props} />;
});

const RowsCustomTable2 = ({ row }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow hover role="checkbox" tabIndex={-1}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell align={"left"} style={{ width: 200 }}>
          {row.codigo_pedido}
        </TableCell>
        <TableCell align={"left"}>{row.cantidad_mesa}</TableCell>
        <TableCell align={"left"}>#{row.codigo_mesa}</TableCell>
        <TableCell align={"left"}>{row.usu_nombre}</TableCell>
        <TableCell align={"left"}>
          {dayjs(row.fechareg_pedido).format("DD/MM/YYYY")}
        </TableCell>
        <TableCell align={"left"}>
          {formatMoney(row.pagoxconsumo_pedido)}
        </TableCell>
        <TableCell align={"left"}>
          {formatMoney(row.pagopropina_pedido)}
        </TableCell>
        <TableCell align={"left"}>
          {formatMoney(row.pagototal_pedido)}
        </TableCell>
        <TableCell align={"left"}>{row.tipodepago_pedido}</TableCell>
        <TableCell align={"left"}>{row.rowsProductos}</TableCell>
        <TableCell align={"left"}>{row.tipodescuento_pedido}</TableCell>
        <TableCell align={"left"}>
          {formatMoney(row.descuento_pedido)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={13}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 2 }}>
              <Typography
                variant="subtitle1"
                color={"black"}
                style={{ padding: 5 }}
              >
                Detalle de pedido
              </Typography>
              <Box sx={{ mt: 0, border: "1px silver solid" }}>
                <Table stickyHeader aria-label="sticky table" size={"small"}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" style={{ minWidth: 120 }}>
                        Nombre
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 80 }}>
                        Cantidad
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 80 }}>
                        Precio
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 60 }}>
                        Àrea del plato
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 100 }}>
                        Indicación
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 60 }}>
                        Estado
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 60 }}>
                        Tipo descuento
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 60 }}>
                        Descuento
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 60 }}>
                        Fecha de registro
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.productos.map((rowProduct, idx) => (
                      <TableRow key={idx}>
                        <TableCell scope="row">
                          {rowProduct.nombre_plato}
                        </TableCell>
                        <TableCell scope="row">
                          x{rowProduct.amount_plapedido}
                        </TableCell>
                        <TableCell scope="row">
                          {formatMoney(rowProduct.precio_plato)}
                        </TableCell>
                        <TableCell scope="row">
                          {rowProduct.nombre_area}
                        </TableCell>
                        <TableCell scope="row">
                          {rowProduct.text_plapedido}
                        </TableCell>
                        <TableCell scope="row">
                          {rowProduct.status_plapedido}
                        </TableCell>
                        <TableCell scope="row">
                          {rowProduct.tipodescuento_plapedido}
                        </TableCell>
                        <TableCell scope="row">
                          {formatMoney(rowProduct.descuento_plapedido)}
                        </TableCell>
                        <TableCell scope="row">
                          {dayjs(rowProduct.fechareg_plapedido).format(
                            "DD/MM/YYYY"
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const CustomTable2 = ({ rows }) => {
  const camarero = nameCamarero();

  if (rows.length === 0) {
    return <NotFound texto="No se encontró registros." />;
  }

  return (
    <>
      <TableContainer sx={{ maxHeight: 550 }}>
        <Table stickyHeader aria-label="sticky table" size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align={"left"} style={{ minWidth: 200 }}>
                Cód. de pedido
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 110 }}>
                # personas
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 110 }}>
                Cod. mesa
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 150 }}>
                {camarero}
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 130 }}>
                Fec. de pedido
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 130 }}>
                Pago consumo
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 120 }}>
                Pago propina
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 110 }}>
                Pago total
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 150 }}>
                Tipo de pago
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 140 }}>
                Cant. platos
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 140 }}>
                Tipo descuento
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 130 }}>
                Descuento
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, idx) => (
              <RowsCustomTable2 row={row} key={idx} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const TableScanMenu = ({ titleHead, rows }) => {
  const columnTable = [
    {
      id: "createdat_cartahistory",
      label: "Fecha de registro",
      minWidth: 130,
      align: "left",
    },
    {
      id: "pedido",
      label: "Creo un pedido",
      minWidth: 80,
      align: "left",
    },
  ];

  const newRows = rows.map((item) => {
    return {
      ...item,
      createdat_cartahistory: dayjs(item.createdat_cartahistory).format(
        "DD/MM/YYYY"
      ),
      pedido: item.pedido ? "Si" : "No",
    };
  });

  return (
    <>
      <Typography variant="subtitle1" color={"black"} style={{ padding: 5 }}>
        {titleHead ? titleHead : "Cantidad de veces que ha escaneado"}
      </Typography>
      <Box sx={{ mt: 0, border: "1px silver solid" }}>
        <CustomTable columns={columnTable} rows={newRows} pagination={false} />
      </Box>
      <Typography
        align="right"
        variant="subtitle1"
        color={"black"}
        style={{ padding: 5 }}
      >
        Total: {rows.length}
      </Typography>
    </>
  );
};

const TablePedidos = ({ titleHead, rows }) => {
  return (
    <>
      <Typography variant="subtitle1" color={"black"} style={{ padding: 5 }}>
        {titleHead ? titleHead : "Cantidad de pedidos"}
      </Typography>
      <Box sx={{ mt: 0, border: "1px silver solid" }}>
        <CustomTable2 rows={rows} />
      </Box>
      <Typography
        align="right"
        variant="subtitle1"
        color={"black"}
        style={{ padding: 5 }}
      >
        Total: {rows.length}
      </Typography>
    </>
  );
};

const TableReservas = ({ titleHead, rows }) => {
  const columnTable = [
    {
      id: "cliente_reserva",
      label: "Nombre de la reserva",
      minWidth: 200,
      align: "left",
    },
    {
      id: "estado_reserva",
      label: "Estado",
      minWidth: 50,
      align: "left",
    },
    {
      id: "pograma_reserva",
      label: "Programa",
      minWidth: 80,
      align: "left",
    },
    {
      id: "fechareserva_reserva",
      label: "Fecha reserva",
      minWidth: 80,
      align: "left",
    },

    {
      id: "correo_reserva",
      label: "Correo",
      minWidth: 80,
      align: "left",
    },
    {
      id: "telefono_reserva",
      label: "Teléfono",
      minWidth: 80,
      align: "left",
    },
    {
      id: "pax_reserva",
      label: "PAX",
      minWidth: 80,
      align: "left",
    },
  ];

  const newRows = rows.map((item) => {
    return {
      ...item,
      fechareserva_reserva: dayjs(item.fechareserva_reserva).format(
        "DD/MM/YYYY"
      ),
      cliente_reserva: `${item.nombre_reserva} ${item.apellido_paterno_reserva} ${item.apellido_materno_reserva}`,
    };
  });

  return (
    <>
      <Typography variant="subtitle1" color={"black"} style={{ padding: 5 }}>
        {titleHead ? titleHead : "Cantidad de reservas"}
      </Typography>
      <Box sx={{ mt: 0, border: "1px silver solid" }}>
        <CustomTable columns={columnTable} rows={newRows} pagination={false} />
      </Box>
      <Typography
        align="right"
        variant="subtitle1"
        color={"black"}
        style={{ padding: 5 }}
      >
        Total: {rows.length}
      </Typography>
    </>
  );
};

const DialogViewClientData = () => {
  const state = useAppContext();

  if (state.isLoadDialog) {
    return (
      <Dialog
        open={true}
        TransitionComponent={Transition}
        fullWidth
        onClose={state.onChangeOpenDataClient}
        maxWidth={"md"}
        keepMounted
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <div style={{ textAlign: "center", padding: "20px" }}>
            <CircularProgress color="inherit" /> <br />
            <Typography
              component="h5"
              variant="h5"
              style={{ textAlign: "center" }}
            >
              Cargando...
            </Typography>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog
      open={state.openDataClient}
      TransitionComponent={Transition}
      fullWidth
      onClose={state.onChangeOpenDataClient}
      maxWidth={"md"}
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title"></DialogTitle>
      <DialogContent>
        <TableScanMenu rows={state.dataMenuScan} />
        <br />
        <TablePedidos rows={state.dataPedidos} />
        <br />
        <TableReservas rows={state.dataReservas} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={state.onChangeOpenDataClient}
          variant="text"
          color="inherit"
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogViewClientData;
