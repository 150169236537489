import TemplateLogin from "../../components/TemplateLogin";

import MyReservationState, { useAppContext } from "./state.myreservations";

import Logo from "../../assets/images/logo.png";

import TableMyReservations from "./components/TableMyReservations";
import "./style.myreservations.scss";

const MyReservation = () => {
  const state = useAppContext();

  return (
    <MyReservationState>
      <TemplateLogin
        title="Mis reservas"
        styleBody={{
          display: "block",
          height: "auto",
          paddingTop: "30px",
          paddingBottom: "30px",
        }}
      >
        <div className="MyReservation">
          <img
            className="Logo_Login"
            src={Logo}
            alt="Logo"
            style={{ marginBottom: 20, width: 150 }}
          />
          <div className="MyReservation__title">Mis reservas</div>
          <div className="MyReservation__table">
            <TableMyReservations />
          </div>
        </div>
      </TemplateLogin>
    </MyReservationState>
  );
};

export default MyReservation;
