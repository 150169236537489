/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Template from "../../components/Template";
import TableHistorialCliente from "./Table";
import Input from "../../components/Forms/Input";
import ContainerInput from "../../components/ContainerInput";

import { checkToken } from "../../services/auth.services";

import { useSelector, useDispatch } from "react-redux";

import {
  Divider,
  Avatar,
  Button,
  Grid,
  Typography,
  InputAdornment,
  TextField,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";

import { Autocomplete } from "@mui/lab";

import { Search, Add, CalendarToday, ListAlt } from "@mui/icons-material/";

import { useForm, Controller } from "react-hook-form";

import { get_historialCliente } from "../../services/historialCliente.services";

import { actionTypes } from "../../redux/historialCliente/actions/index";
import DialogViewData from "./DialogViewData";

const HistorialCliente = () => {
  let history = useHistory();

  let initialValues = {
    id_mesa: "",
  };

  const { control, reset, watch, setValue, handleSubmit } = useForm({
    defaultValues: initialValues,
  });

  const { clientes, loading, error } = useSelector(
    (state) => state.reducer_historialCLiente
  );

  const dispatch = useDispatch();

  const onSearch = async (data) => {

    // try {
    //   let response = await search_reserva(searchReserva);
    // } catch (err) {
    //   console.log(err);
    // }
  };

  const onGet = (data) =>
    dispatch({ type: actionTypes.updateCliente, payload: data });

  const onLoading = (data) =>
    dispatch({ type: actionTypes.updateLoading, payload: data });

  useEffect(() => {
    const obtainHistorialCliente = async () => {
      onLoading(true);

      try {
        let response = await get_historialCliente();
        onGet(response);

        onLoading(false);
      } catch (err) {
        onLoading(false);
        console.log(err);
      }
    };

    obtainHistorialCliente();

    return () => {};
  }, []);

  //CHECK TOKEN
  useEffect(() => {
    if (!checkToken()) {
      history.push("/");
      return null;
    }
  }, []);

  return (
    <Template title="Historial clientes">
      {/* <form onSubmit={handleSubmit(onSearch)} autoComplete="off">
        <Grid container spacing={1}>
          <Grid item xs={11} sm={11} lg={11}>
            <Controller
              name="cliente"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Input
                  required
                  value={value}
                  onChange={onChange}
                  type="text"
                  title="Cliente"
                />
              )}
            />
          </Grid>
          <Grid item xs={1} sm={1} lg={1} style={{ textAlign: "center" }}>
            <IconButton
              fullWidth
              type="submit"
              aria-label="search"
              size="large"
              style={{ marginLeft: "5px" }}
            >
              <Search fontSize="inherit" />
            </IconButton>
          </Grid>
        </Grid>
      </form> */}
      <ContainerInput
        style={{
          marginTop: 20,
          marginBottom: 20,
          padding: "35px 10px",
          textAlign: "center",
        }}
      >
        <TableHistorialCliente />
      </ContainerInput>
      <DialogViewData />
    </Template>
  );
};

export default HistorialCliente;
