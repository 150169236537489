import axios from "axios";
import { config } from "../config";
import { axiosHeader } from "../functions/axiosHeader";

const { baseurl } = config;

const base_url = `${baseurl}/api/kommo`;

export const get_leads_won = (getUserId) => {
  const url = `${base_url}/get_won_leads/${getUserId}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};
