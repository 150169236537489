import axios from "axios";
import { config } from "../config";
import { axiosHeader } from "../functions/axiosHeader";

const { baseurl } = config;

const base_url = `${baseurl}/api/agotadoproducto`;

export const add_agotadoproducto = (params) => {
  const url = `${base_url}/add/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const addMassive_agotadoproducto = (params) => {
  const url = `${base_url}/addMassive/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const edit_agotadoproducto = (params) => {
  const url = `${base_url}/edit/`;

  return axios
    .patch(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_agotadoproducto = () => {
  const url = `${base_url}/get/`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const delete_agotadoproducto = (id) => {
  const url = `${base_url}/delete/${id}`;

  return axios
    .delete(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};
