import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import TablePlatos from "./TablePlatos";

import { IconButton } from "@mui/material";

import { useSelector, useDispatch } from "react-redux";

import { actionTypes } from "../../../../../redux/menu/actions";

import { add_categoria_plato } from "../../../../../services/categoria_plato.services";

import LoadingPage from "../../../../../components/LoadingPage";

import toast from "react-hot-toast";

import "./style.products.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Platos = () => {
  const dispatch = useDispatch();
  const [loadPage, setLoadPage] = React.useState(false);

  const {
    dataSelected_categoria,
    openPlatos_categoria,
    paramSelected_plato,
    dataMenu,
  } = useSelector((state) => state.reducer_menu);

  const onGetMenu = (data) =>
    dispatch({
      type: actionTypes.getDataMenu,
      payload: data,
    });

  const onOpen = (data) =>
    dispatch({
      type: actionTypes.openModalPlatos_categoria,
      payload: data,
    });

  const onSetDataSelected = (data) =>
    dispatch({
      type: actionTypes.setDataSelected_categoria,
      payload: data,
    });

  const onSetDataSelectedPlato = (data) =>
    dispatch({
      type: actionTypes.setParamSelected_plato,
      payload: data,
    });

  const handleModal = (value) => {
    onOpen(value);

    if (!value) {
      onSetDataSelectedPlato([]);
      onSetDataSelected({
        ...dataSelected_categoria,
        id_categoriapla: "",
        nombre_categoriapla: "",
      });
    }
  };

  const handleAddPlato = async () => {
    try {
      setLoadPage(true);

      let json = {
        id_categoria: dataSelected_categoria.id_categoriapla,
        platos: paramSelected_plato,
      };

      onGetMenu({
        ...dataMenu,
        load: true,
        data: [],
        err: false,
      });

      const response = await add_categoria_plato(json);

      toast.success("Se agrego correctamente.");

      onGetMenu({
        ...dataMenu,
        load: false,
        data: response,
        err: false,
      });

      setLoadPage(false);
      handleModal(false);
    } catch (err) {
      console.log(err);

      onGetMenu({
        ...dataMenu,
        load: false,
        data: [],
        err: true,
      });

      setLoadPage(false);
      handleModal(false);
    }
  };

  return (
    <Dialog
      fullScreen
      open={openPlatos_categoria}
      onClose={() => handleModal(false)}
      TransitionComponent={Transition}
    >
      <LoadingPage open={loadPage} setOpen={setLoadPage} />
      <AppBar sx={{ position: "relative" }} className="AppHeader">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => handleModal(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>

          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {dataSelected_categoria.nombre_categoriapla}
          </Typography>
          <Button
            autoFocus
            variant="outlined"
            color="inherit"
            onClick={() => handleAddPlato()}
          >
            Agregar
          </Button>
        </Toolbar>
      </AppBar>
      <div style={{ padding: 10 }}>
        <TablePlatos />
      </div>
    </Dialog>
  );
};

export default Platos;
