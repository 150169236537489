import React from "react";

import { Alert, Tab, Tabs } from "@mui/material";
import ConsumptionSearch from "./search.consumption";
import ConsumptionState, {
  TYPE_SEARCH,
  useAppContext,
} from "./state.consumption";

import TableView1 from "./table.consumption";
import TableViewFour from "./tableViewFour.consumption";
import TableViewThree from "./tableViewThree.consumption";
import TableViewTwo from "./tableViewTwo.consumption";

const Views = () => {
  const state = useAppContext();
  const value = state.tabValue;

  let Table = null;

  if (value === TYPE_SEARCH.HISTORY) {
    Table = <TableView1 />;
  }

  if (value === TYPE_SEARCH.PROJECTION) {
    Table = <TableViewTwo />;
  }

  if (value === TYPE_SEARCH.PROJECTION_WEEK) {
    Table = <TableViewFour />;
  }

  if (value === TYPE_SEARCH.UTILITY) {
    Table = <TableViewThree />;
  }

  return (
    <>
      <ConsumptionSearch value={state.tabValue} />
      {(value === 1 || value === 2) && (
        <Alert severity="info" sx={{ marginTop: 2, marginBottom: 2 }}>
          <strong>Nota:</strong> Para la proyección se toma en cuenta el consumo
          promedio de los últimos 2 meses (8 semanas).
        </Alert>
      )}
      {Table}
    </>
  );
};

const TabComponent = () => {
  const state = useAppContext();

  const handleChange = (event, newValue) => {
    state.changeTab(newValue);
  };

  return (
    <Tabs value={state.tabValue} onChange={handleChange}>
      {state.tabs.map((item) => (
        <Tab key={item.value} disabled={state.isLoadData} label={item.label} />
      ))}
    </Tabs>
  );
};

const ConsumptionComponent = () => {
  return (
    <ConsumptionState>
      <TabComponent />
      <br />
      <Views />
    </ConsumptionState>
  );
};

export default ConsumptionComponent;
