import React from "react";

import { People } from "@mui/icons-material";
import {
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";

import NotFound from "../../../../atomic/organisms/NotFound.organisms";
import "../style.mesas.scss";

//FALTA GUARDAR EL CAMBIO DE LA MESA

const TableMesas = (props) => {
  let { selectedTable, handleSetSelectedTable } = props;

  let { data, err, load } = props.data;

  if (load) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress color="inherit" /> <br />
        <Typography component="h5" variant="h5" style={{ textAlign: "center" }}>
          Cargando...
        </Typography>
      </div>
    );
  }

  if (err) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <NotFound title="¡Ocurrió un problema!" />
      </div>
    );
  }

  if (data.length === 0 && load === false) {
    return <NotFound texto="No se encontró registros." />;
  }

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={1}
      style={{ marginTop: 10 }}
    >
      {data.map((el, idx) => (
        <Grid key={idx} item xs={12} sm={4} md={4} lg={4} zeroMinWidth>
          <Paper
            variant="outlined"
            className={`mesaIcon ${el.ocupado_mesa ? "mesaDisabled" : ""}  ${
              selectedTable.id_mesa === el.id_mesa && "mesaSelected"
            } `}
            onClick={() => handleSetSelectedTable(el)}
          >
            {el.ocupado_mesa === 1 ? (
              <div className="mesaOcupada">Ocupado</div>
            ) : (
              <div className="mesaDisponible">Disponible</div>
            )}
            <Typography component={"h6"} variant={"h6"}>
              {!el.codigo_mesa ? "Directo" : `Mesa #${el.codigo_mesa}`}
            </Typography>
            <Divider />
            <div className="persons">
              <div>
                <People style={{ verticalAlign: "bottom", fontSize: "20px" }} />{" "}
                {el.cantidad_mesa} max.
              </div>
            </div>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default TableMesas;
