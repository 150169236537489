import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import * as React from "react";

import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import {
  Alert,
  Collapse,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import Input from "../../components/Forms/Input";

import { Controller, useForm } from "react-hook-form";

import {
  Add,
  Close,
  Padding,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";

import toast from "react-hot-toast";

import LoadingPage from "../../components/LoadingPage";
import { nameCamarero } from "../../functions/nameCamarero";
import {
  get_data_company_all,
  get_subsidaries,
} from "../../services/empresa.services";
import {
  add_user,
  get_typeuser,
  update_user,
} from "../../services/user.services";
import { getUser } from "../../services/auth.services";
import dayjs from "dayjs";

const ErrorMsg = ({ wrong, setWrong, message }) => {
  return (
    <Collapse in={wrong}>
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setWrong(false);
            }}
          >
            <Close fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
        severity="error"
      >
        {message}
      </Alert>
    </Collapse>
  );
};

const DialogCrudUser = ({
  open,
  setOpen,
  data,
  setData,
  handleGetUsuarios,
}) => {
  const initialValues = {
    usu_id: "",
    tipousu_id: "",
    usu_nombre: "",
    usu_email: "",
    usu_username: "",
    usu_password: "",
    usu_comission_value: 0,
    type_user: false,
    usu_ranges_commision: [],
    id_emp: "",
    id_empsede: "",
  };

  const { control, reset, handleSubmit, watch } = useForm({
    defaultValues: initialValues,
  });

  const [wrong, setWrong] = React.useState({ open: false, message: "" });
  const [dataTypeUser, setDataTypeUser] = React.useState([]);
  const [openLoad, setOpenLoad] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [company, setCompany] = React.useState([]);
  const [subsidiary, setSubsidiary] = React.useState([]);

  const INIT_VALUES_COMISSION = [
    {
      min: 1,
      max: 30,
      value: 1500,
    },
    {
      min: 31,
      max: 50,
      value: 2000,
    },
    {
      min: 51,
      max: 100000,
      value: 5000,
    },
  ];

  const [rangoComission, setRangoComission] = React.useState(
    INIT_VALUES_COMISSION
  );

  const [rangoComissionDate, setRangoComissionDate] = React.useState([]);

  const IS_DH_RCS = getUser().empresa?.id_emp === 4;

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddUser = async (data) => {
    try {
      setOpenLoad(true);
      let response;

      data.usu_ranges_commision = rangoComission || [];
      data.usu_ranges_commision_date = rangoComissionDate || [];

      if (data.usu_id) {
        response = await update_user(data, data.usu_id);
        if (response.affectedRows === 1) {
          toast.success("Usuario modificado correctamente");
          setOpen(false);
          reset(initialValues);
        } else {
          toast.error("No se pudo modificar el usuario");
          setOpen(false);
          reset(initialValues);
        }
      } else {
        response = await add_user(data);

        if (response.affectedRows === 1) {
          toast.success("Usuario creado correctamente");
          setOpen(false);
          reset(initialValues);
        } else if (response.affectedRows === 2) {
          toast.error("El nombre de usuario no se encuentra disponible");
        } else {
          toast.error("No se pudo crear el usuario");
          setOpen(false);
          reset(initialValues);
        }
      }

      setOpenLoad(false);
      handleGetUsuarios();
    } catch (err) {
      setOpenLoad(false);

      console.log(err.message);
      toast.error("Hubo un error al crear el usuario");
      setOpen(false);
    }
  };

  const fetchTypeUser = async () => {
    try {
      const response = await get_typeuser();
      setDataTypeUser(
        response.map((el) => ({
          label: el.tipousu_id === 1 ? nameCamarero() : el.tipousu_nombre,
          value: el.tipousu_id,
        }))
      );
    } catch (err) {
      console.log(err.message);
    }
  };

  const fetchCompany = async () => {
    try {
      const response = await get_data_company_all();

      setCompany(
        response.map((el) => ({
          label: el.nom_emp,
          value: el.id_emp,
        }))
      );
    } catch (err) {
      console.log(err.message);
    }
  };

  const fetchSubsidiary = async (id_emp) => {
    try {
      const response = await get_subsidaries(id_emp);

      setSubsidiary(
        response.map((el) => ({
          label: el.nom_empsede,
          value: el.id_empsede,
        }))
      );
    } catch (err) {
      console.log(err.message);
    }
  };

  const onDeleteRowComission = (index) => {
    if (rangoComission.length === 1) {
      return;
    }

    setRangoComission((prev) => {
      const copy = [...prev];
      copy.splice(index, 1);
      return copy;
    });
  };

  const onDeleteRowComissionDate = (index) => {
    if (rangoComissionDate.length === 1) {
      return;
    }

    setRangoComissionDate((prev) => {
      const copy = [...prev];
      copy.splice(index, 1);
      return copy;
    });
  };

  React.useEffect(() => {
    if (open && watch("id_emp") > 0) {
      fetchSubsidiary(watch("id_emp"));
    }
  }, [open, watch("id_emp")]);

  React.useEffect(() => {
    if (open) {
      if (data.usu_id === "") {
        reset(initialValues);
      }

      fetchTypeUser();
      fetchCompany();

      reset({
        ...initialValues,
        ...data,
      });

      if (data.id_emp > 0) {
        fetchSubsidiary(data.id_emp);
      }

      setRangoComission(
        data?.usu_ranges_commision && data?.usu_ranges_commision.length > 0
          ? data?.usu_ranges_commision.map((el) => ({
              min: el.comission_min,
              max: el.comission_max,
              value: el.comission_value,
            }))
          : INIT_VALUES_COMISSION
      );

      setRangoComissionDate(
        data?.usu_ranges_commision_date &&
          data?.usu_ranges_commision_date.length > 0 &&
          data?.usu_ranges_commision_date.map((el) => ({
            minDate: dayjs.utc(el.mindate_comissiondate).format("YYYY-MM-DD"),
            maxDate: dayjs.utc(el.maxdate_comissiondate).format("YYYY-MM-DD"),
            paxValue: el.paxvalue_comissiondate,
            value: el.value_comissiondate,
          }))
      );
    } else {
      reset(initialValues);
      setRangoComission([]);
      setRangoComissionDate([]);
      setData({});
    }
  }, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"sm"}>
        <LoadingPage open={openLoad} />
        <form onSubmit={handleSubmit(handleAddUser)} autoComplete="off">
          <DialogTitle>
            {data.usu_id ? "Modificar usuario" : "Nuevo usuario"}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <br />
            <ErrorMsg
              wrong={wrong.open}
              setWrong={(el) => setWrong({ ...wrong, open: el })}
              message={wrong.message}
            />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} lg={12}>
                <Controller
                  name="usu_nombre"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      required
                      value={value}
                      onChange={onChange}
                      type="text"
                      title="Nombres"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Controller
                  name="usu_email"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      required
                      value={value}
                      onChange={onChange}
                      type="email"
                      title="Correo electrónico"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Controller
                  name="usu_username"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      required
                      value={value}
                      onChange={onChange}
                      type="text"
                      title="Usuario"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={12} style={{ display: "flex" }}>
                <Controller
                  name="usu_password"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      required
                      value={value}
                      onChange={onChange}
                      type={showPassword ? "text" : "password"}
                      title="Contraseña"
                    />
                  )}
                />
                <IconButton
                  type="button"
                  title="Mostrar contraseña"
                  style={{ marginLeft: "6px" }}
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  {showPassword ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff color="disabled" />
                  )}
                </IconButton>
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Controller
                  name="tipousu_id"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      select
                      required
                      value={value}
                      onChange={onChange}
                      type="select"
                      options={dataTypeUser}
                      title="Tipo"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <Controller
                  name="id_emp"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      select
                      required
                      value={value}
                      onChange={onChange}
                      type="select"
                      options={company}
                      title="Empresa"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <Controller
                  name="id_empsede"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      select
                      required
                      value={value}
                      onChange={onChange}
                      type="select"
                      options={subsidiary}
                      title="Sucursal"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              {Number(watch("tipousu_id")) === 14 && (
                <Grid item xs={12} sm={12} lg={12}>
                  <Table
                    aria-label="sticky table"
                    size={"small"}
                    className="w-full border border-gray-200"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={4} className="!text-center">
                          Comisión
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rangoComission.map((el, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>
                              <Input
                                required
                                fullWidth
                                value={el.min}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setRangoComission((prev) => {
                                    const copy = [...prev];
                                    copy[index].min = value;
                                    return copy;
                                  });
                                }}
                                type="number"
                                title={`Minimo`}
                              />
                            </TableCell>
                            <TableCell>
                              <Input
                                required
                                fullWidth
                                value={el.max}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setRangoComission((prev) => {
                                    const copy = [...prev];
                                    copy[index].max = value;
                                    return copy;
                                  });
                                }}
                                type="number"
                                title={`Maximo`}
                              />
                            </TableCell>
                            <TableCell>
                              <Input
                                required
                                fullWidth
                                value={el.value}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setRangoComission((prev) => {
                                    const copy = [...prev];
                                    copy[index].value = value;
                                    return copy;
                                  });
                                }}
                                type="number"
                                title={`Valor`}
                              />
                            </TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() => onDeleteRowComission(index)}
                                disabled={rangoComission.length === 1}
                              >
                                <Close />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      <TableCell className="!text-center !px-2" colSpan={4}>
                        <Button
                          color="info"
                          variant="text"
                          className="w-full !bg-slate-100 hover:bg-slate-700 text-white"
                          fullWidth
                          onClick={() => {
                            setRangoComission((prev) => [
                              ...prev,
                              {
                                min: 0,
                                max: 0,
                                value: 0,
                              },
                            ]);
                          }}
                        >
                          <Add />
                          Agregar
                        </Button>
                      </TableCell>
                    </TableBody>
                  </Table>
                </Grid>
              )}

              {IS_DH_RCS && Number(watch("tipousu_id")) === 6 && (
                <Grid item xs={12} sm={12} lg={12}>
                  <Table
                    aria-label="sticky table"
                    size={"small"}
                    className="w-full border border-gray-200"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={5} className="!text-center !px-2">
                          Comisión por fecha
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rangoComissionDate.map((el, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell className="!px-2">
                              <Input
                                required
                                fullWidth
                                value={el.minDate}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setRangoComissionDate((prev) => {
                                    const copy = [...prev];
                                    copy[index].minDate = value;
                                    return copy;
                                  });
                                }}
                                type="date"
                                title={`Fecha minima`}
                              />
                            </TableCell>
                            <TableCell className="!px-2">
                              <Input
                                required
                                fullWidth
                                value={el.maxDate}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setRangoComissionDate((prev) => {
                                    const copy = [...prev];
                                    copy[index].maxDate = value;
                                    return copy;
                                  });
                                }}
                                type="date"
                                title={`Fecha maxima`}
                              />
                            </TableCell>
                            <TableCell className="!px-2">
                              <Input
                                required
                                fullWidth
                                value={el.paxValue}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setRangoComissionDate((prev) => {
                                    const copy = [...prev];
                                    copy[index].paxValue = value;
                                    return copy;
                                  });
                                }}
                                type="number"
                                title={`PAX`}
                              />
                            </TableCell>
                            <TableCell className="!px-2">
                              <Input
                                required
                                fullWidth
                                value={el.value}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setRangoComissionDate((prev) => {
                                    const copy = [...prev];
                                    copy[index].value = value;
                                    return copy;
                                  });
                                }}
                                type="number"
                                title={`Valor`}
                              />
                            </TableCell>
                            <TableCell className="!px-2  bg-slate-200">
                              <IconButton
                                onClick={() => onDeleteRowComissionDate(index)}
                                disabled={rangoComission.length === 1}
                              >
                                <Close />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      <TableCell className="!text-center !px-2" colSpan={5}>
                        <Button
                          color="info"
                          variant="text"
                          className="w-full !bg-slate-100 hover:bg-slate-700 text-white"
                          fullWidth
                          onClick={() => {
                            setRangoComissionDate((prev) => [
                              ...prev,
                              {
                                minDate: dayjs().format("YYYY-MM-DD"),
                                maxDate: dayjs().format("YYYY-MM-DD"),
                                value: 0,
                                paxValue: 0,
                              },
                            ]);
                          }}
                        >
                          <Add />
                          Agregar
                        </Button>
                      </TableCell>
                    </TableBody>
                  </Table>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="info"
              variant="text"
              onClick={() => {
                setOpen(false);
              }}
            >
              Salir
            </Button>
            <Button type="submit" color="success" variant="contained">
              Agregar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default DialogCrudUser;
