import { Home } from "@mui/icons-material";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Template from "../../components/Template";
import { findPlanningByCod } from "../../services/planning.services";
import ContainerInput from "../../components/ContainerInput";
import { Box, Tab, Tabs } from "@mui/material";
import GeneralsData from "./generalsData.planning";

const BreadCrumb = (props) => {
  const { nameCod } = props;

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
        <li className="inline-flex items-center">
          <Link
            to="/planning"
            className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-800"
          >
            <Home className="w-5 h-5 mr-1" aria-hidden="true" />
            Planificación
          </Link>
        </li>

        <li aria-current="page">
          <div className="flex items-center">
            <svg
              className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 9 4-4-4-4"
              />
            </svg>
            <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">
              {nameCod}
            </span>
          </div>
        </li>
      </ol>
    </nav>
  );
};

const PlanningPageCod = () => {
  const { cod } = useParams();

  const [dataPlanning, setDataPlanning] = React.useState([]);
  const [load, setLoad] = React.useState(false);

  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchDataPlanning = async () => {
    try {
      setLoad(true);
      const data = await findPlanningByCod(cod);
      // console.log(data);

      setLoad(false);

      setDataPlanning(data);
    } catch (error) {
      console.error("Error en fetchDataPlanning: ", error);
    }
  };

  useEffect(() => {
    fetchDataPlanning();
  }, []);

  return (
    <Template title={cod} style={{ position: "relative" }}>
      <div className="p-5">
        <div className="pb-5">
          <BreadCrumb nameCod={cod} />
        </div>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 md:col-span-12">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Datos generales" value={1} wrapped />
                <Tab label="Menú del evento" value={2} />
                <Tab label="Insumo fiesta" value={3} />
                <Tab label="Ceremonia" value={4} />
              </Tabs>
            </Box>
            <ContainerInput className="mt-4 p-4">
              {value === 1 && <GeneralsData data={dataPlanning} />}
              {value === 2 && <h1>Pending</h1>}
              {value === 3 && <h1>Pending</h1>}
              {value === 4 && <h1>Pending</h1>}
            </ContainerInput>
          </div>
        </div>
      </div>
    </Template>
  );
};

export default PlanningPageCod;
