import React, { useEffect, useState } from "react";

// import ContainerInput from "../../components/ContainerInput";

import { OpenInNew } from "@mui/icons-material";
import { Checkbox, IconButton } from "@mui/material";

import "../style.menu.scss";

import InputDefault from "../../../atomic/atoms/input.atoms";
import { getUrlFront } from "../../../functions/utilidades.function";

const LinkCarta = ({ dataEmpresa }) => {
  const url = getUrlFront();

  const [checked, setChecked] = useState(false);
  const [linkCarta, setLinkCarta] = useState(null);

  const handleChangeFreeLink = () => {
    const cartaUrl = `${url}/carta-secondary/${dataEmpresa.data.alias_empsede}${
      !checked ? "?user=free" : ""
    }`;
    setLinkCarta(cartaUrl);

    setChecked(!checked);
  };

  useEffect(() => {
    const cartaUrl = `${url}/carta-secondary/${dataEmpresa.data.alias_empsede}`;
    setLinkCarta(cartaUrl);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEmpresa]);

  return (
    <div style={{ display: "inline-flex", width: "55vh" }}>
      <div style={{ display: "inline-flex", alignItems: "center" }}>
        Libre
        <Checkbox value={checked} onChange={() => handleChangeFreeLink()} />
      </div>
      {dataEmpresa.load === false ? (
        <>
          <InputDefault
            title="Link carta"
            type="text"
            value={linkCarta}
            readOnly
          />
          <IconButton
            style={{ marginLeft: 5 }}
            color="warning"
            onClick={() => window.open(linkCarta, "_blank")}
          >
            <OpenInNew />
          </IconButton>
        </>
      ) : (
        false
      )}
    </div>
  );
};

export default LinkCarta;
