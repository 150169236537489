import { actionTypesPlato } from "../actions/index";

const initialState = {
  loading: false,
  error: false,
  dataPlato: { load: false, err: false, data: [] },
  openAddEdit_plato: false,
  openDelete: false,
  openProducts: false,
  dataSelected: {
    id_plato: "",
    nombre_plato: "",
    precio_plato: "",
    descripcion_plato: "",
  },
  paramSelected_product: [],
  paramDataPlatos: { data: [], load: false, err: false },
};

const reducerObject = (state, payload) => ({
  [actionTypesPlato.getDataPlato]: {
    ...state,
    dataPlato: payload,
  },
  [actionTypesPlato.openModalAddEdit_plato]: {
    ...state,
    openAddEdit_plato: payload,
  },
  [actionTypesPlato.openModalProducts]: {
    ...state,
    openProducts: payload,
  },
  [actionTypesPlato.openModalDelete]: {
    ...state,
    openDelete: payload,
  },
  [actionTypesPlato.setLoading]: {
    ...state,
    loading: payload,
  },
  [actionTypesPlato.setDataSelected]: {
    ...state,
    dataSelected: payload,
  },
  [actionTypesPlato.setParamSelected_product]: {
    ...state,
    paramSelected_product: payload,
  },
  [actionTypesPlato.setData_products]: {
    ...state,
    paramDataProducto: payload,
  },
});

export const reducer_plato = (state = initialState, action) => {
  if (reducerObject(state)[action.type]) {
    return reducerObject(state, action.payload)[action.type];
  } else {
    return state;
  }
};
