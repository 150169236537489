/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";

import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";

import HorizontalLinearStepper from "../../components/Stepper/Stepper";
import ModalQuotationCalendar from "./modal-calendar.quotation";
import ContractFormDH from "./ui/ContractForm";
import { useStoreQuotation } from "./useStoreQuotation";
import ViewAndDownloadContractFile from "./ui/ViewAndDownloadContractFile";
import ContractFormUploadContractFile from "./ui/ContractFormUploadContractFile";

const ModalQuotationFinish = (props) => {
  const { open, setOpen, selectedId, callBack } = props;

  const [selected, setSelected] = React.useState(null);
  const [loadingModal, setLoadingModal] = React.useState(false);

  const loading = useStoreQuotation((state) => state.loading);
  const setDataContract = useStoreQuotation((state) => state.setDataContract);

  const setDataFileContract = useStoreQuotation(
    (state) => state.setDataFileContract
  );

  const activeStep = useStoreQuotation((state) => state.step);
  const incrementStep = useStoreQuotation((state) => state.incrementStep);
  const decrementStep = useStoreQuotation((state) => state.decrementStep);
  const resetStep = useStoreQuotation((state) => state.resetStep);

  const [openCalendar, setOpenCalendar] = React.useState(false);

  const sizeDialog = activeStep === 1 ? "md" : "sm";

  useEffect(() => {
    return () => {
      callBack();
      setSelected(null);
      resetStep();
    };
  }, []);

  useEffect(() => {
    async function handleRefreshSelected() {
      setLoadingModal(true);
      const response = await callBack();
      setLoadingModal(false);
      setSelected(response.find((item) => item.id_quotation === selectedId));
    }

    if (activeStep === 0) {
      handleRefreshSelected();
    }
  }, [activeStep]);

  return (
    <>
      <Dialog
        open={open}
        keepMounted
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth={sizeDialog}
        aria-describedby="dialog-benefit"
      >
        <DialogTitle>
          Finalizar cotización
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent className={`${loading && "animate-pulse"} `}>
          <HorizontalLinearStepper
            loading={loading || loadingModal}
            activeStep={activeStep}
            incrementStep={incrementStep}
            decrementStep={decrementStep}
            resetStep={() => setOpen(false)}
            steps={[
              {
                title: "Completar datos",
                description: (
                  <>Los datos del evento tienen que ser completados</>
                ),
                component: () => {
                  return (
                    <ContractFormDH
                      setDataContract={setDataContract}
                      selected={selected}
                    />
                  );
                },
              },
              {
                title: "Descargar contrato",
                description: (
                  <>
                    Para continuar tiene que descargar el contrato, este es el
                    documento que se utilizará para la firma de la cotización,
                    revisalo antes de enviarlo al cliente.
                  </>
                ),
                component: () => <ViewAndDownloadContractFile />,
              },
              {
                title: "Subir contrato",
                description: (
                  <>
                    Para finalizar tiene que subir el contrato firmado, revisalo
                    antes de subirlo.
                  </>
                ),
                component: () => (
                  <ContractFormUploadContractFile
                    setDataFileContract={setDataFileContract}
                  />
                ),
              },
            ]}
          />
        </DialogContent>
      </Dialog>
      <ModalQuotationCalendar open={openCalendar} setOpen={setOpenCalendar} />
    </>
  );
};
export default ModalQuotationFinish;
