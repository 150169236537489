import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";

import "moment/locale/es";
import Moment from "react-moment";

import { formatMoney } from "../../functions/formatMoney";
import { formatPhoneNumber } from "../../functions/formatPhoneNumber";

const toUpperCaseFilter = (d) => {
  if (!d) {
    return d;
  }

  if (d === "") {
    return d;
  }

  return d.toUpperCase();
};

const DialogEliminar = ({
  open,
  setOpen,
  data,
  handleChangeStatus,
  handleReload,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Eliminar"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          ¿Estas seguro de eliminar la reserva?, se perderán estos datos:
          <ul>
            <li>
              Nombres: {data.nombre_reserva} {data.apellido_paterno_reserva}{" "}
              {data.apellido_materno_reserva}
            </li>
            <li>Teléfono: {formatPhoneNumber(data.telefono_reserva)} </li>
            <li>Correo electrónico: {data.correo_reserva} </li>
            <li>Programa: {data.pograma_reserva} </li>
            <li>
              Fecha:{" "}
              <Moment filter={toUpperCaseFilter} format="DD/MMM/YYYY">
                {data.fechareserva_reserva}
              </Moment>
            </li>
            <li>Cantidad de personas: {data.pax_reserva} </li>
            <li>Estado: {data.estado_reserva} </li>
            <li>Nombre cumpleañerx: {data.nombrecumpleanera_reserva} </li>
            <li>Detalle reserva: {data.detalle_reserva} </li>
            <li>Observación: {data.observacion_reserva} </li>
            <li>
              Monto abonado:{" "}
              {data.monto_reserva
                ? formatMoney(data.monto_reserva)
                : formatMoney(0)}
            </li>
          </ul>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="text" color="inherit">
          Cerrar
        </Button>
        <Button
          onClick={() => {
            handleChangeStatus(data.id_reserva, "eliminado");
            handleClose();
            handleReload && handleReload();
          }}
          variant="contained"
          color="error"
          autoFocus
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogEliminar;
