import {
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useAppContext } from "./state.benefit";

import "moment/locale/es";

import { Edit } from "@mui/icons-material";
import { Delete } from "@mui/icons-material";
import dayjs from "dayjs";
import NotFound from "../../atomic/organisms/NotFound.organisms";
import ContainerInput from "../../components/ContainerInput";
import { formatMoney } from "../../functions/formatMoney";
import { Link } from "react-router-dom";

const CustomTable = ({ rows, isLoad, onSelected, withActions = true }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (isLoad) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress color="inherit" /> <br />
        <Typography component="h5" variant="h5" style={{ textAlign: "center" }}>
          Cargando...
        </Typography>
      </div>
    );
  }

  if (rows.length === 0) {
    return <NotFound texto="No se encontró registros." />;
  }

  return (
    <>
      <TableContainer
        sx={{ maxHeight: 550 }}
        style={{
          border: "1px solid #e0e0e0",
        }}
      >
        <Table stickyHeader aria-label="sticky table" size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell align={"left"} style={{ minWidth: 150 }}>
                Nombre
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 140 }}>
                Tipo beneficio
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 140 }}>
                Fec. de apertura
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 140 }}>
                Fec. de cierre
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 120 }}>
                Valor de beneficio
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 140 }}>
                Fec. de creación
              </TableCell>
              {withActions && (
                <TableCell align={"left"} style={{ minWidth: 80 }}></TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                  <TableCell align={"left"}>
                    <Link
                      to={`/benefit/${row.id_benefit}`}
                      style={{ color: "blue", textDecoration: "underline" }}
                    >
                      {row.name_benefit}
                    </Link>
                  </TableCell>
                  <TableCell align={"left"}>{row.name_typebenefit}</TableCell>
                  <TableCell align={"left"}>
                    {dayjs(row.startdate_benefit).format("DD/MM/YYYY") ||
                      "Sin fecha de apertura"}
                  </TableCell>
                  <TableCell align={"left"}>
                    {dayjs(row.enddate_benefit).format("DD/MM/YYYY") ||
                      "Sin fecha de cierre"}
                  </TableCell>
                  <TableCell align={"left"}>
                    {row.typevalue_benefit === "percent"
                      ? row.value_benefit
                      : formatMoney(row.value_benefit)}
                    {row.typevalue_benefit === "percent" ? "%" : ""}
                  </TableCell>
                  <TableCell align={"left"}>
                    {dayjs(row.createdat_benefit).format("DD/MM/YYYY") ||
                      "Sin fecha de cierre"}
                  </TableCell>
                  {withActions && (
                    <TableCell
                      align={"center"}
                      style={{
                        display: "flex",
                      }}
                    >
                      <IconButton
                        style={{ marginRight: 10 }}
                        color="secondary"
                        onClick={() => onSelected("edit", row.id_benefit)}
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => onSelected("delete", row.id_benefit)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage="Filas por página"
        rowsPerPageOptions={[0, 30, 60, 90, 120, { value: -1, label: "Todo" }]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

const BenefitTable = () => {
  const state = useAppContext();

  return (
    <ContainerInput
      type="primary"
      style={{
        marginTop: 20,
        marginBottom: 20,
        padding: "0px",
        textAlign: "center",
        borderRadius: "0px",
      }}
    >
      <CustomTable
        rows={state.data}
        isLoad={state.isLoadData}
        onOpenModal={state.handleModal}
        onSelected={state.handleSelected}
      />
    </ContainerInput>
  );
};

export default BenefitTable;
