import dayjs from "dayjs";
import React, { useEffect } from "react";

import InputDefault from "../../../atomic/atoms/input.atoms";

import { Search } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import { Controller, useForm } from "react-hook-form";
import { getUser } from "../../../services/auth.services";
import { get_carta } from "../../../services/carta.services";

dayjs.locale("es");

const FilterDeliveryProduct = (props) => {
  const { onSearch } = props;

  const [categoryData, setCategoryData] = React.useState([]);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      searchName: "",
      categoryId: "",
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await get_carta(getUser()?.empresa.id_empsede);

      const newResponse = response.map((el) => ({
        label: el.nombre_categoriapla,
        value: el.id_categoriapla,
      }));

      setCategoryData(newResponse);
    };

    fetchData();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSearch)} autoComplete="off">
      <div className="mb-4 w-full grid grid-cols-12 gap-2">
        <div className="col-span-2">
          <Controller
            name="categoryId"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputDefault
                loading={false}
                fullWidth
                value={value}
                onChange={onChange}
                title={"Categoría"}
                type={"select"}
                options={categoryData}
              />
            )}
          />
        </div>

        <div className="col-span-3">
          <Controller
            name="searchName"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                value={value}
                onChange={onChange}
                type={"text"}
                title={"Buscar por nombre de plato"}
              />
            )}
          />
        </div>

        <div className="col-span-4 md:col-span-3 flex gap-2">
          <IconButton
            type="submit"
            color="default"
            className="ml-auto col-span-1"
          >
            <Search />
          </IconButton>
        </div>
      </div>
    </form>
  );
};

export default FilterDeliveryProduct;
