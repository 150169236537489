import "../../assets/styles/templateReservas.scss";

import LOGO from "../../assets/images/logo-verde.png";

import { motion } from "framer-motion";

import { useEffect, useState } from "react";
import Confetti from "react-confetti";

const VerdeMostazaTemplate = ({ children, selectedSucursal, confettiShow }) => {
  const [isCelebrate, setIsCelebrate] = useState(false);

  const dataFooter = {
    3: {
      title: "Local Valparaíso",
      subtitle: "Santos Ossa #3788",
      subtitle2: "Horario: Viernes y Sábado desde las 22:30 hrs.",
      subtitle3: "Más información: +56 9 42207279 - +56 9 58715831",
    },
    2: {
      title: "Local Villa Alemana",
      subtitle: "Aromos #82",
      subtitle2: "Horario: Viernes y Sábado desde las 22:30 hrs.",
      subtitle3: "Más información: +56 9 42207279 - +56 9 58715831",
    },
  };

  const dataFooterSelected = selectedSucursal && dataFooter[selectedSucursal];

  const animationConfetti = () => {
    setIsCelebrate(true);
    setTimeout(() => {
      setIsCelebrate(false);
    }, 10000);
  };

  const fadeIn = (direction, delay) => {
    return {
      hidden: {
        y: direction === "up" ? 80 : direction === "down" ? -80 : 0,
        opacity: 0,
        x: direction === "left" ? 80 : direction === "right" ? -80 : 0,
      },
      show: {
        y: 0,
        x: 0,
        opacity: 1,
        transition: {
          type: "tween",
          duration: 0.5,
          delay: delay,
          ease: [0.25, 0.25, 0.25, 0.75],
        },
      },
    };
  };

  useEffect(() => {
    confettiShow && animationConfetti();
  }, [confettiShow]);

  return (
    <div className="template-carta">
      {isCelebrate && <Confetti />}

      <div className="template-carta--header">
        <div className="template-carta--header__logo">
          <motion.img
            src={LOGO}
            variants={fadeIn("up", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.2 }}
          />
        </div>
        <div className="template-carta--header__title">
          <motion.h1
            variants={fadeIn("down", 0.4)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.2 }}
          >
            SÚMATE A LA MEJOR FIESTA 80's y 90's
          </motion.h1>
        </div>
      </div>

      <div className="template-carta--body">{children}</div>

      <div className="template-carta--footer">
        {dataFooterSelected && (
          <p>
            {dataFooterSelected.title}: {dataFooterSelected.subtitle} -{" "}
            {dataFooterSelected.subtitle2}
            <br />
            {dataFooterSelected.subtitle3}
          </p>
        )}
      </div>
    </div>
  );
};

export default VerdeMostazaTemplate;
