import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";

import { Close } from "@mui/icons-material";
import {
  IconButton,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
} from "@mui/material";
import LoadingPage from "../../components/LoadingPage";
import { get_leads_won } from "../../services/kommo.services";
import dayjs from "dayjs";
import { getUser } from "../../services/auth.services";

const DialogLeads = ({ open, setOpen, onSelectLead }) => {
  const [isLoad, setIsLoad] = React.useState(false);
  const [leads, setLeads] = React.useState([]);

  const fetchLeads = async () => {
    try {
      const getUserId = getUser()?.kommoId;

      setIsLoad(true);
      const response = await get_leads_won(getUserId);
      setIsLoad(false);
      setLeads(response);
    } catch (err) {
      setIsLoad(false);
      console.log(err);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectLead = (lead) => {
    onSelectLead(lead);
    setOpen(false);
  };

  React.useEffect(() => {
    if (open) {
      fetchLeads();
    }
  }, [open]);

  if (isLoad) {
    return <LoadingPage open={isLoad} />;
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth={"md"}
    >
      <DialogTitle id="alert-dialog-title">
        Leads ganados
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table
            size="small"
            style={{
              border: "1px #eee solid",
              position: "sticky",
              top: 0,
              background: "white",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ background: "#eee", textAlign: "left" }}>
                  Lead Id
                </TableCell>
                <TableCell style={{ background: "#eee", textAlign: "left" }}>
                  Nombre del Lead
                </TableCell>
                <TableCell style={{ background: "#eee", textAlign: "left" }}>
                  Contácto
                </TableCell>
                <TableCell style={{ background: "#eee", textAlign: "left" }}>
                  Número de teléfono
                </TableCell>
                <TableCell style={{ background: "#eee", textAlign: "left" }}>
                  Fecha registro
                </TableCell>
                <TableCell
                  style={{ background: "#eee", textAlign: "left" }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {leads.map((row, index) => (
                <TableRow key={index}>
                  <TableCell style={{ textAlign: "left" }}>
                    {row.leadId}
                  </TableCell>
                  <TableCell style={{ textAlign: "left" }}>
                    {row.name}
                  </TableCell>
                  <TableCell style={{ textAlign: "left" }}>
                    {row.contact.name}
                  </TableCell>
                  <TableCell style={{ textAlign: "left" }}>
                    {row.contact?.phoneNumber}
                  </TableCell>
                  <TableCell style={{ textAlign: "left" }}>
                    {dayjs.unix(row.createdAt).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Button
                      disabled={!row.contact?.phoneNumber}
                      variant="contained"
                      color="success"
                      size="small"
                      onClick={() => handleSelectLead(row)}
                    >
                      Crear reserva
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={handleClose} variant="text" color="inherit">
          Salir
        </Button>
      </DialogActions> */}
    </Dialog>
  );
};

export default DialogLeads;
