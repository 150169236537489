import React from "react";

import { Close } from "@mui/icons-material";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import { useForm } from "react-hook-form";

import { toast } from "react-hot-toast";
import { formatNumber } from "../index.pay-merchante";
import CustomTable from "../../../atomic/organisms/Table.organisms";
import { updateWallet } from "../../../services/wallet.services";

const initalValues = null;

const ModalPayMerchante = (props) => {
  const { open, setOpen, selected, callBack } = props;
  const sumTotal = selected.reduce((acc, el) => acc + el.quantity_to_pay, 0);

  const { handleSubmit } = useForm({
    defaultValues: initalValues,
  });

  const [load, setLoad] = React.useState(false);

  const onAction = async () => {
    setLoad(true);

    const inBound = selected.map((el) => {
      return {
        id_wallet: el.id_wallet,
        status: "pagado",
      };
    });

    await updateWallet(inBound);

    await callBack();

    toast.success("Se pagó correctamente", {
      position: "top-center",
    });

    setLoad(false);
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth={"sm"}
      aria-describedby="dialog-benefit"
    >
      <form onSubmit={handleSubmit(onAction)} autoComplete="off">
        <DialogTitle>
          Pagar cuentas de merchante
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={`${load && "animate-pulse"} `}>
          <Alert className="mb-2" severity="info">
            Verificar los datos antes de realizar el pago, si esta seguro de la
            acción presione aceptar.
          </Alert>
          <CustomTable
            onRowSelected={false}
            columns={[
              {
                id: "id_reserva",
                label: "Código",
                minWidth: 80,
                align: "left",
              },
              {
                id: "usu_nombre",
                label: "Merchante",
                minWidth: 80,
                align: "left",
                format: (el) => {
                  return el.toUpperCase();
                },
              },
              {
                id: "quantity_to_pay",
                label: "Cantidad a pagar",
                minWidth: 150,
                align: "left",
                format: (el) => {
                  return `$ ${formatNumber(el)}`;
                },
              },
            ]}
            rows={selected}
            load={load}
            error={false}
            pagination={true}
          />
          <div className="flex justify-end border-t-2 border-gray-200 pt-2">
            <p className="text-sm font-semibold">Total: </p>
            <p className="text-sm font-semibold ml-2 text-red-500">$ {formatNumber(sumTotal)}</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            disable={load.toString()}
            color="inherit"
            variant="text"
            onClick={() => {
              setOpen(false);
            }}
          >
            Salir
          </Button>
          <Button
            color="secondary"
            variant="contained"
            type="submit"
            disable={load.toString()}
          >
            Aceptar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default ModalPayMerchante;
