import ContainerInput from "./ContainerInput";

import { PointOfSale } from "@mui/icons-material";
import { Typography } from "@mui/material";

const NotExistOpenBox = () => {
  return (
    <ContainerInput
      style={{
        textAlign: "center",
        height: "100%",
        background: "transparent",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div style={{ textAlign: "center", margin: "auto" }}>
        <PointOfSale style={{ fontSize: 40 }} /> <br />
        <Typography component={"h5"} variant={"h5"}>
          No existe caja abierta para el dia de hoy.
        </Typography>
      </div>
    </ContainerInput>
  );
};

export default NotExistOpenBox;
