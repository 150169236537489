import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import {
  searchConsumption,
  searchConsumptionByNameDay,
  searchConsumptionbByWeek,
} from "../../services/consumption.services";
import { get_plato } from "../../services/plato.services";

import dayjs from "dayjs";
import "moment/locale/es";
import { getUser } from "../../services/auth.services";
import {
  fechaFormat,
  fechaLastMonths,
  formatHorizontalDate,
  formatPrint,
  formatProjection,
  formatProjectionWeek,
} from "./helper.consumption";

export const TYPE_SEARCH = {
  HISTORY: 0,
  PROJECTION: 1,
  PROJECTION_WEEK: 2,
  UTILITY: 3,
};

const SEARCH_DEFAULT_VALUES = {
  idCategory: "",
  dateIni: dayjs().startOf("month").format("YYYY-MM-DD"),
  dateEnd: dayjs().format("YYYY-MM-DD"),
  nameDay: "",
  amountWeek: 0,
  // dateIni: "",
  // dateEnd: "",
};

const AppContext = createContext({
  /* Values */
  data: [],
  dataPlatos: [],
  dataProjection: [],
  dataProjectionWeek: [],
  isLoadData: false,
  dataExcel: [],
  tabValue: 0,
  searchDefaultValues: SEARCH_DEFAULT_VALUES,
  tabs: [],
  inputSearch: true,
  /* Methods */
  onSearch: (value) => {},
  changeTab: (value) => {},
});

export default function ConsumptionState({ children }) {
  const [data, setData] = useState([]);
  const [dataProjection, setDataProjection] = useState([]);
  const [dataProjectionWeek, setDataProjectionWeek] = useState([]);

  const [dataPlatos, setDataPlatos] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [sortValue, setSortValue] = useState("desc");

  const [loading, setLoading] = useState(false);

  const TYPE_COOK = {
    2: ["Cocina - Fondos", "Cocina - Entradas"],
    3: ["Bartender"],
    10: ["Pastelería"],
  };

  const { id_perfil } = getUser();

  const [tabs, setTabs] = useState([
    {
      label: "Historico",
      value: TYPE_SEARCH.HISTORY,
    },
    {
      label: "Proyección",
      value: TYPE_SEARCH.PROJECTION,
    },
    {
      label: "Proyección semanal",
      value: TYPE_SEARCH.PROJECTION_WEEK,
    },
    {
      label: "Utilidad",
      value: TYPE_SEARCH.UTILITY,
    },
  ]);

  const dataExcel = useCallback(
    () =>
      formatPrint(
        tabValue,
        tabValue === TYPE_SEARCH.PROJECTION ? dataProjection : data
      ),
    [tabValue]
  );

  async function obtainPlatos() {
    const dataPlatos = await get_plato();

    if (!TYPE_COOK[id_perfil]) return dataPlatos;

    const newDataPlato = dataPlatos.filter((el) => {
      const { nombre_area } = el;

      return TYPE_COOK[id_perfil].includes(nombre_area);
    });

    return newDataPlato;
  }

  async function handleSearch(params) {
    setLoading(true);

    const lastMonths = fechaLastMonths(6);

    const newParams = {
      dateIni:
        tabValue === TYPE_SEARCH.HISTORY ? params.dateIni : lastMonths.dateIni,
      dateEnd:
        tabValue === TYPE_SEARCH.HISTORY ? params.dateEnd : lastMonths.dateEnd,
      idCategory: params.idCategory,
      idPlato: params.idPlato,
      nameDay: params?.nameDay || "",
      amountWeek: params?.amountWeek || 0,
    };

    let dataPlatos = await obtainPlatos();

    if (params.idCategory !== "") {
      dataPlatos = dataPlatos.filter(
        (el) => el.id_categoriapla === parseInt(params.idCategory)
      );
    }

    if (params.idPlato > 0) {
      dataPlatos = dataPlatos.filter(
        (el) => el.id_plato === parseInt(params.idPlato)
      );
    }

    if (tabValue === TYPE_SEARCH.HISTORY) {
      const data = await searchConsumption(newParams);

      const newData = formatHorizontalDate(data, dataPlatos, params);
      setData(newData || []);
    }

    if (tabValue === TYPE_SEARCH.PROJECTION) {
      const data = await searchConsumptionByNameDay(newParams);

      const newData = formatProjection(dataPlatos, data, params);
      setDataProjection(newData || []);
    }

    if (tabValue === TYPE_SEARCH.PROJECTION_WEEK) {
      const data = await searchConsumptionbByWeek(newParams);

      const newData = formatProjectionWeek(dataPlatos, data, params);
      setDataProjectionWeek(newData || []);
    }

    setLoading(false);
  }

  const onChangeTab = (value) => {
    setTabValue(value);
  };

  const onChangeSort = (value) => {
    setSortValue(value);
  };

  async function obtain() {
    setLoading(true);
    const date = dayjs();

    const dateIni = date.startOf("month");
    const dateEnd = date;

    const params = {
      dateIni: fechaFormat(dateIni),
      dateEnd: fechaFormat(dateEnd),
    };

    const data = await searchConsumption();
    const dataPlatos = await obtainPlatos();

    const newData = formatHorizontalDate(data, dataPlatos, params, sortValue);

    setData(newData || []);
    setLoading(false);
  }

  async function obtainProducts() {
    const response = await obtainPlatos();

    const formatPlato = response.map((el) => ({
      value: el.id_plato,
      label: `#${el.id_plato} - ${el.nombre_plato}`,
    }));

    setDataPlatos(formatPlato);
  }

  function filterTabsByRol() {
    const ID_PERFIL = id_perfil;
    if (ID_PERFIL === 2 || ID_PERFIL === 3 || ID_PERFIL === 10) {
      setTabs(tabs.filter((el) => el.value !== TYPE_SEARCH.UTILITY));
    }
  }

  useEffect(() => {
    async function obtainData() {
      await obtain();
    }

    obtainData();
  }, [tabValue]);

  useEffect(() => {
    obtain();
  }, [sortValue]);

  useEffect(() => {
    obtainProducts();
    filterTabsByRol();
  }, []);

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <AppContext.Provider
      value={{
        onSearch: handleSearch,
        data,
        dataPlatos,
        searchDefaultValues: SEARCH_DEFAULT_VALUES,
        dataProjection,
        dataProjectionWeek,
        isLoadData: loading,
        dataExcel: dataExcel(),
        tabValue,
        sortValue,
        changeTab: onChangeTab,
        changeSort: onChangeSort,
        tabs,
        inputSearch: !TYPE_COOK[id_perfil],
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
