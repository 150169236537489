import React from "react";
import Template from "../../components/Template";

import { getUser } from "../../services/auth.services";

const Report = () => {
  return (
    <Template title="Reporte" classmaincontainer="absolute top-16 bottom-0 left-0 right-0">
      <div className="h-full w-full">
        <iframe
          title={getUser().empresa.nom_emp}
          className="w-full h-full"
          src="https://graph-python-qr75l.ondigitalocean.app/?embedded=true"
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
      </div>
    </Template>
  );
};

export default Report;
