/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import * as React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import { Grid } from "@mui/material/";

import { Alert, Collapse } from "@mui/material";

import { useForm } from "react-hook-form";

import { Close } from "@mui/icons-material";

import { actionTypes } from "../../../../../redux/menu/actions";

import toast from "react-hot-toast";

import { useDispatch, useSelector } from "react-redux";

import { delete_categoria } from "../../../../../services/categoria_plato.services";

const ErrorMsg = ({ wrong, setWrong, message }) => {
  return (
    <Collapse in={wrong}>
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setWrong(false);
            }}
          >
            <Close fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
        severity="error"
      >
        {message}
      </Alert>
    </Collapse>
  );
};

const DialogDelete = () => {
  const dispatch = useDispatch();
  const { dataSelected_categoria, openDelete_categoria, dataMenu } =
    useSelector((state) => state.reducer_menu);

  const { reset, handleSubmit } = useForm({
    defaultValues: dataSelected_categoria,
  });

  const [wrong, setWrong] = React.useState({ open: false, message: "" });

  const onOpen = (data) =>
    dispatch({
      type: actionTypes.openModalDelete_categoria,
      payload: data,
    });

  const onLoad = (data) =>
    dispatch({
      type: actionTypes.setLoading_categoria,
      payload: data,
    });

  const onGet = (data) =>
    dispatch({
      type: actionTypes.getDataMenu,
      payload: data,
    });

  const onSetDataSelected = (data) =>
    dispatch({
      type: actionTypes.setDataSelected_categoria,
      payload: data,
    });

  const handleModal = (value) => {
    onOpen(value);

    if (!value) {
      onSetDataSelected({
        ...dataSelected_categoria,
        id_categoriapla: "",
        nombre_categoriapla: "",
      });
    }
  };

  const handleDelete = async (data) => {
    try {
      onGet({ ...dataMenu, load: true, data: [], err: false });
      onLoad(true);
      handleModal(false);

      let { response, categoria_plato } = await delete_categoria(
        data.id_categoriapla
      );

      if (response.affectedRows === 1) {
        toast.success("La categoría fue eliminada correctamente.");
        onGet({ ...dataMenu, load: false, data: categoria_plato, err: false });
      } else {
        toast.error("No se pudo eliminar la categoría.");
      }

      onLoad(false);
    } catch (err) {
      console.error(err);
      toast.error("Hubo un error al eliminar la categoría.");
      onLoad(false);
    }
  };

  React.useEffect(() => {
    if (openDelete_categoria) {
      reset(dataSelected_categoria);
    }
  }, [openDelete_categoria]);

  return (
    <>
      <Dialog
        open={openDelete_categoria}
        onClose={() => handleModal(false)}
        fullWidth
        maxWidth={"sm"}
      >
        <form onSubmit={handleSubmit(handleDelete)} autoComplete="off">
          <DialogTitle>
            Importante!
            <IconButton
              aria-label="close"
              onClick={() => handleModal(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <ErrorMsg
              wrong={wrong.open}
              setWrong={(el) => setWrong({ ...wrong, open: el })}
              message={wrong.message}
            />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} lg={12}>
                ¿Está seguro que deseas eliminar la categoría?
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="info"
              variant="text"
              onClick={() => {
                handleModal(false);
              }}
            >
              Salir
            </Button>
            <Button type="submit" color="error" variant="contained">
              Eliminar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default DialogDelete;
