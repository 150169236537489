import * as React from "react";

import { get_user_today } from "../../../../../services/user.services";

import {
  Slide,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Grid,
  Typography,
  CircularProgress,
  Paper,
} from "@mui/material";

import NotFound from "../../../../../atomic/organisms/NotFound.organisms";
import { nameCamarero } from "../../../../../functions/nameCamarero";

// import ItemInput from "../../Components/ItemInput";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref || false} {...props} />;
});

const TableGarzon = (props) => {
  const { selectedTable, handleSetSelectedTable, usuIdSelected } = props;
  const { data, err, load } = props.data;

  if (load) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress color="inherit" /> <br />
        <Typography component="h5" variant="h5" style={{ textAlign: "center" }}>
          Cargando...
        </Typography>
      </div>
    );
  }

  if (err) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <NotFound title="¡Ocurrió un problema!" />
      </div>
    );
  }

  if (data.length === 0 && load === false) {
    return <NotFound texto="No se encontró registros." />;
  }

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={1}
      style={{ marginTop: 10 }}
    >
      {data.map((el, idx) => (
        <Grid key={idx} item xs={12} sm={4} md={4} lg={4} zeroMinWidth>
          <Paper
            variant="outlined"
            className={`mesaIcon ${
              el.usu_id === usuIdSelected ? "mesaDisabled" : ""
            }  ${selectedTable.usu_id === el.usu_id && "mesaSelected"} `}
            onClick={() => handleSetSelectedTable(el)}
          >
            <Typography component={"h6"} variant={"h6"}>
              {el.usu_nombre}
            </Typography>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

const DialogChangeGarzon = (props) => {
  let { open, setOpen, infoPedido, callback } = props;

  const [dataGarzon, setDataGarzon] = React.useState({
    data: [],
    load: false,
    err: false,
  });

  const usuIdSelected = infoPedido.data.usu_id;

  const selectedTable = "";

  const handleClose = () => {
    setOpen(false);
  };

  const handleSetSelectedTable = (element) => {
    if (usuIdSelected === element.usu_id) {
      return null;
    }

    let data = {
      usu_id: element.usu_id,
    };

    callback(data);
    handleClose();
  };

  React.useEffect(() => {
    const getGarzon = async () => {
      try {
        setDataGarzon({ data: [], load: true, err: false });
        const response = await get_user_today();
        const newResponse = response.filter((el) => el.usu_active_today === 1);

        setDataGarzon({ data: newResponse, load: false, err: false });
      } catch (err) {
        console.log(err);
        setDataGarzon({ data: [], load: false, err: true });
      }
    };

    if (open) {
      getGarzon();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth
      onClose={handleClose}
      maxWidth={"sm"}
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Cambiar el {nameCamarero(false)}</DialogTitle>
      <DialogContent>
        <TableGarzon
          data={dataGarzon}
          usuIdSelected={usuIdSelected}
          selectedTable={selectedTable}
          handleSetSelectedTable={handleSetSelectedTable}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="text" color="inherit">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogChangeGarzon;
