import React, { useEffect, useState } from "react";

import Head from "../Head";

import HeadCarta from "./Components/HeadCarta";
import BodyCarta from "./Components/BodyCarta";
import FooterCarta from "./Components/FooterCarta";

// import Logo from "../../assets/images/logo.png";

import DialogCart from "../../pages/carta/Cart";
import DialogSearchPedido from "../../pages/carta/Pedido/SearchPedido";
import DialogSaveUserData from "../../pages/carta/SaveUserData/saveUserData";

import useEmpresa from "../../hooks/useEmpresa";

import { Toaster } from "react-hot-toast";
import LoadingPage from "../LoadingPage";
import NotFound from "../../atomic/organisms/NotFound.organisms";

import { useLocation } from "react-router-dom";
import dayjs from "dayjs";

const Html = (props) => {
  const { title } = props;
  return (
    <div className="carta">
      <Toaster />
      <Head>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content="POS" />
        <meta property="og:title" content={title} />
      </Head>
      <div className="container-carta">{props.children}</div>
    </div>
  );
};

const Template = (props) => {
  let { empresa, title, dialog = null } = props;

  const [openCart, setOpenCart] = useState(false);
  const [openSaveUser, setOpenSaveUser] = useState(false);
  const [openSearchPEdido, setOpenSearchPedido] = useState(false);
  const [dataUser, setDataUser] = useState(false);

  const dataEmpresa = useEmpresa(empresa, "alias");

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const handleSetDataUser = (data) => {
    setDataUser(data);
  };

  useEffect(() => {
    const getSessionUser = () => {
      let cartUser = JSON.parse(localStorage.getItem("cart_user"));
      const dateNow = dayjs();

      // when the user is not registered and not is haven a date, then set the date now
      if (!cartUser?.createdAt) {
        localStorage.setItem(
          "cart_user",
          JSON.stringify({ ...cartUser, createdAt: dateNow })
        );
      }

      if (
        cartUser &&
        cartUser?.numtelf_client &&
        cartUser?.numtelf_client !== "" &&
        !searchParams.get("user")
      ) {
        const dateCart = dayjs(cartUser.createdAt);

        const elapsedHours = dateNow.diff(dateCart, "hour");

        if (elapsedHours >= 24) {
          localStorage.removeItem("cart_user");
          setOpenSaveUser(true);
          return;
        }

        setOpenSaveUser(false);
        setDataUser(cartUser);
      } else if (searchParams.get("user") === "free") {
        localStorage.removeItem("cart_user");
      } else {
        setOpenSaveUser(true);
      }
    };

    getSessionUser();
  }, []);

  if (dataEmpresa.load === true) {
    return (
      <Html title={title}>
        <LoadingPage open={true} />
      </Html>
    );
  }

  if (dataEmpresa.data === undefined) {
    return (
      <Html title={title}>
        <div
          style={{
            textAlign: "center",
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
            display: "flex",
            height: "100%",
            alignItems: "center",
          }}
        >
          <div style={{ margin: "auto" }}>
            <NotFound title="No se encontró la carta" />
          </div>
        </div>
      </Html>
    );
  }

  return (
    <Html title={title}>
      <HeadCarta
        image={dataEmpresa.data.logo_emp}
        actionButtons={
          <>
            {dataUser && (
              <div style={{ color: "black" }}>Hola {dataUser.name_client}!</div>
            )}
          </>
        }
      />
      {/* BODY */}
      <BodyCarta {...props}>{props.children}</BodyCarta>
      {/* FOOTER */}
      <FooterCarta
        data={dataEmpresa.data}
        name={dataEmpresa.data.nom_emp}
        direction={dataEmpresa.data.direction_empsede}
        comuna={dataEmpresa.data.comuna_empsede}
      />
      {/* DIALOG */}
      <DialogSaveUserData
        empresa={empresa}
        open={openSaveUser}
        setOpen={setOpenSaveUser}
        handleSetDataUser={handleSetDataUser}
        dataUrl={{
          id_empsede: searchParams.get("sede"),
          id_mesa: searchParams.get("mesa"),
        }}
      />
      <DialogCart open={openCart} setOpen={setOpenCart} />
      <DialogSearchPedido
        open={openSearchPEdido}
        setOpen={setOpenSearchPedido}
      />
      {dialog}
    </Html>
  );
};

export default Template;
