import dayjs from "dayjs";
import TitleStep from "../Component/TitleStep";

import { Paper, Grid, TextField, Typography } from "@mui/material";

const Checkout = (props) => {
  const { selected, handleSetSelected } = props;

  const handleChange = (type, e) => {
    e.preventDefault();

    const { value, name } = e.target;

    const data = {
      ...selected[type],
      [name]: value,
    };

    // CAMBIAR QUE NO SEA PARA MESA PORQUE REFRESA TODO EL LOAD
    handleSetSelected(data, type);
  };

  return (
    <Paper variant="outlined" style={{ padding: 5 }}>
      <TitleStep>Verificar datos antes de aperturar</TitleStep>
      <br />

      <Typography variant="h6" component="h6">
        Datos de la mesa:
      </Typography>

      <Grid container spacing={1} style={{ marginTop: 5 }}>
        <Grid item xs={12} sm={12} lg={12}>
          <TextField
            value={`#${selected.mesa.codigo_mesa}`}
            disabled
            label="Código de mesa"
            className={`InputDefault`}
          />
        </Grid>

        {!selected.reserva.id_reserva && (
          <Grid item xs={12} sm={12} lg={12}>
            <TextField
              value={`${selected.mesa.amount_people}`}
              label="Cantidad de personas"
              name="amount_people"
              onChange={(e) => handleChange("mesa", e)}
              className={`InputDefault`}
            />
          </Grid>
        )}
      </Grid>

      {selected.reserva.id_reserva && (
        <>
          <Typography variant="h6" component="h6">
            Datos de la reserva:
          </Typography>

          <Grid container spacing={1} style={{ marginTop: 5 }}>
            <Grid item xs={12} sm={2} lg={2}>
              <TextField
                fullWidth
                value={`#${selected.reserva.id_reserva}`}
                disabled
                label="Código de reserva"
                className={`InputDefault `}
              />
            </Grid>
            <Grid item xs={12} sm={3} lg={3}>
              <TextField
                value={selected.reserva.nombres_reserva}
                disabled
                label="Nombres"
                className={`InputDefault`}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3} lg={3}>
              <TextField
                value={selected.reserva.apellidos_reserva}
                disabled
                label="Apellidos"
                className={`InputDefault`}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={4}>
              <TextField
                value={dayjs(selected.reserva.fechareserva_reserva).format(
                  "DD/MMM/YYYY"
                )}
                disabled
                label="Fecha de reserva"
                className={`InputDefault `}
                fullWidth
              />
            </Grid>
          </Grid>
        </>
      )}

      {selected.cliente.id_cliente !== "" && selected.reserva.id_reserva && (
        <>
          <Typography variant="h6" component="h6">
            Datos del cliente:
          </Typography>
          <Grid container spacing={1} style={{ marginTop: 5 }}>
            <Grid item xs={12} sm={4} lg={4}>
              <TextField
                value={`${selected.cliente.nombre_cliente}`}
                disabled
                fullWidth
                name="texto"
                label="Nombres"
                className={`InputDefault `}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={4}>
              <TextField
                value={`${selected.cliente.apepaterno_cliente} ${selected.cliente.apematerno_cliente}`}
                disabled
                fullWidth
                name="texto"
                label="Apellidos"
                className={`InputDefault `}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={4}>
              <TextField
                value={`${selected.cliente.telf_cliente}`}
                disabled
                fullWidth
                name="texto"
                label="Teléfono"
                className={`InputDefault `}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={4}>
              <TextField
                value={`${selected.cliente.clasificacion_cliente}`}
                disabled
                fullWidth
                name="texto"
                label="Clasificación"
                className={`InputDefault `}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Paper>
  );
};

export default Checkout;
