import * as React from "react";

import { useHistory } from "react-router-dom";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import { Grid, List, ListItem, Typography } from "@mui/material";

import { ShoppingCart } from "@mui/icons-material";

import { useDispatch, useSelector } from "react-redux";
import { actionTypes } from "../../../redux/carta/actions";

import InputCount from "./Components/Count";

import { formatMoney } from "../../../functions/formatMoney";
import "./style.cart.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogContainer = ({ data, onClose, ...props }) => {
  if (data === 0) {
    return (
      <DialogContent style={{ paddingTop: 20 }}>
        <div className="emptyText">
          <Typography variant="h5" component="h5">
            Tu carito esta vacío =(
          </Typography>{" "}
          <br />
          <Typography variant="span" component="span">
            Cuando tengas algo en tu carrito podrás continuar, esto aparecerá
            aquí. ¿Empezamos?
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            style={{ display: "block", margin: "auto", marginTop: "20px" }}
            onClick={onClose}
          >
            Iniciar
          </Button>
        </div>
      </DialogContent>
    );
  }

  return (
    <DialogContent className="dialogContainer">{props.children}</DialogContent>
  );
};

const DialogFooter = ({ data, ...props }) => {
  if (data === 0) {
    return null;
  }

  return <DialogActions {...props}>{props.children}</DialogActions>;
};

const ItemRow = (props) => {
  let { idx, item, handleAddProduct } = props;

  return (
    <ListItem key={idx} className="listMiniCart__listitem">
      <Grid container spacing={1}>
        <Grid item className="imageItem" xs={12} sm={8} lg={8}>
          <div className="container__title">
            <div className="__name">{item.nombre_plato}</div>
            <div className="__price">
              {formatMoney(item.precio_plato * item.totAmount)}
            </div>
          </div>
        </Grid>
        <Grid item className="countItem" xs={12} sm={4} lg={4}>
          <div className="container__count">
            <InputCount
              style={{ width: "100%" }}
              addCount={(el) => handleAddProduct(item, el)}
              valueTotAmount={item.totAmount}
            />
          </div>
        </Grid>
      </Grid>
    </ListItem>
  );
};

/*
    MAIN COMPONENT
*/

const Cart = (props) => {
  let { open, setOpen } = props;

  let history = useHistory();

  const dispatch = useDispatch();
  const { products, priceProducts } = useSelector(
    (state) => state.reducer_carta
  );

  const onAddProduct = (row, count) => {
    dispatch({
      type: actionTypes.addProduct,
      payload: { row, count },
    });
  };

  const onProductsDelete = (index) => {
    dispatch({
      type: actionTypes.deleteProduct,
      payload: { idx_delete: index },
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRedirect = () => {
    handleClose();
    history.push("/carta/checkout");
  };

  const handleAddProduct = (product, count = 1) => {
    if (parseInt(count) > 0) {
      onAddProduct(product, parseInt(count));
    } else {
      let findIdxProduct = products.findIndex(
        (el) => el.id_plato === product.id_plato
      );

      onProductsDelete(findIdxProduct);
    }
  };

  return (
    <Dialog
      fullWidth
      open={open}
      TransitionComponent={Transition}
      maxWidth={"sm"}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle style={{ borderBottom: "1px solid #cfcfcf" }}>
        <ShoppingCart style={{ fontSize: 18, marginRight: 5 }} />
        {"Carrito"}
      </DialogTitle>
      <DialogContainer
        data={products.length}
        style={{ padding: 0 }}
        onClose={handleClose}
      >
        <List component="ul" className="listMiniCart">
          {products.map((item, idx) => (
            <ItemRow
              key={idx}
              idx={idx}
              item={item}
              handleAddProduct={handleAddProduct}
            />
          ))}
        </List>
      </DialogContainer>
      <DialogFooter
        data={products.length}
        style={{ borderTop: "1px solid #cfcfcf" }}
      >
        <Button color="inherit" variant={"text"} onClick={handleClose}>
          Salir
        </Button>
        <Button
          color={"secondary"}
          variant={"contained"}
          onClick={handleRedirect}
        >
          Pedir {formatMoney(priceProducts)}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default Cart;
