import "../carta.style.scss";

import { Container } from "@mui/material";

const BodyCarta = (props) => {
  return (
    <Container fixed className="body" {...props}>
      {props.children}
    </Container>
  );
};

export default BodyCarta;
