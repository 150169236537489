import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { Controller, useForm } from "react-hook-form";

import TemplateLogin from "../../components/TemplateLogin";

import Logo from "../../assets/images/logo-new.png";
import Input from "../../components/Forms/Input";

import { login } from "../../services/auth.services";

import { Close } from "@mui/icons-material";
import { Alert, Button, Collapse, IconButton, Typography } from "@mui/material";

import { toast } from "react-hot-toast";

import { motion } from "framer-motion";
import { Link } from "react-router-dom/cjs/react-router-dom";

const fadeIn = (direction, delay) => {
  return {
    hidden: {
      y: direction === "up" ? 80 : direction === "down" ? -80 : 0,
      opacity: 0,
      x: direction === "left" ? 80 : direction === "right" ? -80 : 0,
    },
    show: {
      y: 0,
      x: 0,
      opacity: 1,
      transition: {
        type: "tween",
        duration: 0.5,
        delay: delay,
        ease: [0.25, 0.25, 0.25, 0.75],
      },
    },
  };
};

export const RenderLogin = () => {
  let history = useHistory();

  // let initialValues = {
  //   username: "pedroolivares@kioskovirtual.cl",
  //   password: "kioskodrilo",
  // };

  let initialValues = {
    username: "",
    password: "",
  };

  const { control, handleSubmit } = useForm({
    defaultValues: initialValues,
  });

  const [wrongPassword, setWrongPassword] = useState(false);

  const handleLogin = async (data) => {
    let response = await login(data);

    const responseLogin = response.user;
    const responseToken = response.token;

    if (!responseLogin) {
      setWrongPassword(true);

      return;
    }

    if (responseLogin.estado === "INACTIVO") {
      toast.error(
        "Tu cuenta se encuentra eliminada, comunícate con el administrador.",
        { position: "top-center" }
      );
      return;
    }

    if (parseInt(responseLogin.active) === 0) {
      toast.error("Tu cuenta se encuentra desactivada.", {
        position: "top-center",
      });
      return;
    }

    if (responseLogin.id_perfil === 1 && responseLogin.activeToday === 0) {
      toast.error("Tu cuenta no está activa para hoy.", {
        position: "top-center",
      });
      return;
    }

    localStorage.setItem("tokenAccess_vm", responseToken);
    localStorage.setItem("user_vm", JSON.stringify(responseLogin));

    setWrongPassword(false);
    history.push("/home");
  };

  return (
    <TemplateLogin title="Login">
      <motion.div
        variants={fadeIn("up", 0.5)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: true, amount: 0.6 }}
        className="text-center Logo_Login"
      >
        <img
          src={Logo}
          alt="Logo"
          className="w-40 mx-auto object-cover object-center"
        />

        <Typography variant="h5" className="Texto_Login">
          Iniciar sesión
        </Typography>
      </motion.div>

      <div className="mt-4">
        <form onSubmit={handleSubmit(handleLogin)} autoComplete="off">
          <Collapse in={wrongPassword}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setWrongPassword(false);
                  }}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
              severity="error"
            >
              Nombre de usuario o contraseña incorrecta.
            </Alert>
          </Collapse>

          <motion.div
            variants={fadeIn("down", 0.9)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.6 }}
            className="text-center Logo_Login"
          >
            <Controller
              name="username"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Input
                  required
                  value={value}
                  onChange={onChange}
                  type="text"
                  title="Usuario"
                  style={{ marginBottom: "15px" }}
                />
              )}
            />
          </motion.div>

          <motion.div
            variants={fadeIn("down", 1.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.6 }}
            className="text-center Logo_Login"
          >
            <Controller
              name="password"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Input
                  required
                  value={value}
                  onChange={onChange}
                  type="password"
                  title="Contraseña"
                />
              )}
            />
          </motion.div>

          <motion.div
            variants={fadeIn("down", 1.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.6 }}
            className="text-right mb-4 mt-2"
          >
            <Link
              to="/forgot-password"
              style={{ fontSize: "13px" }}
              className="text-gray-500 hover:text-gray-900"
            >
              ¿Olvidaste tu contraseña?
            </Link>
          </motion.div>

          <motion.div
            variants={fadeIn("down", 1.5)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.6 }}
            className="text-center Logo_Login mt-8"
          >
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              style={{
                width: "30%",
                marginTop: "20px",
                display: "block",
                margin: "auto",
              }}
            >
              Aceptar
            </Button>
          </motion.div>
        </form>
      </div>
    </TemplateLogin>
  );
};

export default RenderLogin;
