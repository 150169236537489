import React, { useState } from "react";
import Template from "../../components/Template";

import ToDayBox from "../../components/ToDayBox";

import PlatosSoldOutComponente from "./ProductSoldOutComponente";

const PlatosSoldOut = (props) => {
  const [loadPage, setLoadPage] = useState(false);

  return (
    <Template title="Productos agotados">
      <ToDayBox open={loadPage} setOpen={setLoadPage}>
        <PlatosSoldOutComponente />
      </ToDayBox>
    </Template>
  );
};

export default PlatosSoldOut;
