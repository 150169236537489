import axios from "axios";
import { config } from "../config";

import { axiosHeader } from "../functions/axiosHeader";

const { baseurl } = config;

const uri = "quotation";

export const add_quotation = (data) => {
  const url = `${baseurl}/api/${uri}/create`;

  return axios
    .post(url, data, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const update_quotation = (data, id) => {
  const url = `${baseurl}/api/${uri}/update/${id}`;

  return axios
    .patch(url, data, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const delete_quotation = (id) => {
  const url = `${baseurl}/api/${uri}/delete/${id}`;

  return axios
    .delete(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_quotation = () => {
  const url = `${baseurl}/api/${uri}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_quotation_by_id = (id) => {
  const url = `${baseurl}/api/${uri}/searchCod/${id}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_type_contact_quotation = () => {
  const url = `${baseurl}/api/quotation/type-contact-quotation`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_event = () => {
  const url = `${baseurl}/api/quotation/event`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_type_event = () => {
  const url = `${baseurl}/api/quotation/type-event`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_tracking_by_id = (id) => {
  const url = `${baseurl}/api/${uri}/tracking/quotation/${id}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_phases = () => {
  const url = `${baseurl}/api/quotation/phases`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_action_phases = () => {
  const url = `${baseurl}/api/quotation/action-phases`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const add_tracking = (data) => {
  const url = `${baseurl}/api/${uri}/tracking/create`;

  return axios
    .post(url, data, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const delete_tracking = (id) => {
  const url = `${baseurl}/api/${uri}/tracking/delete/${id}`;

  return axios
    .delete(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const update_tracking = (data, id) => {
  const url = `${baseurl}/api/${uri}/tracking/update/${id}`;

  return axios
    .patch(url, data, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};
