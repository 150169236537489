import React from "react";
import { Calendar, dayjsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

const localizer = dayjsLocalizer(dayjs);

const timeZone = "America/Santiago";

dayjs.extend(utc);
dayjs.extend(timezone);

const CalendarTask = (props) => {
  const { events = [], onSelected, onView } = props;

  const dayPropGetter = (event) => {
    if (event.status_task === "done") {
      return {
        style: {
          backgroundColor: "#53d753",
          color: "#000",
        },
      };
    }

    return {
      style: {
        backgroundColor: "#ffa2a2",
        color: "#000",
      },
    };
  };

  return (
    <Calendar
      localizer={localizer}
      views={["month", "week", "day"]}
      defaultView="month"
      messages={{
        week: "Semana",
        work_week: "Semana de trabajo",
        day: "Día",
        month: "Mes",
        previous: "Atrás",
        next: "Después",
        today: "Hoy",
        agenda: "El Diario",
        showMore: (total) => `+${total} más`,
      }}
      events={events}
      eventPropGetter={(event) => dayPropGetter(event)}
      onSelectEvent={(event) => onView && onView(event.id_task, "view")}
      onSelectSlot={(slotInfo) => {
        onSelected &&
          onSelected(dayjs(slotInfo.start).tz(timeZone).toDate(), "add");
      }}
      selectable
      style={{ height: 500 }}
    />
  );
};

export default CalendarTask;
