import { Add, Search } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import InputDefault from "../../atomic/atoms/input.atoms";
import { useAppContext } from "./state.myTickets";

// import { Print } from "@mui/icons-material";
// import ReactExport from "react-data-export";
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const BenefitSearch = () => {
  const state = useAppContext();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      date_start: "",
      date_end: "",
      code: "",
      redeem: "",
    },
  });

  const onOpenModal = () => {
    state.handleModal(true);
    state.handleReset();
  };

  return (
    <form onSubmit={handleSubmit(state.handleSearch)} autoComplete="off">
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Controller
            name="code"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                fullWidth
                value={value}
                onChange={onChange}
                type="text"
                title={"Buscar por código"}
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            name="redeem"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                fullWidth
                options={[
                  {
                    value: true,
                    label: "Si",
                  },
                  {
                    value: false,
                    label: "No",
                  },
                ]}
                value={value}
                onChange={onChange}
                type="select"
                title={"Canjeado"}
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            name="date_start"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                fullWidth
                value={value}
                onChange={onChange}
                type="date"
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            name="date_end"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                fullWidth
                value={value}
                onChange={onChange}
                type="date"
              />
            )}
          />
        </Grid>
        <Grid item xs={1} style={{ textAlign: "left", display: "inline-flex" }}>
          <IconButton
            title="Buscar"
            type="submit"
            size="large"
            color="primary"
            style={{ background: "#eee", marginRight: "10px" }}
          >
            <Search />
          </IconButton>
          <IconButton
            title="Agregar"
            size="large"
            color="secondary"
            onClick={onOpenModal}
            style={{ background: "#eee", marginRight: "10px" }}
          >
            <Add />
          </IconButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default BenefitSearch;
