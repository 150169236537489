import { StyleSheet } from "@react-pdf/renderer";

export const styleContract = StyleSheet.create({
  page: {
    padding: "70px 80px",
    backgroundColor: "#fff",
    flexDirection: "row",
  },
  section: {
    margin: 0,
    padding: 0,
    flexGrow: 1,
  },
  watermark: {
    position: "absolute",
    zIndex: -1,
    width: "100%",
    height: "100%",
  },
  watermarkContainer: {
    position: "absolute",
    zIndex: -1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  text: {
    fontSize: 10,
    color: "#000 !important",
    textAlign: "justify",
    marginBottom: 18,
    lineHeight: 1.8,
    fontFamily: "Roboto",
    fontStyle: "normal",
    width: "auto",
  },
  textBold: {
    fontFamily: "Roboto",
    fontStyle: "bold",
    fontSize: 10,
    width: "auto",
    height: "auto",
    marginBottom: 5,
  },
  textTitle: {
    fontFamily: "Roboto",
    fontStyle: "bold",
    fontSize: 12,
    margin: "auto",
  },
  viewTextParagraph: {},
  table: {
    display: "table",
    width: "100%",
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    gap: 10,
  },
  tableCell: {
    margin: "auto",
    width: "50%",
    marginTop: 30,
    fontSize: 10,
    borderTopWidth: 1,
    borderStyle: "solid",
    borderColor: "#000",
    textAlign: "center",
    padding: 8,
    position: "relative",
  },
  tableCellSign: {
    margin: "auto",
    width: "50%",
    marginTop: 30,
    fontSize: 10,
    textAlign: "center",
    position: "relative",
  },
});
