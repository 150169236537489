/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import ContainerInput from "../../components/ContainerInput";
import Template from "../../components/Template";

import TableReserva from "../../components/Tables/TableReserva";

import LoadingPage from "../../components/LoadingPage";

import DialogEliminar from "./DialogEliminar";
import DialogReservar from "./DialogReservar";
import DialogViewData from "./DialogViewData";
import DialogViewDataEditar from "./DialogViewDataEditar";
import DialogViewDataForDay from "./DialogViewDataForDay";

import { checkToken } from "../../services/auth.services";

import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

import {
  Add,
  CalendarToday,
  OpenInNew,
  Print,
  Search,
  ViewList,
} from "@mui/icons-material/";

import {
  getTotCalendar,
  getTotDay,
  search_reserva,
  update_reserva,
} from "../../services/reservas.services";

import InputDefault from "../../atomic/atoms/input.atoms";
import { getUrlFront } from "../../functions/utilidades.function";
import { getUser } from "../../services/auth.services";
import DialogAddPerson from "./DialogAddPerson";
import DialogMenu from "./DialogMenu";
import DialogViewDataReminder from "./DialogViewDataReminder";
import DialogVisitor from "./DialogVisitor";

import dayjs from "dayjs";
import { utils, writeFile } from "xlsx";
import { formatMoney } from "../../functions/formatMoney";
import { formatPhoneNumber } from "../../functions/formatPhoneNumber";
import { nameCamarero } from "../../functions/nameCamarero";
import useSubsidiary from "../../hooks/useSubsidiary";
import { getv2_capacidad } from "../../services/capacidad.services";
import CalendarReservas from "./CalendarReservas";
import DialogCapacity from "./DialogCapacity";
import TotalesReservas from "./Components/total.reserva";
import useReservas from "./Components/date.reserva";
import DialogLeads from "./DialogLeads";

function formatDate(date) {
  if (!date) {
    return null;
  }

  let dFormat = date.split("T");

  return dFormat[0];
}

const fechaFormat = (fecha) => {
  return dayjs(fecha).format("DD-MM-YYYY");
};

function removeSpacesAndLowerCase(text) {
  if (text) {
    // Remove spaces
    const textWithoutSpaces = text.replace(/\s/g, "");

    // Convert to lowercase
    return textWithoutSpaces.toLowerCase();
  }
}

const Reservas = () => {
  const history = useHistory();

  const EXTERNAL_SELLER = 14;

  const isSellerExternal = getUser().id_perfil === EXTERNAL_SELLER;
  const isRCSDH = getUser().empresa.id_emp === 4;

  const COOKER = 2;

  const IS_READ_MODE = getUser()?.id_perfil === COOKER;

  const [dataReserva, setDataReserva] = useState({ load: false, data: [] });
  const [loadPage, setLoadPage] = useState(false);
  const [loadPageReservaToday, setLoadPageReservaToday] = useState(false);

  const [openReservar, setOpenReservar] = useState(false);
  const [openEliminarReserva, setOpenEliminarReserva] = useState(false);
  const [dateSelected, setDateSelected] = useState({
    fechareserva_reserva: "",
    telf_cliente: "",
    nombre_cliente: "",
    lead_id: "",
  });

  const [openViewData, setOpenViewData] = useState(false);
  const [dataViewData, setDataViewData] = useState("");
  const [valuesCapacity, setValuesCapacity] = useState(false);

  const [openViewDataEditar, setOpenViewDataEditar] = useState(false);
  const [dataViewDataEditar, setDataViewDataEditar] = useState("");

  const [openViewDataReminder, setOpenViewDataReminder] = useState(false);
  const [dataViewDataReminder, setDataViewDataReminder] = useState("");

  const [openMenu, setOpenMenu] = useState(false);
  const [dataMenu, setDataMenu] = useState("");

  const [events, setEvents] = useState([]);

  const [openLeads, setOpenLeads] = useState(false);

  const [openVisitor, setOpenVisitor] = useState(false);
  const [openCapacity, setOpenCapacity] = useState(false);

  const [dataAddPersons, setDataAddPersons] = useState({
    data: [],
    open: false,
  });

  const [dataViewEliminarData, setDataViewEliminarData] = useState("");

  const [openViewDataByDate, setOpenViewDataByDate] = useState(false);
  const [dataViewDataByDate, setDataViewDataByDate] = useState([]);
  const [dataViewDataTotals, setDataViewDataTotals] = useState([]);
  const [dataDaysActived, setDataDaysActived] = useState([]);

  const [typeView, setTypeView] = useState("calendar");

  const { fechaInicio, fechaFin, formatearFecha } = useReservas();

  const [searchReserva, setSearchReserva] = useState({
    texto: "",
    estado: "todos",
    fecha1: formatearFecha(fechaInicio),
    fecha2: formatearFecha(fechaFin),
  });

  const [subsidiary, setSubsidiary] = useState(getUser()?.empresa?.id_empsede);
  const subsidaries = useSubsidiary(getUser()?.empresa?.id_emp);

  const dataSubsidary = useCallback(() => {
    return subsidaries.data.find(
      (el) => Number(el.id_empsede) === Number(subsidiary)
    );
  }, [subsidiary, subsidaries]);

  const nameSubsidiary = dataSubsidary()?.alias_empsede
    ? dataSubsidary()?.alias_empsede.split("_").pop()
    : getUser()?.empresa?.alias_empsede.split("_").pop();

  const nameSubsidiaryOriginal =
    dataSubsidary()?.alias_empsede || getUser()?.empresa?.alias_empsede;

  const nameEmp = dataSubsidary()?.nom_emp || getUser()?.empresa?.nom_emp;
  const nameEmpFormatted = removeSpacesAndLowerCase(nameEmp);
  const idEmSede =
    dataSubsidary()?.id_empsede || getUser()?.empresa?.id_empsede;

  const NAME_COMPANY =
    dataSubsidary()?.alias_empsede || getUser()?.empresa?.nom_emp;
  const NOW_DATE = new Date().toISOString().slice(0, 10);

  const DATE =
    searchReserva.fecha1 && searchReserva.fecha2
      ? `(${searchReserva.fecha1}/${searchReserva.fecha2})`
      : `(${NOW_DATE})`;

  const camarero = nameCamarero();

  const handlePrint = () => {
    const data = dataReserva.data.map((row) => ({
      Código: row.id_reserva,
      "Fecha reserva": fechaFormat(formatDate(row.fechareserva_reserva)),
      Estado: row.estado_reserva.toUpperCase(),
      "Nombre Completo": `${row.nombre_reserva} ${row.apellido_paterno_reserva} ${row.apellido_materno_reserva}`,
      "Nro Mesa": row.codigo_mesa ? row.codigo_mesa : "-",
      [camarero]: row.usu_garzon ? row.usu_garzon.toUpperCase() : "-",
      "Monto reserva": formatMoney(row.monto_reserva ? row.monto_reserva : 0),
      Teléfono: formatPhoneNumber(row.telefono_reserva),
      Email: row.correo_reserva,
      Dirección: `${row.region} ${row.comunas}`,
      PAX: row.pax_reserva,
      Programa: row.pograma_reserva.toUpperCase(),
      Vendedor: row.usu_nombre,
      Categoría: row?.clasificacion_cliente || "Sin clasificación",
      "Fecha registro": fechaFormat(row.fecharegistro_reserva),
    }));

    let wb = utils.book_new();
    let ws = utils.json_to_sheet(data);
    utils.book_append_sheet(wb, ws, `RESERVA`);
    writeFile(wb, `${NAME_COMPANY || "RESERVA"}-${DATE}.xlsx`);
  };

  const handleObtain_reserva = async (id) => {
    setLoadPage(true);

    setDataReserva({
      ...dataReserva,
      load: true,
    });

    try {
      const newResponse = [];
      const response = await search_reserva(searchReserva, id);

      if (isSellerExternal) {
        response.map((el) => {
          if (Number(el.id_usuario) === getUser().id) {
            newResponse.push(el);
          }
        });
      } else {
        newResponse.push(...response);
      }

      setDataReserva({
        ...dataReserva,
        load: false,
        data: newResponse,
      });

      setLoadPage(false);
    } catch (err) {
      setLoadPage(false);
      console.log(err);
    }
  };

  const handleSearch_reserva = async (e) => {
    e.preventDefault();

    setLoadPage(true);

    setDataReserva({
      ...dataReserva,
      load: true,
    });

    try {
      const newResponse = [];
      const response = await search_reserva(searchReserva, idEmSede);

      if (isSellerExternal) {
        response.map((el) => {
          if (Number(el.id_usuario) === getUser().id) {
            newResponse.push(el);
          }
        });
      } else {
        newResponse.push(...response);
      }

      setDataReserva({
        ...dataReserva,
        load: false,
        data: newResponse,
      });

      setLoadPage(false);
    } catch (err) {
      setLoadPage(false);
    }
  };

  const toogleTypeView = () => {
    if (typeView === "table") {
      setTypeView("calendar");
    } else {
      setTypeView("table");
    }
  };

  /* VIEW */

  const handleSelectedLeadToReserva = (lead) => {
    setDateSelected({
      ...dateSelected,
      telf_cliente: lead?.contact?.phoneNumber.substring(3),
      nombre_cliente: lead?.contact?.name,
      lead_id: lead?.leadId,
    });

    setOpenReservar(true);
  };

  const resetInitialValues = () => {
    setDateSelected({
      ...dateSelected,
      telf_cliente: "",
      nombre_cliente: "",
      lead_id: "",
    });
  };

  const handleAddPersons = (data) => {
    setDataAddPersons({
      data,
      open: true,
    });
  };

  const handleViewDataEditar = (data) => {
    setOpenViewDataEditar(true);
    setDataViewDataEditar(data);
  };

  const handleViewData = (data) => {
    setOpenViewData(true);
    setDataViewData(data);
  };

  const handleViewDataReminder = (data) => {
    setOpenViewDataReminder(true);
    setDataViewDataReminder(data);
  };

  const handleAddMenu = (data) => {
    setOpenMenu(true);
    setDataMenu(data);
  };

  const handleViewDataEliminar = (data) => {
    setOpenEliminarReserva(true);
    setDataViewEliminarData(data);
  };

  const handleChangeStatus = async (id, status) => {
    setLoadPage(true);
    let json = {
      id,
      status,
    };

    try {
      await update_reserva(json);

      await handleObtain_reserva(idEmSede);

      setLoadPage(false);
    } catch (err) {
      console.log(err);
      setLoadPage(false);
    }
  };

  const getCalendar = async (subsidiary) => {
    try {
      const response = await getTotCalendar(subsidiary);
      setEvents(response);
    } catch (err) {}
  };

  const handleOpenCapacity = (data) => {
    setOpenCapacity(true);

    setValuesCapacity({
      idCapacity: data.idCapacity,
      date: data.date,
      quantityCapacity: data.quantityCapacity,
    });
  };

  const getDataDaysActived = async (id) => {
    try {
      const response = await getv2_capacidad(id);
      setDataDaysActived(response);
    } catch (err) {}
  };

  const handleGetDateToday = async (date) => {
    setDateSelected(date);

    setLoadPageReservaToday(true);

    try {
      const respsonse = await getTotDay(
        {
          fecha: date,
        },
        idEmSede
      );

      setDataViewDataTotals(respsonse);

      const newResponse = [];

      if (isSellerExternal) {
        respsonse.map((el) => {
          if (Number(el.id_usuario) === getUser().id) {
            newResponse.push(el);
          }
        });
      } else {
        newResponse.push(...respsonse);
      }

      setDataViewDataByDate(newResponse);

      setLoadPageReservaToday(false);
    } catch (err) {
      setLoadPageReservaToday(false);
    }
  };

  const handleRefreshCapacity = async () => {
    await getCalendar(idEmSede);
    await getDataDaysActived(idEmSede);
  };

  const linkReserva =
    nameEmpFormatted === "verdemostaza"
      ? `${getUrlFront()}/reservar/${nameEmpFormatted}?name=${nameSubsidiary}`
      : `${getUrlFront()}/reservar/${nameEmpFormatted}/${nameSubsidiaryOriginal}`;

  useEffect(() => {
    getCalendar(idEmSede);
    getDataDaysActived(idEmSede);
  }, []);

  useEffect(() => {
    if (!openReservar) {
      handleObtain_reserva(idEmSede);
      getCalendar(idEmSede);
    }
  }, [openReservar]);

  //CHECK TOKEN
  useEffect(() => {
    if (!checkToken()) {
      history.push("/");
      return null;
    }
  }, []);

  return (
    <Template title="Reservas">
      <LoadingPage open={loadPage} setOpen={setLoadPage} />

      <Grid container spacing={2} style={{ marginBottom: 20 }}>
        <Grid
          item
          xs={12}
          sm={8}
          lg={8}
          style={{
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          {!isSellerExternal && !IS_READ_MODE && (
            <>
              <InputDefault
                title={"Link de reserva"}
                value={linkReserva}
                style={{
                  width: "50%",
                }}
              />
              <IconButton
                style={{ marginLeft: 5 }}
                color="warning"
                onClick={() => window.open(linkReserva, "_blank")}
              >
                <OpenInNew />
              </IconButton>
              <IconButton size="large" color="primary" onClick={handlePrint}>
                <Print />
              </IconButton>
            </>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          lg={4}
          style={{
            textAlign: "right",
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          {!IS_READ_MODE && (
            <Button
              style={{
                marginLeft: 10,
              }}
              onClick={() => setOpenReservar(true)}
              className="button-default"
            >
              <Add />
            </Button>
          )}

          <Button
            style={{
              marginLeft: 10,
            }}
            onClick={() => toogleTypeView()}
            className="button-default"
          >
            {typeView === "table" ? <CalendarToday /> : <ViewList />}
          </Button>

          {isRCSDH && (
            <Button
              style={{
                marginLeft: 10,
              }}
              onClick={() => setOpenLeads(true)}
              className="button-default"
            >
              LEADS
            </Button>
          )}
        </Grid>
      </Grid>

      {typeView === "table" && (
        <form onSubmit={handleSearch_reserva} autoComplete="off">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4} lg={4}>
              <TextField
                defaultValue={searchReserva.texto}
                fullWidth
                name="texto"
                label="Buscar por nombre..."
                style={{
                  width: "100%",
                }}
                className={`InputDefault`}
                onChange={(e) =>
                  setSearchReserva({
                    ...searchReserva,
                    texto: e.target.value,
                  })
                }
                InputProps={{
                  type: "search",
                  startAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2} lg={2}>
              <TextField
                name="estado"
                variant="outlined"
                select
                fullWidth
                className={`InputDefault`}
                defaultValue={searchReserva.estado}
                onChange={(e) =>
                  setSearchReserva({
                    ...searchReserva,
                    estado: e.target.value,
                  })
                }
                SelectProps={{
                  native: true,
                }}
              >
                <option value="todos">Todos</option>
                <option value={"pendiente"}>Pendiente</option>
                <option value={"en espera"}>En espera</option>
                <option value={"cancelado"}>Cancelado</option>
                <option value={"confirmado"}>Confirmado</option>
                {/* <option value={"re confirmado"}>Re confirmado</option> */}
              </TextField>
            </Grid>
            <Grid item xs={5} sm={2} lg={2}>
              <TextField
                required
                value={searchReserva.fecha1}
                type="date"
                fullWidth
                name="fecha1"
                // label="Fecha inicio"
                className={`InputDefault`}
                onChange={(e) =>
                  setSearchReserva({
                    ...searchReserva,
                    fecha1: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={5} sm={2} lg={2}>
              <TextField
                required
                defaultValue={searchReserva.fecha2}
                type="date"
                fullWidth
                name="fecha2"
                // label="Fecha final"
                className={`InputDefault`}
                onChange={(e) =>
                  setSearchReserva({
                    ...searchReserva,
                    fecha2: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={2} sm={1} lg={1} style={{ textAlign: "left" }}>
              <IconButton
                fullWidth
                type="submit"
                aria-label="search"
                size="medium"
                style={{ marginLeft: "0px" }}
              >
                <Search fontSize="inherit" />
              </IconButton>
            </Grid>
          </Grid>
        </form>
      )}

      {/**
       * SOLO PARA VENDEDORES Y ADMINISTRADORES
       */}
      {getUser().id_perfil !== 7 &&
        !isSellerExternal &&
        typeView === "table" && <TotalesReservas dataReserva={dataReserva} />}

      <ContainerInput
        style={{
          marginTop: 20,
          marginBottom: 20,
          padding: "0px",
          textAlign: "center",
        }}
      >
        {typeView === "table" ? (
          <TableReserva
            isReadMode={IS_READ_MODE}
            rows={dataReserva.data}
            handleViewData={handleViewData}
            handleViewDataEditar={handleViewDataEditar}
            handleViewDataEliminar={handleViewDataEliminar}
            handleAddPersons={handleAddPersons}
            handleViewDataReminder={handleViewDataReminder}
            handleAddMenu={handleAddMenu}
          />
        ) : (
          <div
            style={{
              padding: "10px 10px",
            }}
          >
            <CalendarReservas
              isSellerExternal={isSellerExternal}
              handleOpenCapacity={handleOpenCapacity}
              setOpenViewDataByDate={setOpenViewDataByDate}
              handleGetDateToday={handleGetDateToday}
              handleonDateSelected={handleGetDateToday}
              daysActivated={dataDaysActived}
              events={events}
            />
          </div>
        )}
      </ContainerInput>

      <DialogViewDataForDay
        open={openViewDataByDate}
        setOpen={setOpenViewDataByDate}
        isLoading={loadPageReservaToday}
        dataViewDataTotals={dataViewDataTotals}
        dataViewDataByDate={dataViewDataByDate}
        dateSelected={dateSelected}
        dataCapacity={dataDaysActived}
        TableComponent={(el) => (
          <TableReserva
            isReadMode={IS_READ_MODE}
            rows={el.data}
            handleViewData={handleViewData}
            handleViewDataEditar={handleViewDataEditar}
            handleViewDataEliminar={handleViewDataEliminar}
            handleAddPersons={handleAddPersons}
            handleViewDataReminder={handleViewDataReminder}
            handleAddMenu={handleAddMenu}
          />
        )}
      />

      <DialogReservar
        idEmpSede={idEmSede}
        initialValues={dateSelected}
        resetInitialValues={resetInitialValues}
        open={openReservar}
        setOpen={setOpenReservar}
        callBack={() => {
          handleObtain_reserva(idEmSede);
          getCalendar(idEmSede);

          if (dateSelected?.fechareserva_reserva) {
            handleGetDateToday(dateSelected?.fechareserva_reserva);
          }
        }}
      />

      <DialogCapacity
        open={openCapacity}
        setOpen={setOpenCapacity}
        initialValues={valuesCapacity}
        callBack={handleRefreshCapacity}
        idEmpSede={idEmSede}
      />

      <DialogEliminar
        open={openEliminarReserva}
        setOpen={setOpenEliminarReserva}
        data={dataViewEliminarData}
        handleChangeStatus={handleChangeStatus}
        handleReload={() => {
          handleObtain_reserva(idEmSede);
          getCalendar(idEmSede);

          if (dateSelected?.fechareserva_reserva) {
            handleGetDateToday(dateSelected?.fechareserva_reserva);
          }
        }}
      />

      <DialogViewDataEditar
        open={openViewDataEditar}
        setOpen={setOpenViewDataEditar}
        data={dataViewDataEditar}
        handleChangeStatus={handleChangeStatus}
        handleReload={() => {
          handleObtain_reserva(idEmSede);
          getCalendar(idEmSede);

          if (dateSelected?.fechareserva_reserva) {
            handleGetDateToday(dateSelected?.fechareserva_reserva);
          }
        }}
      />

      <DialogViewData
        open={openViewData}
        setOpen={setOpenViewData}
        data={dataViewData}
        handleChangeStatus={handleChangeStatus}
        handleReload={() => {
          handleObtain_reserva(idEmSede);
          getCalendar(idEmSede);

          handleGetDateToday(dateSelected);
        }}
      />

      <DialogViewDataReminder
        open={openViewDataReminder}
        setOpen={setOpenViewDataReminder}
        data={dataViewDataReminder}
      />

      <DialogMenu open={openMenu} setOpen={setOpenMenu} data={dataMenu} />

      <DialogAddPerson
        open={dataAddPersons.open}
        setOpen={(el) => setDataAddPersons({ ...dataAddPersons, open: el })}
        data={dataAddPersons.data}
      />

      <DialogVisitor open={openVisitor} setOpen={setOpenVisitor} />

      <DialogLeads
        open={openLeads}
        setOpen={setOpenLeads}
        onSelectLead={handleSelectedLeadToReserva}
      />
    </Template>
  );
};

export default Reservas;
