import {
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

import ContainerInput from "../../../components/ContainerInput";

import InputDefault from "../../../atomic/atoms/input.atoms";
import NotFound from "../../../atomic/organisms/NotFound.organisms";
import { formatMoney } from "../../../functions/formatMoney";

const ItemRow = (props) => {
  let { idx, el, handleAddProduct, handleDeleteProduct, amount } = props;

  const onChange = (e) => {
    let { value } = e.target;

    if (value === 0) {
      handleDeleteProduct(el);
    } else {
      handleAddProduct(el, value);
    }
  };

  return (
    <TableRow
      key={idx}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell align="left">
        <Typography
          component="h5"
          style={{
            color: "#008001",
            fontWeight: "600",
            lineHeight: "inherit",
          }}
        >
          {el.nombre_plato}
        </Typography>
        <Typography
          component="label"
          style={{
            color: "red",
            fontWeight: "400",
            lineHeight: "inherit",
            fontSize: "12px",
          }}
        >
          {el.nombre_categoriapla}
        </Typography>{" "}
        <br />
        <br />
        {el.descripcion_plato && (
          <>
            <Typography variant="inherit" component="p">
              {el.descripcion_plato}
            </Typography>
            <br />
          </>
        )}
        <strong style={{ fontSize: 15 }}>{formatMoney(el.precio_plato)}</strong>
      </TableCell>

      <TableCell align="right">
        <InputDefault
          loading={false}
          value={amount || 0}
          style={{ width: 100 }}
          onChange={onChange}
          type="select"
          title={"Cantidad"}
          options={[
            { value: 0, label: 0 },
            { value: 1, label: 1 },
            { value: 2, label: 2 },
            { value: 3, label: 3 },
            { value: 4, label: 4 },
            { value: 5, label: 5 },
            { value: 6, label: 6 },
            { value: 7, label: 7 },
            { value: 8, label: 8 },
            { value: 9, label: 9 },
            { value: 10, label: 10 },
            { value: 12, label: 12 },
            { value: 13, label: 13 },
            { value: 14, label: 14 },
            { value: 15, label: 15 },
            { value: 16, label: 16 },
            { value: 17, label: 17 },
            { value: 18, label: 18 },
            { value: 19, label: 19 },
            { value: 20, label: 20 },
            { value: 21, label: 21 },
          ]}
        />
      </TableCell>
    </TableRow>
  );
};

const ProductMenuReserva = (props) => {
  let { data } = props.data;

  let {
    loading,
    dataPlatoReserva,
    setDataPlatoReserva,
    onAddProduct,
    onDeleteProduct,
  } = props;

  const handleAddProduct = (product, count) => {
    const filterIdx = dataPlatoReserva.findIndex(
      (item) => Number(item.id_plato) === Number(product.id_plato)
    );

    const newProduct = {
      id_plato: product?.id_plato,
      id_categoriapla: product?.id_categoriapla,
      amount_resrvplato: Number(count),
    };

    if (filterIdx >= 0) {
      let newData = dataPlatoReserva.filter(
        (item) => Number(item.id_plato) !== Number(product.id_plato)
      );

      setDataPlatoReserva([...newData, newProduct]);

      onAddProduct([...newData, newProduct]);
    } else {
      setDataPlatoReserva([...dataPlatoReserva, newProduct]);

      onAddProduct([...dataPlatoReserva, newProduct]);
    }
  };

  const handleDeleteProduct = (product) => {
    let newData = dataPlatoReserva.filter(
      (item) => Number(item.id_plato) !== Number(product.id_plato)
    );

    setDataPlatoReserva(newData);

    onDeleteProduct(newData);
  };

  if (loading && data.length === 0) {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Skeleton animation="wave" height={80} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Skeleton animation="wave" height={80} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Skeleton animation="wave" height={80} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Skeleton animation="wave" height={80} />
        </Grid>
      </Grid>
    );
  }

  if (data.length === 0) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={1}
        style={{ marginTop: 10 }}
      >
        <NotFound title="No se encontró registros, debes de seleccionar una categoría" />
      </Grid>
    );
  }

  return (
    <ContainerInput
      style={{
        marginTop: 10,
        marginBottom: 20,
        padding: "15px 10px",
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={1}
        style={{ marginTop: 10 }}
      >
        <Table aria-label="simple table">
          <TableBody>
            {data.map((el, idx) => (
              <ItemRow
                key={idx}
                el={el}
                idx={idx}
                handleAddProduct={handleAddProduct}
                handleDeleteProduct={handleDeleteProduct}
                amount={
                  dataPlatoReserva.find(
                    (item) => Number(item.id_plato) === Number(el.id_plato)
                  )?.amount_resrvplato || 0
                }
              />
            ))}
          </TableBody>
        </Table>
      </Grid>
    </ContainerInput>
  );
};

export default ProductMenuReserva;
