import { getToken, getUser } from "../services/auth.services";

export const axiosHeader = (params) => {
  const token = getToken();
  const companyRoot =
    getUser()?.id_perfil === 16 &&
    JSON.stringify({
      id_emp: getUser()?.empresa?.id_emp,
      id_empsede: getUser()?.empresa?.id_empsede,
    });

  return {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      company_root: companyRoot,
      ...params,
    },
  };
};
