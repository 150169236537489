import React, { useEffect } from "react";

import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import InputDefault from "../../atomic/atoms/input.atoms";

import { Controller, useForm } from "react-hook-form";
import {
  add_quotation,
  get_event,
  get_type_contact_quotation,
  get_type_event,
  update_quotation,
} from "../../services/quotation.services";

import dayjs from "dayjs";
import { toast } from "react-hot-toast";
import {
  getComunas,
  viaContactQuotationData,
} from "../../functions/utilidades.function";
import { getUser } from "../../services/auth.services";
import { get_group } from "../../services/reservas.services";
import { get_sucursales_by_id } from "../../services/sucursal.services";

const initalValues = {
  idQuotation: null,
  idReservaGroup: null,
  phoneQuotationCustomer: "",
  codQuotation: "",
  nameQuotation: "",
  lastNameQuotation: "",
  nameCompanyQuotation: "",
  emailQuotation: "",
  phoneQuotation: "",
  amountPeopleQuotation: 0,
  dateEventQuotation: "",
  nameSubsidiary: "",
  idTypeContact: 0,
  idTypeEvent: 0,
  idEvent: 0,
  viaContactQuotation: "",
  regionContactQuotation: "",
  comunaContactQuotation: "",
  urlFileDriveQuotation: "",
  nameBoyfriendQuotation: "",
  nameGirlfriendQuotation: "",
};

const ModalQuotation = (props) => {
  const { open, setOpen, selected, setSelected, callBack } = props;

  const { control, handleSubmit, watch, reset, setValue } = useForm({
    defaultValues: initalValues,
  });

  const dataRegionComunas = getComunas();

  const [typeContact, setTypeContact] = React.useState([]);
  const [typeEvent, setTypeEvent] = React.useState([]);
  const [event, setEvent] = React.useState([]);
  const [region, setRegion] = React.useState([]);
  const [comuna, setComuna] = React.useState([]);
  const [dataGroup, setDataGroup] = React.useState([]);
  const [dataSucursales, setDataSucursales] = React.useState([]);

  const [load, setLoad] = React.useState(false);

  const IS_DH_RCS_ID = getUser()?.empresa?.id_emp === 4;

  const onAction = async (data) => {
    try {
      setLoad(true);

      if (data.idQuotation) {
        await update_quotation(data, data.idQuotation);

        await callBack();

        toast.success("Se modifico la cotización correctamente", {
          position: "top-center",
        });

        setLoad(false);
        setOpen(false);

        return;
      }

      await add_quotation(data);

      await callBack();

      toast.success("Se creo la cotización correctamente", {
        position: "top-center",
      });

      setLoad(false);
      setOpen(false);
    } catch (err) {
      console.log(err);

      setLoad(false);
    }
  };

  const fetchDataEvent = async () => {
    const response = await get_event();

    const newResponse = response.map((el) => ({
      value: el.id_event,
      label: el.name_event,
    }));

    setEvent(newResponse);
  };

  const fetchDataRegion = async () => {
    const newResponse = dataRegionComunas.map((el) => ({
      value: el.region,
      label: el.region,
    }));

    setRegion(newResponse);
    setComuna([]);
  };

  useEffect(() => {
    const handleFetchGroup = async () => {
      try {
        const response = await get_group();

        setDataGroup(
          response.map((el) => ({
            value: el.id_rservagrupa,
            label: el.name_rservagrupa,
          }))
        );
      } catch (err) {}
    };

    handleFetchGroup();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await get_type_event();
      const newResponse = response
        .filter((el) => el.id_event === Number(watch("idEvent")))
        .map((el) => ({
          value: el.id_typeevent,
          label: el.name_typeevent,
        }));

      setTypeEvent(newResponse);

      newResponse.length === 0 && setValue("idTypeEvent", null);
    };

    if (watch("idEvent") > 0) {
      fetchData();
    }
  }, [watch("idEvent")]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await get_type_contact_quotation();
      const newResponse = response
        .filter((el) => el.id_event === Number(watch("idEvent")))
        .map((el) => ({
          value: el.id_typecontact,
          label: el.name_typecontact,
        }));

      setTypeContact(newResponse);

      newResponse.length === 0 && setValue("idTypeContact", null);
    };

    if (watch("idEvent") > 0) {
      fetchData();
    }
  }, [watch("idEvent")]);

  useEffect(() => {
    const fetchData = async () => {
      const newResponse =
        dataRegionComunas
          .find((el) => el.region === watch("regionContactQuotation"))
          ?.comunas.map((el) => ({
            value: el,
            label: el,
          })) || [];

      setComuna(newResponse);

      newResponse.length === 0 && setValue("comunaContactQuotation", []);
    };

    if (watch("regionContactQuotation") !== "") {
      fetchData();
    }
  }, [watch("regionContactQuotation")]);

  useEffect(() => {
    const getSucursales = async () => {
      try {
        const data = JSON.parse(localStorage.getItem("user_vm"));
        const id_empresa = data.empresa.id_emp;

        const response = await get_sucursales_by_id(id_empresa);

        setDataSucursales(
          response.map((item) => ({
            value: item.nom_empsede,
            label: item.nom_empsede,
          }))
        );
      } catch (err) {
        console.log(err.message);
      }
    };

    getSucursales();
  }, []);

  useEffect(() => {
    if (open) {
      fetchDataEvent();
      fetchDataRegion();

      if (selected?.id_quotation && selected?.id_quotation > 0) {
        reset({
          ...initalValues,
          idQuotation: Number(selected.id_quotation),
          idReservaGroup: Number(selected.id_rservagrupa),
          phoneQuotationCustomer: selected.phone_quotation_customer,
          codQuotation: selected.cod_quotation,
          nameQuotation: selected.name_quotation,
          lastNameQuotation: selected.lastname_quotation,
          nameCompanyQuotation: selected.namecompany_quotation,
          emailQuotation: selected.email_quotation,
          phoneQuotation: selected.phone_quotation,
          amountPeopleQuotation: selected.amountpeople_quotation,
          dateEventQuotation: dayjs(selected.date_quotation).format(
            "YYYY-MM-DD HH:mm"
          ),
          nameSubsidiary: selected.nameSubsidiary_quotation,
          idTypeContact: Number(selected.id_typecontact),
          idTypeEvent: Number(selected.id_typeevent),
          idEvent: Number(selected.id_event),
          viaContactQuotation: selected.viacontact_quotation,
          urlFileDriveQuotation: selected.urlfiledrive_quotation,
          nameBoyfriendQuotation: selected.nameboyfriend_quotation,
          nameGirlfriendQuotation: selected.namegirlfriend_quotation,
          regionContactQuotation: selected.regioncontact_quotation,
          comunaContactQuotation: selected.comunacontact_quotation,
          eventHoursQuotation: selected.eventhours_quotation,
        });
      }
    } else {
      setTypeEvent([]);
      setTypeContact([]);
      setSelected(null);
      reset(initalValues);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth={"sm"}
      aria-describedby="dialog-benefit"
    >
      <form onSubmit={handleSubmit(onAction)} autoComplete="off">
        <DialogTitle>
          {watch("idQuotation") ? "Editar" : "Agregar"} cotización
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={`${load && "animate-pulse"} `}>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              Datos del evento
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="idEvent"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    required
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"Evento"}
                    type={"select"}
                    options={event}
                  />
                )}
              />
            </Grid>

            {typeEvent.length > 0 && (
              <Grid item xs={12} sm={6}>
                <Controller
                  name="idTypeEvent"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputDefault
                      required
                      loading={false}
                      fullWidth
                      value={value}
                      onChange={onChange}
                      title={"Tipo de evento"}
                      type={"select"}
                      options={typeEvent}
                    />
                  )}
                />
              </Grid>
            )}
            {/**
             * MATRIMONIO
             */}
            {Number(watch("idEvent")) === 1 && (
              <>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="nameBoyfriendQuotation"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputDefault
                        loading={false}
                        fullWidth
                        value={value}
                        onChange={onChange}
                        title={"Nombre novio"}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="nameGirlfriendQuotation"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputDefault
                        loading={false}
                        fullWidth
                        value={value}
                        onChange={onChange}
                        title={"Nombre novia"}
                      />
                    )}
                  />
                </Grid>
              </>
            )}

            {/**
             * CENA CORPORATIVA Y GRADUACIÓN
             */}
            {(Number(watch("idEvent")) === 2 ||
              Number(watch("idEvent")) === 3) && (
              <Grid item xs={12} sm={12}>
                <Controller
                  name="nameCompanyQuotation"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputDefault
                      required
                      loading={false}
                      fullWidth
                      value={value}
                      onChange={onChange}
                      title={"Nombre colegio/empresa"}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <Controller
                name="amountPeopleQuotation"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    required
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"Cantidad de personas"}
                    type={"number"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="dateEventQuotation"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    required
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"Fecha de evento"}
                    type={"date"}
                  />
                )}
              />
            </Grid>

            {/* <Grid item xs={12} sm={6}>
              <Controller
                name="nameSubsidiary"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    required
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"Salón de evento"}
                    type={"select"}
                    options={dataSucursales}
                  />
                )}
              />
            </Grid> */}
            {IS_DH_RCS_ID && (
              <>
                <Grid item xs={12} sm={12}>
                  Datos de cliente
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Controller
                    name="idReservaGroup"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputDefault
                        required
                        loading={false}
                        fullWidth
                        value={value}
                        onChange={onChange}
                        title={"Segmentación"}
                        type={"select"}
                        options={dataGroup}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Controller
                    name="phoneQuotationCustomer"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputDefault
                        loading={false}
                        fullWidth
                        value={value}
                        onChange={onChange}
                        title={"Teléfono"}
                      />
                    )}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12} sm={12}>
              Datos de contacto
            </Grid>
            {typeContact.length > 0 && (
              <>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="idTypeContact"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputDefault
                        required
                        loading={false}
                        fullWidth
                        value={value}
                        onChange={onChange}
                        title={"Tipo de contácto"}
                        type={"select"}
                        options={typeContact}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}></Grid>
              </>
            )}
            <Grid item xs={12} sm={6}>
              <Controller
                name="nameQuotation"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    required
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"Nombres"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="lastNameQuotation"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    required
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"Apellidos"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="emailQuotation"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    required
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"E-mail"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="phoneQuotation"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    required
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"Teléfono"}
                  />
                )}
              />
            </Grid>

            {/* <Grid item xs={12} sm={6}>
              <Controller
                name="regionContactQuotation"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    required
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"Región"}
                    type={"select"}
                    options={region}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="comunaContactQuotation"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    required
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"Comuna"}
                    type={"select"}
                    options={comuna}
                  />
                )}
              />
            </Grid> */}

            <Grid item xs={12} sm={12}>
              Extras (Opcional)
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="viaContactQuotation"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"Vía de contacto"}
                    type={"select"}
                    options={viaContactQuotationData}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controller
                name="urlFileDriveQuotation"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"URL Drive"}
                    type={"text"}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={load}
            color="inherit"
            variant="text"
            onClick={() => {
              setOpen(false);
            }}
          >
            Salir
          </Button>
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            disabled={load}
          >
            Agregar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default ModalQuotation;
