/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Template from "../../components/Template";

import { checkToken } from "../../services/auth.services";
import {
  change_status_product,
  get_pedido_cocinero,
} from "../../services/pedidos.services";

import { getUser } from "../../services/auth.services";

import ContainerInput from "../../components/ContainerInput";
import LoadingPage from "../../components/LoadingPage";
import CardProduct from "./Components/CardProduct";

import { Grid } from "@mui/material";

import DialogConfirm from "./Components/DialogConfirm";

import "./style.cookordes.scss";

import {
  ArrowBack,
  ArrowForward,
  ProductionQuantityLimits,
} from "@mui/icons-material";
import { Fab } from "@mui/material";
import toast from "react-hot-toast";
import NotFound from "../../atomic/organisms/NotFound.organisms";

import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/lab";
import DialogAddProductAll from "./Components/DialogAddProductAll";
import DialogRequestPassword from "./Components/DialogRequestPassword";

const OtherCook = (props) => {
  const { dataPedido, handleChangeStatus } = props;

  return (
    <ContainerInput
      style={{
        marginTop: 20,
        marginBottom: 20,
        padding: "20px 10px",
        width: "100%",
        overflow: "auto",
      }}
    >
      <div className="Grid-container-Pedidos">
        {dataPedido.data.length === 0 ? (
          <div style={{ textAlign: "center" }}>
            <NotFound texto="No se encontró registros." />
          </div>
        ) : (
          <div style={{ display: "inline-flex" }}>
            {dataPedido.data.map((el, idx) => (
              <div
                key={idx}
                className="GridItemPedido"
                style={{ width: 350, marginRight: "15px" }}
              >
                <CardProduct
                  data={el}
                  handleChangeStatus={handleChangeStatus}
                  timeMax={15}
                />
              </div>
            ))}
          </div>
        )}
      </div>
      {dataPedido.data.length > 0 && (
        <div style={{ margin: "auto", width: 200 }}>
          <ArrowBack style={{ verticalAlign: "middle" }} /> <b>Deslizar</b>
          <ArrowForward style={{ verticalAlign: "middle" }} />
        </div>
      )}
    </ContainerInput>
  );
};

const Cook = (props) => {
  const { dataPedido, dataPedidoEntrada, disabledPedidos, handleChangeStatus } =
    props;

  return (
    <Grid container spacing={1}>
      <Grid item xs={5}>
        <div style={{ marginBottom: 10 }}>
          <h4 style={{ margin: 0, padding: 0 }}>Entrada</h4>
        </div>
        <ContainerInput
          style={{
            marginTop: 20,
            marginBottom: 20,
            padding: "20px 10px",
            width: "100%",
            overflow: "auto",
          }}
        >
          <div className="Grid-container-Pedidos">
            {dataPedidoEntrada.data.length === 0 ? (
              <div style={{ textAlign: "center" }}>
                <NotFound texto="No se encontró registros." />
              </div>
            ) : (
              <div style={{ display: "inline-flex" }}>
                {dataPedidoEntrada.data.map((el, idx) => {
                  return (
                    <div
                      key={idx}
                      className="GridItemPedido"
                      style={{ width: 300, marginRight: "15px" }}
                    >
                      <CardProduct
                        type={"Entradas"}
                        data={el}
                        handleChangeStatus={handleChangeStatus}
                        disabledButton={false}
                        timeMax={15}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </ContainerInput>
        {dataPedido.data.length > 0 && (
          <div style={{ margin: "auto", width: 200 }}>
            <ArrowBack style={{ verticalAlign: "middle" }} /> <b>Deslizar</b>
            <ArrowForward style={{ verticalAlign: "middle" }} />
          </div>
        )}
      </Grid>
      <Grid item xs={7}>
        <div style={{ marginBottom: 10 }}>
          <h4 style={{ margin: 0, padding: 0 }}>Fondos</h4>
        </div>
        <ContainerInput
          style={{
            marginTop: 20,
            marginBottom: 20,
            padding: "20px 10px",
            width: "100%",
            overflow: "auto",
          }}
        >
          <div className="Grid-container-Pedidos">
            {dataPedido.data.length === 0 ? (
              <div style={{ textAlign: "center" }}>
                <NotFound texto="No se encontró registros." />
              </div>
            ) : (
              <div style={{ display: "inline-flex" }}>
                {dataPedido.data.map((el, idx) => {
                  const seachDisabled = disabledPedidos.some(
                    (disabledPedido) => disabledPedido === el.id_pedido
                  );

                  return (
                    <div
                      key={idx}
                      className="GridItemPedido"
                      style={{ width: 350, marginRight: "15px" }}
                    >
                      <CardProduct
                        data={el}
                        handleChangeStatus={handleChangeStatus}
                        disabledButton={seachDisabled}
                        timeMax={30}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </ContainerInput>
        {dataPedido.data.length > 0 && (
          <div style={{ margin: "auto", width: 200 }}>
            <ArrowBack style={{ verticalAlign: "middle" }} /> <b>Deslizar</b>
            <ArrowForward style={{ verticalAlign: "middle" }} />
          </div>
        )}
      </Grid>
    </Grid>
  );
};

const CookOrdersHistory = (props) => {
  let history = useHistory();

  let { socket } = props;

  const [loadPage, setLoadPage] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedPedido, setSelectedPedido] = useState("");

  const [openSpeedDial, setOpenSpeedDial] = useState(false);

  const [amountPriority, setAmountPriority] = useState(0);

  const [disabledPedidos, setDisabledPedidos] = useState([]);
  const [openModalAllProduct, setOpenModalAllProduct] = useState(false);
  const [openModalRequestPassword, setOpenModalRequestPassword] =
    useState(false);
  const [typeRequestPassword, setTypeRequestPassword] = useState("allProduct");

  const companies = {
    DEJANDO_HUELLA: 4,
  };

  const [dataPedido, setDataPedido] = useState({
    load: false,
    err: false,
    data: [],
  });

  const [dataPedidoEntrada, setDataPedidoEntrada] = useState({
    load: false,
    err: false,
    data: [],
  });

  const handleCalculatePriority = (data) => {
    const amountPriority = [];

    data.forEach((el) => {
      const { productos } = el;
      productos.forEach((el2) => {
        if (
          parseInt(el2.ispriority_plato) === 1 &&
          el2.status_plapedido === "INGRESADO"
        ) {
          amountPriority.push(el2);
        }
      });
    });

    return amountPriority;
  };

  const hideProductsFinished = (dataProducts) => {
    const newDataProducts = [];
    dataProducts.forEach((item) => {
      const rowProduct = item.productos.length;

      const rowSearchProductFinished = item.productos.filter(
        (el) =>
          el.status_plapedido === "TERMINADO" ||
          el.status_plapedido === "ENTREGADO"
      ).length;

      if (rowProduct === rowSearchProductFinished) {
        return;
      }

      newDataProducts.push(item);
    });

    setAmountPriority(handleCalculatePriority(newDataProducts));

    return newDataProducts;
  };

  const handleGetPedido = async () => {
    try {
      const type = "fondo";
      setDataPedido({ ...dataPedido, load: true, err: false }); /* load */
      const response = await get_pedido_cocinero(type);

      const newResponse = hideProductsFinished(response);

      setDataPedido({ load: false, err: false, data: newResponse }); /* data */

      return response;
    } catch (err) {
      setDataPedido({ load: false, err: true, data: [] });
      setLoadPage(false);
      console.log(err);
    }
  };

  const handleGetPedidoEntradas = async () => {
    try {
      const type = "entrada";
      setDataPedidoEntrada({
        ...dataPedidoEntrada,
        load: true,
        err: false,
      }); /* load */

      const response = await get_pedido_cocinero(type);

      // const disabledProducts = disabledProductos(response);
      // setDisabledPedidos(disabledProducts);

      const newResponse = hideProductsFinished(response);

      setDataPedidoEntrada({
        load: false,
        err: false,
        data: newResponse,
      }); /* data */

      return response;
    } catch (err) {
      setDataPedidoEntrada({ load: false, err: true, data: [] });
      setLoadPage(false);
      console.log(err);
    }
  };

  const handleChangeStatus = (element, id_pedido) => {
    if (
      element.status_plapedido === "TERMINADO" ||
      element.status_plapedido === "ENTREGADO"
    ) {
      return null;
    }

    setSelectedPedido({ ...element, id_pedido });
    setOpenConfirm(true);
  };

  const handleUpdateStatus = async (json) => {
    await change_status_product(json);

    toast.success("Se actualizó la orden.");

    socket.emit("client:refreshProductStatus", {
      id_empsede: getUser().empresa.id_empsede,
      cod: json.cod_pedido,
    });

    getPedidos();
  };

  const getPedidos = async () => {
    if (getUser().id_perfil === 2) {
      setLoadPage(true);
      await handleGetPedidoEntradas();
      await handleGetPedido();

      setLoadPage(false);

      return;
    }

    setLoadPage(true);
    await handleGetPedido();
    setLoadPage(false);

    return;
  };

  const handleRequestPassword = (type) => {
    setOpenModalRequestPassword(true);

    if (type === "allProduct") {
      setTypeRequestPassword("allProduct");
    }
  };

  const handleCheckPassword = async (value) => {
    if (value) {
      setOpenModalAllProduct(true);

      return;
    }
  };

  useEffect(() => {
    socket.on("server:refreshProducts_cocina", async (response) => {
      if (response.id_empsede === getUser().empresa.id_empsede) {
        if (getUser().id_perfil === 2) {
          const responseEntrada = await get_pedido_cocinero("entrada");
          const newResponseEntrada = hideProductsFinished(responseEntrada);
          setDataPedidoEntrada({
            load: false,
            err: false,
            data: newResponseEntrada,
          });

          const responseFondo = await get_pedido_cocinero("fondo");
          const newResponseFondo = hideProductsFinished(responseFondo);

          setDataPedido({ load: false, err: false, data: newResponseFondo });
        } else {
          const responseFondo = await get_pedido_cocinero("fondo");

          const newResponseFondo = hideProductsFinished(responseFondo);

          setDataPedido({ load: false, err: false, data: newResponseFondo });
        }
      }
    });

    return null;
  }, []);

  //CHECK TOKEN
  useEffect(() => {
    if (!checkToken()) {
      history.push("/");
      return null;
    }

    getPedidos();
  }, []);

  return (
    <Template title="Pedidos">
      <LoadingPage open={loadPage} setOpen={setLoadPage} />

      {getUser().id_perfil === 2 &&
      getUser().empresa.id_emp !== companies.DEJANDO_HUELLA ? (
        <Cook
          dataPedido={dataPedido}
          dataPedidoEntrada={dataPedidoEntrada}
          disabledPedidos={disabledPedidos}
          handleChangeStatus={handleChangeStatus}
        />
      ) : (
        <OtherCook
          dataPedido={dataPedido}
          handleChangeStatus={handleChangeStatus}
        />
      )}

      <DialogConfirm
        open={openConfirm}
        setOpen={setOpenConfirm}
        pedido={selectedPedido}
        handleUpdateStatus={handleUpdateStatus}
      />

      {amountPriority && amountPriority.length > 0 && (
        <Fab
          size="large"
          aria-label="add"
          style={{
            backgroundColor: "#ff2f2f",
            position: "fixed",
            color: "#fff",
            right: 80,
            bottom: 17,
            zIndex: 1,
            fontSize: 22,
          }}
        >
          {amountPriority.length}
        </Fab>
      )}

      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        sx={{ position: "absolute", bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
        onClose={() => setOpenSpeedDial(false)}
        onOpen={() => setOpenSpeedDial(true)}
        open={openSpeedDial}
        color="secondary"
      >
        <SpeedDialAction
          key={"Stock platos"}
          icon={<ProductionQuantityLimits />}
          tooltipTitle={"Stock platos"}
          tooltipOpen
          onClick={() => {
            setOpenSpeedDial(false);

            handleRequestPassword("allProduct");
          }}
        />
      </SpeedDial>

      <DialogRequestPassword
        open={openModalRequestPassword}
        setOpen={setOpenModalRequestPassword}
        callback={handleCheckPassword}
      />

      <DialogAddProductAll
        open={openModalAllProduct}
        setOpen={setOpenModalAllProduct}
      />
    </Template>
  );
};

export default CookOrdersHistory;
