import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React from "react";

import { useAppContext } from "./state.ticketBenefit";

const TicketBenefitRedeemModal = () => {
  const state = useAppContext();

  const handleExternalButtonClick = () => {
    state.handleUpdate(state.defaultValues.id, state.defaultValues);
    state.handleModalRedeem(false);
  };

  return (
    <Dialog
      open={state.openModalRedeem}
      keepMounted
      onClose={() => state.handleModalRedeem(false)}
      fullWidth
      maxWidth={"sm"}
      aria-describedby="dialog-benefit"
    >
      <DialogTitle>
        Canjear ticket del beneficio
        <IconButton
          aria-label="close"
          onClick={() => state.handleModalRedeem(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <br />
        ¿Está seguro que desea canjear el ticket{" "}
        <b>#{state.defaultValues.id}</b> del promotor{" "}
        <b style={{ textTransform: "uppercase" }}>
          {state.defaultValues.nameUsu}
        </b>
        ?
      </DialogContent>
      <DialogActions>
        <Button
          color="inherit"
          variant="text"
          onClick={() => {
            state.handleModalRedeem(false);
          }}
        >
          Salir
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleExternalButtonClick}
        >
          Canjear
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default TicketBenefitRedeemModal;
