import React from "react";
import { Link, useParams } from "react-router-dom";
import Template from "../../components/Template";
import {
  get_quotation_by_id,
  get_tracking_by_id,
} from "../../services/quotation.services";

import ContainerInput from "../../components/ContainerInput";

import {
  Home,
  ArrowUpward,
  ArrowDownward,
  Add,
  Cancel,
  Check,
} from "@mui/icons-material";
import { Alert, Button, Grid } from "@mui/material";
import dayjs from "dayjs";
import CustomTable from "../../atomic/organisms/Table.organisms";
import ModalTrackingQuotation from "./modal-tracking.quotation";
import ModalTrackingQuotationDelete from "./modal-tracking-delete.quotation";

import { interestCalc, interestNivel } from "./function.quotation";
import ModalQuotationDecline from "./modal-decline.quotation";
import ModalQuotationApprove from "./modal-approve.quotation";
import { getUser } from "../../services/auth.services";

const BreadCrumb = (props) => {
  const { nameCod } = props;

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
        <li className="inline-flex items-center">
          <Link
            to="/quotation"
            className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-800"
          >
            <Home className="w-5 h-5 mr-1" aria-hidden="true" />
            Cotización
          </Link>
        </li>

        <li aria-current="page">
          <div className="flex items-center">
            <svg
              className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 9 4-4-4-4"
              />
            </svg>
            <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">
              {nameCod}
            </span>
          </div>
        </li>
      </ol>
    </nav>
  );
};

const InfoQuotation = (props) => {
  const { data } = props;

  const IS_DH_RCS_ID = getUser()?.empresa?.id_emp === 4;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h1 className="text-lg text-blue-900 font-[500] uppercase">
          Detalle de la cotización
        </h1>
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={2}>
        <label className="text-xs text-gray-500">Código</label>
        <p className="text-sm text-blue-900 overflow-auto">
          {data.cod_quotation}
        </p>
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={2}>
        <label className="text-xs text-gray-500">Evento</label>
        <p className="text-sm text-blue-900">{data.name_event}</p>
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={2}>
        <label className="text-xs text-gray-500">Tipo evento</label>
        <p className="text-sm text-blue-900">{data.name_typeevent}</p>
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={2}>
        <label className="text-xs text-gray-500">Nombre colegio/empresa</label>
        <p className="text-sm text-blue-900">{data.namecompany_quotation}</p>
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={2}>
        <label className="text-xs text-gray-500">Tipo contácto</label>
        <p className="text-sm text-blue-900">{data.name_typecontact}</p>
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={2}>
        <label className="text-xs text-gray-500">Fecha del evento</label>
        <p className="text-sm text-blue-900">
          {dayjs(data.date_quotation).format("DD/MM/YYYY")}
        </p>
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={2}>
        <label className="text-xs text-gray-500">Cantidad de personas</label>
        <p className="text-sm text-blue-900">{data.amountpeople_quotation}</p>
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={2}>
        <label className="text-xs text-gray-500">Nombres</label>
        <p className="text-sm text-blue-900">
          {data.name_quotation} {data.lastname_quotation}
        </p>
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={2}>
        <label className="text-xs text-gray-500">E-mail</label>
        <p className="text-sm text-blue-900 overflow-auto">
          {data.email_quotation}
        </p>
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={2}>
        <label className="text-xs text-gray-500">Número telefónico</label>
        <p className="text-sm text-blue-900">{data.phone_quotation}</p>
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={2}>
        <label className="text-xs text-gray-500">Región</label>
        <p className="text-sm text-blue-900">{data.regioncontact_quotation}</p>
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={2}>
        <label className="text-xs text-gray-500">Comuna</label>
        <p className="text-sm text-blue-900">{data.comunacontact_quotation}</p>
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={2}>
        <label className="text-xs text-gray-500">Via contácto</label>
        <p className="text-sm text-blue-900">{data.viacontact_quotation}</p>
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={2}>
        <label className="text-xs text-gray-500">Interés</label>
        <div className="flex items-center gap-2">
          <p className="text-sm text-blue-900">
            {interestNivel(data.interest_quotation.number)}
          </p>
          <p className="text-xs text-gray-500">
            {data.interest_quotation &&
              data.interest_quotation?.behavior === "up" && <ArrowUpward />}

            {data.interest_quotation &&
              data.interest_quotation?.behavior === "down" && <ArrowDownward />}
          </p>
        </div>
      </Grid>
      {IS_DH_RCS_ID && (
        <>
          <Grid item xs={6} sm={6} md={4} lg={2}>
            <label className="text-xs text-gray-500">Teléfono cliente</label>
            <p className="text-sm text-blue-900">
              {data.phone_quotation_customer}
            </p>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2}>
            <label className="text-xs text-gray-500">Segmentación</label>
            <p className="text-sm text-blue-900">
              {data.name_rservagrupa ? data.name_rservagrupa : "-"}
            </p>
          </Grid>
        </>
      )}
    </Grid>
  );
};

const IdQuotation = () => {
  const { cod } = useParams();

  const [data, setData] = React.useState([]);
  const [dataTracking, setDataTracking] = React.useState([]);
  const [load, setLoad] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [selected, setSelected] = React.useState(null);

  const [statusQuotation, setStatusQuotation] = React.useState("pendiente");

  const [openModal, setOpenModal] = React.useState(false);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [openModalDecline, setOpenModalDecline] = React.useState(false);
  const [openModalApprove, setOpenModalApprove] = React.useState(false);

  const interestValue = interestCalc(dataTracking);

  const fetchDataQuotation = async () => {
    try {
      setLoad(true);
      const responseQuotation = await get_quotation_by_id(cod);

      const quotationId = responseQuotation?.id_quotation;
      const responseTracking = await get_tracking_by_id(quotationId);

      setStatusQuotation(responseQuotation.status_quotation);

      setData(responseQuotation);
      setDataTracking(responseTracking);
    } catch (error) {
      setError(true);
    } finally {
      setLoad(false);
    }
  };

  React.useEffect(() => {
    // Fetch data
    fetchDataQuotation();
  }, []);

  return (
    <Template title="Cotización">
      <div className="p-5">
        <div className="pb-5">
          <BreadCrumb nameCod={cod} />
        </div>
        {statusQuotation === "cancelado" && (
          <Alert severity="error" className="mb-4 w-full">
            Esta cotización ha sido rechazada, no se puede realizar ninguna
            acción.
          </Alert>
        )}

        {statusQuotation === "aprobado" && (
          <Alert severity="success" className="mb-4 w-full">
            Esta cotización ha sido aprobada, no se puede realizar ninguna
            acción.
          </Alert>
        )}

        <InfoQuotation data={{ ...data, interest_quotation: interestValue }} />
        <div className="mb-4 w-full mt-4 flex gap-2 justify-end">
          <Button
            variant="contained"
            color="secondary"
            className="ml-auto gap-2"
            onClick={() => setOpenModal(true)}
            disabled={statusQuotation !== "pendiente"}
          >
            <Add /> Agregar
          </Button>
          <Button
            variant="contained"
            color="error"
            className="ml-auto gap-2"
            onClick={() => setOpenModalDecline(true)}
            disabled={
              statusQuotation !== "pendiente" || dataTracking.length === 0
            }
          >
            <Cancel /> Rechazar
          </Button>
          <Button
            variant="contained"
            color="success"
            className="ml-auto gap-2"
            onClick={() => setOpenModalApprove(true)}
            disabled={
              statusQuotation !== "pendiente" || dataTracking.length === 0
            }
          >
            <Check /> Aprobar
          </Button>
        </div>
        <ContainerInput className="p-0 mt-4">
          <CustomTable
            columns={[
              {
                id: "type_interest_tracking",
                label: "Nivel de interés",
                minWidth: 100,
                align: "left",
                format: (row) => interestNivel(row),
              },
              {
                id: "name_phase",
                label: "Fase",
                minWidth: 80,
                align: "left",
              },
              {
                id: "name_actionphase",
                label: "Acción de fase",
                minWidth: 100,
                align: "left",
              },
              {
                id: "description_tracking",
                label: "Descripción",
                minWidth: 100,
                align: "left",
              },
              {
                id: "date_scheduled_tracking",
                label: "Agendamiento",
                minWidth: 160,
                align: "left",
                format: (el) => {
                  if (!el) {
                    return null;
                  }

                  return dayjs(el).utc().format("DD/MM/YYYY HH:mm");
                },
              },
              {
                id: "created_at",
                label: "Fecha de creación",
                minWidth: 160,
                align: "left",
                format: (el) => {
                  return dayjs(el).utc().format("DD/MM/YYYY HH:mm");
                },
              },
            ]}
            rows={dataTracking}
            load={load}
            error={false}
            disableButtons={statusQuotation !== "pendiente"}
            actionEdit={(element) => {
              setSelected(element);
              setOpenModal(true);
            }}
            actionDelete={(element) => {
              setSelected(element);
              setOpenModalDelete(true);
            }}
            pagination={true}
          />
        </ContainerInput>
      </div>

      <ModalTrackingQuotation
        idQuotation={data.id_quotation}
        open={openModal}
        selected={selected}
        setSelected={setSelected}
        setOpen={setOpenModal}
        callBack={() => {
          fetchDataQuotation();
        }}
      />

      <ModalTrackingQuotationDelete
        open={openModalDelete}
        selected={selected}
        setSelected={setSelected}
        setOpen={setOpenModalDelete}
        callBack={() => {
          fetchDataQuotation();
        }}
      />

      <ModalQuotationDecline
        open={openModalDecline}
        selected={data}
        setOpen={setOpenModalDecline}
        callBack={() => {
          fetchDataQuotation();
        }}
      />

      <ModalQuotationApprove
        open={openModalApprove}
        selected={data}
        setOpen={setOpenModalApprove}
        callBack={() => {
          fetchDataQuotation();
        }}
      />
    </Template>
  );
};

export default IdQuotation;
