/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Template from "../../components/Template";

import LoadingPage from "../../components/LoadingPage";

import { get_user_by_id, update_user } from "../../services/user.services";

import { Alert, Button, Grid } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import InputDefault from "../../atomic/atoms/input.atoms";
import ContainerInput from "../../components/ContainerInput";
import { checkToken, getUser } from "../../services/auth.services";
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";

const initalValues = {
  usu_username: "",
  usu_id: "",
  usu_nombre: "",
  usu_email: "",
  usu_direccion: "",
  usu_phone: "",
  usu_rut: "",
  usu_bank: "",
  usu_bank_type: "",
  usu_bank_number_account: "",
};

const SettingProfile = () => {
  const history = useHistory();

  const { control, handleSubmit, reset } = useForm({
    defaultValues: initalValues,
  });

  const [loadPage, setLoadPage] = useState(false);
  const [load, setLoad] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);

  //obtain the user data

  const getUserData = async () => {
    setLoadPage(true);
    try {
      const userId = getUser()?.id;

      const response = await get_user_by_id(userId);

      const dataUserResponse = response[0];

      reset({
        usu_username: dataUserResponse.usu_username,
        usu_id: dataUserResponse.usu_id,
        usu_nombre: dataUserResponse.usu_nombre,
        usu_email: dataUserResponse.usu_email,
        usu_direccion: dataUserResponse.usu_direccion,
        usu_phone: dataUserResponse.usu_phone,
        usu_rut: dataUserResponse.usu_rut,
        usu_bank: dataUserResponse.usu_bank,
        usu_bank_type: dataUserResponse.usu_bank_type,
        usu_bank_number_account: dataUserResponse.usu_bank_number_account,
      });
      setLoadPage(false);
    } catch (err) {
      setLoadPage(false);
      console.log(err.message);
    }
  };

  const onSubmitAction = async (data) => {

    try {
      setLoad(true);
      const response = await update_user(data, data.usu_id);
      toast.success("Datos actualizados correctamente");

      setLoad(false);
      setIsSuccess(true);
    } catch (err) {
      toast.error("No se pudo actualizar los datos");
      setIsSuccess(false);
      setLoad(false);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (!checkToken()) {
      history.push("/");
      return null;
    }
  }, []);

  return (
    <Template title="Mi perfil">
      <LoadingPage open={loadPage} />
      {isSuccess && (
        <Alert variant="standard" color="success" className="mb-5 text-left">
          Los datos se han actualizado correctamente.
        </Alert>
      )}
      <h1 className="text-2xl text-blue-950 mb-4 ml-2">Información personal</h1>
      <ContainerInput className="px-4">
        <form onSubmit={handleSubmit(onSubmitAction)} autoComplete="off">
          <div className={`${load && "animate-pulse"} `}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <h2 className="text-xl text-blue-950 mb-2">Mis datos</h2>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Controller
                  name="usu_nombre"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputDefault
                      required
                      loading={false}
                      fullWidth
                      value={value}
                      onChange={onChange}
                      title={"Nombres"}
                      type={"text"}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}></Grid>
              <Grid item xs={12} sm={5}>
                <Controller
                  name="usu_email"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputDefault
                      required
                      loading={false}
                      fullWidth
                      value={value}
                      onChange={onChange}
                      title={"Correo electrónico"}
                      type={"email"}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={7}></Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="usu_direccion"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputDefault
                      loading={false}
                      fullWidth
                      value={value}
                      onChange={onChange}
                      title={"Dirección"}
                      type={"text"}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="usu_phone"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputDefault
                      loading={false}
                      fullWidth
                      value={value}
                      onChange={onChange}
                      title={"Teléfono"}
                      type={"tel"}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <h2 className="text-xl text-blue-950 mb-2">Datos bancarios</h2>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="usu_rut"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputDefault
                      loading={false}
                      fullWidth
                      value={value}
                      onChange={onChange}
                      title={"Rut"}
                      type={"text"}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
              <Grid item xs={12} sm={3}>
                <Controller
                  name="usu_bank"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputDefault
                      loading={false}
                      fullWidth
                      value={value}
                      onChange={onChange}
                      title={"Selecciona tu banco"}
                      type={"select"}
                      options={[
                        { value: "Banco de Chile", label: "Banco de Chile" },
                        { value: "Banco Estado", label: "Banco Estado" },
                        { value: "Banco Santander", label: "Banco Santander" },
                        { value: "Banco BCI", label: "Banco BCI" },
                        { value: "Banco Itaú", label: "Banco Itaú" },
                        { value: "Banco Falabella", label: "Banco Falabella" },
                        { value: "Banco Security", label: "Banco Security" },
                        {
                          value: "Banco Scotiabank",
                          label: "Banco Scotiabank",
                        },
                        { value: "Banco BBVA", label: "Banco BBVA" },
                        { value: "Banco Ripley", label: "Banco Ripley" },
                        { value: "Banco Consorcio", label: "Banco Consorcio" },
                        {
                          value: "Banco Internacional",
                          label: "Banco Internacional",
                        },
                        { value: "Banco Corpbanca", label: "Banco Corpbanca" },
                        { value: "Banco Paris", label: "Banco Paris" },
                        { value: "Banco Edwards", label: "Banco Edwards" },
                        { value: "Banco Condell", label: "Banco Condell" },
                      ]}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Controller
                  name="usu_bank_type"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputDefault
                      loading={false}
                      fullWidth
                      value={value}
                      onChange={onChange}
                      title={"Tipo de cuenta"}
                      type={"select"}
                      options={[
                        { value: "Cuenta vista", label: "Cuenta vista" },
                        {
                          value: "Cuenta Corriente",
                          label: "Cuenta Corriente",
                        },
                      ]}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Controller
                  name="usu_bank_number_account"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputDefault
                      loading={false}
                      fullWidth
                      value={value}
                      onChange={onChange}
                      title={"Número de cuenta"}
                      type={"text"}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                className="text-right flex gap-2 justify-end mt-4"
              >
                <Button
                  color="info"
                  variant="text"
                  disable={load.toString()}
                  onClick={() => reset(initalValues)}
                  className="mr-2"
                >
                  Limpiar
                </Button>
                <LoadingButton
                  className="!w-32"
                  loading={load}
                  type="submit"
                  color="secondary"
                  variant="contained"
                  loadingIndicator="Cargando..."
                >
                  Guardar
                </LoadingButton>
              </Grid>
            </Grid>
          </div>
        </form>
      </ContainerInput>
    </Template>
  );
};

export default SettingProfile;
