import * as React from "react";

import TextField from "@mui/material/TextField";

import "./style.comments.scss";

const Comments = (props) => {
  let { handleSaveComment, infoPedido } = props;

  let { data } = infoPedido;

  const [inputComment, setInputComment] = React.useState("");

  const handleChange = (e) => {
    //guardar comentario en el estado
    setInputComment(e.target.value);

    //guardar comentario en la bd
    handleSaveComment(e.target.value);
  };

  React.useEffect(() => {
    if (data.commets_pedido) {
      setInputComment(data.commets_pedido);
    }

    return null;
  }, [infoPedido.data]);

  return (
    <div className="comments">
      <TextField
        id="outlined-multiline-static"
        label="Comentarios / observaciones"
        multiline
        rows={3}
        value={inputComment}
        onChange={handleChange}
        fullWidth
        style={{ padding: "10px" }}
      />
    </div>
  );
};
export default Comments;
