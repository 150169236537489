import { combineReducers } from "redux";

import { reducer_historialCLiente } from "./historialCliente/reducers/historialCliente.reducers";
import { reducer_marca } from "./marca/reducers/marca.reducers";
import { reducer_producto } from "./producto/reducers/producto.reducers";
import { reducer_menu } from "./menu/reducers/menu.reducers";
import { reducer_plato } from "./plato/reducers/plato.reducers";
import { reducer_categoria } from "./categoria/reducers/categoria.reducers";
import { reducer_carta } from "./carta/reducers/carta.reducers";
import { reducer_pedido } from "./pedido/reducers/pedido.reducers";

const rootReducer = combineReducers({
  reducer_historialCLiente,
  reducer_marca,
  reducer_producto,
  reducer_menu,
  reducer_plato,
  reducer_categoria,
  reducer_carta,
  reducer_pedido,
});

export default rootReducer;
