import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import * as React from "react";

import IconButton from "@mui/material/IconButton";
import { Alert, Collapse, DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow } from "@mui/material";
import { Close } from "@mui/icons-material";

import { get_wallet_merchante } from "../../services/wallet.services";
import toast from "react-hot-toast";

import LoadingPage from "../../components/LoadingPage";

const formatNumber = (n) => {
  return n === "" || n === 0 ? n : Number(n).toLocaleString();
};

const ErrorMsg = ({ wrong, setWrong, message }) => {
  return (
    <Collapse in={wrong}>
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setWrong(false);
            }}
          >
            <Close fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
        severity="error"
      >
        {message}
      </Alert>
    </Collapse>
  );
};

const DialogWallet = ({
  open,
  setOpen,
  data,
}) => {
  const [wrong, setWrong] = React.useState({ open: false, message: "" });
  const [openLoad, setOpenLoad] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);

  const handleClose = () => {
    setOpen(false);
    setRows([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleWallet = async (data) => {
    try {
      setOpenLoad(true);
      let response = await get_wallet_merchante(data.usu_id);
      if (response) {
        setRows(response);
        setOpenLoad(false);
        toast.success(`Wallet de ${data.usu_nombre}`); 
      } else {
        setOpenLoad(false);
        toast.error(`No se encontró el wallet de ${data.usu_nombre}`);
      }
      
    } catch (error) {
      setOpenLoad(false);

      console.log(error.message);
      toast.error("Hubo un error al crear la sucursal");
      setOpen(false);
    }
  };

  React.useEffect(() => {
    if (open) {
      handleWallet(data);
    } else {
      setRows([]);
    }
  }, [open]);
  return (
    
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"xl"}>
        <LoadingPage open={openLoad} />
        <DialogTitle>
          Wallet
          <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <br />
              <ErrorMsg
                wrong={wrong.open}
                setWrong={(el) => setWrong({ ...wrong, open: el })}
                message={wrong.message}
              />
          <TableContainer sx={{ maxHeight: 550 }}>
          <Table stickyHeader aria-label="sticky table" size={"small"}>
            <TableHead>
              <TableRow>
                <TableCell align={"left"} style={{ minWidth: 190 }}>
                  Monto de comision
                </TableCell>
                <TableCell align={"left"} style={{ minWidth: 100 }}>
                  Cantidades vendidas
                </TableCell>
                <TableCell align={"left"} style={{ minWidth: 100 }}>
                  Estado
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, idx) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                        <TableCell align={"left"}> $ {formatNumber(row.monto_comission)}</TableCell>
                        <TableCell align={"left"}>{row.quantity_sold}</TableCell>
                        <TableCell align={"left"}>{row.status_wallet}</TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>

          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage="Filas por página"
          rowsPerPageOptions={[10, 25, 100, { value: -1, label: "Todo" }]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        </DialogContent>
      </Dialog>
      
    </>
  )
}

export default DialogWallet;
