/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import Template from "../../components/Template";

import { checkToken } from "../../services/auth.services";

import {
  get_producto,
  search_producto,
} from "../../services/productos.services";

import ContainerInput from "../../components/ContainerInput";
import DialogCrud from "./Dialog/CrudForm";
import DialogDelete from "./Dialog/ConfirmDelete/index";
// import LoadingPage from "../../components/LoadingPage";

import { useForm, Controller } from "react-hook-form";

import Table from "./Table/index";

import { Grid, Button, TextField, IconButton } from "@mui/material";

import { Add, Search } from "@mui/icons-material";

import { useSelector, useDispatch } from "react-redux";
import { actionTypes } from "../../redux/producto/actions";
import Input from "../../components/Forms/Input";
import useCategoriaProducto from "../../hooks/useCategoriaProducto";

const Insumos = () => {
  let history = useHistory();

  const dispatch = useDispatch();

  const { dataCategoriaProducto } = useCategoriaProducto();
  const { dataProducto, loading } = useSelector(
    (state) => state.reducer_producto
  );

  const { control, handleSubmit } = useForm({
    defaultValues: {
      prod_nombre: "",
      marc_id: "",
      cat_id: "",
    },
  });

  const onOpen = (data) =>
    dispatch({
      type: actionTypes.updateDialogEdit,
      payload: data,
    });

  const onGet = (data) =>
    dispatch({
      type: actionTypes.getDataProducto,
      payload: data,
    });

  const handleSearchProduct = async (data) => {
    try {
      onGet({ ...dataProducto, load: true, data: [], err: false });
      let response = await search_producto(data);
      onGet({ ...dataProducto, load: false, data: response, err: false });
    } catch (err) {
      onGet({ ...dataProducto, load: false, data: [], err: true });
      console.log(err);
    }
  };

  useEffect(() => {
    async function obtainDataProducto() {
      try {
        onGet({ ...dataProducto, load: true, data: [], err: false });
        let response = await get_producto();
        onGet({ ...dataProducto, load: false, data: response, err: false });
      } catch (err) {
        console.log(err);
        onGet({ ...dataProducto, load: false, data: [], err: true });
      }
    }

    if (!loading) {
      obtainDataProducto();
    }

    return null;
  }, [loading]);

  useEffect(() => {
    if (!checkToken()) {
      history.push("/");
      return null;
    }
  }, []);

  return (
    <>
      {/* <LoadingPage open={loading} /> */}
      <Grid container spacing={2} style={{ marginBottom: 20 }}>
        <Grid item xs={12} sm={11} lg={11}>
          <form onSubmit={handleSubmit(handleSearchProduct)} autoComplete="off">
            <Grid container spacing={1}>
              <Grid item xs={7}>
                <Controller
                  name="prod_nombre"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      fullWidth
                      value={value}
                      onChange={onChange}
                      type="text"
                      title="Nombre producto"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name="cat_id"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      fullWidth
                      select
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                      className={`InputDefault`}
                      SelectProps={{
                        native: true,
                      }}
                    >
                      <option value="">[Categoría]</option>
                      {dataCategoriaProducto.data.map((option, idx) => (
                        <option key={idx} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={1} style={{ textAlign: "center" }}>
                <IconButton
                  type="submit"
                  size="large"
                  color="primary"
                  style={{ background: "#eee" }}
                >
                  <Search />
                </IconButton>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid
          item
          xs={12}
          sm={1}
          lg={1}
          style={{
            textAlign: "right",
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Button
            fullWidth
            onClick={() => {
              onOpen(true);
            }}
            className="button-default"
          >
            <Add />
          </Button>
        </Grid>
      </Grid>

      <ContainerInput className="!p-0">
        <Table />
      </ContainerInput>
      <DialogCrud />
      <DialogDelete />
    </>
  );
};

export default Insumos;
