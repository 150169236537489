export const nameSockets = {
  MAIN: {
    client: {
      refreshOrders: "client:refreshOrders",
      refreshProducts: "client:refreshProducts",
      refreshProductStatus: "client:refreshProductStatus",
    },
    server: {
      refreshOrders: "server:refreshOrders",
      refreshProductStatus: "server:refreshProductStatus",
    },
  },
  SECONDARY: {
    client: {
      refreshOrders: "client:refreshOrdersSecondary",
      refreshProducts: "client:refreshProductsSecondary",
      refreshProductStatus: "client:refreshProductStatusSecondary",
    },
    server: {
      refreshOrders: "server:refreshOrdersSecondary",
      refreshProductStatus: "server:refreshProductStatusSecondary",
    },
  },
};
