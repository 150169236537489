export const actionTypesPlato = {
  openModalAddEdit_plato: "OPEN_MODAL_ADD_EDIT_PLATO",
  openModalDelete: "OPEN_MODAL_DELETE",
  openModalProducts: "OPEN_MODAL_PRODUCTS",
  getDataPlato: "GET_DATA_PLATO",
  setLoading: "SET_LOADING",
  setDataSelected: "SET_DATA_SELECTED",
  setParamSelected_product: "SET_PARAM_SELECTED",
  setData_products: "SET_DATA_PRODUCTS",
};
