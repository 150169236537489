/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";

import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";

import { addCampaign, updateCampaign } from "../../../services/ticket.services";

import { Alert, Collapse, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Input from "../../../components/Forms/Input";

import { Close } from "@mui/icons-material";

import LoadingPage from "../../../components/LoadingPage";
import Ticket from "../../ticket/component/ticket.ticket";

const ErrorMsg = ({ wrong, setWrong, message }) => {
  return (
    <Collapse in={wrong}>
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setWrong(false);
            }}
          >
            <Close fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
        severity="error"
      >
        {message}
      </Alert>
    </Collapse>
  );
};

const DialogCrudCampaign = (props) => {
  let { open, setOpen, infoTicket, dataSelected, dataTypeCampaign, callback } =
    props;

  const { control, watch, reset, handleSubmit } = useForm({
    defaultValues: dataSelected,
  });

  const [wrong, setWrong] = React.useState({ open: false, message: "" });
  const [steps, setSteps] = React.useState(1);
  const [loading, setLoading] = React.useState(false);

  const handleModal = (value) => {
    setOpen(value);
  };

  const handleAdd = async (data) => {
    try {
      let response;
      setLoading(true);
      if (data.id_campaign) {
        response = await updateCampaign(data.id_campaign, data);
        toast.success("Se actualizo la campaña correctamente");
      } else {
        response = await addCampaign(data);
        toast.success("Se creo la campaña correctamente");
      }

      await callback();

      setLoading(false);

      handleModal(false);
    } catch (err) {
      console.error(err);
      toast.error("Hubo un error con la solicitud.");
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (open) {
      reset(dataSelected);
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => handleModal(false)}
        fullWidth
        maxWidth={"lg"}
      >
        <LoadingPage open={loading} />
        <form onSubmit={handleSubmit(handleAdd)} autoComplete="off">
          <DialogTitle
            style={{
              position: "sticky",
              top: 0,
              background: "white",
              zIndex: 5,
            }}
          >
            {watch("id_campaign") ? "Editar" : "Agregar"} campaña
            <IconButton
              aria-label="close"
              onClick={() => handleModal(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <br />
            <ErrorMsg
              wrong={wrong.open}
              setWrong={(el) => setWrong({ ...wrong, open: el })}
              message={wrong.message}
            />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Typography variant={"h6"} component={"h6"}>
                  Datos:
                </Typography>
                <br />
                <div
                  style={{
                    border: "1px silver solid",
                    padding: 10,
                    borderRadius: 10,
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Controller
                        name="title_campaign"
                        control={control}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <Input
                            required
                            value={value}
                            onChange={onChange}
                            type="text"
                            title="Título campaña"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="id_typecampaign"
                        control={control}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <Input
                            required
                            value={value}
                            onChange={onChange}
                            type="select"
                            title="Tipo de campaña"
                            options={dataTypeCampaign}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <Controller
                        name="titleticket_campaign"
                        control={control}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <Input
                            required
                            value={value}
                            onChange={onChange}
                            type="text"
                            title="Título de ticket"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <Controller
                        name="maxnumberticket_campaign"
                        control={control}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <Input
                            value={value}
                            onChange={onChange}
                            type="number"
                            title="Máximo de tickets"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="observations_campaign"
                        control={control}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <Input
                            required
                            value={value}
                            onChange={onChange}
                            type="textarea"
                            title="Requerimientos, observaciones...."
                            rows="4"
                            cols="50"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="requirements_campaign"
                        control={control}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <Input
                            required
                            value={value}
                            onChange={onChange}
                            type="textarea"
                            title="Mensaje terminado,"
                            rows="4"
                            cols="50"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      Fecha de vencimiento <br />
                      <Controller
                        name="durationdate_campaign"
                        control={control}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <Input
                            value={value}
                            onChange={onChange}
                            type="date"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <Typography variant={"h6"} component={"h6"}>
                  Vista previa:
                </Typography>
                <br />
                <div
                  style={{
                    border: "1px silver solid",
                    padding: 10,
                    borderRadius: 10,
                    textAlign: "center",
                  }}
                >
                  <Typography component={"h4"} variant={"h4"}>
                    {watch("title_campaign") || "Título campaña"}
                  </Typography>
                  <br />

                  {steps === 1 ? (
                    <>
                      <Alert variant="outlined" severity="info">
                        {watch("observations_campaign") ||
                          "Requerimientos, observaciones, etc..."}
                      </Alert>
                      <br />
                    </>
                  ) : (
                    <>
                      <Alert variant="outlined" severity="success">
                        {watch("requirements_campaign") ||
                          "Mensaje terminado..."}
                      </Alert>
                      <br />
                    </>
                  )}

                  <Ticket
                    infoTicket={infoTicket}
                    campaign={{
                      titleticketCampaign:
                        watch("titleticket_campaign") || "<<Título ticket>>",
                      durationdateCampaign: watch("durationdate_campaign"),
                    }}
                    dataTicket={{
                      email: "test@gmail.com",
                      name: "name",
                      lastname: "lastname",
                    }}
                    numberTicket={steps === 2 && "000001"}
                  />
                  <br />

                  {steps === 1 ? (
                    <>
                      <Button
                        type="button"
                        color="secondary"
                        variant="outlined"
                        size="medium"
                        onClick={() => setSteps(2)}
                      >
                        Siguiente
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        type="button"
                        color="info"
                        variant="outlined"
                        size="medium"
                        onClick={() => setSteps(1)}
                      >
                        Atrás
                      </Button>
                    </>
                  )}
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{ background: "#fff", position: "sticky", bottom: 0 }}
          >
            <Button
              color="info"
              variant="text"
              onClick={() => {
                handleModal(false);
              }}
            >
              Salir
            </Button>
            <Button type="submit" color="success" variant="contained">
              Aceptar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default DialogCrudCampaign;
