import axios from "axios";
import { config } from "../config";

import { axiosHeader } from "../functions/axiosHeader";

const { baseurl } = config;

export const getHistoryOrders = () => {
  const url = `${baseurl}/api/caja/get`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const searchHistoryOrders = (params) => {
  const url = `${baseurl}/api/caja/search`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};
