/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import * as React from "react";

import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import { Grid } from "@mui/material/";

import { Alert, Collapse } from "@mui/material";

import Input from "../../components/Forms/Input";

import { Controller, useForm } from "react-hook-form";

import { Close } from "@mui/icons-material";

import toast from "react-hot-toast";

import { add_mesas, update_mesas } from "../../services/mesas.services";

const ErrorMsg = ({ wrong, setWrong, message }) => {
  return (
    <Collapse in={wrong}>
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setWrong(false);
            }}
          >
            <Close fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
        severity="error"
      >
        {message}
      </Alert>
    </Collapse>
  );
};

const DialogCrudMesas = ({ open, setOpen, data, handleGetMesas }) => {
  let initialValues = {
    codigo_mesa: "",
    cantidad_mesa: 0,
  };

  const { control, reset, watch, setValue, handleSubmit } = useForm({
    defaultValues: initialValues,
  });

  const [wrong, setWrong] = React.useState({ open: false, message: "" });

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddMesa = async (dataMesa) => {
    try {
      let response;

      if (dataMesa.id_mesa) {
        response = await update_mesas(dataMesa, dataMesa.id_mesa);
        if (response.affectedRows === 1) {
          toast.success("Mesa modificado correctamente");
          setOpen(false);
          reset(initialValues);
        } else {
          toast.error("No se pudo modificar la mesa");
          setOpen(false);
          reset(initialValues);
        }
      } else {
        response = await add_mesas(dataMesa);

        if (response.affectedRows === 1) {
          toast.success("Mesa creado correctamente");
          setOpen(false);
          reset(initialValues);
        } else {
          toast.error("No se pudo crear la mesa");
          setOpen(false);
          reset(initialValues);
        }
      }

      handleGetMesas();
    } catch (err) {
      console.log(err.message);
      toast.error("Hubo un error al crear la mesa");
      setOpen(false);
    }
  };

  // OBTAIN DATA

  React.useEffect(() => {
    if (open) {
      //console.log(open);
      // obtain_comuna();
      // obtain_banco();

      reset(data);
    }
  }, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"sm"}>
        <form onSubmit={handleSubmit(handleAddMesa)} autoComplete="off">
          <DialogTitle>
            {data.id_mesa ? "Editar" : "Agregar"}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <br />
            <ErrorMsg
              wrong={wrong.open}
              setWrong={(el) => setWrong({ ...wrong, open: el })}
              message={wrong.message}
            />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} lg={12}>
                <Controller
                  name="codigo_mesa"
                  control={control}
                  // rules={{ required: "First name required" }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      required
                      value={value}
                      onChange={onChange}
                      type="text"
                      title="Código o nombre de la mesa"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Controller
                  name="cantidad_mesa"
                  control={control}
                  // rules={{ required: "First name required" }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      required
                      value={value}
                      onChange={onChange}
                      type="number"
                      title="Capacidad de la mesa"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="info"
              variant="text"
              onClick={() => {
                setOpen(false);
              }}
            >
              Salir
            </Button>
            <Button type="submit" color="success" variant="contained">
              Aceptar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default DialogCrudMesas;
