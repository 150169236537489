import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Template from "../../components/Template";

import { checkToken } from "../../services/auth.services";

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
} from "@mui/material";

import ContainerInput from "../../components/ContainerInput";
import ToDayBox from "../../components/ToDayBox";

import { get_user_today } from "../../services/user.services";

import { updateActiveToday } from "../../services/auth.services";

// import CalendarReservas from "../BffHome/CalendarReservas";
// import GarzonReservas from "../BffHome/GarzonReservas";

const ActivarGarzones = (props) => {
  let history = useHistory();
  //   const { socket } = props;

  const [loadPage, setLoadPage] = useState(false);
  const [dataUser, setDataUser] = useState([]);
  const [allUser, setAllUser] = useState(false);

  /**
   * @description: Funcion para obtener los reportes
   */

  const handleGetUserData = async () => {
    setLoadPage(true);
    try {
      const response = await get_user_today();
      setDataUser(response);
      setLoadPage(false);
    } catch (err) {
      setLoadPage(false);
      console.log(err.message);
    }
  };

  const handleActive = async (value) => {
    const newDataUser = [...dataUser];
    const idx = newDataUser.findIndex((el) => el.usu_id === value.usu_id);

    newDataUser[idx] = {
      ...newDataUser[idx],
      usu_active_today: value.usu_active_today === 1 ? 0 : 1,
    };

    const data = {
      usu_id: newDataUser[idx].usu_id,
      usu_active_today: newDataUser[idx].usu_active_today,
      id_gactivo: newDataUser[idx].id_gactivo,
    };

    setDataUser(newDataUser);

    await updateActiveToday(data);
  };

  const handleAllUser = async () => {
    setLoadPage(true);

    setAllUser(!allUser);

    let newDataUser;

    if (!allUser) {
      newDataUser = dataUser.map((el) => ({ ...el, usu_active_today: 1 }));
    } else {
      newDataUser = dataUser.map((el) => ({ ...el, usu_active_today: 0 }));
    }

    setDataUser(newDataUser);

    await newDataUser.reduce(async (previousPromise, product) => {
      await previousPromise;

      const data = {
        usu_id: product.usu_id,
        usu_active_today: product.usu_active_today,
        id_gactivo: product.id_gactivo,
      };

      await updateActiveToday(data);
    }, Promise.resolve());

    setLoadPage(false);
  };

  useEffect(() => {
    handleGetUserData();
  }, []);

  useEffect(() => {
    if (!checkToken()) {
      history.push("/");
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Template title="Inicio">
      <ToDayBox open={loadPage} setOpen={setLoadPage}>
        <ContainerInput
          style={{
            padding: "5px 15px",
          }}
        >
          <Table size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align={"left"} style={{ minWidth: 150 }}>
                  Nombres
                </TableCell>
                <TableCell align={"left"} style={{ minWidth: 190 }}>
                  Correo electrónico
                </TableCell>
                <TableCell align={"left"} style={{ minWidth: 100 }}>
                  Usuario
                </TableCell>
                <TableCell align={"left"} style={{ minWidth: 100 }}>
                  <Checkbox
                    onChange={() => handleAllUser()}
                    checked={allUser}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataUser.map((row, idx) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                  <TableCell align={"left"}>{row.usu_nombre}</TableCell>
                  <TableCell align={"left"}>{row.usu_email}</TableCell>
                  <TableCell align={"left"}>{row.usu_username}</TableCell>
                  <TableCell align={"left"}>
                    <Checkbox
                      onChange={() => handleActive(row)}
                      checked={row.usu_active_today === 1 ? true : false}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ContainerInput>
      </ToDayBox>
    </Template>
  );
};

export default ActivarGarzones;
