/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import { Typography, IconButton, Breadcrumbs } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

import Template from "../../../components/Template/TemplateCarta";
import ContainerInput from "../../../components/ContainerInput";
import LoadingPage from "../../../components/LoadingPage";

import ListProduct from "../../orders/Ver/Components/ListProduct";

import {
  get_pedido_producto,
  get_pedido_id,
} from "../../../services/pedidos.services";
import { getUser } from "../../../services/auth.services";

const NavBack = (props) => {
  let { handleBack, info } = props;
  return (
    <Breadcrumbs className="navBackCartProduct" aria-label="breadcrumb">
      <IconButton onClick={() => handleBack()} size="small">
        <ArrowBack />
      </IconButton>

      <Typography color="text.primary">
        {info.nombre_cliente} {info.apepaterno_cliente}
      </Typography>
    </Breadcrumbs>
  );
};

const Pedido = (props) => {
  let { id_pedido } = useParams();

  let { socket } = props;

  let history = useHistory();

  let INITIALVALUES_PEDIDO = {
    cantidad_mesa: "",
    codigo_mesa: "",
    codigo_pedido: "",
    estado_reserva: "",
    fechareg_pedido: "",
    fechareserva_reserva: "",
    id_mesa: "",
    id_pedido: "",
    nombre_estadopedido: "",
    nombre_cliente: "",
    apepaterno_cliente: "",
    apematerno_cliente: "",
  };

  const [dataPedido, setDataPedido] = useState(INITIALVALUES_PEDIDO);

  const [loadPage, setLoadPage] = useState(false);
  const [dataProducts, setDataProducts] = useState({
    data: [],
    load: false,
    err: false,
  });

  const handleBack = () => {
    history.push("/carta");
  };

  const handleGetPedido = async (id) => {
    try {
      setLoadPage(true);
      let response = await get_pedido_id(id);
      setLoadPage(false);

      if (response === "") {
        history.push("/carta");
        return null;
      }

      setDataPedido(response);
    } catch (err) {
      setLoadPage(false);
      console.log(err);
    }
  };

  const handleGetProducts = async (id_pedido) => {
    try {
      setDataProducts({ data: [], load: true, err: false });
      let response = await get_pedido_producto(id_pedido);

      setDataProducts({ data: response, load: false, err: false });
    } catch (err) {
      setDataProducts({ data: [], load: false, err: true });
      console.log(err);
    }
  };

  useEffect(() => {
    socket.on("server:refreshProductStatus", (response) => {
      if (
        response.codigo_pedido === id_pedido &&
        response.id_empsede === getUser().empresa.id_empsede
      ) {
        setDataProducts({ data: response.data, load: false, err: false });
      }
    });

    return null;
  }, []);

  useEffect(() => {
    function fetchGet() {
      handleGetPedido(id_pedido);
      handleGetProducts(id_pedido);
    }

    fetchGet();

    return () => {};
  }, [id_pedido]);

  return (
    <Template title="Pedido">
      <LoadingPage open={loadPage} setOpen={setLoadPage} />

      <br />

      <NavBack handleBack={handleBack} info={dataPedido} />

      <ContainerInput
        style={{
          marginTop: 10,
          marginBottom: 20,
          padding: "15px 10px",
        }}
      >
        <ListProduct dataProducts={dataProducts} idPedido={id_pedido} />
      </ContainerInput>
    </Template>
  );
};

export default Pedido;
