import { Grid } from "@mui/material";
import dayjs from "dayjs";
import ContainerInput from "../../components/ContainerInput";
import { formatMoney } from "../../functions/formatMoney";
import { useAppContext } from "./state.ticketBenefit";

const CardBenefit = () => {
  const state = useAppContext();

  const {
    data, // data from table
    loading, // loading from table
  } = state.dataBenefit;

  if (loading) {
    return <div>cargando</div>;
  }

  return (
    <ContainerInput
      type="default"
      title={data.name_benefit}
      style={{ marginBottom: 15 }}
    >
      <Grid container spacing={1} style={{ marginLeft: 6 }}>
        <Grid item xs={12} sm={2}>
          <span style={{ color: "rgb(51, 5, 141)" }}>Tipo:</span>
          <br />
          <span style={{ opacity: 0.7 }}>{data.name_typebenefit}</span>
        </Grid>
        <Grid item xs={12} sm={2}>
          <span style={{ color: "rgb(51, 5, 141)" }}>Creado:</span>
          <br />
          <span style={{ opacity: 0.7 }}>
            {dayjs(data.createdat_benefit).format("DD/MM/YYYY")}
          </span>
        </Grid>
        <Grid item xs={12} sm={2}>
          <span style={{ color: "rgb(51, 5, 141)" }}>Fecha de inicio:</span>
          <br />
          <span style={{ opacity: 0.7 }}>
            {dayjs(data.startdate_benefit).format("DD/MM/YYYY")}
          </span>
        </Grid>
        <Grid item xs={12} sm={2}>
          <span style={{ color: "rgb(51, 5, 141)" }}>Fecha de fin:</span>
          <br />
          <span style={{ opacity: 0.7 }}>
            {dayjs(data.enddate_benefit).format("DD/MM/YYYY")}
          </span>
        </Grid>
        <Grid item xs={12} sm={2}>
          <span style={{ color: "rgb(51, 5, 141)" }}>Beneficio:</span>
          <br />
          <span style={{ opacity: 0.7 }}>
            {data.typevalue_benefit
              ? `${data.value_benefit}%`
              : `${formatMoney(data.value_benefit)}`}
          </span>
        </Grid>
      </Grid>
    </ContainerInput>
  );
};

export default CardBenefit;
