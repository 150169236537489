import React, { useEffect } from "react";
import ContainerInput from "../../components/ContainerInput";
import Template from "../../components/Template";

import { deleteTask, getTask } from "../../services/task.services";
import CalendarTask from "./ui/calendar.task";
import DialogTaskView from "./ui/dialog-view.task";
import DialogTask from "./ui/dialog.task";
import DialogRescheduleTask from "./ui/dialog.reschedule.task";

export const adjustDateToUTC = (date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    0
  );
};

const Tasks = () => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogReschedule, setOpenDialogReschedule] = React.useState(false);
  const [openDialogView, setOpenDialogView] = React.useState(false);

  const [event, setEvent] = React.useState(null);
  const [events, setEvents] = React.useState([]);

  const handleObtainTask = async () => {
    try {
      const response = await getTask();

      setEvents(
        response.map((el) => {
          const startUtcDate = new Date(el.date_task);
          const endUtcDate = new Date(startUtcDate.getTime() + 60 * 60 * 1000);

          return {
            title: el.name_task,
            start: adjustDateToUTC(startUtcDate),
            end: adjustDateToUTC(endUtcDate),
            ...el,
          };
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelected = (param, type) => {
    handleSetEvent(param, type);
    setOpenDialog(true);
  };

  const handleSelectedEdit = (param, type) => {
    handleSetEvent(param, type);
    setOpenDialog(true);
  };

  const handleSelectedReschedule = (param, type) => {
    handleSetEvent(param, type);
    setOpenDialogReschedule(true);
  };

  const handleView = (param, type) => {
    handleSetEvent(param, type);
    setOpenDialogView(true);
  };

  const handleSelectedDelete = async (param) => {
    if (window.confirm("¿Estas seguro de eliminar la tarea?")) {
      await deleteTask(param.id_task);
      handleObtainTask();
      setOpenDialogView(false);
    }
  };

  const handleSetEvent = (param, type) => {
    if (type === "view") {
      const filter = events.find((el) => el.id_task === param);

      setEvent(filter);

      return;
    }

    if (type === "edit") {
      return;
    }

    if (type === "add") {
      setEvent({
        date_task: param,
        type_task: "event",
      });
      return;
    }
  };

  const handleAddTask = () => {
    setEvent(null);
    setOpenDialog(true);
  };

  useEffect(() => {
    handleObtainTask();
  }, []);

  return (
    <Template title="Reporte" style={{ position: "relative" }}>
      <div className="flex w-full justify-end p-3">
        <button
          onClick={() => handleAddTask()}
          className="bg-blue-900 rounded-md p-2 text-white text-sm hover:bg-blue-800 transition duration-300 ease-in-out w-40"
        >
          Crear tarea
        </button>
      </div>

      <ContainerInput style={{ padding: 10 }}>
        <CalendarTask
          events={events}
          onSelected={handleSelected}
          onView={handleView}
        />
      </ContainerInput>

      <DialogTaskView
        open={openDialogView}
        setOpen={setOpenDialogView}
        task={event}
        onEdit={handleSelectedEdit}
        onDelete={handleSelectedDelete}
        onReschedule={handleSelectedReschedule}
        callBack={() => {
          handleObtainTask();
        }}
      />

      {/**
       * RESCHEDULE AQUI !!!
       */}
      <DialogRescheduleTask
        open={openDialogReschedule}
        setOpen={setOpenDialogReschedule}
        task={event}
        callBack={() => {
          handleObtainTask();
        }}
      />

      <DialogTask
        task={event}
        open={openDialog}
        setOpen={setOpenDialog}
        callBack={() => {
          handleObtainTask();
        }}
      />
    </Template>
  );
};

export default Tasks;
