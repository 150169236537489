import dayjs from "dayjs";
import React, { useEffect } from "react";

import { Close } from "@mui/icons-material";
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";

import { Calendar, dayjsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

import "dayjs/locale/es";
import { getUser } from "../../services/auth.services";
import { get_subsidaries } from "../../services/empresa.services";
// import utc from "dayjs/plugin/utc";

const localizer = dayjsLocalizer(dayjs);

const CustomToolbar = (toolbar) => {
  const label = () => {
    const date = toolbar.date;
    return (
      <span className="text-xl uppercase">
        {date.toLocaleString("es-ES", { month: "long", year: "numeric" })}
      </span>
    );
  };

  return (
    <div className="rbc-toolbar">
      <div className="rbc-toolbar-label">{label()}</div>
    </div>
  );
};

const CalendarComponent = ({ subsidiary }) => {
  return (
    <div>
      <div className="text-center bg-slate-500 p-1 rounded-lg text-white">
        <h3>{subsidiary?.nom_empsede}</h3>
      </div>
      <Calendar
        localizer={{
          ...localizer,
        }}
        views={["month"]}
        defaultView="month"
        components={{
          toolbar: CustomToolbar,
        }}
        messages={{
          week: "Semana",
          work_week: "Semana de trabajo",
          day: "Día",
          month: "Mes",
          previous: "Atrás",
          next: "Después",
          today: "Hoy",
          agenda: "El Diario",
          showMore: (total) => `+${total} más`,
        }}
        events={[]}
        selectable={false}
        style={{ height: 200, width: 400 }}
      />
    </div>
  );
};

const ModalQuotationCalendar = (props) => {
  const { open, setOpen } = props;

  const [load, setLoad] = React.useState(false);
  const [subsidiary, setSubsidiary] = React.useState([]);

  const handleObtainSubsidiary = async () => {
    try {
      const response = await get_subsidaries(getUser().empresa.id_emp);
      setSubsidiary(response);
    } catch (err) {}
  };
  useEffect(() => {
    if (open) {
      handleObtainSubsidiary();
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
      <AppBar sx={{ position: "relative" }} color="secondary">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setOpen(false)}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Calendario
          </Typography>
        </Toolbar>
      </AppBar>

      <DialogContent className={`${load && "animate-pulse"} `}>
        <div className="text-center flex gap-2">
          {subsidiary.map((el) => (
            <CalendarComponent subsidiary={el} />
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          disable={load.toString()}
          color="inherit"
          variant="text"
          onClick={() => {
            setOpen(false);
          }}
        >
          Salir
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ModalQuotationCalendar;
