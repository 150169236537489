import * as React from "react";

import { Grid } from "@mui/material";

import "./style.print.account.scss";

import { getUser } from "../../../services/auth.services";

import "moment/locale/es";
import moment from "moment";
import { nameCamarero } from "../../../functions/nameCamarero";

const HeadPrint = (props) => {
  const { data, type = "" } = props;
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        {type}
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        ============================================================
      </Grid>
      <Grid item xs={12}>
        {getUser().empresa.nom_emp} <br />
        {getUser().empresa.direction_emp}
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        ============================================================
      </Grid>
      <Grid item xs={6}>
        COD. PEDIDO: {data.codigo_pedido}
      </Grid>
      <Grid item xs={6} style={{ textAlign: "right" }}>
        {moment(data.fechareg_pedido).format("DD/MM/YYYY HH:mm")}
      </Grid>
      <Grid item xs={12}>
        COD. MESA: {!data.codigo_mesa ? "DIRECTO" : `#${data.codigo_mesa}`}
      </Grid>
      <Grid item xs={12}>
        <span style={{ textTransform: "uppercase" }}>
          {nameCamarero()}: {data.usu_nombre}
        </span>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        ============================================================
      </Grid>
    </Grid>
  );
};

const DialogPrintAccount = React.forwardRef((props, ref) => {
  const {
    infoPedido,
    dataProducts,
    dataProductsCocina,
    dataProductsBartender,
    dataProductsPasteleria,
    dataProductsEntrada,
    dataFondosCocina,
  } = props;

  const data = infoPedido.data;

  const productsCocina = dataProductsCocina.data || [];
  const productsEntrada = dataProductsEntrada.data || [];
  const productsBartender = dataProductsBartender.data || [];
  const productsPasteleria = dataProductsPasteleria.data || [];
  const product = dataProducts.data;

  if (product) {
    if (product.length === 0) {
      return (
        <div ref={ref}>
          <h1>Nada para imprimir</h1>
        </div>
      );
    }

    return (
      <div ref={ref}>
        <div className="containerPrint">
          <HeadPrint data={data} />
          <Grid container spacing={1}>
            <Grid item xs={6}>
              CANTIDAD
            </Grid>
            <Grid item xs={6}>
              DESCRIPCION
            </Grid>
            {product.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <Grid item xs={4}>
                    {item.amount_plapedido}
                  </Grid>
                  <Grid item xs={8}>
                    {item.nombre_plato}
                  </Grid>
                  {item.text_plapedido ? (
                    <React.Fragment>
                      <Grid item xs={4}></Grid>
                      <Grid item xs={8}>
                        <i>({item.text_plapedido})</i>
                      </Grid>
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
              );
            })}
          </Grid>
        </div>
      </div>
    );
  } else {
    if (
      productsCocina.length === 0 &&
      productsEntrada.length === 0 &&
      productsBartender.length === 0 &&
      productsPasteleria.length === 0
    ) {
      return (
        <div ref={ref}>
          <h1>Nada para imprimir</h1>
        </div>
      );
    }

    return (
      <div ref={ref}>
        {productsEntrada.length > 0 && (
          <div className="containerPrint">
            <HeadPrint data={data} type="COCINA / ENTRADAS" />
            <Grid container spacing={1}>
              <Grid item xs={6}>
                CANTIDAD
              </Grid>
              <Grid item xs={6}>
                DESCRIPCION
              </Grid>
              {productsEntrada.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <Grid item xs={4}>
                      {item.amount_plapedido}
                    </Grid>
                    <Grid item xs={8}>
                      {item.nombre_plato}
                    </Grid>
                    {item.text_plapedido ? (
                      <React.Fragment>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={8}>
                          <i>({item.text_plapedido})</i>
                        </Grid>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                );
              })}
            </Grid>
          </div>
        )}

        {productsCocina.length > 0 && (
          <>
            <div className="containerPrint">
              <HeadPrint data={data} type="COCINA / FONDOS" />
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  CANTIDAD
                </Grid>
                <Grid item xs={6}>
                  DESCRIPCION
                </Grid>
                {productsCocina.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Grid item xs={4}>
                        {item.amount_plapedido}
                      </Grid>
                      <Grid item xs={8}>
                        {item.nombre_plato}
                      </Grid>
                      {item.text_plapedido ? (
                        <React.Fragment>
                          <Grid item xs={4}></Grid>
                          <Grid item xs={8}>
                            <i>({item.text_plapedido})</i>
                          </Grid>
                        </React.Fragment>
                      ) : null}
                    </React.Fragment>
                  );
                })}
              </Grid>
            </div>

            {dataFondosCocina.cuartoParrilla.length > 0 && (
              <div className="containerPrint">
                <HeadPrint data={data} type="COCINA / CUARTO PARRILLA" />
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    CANTIDAD
                  </Grid>
                  <Grid item xs={6}>
                    DESCRIPCION
                  </Grid>
                  {dataFondosCocina.cuartoParrilla.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Grid item xs={4}>
                          {item.amount_plapedido}
                        </Grid>
                        <Grid item xs={8}>
                          {item.nombre_plato}
                        </Grid>
                        {item.text_plapedido ? (
                          <React.Fragment>
                            <Grid item xs={4}></Grid>
                            <Grid item xs={8}>
                              <i>({item.text_plapedido})</i>
                            </Grid>
                          </React.Fragment>
                        ) : null}
                      </React.Fragment>
                    );
                  })}
                </Grid>
              </div>
            )}

            {dataFondosCocina.cuartoCaliente.length > 0 && (
              <div className="containerPrint">
                <HeadPrint data={data} type="COCINA / CUARTO CALIENTE" />
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    CANTIDAD
                  </Grid>
                  <Grid item xs={6}>
                    DESCRIPCION
                  </Grid>
                  {dataFondosCocina.cuartoCaliente.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Grid item xs={4}>
                          {item.amount_plapedido}
                        </Grid>
                        <Grid item xs={8}>
                          {item.nombre_plato}
                        </Grid>
                        {item.text_plapedido ? (
                          <React.Fragment>
                            <Grid item xs={4}></Grid>
                            <Grid item xs={8}>
                              <i>({item.text_plapedido})</i>
                            </Grid>
                          </React.Fragment>
                        ) : null}
                      </React.Fragment>
                    );
                  })}
                </Grid>
              </div>
            )}

            {dataFondosCocina.cuartoFrio.length > 0 && (
              <div className="containerPrint">
                <HeadPrint data={data} type="COCINA / CUARTO FRÍO" />
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    CANTIDAD
                  </Grid>
                  <Grid item xs={6}>
                    DESCRIPCION
                  </Grid>
                  {dataFondosCocina.cuartoFrio.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Grid item xs={4}>
                          {item.amount_plapedido}
                        </Grid>
                        <Grid item xs={8}>
                          {item.nombre_plato}
                        </Grid>
                        {item.text_plapedido ? (
                          <React.Fragment>
                            <Grid item xs={4}></Grid>
                            <Grid item xs={8}>
                              <i>({item.text_plapedido})</i>
                            </Grid>
                          </React.Fragment>
                        ) : null}
                      </React.Fragment>
                    );
                  })}
                </Grid>
              </div>
            )}
          </>
        )}

        {productsBartender.length > 0 && (
          <div className="containerPrint">
            <HeadPrint data={data} type="BARRA" />
            <Grid container spacing={1}>
              <Grid item xs={6}>
                CANTIDAD
              </Grid>
              <Grid item xs={6}>
                DESCRIPCION
              </Grid>
              {productsBartender.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <Grid item xs={4}>
                      {item.amount_plapedido}
                    </Grid>
                    <Grid item xs={8}>
                      {item.nombre_plato}
                    </Grid>
                    {item.text_plapedido ? (
                      <React.Fragment>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={8}>
                          <i>({item.text_plapedido})</i>
                        </Grid>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                );
              })}
            </Grid>
          </div>
        )}

        {productsPasteleria.length > 0 && (
          <div className="containerPrint">
            <HeadPrint data={data} type="PASTELERIA" />
            <Grid container spacing={1}>
              <Grid item xs={6}>
                CANTIDAD
              </Grid>
              <Grid item xs={6}>
                DESCRIPCION
              </Grid>
              {productsPasteleria.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <Grid item xs={4}>
                      {item.amount_plapedido}
                    </Grid>
                    <Grid item xs={8}>
                      {item.nombre_plato}
                    </Grid>
                    {item.text_plapedido ? (
                      <React.Fragment>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={8}>
                          <i>({item.text_plapedido})</i>
                        </Grid>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                );
              })}
            </Grid>
          </div>
        )}
      </div>
    );
  }
});

export default DialogPrintAccount;
