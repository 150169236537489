import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";

const StepperComponent = (props) => {
  const { steps, activeStep, isStepSkipped } = props;
  const { mesas, cliente, checkOut, reserva } = props;

  const StepsNames = {
    Mesa: mesas,
    Cliente: cliente,
    Reserva: reserva,
  };

  return (
    <Box sx={{ width: "100%" }}>
      {/* <TitleStep className="mb-6">Selecciona</TitleStep> */}
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel className="font-bold" {...labelProps}>
                <label className="font-semibold text-lg">{label}</label>{" "}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === steps.length ? (
        <div style={{ paddingTop: 20 }}>{checkOut}</div>
      ) : (
        <div style={{ paddingTop: 20 }}>
          {steps.map((label, index) => {
            if (activeStep === index) {
              return StepsNames[label];
            }

            return null;
          })}
        </div>
      )}
    </Box>
  );
};

export default StepperComponent;
