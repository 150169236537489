import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Template from "../../../components/Template";

import { checkToken } from "../../../services/auth.services";

import {
  add_producto_pedido,
  get_pedido_id,
  search_products,
} from "../../../services/pedidos.services";

import { getUser } from "../../../services/auth.services";
import { get_carta } from "../../../services/carta.services";

import ContainerInput from "../../../components/ContainerInput";
import Input from "../../../components/Forms/Input";
import LoadingPage from "../../../components/LoadingPage";
import DialogCart from "./Components/Cart";

import CategoryProduct from "./Components/CategoryProduct";
import ListProduct from "./Components/ListProduct";

import { Search, ShoppingCart } from "@mui/icons-material";
import { Badge, Button, Fab, Grid, TextField } from "@mui/material";

import { Home } from "@mui/icons-material";

import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { actionTypes } from "../../../redux/pedido/actions";

import toast from "react-hot-toast";
import { nameSockets } from "../../../functions/nameSockets";
import "./style.crear.scss";

function updatePriceReserva(data, newPrice) {
  return data.map((el) => {
    /**
     * Si el producto tiene la propiedad iskeep_price_plato o es igual a 1
     * no se actualiza el precio del producto
     * esto es para DH
     */
    if (el?.iskeep_price_plato || el?.iskeep_price_plato === 1) {
      return el;
    }

    return {
      ...el,
      precio_plato: newPrice,
    };
  });
}

const NavBack = (props) => {
  let { handleBack, info } = props;

  return (
    <nav className="flex mb-2" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
        <li className="inline-flex items-center">
          <Link
            to="/orders"
            className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-800"
          >
            <Home className="w-5 h-5 mr-1" aria-hidden="true" />
            Pedidos
          </Link>
        </li>

        <li aria-current="page">
          <div className="flex items-center">
            <svg
              className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 9 4-4-4-4"
              />
            </svg>
            <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">
              {info.codigo_pedido}
            </span>
          </div>
        </li>
      </ol>
    </nav>
  );
};

const NavSearch = (props) => {
  const {
    control,
    handleSubmit,
    handleSearchProduct,
    dataCategories = {
      data: [],
    },
  } = props;

  const newDataCategories = dataCategories.data.map((el) => ({
    value: el.id_categoriapla,
    label: el.nombre_categoriapla,
  }));

  return (
    <ContainerInput className="navSearchProduct">
      <form
        onSubmit={handleSubmit(handleSearchProduct)}
        autoComplete="off"
        style={{ marginTop: 10 }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <Controller
              name="text"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Input
                  value={value}
                  onChange={onChange}
                  type="text"
                  title="Buscar por nombre"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={10} md={4} lg={4}>
            <Controller
              name="category"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  select
                  variant="outlined"
                  value={value}
                  onChange={onChange}
                  className={`InputDefault`}
                  SelectProps={{
                    native: true,
                  }}
                >
                  <option value="">[Categoría]</option>
                  {newDataCategories.map((option, idx) => (
                    <option key={idx} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2} md={1} lg={1} style={{ display: "flex" }}>
            <Button fullWidth variant="contained" color="warning" type="submit">
              <Search />
            </Button>
          </Grid>
        </Grid>
      </form>
    </ContainerInput>
  );
};

const Crear = (props) => {
  let history = useHistory();
  const { id_pedido } = useParams();

  const { socket } = props;

  const INITIALVALUES_PEDIDO = {
    cantidad_mesa: "",
    codigo_mesa: "",
    codigo_pedido: "",
    estado_reserva: "",
    fechareg_pedido: "",
    fechareserva_reserva: "",
    id_mesa: "",
    id_pedido: "",
    nombre_estadopedido: "",
    nombre_cliente: "",
    apepaterno_cliente: "",
    apematerno_cliente: "",
  };

  const INITIALVALUES_SEARCH_PRODUCT = {
    text: "",
    category: "",
  };

  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues: INITIALVALUES_SEARCH_PRODUCT,
  });

  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.reducer_pedido);

  const [openCart, setOpenCart] = useState(false);
  const [loadPage, setLoadPage] = useState(false);
  const [dataPedido, setDataPedido] = useState(INITIALVALUES_PEDIDO);
  const [inputSearch, setInputSearch] = useState(INITIALVALUES_SEARCH_PRODUCT);

  const [dataProducts, setDataProducts] = useState({
    load: false,
    err: false,
    data: [],
    full: "",
  });

  const [dataCategories, setDataCategories] = useState({
    load: false,
    err: false,
    data: [],
  });

  const onClearProductCart = () => {
    dispatch({
      type: actionTypes.clearProduct,
    });
  };

  const handleDialogCart = (param) => {
    setOpenCart(param);
  };

  const handleBack = () => {
    history.push("/orders");
  };

  const handleGetPedido = async (id) => {
    try {
      setLoadPage(true);
      const response = await get_pedido_id(id);

      setLoadPage(false);

      if (response === "") {
        history.push("/orders");
        return null;
      }

      setDataPedido(response);

      return response;
    } catch (err) {
      setLoadPage(false);
      console.log(err);
    }
  };

  const handleSearchProduct = async (data) => {
    if (data.category !== "") {
      const { nombre_categoriapla } = dataCategories.data.find(
        (el) => el.id_categoriapla === parseInt(data.category)
      );
      setInputSearch({ ...data, category: nombre_categoriapla });
    } else {
      setInputSearch(data);
    }

    try {
      setLoadPage(true);
      setDataProducts({ load: true, err: false, data: [], full: "" });

      const response = await search_products(data);

      const newResponseToReseva =
        dataPedido?.id_reserva && Number(dataPedido?.asistentvalue_reserva) > 0
          ? updatePriceReserva(
              response,
              Number(dataPedido?.asistentvalue_reserva)
            )
          : response;

      setDataProducts({
        load: false,
        err: false,
        data: newResponseToReseva,
        full: newResponseToReseva.length > 0 ? "full" : "notfound",
      });
      setLoadPage(false);
    } catch (err) {
      setInputSearch(INITIALVALUES_SEARCH_PRODUCT);
      setDataProducts({ load: false, err: true, data: [], full: "" });
      console.log(err);
    }
  };

  const handleGetCategories = async (type = 1) => {
    try {
      setDataCategories({ load: true, err: false, data: [] });
      const response = await get_carta(getUser().empresa.id_empsede, type);
      setDataCategories({
        load: false,
        err: false,
        data: response,
      });
    } catch (err) {
      setDataCategories({ load: false, err: true, data: [] });
      console.log(err);
    }
  };

  const handleBackProduct = () => {
    setInputSearch(INITIALVALUES_SEARCH_PRODUCT);
    setDataProducts({ load: false, err: false, data: [], full: "" });

    setValue("category", "");
    setValue("text", "");
  };

  const handleSelectedCategory = (element) => {
    setValue("category", element);

    const jsonSearch = watch();

    handleSearchProduct(jsonSearch);
  };

  const handleCreatePedido = async () => {
    if (products.filter((el) => el.totAmount === 0).length > 0) {
      toast.error("Los productos tienen que tener cantidad mayor a 0");

      return null;
    }

    const NUMBER_TYPE_CAJA = dataPedido?.typecaja_pedido || 1;
    const TYPE_CAJA = NUMBER_TYPE_CAJA === 1 ? "MAIN" : "SECONDARY";

    try {
      const jsonProduct = products.map((el) => ({
        id_plato: el.id_plato,
        amount: el.totAmount,
        texto: el.texto || "",
      }));

      const json = {
        codigo_pedido: id_pedido,
        id_pedido: dataPedido.id_pedido,
        products: jsonProduct,
      };

      setLoadPage(true);

      await add_producto_pedido(json);

      // refrescar todos los productos para cocina
      socket.emit(
        nameSockets[TYPE_CAJA].client?.refreshProducts,
        getUser().empresa.id_empsede
      );

      // refrescar todos los productos de ese pedido (ID, CODIGO)
      socket.emit(nameSockets[TYPE_CAJA].client.refreshProductStatus, {
        id_empsede: getUser().empresa.id_empsede,
        cod: id_pedido,
      });

      // refresar todads las ordenes porque se ingreso uno nuevo
      socket.emit(
        nameSockets[TYPE_CAJA].client?.refreshOrders,
        getUser().empresa.id_empsede
      );

      setLoadPage(false);

      history.push(`/orders/view/${id_pedido}`);
    } catch (err) {
      setLoadPage(false);
    }
  };

  const handleClearCart = () => {
    onClearProductCart();
  };

  const initialData = async () => {
    const response = await handleGetPedido(id_pedido);

    await handleGetCategories(response?.typecaja_pedido);
    handleClearCart();
  };

  const fullProduct = dataProducts.full;

  const amuntCart = products.length;

  useEffect(() => {
    if (!checkToken()) {
      history.push("/");
      return null;
    }

    initialData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_pedido]);

  return (
    <Template title="Crear pedido">
      <LoadingPage open={loadPage} setOpen={setLoadPage} />
      <NavBack handleBack={handleBack} info={dataPedido} />
      <NavSearch
        control={control}
        handleSubmit={handleSubmit}
        handleSearchProduct={handleSearchProduct}
        dataCategories={dataCategories}
      />

      <CategoryProduct
        handleSelectedCategory={handleSelectedCategory}
        data={dataCategories}
        fullProduct={fullProduct}
      />
      <ListProduct
        data={dataProducts}
        inputSearch={inputSearch}
        fullProduct={fullProduct}
        handleBackProduct={handleBackProduct}
      />
      <Fab
        color="inherit"
        aria-label="add"
        className="CartButton"
        onClick={() => handleDialogCart(true)}
      >
        <Badge badgeContent={amuntCart} color="success">
          <ShoppingCart />
        </Badge>
      </Fab>
      <DialogCart
        open={openCart}
        setOpen={setOpenCart}
        handleCreatePedido={handleCreatePedido}
      />
    </Template>
  );
};

export default Crear;
