import React from "react";
import { Grid, Typography } from "@mui/material";

import "moment/locale/es";
import Moment from "react-moment";

const ListProduct = (props) => {
  let { id_pedido, data, handleChangeStatus, disabledButton } = props;

  if (data.length === 0) {
    return (
      <div style={{ textAlign: "center", fontSize: 14, padding: "25px 0px" }}>
        Aún no hay productos en el pedido
      </div>
    );
  }

  return data.map((el, idx) => {
    return (
      <Grid
        container
        className={`ListProduct ${
          el.ispriority_plato === 1 &&
          el.status_plapedido === "INGRESADO" &&
          "ListProduct__priority"
        }`}
        key={idx}
        onClick={() =>
          !disabledButton ? handleChangeStatus(el, id_pedido) : null
        }
        style={{
          border: "0px solid #ccc",
          cursor:
            el.status_plapedido !== "TERMINADO" ||
            el.status_plapedido !== "ENTREGADO"
              ? "pointer"
              : "default",
        }}
      >
        <Grid item xs={8} style={{ textAlign: "left" }}>
          <div className="__nombre">
            {el.amount_plapedido} {el.nombre_plato} <br />
          </div>
          <Typography
            component={"p"}
            variant={"caption"}
            style={{ wordBreak: "break-all" }}
          >
            {el.text_plapedido}
          </Typography>
          <Moment style={{ fontSize: 14, color: "green" }} format="HH:mm">
            {el.fechareg_plapedido}
          </Moment>
        </Grid>
        <Grid item xs={4} style={{ textAlign: "right" }}>
          {!disabledButton ? (
            <div
              className={`__estado ${
                el.status_plapedido === "TERMINADO" ||
                el.status_plapedido === "ENTREGADO"
                  ? "_terminado"
                  : el.status_plapedido === "EN PROCESO"
                  ? "_proceso"
                  : "_ingresado"
              } `}
            >
              {el.status_plapedido}
            </div>
          ) : null}
        </Grid>
      </Grid>
    );
  });
};

export default ListProduct;
