import * as React from "react";

import {
  Slide,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
} from "@mui/material";

// import ItemInput from "../../Components/ItemInput";

import "moment/locale/es";
import TableMesas from "../../../../orders/Apertura/Mesas/TableMesas";
import { get_mesa_disponible } from "../../../../../services/pedidos.services";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref || false} {...props} />;
});

const DialogChangeTable = (props) => {
  let { open, setOpen, callback } = props;

  const selectedTable = "";

  const [dataTable, setDataTable] = React.useState({
    data: [],
    load: false,
    err: false,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleSetSelectedTable = (element) => {
    if (element.ocupado_mesa) {
      return null;
    }

    let data = {
      id_mesa: element.id_mesa,
      codigo_mesa: element.codigo_mesa,
    };

    callback(data);
    handleClose();
  };

  React.useEffect(() => {
    const getMesas = async () => {
      try {
        setDataTable({ data: [], load: true, err: false });
        const response = await get_mesa_disponible();
        setDataTable({ data: response, load: false, err: false });
      } catch (err) {
        console.log(err);
        setDataTable({ data: [], load: false, err: true });
      }
    };

    if (open) {
      getMesas(); /* FETCH TODAS LAS MESAS */
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth
      onClose={handleClose}
      maxWidth={"sm"}
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Cambiar la mesa</DialogTitle>
      <DialogContent>
        <TableMesas
          data={dataTable}
          selectedTable={selectedTable}
          handleSetSelectedTable={handleSetSelectedTable}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="text" color="inherit">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogChangeTable;
