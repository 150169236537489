import React, { useEffect, useState } from "react";

// import { useForm, Controller } from "react-hook-form";

import {
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { AddCircle } from "@mui/icons-material";

import { Search } from "@mui/icons-material/";

import LoadingPage from "../../../../components/LoadingPage";

import "moment/locale/es";
import Moment from "react-moment";

import dayjs from "dayjs";
import NotFound from "../../../../atomic/organisms/NotFound.organisms";
import { formatMoney } from "../../../../functions/formatMoney";
import { search_reserva } from "../../../../services/reservas.services";

const columns = [
  {
    id: "id_reserva",
    label: "Código",
    minWidth: 30,
    align: "left",
    price: false,
    date: false,
  },
  {
    id: "nombre_reserva",
    id2: "apellido_paterno_reserva",
    id3: "apellido_materno_reserva",
    label: "Nombres y apellidos",
    minWidth: 140,
    align: "left",
    price: false,
    date: false,
  },

  {
    id: "fechareserva_reserva",
    label: "Fecha reserva",
    minWidth: 80,
    align: "center",
    price: false,
    date: true,
  },
];

const dateReserva = () => {
  // const formatearFecha = (fecha) => {
  //   const newDate = new Date(fecha);
  //   const mes = newDate.getMonth() + 1;
  //   const dia = newDate.getDate();
  //   return `${newDate.getFullYear()}-${(mes < 10 ? "0" : "").concat(
  //     mes
  //   )}-${(dia < 10 ? "0" : "").concat(dia)}`;
  // };

  const formetearFechaDayJs = (fecha) => {
    return dayjs(fecha).format("YYYY-MM-DD");
  };

  return {
    fechaInicio: dayjs().toDate(),
    fechaFin: dayjs().toDate(),
    formatearFecha: formetearFechaDayJs,
  };
};

/*
  TABLE LIST PRODUCT
*/

const TableReserva = (props) => {
  const { dataReserva, selectedReserva, handleSetSelectedReserva } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const toUpperCaseFilter = (d) => {
    if (!d) {
      return d;
    }

    if (d === "") {
      return d;
    }

    return d.toUpperCase();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
  }, []);

  if (dataReserva.load) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress color="inherit" /> <br />
        <Typography component="h5" variant="h5" style={{ textAlign: "center" }}>
          Cargando...
        </Typography>
      </div>
    );
  }

  if (dataReserva.err) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <NotFound title="¡Ocurrió un problema!" />
      </div>
    );
  }

  if (dataReserva.data.length === 0) {
    return <NotFound texto="No se encontró registros." />;
  }

  const ColumsTable = (props) => {
    let { row } = props;
    let {
      id,
      id2 = null,
      id3 = null,
      format,
      price,
      align,
      date,
    } = props.colum;

    const value = row[id];

    if (format && typeof value === "number") {
      return (
        <TableCell align={align} {...props}>
          {format(value)}
        </TableCell>
      );
    }

    if (date) {
      return (
        <TableCell align={align} {...props}>
          <Moment filter={toUpperCaseFilter} format="DD/MMM/YYYY">
            {value}
          </Moment>
        </TableCell>
      );
    }

    if (price) {
      return (
        <TableCell align={align} {...props}>
          {formatMoney(value)}
        </TableCell>
      );
    }

    if (id2 && id3) {
      const value2 = row[id2];
      const value3 = row[id3];
      return (
        <TableCell align={align} {...props}>
          {value} {value2} {value3}
        </TableCell>
      );
    }
    if (id2) {
      const value2 = row[id2];
      return (
        <TableCell align={align} {...props}>
          {value} {value2}
        </TableCell>
      );
    }

    return (
      <TableCell align={align} {...props}>
        {value}
      </TableCell>
    );
  };

  return (
    <>
      <TableContainer
        sx={{ maxHeight: 640 }}
        style={{ border: "1px solid #e0e0e0", marginTop: 10 }}
      >
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {columns.map((column, idx) => (
                <TableCell
                  key={idx}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {dataReserva.data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => {
                const isItemSelected =
                  selectedReserva.id_reserva === row.id_reserva;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={idx}
                    className={` ${isItemSelected && "mesaSelected"} `}
                  >
                    {columns.map((column, idx) => (
                      <ColumsTable
                        style={{ textTransform: "capitalize" }}
                        key={idx}
                        row={row}
                        colum={column}
                      />
                    ))}
                    <TableCell padding="checkbox">
                      <IconButton
                        disabled={isItemSelected ? true : false}
                        color="success"
                        size="large"
                        onClick={() => handleSetSelectedReserva(row)}
                      >
                        <AddCircle />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage="Filas por página"
        rowsPerPageOptions={[10, 25, 100, { value: -1, label: "Todo" }]}
        component="div"
        count={dataReserva.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

const Reserva = (props) => {
  const {
    dataReserva,
    dataPedido,
    setDataReserva,
    selected,
    handleSetSelected,
  } = props;

  const [loadPage, setLoadPage] = useState(false);

  const { fechaInicio, fechaFin, formatearFecha } = dateReserva();

  const [searchReserva, setSearchReserva] = useState({
    texto: "",
    estado: "todos",
    fecha1: formatearFecha(fechaInicio),
    fecha2: formatearFecha(fechaFin),
  });

  const selectedReserva = selected.reserva;

  const handleSearchReserva = async (e = null) => {
    if (e) {
      e.preventDefault();
    }

    setLoadPage(true);
    setDataReserva({
      load: true,
      data: [],
      err: false,
    });
    try {
      const response = await search_reserva(searchReserva);

      const newResponse = response.filter((el) => {
        const el2 = dataPedido.filter(
          (el2) => Number(el2.id_reserva) === Number(el.id_reserva)
        );

        return el2.length === 0;
      });

      setDataReserva({
        load: false,
        data: newResponse,
        err: false,
      });
      setLoadPage(false);
    } catch (err) {
      setDataReserva({
        load: false,
        data: [],
        err: true,
      });
      setLoadPage(false);
    }
  };

  const handleSetSelectedReserva = (element) => {
    handleSetSelected({
      ...selected,
      reserva: {
        ...selected.reserva,
        id_reserva: element.id_reserva,
        fechareserva_reserva: element.fechareserva_reserva,
        nombres_reserva: `${element.nombre_reserva}`,
        apellidos_reserva: `${element.apellido_paterno_reserva} ${element.apellido_materno_reserva}`,
      },
      cliente: {
        ...selected.cliente,
        id_cliente: element.id_cliente,
        nombre_cliente: element.nombre_reserva,
        apepaterno_cliente: element.apellido_paterno_reserva,
        apematerno_cliente: element.apellido_materno_reserva,
        telf_cliente: element.telefono_reserva,
        correo_cliente: element.correo_reserva,
        clasificacion_cliente: element.clasificacion_cliente,
      },
    });
  };

  useEffect(() => {
    /* FETCH TODOS LOS CLIENTES */
    handleSearchReserva();
  }, []);

  return (
    <>
      {/* <TitleStep la reservaTitleStep></TitleStep>
      <br /> */}
      <Paper variant="outlined" style={{ padding: 5 }}>
        <LoadingPage open={loadPage} setOpen={setLoadPage} />
        <form onSubmit={handleSearchReserva}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} lg={12}>
              <TextField
                defaultValue={searchReserva.texto}
                fullWidth
                name="texto"
                label="Buscar por nombre..."
                className={`InputDefault `}
                onChange={(e) =>
                  setSearchReserva({
                    ...searchReserva,
                    texto: e.target.value,
                  })
                }
                InputProps={{
                  type: "search",
                  endAdornment: (
                    <IconButton
                      type="submit"
                      aria-label="search"
                      position="end"
                    >
                      <Search fontSize="inherit" />
                    </IconButton>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </form>
        <TableReserva
          dataReserva={dataReserva}
          selectedReserva={selectedReserva}
          handleSetSelectedReserva={handleSetSelectedReserva}
        />
      </Paper>
    </>
  );
};

export default Reserva;
