import React, { useState } from "react";

import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
} from "@mui/material";

import ContainerInput from "../../../components/ContainerInput";

import { Delete, Edit } from "@mui/icons-material";
import NotFound from "../../../atomic/organisms/NotFound.organisms";
import CrudDeleteCategoria from "../categoria/Dialog/ConfirmDelete/CrudDeleteCategoria";
import CrudFormCartaSecondary from "../categoria/Dialog/CrudForm/CrudFormCartaSecondary";

const ListCategory = (props) => {
  const { dataMenu, loading, onRefreshDataMenu, onSelected, onClearSelected } =
    props;

  const data = dataMenu;

  const [categorySelected, setCategorySelected] = useState(null);

  const [openCrudForm, setOpenCrudForm] = useState(false);
  const [openCrudFormDelete, setOpenCrudFormDelete] = useState(false);

  const filterData = (value) => data.find((el) => el.id_categoriapla === value);

  const handleOpenAddCategory = (value) => {
    setOpenCrudForm(value);
  };

  const handleOpenDeleteCategory = (value) => {
    setOpenCrudFormDelete(value);
  };

  const handleEdit = (value) => {
    setOpenCrudForm(true);
    setCategorySelected(filterData(value));
  };

  const handleDelete = (value) => {
    setOpenCrudFormDelete(true);

    setCategorySelected(filterData(value));
  };

  const handleSelectCategory = async (value) => {
    onSelected(value);

    setCategorySelected(filterData(value.id_categoriapla));
  };

  if (loading) {
    return (
      <h3 className="text-center text-gray-600 text-xl pt-20 pb-20 animate-pulse">
        Cargando...
      </h3>
    );
  }

  if (data.length === 0) {
    return (
      <>
        <NotFound texto="No se encontró registros." />
        <ListItemButton onClick={() => handleOpenAddCategory(true)}>
          <ListItemText
            id={"www"}
            primary={
              <Button variant="outlined" color="inherit" fullWidth>
                AGREGAR
              </Button>
            }
          />
        </ListItemButton>
        <CrudFormCartaSecondary
          open={openCrudForm}
          setOpen={handleOpenAddCategory}
          categorySelected={categorySelected}
          onRefresh={onRefreshDataMenu}
        />
      </>
    );
  }

  return (
    <>
      <List
        dense={true}
        sx={{ width: "100%" }}
        subheader={
          <ListSubheader style={{ textAlign: "left" }}>
            Categorías
          </ListSubheader>
        }
      >
        {data.map((value, idx) => {
          const labelId = `list-secondary-label-${idx}`;
          return (
            <ListItem
              key={idx}
              onClick={() => {
                handleSelectCategory(value);
              }}
              style={{
                background:
                  value.id_categoriapla === categorySelected?.id_categoriapla &&
                  "#33058d40",
              }}
              secondaryAction={
                <>
                  <IconButton color="warning" onClick={() => handleEdit(value)}>
                    <Edit />
                  </IconButton>
                  <IconButton
                    color="error"
                    disabled={value.platos.length !== 0}
                    onClick={() => handleDelete(value.id_categoriapla)}
                  >
                    <Delete />
                  </IconButton>
                </>
              }
              disablePadding
            >
              <ListItemButton>
                <ListItemText
                  id={labelId}
                  primary={`${value.nombre_categoriapla} (${value.platos.length})`}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              setCategorySelected(null);
              handleOpenAddCategory(true);
            }}
          >
            <ListItemText
              id={"asd"}
              primary={
                <Button variant="outlined" color="inherit" fullWidth>
                  AGREGAR
                </Button>
              }
            />
          </ListItemButton>
        </ListItem>
      </List>

      <CrudFormCartaSecondary
        open={openCrudForm}
        setOpen={handleOpenAddCategory}
        categorySelected={categorySelected}
        onRefresh={onRefreshDataMenu}
      />

      <CrudDeleteCategoria
        open={openCrudFormDelete}
        setOpen={handleOpenDeleteCategory}
        categorySelected={categorySelected}
        callBack={() => {
          onRefreshDataMenu();
          onClearSelected();
        }}
      />
    </>
  );
};

const CategoryMenu = ({
  onSelected,
  onRefreshDataMenu,
  dataMenu,
  loading,
  onClearSelected,
}) => {
  return (
    <>
      <ContainerInput
        style={{
          marginTop: 10,
          marginBottom: 10,
          padding: 0,
          textAlign: "center",
        }}
      >
        <ListCategory
          dataMenu={dataMenu}
          loading={loading}
          onRefreshDataMenu={() => onRefreshDataMenu()}
          onSelected={onSelected}
          onClearSelected={onClearSelected}
        />
      </ContainerInput>
    </>
  );
};

export default CategoryMenu;
