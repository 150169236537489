import React from "react";

const BodyContainer = ({ head, ...props }) => {
  return (
    <div className="BodyContainer" {...props}>
      {head}
      <div className={`MainContainer ${props.classmaincontainer}`}>{props.children}</div>
    </div>
  );
};

export default BodyContainer;
