/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";

import { get_clientes } from "../services/clientes.services";

const useClients = (type = "email", id_empsede) => {
  const [dataClient, setDataClient] = React.useState([]);

  React.useEffect(() => {
    async function obtainClients() {
      try {
        const response = await get_clientes(id_empsede);

        setDataClient(
          response.map((el) => ({
            value: el?.id_cliente,
            label: type === "email" ? el?.correo_cliente : el?.telf_cliente || "",
          }))
        );
      } catch (err) {
        console.log(err);
        setDataClient([]);
      }
    }

    obtainClients();

    return null;
  }, []);

  return dataClient;
};

export default useClients;
