import React from "react";

import Typography from "@mui/material/Typography";

const ContainerInput = ({ type = "default", title, className, ...props }) => {
  if (type === "default") {
    return (
      <div className={`ContainerInput ${className}`} {...props}>
        {title && title !== "" ? (
          <div className="ContainerInput_title">
            <Typography variant="caption">{title}</Typography>
          </div>
        ) : null}
        {props.children}
      </div>
    );
  }

  return (
    <div className={`ContainerInputPrimary ${className}`} {...props}>
      {title && title !== "" ? (
        <div className="ContainerInputPrimary_title">
          <Typography variant="caption">{title}</Typography>
        </div>
      ) : null}
      {props.children}
    </div>
  );
};

export default ContainerInput;
