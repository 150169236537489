import axios from "axios";
import { config } from "../config";

import { axiosHeader } from "../functions/axiosHeader";

const { baseurl } = config;

const URI = `${baseurl}/api/planning`;

export const addPlanning = (data) => {
  const url = `${URI}/add`;

  return axios
    .post(url, data, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const updatePlanning = (data, cod) => {
  const url = `${URI}/update/${cod}`;

  return axios
    .patch(url, data, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getPlanning = () => {
  const url = `${URI}/get`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const findPlanningByCod = (cod) => {
  const url = `${URI}/find_planning/${cod}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};
