import * as React from "react";

import {
  Slide,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref || false} {...props} />;
});

const DialogOpenAccount = (props) => {
  let { callback, open, setOpen } = props;

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    callback();
    handleClose();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth
      onClose={handleClose}
      maxWidth={"sm"}
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Abrir la cuenta</DialogTitle>
      <DialogContent>¿Estas seguro de abrir la cuenta?</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="text" color="inherit">
          Cerrar
        </Button>
        <Button onClick={() => handleConfirm()} variant="contained" color="success">
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogOpenAccount;
