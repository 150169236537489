/* eslint-disable react-hooks/exhaustive-deps */

import Button from "@mui/material/Button";
import * as React from "react";

import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";

import Chip from "@mui/material/Chip";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import { Grid, List, ListItem, Tab, Tabs } from "@mui/material/";
import Input from "../../components/Forms/Input";

import { useForm } from "react-hook-form";

import { Close } from "@mui/icons-material";

import toast from "react-hot-toast";

import {
  update_reserva_form,
  update_status_arrived,
  upload_reserva,
} from "../../services/reservas.services";

import { getUser } from "../../services/auth.services";
import { get_mesas } from "../../services/mesas.services";

import "moment/locale/es";
import Moment from "react-moment";
import { formatMoney } from "../../functions/formatMoney";
import { formatPhoneNumber } from "../../functions/formatPhoneNumber";
import FormTask from "../tasks/ui/form.task";

const toUpperCaseFilter = (d) => {
  if (!d) {
    return d;
  }

  if (d === "") {
    return d;
  }

  return d.toUpperCase();
};

const formatDate = (date) => {
  if (!date) {
    return null;
  }

  let dFormat = date.split("T");

  return dFormat[0];
};

const DialogViewData = ({
  open,
  setOpen,
  data,
  handleChangeStatus,
  handleReload,
}) => {
  const [valueTabs, setValueTabs] = React.useState(0);

  const [fileUpload, setFileUpload] = React.useState("");
  const [montoUpload, setMontoUpload] = React.useState("");
  const [imagen_reserva, setImagen_reserva] = React.useState("");

  const [dataMesas, setDataMesas] = React.useState([]);

  let initialValues = {
    id_mesa: "",
  };

  const { reset, handleSubmit } = useForm({
    defaultValues: initialValues,
  });

  const { Form, submitForm, loading } = FormTask({
    callBack: () => {
      setOpen(false);
    },
    initTask: undefined,
  });

  const [load, setLoad] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValueTabs(newValue);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async (e) => {
    e.preventDefault();

    try {
      let formData = new FormData();

      formData.append("imagen_reserva", fileUpload);
      formData.append("monto_reserva", montoUpload);
      formData.append("id_reserva", data.id_reserva);

      setLoad(true);

      const response = await upload_reserva(formData);
      setImagen_reserva(response.url);

      setLoad(false);
      await handleChangeStatus(data.id_reserva, "confirmado");
      handleReload && handleReload();
    } catch (err) {
      setLoad(false);
      console.log(err);
    }
  };

  const handleUpdateMesa = async (data) => {
    try {
      const response = await update_reserva_form(data);

      if (response.affectedRows === 1) {
        toast.success("Reserva modificada correctamente");
        setOpen(false);
        reset(initialValues);
        handleReload && handleReload();
      } else {
        toast.error("No se pudo modificar la reserva");
        setOpen(false);
        reset(initialValues);
      }
    } catch (err) {
      console.log(err.message);
      toast.error("Hubo un problema al modificar la reserva");
      setOpen(false);
    }
  };

  const handleClientArrived = async (data, id_estado_presencial) => {
    try {
      let jInput = {
        id_reserva: data.id_reserva,
        id_estado_presencial: id_estado_presencial,
        id_garzon: data.id_garzon,
      };

      const response = await update_status_arrived(jInput);

      if (response.status === 1) {
        toast.success("Estado modificado correctamente");
        handleClose();
        reset(initialValues);
        handleReload();
      } else {
        toast.error("No se pudo modificar el estado de la reserva");
        handleClose();
      }
    } catch (err) {
      console.log(err.message);
      toast.error("Hubo un problema al modificar el estado");
      handleClose();
    }
  };
  // OBTAIN DATA

  const obtain_mesa = async () => {
    try {
      let response = await get_mesas();

      let dataMesa = response.find((el) => el.id_mesa === data.id_mesa);
      setDataMesas(dataMesa);
    } catch (err) {
      setLoad(false);
      console.log(err.message);
    }
  };

  React.useEffect(async () => {
    if (open) {
      setLoad(true);
      setImagen_reserva(data.imagen_reserva);
      setMontoUpload(data.monto_reserva);

      await obtain_mesa();

      reset(data);
      setLoad(false);
    }
  }, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>
          Datos de la reserva
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {getUser().id_perfil !== 1 && (
            <Tabs value={valueTabs} onChange={handleChange} centered>
              <Tab label="Datos" />
              <Tab label="Abono" />
              <Tab label="Agenda" />
            </Tabs>
          )}

          {valueTabs === 0 && (
            <form onSubmit={handleSubmit(handleUpdateMesa)} autoComplete="off">
              <List>
                <ListItem style={{ display: "block" }}>
                  <b>Nombres:</b> <br />
                  {data.nombre_reserva} {data.apellido_paterno_reserva}{" "}
                  {data.apellido_materno_reserva}
                </ListItem>
                <ListItem style={{ display: "block" }}>
                  <b>Teléfono:</b> <br />
                  {formatPhoneNumber(data.telefono_reserva)}
                </ListItem>
                <ListItem style={{ display: "block" }}>
                  <b>Correo electrónico:</b> <br />
                  {data.correo_reserva}
                </ListItem>
                <ListItem style={{ display: "block" }}>
                  <b>Región:</b> <br />
                  {data.nombre_region}
                </ListItem>
                <ListItem style={{ display: "block" }}>
                  <b>Comuna:</b> <br />
                  {data.nombre_comuna}
                </ListItem>
                <ListItem style={{ display: "block" }}>
                  <b>Programa:</b> <br />
                  {data.pograma_reserva}
                </ListItem>
                <ListItem style={{ display: "block" }}>
                  <b>PAX:</b> <br />
                  {data.pax_reserva}
                </ListItem>
                <ListItem style={{ display: "block" }}>
                  <b>Fecha de reserva:</b> <br />
                  <Moment filter={toUpperCaseFilter} format="DD/MMM/YYYY">
                    {formatDate(data.fechareserva_reserva)}
                  </Moment>
                </ListItem>
                <ListItem style={{ display: "block" }}>
                  <b>Detalle de reserva:</b> <br />
                  {data.detalle_reserva}
                </ListItem>
                <ListItem style={{ display: "block" }}>
                  <b>Observación:</b> <br />
                  {data.observacion_reserva ? data.observacion_reserva : "-"}
                </ListItem>
                <ListItem style={{ display: "block" }}>
                  <b>Estado:</b> <br />
                  {data.estado_reserva === "cancelado" ? (
                    <Chip
                      label={toUpperCaseFilter(data.estado_reserva)}
                      color="error"
                    />
                  ) : data.estado_reserva === "confirmado" ||
                    data.estado_reserva === "re confirmado" ? (
                    <Chip
                      label={toUpperCaseFilter(data.estado_reserva)}
                      color="success"
                    />
                  ) : data.estado_reserva === "en espera" ? (
                    <Chip
                      label={toUpperCaseFilter(data.estado_reserva)}
                      color="info"
                    />
                  ) : (
                    <Chip
                      label={toUpperCaseFilter(data.estado_reserva)}
                      color="warning"
                    />
                  )}
                </ListItem>
                <ListItem style={{ display: "block" }}>
                  <b>Estado de llegada:</b> <br />
                  {data.id_estadopresencial === 1 ? (
                    <Chip label={"EN SU MESA"} color="warning" />
                  ) : data.estado_reserva === 2 ? (
                    <Chip label={"CONTACTADO"} color="success" />
                  ) : (
                    "-"
                  )}
                </ListItem>
                <ListItem style={{ display: "block" }}>
                  <b>Monto pagado:</b> <br />
                  {data.monto_reserva
                    ? formatMoney(data.monto_reserva)
                    : formatMoney(0)}
                </ListItem>
                <ListItem style={{ display: "block" }}>
                  <b>Mesa:</b> <br />
                  {dataMesas ? dataMesas.codigo_mesa : "-"}
                </ListItem>
                <hr />

                {/**
                 * SOLO SI ES ANFITRION
                 */}

                {getUser().id_perfil === 7 && !data.id_estadopresencial && (
                  <ListItem>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} lg={12}>
                        <Button
                          fullWidth
                          color="success"
                          variant="contained"
                          onClick={() => {
                            handleClientArrived(data, 1);
                          }}
                        >
                          Llego el cliente
                        </Button>
                      </Grid>
                    </Grid>
                  </ListItem>
                )}

                {/**
                 * SOLO SI ES GARZON
                 */}

                {getUser().id_perfil === 1 &&
                  data.id_estadopresencial === 1 && (
                    <ListItem>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} lg={12}>
                          <Button
                            fullWidth
                            color="success"
                            variant="contained"
                            onClick={() => {
                              handleClientArrived(data, 2);
                            }}
                          >
                            Lo deje en su mesa
                          </Button>
                        </Grid>
                      </Grid>
                    </ListItem>
                  )}

                {getUser().id_perfil !== 1 && (
                  <ListItem>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} lg={12}>
                        <Button
                          fullWidth
                          color="error"
                          variant="contained"
                          onClick={() => {
                            handleChangeStatus(data.id_reserva, "cancelado");
                            setOpen(false);
                            handleReload && handleReload();
                          }}
                        >
                          Cancelar Reservar
                        </Button>
                      </Grid>
                    </Grid>
                  </ListItem>
                )}
              </List>
            </form>
          )}

          {valueTabs === 1 &&
            (load ? (
              <h2
                style={{
                  paddingTop: 20,
                  paddingBottom: 20,
                  textAlign: "center",
                }}
              >
                Cargando...
              </h2>
            ) : (
              <form
                id="form-upload-reserva"
                encType="multipart/form-data"
                onSubmit={handleSave}
                autoComplete="off"
              >
                <Grid container spacing={2}>
                  {imagen_reserva !== null ? (
                    <Grid item xs={12} sm={12} lg={12}>
                      <iframe
                        style={{ width: "100%", height: "300px" }}
                        title="iframe"
                        src={`${imagen_reserva}`}
                      ></iframe>
                    </Grid>
                  ) : null}
                  <Grid item xs={12} sm={12} lg={12}>
                    <Input
                      name="imagen_reserva"
                      onChange={(e) => setFileUpload(e.target.files[0])}
                      type="file"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <Input
                      name="monto_reserva"
                      value={montoUpload}
                      onChange={(e) => setMontoUpload(e.target.value)}
                      type="number"
                      title="Monto"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <Button
                      fullWidth
                      color="info"
                      variant="contained"
                      type="submit"
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            ))}

          {valueTabs === 2 && (
            <>
              <Form />
              <div className="w-full flex justify-end">
                <button
                  disabled={loading}
                  type="button"
                  onClick={submitForm}
                  className="bg-blue-900 rounded-md p-2 text-white text-sm hover:bg-blue-800 transition duration-300 ease-in-out w-28"
                >
                  Aceptar
                </button>
              </div>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DialogViewData;
