/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import Dialog from "@mui/material/Dialog";

import {
  AppBar,
  Badge,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import Marca from "../../marca";

const DialogBrand = ({ open, setOpen }) => {
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
        <AppBar sx={{ position: "sticky", top: 0 }} className="AppHeader">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpen(false)}
              aria-label="close"
            >
              <Close />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Mantenedor de Marcas
            </Typography>
          </Toolbar>
        </AppBar>
        <div className="p-6">
          <Marca />
        </div>
      </Dialog>
    </>
  );
};

export default DialogBrand;
