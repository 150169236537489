import { Table, TableBody, TableCell, TableRow } from "@mui/material";

const TableMetaResume = (props) => {
  const {
    justBySeller = true,
    paxMonth = 0,
    metaMonth = 0,
    saldo = 0,
    alcanzado = 0,
  } = props;
  return (
    <div className="w-full">
      <Table
        aria-label="sticky table"
        size={"small"}
        className="!w-96 border border-gray-200"
      >
        <TableBody>
          <TableRow>
            <TableCell className="!font-semibold !text-gray-600">
              PAX vendidos en el mes
            </TableCell>
            <TableCell align="right">{paxMonth}</TableCell>
          </TableRow>
          {justBySeller && (
            <>
              <TableRow>
                <TableCell className="!font-semibold !text-gray-600">
                  Meta del mes
                </TableCell>
                <TableCell align="right">{metaMonth}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="!font-semibold !text-gray-600">
                  Saldo para la meta
                </TableCell>
                <TableCell align="right">{saldo}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="!font-semibold !text-gray-600">
                  % alcanzado
                </TableCell>
                <TableCell align="right">{alcanzado}</TableCell>
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default TableMetaResume;
