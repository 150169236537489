import { CircularProgress, Grid, Typography } from "@mui/material";

import "./style.datapedido.scss";

import "moment/locale/es";

import moment from "moment";

import NotFound from "../../../../atomic/organisms/NotFound.organisms";
import { nameCamarero } from "../../../../functions/nameCamarero";
import ItemInput from "../Components/ItemInput";

const DataPedido = (props) => {
  const { infoPedido } = props;

  const data = infoPedido.data;

  const customerName =
    data?.typecaja_pedido === 1
      ? `${data.nombre_cliente ? data.nombre_cliente : ""} ${
          data.apepaterno_cliente ? data.apepaterno_cliente : ""
        } ${data.apematerno_cliente ? data.apematerno_cliente : ""}`
      : data?.fullname_pedido || "Desconocido";

  if (infoPedido.load) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress color="inherit" /> <br />
        <Typography component="h5" variant="h5" style={{ textAlign: "center" }}>
          Cargando...
        </Typography>
      </div>
    );
  }

  if (infoPedido.err) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <NotFound title="¡Ocurrió un problema!" />
      </div>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <ItemInput
          label="Apertura"
          value={`${moment(data.fechareg_pedido).format("DD/MM/YYYY HH:mm")}`}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ItemInput label="Cliente" value={customerName} fullWidth />
      </Grid>
      {/* <Grid item xs={12} sm={6}>
        <ItemInput
          label="Clasificación"
          value={`${data.clasificacion_cliente || "Sin clasificación"}`}
          fullWidth
        />
      </Grid> */}
      <Grid item xs={12} md={6}>
        <ItemInput label="Cod. Pedido" value={data.codigo_pedido} fullWidth />
      </Grid>
      {/* <Grid item xs={6}>
        <ItemInput label="Cod. Reserva" value={data.id_reserva} />
      </Grid> */}
      <Grid item xs={12} md={6}>
        <ItemInput
          label={nameCamarero()}
          value={data.usu_nombre}
          style={{ textTransform: "inherit" }}
          fullWidth
        />
      </Grid>
      {/* <Grid item xs={6} md={6}>
        <ItemInput label="Cod. Mesa" value={data.codigo_mesa} fullWidth />
      </Grid> */}
      {/* <Grid item xs={6} md={6}>
        <ItemInput label="Personas" value={data.cantidad_mesa} fullWidth />
      </Grid> */}

      {/* {data.id_estadopedido > 3 && (
        <Grid item xs={12}>
          <ItemInput
            label="Estado"
            value={data.nombre_estadopedido}
            style={{ width: "300px" }}
          />
        </Grid>
      )} */}
    </Grid>
  );
};

export default DataPedido;
