/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";

import { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import NotFound from "../../atomic/organisms/NotFound.organisms";
import Head from "../../components/Head";
import LoadingPage from "../../components/LoadingPage";
import {
  get_data_company,
  get_empresas,
} from "../../services/empresa.services";
import ListSucursales from "./list-subsidiaries";
import ReservarVerdeMostaza from "./reservarVerdeMostaza";
import DialogSubsidiarySelect from "./dialogSubsidiarySelect";
import { useState } from "react";
import { getQueryParams } from "../../functions/getQueryParams";

const Html = (props) => {
  const { title } = props;
  return (
    <div className="carta">
      <Toaster />
      <Head>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content="POS" />
        <meta property="og:title" content={title} />
      </Head>
      <div className="container-carta">{props.children}</div>
    </div>
  );
};

const PanelReserva = () => {
  let { empresa } = useParams();

  const ID_COMPANIES = {
    VERDE_MOSTAZA: 2,
  };

  const [openDialogSubsidiarySelect, setOpenDialogSubsidiarySelect] =
    useState(false);

  const [dataEmpresa, setDataEmpresa] = useState({
    load: false,
    data: [],
  });

  const [dataSucursales, setDataSucursales] = useState({
    load: false,
    data: [],
  });

  const [selectedSucursal, setSelectedSucursal] = useState(undefined);
  const [nameSelectedSucursal, setNameSelectedSucursal] = useState(undefined);

  const obtainCompany = async () => {
    try {
      setDataEmpresa({
        load: true,
        data: null,
      });
      const response = await get_data_company(empresa);

      setDataEmpresa({
        load: false,
        data: response,
      });
    } catch (err) {}
  };

  const obtainSucursales = async () => {
    try {
      setDataSucursales({
        load: true,
        data: [],
      });
      const response = await get_empresas(empresa);
      setDataSucursales({
        load: false,
        data: response,
      });
    } catch (err) {}
  };

  useEffect(() => {
    async function obtainData() {
      await obtainCompany();
      await obtainSucursales();
    }

    obtainData();
  }, []);

  useEffect(() => {
    if (dataEmpresa?.data?.id_emp === ID_COMPANIES.VERDE_MOSTAZA) {
      const queryParams = getQueryParams();

      if (queryParams.get("name")) {
        if (dataSucursales?.data.length > 0) {
          const findSucursal = dataSucursales.data.find(
            (item) =>
              item.alias_empsede.trim().split("_")[1] ===
              queryParams.get("name").trim()
          );

          // DARLE DISEÑO COMO SE BUSCA EN EL FIGMA
          setOpenDialogSubsidiarySelect(
            findSucursal?.id_empsede <= 0 || !findSucursal?.id_empsede
          );

          setSelectedSucursal(findSucursal?.id_empsede);
          setNameSelectedSucursal(queryParams.get("name").trim());
        }
      } else {
        setOpenDialogSubsidiarySelect(true);
      }
    }
  }, [dataSucursales]);

  if (dataEmpresa.load === true) {
    return (
      <Html title={"Reservar"}>
        <LoadingPage open={true} />
      </Html>
    );
  }

  if (dataEmpresa.data.length === 0) {
    return (
      <Html title={"Reservar"}>
        <div
          style={{
            textAlign: "center",
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
            display: "flex",
            height: "100%",
            alignItems: "center",
          }}
        >
          <div style={{ margin: "auto" }}>
            <NotFound title="No se encontró la carta" />
          </div>
        </div>
      </Html>
    );
  }

  // ONLY IF IS VERDE MOSTAZA
  if (dataEmpresa?.data?.id_emp === ID_COMPANIES.VERDE_MOSTAZA) {
    return (
      <>
        <Head>
          <title>{`Reservar | Verde Mostaza`}</title>
          {/* Meta Pixel Code */}
          {/* End Meta Pixel Code */}
        </Head>
        <main>
          <Toaster />
          <ReservarVerdeMostaza
            idEmp={dataEmpresa?.data?.id_emp}
            selectedSucursal={selectedSucursal}
            nameSelectedSucursal={nameSelectedSucursal}
          />
          <DialogSubsidiarySelect
            id_emp={dataEmpresa?.data?.id_emp}
            open={openDialogSubsidiarySelect}
            setOpen={setOpenDialogSubsidiarySelect}
          />
        </main>
      </>
    );
  }

  return (
    <div className="container">
      <Toaster />
      <Head>
        <title>{`Reservar | Ubuntu`}</title>
      </Head>
      <main className="Login">
        <div
          className="Login__Body"
          style={{
            display: "block",
            height: "auto",
            paddingTop: "30px",
            paddingBottom: "30px",
          }}
        >
          {dataEmpresa.data.logo_emp && (
            <div style={{ textAlign: "center", marginBottom: 15 }}>
              <img src={dataEmpresa.data.logo_emp} width="150" alt="logo" />
            </div>
          )}
          <div className="Login_Container">
            <ListSucursales data={dataSucursales} />
          </div>
        </div>
      </main>
    </div>
  );
};

export default PanelReserva;
