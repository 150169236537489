import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Template from "../../components/Template";

import { checkToken, getUser } from "../../services/auth.services";

/**
 * Components
 */

import HistoryModal from "./modal.orderHistory";
import HistorySearch from "./search.orderHistory";
import HistoryTable from "./table.orderHistory";

/**
 * State
 */
import ClientHistoryState from "./state.orderHistory";

const ClientHistory = (props) => {
  let history = useHistory();

  const isRCSDH = getUser()?.empresa?.id_emp === 4;

  console.log(isRCSDH);

  useEffect(() => {
    if (!checkToken()) {
      history.push("/");
      return null;
    }
  }, []);

  return (
    <ClientHistoryState>
      <Template title="Historial de caja">
        <HistorySearch />
        <HistoryTable isRCSDH={isRCSDH} />
        <HistoryModal />
      </Template>
    </ClientHistoryState>
  );
};

export default ClientHistory;
