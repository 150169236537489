import axios from "axios";
import { config } from "../config";

import { axiosHeader } from "../functions/axiosHeader";

const { baseurl } = config;

const uri = "empresa";

export const get_empresa_id = (id) => {
  const url = `${baseurl}/api/${uri}/get_empresa/${id}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_subsidaries = (id) => {
  const url = `${baseurl}/api/${uri}/get/subsidaries/${id}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_empresa_alias = (alias) => {
  const url = `${baseurl}/api/${uri}/get_empresa_alias/${alias}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_empresas = (nameCompany) => {
  const url = `${baseurl}/api/${uri}/get_empresa/company/${nameCompany}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_empresa_sucursal = () => {
  const url = `${baseurl}/api/${uri}/get_empresa_sucursal`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_empresa = (nameCompany) => {
  const url = `${baseurl}/api/${uri}/get_empresa/${nameCompany}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_data_company = (nameCompany) => {
  const url = `${baseurl}/api/${uri}/get_data_company/${nameCompany}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_data_company_all = () => {
  const url = `${baseurl}/api/${uri}/get_data_company_all`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_all_compaines = () => {
  const url = `${baseurl}/api/empresa/get_all_companies`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
}


export const add_empresa = (data) => {
  const url = `${baseurl}/api/${uri}/add`;

  return axios
    .post(url, data, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const update_empresa = (data, idx) => {
  const url = `${baseurl}/api/${uri}/update/${idx}`;

  return axios
    .put(url, data, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const delete_empresa = (idx) => {
  const url = `${baseurl}/api/${uri}/delete/${idx}`;

  return axios
    .delete(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};