import { config } from "../config";
import axios from "axios";

const { baseurl } = config;

export const get_carta = (id_empsede, type = 1) => {
  const url = `${baseurl}/api/carta/get/${id_empsede}/${type}`;

  return axios
    .get(url)
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};
