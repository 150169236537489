/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import ContainerInput from "../../../components/ContainerInput";

import { Grid, Typography } from "@mui/material";

const totRow = (dataReserva, type) => {
  const totRow = type
    ? dataReserva.data.filter((el) => el.estado_reserva === type)
    : dataReserva.data;

  return {
    sum: totRow.reduce((a, b) => a + b.pax_reserva, 0),
    data: dataReserva.data.reduce((a, b) => a + b.pax_reserva, 0),
    paxTotal: totRow?.reduce((a, b) => a + b.pax_reserva, 0),
  };
};

const TotalesReservas = ({ dataReserva }) => {
  const totConfirmado = totRow(dataReserva, "confirmado");
  const totPendiente = totRow(dataReserva, "pendiente");
  const totEnEspera = totRow(dataReserva, "en espera");
  const totCancelado = totRow(dataReserva, "cancelado");
  const totPax = totRow(dataReserva, null).paxTotal;
  const totConfirmadoPendiente =
    Number(totRow(dataReserva, "confirmado").sum) +
    Number(totRow(dataReserva, "pendiente").sum);

  return (
    <ContainerInput
      style={{
        marginTop: 5,
        marginBottom: -11,
        padding: "5px ​5px 17px",
        textAlign: "center",
      }}
    >
      <Grid
        container
        spacing={1}
        style={{ fontSize: 14, textAlign: "center" }}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12} sm={2} lg={2} style={{ textAlign: "center" }}>
          <div
            style={{
              width: 10,
              height: 10,
              background: "black",
              display: "inline-block",
              marginRight: 3,
              borderRadius: 3,
            }}
          ></div>
          <Typography variant="inherit" component={"label"}>
            Total PAX:
          </Typography>{" "}
          <Typography variant="inherit" component={"b"}>
            {totPax}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2} lg={2} style={{ textAlign: "center" }}>
          <div
            style={{
              width: 10,
              height: 10,
              background: "brown",
              display: "inline-block",
              marginRight: 3,
              borderRadius: 3,
            }}
          ></div>
          <Typography variant="inherit" component={"label"}>
            Total Confirmado + Pendiente:
          </Typography>{" "}
          <Typography variant="inherit" component={"b"}>
            {totConfirmadoPendiente}
          </Typography>
        </Grid>
        <Grid item xs={3} sm={2} lg={2} style={{ textAlign: "center" }}>
          <div
            style={{
              width: 10,
              height: 10,
              background: "green",
              display: "inline-block",
              marginRight: 3,
              borderRadius: 3,
            }}
          ></div>
          <Typography variant="inherit" component={"label"}>
            Confirmados:
          </Typography>{" "}
          <Typography variant="inherit" component={"b"}>
            {totConfirmado.sum}
          </Typography>
        </Grid>
        <Grid item xs={3} sm={2} lg={2} style={{ textAlign: "center" }}>
          <div
            style={{
              width: 10,
              height: 10,
              background: "orange",
              display: "inline-block",
              marginRight: 3,
              borderRadius: 3,
            }}
          ></div>
          <Typography variant="inherit" component={"label"}>
            Pendiente:
          </Typography>{" "}
          <Typography variant="inherit" component={"b"}>
            {totPendiente.sum}
          </Typography>
        </Grid>
        <Grid item xs={3} sm={2} lg={2} style={{ textAlign: "center" }}>
          <div
            style={{
              width: 10,
              height: 10,
              background: "blue",
              display: "inline-block",
              marginRight: 3,
              borderRadius: 3,
            }}
          ></div>
          <Typography variant="inherit" component={"label"}>
            En espera:
          </Typography>{" "}
          <Typography variant="inherit" component={"b"}>
            {totEnEspera.sum}
          </Typography>
        </Grid>
        <Grid item xs={3} sm={2} lg={2} style={{ textAlign: "center" }}>
          <div
            style={{
              width: 10,
              height: 10,
              background: "red",
              display: "inline-block",
              marginRight: 3,
              borderRadius: 3,
            }}
          ></div>
          <Typography variant="inherit" component={"label"}>
            Cancelados:
          </Typography>{" "}
          <Typography variant="inherit" component={"b"}>
            {totCancelado.sum}
          </Typography>
        </Grid>
      </Grid>
    </ContainerInput>
  );
};

export default TotalesReservas;
