/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";

import {
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from "@mui/material/";

import { Alert, Collapse } from "@mui/material";

import { Close, Delete } from "@mui/icons-material";

import toast from "react-hot-toast";

import {
  add_asignacion,
  update_asignacion,
  delete_asignacion,
  get_asignacion_id,
} from "../../services/plazas.services";
import { nameCamarero } from "../../functions/nameCamarero";

const ErrorMsg = ({ wrong, setWrong, message }) => {
  return (
    <Collapse in={wrong}>
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setWrong(false);
            }}
          >
            <Close fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
        severity="error"
      >
        {message}
      </Alert>
    </Collapse>
  );
};

const DialogCrudPlazasSelected = ({
  open,
  setOpen,
  data,
  setData,
  handleGetPlazaDetalle,
  dataMesas,
  dataGarzones,
}) => {
  const [wrong, setWrong] = React.useState({ open: false, message: "" });

  const [selectedIPlazaDetalle, setSelectedIPlazaDetalle] = useState("");
  const [selectedGarzones, setSelectedGarzones] = useState([]);
  const [selectedMesas, setSelectedMesas] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddPlazaSelected = async (e) => {
    e.preventDefault();

    //FALTA AGREGAR AQUI A LA BD

    // if (selectedFechas.fecha_inicio === "" || selectedFechas.fecha_fin === "") {
    //   setWrong({ open: true, message: "Debe ingresar las fechas (*)" });
    //   return null;
    // }

    if (selectedGarzones.length === 0) {
      setWrong({ open: true, message: "Debe seleccionar garzones (*)" });
      return null;
    }

    if (selectedMesas.length === 0) {
      setWrong({ open: true, message: "Debe seleccionar mesas (*)" });
      return null;
    }

    try {
      let response;

      if (selectedIPlazaDetalle !== "") {
        let jsonData = {
          id_plaza: data.id_plaza,
          id_plaza_detalle: selectedIPlazaDetalle,
          nombre_plaza: data.nombre_plaza,
          garzones: selectedGarzones,
          mesas: selectedMesas,
        };

        response = await update_asignacion(jsonData);

        if (response) {
          toast.success("Plaza modificada correctamente");
          setOpen(false);
        } else {
          toast.error("No se pudo modificar la plaza");
          setOpen(false);
        }
      } else {
        let jsonData = {
          nombre_plaza: data.nombre_plaza,
          garzones: selectedGarzones,
          mesas: selectedMesas,
        };

        response = await add_asignacion(jsonData);

        if (response) {
          toast.success("Ingresado correctamente.");
          setOpen(false);
        } else {
          toast.error("Ops! Algo salió mal.");
          setOpen(false);
        }
      }

      handleGetPlazaDetalle();
    } catch (err) {
      console.log(err.message);
      toast.error("Hubo un error al crear la plaza");
      setOpen(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await delete_asignacion(id);
      toast.success("Se eliminó correctamente");
      setOpen(false);

      handleGetPlazaDetalle();
    } catch (err) {
      toast.error("No se pudo eliminar");
    }
  };

  const handleSelected = (type, data, e) => {
    if (type === "garzon") {
      if (e.target.checked) {
        setSelectedGarzones([...selectedGarzones, data]);
      } else {
        setSelectedGarzones(
          selectedGarzones.filter(
            (item) => item.id_garzones !== data.id_garzones
          )
        );
      }
    }

    if (type === "mesa") {
      if (e.target.checked) {
        setSelectedMesas([...selectedMesas, data]);
      } else {
        setSelectedMesas(
          selectedMesas.filter((item) => item.id_mesa !== data.id_mesa)
        );
      }
    }

    return null;
  };

  const handleGetAsignacionID = async (id) => {
    try {
      let respsonse = await get_asignacion_id(id);

      setSelectedGarzones(respsonse.garzones);
      setSelectedMesas(respsonse.mesa);
      setSelectedIPlazaDetalle(respsonse.id_plaza_detalle);
    } catch (err) {
      console.log(err);
      //setLoadPage(false);
    }
  };

  React.useEffect(() => {
    if (open) {
      if (data.id_plaza) {
        handleGetAsignacionID(data.id_plazadetalle);
      } else {
        setSelectedGarzones([]);
        setSelectedMesas([]);
        setSelectedIPlazaDetalle("");
      }
    }
  }, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"sm"}>
        <form onSubmit={handleAddPlazaSelected} autoComplete="off">
          <DialogTitle>
            {data.id_plaza_detalle ? "Editar" : "Agregar"}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <br />
            <ErrorMsg
              wrong={wrong.open}
              setWrong={(el) => setWrong({ ...wrong, open: el })}
              message={wrong.message}
            />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} lg={12}>
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  className={`InputDefault`}
                  type={"text"}
                  value={data.nombre_plaza}
                  label="Nombre de plaza"
                  onChange={(event) =>
                    setData({
                      ...data,
                      nombre_plaza: event.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <TableContainer
                  style={{
                    height: 200,
                    border: "1px #eee solid",
                    overflow: "auto",
                  }}
                >
                  <Table
                    size="small"
                    xs={{ minWidth: 650 }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">{nameCamarero()}</TableCell>
                        <TableCell style={{ width: 50 }}>Selecciona</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataGarzones.data.map((row, idx) => (
                        <TableRow key={idx}>
                          <TableCell component="th">
                            {row.nombre_garzones} {row.apellido_garzones}
                          </TableCell>
                          <TableCell style={{ width: 50 }}>
                            <Checkbox
                              checked={selectedGarzones.some(
                                (el) => el.id_garzones === row.id_garzones
                              )}
                              onChange={(e) => handleSelected("garzon", row, e)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* <TransferList title="Garzones" dataLeft={dataMesas.data} /> */}
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <TableContainer
                  style={{ height: 200, border: "1px #eee solid" }}
                >
                  <Table
                    size="small"
                    xs={{ minWidth: 650 }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Mesas</TableCell>
                        <TableCell style={{ width: 50 }}>Selecciona</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataMesas.data.map((row, idx) => (
                        <TableRow key={idx}>
                          <TableCell component="th">
                            {row.codigo_mesa}
                          </TableCell>
                          <TableCell style={{ width: 50 }}>
                            <Checkbox
                              checked={selectedMesas.some(
                                (el) => el.id_mesa === row.id_mesa
                              )}
                              onChange={(e) => handleSelected("mesa", row, e)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* <TransferList title="Mesas" dataLeft={dataMesas.data} /> */}
              </Grid>

              {/* <Grid item xs={12} sm={6} lg={6}>
                Fecha inicio
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  className={`InputDefault`}
                  type={"date"}
                  value={selectedFechas.fecha_inicio}
                  InputProps={{
                    inputProps: { max: selectedFechas.fecha_fin },
                  }}
                  onChange={(event) =>
                    setSelectedFechas({
                      ...selectedFechas,
                      fecha_inicio: event.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                Fecha fin
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  className={`InputDefault`}
                  type={"date"}
                  value={selectedFechas.fecha_fin}
                  InputProps={{
                    inputProps: { min: selectedFechas.fecha_inicio },
                  }}
                  onChange={(event) =>
                    setSelectedFechas({
                      ...selectedFechas,
                      fecha_fin: event.target.value,
                    })
                  }
                />
              </Grid> */}
            </Grid>
          </DialogContent>
          <DialogActions>
            {data.id_plaza_detalle && (
              <Button
                onClick={() => handleDelete(data.id_plaza_detalle)}
                color="error"
                variant="contained"
                style={{ position: "absolute", left: "14px", bottom: "8px" }}
              >
                <Delete /> Eliminar
              </Button>
            )}

            <Button
              color="info"
              variant="text"
              onClick={() => {
                setOpen(false);
              }}
            >
              Salir
            </Button>
            <Button type="submit" color="success" variant="contained">
              Aceptar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default DialogCrudPlazasSelected;
