import { useParams } from "react-router-dom";
import Template from "../../components/Template";

import TicketCardBenefit from "./cardBenefit.ticketBenefit";
import TicketBenefitModal from "./modal.ticketBenefit";
import TicketBenefitSearch from "./search.ticketBenefit";
import TicketBenefitState from "./state.ticketBenefit";
import TicketBenefitTable from "./table.ticketBenefit";
import TicketBredCrumb from "./ticketBredCrumb.ticketBenefit";
import TicketBenefitRedeemModal from "./modalRedeem.ticketBenefit";
import TicketBenefitDeleteModal from "./modalDelete.ticketBenefit";

const TciketBenefit = () => {
  let { id } = useParams();

  return (
    <TicketBenefitState idTicketBenefit={id}>
      <Template title="Tickets de beneficios">
        <TicketBredCrumb />
        <TicketCardBenefit />
        <TicketBenefitSearch />
        <TicketBenefitTable />
        <TicketBenefitModal />
        <TicketBenefitRedeemModal />
        <TicketBenefitDeleteModal />
      </Template>
    </TicketBenefitState>
  );
};

export default TciketBenefit;
