import {
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useAppContext } from "./state.clientHistory";

import { Visibility } from "@mui/icons-material";

import "moment/locale/es";
import Moment from "react-moment";
import ClientClasification from "../../atomic/atoms/clientClasification.atoms";
import NotFound from "../../atomic/organisms/NotFound.organisms";
import ContainerInput from "../../components/ContainerInput";
import { formatPhoneNumber } from "../../functions/formatPhoneNumber";

const CustomTable = ({ rows, isLoad, openViewData, onIdSelected }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSelected = (id) => {
    openViewData();
    onIdSelected(id);
  };

  if (isLoad) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress color="inherit" /> <br />
        <Typography component="h5" variant="h5" style={{ textAlign: "center" }}>
          Cargando...
        </Typography>
      </div>
    );
  }

  if (rows.length === 0) {
    return <NotFound texto="No se encontró registros." />;
  }

  return (
    <>
      <TableContainer sx={{ maxHeight: 550 }}>
        <Table stickyHeader aria-label="sticky table" size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell align={"left"} style={{ minWidth: 220 }}>
                Nombres
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 150 }}>
                Clasificación
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 190 }}>
                Última visita
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 190 }}>
                Número de teléfono
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 190 }}>
                Correo electrónico
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 190 }}>
                Cantidad de visitas
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 190 }}>
                Motivo de visita
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 190 }}>
                Segmentación
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 190 }}>
                Fecha creación
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 80 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                  <TableCell align={"left"} className="capitalize">
                    {row?.fullname_cliente.toLowerCase()}
                  </TableCell>
                  <TableCell align={"left"}>
                    <ClientClasification type={row?.clasificacion_cliente} />
                  </TableCell>
                  <TableCell>
                    <Moment
                      format="DD/MM/yyyy HH:mm:ss"
                    >
                      {row?.last_visite}
                    </Moment>
                  </TableCell>
                  <TableCell align={"left"}>
                    {formatPhoneNumber(row.telf_cliente)}
                  </TableCell>
                  <TableCell align={"left"}>{row.correo_cliente}</TableCell>
                  <TableCell align={"left"}>{row.tot_history}</TableCell>
                  <TableCell align={"left"} className="capitalize">{row.pograma_reserva}</TableCell>
                  <TableCell align={"left"} className="capitalize">{row.name_rservagrupa}</TableCell>
                  <TableCell align={"left"}>
                    <Moment format="DD/MM/yyyy HH:mm:ss">
                      {row.fecharegistro_cliente}
                    </Moment>
                  </TableCell>
                  <TableCell align={"center"}>
                    <IconButton
                      size="small"
                      color="secondary"
                      onClick={() => handleSelected(row.id_cliente)}
                    >
                      <Visibility />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage="Filas por página"
        rowsPerPageOptions={[0, 30, 60, 90, 120, { value: -1, label: "Todo" }]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

const HistoryTable = () => {
  const state = useAppContext();

  return (
    <ContainerInput
      style={{
        marginTop: 20,
        marginBottom: 20,
        padding: "0px",
        textAlign: "center",
        borderRadius: "0px",
      }}
    >
      <CustomTable
        rows={state.data}
        isLoad={state.isLoadData}
        openViewData={state.onChangeOpenDataClient}
        onIdSelected={state.onIdSelected}
      />
    </ContainerInput>
  );
};

export default HistoryTable;
