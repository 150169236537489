import axios from "axios";
import { config } from "../config";

import { axiosHeader } from "../functions/axiosHeader";

const { baseurl } = config;

export const get_plato = () => {
  const url = `${baseurl}/api/plato/get/`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_plato_secondary = (id) => {
  const url = `${baseurl}/api/plato/secondary/get/${id}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const add_plato = (params, type = 1) => {
  const url = `${baseurl}/api/plato/add/${type}`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const update_plato = (params) => {
  const url = `${baseurl}/api/plato/update`;

  return axios
    .put(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const delete_plato = (id) => {
  const url = `${baseurl}/api/plato/delete/${id}/`;

  return axios
    .delete(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const delete_insumo_plato = (id) => {
  const url = `${baseurl}/api/plato/productos/delete/${id}/`;

  return axios
    .delete(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const search_plato = (params) => {
  const url = `${baseurl}/api/plato/search/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const search_producto = (params) => {
  const url = `${baseurl}/api/plato/producto/search/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};
