import React from "react";

import { Grid } from "@mui/material";

import AutoComplete from "../molecule/autocomplete.molecule";

const searchAutocomplete = (props) => {
  const { options, label, setValue, value } = props;
  return (
    <AutoComplete
      setValue={setValue}
      value={value}
      label={label}
      options={options}
    />
  );
};

export default searchAutocomplete;
