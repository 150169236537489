import { useState, useEffect } from "react";

import NotExistOpenBox from "./NotExistBoxOpen";
import BoxClosed from "./BoxClosed";

import LoadingPage from "./LoadingPage";

import { get_caja } from "../services/caja.services";

const ToDayBox = (props) => {
  const [cajaData, setCajaData] = useState(false);

  const { open, setOpen } = props;

  const handleAperturaCaja = async () => {
    try {
      const param = "oneday";

      setOpen(true);
      const response = await get_caja(param);
      setOpen(false);

      setCajaData(response[0] ? response[0] : false);
    } catch (err) {
      setCajaData(false);
      console.log(err);
    }
  };

  useEffect(() => {
    handleAperturaCaja();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (cajaData && cajaData.status_caja === "CERRADO") {
    return (
      <>
        <LoadingPage open={open} setOpen={setOpen} />
        <BoxClosed created_cierre_caja={cajaData.created_cierre_caja} />
      </>
    );
  }

  if (!cajaData) {
    return (
      <>
        <LoadingPage open={open} setOpen={setOpen} />
        <NotExistOpenBox />
      </>
    );
  }

  return (
    <>
      <LoadingPage open={open} setOpen={setOpen} />
      {props.children}
    </>
  );
};

export default ToDayBox;
