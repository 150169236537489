import axios from "axios";
import { config } from "../config";

import { axiosHeader } from "../functions/axiosHeader";

const { baseurl } = config;

/**
 * CAMPAIGN
 */

export const getBenefit = (params) => {
  const url = `${baseurl}/api/benefit/${params || ""}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getBenefitById = (id) => {
  const url = `${baseurl}/api/benefit/${id}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const addBenefit = (params) => {
  const url = `${baseurl}/api/benefit/create`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const updateBenefit = (id_benefit, params) => {
  const url = `${baseurl}/api/benefit/update/${id_benefit}`;

  return axios
    .put(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deleteBenefit = (id_benefit) => {
  const url = `${baseurl}/api/benefit/delete/${id_benefit}`;

  return axios
    .delete(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};
