import * as React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
} from "@mui/material";

import "moment/locale/es";

import { Controller, useForm } from "react-hook-form";

import InputDefault from "../../../../../atomic/atoms/input.atoms";
import { getComunas } from "../../../../../functions/utilidades.function";
import { update_cliente } from "../../../../../services/clientes.services";
import LoadingPage from "../../../../../components/LoadingPage";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref || false} {...props} />;
});

const initalValues = {
  id_cliente: "",
  rut_cliente: "",
  nombre_cliente: "",
  apepaterno_cliente: "",
  apematerno_cliente: "",
  telf_cliente: "",
  correo_cliente: "",
  fecharegistro_cliente: "",
  clasificacion_cliente: "",
  region_cliente: "",
  comuna_cliente: "",
  address_cliente: "",
};

const DialogInfoClient = (props) => {
  const { data, open, setOpen, callback } = props;

  const { control, handleSubmit, watch, reset } = useForm({
    defaultValues: initalValues,
  });

  const [load, setLoad] = React.useState(false);

  const dataRegionComuna = getComunas();

  const handleClose = () => {
    setOpen(false);
  };

  const onAction = async (data) => {
    try {
      setLoad(true);

      delete data.clasificacion_cliente;

      await update_cliente(data, data.id_cliente);
      await callback();

      setLoad(false);
      setOpen(false);
    } catch (err) {
      setLoad(false);
      console.log(err.message);
    }
  };

  React.useEffect(() => {
    if (open) {
      reset({
        ...initalValues,
        id_cliente: data?.id_cliente,
        rut_cliente: data?.rut_cliente,
        nombre_cliente: data?.nombre_cliente,
        apepaterno_cliente: data?.apepaterno_cliente,
        apematerno_cliente: data?.apematerno_cliente,
        telf_cliente: data?.telf_cliente,
        correo_cliente: data?.correo_cliente,
        fecharegistro_cliente: data?.fecharegistro_cliente,
        clasificacion_cliente: data?.clasificacion_cliente,
        region_cliente: data?.region_cliente,
        comuna_cliente: data?.comuna_cliente,
        address_cliente: data?.address_cliente,
      });
    }

    return () => reset(initalValues);
  }, [open]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth
      onClose={handleClose}
      maxWidth={"sm"}
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <LoadingPage open={load} />
      <form onSubmit={handleSubmit(onAction)} autoComplete="off">
        <DialogTitle id="alert-dialog-title">Datos del cliente</DialogTitle>

        <DialogContent>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} className="text-left flex gap-2 text-sm">
              <h3 className="text-pretty text-gray-700 font-semibold">
                Clasificación:{" "}
              </h3>
              <p className="text-sm text-blue-900">
                {watch("clasificacion_cliente") === "Sin clasificación"
                  ? "Sin clasificación"
                  : watch("clasificacion_cliente")}
              </p>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controller
                name="rut_cliente"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    type={"text"}
                    title={"RUT"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controller
                name="nombre_cliente"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    type={"text"}
                    title={"Nombres"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="apepaterno_cliente"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    type={"text"}
                    title={"Apellidos paterno"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="apematerno_cliente"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    type={"text"}
                    title={"Apellidos materno"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="correo_cliente"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    type={"text"}
                    title={"Correo electrónico"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="telf_cliente"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    type={"text"}
                    title={"Teléfono"}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="region_cliente"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"Región"}
                    type={"select"}
                    options={dataRegionComuna.map(
                      (option, idx) =>
                        ({
                          label: option.region,
                          value: option.region,
                        } || [])
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="comuna_cliente"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"Comuna"}
                    type={"select"}
                    options={
                      dataRegionComuna
                        .find((el) => el.region === watch("region_cliente"))
                        ?.comunas.map((option, idx) => ({
                          label: option,
                          value: option,
                        })) || []
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controller
                name="address_cliente"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    type={"text"}
                    title={"Dirección"}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            disabled={load}
            color="inherit"
            variant="text"
            onClick={() => {
              setOpen(false);
            }}
          >
            Salir
          </Button>
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            disabled={load}
          >
            Modificar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogInfoClient;
