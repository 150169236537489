import dayjs from "dayjs";

export const onCheckIsValid = (data) => {
  if (data === "") return "";

  const dateStart = dayjs(data.startdate_benefit);
  const dateEnd = dayjs(data.enddate_benefit);
  const dateNow = dayjs();

  let statusBenefit = "Activo";

  if (dateNow.isBefore(dateStart)) {
    statusBenefit = "Inactivo";
  }

  if (dateNow.isAfter(dateEnd)) {
    statusBenefit = "Vencido";
  }

  return statusBenefit;
};
