/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import ContainerInput from "../../components/ContainerInput";
import Template from "../../components/Template";

import LoadingPage from "../../components/LoadingPage";

import TableGarzones from "../../components/Tables/TableGarzones";

import DialogCrudGarzones from "./DialogCrudGarzones";
import DialogEliminarGarzones from "./DialogEliminarGarzones";

import { get_garzones } from "../../services/garzones.services";

import { Button, Grid } from "@mui/material";

import { Add } from "@mui/icons-material/";

import { checkToken } from "../../services/auth.services";

const Usuario = () => {
  let history = useHistory();

  const [loadPage, setLoadPage] = useState(false);

  const [dataGarzones, setDataGarzones] = useState({ load: false, data: [] });
  const [openAdd, setOpenAdd] = useState(false);

  const [openEliminarGarzones, setOpenEliminarGarzones] = useState(false);

  const [dataViewData, setDataViewData] = useState({});
  const [dataViewEliminarData, setDataViewEliminarData] = useState("");

  //obtain the user data

  const get_garzones_data = async () => {
    setLoadPage(true);
    try {
      let response = await get_garzones();

      setDataGarzones({ ...dataGarzones, data: response });
      setLoadPage(false);
    } catch (err) {
      setLoadPage(false);
      console.log(err.message);
    }
  };

  const handleViewData = (data) => {
    setOpenAdd(true);
    setDataViewData(data);
  };

  const handleViewDataEliminar = (data) => {
    setOpenEliminarGarzones(true);
    setDataViewEliminarData(data);
  };
  useEffect(() => {
    get_garzones_data();
  }, []);

  useEffect(() => {
    if (!checkToken()) {
      history.push("/");
      return null;
    }
  }, []);

  return (
    <Template title="Reservas">
      <LoadingPage open={loadPage} setOpen={setLoadPage} />

      <Grid container spacing={2} style={{ marginBottom: 20 }}>
        <Grid
          item
          xs={12}
          sm={11}
          lg={11}
          style={{
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        ></Grid>
        <Grid
          item
          xs={12}
          sm={1}
          lg={1}
          style={{
            textAlign: "right",
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Button
            fullWidth
            onClick={() => {
              setOpenAdd(true);
              setDataViewData({});
            }}
            className="button-default"
          >
            <Add />
          </Button>
        </Grid>
      </Grid>

      {/* <form onSubmit={handleSearch_User}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={5} lg={5}>
            <TextField
              defaultValue={setSearchUser.texto}
              fullWidth
              name="texto"
              label="Buscar usuario por nombre o email"
              className={`InputDefault ${classes.size}`}
              onChange={(e) =>
                setSearchUser({
                  ...searchUser,
                  texto: e.target.value,
                })
              }
            />
          </Grid>

          <Grid item xs={2} sm={1} lg={1} style={{ textAlign: "center" }}>
            <IconButton
              fullWidth
              type="submit"
              aria-label="search"
              size="large"
              style={{ marginLeft: "5px" }}
            >
              <Search fontSize="inherit" />
            </IconButton>
          </Grid>
        </Grid>
      </form> */}

      <ContainerInput
        style={{
          marginTop: 20,
          marginBottom: 20,
          padding: "35px 10px",
          textAlign: "center",
        }}
      >
        <TableGarzones
          rows={dataGarzones.data}
          handleViewData={handleViewData}
          handleViewDataEliminar={handleViewDataEliminar}
        />
      </ContainerInput>

      <DialogCrudGarzones
        open={openAdd}
        setOpen={setOpenAdd}
        handleGetGarzones={get_garzones_data}
        data={dataViewData}
      />
      <DialogEliminarGarzones
        open={openEliminarGarzones}
        setOpen={setOpenEliminarGarzones}
        data={dataViewEliminarData}
        handleGetGarzones={get_garzones_data}
      />
    </Template>
  );
};

export default Usuario;
