import { Grid } from "@mui/material";
import Question from "./question";

const AllQuestion = (props) => {
  const { data, setAnswer } = props;

  return (
    <Grid container spacing={0}>
      {data.map((el, idx) => (
        <Grid key={idx} item xs={12}>
          <Question
            number={idx + 1}
            question={el.question}
            answer={el.answer}
            setAnswer={setAnswer}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default AllQuestion;
