import * as React from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import { Close } from "@mui/icons-material";

import { Button, DialogActions, Grid } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import InputDefault from "../../atomic/atoms/input.atoms";
import {
  add_capacidad,
  deletev2_capacidad,
  updatev2_capacidad,
} from "../../services/capacidad.services";

const DialogCapacity = ({
  open,
  setOpen,
  initialValues,
  callBack,
  idEmpSede,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const valuesForm = {
    id_capacidadate: null,
    date_capacidadate: "",
    quantity_capacidate: "",
  };

  const { control, reset, watch, handleSubmit } = useForm({
    defaultValues: valuesForm,
  });

  const handleAddCapacity = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        await add_capacidad(data, idEmpSede);
        resolve("Capacidad agregada con éxito");
      } catch (err) {
        reject(new Error("Ocurrió un error al agregar la capacidad"));
      }
    });
  };

  const handleUpdateCapacity = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        await updatev2_capacidad(data);
        resolve("Capacidad modificada con éxito");
      } catch (err) {
        reject(new Error("Ocurrió un error al modificar la capacidad"));
      }
    });
  };

  const handleDeleteCapacity = async (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        await deletev2_capacidad(id);
        resolve("Capacidad eliminada con éxito");
      } catch (err) {
        reject(new Error("Ocurrió un error al eliminar la capacidad"));
      }
    });
  };

  const onSubmit = async (data) => {
    handleClose();

    if (data?.id_capacidadate || data?.id_capacidadate < 0) {
      toast.promise(handleUpdateCapacity(data), {
        loading: "Cargando...",
        success: () => {
          callBack();
          return "Se modificó la capacidad";
        },
        error: (err) => {
          handleOpen();
          toast.error(err.message);
          return "Ops! Ocurrió un error al modificar la capacidad";
        },
      });
      return;
    }

    toast.promise(handleAddCapacity(data), {
      loading: "Cargando...",
      success: () => {
        callBack();
        return "Se agregó la capacidad";
      },
      error: (err) => {
        handleOpen();
        toast.error(err.message);
        return "Ops! Ocurrió un error al agregar la capacidad";
      },
    });
  };

  const onDelete = (id) => {
    handleClose();

    toast.promise(handleDeleteCapacity(id), {
      loading: "Cargando...",
      success: () => {
        callBack();
        return "Se eliminó la capacidad";
      },
      error: (err) => {
        handleOpen();
        toast.error(err.message);
        return "Ops! Ocurrió un error al eliminar la capacidad";
      },
    });
  };

  React.useEffect(() => {
    if (initialValues) {
      reset({
        ...valuesForm,
        date_capacidadate: initialValues.date,
        id_capacidadate: initialValues.idCapacity,
        quantity_capacidate: initialValues.quantityCapacity,
      });
    }
  }, [initialValues]);

  React.useEffect(() => {
    if (open) {
      reset(valuesForm);
    }
  }, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth>
        <DialogTitle>
          {initialValues?.idCapacity ? "Editar" : "Agregar"} capacidad
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          style={{ textAlign: "left" }}
        >
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="date_capacidadate"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <InputDefault
                      required
                      disabled
                      onChange={onChange}
                      fullWidth
                      placeholder={"Fecha de capacidad"}
                      variant="outlined"
                      type="date"
                      value={value}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="quantity_capacidate"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <InputDefault
                      required
                      onChange={onChange}
                      fullWidth
                      placeholder={"Capacidad"}
                      variant="outlined"
                      type="number"
                      value={value}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ padding: 15 }}>
            {initialValues?.idCapacity && (
              <Button
                onClick={() => onDelete(initialValues?.idCapacity)}
                color="error"
                variant="outlined"
              >
                Eliminar
              </Button>
            )}

            <Button type="submit" variant="contained" color="secondary">
              {initialValues?.idCapacity ? "Editar" : "Agregar"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default DialogCapacity;
