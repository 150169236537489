import "moment/locale/es";
import { createContext, useContext, useEffect, useState } from "react";

// import { useDebounce } from "../../hooks/useDebounce";

import {
  addBenefit,
  deleteBenefit,
  getBenefit,
  updateBenefit,
} from "../../services/benefit.services";
import { getTypeBenefit } from "../../services/typeBenefit.service";
import dayjs from "dayjs";

const initDefaultValues = {
  id: "",
  name: "",
  startDate: "",
  endDate: "",
  idTypeBenefit: 0,
  typeValue: "",
  value: "",
};

const AppContext = createContext({
  /* Values */
  data: [],
  dataTypeBenefit: [],
  isLoadData: false,
  dataExcel: [],
  openModal: false,
  defaultValues: initDefaultValues,
  /* Methods */
  handleSearch: (value) => {},
  handleModal: () => {},
  handleModalDelete: () => {},
  handleAdd: (value) => {},
  handleUpdate: (id_benefit, value) => {},
  handleDelete: (id_benefit) => {},
  handleAction: (value) => {},
  handleSelected: (value) => {},
  handleReset: () => {},
});

export default function BenefitState({ children }) {
  const [data, setData] = useState([]);
  const [dataTypeBenefit, setDataTypeBenefit] = useState({
    data: [],
    loading: false,
    error: false,
  });

  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [defaultValues, setDefaultValues] = useState(initDefaultValues);

  // const [name, setName] = useState("");
  // const debouncedFilter = useDebounce(name || "", 500);

  async function handleSearch(params) {
    setLoading(true);

    const urlParams = `?name=${params?.name}&startDate=${
      params?.date_start || ""
    }&endDate=${params?.date_end || ""}`;

    const response = await getBenefit(urlParams);
    setData(response || []);

    setLoading(false);
  }

  async function handleGet() {
    setLoading(true);
    const response = await getBenefit();
    setData(response || []);
    setLoading(false);
  }

  async function handleGetDataTypeBenefit() {
    setDataTypeBenefit({ data: [], loading: true, error: false });
    const response = await getTypeBenefit();
    setDataTypeBenefit({ data: response || [], loading: false, error: false });
  }

  async function handleAction(params) {
    try {
      handleModal(false);
      if (params?.id !== "") {
        await handleUpdate(params?.id, params);
        return;
      }

      await handleAdd(params);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleAdd(params) {
    setLoading(true);
    await addBenefit(params);
    await handleGet();
    setLoading(false);
  }

  async function handleUpdate(id_benefit, params) {
    setLoading(true);
    await updateBenefit(id_benefit, params);
    await handleGet();
    setLoading(false);
  }

  async function handleDelete(id_benefit) {
    setLoading(true);
    await deleteBenefit(id_benefit);
    await handleGet();
    setLoading(false);
  }

  const handleSelected = (type, id) => {
    type === "edit" ? handleModal(true) : handleModalDelete(true);

    const filter = data.find((item) => item.id_benefit === id);

    setDefaultValues({
      id: filter.id_benefit,
      name: filter.name_benefit,
      startDate: dayjs(filter.startdate_benefit).format("YYYY-MM-DD"),
      endDate: dayjs(filter.enddate_benefit).format("YYYY-MM-DD"),
      idTypeBenefit: filter.id_typebenefit,
      typeValue: filter.typevalue_benefit,
      value: filter.value_benefit,
    });
  };

  const handleReset = () => {
    setDefaultValues(initDefaultValues);
  };

  const handleModal = (openStatus) => {
    setOpenModal(openStatus);
  };

  const handleModalDelete = (openStatus) => {
    setOpenModalDelete(openStatus);
  };

  useEffect(() => {
    handleGet();
    handleGetDataTypeBenefit();
  }, []);

  return (
    <AppContext.Provider
      value={{
        /* Values */
        data,
        dataTypeBenefit,
        isLoadData: loading,
        openModal,
        openModalDelete,
        defaultValues,
        /* Methods */
        handleSearch,
        handleModal,
        handleAdd,
        handleUpdate,
        handleDelete,
        handleAction,
        handleSelected,
        handleReset,
        handleModalDelete,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
