import axios from "axios";
import { config } from "../config";
import { axiosHeader } from "../functions/axiosHeader";

const { baseurl } = config;

const base_url = `${baseurl}/api/caja`;

export const get_caja = (param) => {
  const queryParam = param === "all" ? "all" : "oneday";

  const url = `${base_url}/get/${queryParam}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const aperturar_caja = (params) => {
  const url = `${base_url}/apertura/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const cerrar_caja = (params) => {
  const url = `${base_url}/cerrar/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};


export const update_caja = (params, id) => {
  const url = `${base_url}/update/${id}`;

  return axios
    .put(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};