import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import IconButton from "@mui/material/IconButton";

import LoadingPage from "../../../components/LoadingPage";

import CloseIcon from "@mui/icons-material/Close";

const CustomDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const DialogConfirm = (props) => {
  let { open, setOpen, pedido, handleUpdateStatus } = props;

  const [loadPage, setLoadPage] = useState(false);

  /*
    HANDLE SELECTED TABLE
  */

  const handleChangeStatus = async () => {
    try {
      handleClose();

      setLoadPage(true);

      let json = {
        cod_pedido: pedido.id_pedido,
        id_plapedido: pedido.id_plapedido,
        status_plapedido:
          pedido.status_plapedido === "INGRESADO" ? "EN PROCESO" : "TERMINADO",
      };

      await handleUpdateStatus(json);

      setLoadPage(false);
    } catch (err) {
      setLoadPage(false);
      console.log(err);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
    }
  }, [open]);

  return (
    <>
      <LoadingPage open={loadPage} setOpen={setLoadPage} />
      <Dialog
        fullWidth
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <CustomDialogTitle
          style={{ borderBottom: "1px #eee solid", background: "#f5f5f5" }}
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {pedido.status_plapedido === "INGRESADO"
            ? "Preparar pedido"
            : "Terminar pedido"}
        </CustomDialogTitle>
        <DialogContent style={{ padding: "20px 8px" }}>
          {pedido.status_plapedido === "INGRESADO" ? (
            <>
              ¿Deseas comenzar a preparar x{pedido.amount_plapedido}{" "}
              {pedido.nombre_plato}?
            </>
          ) : (
            <>
              ¿Deseas finalizar laa prepararción de x{pedido.amount_plapedido}{" "}
              {pedido.nombre_plato}?
            </>
          )}
        </DialogContent>

        <DialogActions
          style={{ borderTop: "1px #eee solid", background: "#f5f5f5" }}
        >
          <Button onClick={handleClose} variant="text" color="inherit">
            Cancel
          </Button>
          <Button
            onClick={() => handleChangeStatus()}
            variant="contained"
            color="success"
          >
            {pedido.status_plapedido === "INGRESADO" ? "Preparar" : "Terminar"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogConfirm;
