import * as React from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Link,
  Chip,
} from "@mui/material";

import { Edit, Delete, Settings } from "@mui/icons-material";
import NotFound from "../../atomic/organisms/NotFound.organisms";

// import Moment from "react-moment";
// import "moment/locale/es";

const TablePlazas = ({
  rows,
  handleViewData,
  handleViewDataEliminar,
  handleRedirect,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const toUpperCaseFilter = (d) => {
  //   if (!d) {
  //     return d;
  //   }

  //   if (d === "") {
  //     return d;
  //   }

  //   return d.toUpperCase();
  // };

  if (rows.length === 0) {
    return <NotFound texto="No se encontró registros." />;
  }

  return (
    <>
      <TableContainer sx={{ maxHeight: 550 }}>
        <Table stickyHeader aria-label="sticky table" size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell align={"left"} style={{ minWidth: 150 }}>
                Código o nombre
              </TableCell>
              <TableCell
                colSpan={2}
                align={"center"}
                style={{ width: 90, fontSize: 21, color: "#5c5c5c" }}
              >
                <Settings />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                  <TableCell align={"left"}>{row.nombre_plaza}</TableCell>
                  <TableCell align={"center"} width={35}>
                    <IconButton
                      color="warning"
                      onClick={() => handleViewData(row)}
                    >
                      <Edit />
                    </IconButton>
                  </TableCell>
                  <TableCell align={"center"} width={35}>
                    <IconButton
                      color="error"
                      onClick={() =>
                        // handleChangeStatus(row.id_reserva, "eliminado")
                        handleViewDataEliminar(row)
                      }
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage="Filas por página"
        rowsPerPageOptions={[10, 25, 100, { value: -1, label: "Todo" }]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default TablePlazas;
