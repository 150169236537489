import * as React from "react";

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";

import { Cancel } from "@mui/icons-material";
import NotFound from "../../../../../../atomic/organisms/NotFound.organisms";
import { formatMoney } from "../../../../../../functions/formatMoney";

const ItemTable = (props) => {
  let { row, idx, clickAction } = props;

  //   const isItemSelected = isSelected(row.id_plapedido);

  return (
    <React.Fragment>
      <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
        <TableCell align={"left"}>
          <IconButton onClick={() => clickAction(row)}>
            <Cancel />
          </IconButton>
        </TableCell>
        <TableCell align={"left"}>{row.nombre_plato}</TableCell>

        <TableCell align={"left"}>x{row.amount_plapedido}</TableCell>
        <TableCell align={"left"}>{formatMoney(row.precio_plato)}</TableCell>
        <TableCell align={"left"}>
          {formatMoney(row.precio_plato * row.amount_plapedido)}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const TableProduct = (props) => {
  let { dataProducts, handleRemoveProducto } = props;

  if (dataProducts.length === 0) {
    return <NotFound texto="No se encontró registros." />;
  }

  const handleClick = (row) => {
    handleRemoveProducto(row);
    // const selectedIndex = selectedProduct.indexOf(value);
    // let newSelected = [];
    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(selectedProduct, value);
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selectedProduct.slice(1));
    // } else if (selectedIndex === selectedProduct.length - 1) {
    //   newSelected = newSelected.concat(selectedProduct.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(
    //     selectedProduct.slice(0, selectedIndex),
    //     selectedProduct.slice(selectedIndex + 1)
    //   );
    // }
  };

  return (
    <>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table" size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 20 }}></TableCell>
              <TableCell
                align={"left"}
                style={{ minWidth: 170, fontWeight: "bold" }}
              >
                Nombre
              </TableCell>
              <TableCell
                align={"left"}
                style={{ minWidth: 100, fontWeight: "bold" }}
              >
                Cantidad
              </TableCell>
              <TableCell
                align={"left"}
                style={{ minWidth: 110, fontWeight: "bold" }}
              >
                Precio
              </TableCell>
              <TableCell
                align={"left"}
                style={{ minWidth: 110, fontWeight: "bold" }}
              >
                Total
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataProducts.map((row, idx) => {
              //   const isSelected = (value) =>
              //     selectedProduct.indexOf(value) !== -1;

              return (
                <ItemTable
                  idx={idx}
                  key={idx}
                  row={row}
                  clickAction={handleClick}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TableProduct;
