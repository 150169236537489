import * as React from "react";

import {
  Slide,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Grid,
} from "@mui/material";

import Propinas from "../../../../closebox/BoxData/propinas";
import TotalVenta from "../../../../closebox/BoxData/totalventa";
import FormaPago from "../../../../closebox/BoxData/formaPago";

import "moment/locale/es";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref || false} {...props} />;
});

const DialogIndicator = (props) => {
  let { socket, open, setOpen } = props;

  const handleClose = () => {
    setOpen(false);
  };

  if (!open) {
    return null;
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth
      onClose={handleClose}
      maxWidth={"md"}
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Indicadores</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Propinas socket={socket} style={{ padding: 0 }} />
          </Grid>
          <Grid item xs={4}>
            <TotalVenta socket={socket} style={{ padding: 0 }} />
          </Grid>
          <Grid item xs={4}>
            <FormaPago socket={socket} style={{ padding: 0 }} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="text" color="inherit">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogIndicator;
