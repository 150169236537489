import * as React from "react";

import {
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";

import {
  Comment,
  Delete,
  Edit,
  Fastfood,
  MoreVert,
  NotificationsActive,
  People,
  Settings,
  Visibility,
} from "@mui/icons-material";

import "moment/locale/es";
import Moment from "react-moment";

import NotFound from "../../atomic/organisms/NotFound.organisms";
import { formatMoney } from "../../functions/formatMoney";
import { formatPhoneNumber } from "../../functions/formatPhoneNumber";
import { nameCamarero } from "../../functions/nameCamarero";
import { getUser } from "../../services/auth.services";

import ClientClasification from "../../atomic/atoms/clientClasification.atoms";

function formatDate(date) {
  if (!date) {
    return null;
  }

  let dFormat = date.split("T");

  return dFormat[0];
}

const toUpperCaseFilter = (d) => {
  if (!d) {
    return d;
  }

  if (d === "") {
    return d;
  }

  return d.toUpperCase();
};

const ListButtons = (props) => {
  const { options, data } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectOption = (option) => {
    option.onClick(data);
    handleClose();
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map((option, id) => (
          <MenuItem key={id} onClick={() => handleSelectOption(option)} divider>
            <div style={{ marginRight: 5, color: option.color }}>
              {option.icon}
            </div>
            <div>{option.name}</div>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const TableReserva = ({
  rows,
  handleViewData,
  handleViewDataEditar,
  handleViewDataEliminar,
  handleAddPersons,
  handleViewDataReminder,
  handleAddMenu,
  isReadMode = false,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (rows.length === 0) {
    return <NotFound texto="No se encontró registros." />;
  }

  /*
    ANFITRION
  */

  let optionButtons = [
    {
      id: 1,
      icon: <NotificationsActive />,
      onClick: handleViewDataReminder,
      name: "Recordatorio",
      color: "#ff9800",
    },
    {
      id: 2,
      icon: <Fastfood />,
      onClick: handleAddMenu,
      name: "Agregar platos",
      color: "#f44336",
    },
    {
      id: 3,
      icon: <People />,
      onClick: handleAddPersons,
      name: "Agregar personas",
      color: "#2196f3",
    },
    {
      id: 4,
      icon: <Visibility />,
      onClick: handleViewData,
      name: "Ver",
      color: "#4caf50",
    },
    {
      id: 5,
      icon: <Edit />,
      onClick: handleViewDataEditar,
      name: "Editar",
      color: "#ffc107",
    },
    {
      id: 6,
      icon: <Delete />,
      onClick: handleViewDataEliminar,
      name: "Eliminar",
      color: "#f44336",
    },
  ];

  optionButtons =
    getUser().empresa.id_empsede !== 5
      ? optionButtons.filter((option) => option.id !== 2 && option.id !== 3)
      : optionButtons;

  if (getUser().id_perfil === 7) {
    return (
      <>
        <TableContainer sx={{ maxHeight: 550 }}>
          <Table stickyHeader aria-label="sticky table" size={"small"}>
            <TableHead>
              <TableRow>
                <TableCell align={"left"} style={{ minWidth: 50 }}>
                  Código
                </TableCell>
                <TableCell align={"left"} style={{ minWidth: 130 }}>
                  Fecha reserva
                </TableCell>
                <TableCell align={"left"} style={{ minWidth: 100 }}>
                  Estado
                </TableCell>
                <TableCell align={"left"} style={{ minWidth: 150 }}>
                  Estado de llegada
                </TableCell>
                <TableCell align={"left"} style={{ minWidth: 280 }}>
                  Nombre completo
                </TableCell>
                <TableCell align={"left"} style={{ minWidth: 120 }}>
                  N° de mesa
                </TableCell>
                <TableCell align={"left"} style={{ minWidth: 140 }}>
                  {nameCamarero()}
                </TableCell>
                <TableCell align={"left"} style={{ minWidth: 80 }}>
                  Monto reserva
                </TableCell>
                <TableCell align={"left"} style={{ minWidth: 100 }}>
                  Teléfono
                </TableCell>
                <TableCell align={"left"} style={{ minWidth: 100 }}>
                  Mail
                </TableCell>
                <TableCell align={"left"} style={{ minWidth: 100 }}>
                  PAX
                </TableCell>
                <TableCell align={"left"} style={{ minWidth: 100 }}>
                  Programa
                </TableCell>
                <TableCell align={"left"} style={{ minWidth: 100 }}>
                  Vendedor
                </TableCell>
                <TableCell align={"left"} style={{ minWidth: 150 }}>
                  Fecha registro
                </TableCell>
                <TableCell
                  align={"center"}
                  style={{ minWidth: 100, fontSize: 21, color: "#5c5c5c" }}
                  colSpan={2}
                >
                  <Settings />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, idx) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                    <TableCell align={"left"}>#{row.id_reserva}</TableCell>
                    <TableCell align={"left"}>
                      <Moment filter={toUpperCaseFilter} format="DD/MMM/YYYY">
                        {formatDate(row.fechareserva_reserva)}
                      </Moment>
                    </TableCell>
                    <TableCell align={"left"}>
                      {row.estado_reserva === "cancelado" ? (
                        <Chip
                          label={row.estado_reserva.toUpperCase()}
                          color="error"
                        />
                      ) : row.estado_reserva === "confirmado" ||
                        row.estado_reserva === "re confirmado" ? (
                        <Chip
                          label={row.estado_reserva.toUpperCase()}
                          color="success"
                        />
                      ) : row.estado_reserva === "en espera" ? (
                        <Chip
                          label={row.estado_reserva.toUpperCase()}
                          color="info"
                        />
                      ) : (
                        <Chip
                          label={row.estado_reserva.toUpperCase()}
                          color="warning"
                        />
                      )}
                    </TableCell>
                    <TableCell align={"left"}>
                      {row.id_estadopresencial === 1 ? (
                        <Chip label={"EN SU MESA"} color="warning" />
                      ) : row.id_estadopresencial === 2 ? (
                        <Chip label={"CONTACTADO"} color="success" />
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {`${row.nombre_reserva} ${row.apellido_paterno_reserva} ${row.apellido_materno_reserva}`}
                    </TableCell>
                    <TableCell align={"left"}>
                      {row.codigo_mesa ? row.codigo_mesa : "-"}
                    </TableCell>
                    <TableCell align={"left"}>
                      {row.usu_garzon ? row.usu_garzon.toUpperCase() : "-"}
                    </TableCell>
                    <TableCell align={"left"}>
                      {formatMoney(row.monto_reserva ? row.monto_reserva : 0)}
                    </TableCell>
                    <TableCell align={"left"}>
                      {formatPhoneNumber(row.telefono_reserva)}
                    </TableCell>
                    <TableCell align={"left"}>{row.correo_reserva}</TableCell>
                    <TableCell align={"left"}>{row.pax_reserva}</TableCell>
                    <TableCell
                      align={"left"}
                      style={{ textTransform: "capitalize" }}
                    >
                      {row.pograma_reserva}
                    </TableCell>
                    <TableCell align={"left"}>{row.usu_nombre}</TableCell>
                    <TableCell align={"left"}>
                      <Moment filter={toUpperCaseFilter} format="DD/MMM/YYYY">
                        {row.fecharegistro_reserva}
                      </Moment>
                    </TableCell>
                    <TableCell align={"center"}>
                      <IconButton
                        color="warning"
                        onClick={() => handleViewData(row)}
                      >
                        <Visibility />
                      </IconButton>
                    </TableCell>
                    <TableCell align={"center"}>
                      <IconButton
                        color="primary"
                        onClick={() => handleViewDataEditar(row)}
                      >
                        <Edit />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage="Filas por página"
          rowsPerPageOptions={[10, 25, 100, { value: -1, label: "Todo" }]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>
    );
  }

  return (
    <>
      <TableContainer sx={{ maxHeight: 550 }}>
        <Table stickyHeader aria-label="sticky table" size={"small"}>
          <TableHead>
            <TableRow>
              {!isReadMode && (
                <TableCell
                  colSpan={1}
                  align={"center"}
                  style={{ minWidth: 100, fontSize: 21, color: "#5c5c5c" }}
                >
                  <Settings />
                </TableCell>
              )}
              <TableCell align={"left"} style={{ minWidth: 130 }}>
                Código
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 130 }}>
                Fecha reserva
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 100 }}>
                Estado
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 280 }}>
                Nombre completo
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 120 }}>
                N° de mesa
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 140 }}>
                {nameCamarero()}
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 190 }}>
                Monto reserva
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 100 }}>
                Teléfono
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 100 }}>
                Mail
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 100 }}>
                Dirección
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 150 }}>
                PAX
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 100 }}>
                Programa
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 100 }}>
                Vendedor
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 100 }}>
                Categoría
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 150 }}>
                Fecha registro
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                  {!isReadMode && (
                    <TableCell align={"center"}>
                      <ListButtons data={row} options={optionButtons} />
                    </TableCell>
                  )}

                  <TableCell align={"left"}>
                    {getUser().id_perfil === 13 ? (
                      <Tooltip title={row.observacion_reserva}>
                        {row.observacion_reserva && (
                          <Comment className="mr-2" />
                        )}{" "}
                        #{row.id_reserva}
                      </Tooltip>
                    ) : (
                      <>#{row.id_reserva}</>
                    )}
                  </TableCell>
                  <TableCell align={"left"}>
                    {/* {format(row.fechareserva_reserva, "MM/dd/yyyy", {
                      locale: esLocale,
                    })} */}
                    {/* {formatDate(row.fechareserva_reserva)} */}
                    <Moment filter={toUpperCaseFilter} format="DD/MMM/YYYY">
                      {formatDate(row.fechareserva_reserva)}
                    </Moment>
                  </TableCell>
                  <TableCell align={"left"}>
                    {row.estado_reserva === "cancelado" ? (
                      <Chip
                        label={row.estado_reserva.toUpperCase()}
                        color="error"
                      />
                    ) : row.estado_reserva === "confirmado" ||
                      row.estado_reserva === "re confirmado" ? (
                      <Chip
                        label={row.estado_reserva.toUpperCase()}
                        color="success"
                      />
                    ) : row.estado_reserva === "en espera" ? (
                      <Chip
                        label={row.estado_reserva.toUpperCase()}
                        color="info"
                      />
                    ) : (
                      <Chip
                        label={row.estado_reserva.toUpperCase()}
                        color="warning"
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    {`${row.nombre_reserva} ${row.apellido_paterno_reserva} ${row.apellido_materno_reserva}`}
                  </TableCell>

                  <TableCell align={"left"}>
                    {row.codigo_mesa ? row.codigo_mesa : "-"}
                  </TableCell>
                  <TableCell align={"left"}>
                    {row.usu_garzon ? row.usu_garzon.toUpperCase() : "-"}
                  </TableCell>
                  <TableCell align={"left"}>
                    {formatMoney(row.monto_reserva ? row.monto_reserva : 0)}
                  </TableCell>
                  <TableCell align={"left"}>
                    {formatPhoneNumber(row.telefono_reserva)}
                  </TableCell>
                  <TableCell align={"left"}>{row.correo_reserva}</TableCell>
                  <TableCell align={"left"}>
                    {row.region} {row.comunas}
                  </TableCell>
                  <TableCell align={"left"}>{row.pax_reserva}</TableCell>
                  <TableCell
                    align={"left"}
                    style={{ textTransform: "capitalize" }}
                  >
                    {row.pograma_reserva}
                  </TableCell>
                  <TableCell align={"left"}>{row.usu_nombre}</TableCell>
                  <TableCell align={"left"}>
                    <ClientClasification type={row.clasificacion_cliente} />
                  </TableCell>
                  <TableCell align={"left"}>
                    <Moment filter={toUpperCaseFilter} format="DD/MMM/YYYY">
                      {row.fecharegistro_reserva}
                    </Moment>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage="Filas por página"
        rowsPerPageOptions={[10, 25, 100, { value: -1, label: "Todo" }]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default TableReserva;
