import {
  Document,
  Font,
  Image,
  Page,
  PDFViewer,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";

import watermarkImage from "../../../../assets/images/mark_dh.png";
import signatureImage from "../../../../assets/images/sign.png";

import { formatDate } from "@fullcalendar/core/index.js";
import dayjs from "dayjs";
import RobotoBold from "../../../../assets/fonts/Roboto/Roboto-Bold.ttf";
import RobotoItalic from "../../../../assets/fonts/Roboto/Roboto-Italic.ttf";
import Roboto from "../../../../assets/fonts/Roboto/Roboto-Regular.ttf";

import { formatMoney } from "../../../../functions/formatMoney";

import { styleContract } from "./style.js";

Font.register({
  family: "Roboto",
  fonts: [
    { src: Roboto },
    { src: RobotoBold, fontStyle: "bold", fontWeight: 600 },
    { src: RobotoItalic, fontStyle: "italic" },
  ],
});

const PDFMatrimonyContract = ({ dataContract }) => {
  let {
    nameBoyfriendQuotation = "",
    rutBoyfriendQuotation = "",
    dateofBirthBoyfriendQuotation = null,
    phoneBoyfriendQuotation = "",
    addressBoyfriendQuotation = "",
    nameGirlfriendQuotation = "",
    rutGirlfriendQuotation = "",
    dateofBirthGirlfriendQuotation = null,
    phoneGirlfriendQuotation = "",
    addressGirlfriendQuotation = "",
    nameSubsidiary = "",
    valuePerPerson = null,
    amountPeopleQuotation = null,
    dateOfEvent = null,
  } = dataContract;

  const DEFAULT_VALUE = "______________________________";

  rutBoyfriendQuotation = rutBoyfriendQuotation?.toUpperCase() || DEFAULT_VALUE;
  rutGirlfriendQuotation =
    rutGirlfriendQuotation?.toUpperCase() || DEFAULT_VALUE;

  nameBoyfriendQuotation =
    nameBoyfriendQuotation?.toUpperCase() || DEFAULT_VALUE;
  nameGirlfriendQuotation =
    nameGirlfriendQuotation?.toUpperCase() || DEFAULT_VALUE;

  addressBoyfriendQuotation =
    addressBoyfriendQuotation?.toUpperCase() || DEFAULT_VALUE;
  addressGirlfriendQuotation =
    addressGirlfriendQuotation?.toUpperCase() || DEFAULT_VALUE;

  phoneGirlfriendQuotation =
    phoneGirlfriendQuotation?.toUpperCase() || DEFAULT_VALUE;
  phoneBoyfriendQuotation =
    phoneBoyfriendQuotation?.toUpperCase() || DEFAULT_VALUE;

  dateofBirthGirlfriendQuotation = dateofBirthGirlfriendQuotation
    ? formatDate(dateofBirthGirlfriendQuotation)
    : DEFAULT_VALUE;

  dateofBirthBoyfriendQuotation = dateofBirthBoyfriendQuotation
    ? formatDate(dateofBirthBoyfriendQuotation)
    : DEFAULT_VALUE;

  nameSubsidiary = nameSubsidiary?.toUpperCase() || DEFAULT_VALUE;

  valuePerPerson = valuePerPerson ? formatMoney(valuePerPerson, "CLP") : "";
  amountPeopleQuotation = amountPeopleQuotation || DEFAULT_VALUE;

  dateOfEvent = dateOfEvent ? formatDate(dateOfEvent) : DEFAULT_VALUE;


  return (
    <PDFViewer style={{ width: "100%", height: "100%" }}>
      <Document subject="Contrato de prestación de servicios">
        <Page size="A4" style={styleContract.page} wrap={false} debug={false}>
          <View style={styleContract.watermarkContainer} fixed={true}>
            <Image src={watermarkImage} style={styleContract.watermark} />
          </View>
          <View style={styleContract.section}>
            {/* Title */}
            <View style={{ marginBottom: 20 }}>
              <Text style={styleContract.textTitle}>
                CONTRATO DE PRESTACIÓN DE SERVICIOS
              </Text>
            </View>
            <View>
              <Text style={styleContract.text}>
                En Olmué, {dayjs().format("DD MMMM YYYY")}, entre Comercial
                Dejando Huella Ltda. RUT N°76.766.422-2, representado por Don
                Pedro Olivares Maturana, nacionalidad chilena, con cédula
                identidad N°13.994.073-3, profesión Ingeniero Civil Industrial,
                ambos domiciliados en calle Bulnes 1800 Olmué, región de
                Valparaíso, en adelante denominado "Oferente", y (Novio(a), nombres
                y apellidos) {nameGirlfriendQuotation}, con Cédula de Identidad
                de Identidad N° {rutGirlfriendQuotation}, de nacionalidad de
                nacimiento: {dateofBirthGirlfriendQuotation} domiciliada en
                domiciliada en
                {addressGirlfriendQuotation} con número telefónico: +56 9
                {phoneGirlfriendQuotation} y (novio(a)) {nameBoyfriendQuotation},
                con cédula de Identidad N° N° {rutBoyfriendQuotation}, de
                nacionalidad chilena, fecha de nacimiento:{" "}
                {dateofBirthBoyfriendQuotation} domiciliado en:{" "}
                {addressBoyfriendQuotation} con número telefónico: +56 9
                {phoneBoyfriendQuotation} en adelante los “Mandantes”, se ha
                convenido lo siguiente:
              </Text>
            </View>
            <View>
              <Text style={styleContract.textBold}>PRIMERO:</Text>
              <Text style={styleContract.text}>
                El Oferente es una empresa dedicada a la realización de eventos,
                para este contrato en particular de evento de Matrimonio, en
                centro de eventos Dejando Huella {nameSubsidiary}
              </Text>
            </View>

            <View>
              <Text style={styleContract.textBold}>SEGUNDO:</Text>
              <Text style={styleContract.text}>
                Por el presente instrumento, el Mandante contrata al Centro de
                Eventos “DEJANDO HUELLA” para la prestación de servicios
                detallados en la cláusula precedente: programa Todo Incluido de
                Matrimonio
              </Text>
            </View>

            <View>
              <Text style={styleContract.textBold}>TERCERO:</Text>
              <Text style={styleContract.text}>
                Con el propósito de ejecutar el presente contrato, Pedro
                Olivares Maturana se compromete a proporcionar los servicios
                detallados en el programa de Matrimonios (formato PDF de 3
                páginas). Por su parte, el Mandante asume el compromiso de
                cancelar por los servicios recibidos en virtud de este acuerdo.
              </Text>
            </View>

            <View>
              <Text style={styleContract.textBold}>CUARTO:</Text>
              <Text style={styleContract.text}>
                El precio del servicio ofrecido por el Oferente será de{" "}
                {valuePerPerson} por persona, estableciendo una base mínima de{" "}
                una base mínima de {amountPeopleQuotation} personas adultas. El
                pago inicial del 10% del total se realizará dentro de los 2
                meses posteriores a la contratación del servicio o, en su
                defecto, en la fecha de la degustación (lo que ocurra primero).
                El 90% restante del saldo pendiente deberá abonarse como máximo
                20 días antes del evento. Se otorgará un descuento del 50% sobre
                el valor de los adultos a los niños de 6 a 12 años, sin que
                estos últimos sean considerados parte de la base acordada.
              </Text>
            </View>
          </View>
        </Page>
        <Page size="A4" style={styleContract.page} wrap={false} debug={false}>
          <View style={styleContract.watermarkContainer}>
            <Image src={watermarkImage} style={styleContract.watermark} />
          </View>
          <View style={styleContract.section}>
            <View>
              <Text style={styleContract.textBold}>QUINTO:</Text>
              <Text style={styleContract.text}>
                Los Mandantes cancelarán un monto de $100.000 pesos de reserva,
                el cual no será reembolsable. Esta tarifa incluye los gastos
                asociados con la reserva de la fecha acordada y garantiza el
                mantenimiento de los precios pactados. Asimismo, asegura que el
                lugar esté disponible exclusivamente para los Mandantes en la
                fecha establecida, y cubre los costos administrativos y de
                organización relacionados con la preparación del evento.
              </Text>
            </View>
            <View>
              <Text style={styleContract.textBold}>SEXTO:</Text>
              <Text style={styleContract.text}>
                La última confirmación de participantes, se debe hacer 2 semanas
                antes de la realización del evento; si la cantidad de
                participantes disminuye (Base mínima), Dejando Huella podrá
                ajustar el valor cotizado; se facturará de acuerdo a esta última
                ratificación.
              </Text>
            </View>
            <View>
              <Text style={styleContract.textBold}>SÉPTIMO:</Text>
              <Text style={styleContract.text}>
                En caso de declararse estado de emergencia u otro evento
                extraordinario, imprevisible e irresistible que impida la
                realización del evento en la fecha originalmente acordada, los
                contratantes quedan exonerados de pagos adicionales no
                establecidos en este contrato y tendrán la posibilidad de
                suspender el evento, ajustando la fecha a un momento adecuado
                dentro del mismo año. Cualquier cambio de fecha a años
                siguientes podría implicar variaciones basadas en el Índice de
                Precios al Consumidor (IPC).
              </Text>
            </View>
            <View>
              <Text style={styleContract.textBold}>OCTAVO:</Text>
              <Text style={styleContract.text}>
                El presente contrato se regirá para el evento con fecha:{" "}
                {dayjs(dateOfEvent).format("DD/MM/YYYY")} con hora entre las
                ___hrs y ___hrs. (modificable), evento de __ hrs continuas.
              </Text>
            </View>
            <View>
              <Text style={styleContract.textBold}>NOVENO:</Text>
              <Text style={styleContract.text}>
                Declaran los comparecientes que con motivo de este convenio y de
                las relaciones comerciales que se desarrollarán entre las
                partes, de forma que se compromete por sí a cumplir en carácter
                obligatorio el servicio convenido, lo que es estrictamente
                necesario para dar cabal cumplimiento a este contrato.
              </Text>
            </View>
            <View style={styleContract.table}>
              <View style={styleContract.tableRow}>
                <View style={styleContract.tableCell}>
                  <View style={{ textAlign: "left" }}>
                    <Text>Firma (Novio(a))</Text>
                    <Text>RUT:</Text>
                  </View>
                </View>
                <View style={styleContract.tableCell}>
                  <View style={{ textAlign: "left" }}>
                    <Text>Firma (Novio(a))</Text>
                    <Text>RUT:</Text>
                  </View>
                </View>
              </View>
              <View style={styleContract.tableRow}>
                <View style={{ ...styleContract.tableCellSign, marginTop: 2 }}>
                  <Image
                    style={{
                      width: "40%",
                      margin: "auto",
                    }}
                    src={signatureImage}
                  />
                </View>
              </View>
              <View style={styleContract.tableRow}>
                <View style={{ ...styleContract.tableCell, marginTop: 0 }}>
                  <Text>PEDRO OLIVARES MATURANA</Text>
                  <Text>RUT: 13.994.073-3</Text>
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default PDFMatrimonyContract;
