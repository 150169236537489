import * as React from "react";

import {
  Slide,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

import { Add, HorizontalRule } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref || false} {...props} />;
});

function formatProduct(products, deleteProducts) {
  let rows = [];

  deleteProducts.forEach((id) => {
    const findProduct = products.find((el) => el.id_plapedido === id);

    rows.push({
      idPlapedido: findProduct.id_plapedido,
      amountOriginal: findProduct.amount_plapedido,
      amountNew: findProduct.amount_plapedido,
      nombrePlato: findProduct.nombre_plato,
      idPlato: findProduct.id_plato,
    });
  });

  return rows;
}

const Rows = (props) => {
  let { idx, dataRow, setData } = props;

  const addAmount = () => {
    if (dataRow.amountNew === dataRow.amountOriginal) {
      return;
    }
    setData(idx, "amountNew", dataRow.amountNew + 1);
  };

  const removeAmount = () => {
    if (dataRow.amountNew === 0) {
      return;
    }
    setData(idx, "amountNew", dataRow.amountNew - 1);
  };

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {dataRow.nombrePlato}
      </TableCell>
      <TableCell style={{ textAlign: "center", display: "inline-flex" }}>
        <Button
          onClick={() => removeAmount()}
          disabled={dataRow.amountNew === 0}
          size="small"
          variant="outlined"
          color="error"
        >
          <HorizontalRule />
        </Button>
        <div
          style={{
            padding: 6,
            color: "white",
            fontWeight: "400",
            borderRadius: 4,
            width: 50,
            textAlign: "center",
            background: "#ff6161",
          }}
        >
          {dataRow.amountNew}
        </div>
        <Button
          onClick={() => addAmount()}
          disabled={dataRow.amountNew === dataRow.amountOriginal}
          size="small"
          variant="outlined"
          color="error"
        >
          <Add />
        </Button>
      </TableCell>
    </TableRow>
  );
};

const ListProducts = (props) => {
  let { data, setData } = props;

  const newData = [...data];

  const handleSetData = (idx, name, value) => {
    newData[idx] = { ...newData[idx], [name]: value };
    setData(newData);
  };

  return (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Nombre</TableCell>
          <TableCell>Cantidad</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {newData.map((row, idx) => ( 
          <Rows setData={handleSetData} dataRow={row} idx={idx} key={idx} />
        ))}
      </TableBody>
    </Table>
  );
};

const DialogDeleteProducts = (props) => {
  let { callback, open, setOpen, productsDelete, selectedDelete } = props;
  const [dataProducts, setDataProducts] = React.useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    callback(dataProducts);
    handleClose();
  };

  React.useEffect(() => {
    if (open) {
      setDataProducts(formatProduct(productsDelete, selectedDelete));
    }

    return () => setDataProducts([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth
      onClose={handleClose}
      maxWidth={"sm"}
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Elminar productos</DialogTitle>
      <DialogContent>
        <ListProducts data={dataProducts} setData={setDataProducts} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="text" color="inherit">
          Cerrar
        </Button>
        <Button
          onClick={() => handleConfirm()}
          variant="contained"
          color="success"
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogDeleteProducts;
