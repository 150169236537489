import React from "react";

import { Paper, Typography } from "@mui/material";

import { Controller } from "react-hook-form";

import Input from "../../../../../components/Forms/Input";

const FormPedido = (props) => {
  let { control } = props;

  return (
    <>
      <Typography component={"h6"} variant={"h6"}>
        Datos:
      </Typography>
      <Paper variant="outlined" style={{ padding: "15px 10px 0px" }}>
        <Controller
          name="codigo_pedido"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              required
              value={value}
              onChange={onChange}
              type="text"
              title="Código de pedido"
              style={{ marginBottom: "15px" }}
            />
          )}
        />
        {/* <Controller
          name="name"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              required
              value={value}
              onChange={onChange}
              type="text"
              title="Nombres"
              style={{ marginBottom: "15px" }}
            />
          )}
        />
        <Controller
          name="lastname"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              required
              value={value}
              onChange={onChange}
              type="text"
              title="Apellidos"
              style={{ marginBottom: "15px" }}
            />
          )}
        />
        <Controller
          name="number"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              inputProps={{
                maxLength: 9,
                minLength: 9,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+56</InputAdornment>
                ),
              }}
              required
              value={value}
              onChange={onChange}
              type="tel"
              title="Teléfono"
              onInput={(e) => {
                parseInt(e.target.value) > 0
                  ? (e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 9))
                  : parseInt(e.target.value);
              }}
              min={9}
              style={{ marginBottom: "15px" }}
            />
          )}
        /> */}
        {/* <Controller
          name="email"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              required
              value={value}
              onChange={onChange}
              type="email"
              title="Correo electrónico"
              style={{ marginBottom: "15px" }}
            />
          )}
        /> */}
      </Paper>
    </>
  );
};

export default FormPedido;
