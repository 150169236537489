import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";

import { delete_sucursal } from "../../services/sucursal.services";

import toast from "react-hot-toast";

const DialogEliminarSucursal = ({ open, setOpen, data, handleGetSucursales }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const hanndleDeleteSucursal = async (id) => {
    try {
      let { affectedRows } = await delete_sucursal(id);

      if (affectedRows === 1) {
        toast.success("Sucursal eliminado correctamente");
        setOpen(false);
        handleGetSucursales();
      } else {
        toast.error("No se pudo eliminar el sucursal");
        setOpen(false);
      }
    } catch (err) {
      console.log(err.message);
      toast.error("Hubo un error al eliminar al sucursal");
      setOpen(false);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Eliminar"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Estas seguro de eliminar a {data.nom_empsede} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="text" color="inherit">
            Cerrar
          </Button>
          <Button
            onClick={() => {
              hanndleDeleteSucursal(data.id_empsede);
            }}
            variant="contained"
            color="error"
            autoFocus
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogEliminarSucursal;
