import * as React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import Input from "../../../../../components/Forms/Input";

import { Controller, useForm } from "react-hook-form";

import { Search } from "@mui/icons-material";
import { toast } from "react-hot-toast";
import { onCheckIsValid } from "../../../../../functions/checkValidBenefit";
import { getUser } from "../../../../../services/auth.services";
import { getTicketBenefitId } from "../../../../../services/ticketBenefit.services";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Discount = ({ control, watch, options, optionCover }) => {
  return (
    <>
      <Grid item xs={12}>
        <Controller
          name="discountType"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              required
              value={value}
              onChange={onChange}
              options={options}
              type="select"
              title="Tipo"
            />
          )}
        />
      </Grid>
      {/* {watch("discountType") === "COVER" && (
        <Grid item xs={12}>
          <Controller
            name="amountCover"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                required
                value={value}
                onChange={onChange}
                options={optionCover}
                type="select"
                title="Cantidad de covers"
              />
            )}
          />
        </Grid>
      )} */}

      <Grid item xs={12}>
        <Controller
          name="numDiscount"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              required
              value={value}
              onChange={onChange}
              type="text"
              title="Descuento"
              inputProps={{
                min: 0,
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="commentDiscount"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              value={value}
              onChange={onChange}
              type="textarea"
              title="Comentario"
              minRows={3}
            />
          )}
        />
      </Grid>
    </>
  );
};

const Benefit = ({
  handleSearchBenefit,
  cod,
  setCod,
  resetFormBenefit,
  control,
  options,
}) => {
  return (
    <>
      <Grid item xs={11}>
        <Input
          required
          value={cod}
          onChange={(e) => {
            setCod(e.target.value);
            resetFormBenefit();
          }}
          type="text"
          title="Código de beneficio"
        />
      </Grid>
      <Grid item xs={1}>
        <IconButton
          size="large"
          onClick={handleSearchBenefit}
          style={{ background: "#33058d", color: "#fff" }}
        >
          <Search />
        </IconButton>
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="discountType"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              disabled
              value={value}
              onChange={onChange}
              options={options}
              type="select"
              title="Tipo"
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="numDiscount"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              disabled
              value={value}
              onChange={onChange}
              type="text"
              title="Descuento"
              inputProps={{
                min: 0,
              }}
            />
          )}
        />
      </Grid>
    </>
  );
};

const optionFilters = () => {
  // only for company Verde mostaza (2)
  // if (getUser().empresa.id_emp === 2) {
  //   return [
  //     { value: "PORCENTAJE", label: "Porcentaje" },
  //     { value: "PRECIO", label: "Precio" },
  //     { value: "COVER", label: "Cover" },
  //   ];
  // }

  return [
    { value: "PORCENTAJE", label: "Porcentaje" },
    { value: "PRECIO", label: "Precio" },
  ];
};

const DialogApplyDiscount = (props) => {
  let { handleAction, open, setOpen, infoPedido } = props;

  let { data } = infoPedido;

  let initialValues = {
    numDiscount: 0,
    commentDiscount: "",
    discountType: 0,
    amountCover: 0,
    formBenefit: "normal",
    id_ticketBenefit: null,
  };

  const { control, setValue, handleSubmit, watch, reset } = useForm({
    defaultValues: initialValues,
  });

  const [errDialog, setErrDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [dataBenefit, setDataBenefit] = React.useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearchBenefit = async () => {
    if (watch("id_ticketBenefit") === "") {
      toast.error("Debe ingresar un código de beneficio");
      return;
    }

    setDataBenefit(null);

    setLoading(true);
    const idEmpSede = getUser()?.empresa?.id_empsede;
    const response = await getTicketBenefitId(watch("id_ticketBenefit"));

    setLoading(false);

    if (!response) {
      toast.error("No se encontró el código de beneficio");
      setValue("numDiscount", 0);
      setValue("discountType", 0);
      return;
    }

    if (response?.isredeemed_ticketbenefit === 1) {
      toast.error("El beneficio ya fue utilizado.");
      setValue("numDiscount", 0);
      setValue("discountType", 0);
      return;
    }
    if (
      onCheckIsValid(response) === "Inactivo" ||
      onCheckIsValid(response) === "Vencido"
    ) {
      toast.error(`El beneficio está ${onCheckIsValid(response)}.`);
      setValue("numDiscount", 0);
      setValue("discountType", 0);
      return;
    }

    if (response?.id_empsede === idEmpSede) {
      setDataBenefit(response);

      setValue("numDiscount", response?.value_benefit);
      setValue(
        "discountType",
        response?.typevalue_benefit === "money" ? "PRECIO" : "PORCENTAJE"
      );

      return;
    }

    toast.error("El beneficio no existe.");
  };

  const handleSubmitDialog = async (data) => {
    handleClose();
    const { status } = await handleAction(data);

    if (status === "error") {
      setErrDialog("Error al aplicar descuento");
    }
  };

  const options = optionFilters();

  const optionCover = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10" },
  ];

  const priceCover = 5000;

  const handleCalculateCover = () => {
    if (watch("discountType") === "COVER") {
      setValue("numDiscount", priceCover * watch("amountCover"));
      return;
    }
  };

  const resetFormBenefit = () => {
    setValue("numDiscount", 0);
    setValue("discountType", 0);
    setDataBenefit(null);
  };

  handleCalculateCover();

  React.useEffect(() => {
    setErrDialog(false);

    if (open) {
      setValue("discountType", data.tipodescuento_pedido);
      setValue("numDiscount", data.descuento_pedido);
      setValue("commentDiscount", data.observaciondescuento_pedido);
      setValue("amountCover", data.cover_pedido || 0);
      setValue("formBenefit", data.form_benefit || "normal");
      setValue("id_ticketBenefit", data.id_ticketbenefit || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      maxWidth={"sm"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form onSubmit={handleSubmit(handleSubmitDialog)} autoComplete="off">
        <DialogTitle id="alert-dialog-title">Aplicar descuento</DialogTitle>
        <DialogContent>
          <br />

          <Grid container spacing={1}>
            {errDialog && (
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography
                  variant="h6"
                  component={"h6"}
                  style={{ color: "red" }}
                >
                  Error al aplicar descuento.
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Tabs
                value={watch("formBenefit") === "normal" ? 0 : 1}
                onChange={(event, newValue) => {
                  reset(initialValues);
                  setValue(
                    "formBenefit",
                    newValue === 0 ? "normal" : "benefit"
                  );
                }}
                centered
              >
                <Tab label="Descuento" />
                <Tab label="Beneficio" />
              </Tabs>
            </Grid>

            {watch("formBenefit") === "normal" && (
              <Discount
                control={control}
                watch={watch}
                options={options}
                optionCover={optionCover}
              />
            )}

            {watch("formBenefit") === "benefit" && (
              <Benefit
                handleSearchBenefit={handleSearchBenefit}
                resetFormBenefit={resetFormBenefit}
                control={control}
                options={options}
                cod={watch("id_ticketBenefit")}
                setCod={(cod) => setValue("id_ticketBenefit", cod)}
              />
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="text" color="inherit">
            Cerrar
          </Button>
          <Button
            type="submit"
            disabled={
              loading ||
              (!dataBenefit?.id_ticketbenefit &&
                watch("formBenefit") === "benefit")
            }
            variant="contained"
            color="success"
          >
            Aceptar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogApplyDiscount;
