/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Template from "../../components/Template";

import LoadingPage from "../../components/LoadingPage";

import PasswordStrengthBar from "react-password-strength-bar";

import { get_user_by_id } from "../../services/user.services";

import { LoadingButton } from "@mui/lab";
import { Alert, Button, Grid } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import InputDefault from "../../atomic/atoms/input.atoms";
import ContainerInput from "../../components/ContainerInput";
import {
  changePassword,
  checkToken,
  getUser,
} from "../../services/auth.services";

const initalValues = {
  usu_id: "",
  usu_actual_passwprd: "",
  usu_new_password: "",
  usu_confirm_new_password: "",
};

const SettingPassword = () => {
  const history = useHistory();

  const { control, handleSubmit, reset } = useForm({
    defaultValues: initalValues,
  });

  const [loadPage, setLoadPage] = useState(false);
  const [load, setLoad] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);

  //obtain the user data

  const getUserData = async () => {
    setLoadPage(true);
    try {
      const userId = getUser()?.id;

      const response = await get_user_by_id(userId);

      const dataUserResponse = response[0];

      reset({
        ...initalValues,
        usu_id: dataUserResponse.usu_id,
      });
      setLoadPage(false);
    } catch (err) {
      setLoadPage(false);
      console.log(err.message);
    }
  };

  const onSubmitAction = async (data) => {
    if (data.usu_new_password !== data.usu_confirm_new_password) {
      toast.error("Las contraseña nueva no coincide con la confirmación", {
        duration: 5000,
      });
      return;
    }

    try {
      setLoad(true);
      const response = await changePassword(data);

      if (response.status !== 200 && response.body !== "Incorrect password") {
        toast.error("La contraseña actual no coincide con la registrada");

        setLoad(false);
        setIsSuccess(false);
        return;
      }

      if (response.status !== 200) {
        toast.error("Ha ocurrido un error, por favor intente nuevamente");
        setLoad(false);
        setIsSuccess(false);
        return;
      }

      toast.success("Contraseña actualizada correctamente");
      setLoad(false);
      setIsSuccess(true);
    } catch (err) {
      toast.error("Ha ocurrido un error, por favor intente nuevamente");
      setIsSuccess(false);
      setLoad(false);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (!checkToken()) {
      history.push("/");
      return null;
    }
  }, []);

  return (
    <Template title="Mi perfil">
      <LoadingPage open={loadPage} />
      {isSuccess && (
        <Alert variant="standard" color="success" className="mb-5 text-left">
          Contraseña actualizada correctamente
        </Alert>
      )}
      <ContainerInput className="px-4">
        <form onSubmit={handleSubmit(onSubmitAction)} autoComplete="off">
          <div className={`${load && "animate-pulse"} `}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <h1 className="text-2xl text-blue-950 mb-4">
                  Cambiar contraseña
                </h1>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="usu_actual_passwprd"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputDefault
                      required
                      loading={false}
                      fullWidth
                      value={value}
                      onChange={onChange}
                      title={"Contraseña actual"}
                      type={"password"}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="usu_new_password"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <InputDefault
                        required
                        loading={false}
                        fullWidth
                        value={value}
                        onChange={onChange}
                        title={"Nueva contraseña"}
                        type={"password"}
                        inputProps={{ minLength: 6, maxLength: 20 }}
                      />
                      <PasswordStrengthBar
                        password={value}
                        minLength={6}
                        scoreWords={[
                          "Muy débil",
                          "Débil",
                          "Seguro",
                          "Fuerte",
                          "Muy Fuerte",
                        ]}
                        shortScoreWord="Corto"
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="usu_confirm_new_password"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <InputDefault
                        required
                        loading={false}
                        fullWidth
                        value={value}
                        onChange={onChange}
                        title={"Confirmar nueva contraseña"}
                        type={"password"}
                        inputProps={{ minLength: 6, maxLength: 20 }}
                      />
                      <PasswordStrengthBar
                        password={value}
                        minLength={6}
                        scoreWords={[
                          "Muy débil",
                          "Débil",
                          "Seguro",
                          "Fuerte",
                          "Muy Fuerte",
                        ]}
                        shortScoreWord="Corto"
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
              <Grid
                item
                xs={12}
                sm={12}
                className="text-right flex gap-2 justify-end mt-4"
              >
                <Button
                  color="info"
                  variant="text"
                  disable={load.toString()}
                  onClick={() => reset(initalValues)}
                  className="mr-2"
                >
                  Limpiar
                </Button>
                <LoadingButton
                  className="!w-32"
                  loading={load}
                  type="submit"
                  color="secondary"
                  variant="contained"
                  loadingIndicator="Cargando..."
                >
                  Guardar
                </LoadingButton>
              </Grid>
            </Grid>
          </div>
        </form>
      </ContainerInput>
    </Template>
  );
};

export default SettingPassword;
