import axios from "axios";
import { config } from "../config";

import { axiosHeader } from "../functions/axiosHeader";

const { baseurl } = config;

export const add_pedido = (params) => {
  const url = `${baseurl}/api/pedidos/add/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const update_discount = (params) => {
  const url = `${baseurl}/api/pedidos/update_discount/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const update_discount_product = (params) => {
  const url = `${baseurl}/api/pedidos/update_discount_product/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_pedido_id = (id) => {
  const url = `${baseurl}/api/pedidos/get_codigo/${id}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_product_print = (codigo_pedido) => {
  const url = `${baseurl}/api/pedidos/get_producto_print/${codigo_pedido}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_product_all_print = (codigo_pedido) => {
  const url = `${baseurl}/api/pedidos/get_all_producto_print/${codigo_pedido}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const update_print_product = (params) => {
  const url = `${baseurl}/api/pedidos/update_print_product/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_pedido = (status) => {
  const url = `${baseurl}/api/pedidos/get/${status}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_pedido_all = () => {
  const url = `${baseurl}/api/pedidos/get_all`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_pedido_print = () => {
  const url = `${baseurl}/api/pedidos/get_pedido_print`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_finished_today = () => {
  const url = `${baseurl}/api/pedidos/get_finished_today`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_pedido_cocinero = (type) => {
  const url = `${baseurl}/api/pedidos/get_cocinero/${type}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_pedido_caja = () => {
  const url = `${baseurl}/api/pedidos/get_caja`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_pedido_caja_secondary = () => {
  const url = `${baseurl}/api/pedidos/get_caja_secondary`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_mesa_disponible = () => {
  const url = `${baseurl}/api/pedidos/get_mesa`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const search_products = (params) => {
  const url = `${baseurl}/api/pedidos/search_products/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const add_producto_pedido = (params) => {
  const url = `${baseurl}/api/pedidos/add_product/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_pedido_producto = (id) => {
  const url = `${baseurl}/api/pedidos/get_product/${id}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const change_status_product = (params) => {
  const url = `${baseurl}/api/pedidos/status_product/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const update_plato_product = (params) => {
  const url = `${baseurl}/api/pedidos/change_product_discount/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_save_comment = (params) => {
  const url = `${baseurl}/api/pedidos/saveComment/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const update_payaccount = (params) => {
  const url = `${baseurl}/api/pedidos/update_payaccount/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const change_status_order = (params) => {
  const url = `${baseurl}/api/pedidos/status_order/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const delete_products = (params) => {
  const url = `${baseurl}/api/pedidos/delete_products/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const saveSplit_account = (params) => {
  const url = `${baseurl}/api/pedidos/savesplit_account/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const motiveDelete = (params) => {
  const url = `${baseurl}/api/pedidos/motive_delete/`;

  return axios
    .patch(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const change_mesa = (params) => {
  const url = `${baseurl}/api/pedidos/change_mesa/`;

  return axios
    .patch(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const change_garzon = (params) => {
  const url = `${baseurl}/api/pedidos/change_garzon/`;

  return axios
    .patch(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const number_opentables = (params) => {
  const url = `${baseurl}/api/pedidos/number_opentables/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const search_clientcarta = (params) => {
  const url = `${baseurl}/api/pedidos/search_clientcarta/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const join_pedidos = (params) => {
  const url = `${baseurl}/api/pedidos/join_pedidos/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const update_liberados = (params) => {
  const url = `${baseurl}/api/pedidos/update_liberados/`;

  return axios
    .patch(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

/**
 * MOTIVOS
 */

export const get_motivos = () => {
  const url = `${baseurl}/api/pedidos/motivos/get`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

/**
 * REPORTES
 */

export const best_selling_product = (id_caja = 0) => {
  const url = `${baseurl}/api/pedidos/reports/best_selling_product/${id_caja}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const best_selling_category = (id_caja = 0) => {
  const url = `${baseurl}/api/pedidos/reports/best_selling_category/${id_caja}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const best_selling_table = (id_caja = 0) => {
  const url = `${baseurl}/api/pedidos/reports/best_selling_table/${id_caja}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const best_seller = (id_caja = 0) => {
  const url = `${baseurl}/api/pedidos/reports/best_seller/${id_caja}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const best_seller_tip = (id_caja = 0) => {
  const url = `${baseurl}/api/pedidos/reports/best_seller_tip/${id_caja}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const pay_type = (id_caja = 0) => {
  const url = `${baseurl}/api/pedidos/reports/pay_type/${id_caja}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const tot_sale = (id_caja = 0) => {
  const url = `${baseurl}/api/pedidos/reports/tot_sale/${id_caja}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const tot_apertura = (id_caja = 0) => {
  const url = `${baseurl}/api/pedidos/reports/tot_apertura/${id_caja}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const tot_cover = (id_caja = 0) => {
  const url = `${baseurl}/api/pedidos/reports/tot_cover/${id_caja}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const obtainProductsBodegaToday = () => {
  const url = `${baseurl}/api/pedidos/productsBodegaToday/`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const productBodegaHistory = () => {
  const url = `${baseurl}/api/pedidos/bodega/productsBodegaHistory/`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const addProductBodegaHistory = (params) => {
  const url = `${baseurl}/api/pedidos/bodega/addProductBodegaHistory/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};
