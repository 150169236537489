import React, { useEffect } from "react";

import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Rating,
} from "@mui/material";
import InputDefault from "../../atomic/atoms/input.atoms";

import { Controller, useForm } from "react-hook-form";
import {
  add_tracking,
  get_action_phases,
  get_phases,
  update_tracking,
} from "../../services/quotation.services";

import { toast } from "react-hot-toast";
import dayjs from "dayjs";

const initalValues = {
  idQuotation: 0,
  idTracking: 0,
  idPhase: 0,
  idActionPhase: 0,
  descriptionTracking: "",
  typeInterestTracking: 0,
  dateScheduledTracking: "",
};

const ModalTrackingQuotation = (props) => {
  const { open, setOpen, selected, setSelected, callBack, idQuotation } = props;

  const { control, handleSubmit, watch, reset, setValue } = useForm({
    defaultValues: initalValues,
  });

  const [actionPhase, setActionPhase] = React.useState([]);
  const [phases, setPhases] = React.useState([]);

  const [load, setLoad] = React.useState(false);

  const SCHEDULE_WITHOUT_DATE = 14;

  const onAction = async (data) => {
    setLoad(true);

    if (data?.idTracking > 0) {
      await update_tracking(data, data.idTracking);

      await callBack();

      toast.success("Se modifico el seguimiento correctamente", {
        position: "top-center",
      });

      setOpen(false);
      setLoad(false);

      return;
    }

    await add_tracking(data);
    await callBack();

    toast.success("Se creo el seguimiento correctamente", {
      position: "top-center",
    });

    setLoad(false);
    setOpen(false);
  };

  const fetchDataPhases = async () => {
    const response = await get_phases();

    const newResponse = response.map((el) => ({
      value: el.id_phase,
      label: el.name_phase,
    }));

    setPhases(newResponse);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await get_action_phases();

      const newResponse = response
        .filter((el) => el.id_phase === Number(watch("idPhase")))
        .map((el) => ({
          value: el.id_actionphase,
          label: el.name_actionphase,
        }));

      setActionPhase(newResponse);

      newResponse.length === 0 && setValue("idActionPhase", null);
    };

    if (watch("idPhase") > 0) {
      fetchData();
    }
  }, [watch("idPhase")]);

  useEffect(() => {
    if (open) {
      fetchDataPhases();

      if (selected?.id_tracking && selected?.id_tracking > 0) {
        reset({
          ...initalValues,
          idTracking: selected.id_tracking,
          idPhase: Number(selected.id_phase),
          idActionPhase: Number(selected.id_actionphase),
          descriptionTracking: selected.description_tracking,
          typeInterestTracking: selected.type_interest_tracking,
          dateScheduledTracking:
            selected.date_scheduled_tracking &&
            dayjs(selected.date_scheduled_tracking)
              .utc()
              .format("YYYY-MM-DDTHH:mm"),
          idQuotation,
        });
      } else {
        reset({
          ...initalValues,
          idQuotation,
        });
      }
    } else {
      setActionPhase([]);

      reset(initalValues);
      setSelected(null);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth={"sm"}
      aria-describedby="dialog-benefit"
    >
      <form onSubmit={handleSubmit(onAction)} autoComplete="off">
        <DialogTitle>
          {watch("idTracking") ? "Editar" : "Agregar"} seguimiento
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={`${load && "animate-pulse"} `}>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Controller
                name="idPhase"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    required
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"Fases"}
                    type={"select"}
                    options={phases}
                  />
                )}
              />
            </Grid>
            {actionPhase.length > 0 && (
              <Grid item xs={12} sm={6}>
                <Controller
                  name="idActionPhase"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputDefault
                      required
                      loading={false}
                      fullWidth
                      value={value}
                      onChange={onChange}
                      title={"Acción de fase"}
                      type={"select"}
                      options={actionPhase}
                    />
                  )}
                />
              </Grid>
            )}

            <Grid item xs={12} sm={12}>
              <p className="text-xs text-gray-500">Nivel de interés:</p>
              <Controller
                name="typeInterestTracking"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Rating
                    name="simple-controlled"
                    value={value}
                    onChange={(_, newValue) => {
                      onChange(Number(newValue));
                    }}
                  />
                )}
              />
            </Grid>

            {Number(watch("idPhase")) === 3 && Number(watch("idActionPhase")) !== SCHEDULE_WITHOUT_DATE && (
              <Grid item xs={12} sm={12}>
                <Controller
                  name="dateScheduledTracking"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputDefault
                      required
                      loading={false}
                      fullWidth
                      value={value}
                      onChange={onChange}
                      title={"Fecha de agendamiento"}
                      type={"datetime-local"}
                    />
                  )}
                />
              </Grid>
            )}

            <Grid item xs={12} sm={12}>
              <Controller
                name="descriptionTracking"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    required
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    type={"textarea"}
                    title={"Descripción"}
                    rows="4"
                    cols="50"
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disable={load.toString()}
            color="inherit"
            variant="text"
            onClick={() => {
              setOpen(false);
            }}
          >
            Salir
          </Button>
          <Button
            color="secondary"
            variant="contained"
            type="submit"
            disable={load.toString()}
          >
            Aceptar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default ModalTrackingQuotation;
