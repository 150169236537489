import { Skeleton, Stack } from "@mui/material";
import { useEffect, useState } from "react";

import InputDefault from "../../atomic/atoms/input.atoms";

import { get_categoria } from "../../services/categoria_plato.services";

const CategoryProduct = ({ value, onChange, props }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const obtainCategoryProduct = async () => {
    setLoading(true);
    const data = await get_categoria();
    setData(
      data.map((el) => ({
        value: el.id_categoriapla,
        label: el.nombre_categoriapla,
      }))
    );
    setLoading(false);
  };

  useEffect(() => {
    obtainCategoryProduct();
  }, []);

  if (loading) {
    return (
      <Stack spacing={1}>
        <Skeleton variant="rounded" animation="wave" height={40} />
      </Stack>
    );
  }

  return (
    <>
      <InputDefault
        {...props}
        fullWidth
        value={value}
        onChange={onChange}
        type="select"
        title={"Categoría"}
        options={data}
      />
    </>
  );
};
export default CategoryProduct;
