import React, { useEffect } from "react";

import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";

import { Controller, useForm } from "react-hook-form";

import dayjs from "dayjs";
import { toast } from "react-hot-toast";
import CustomTable from "../../../atomic/organisms/Table.organisms";
import InputDefault from "../../../components/Forms/Input";
import { addProductBodegaHistory } from "../../../services/pedidos.services";

const initalValues = {
  idPlapedido: null,
  amount: 0,
};

const ModalDeliveryProduct = (props) => {
  const { open, setOpen, selected, setSelected, callBack, data } = props;

  const { control, handleSubmit, watch, reset, setValue } = useForm({
    defaultValues: initalValues,
  });

  const [load, setLoad] = React.useState(false);
  const [dataProducts, setDataProducts] = React.useState([]);

  const onAction = async (data) => {
    if (Number(data.amount) <= 0) {
      toast.error("La cantidad debe ser mayor a 0", {
        position: "top-center",
      });

      return;
    }

    await addProductBodegaHistory(data);

    setLoad(true);

    await callBack();

    toast.success("Se creo la cotización correctamente", {
      position: "top-center",
    });

    setLoad(false);
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      if (selected?.id_plapedido && selected?.id_plapedido > 0) {
        reset({
          ...initalValues,
          idPlapedido: selected?.id_plapedido,
        });

        const filterData = data.filter(
          (el) => el.id_plapedido === Number(selected?.id_plapedido)
        );

        setDataProducts(filterData);
      }
    } else {
      setSelected(null);
      reset(initalValues);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth={"sm"}
      aria-describedby="dialog-benefit"
    >
      <form onSubmit={handleSubmit(onAction)} autoComplete="off">
        <DialogTitle>
          Agregar cantidad entregada
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={`${load && "animate-pulse"} `}>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Controller
                name="amount"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"Cantidad entregada"}
                    type={"number"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTable
                columns={[
                  {
                    id: "amount_bpd",
                    label: "Cantidad",
                    minWidth: 140,
                    align: "left",
                  },
                  {
                    id: "createdAt_bdp",
                    label: "Fec. de registro",
                    minWidth: 160,
                    align: "left",
                    format: (el) => {
                      return dayjs(el).utc().format("DD/MM/YYYY");
                    },
                  },
                ]}
                rows={dataProducts}
                load={false}
                error={false}
                pagination={true}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={load}
            color="inherit"
            variant="text"
            onClick={() => {
              setOpen(false);
            }}
          >
            Salir
          </Button>
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            disabled={load}
          >
            Agregar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalDeliveryProduct;
