/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { get_carta } from "../../../services/carta.services";

import Template from "../../../components/Template/TemplateCarta";

import useEmpresa from "../../../hooks/useEmpresa";

import AcordionCartaComponent from "../../../components/AcordionCartaComponent";

const Categories = () => {
  const { empresa } = useParams();

  const [carta, setCarta] = useState({ load: false, err: false, data: [] });
  const dataEmpresa = useEmpresa(empresa, "alias");

  useEffect(() => {
    const handleGetCarta = async () => {
      try {
        setCarta({ load: true, err: false, data: [] });
        const response = await get_carta(dataEmpresa.data.id_empsede);

        // const newResponse = handleFiltrarNoStock(response);

        setCarta({ load: false, err: false, data: response });
      } catch (err) {
        setCarta({ load: false, err: true, data: [] });
        console.log(err);
      }
    };

    if (dataEmpresa.data) {
      handleGetCarta();
    }
  }, [dataEmpresa]);

  return (
    <Template empresa={empresa} title="Carta">
      <br />
      <AcordionCartaComponent dataEmpresa={dataEmpresa.data} data={carta} />
      <br />
    </Template>
  );
};

export default Categories;
