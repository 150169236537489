import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React from "react";

import { useAppContext } from "./state.benefit";

const BenefitModalDelete = () => {
  const state = useAppContext();

  const onDelete = (id) => {
    state.handleDelete(id);
    state.handleModalDelete(false);
  };

  return (
    <Dialog
      open={state.openModalDelete}
      keepMounted
      onClose={() => state.handleModalDelete(false)}
      fullWidth
      maxWidth={"sm"}
      aria-describedby="dialog-benefit"
    >
      <DialogTitle>
        Eliminar beneficio
        <IconButton
          aria-label="close"
          onClick={() => state.handleModalDelete(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <br />
        ¿Está seguro de eliminar el beneficio?
      </DialogContent>
      <DialogActions>
        <Button
          color="inherit"
          variant="text"
          onClick={() => {
            state.handleModalDelete(false);
          }}
        >
          Salir
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => onDelete(state?.defaultValues?.id)}
        >
          Eliminar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default BenefitModalDelete;
