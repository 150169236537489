import { useEffect, useState } from "react";

import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { delete_empresa } from "../../../../services/empresa.services";

const initalValues = {
  idEmp: null,
  nom_emp: "",
};

const ModalCompanyDelete = (props) => {
  const { open, setOpen, selected, setSelected, callBack } = props;

  const [load, setLoad] = useState(false);

  const { handleSubmit, reset } = useForm({
    defaultValues: initalValues,
  });

  const onAction = async (data) => {
    if (!data.idEmp) {
      return;
    }

    setLoad(true);

    try {
      await delete_empresa(selected.id_emp);
      await callBack();

      toast.success("Empresa modificada correctamente");
      setLoad(false);
      setOpen(false);
      return;
    } catch (err) {
      setLoad(false);
      console.log(err);
    }
  };

  useEffect(() => {
    if (open) {
      if (selected?.id_emp && selected?.id_emp > 0) {
        reset({
          ...initalValues,
          idEmp: selected.id_emp,
        });
      } else {
        reset({
          ...initalValues,
        });
      }
    } else {
      reset(initalValues);
      setSelected(null);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth={"sm"}
      aria-describedby="dialog-company"
    >
      <form onSubmit={handleSubmit(onAction)} autoComplete="off">
        <DialogTitle>
          Eliminar empresa
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={`${load && "animate-pulse"} `}>
          <br />
          Estas seguro de eliminar la empresa{" "}
          <span className="text-red-500">{selected?.nom_emp}</span>?
        </DialogContent>
        <DialogActions>
          <Button
            disable={load.toString()}
            color="inherit"
            variant="text"
            onClick={() => {
              setOpen(false);
            }}
          >
            Salir
          </Button>
          <Button
            color="error"
            variant="contained"
            type="submit"
            disable={load.toString()}
          >
            Eliminar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalCompanyDelete;
