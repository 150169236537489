/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";

import { get_user_today } from "../services/user.services";

const useGarzones = () => {
  const [dataGarzon, setDataGarzon] = React.useState({
    data: [],
    load: false,
    err: false,
  });

  React.useEffect(() => {
    async function fetchData() {
      try {
        setDataGarzon({ load: true, data: [], err: false });
        const response = await get_user_today();

        const filterGarzon = response
          .filter((el) => el.usu_active_today === 1)
          .map((el) => ({
            label: el.usu_nombre,
            value: el.usu_id,
          }));

        setDataGarzon({
          load: false,
          data: filterGarzon,
          err: false,
        });
      } catch (err) {
        console.log(err);
        setDataGarzon({ load: false, data: [], err: true });
      }
    }

    fetchData();

    return null;
  }, []);

  return {
    dataGarzon,
  };
};

export default useGarzones;
