
import { Grid, Paper, TextField, Typography } from "@mui/material";

const PedidoSecundario = (props) => {
  const { selected, handleSetSelected } = props;

  const handleChange = (type, e) => {
    e.preventDefault();

    const { value, name } = e.target;

    const data = {
      ...selected[type],
      [name]: value,
    };

    // CAMBIAR QUE NO SEA PARA MESA PORQUE REFRESA TODO EL LOAD
    handleSetSelected(data, type);
  };

  return (
    <Paper variant="none" style={{ padding: 5 }}>
      <Typography variant="h6" component="h6">
        Datos del cliente:
      </Typography>

      <Grid container spacing={1} style={{ marginTop: 5 }}>
        <Grid item xs={12} sm={4} lg={4}>
          <TextField
            value={`${selected.cliente.nombre_cliente}`}
            onChange={(e) => handleChange("cliente", e)}
            fullWidth
            name="nombre_cliente"
            label="Nombres"
            className={`InputDefault`}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PedidoSecundario;
