import React, { useEffect, useState } from "react";

import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import { actionTypes } from "../../../redux/menu/actions";
import { actionTypesPlato } from "../../../redux/plato/actions";

import { get_menu } from "../../../services/menu.services";

import ContainerInput from "../../../components/ContainerInput";

import { Delete, Edit } from "@mui/icons-material";
import NotFound from "../../../atomic/organisms/NotFound.organisms";
import LoadingPage from "../../../components/LoadingPage";
import { get_plato } from "../../../services/plato.services";
import ModalDeleteCategoria from "./Dialog/ConfirmDelete/confirmDeleteCategoria";
import ModalAddEditCategoria from "./Dialog/CrudForm";
import ModalPlatos from "./Dialog/Platos";

const ListCategory = (props) => {
  const dispatch = useDispatch();

  const { dataMenu } = props;

  const { dataSelected_categoria } = useSelector((state) => state.reducer_menu);

  const data = dataMenu.data;
  const loading = dataMenu.load;

  const [loadingPlatos, setLoadingPlatos] = useState(false);

  async function obtainDataPlato() {
    try {
      return await get_plato();
    } catch (err) {
      console.log(err);
    }
  }

  const handleSelectedCategoria = (data) =>
    dispatch({
      type: actionTypes.setDataSelected_categoria,
      payload: data,
    });

  const handleOpenCategory = (data) =>
    dispatch({
      type: actionTypes.openModalAddEdit_categoria,
      payload: data,
    });

  const handleDeleteCategoria = (data) =>
    dispatch({
      type: actionTypes.openModalDelete_categoria,
      payload: data,
    });

  const handlenGetPlatos = (data) =>
    dispatch({
      type: actionTypesPlato.getDataPlato,
      payload: data,
    });

  const handleOpenAddCategory = () => {
    handleOpenCategory(true);
    handleSelectedCategoria({
      id_categoriapla: "",
      nombre_categoriapla: "",
    });
  };

  const handleEdit = (value) => {
    handleOpenCategory(true);
    handleSelectedCategoria({ ...dataSelected_categoria, ...value });
  };

  const handleDelete = (value) => {
    handleSelectedCategoria({ ...dataSelected_categoria, ...value });
    handleDeleteCategoria(true);
  };

  const handleSelectCategory = async (value) => {
    setLoadingPlatos(true);
    const dataPlatos = await obtainDataPlato();

    setLoadingPlatos(false);

    handleSelectedCategoria({ ...dataSelected_categoria, ...value });

    const filter = dataPlatos.filter(
      (el) => el.id_categoriapla === value?.id_categoriapla
    );

    handlenGetPlatos({ data: filter, load: false, err: false });
  };

  if (loading) {
    return (
      <div>
        <h3>Cargando...</h3>
      </div>
    );
  }

  if (data.length === 0) {
    return (
      <>
        <NotFound texto="No se encontró registros." />
        <ListItemButton onClick={() => handleOpenAddCategory(true)}>
          <ListItemText
            id={"asd"}
            primary={
              <Button variant="outlined" color="inherit" fullWidth>
                AGREGAR
              </Button>
            }
          />
        </ListItemButton>
      </>
    );
  }

  return (
    <>
      <LoadingPage open={loadingPlatos} />
      <List
        dense={true}
        sx={{ width: "100%" }}
        subheader={
          <ListSubheader style={{ textAlign: "left" }}>
            Categorías
          </ListSubheader>
        }
      >
        {data.map((value, idx) => {
          const labelId = `list-secondary-label-${idx}`;
          return (
            <ListItem
              key={idx}
              onClick={() => {
                handleSelectCategory(value);
              }}
              style={{
                background:
                  value.id_categoriapla ===
                    dataSelected_categoria?.id_categoriapla && "#33058d40",
              }}
              secondaryAction={
                <>
                  <IconButton color="warning" onClick={() => handleEdit(value)}>
                    <Edit />
                  </IconButton>
                  <IconButton
                    color="error"
                    disabled={value.platos.length !== 0}
                    onClick={() => handleDelete(value)}
                  >
                    <Delete />
                  </IconButton>
                </>
              }
              disablePadding
            >
              <ListItemButton>
                <ListItemText
                  id={labelId}
                  primary={`${
                    value.orden_categoriapla
                      ? `${value.orden_categoriapla})`
                      : ""
                  } ${value.nombre_categoriapla} (${value.platos.length})`}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
        <ListItem disablePadding>
          <ListItemButton onClick={() => handleOpenAddCategory(true)}>
            <ListItemText
              id={"asd"}
              primary={
                <Button variant="outlined" color="inherit" fullWidth>
                  AGREGAR
                </Button>
              }
            />
          </ListItemButton>
        </ListItem>
      </List>
    </>
  );
};

const Categoria = () => {
  const dispatch = useDispatch();

  const { dataMenu } = useSelector((state) => state.reducer_menu);

  const onGet = (data) =>
    dispatch({
      type: actionTypes.getDataMenu,
      payload: data,
    });

  useEffect(() => {
    async function obtainDataMenu() {
      try {
        onGet({ ...dataMenu, load: true, data: [], err: false });
        let response = await get_menu(1);
        onGet({ ...dataMenu, load: false, data: response, err: false });
      } catch (err) {
        console.log(err);
        onGet({ ...dataMenu, load: false, data: [], err: true });
      }
    }

    obtainDataMenu();

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ContainerInput
        style={{
          marginTop: 10,
          marginBottom: 10,
          padding: "15px 5px",
          textAlign: "center",
        }}
      >
        <ListCategory dataMenu={dataMenu} />
      </ContainerInput>

      <ModalAddEditCategoria />
      <ModalDeleteCategoria />
      <ModalPlatos />
    </>
  );
};

export default Categoria;
