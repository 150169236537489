import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

import CustomTable from "../../atomic/organisms/Table.organisms";
import ContainerInput from "../../components/ContainerInput";
import Template from "../../components/Template";

import { Add } from "@mui/icons-material";
import {
  obtainProductsBodegaToday,
  productBodegaHistory,
} from "../../services/pedidos.services";
import FilterDeliveryProduct from "./ui/FilterDeliveryProduct.ui";
import ModalDeliveryProduct from "./ui/ModalDeliveryProduct.ui";

dayjs.locale("es");

const DeliveryProducts = () => {
  const [data, setData] = React.useState([]);
  const [load, setLoad] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);

  const [dataProductHistory, setDataProductHistory] = useState([]);
  const [selected, setSelected] = React.useState(null);

  const fetchData = async () => {
    try {
      setLoad(true);

      const responseHistory = await productBodegaHistory();
      setDataProductHistory(responseHistory);

      const response = await obtainProductsBodegaToday();

      const newResponse = response.map((el) => {
        const historyFilter = responseHistory.filter((item) => {
          return Number(item.id_plapedido) === Number(el.id_plapedido);
        });

        const historyAmount = historyFilter.reduce(
          (acc, el) => acc + el.amount_bpd,
          0
        );

        return {
          ...el,
          historyAmount,
          totalAmount: Number(el.amount) - historyAmount,
        };
      });

      setData(newResponse);
      setLoad(false);

      return response;
    } catch (error) {
      setError(true);
    } finally {
      setLoad(false);
    }
  };

  const onSearch = async (value) => {
    const response = await fetchData();

    let filterData = response;

    if (value.searchName !== "") {
      filterData = response.filter((el) => {
        return el.nombre_plato
          .toLowerCase()
          .includes(value.searchName.toLowerCase());
      });
    }

    if (value.categoryId !== "") {
      filterData = response.filter((el) => {
        return el.id_categoriapla === Number(value.categoryId);
      });
    }

    setData(filterData);
  };

  useEffect(() => {
    // Fetch data
    fetchData();
  }, []);

  return (
    <Template title="Cotización">
      {error && (
        <ContainerInput className="p-0 mb-5">
          ¡Ocurrió un problema!
        </ContainerInput>
      )}

      <FilterDeliveryProduct onSearch={onSearch} />

      <ContainerInput className="p-0">
        <CustomTable
          columns={[
            {
              id: "nombre_plato",
              label: "Plato",
              minWidth: 150,
              align: "left",
              format: (item) => {
                return (
                  <div
                    className="text-blue-500 cursor-pointer hover:underline flex align-middle items-center"
                    onClick={() => {
                      setOpenModal(true);
                      const itemFilter = data.find(
                        (el) => el.nombre_plato === item
                      );

                      setSelected(itemFilter);
                    }}
                  >
                    <Add
                      className="
                      cursor-pointer
                      hover:scale-110
                      transition-transform
                      duration-500
                    "
                    />
                    <span className="ml-2">{item}</span>
                  </div>
                );
              },
            },
            {
              id: "nombre_categoriapla",
              label: "Categoría",
              minWidth: 150,
              align: "left",
            },
            {
              id: "amount",
              label: "Cantidad solicitada",
              minWidth: 140,
              align: "left",
            },
            {
              id: "historyAmount",
              label: "Cantidad entregada",
              minWidth: 140,
              align: "left",
            },
            {
              id: "totalAmount",
              label: "Cantidad pendiente",
              minWidth: 140,
              align: "left",
            },
          ]}
          rows={data}
          load={load}
          error={false}
          pagination={true}
        />
      </ContainerInput>

      <ModalDeliveryProduct
        open={openModal}
        selected={selected}
        data={dataProductHistory}
        setSelected={setSelected}
        setOpen={setOpenModal}
        callBack={() => {
          fetchData();
        }}
      />
    </Template>
  );
};

export default DeliveryProducts;
