import * as React from "react";

import {
  Slide,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Grid,
  Alert,
} from "@mui/material";

import { cerrar_caja } from "../../../services/caja.services";
import LoadingPage from "../../../components/LoadingPage";

import { useForm } from "react-hook-form";

import toast from "react-hot-toast";
import { useEffect } from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref || false} {...props} />;
});

const DialogCerrar = (props) => {
  let { open, setOpen, handleCajaRefresh } = props;

  const [errDialog, setErrDialog] = React.useState(false);
  const [loadPage, setLoadPage] = React.useState(false);

  const { handleSubmit } = useForm({
    defaultValues: {},
  });

  const handleLoad = (value) => {
    setLoadPage(value);
  };

  const handleServiceCerrar = async (data) => {
    try {
      const response = await cerrar_caja();

      if (response.affectedRows !== 1) {
        return { status: "error" };
      }

      return { status: "success" };
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmitDialog = async (data) => {
    handleLoad(true);
    const response = await handleServiceCerrar(data);
    handleLoad(false);

    if (response.status === "error") {
      setErrDialog("Error al cerrar la caja");
      return;
    }

    handleClose();
    handleCajaRefresh();
    toast.success("Caja cerrada con éxito");
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setErrDialog(false)
  },[open]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth
      onClose={handleClose}
      maxWidth={"sm"}
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <LoadingPage open={loadPage} setOpen={setLoadPage} />
      <form onSubmit={handleSubmit(handleSubmitDialog)} autoComplete="off">
        <DialogTitle id="alert-dialog-title">Cerrar caja</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            {errDialog && (
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Alert severity="warning">
                  No se puede cerrar hay pedidos sin finalizar
                </Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              ¿Estás seguro de cerrar la caja de hoy?
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="text" color="inherit">
            Cerrar
          </Button>
          <Button type="submit" variant="contained" color="success">
            Confirmar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogCerrar;
