/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import ContainerInput from "../../components/ContainerInput";
import Template from "../../components/Template";

import LoadingPage from "../../components/LoadingPage";

import { styled } from "@mui/system";

import { Controller, useForm } from "react-hook-form";

import Input from "../../components/Forms/Input";
import {
  get_capacidad,
  update_capacidad,
} from "../../services/capacidad.services";

import { Button, Grid } from "@mui/material";

import { checkToken, getUser } from "../../services/auth.services";

const Capacidad = () => {
  const [loadPage, setLoadPage] = useState(false);

  let history = useHistory();

  let initialValues = {
    lunes: "",
    martes: "",
    miercoles: "",
    jueves: "",
    viernes: "",
    sabado: "",
    domingo: "",
  };

  const { control, reset, watch, setValue, handleSubmit } = useForm({
    defaultValues: initialValues,
  });

  const handleUpdate = async (data) => {
    setLoadPage(true);
    await update_capacidad(data);
    setLoadPage(false);
  };

  useEffect(async () => {
    let response = await get_capacidad(getUser().empresa.id_empsede);
    reset(response);
  }, [reset]);

  useEffect(() => {
    if (!checkToken()) {
      history.push("/");
      return null;
    }
  }, []);

  return (
    <Template title="Reservas">
      <LoadingPage open={loadPage} setOpen={setLoadPage} />

      <form
        onSubmit={handleSubmit(handleUpdate)}
        autoComplete="off"
        style={{ textAlign: "left" }}
      >
        <ContainerInput
          style={{
            marginTop: 20,
            marginBottom: 20,
            padding: "35px 10px",
            textAlign: "center",
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={5} lg={5}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} lg={12}>
                  <Controller
                    name="lunes"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        required
                        value={value}
                        onChange={onChange}
                        type="number"
                        title="Lunes"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                  <Controller
                    name="martes"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        required
                        value={value}
                        onChange={onChange}
                        type="number"
                        title="Martes"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                  <Controller
                    name="miercoles"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        required
                        value={value}
                        onChange={onChange}
                        type="number"
                        title="Miercoles"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                  <Controller
                    name="jueves"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        required
                        value={value}
                        onChange={onChange}
                        type="number"
                        title="Jueves"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                  <Controller
                    name="viernes"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        required
                        value={value}
                        onChange={onChange}
                        type="number"
                        title="Viernes"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                  <Controller
                    name="sabado"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        required
                        value={value}
                        onChange={onChange}
                        type="number"
                        title="Sábado"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                  <Controller
                    name="domingo"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        required
                        value={value}
                        onChange={onChange}
                        type="number"
                        title="Domingo"
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={12}
                  style={{ textAlign: "left" }}
                >
                  <Button type="submit" variant="contained" color="primary">
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ContainerInput>
      </form>
    </Template>
  );
};

export default Capacidad;
