import * as React from "react";

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from "@mui/material";

import { Description } from "@mui/icons-material";
import NotFound from "../../atomic/organisms/NotFound.organisms";

const formatNumber = (n) => {
  return n === "" || n === 0 ? n : Number(n).toLocaleString();
};

const TableMerchantes = ({ rows, setRow, handleViewData }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

   if (rows.length === 0) {
     return <NotFound texto="No se encontró registros." />;
   }

  return (
    <>
      <TableContainer sx={{ maxHeight: 550 }}>
        <Table stickyHeader aria-label="sticky table" size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell align={"left"} style={{ minWidth: 150 }}>
                Nombre
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 190 }}>
                Lucas generadas
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 100 }}>
                Ver historial
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, idx) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                      <TableCell align={"left"}>{row.usu_nombre}</TableCell>
                      <TableCell align={"left"}>$ {formatNumber(row.total_comission)}</TableCell>
                      <TableCell align={"left"}>
                        <IconButton
                          color="warning"
                          onClick={() => handleViewData(row)}
                        >
                          <Description />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage="Filas por página"
        rowsPerPageOptions={[10, 25, 100, { value: -1, label: "Todo" }]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  )
};

export default TableMerchantes;
