/* eslint-disable react-hooks/exhaustive-deps */
import { Print, Search } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import InputDefault from "../../atomic/atoms/input.atoms";
import { useAppContext } from "./state.consumption";

import dayjs from "dayjs";

import { utils, writeFile } from "xlsx";

import InputAutoComplete from "../../atomic/molecule/autocomplete.molecule";
import CategoryProduct from "./categoryProduct.consumption";
import { getUser } from "../../services/auth.services";
import { TYPE_SEARCH } from "./state.consumption";

const ConsumptionSearch = ({ value }) => {
  const state = useAppContext();

  const { control, watch, handleSubmit, reset } = useForm({
    defaultValues: state.searchDefaultValues,
  });

  const NOW_DATE = dayjs();
  const MAX_DATE_INPUT = dayjs(NOW_DATE).format("YYYY-MM-DD");

  const NAME_COMPANY = getUser()?.empresa?.alias_empsede;

  const handlePrint = () => {
    let wb = utils.book_new();
    let ws = utils.json_to_sheet(state.dataExcel);
    utils.book_append_sheet(wb, ws, `Reporte`);
    writeFile(wb, `${NAME_COMPANY || "CONSUMOS REPORTE"}-.xlsx`);
  };

  const handleSearch = (data) => {
    state.onSearch(data);
  };

  useEffect(() => {
    reset(state.searchDefaultValues);
  }, [value]);

  return (
    <form onSubmit={handleSubmit(handleSearch)} autoComplete="off">
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Controller
            name="idPlato"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputAutoComplete
                option={state.dataPlatos}
                label="Platos"
                value={value}
                handleChange={onChange}
              />
            )}
          />
        </Grid>
        {state.inputSearch && (
          <Grid item xs={2}>
            <Controller
              name="idCategory"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => <CategoryProduct value={value} onChange={onChange} />}
            />
          </Grid>
        )}

        {value === TYPE_SEARCH.HISTORY && (
          <>
            <Grid item xs={2}>
              <Controller
                name="dateIni"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputDefault
                    fullWidth
                    InputProps={{
                      inputProps: { min: "", max: MAX_DATE_INPUT },
                    }}
                    required // This is the important part
                    value={value}
                    onChange={onChange}
                    type="date"
                  />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Controller
                name="dateEnd"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputDefault
                    fullWidth
                    InputProps={{
                      inputProps: {
                        min: watch("dateIni"),
                        max: MAX_DATE_INPUT,
                      },
                    }}
                    disabled={!watch("dateIni")}
                    required // This is the important part
                    value={value}
                    onChange={onChange}
                    type="date"
                  />
                )}
              />
            </Grid>
          </>
        )}
        {value === TYPE_SEARCH.PROJECTION && (
          <Grid item xs={3}>
            <Controller
              name="nameDay"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InputDefault
                  required
                  fullWidth
                  value={value}
                  onChange={onChange}
                  title={"Día de la semana"}
                  type="select"
                  options={[
                    { value: "Monday", label: "Lunes" },
                    { value: "Tuesday", label: "Martes" },
                    { value: "Wednesday", label: "Miercoles" },
                    { value: "Thursday", label: "Jueves" },
                    { value: "Friday", label: "Viernes" },
                    { value: "Saturday", label: "Sabado" },
                    { value: "Sunday", label: "Domingo" },
                  ]}
                />
              )}
            />
          </Grid>
        )}
        {value === TYPE_SEARCH.PROJECTION_WEEK && (
          <Grid item xs={3}>
            <Controller
              name="amountWeek"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InputDefault
                  required
                  fullWidth
                  value={value}
                  onChange={onChange}
                  title={"Cantidad de semanas"}
                  type="select"
                  options={[
                    { value: 1, label: "1" },
                    { value: 2, label: "2" },
                    { value: 3, label: "3" },
                    { value: 4, label: "4" },
                    { value: 5, label: "5" },
                    { value: 6, label: "6" },
                    { value: 7, label: "7" },
                    { value: 8, label: "8" },
                    { value: 9, label: "9" },
                    { value: 10, label: "10" },
                  ]}
                />
              )}
            />
          </Grid>
        )}
        <Grid
          item
          xs={2}
          style={{ textAlign: "left", display: "flex", alignItems: "center" }}
        >
          <IconButton
            type="submit"
            size="medium"
            color="primary"
            style={{ background: "#eee" }}
          >
            <Search />
          </IconButton>
          {(state.tabValue === TYPE_SEARCH.HISTORY ||
            state.tabValue === TYPE_SEARCH.PROJECTION) && (
            <IconButton
              size="medium"
              color="primary"
              style={{ background: "#eee", marginLeft: 10 }}
              onClick={handlePrint}
            >
              <Print />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default ConsumptionSearch;
