import { Button } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect } from "react";

import {
  get_event,
  get_type_event,
} from "../../../services/quotation.services";

import InputDefault from "../../../atomic/atoms/input.atoms";

import { Search } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import { Controller, useForm } from "react-hook-form";
import { get_user } from "../../../services/user.services";
import BasicDateRangePicker from "./BasicDateRangePicker.quotation";

dayjs.locale("es");

const FiltersQuotation = (props) => {
  const { onOpenAddModal, onSearch } = props;

  const [event, setEvent] = React.useState([]);
  const [typeEvent, setTypeEvent] = React.useState([]);
  const [dataTypeUser, setDataTypeUser] = React.useState([]);

  const PLANNER_ID = 18;

  const fetchTypeUser = async () => {
    try {
      const response = await get_user();

      setDataTypeUser(
        response
          .filter((el) => Number(el.tipousu_id) === PLANNER_ID && Number(el.usu_active) === 1)
          .map((el) => ({
            label: el.usu_nombre,
            value: el.usu_id,
          }))
      );
    } catch (err) {
      console.log(err.message);
    }
  };

  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      searchName: "",
      dateRange: [],
      idEvent: "",
      idTypeEvent: "",
      userId: "",
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await get_type_event();
      const newResponse = response
        .filter((el) => el.id_event === Number(watch("idEvent")))
        .map((el) => ({
          value: el.id_typeevent,
          label: el.name_typeevent,
        }));

      setTypeEvent(newResponse);

      newResponse.length === 0 && setValue("idTypeEvent", null);
    };

    if (watch("idEvent") > 0) {
      fetchData();
    }
  }, [watch("idEvent")]);

  React.useEffect(() => {
    fetchTypeUser();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await get_event();

      const newResponse = response.map((el) => ({
        value: el.id_event,
        label: el.name_event,
      }));

      setEvent(newResponse);
    };

    fetchData();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSearch)} autoComplete="off">
      <div className="mb-4 w-full grid grid-cols-12 gap-2">
        <div className="col-span-12 md:col-span-2">
          <Controller
            name="idEvent"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputDefault
                loading={false}
                fullWidth
                value={value}
                onChange={onChange}
                title={"Evento"}
                type={"select"}
                options={event}
              />
            )}
          />
        </div>
        {typeEvent.length > 0 && (
          <div className="col-span-12 md:col-span-2">
            <Controller
              name="idTypeEvent"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputDefault
                  loading={false}
                  fullWidth
                  value={value}
                  onChange={onChange}
                  title={"Tipo de evento"}
                  type={"select"}
                  options={typeEvent}
                />
              )}
            />
          </div>
        )}
        <div className="col-span-12 md:col-span-3">
          <Controller
            name="searchName"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                value={value}
                onChange={onChange}
                type={"text"}
                title={"Buscar por código, nombres, email o teléfono"}
              />
            )}
          />
        </div>
        <div className="col-span-6 md:col-span-2">
          <Controller
            name="userId"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                value={value}
                onChange={onChange}
                type={"select"}
                title={"Usuario"}
                options={dataTypeUser}
              />
            )}
          />
        </div>
        <div className="col-span-6 md:col-span-3 flex gap-2">
          <Controller
            name="dateRange"
            className="!w-full"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <BasicDateRangePicker value={value} onChange={onChange} />
            )}
          />
          <IconButton
            type="submit"
            color="default"
            className="ml-auto col-span-1"
          >
            <Search />
          </IconButton>
        </div>
      </div>
      <div className="col-span-12 md:col-span-4 flex justify-end mb-4">
        <Button
          variant="contained"
          color="primary"
          className="ml-auto w-full md:w-auto"
          onClick={() => onOpenAddModal(true)}
        >
          Agregar
        </Button>
      </div>
    </form>
  );
};

export default FiltersQuotation;
