import dayjs from "dayjs";
import { useEffect, useState } from "react";
import CustomTable from "../../atomic/organisms/Table.organisms";
import ContainerInput from "../../components/ContainerInput";
import Template from "../../components/Template";
import { formatMoney } from "../../functions/formatMoney";
import { findPaxProductivity } from "../../services/dashboard.services";
import FiltersReservationSellers from "./ui/filters.reservationsellers";
import TableResume from "./ui/tableResume";

const initalDataFilter = {
  userId: 0,
  filterMonth: dayjs().format("YYYY-MM"),
};

const PAXProductivity = () => {
  const [data, setData] = useState({
    result: [],
    totals: {},
  });
  const [load, setLoad] = useState(false);

  const [dataFilter, setDataFilter] = useState(initalDataFilter);

  const onSearchData = async (value) => {
    setDataFilter(value);

    setLoad(true);

    const response = await findPaxProductivity(value);

    setLoad(false);

    setData(response);
  };

  useEffect(() => {
    onSearchData(initalDataFilter);
  }, []);

  return (
    <Template title="Productivad de ventas">
      <FiltersReservationSellers
        onSearchData={onSearchData}
        initialState={initalDataFilter}
        onLoadData={load}
      />

      <ContainerInput className="p-0">
        <CustomTable
          columns={[
            {
              id: "dateOfReservation",
              label: "Fecha de Reserva",
              minWidth: 130,
              align: "left",
              format: (el) => {
                return dayjs(el).utc().format("DD/MM/YYYY");
              },
            },
            {
              id: "PAXProyected",
              label: "PAX Venta Proyectado",
              minWidth: 80,
              align: "left",
              format: (el) => {
                return <div className="text-right">{el}</div>;
              },
            },
            {
              id: "PAXConfirmed",
              label: "PAX Confirmado",
              minWidth: 80,
              align: "left",
              format: (el) => {
                return <div className="text-right">{el}</div>;
              },
            },
            {
              id: "PAXPending",
              label: "PAX Pendiente",
              minWidth: 80,
              align: "left",
              format: (el) => {
                return <div className="text-right">{el}</div>;
              },
            },
            {
              id: "PAXEliminated",
              label: "PAX Eliminado",
              minWidth: 80,
              align: "left",
              format: (el) => {
                return <div className="text-right">{el}</div>;
              },
            },
            {
              id: "PAXNoAsisted",
              label: "PAX No Asistido",
              minWidth: 80,
              align: "left",
              format: (el) => {
                return <div className="text-right">{el}</div>;
              },
            },
            {
              id: "PAXReleased",
              label: "PAX Liberado",
              minWidth: 80,
              align: "left",
              format: (el) => {
                return <div className="text-right">{el}</div>;
              },
            },
            {
              id: "PAXAssistedBruto",
              label: "PAX Asistido Bruto",
              minWidth: 80,
              align: "left",
              format: (el) => {
                return <div className="text-right">{el}</div>;
              },
            },
            {
              id: "PAXAssistedNeto",
              label: "PAX Asistido Neto",
              minWidth: 80,
              align: "left",
              format: (el) => {
                return <div className="text-right">{el}</div>;
              },
            },
            {
              id: "PagoXConsumo",
              label: "Ingreso por venta",
              minWidth: 80,
              align: "left",
              format: (el) => {
                return <div className="text-right">{formatMoney(el)}</div>;
              },
            },
          ]}
          rows={data.result}
          rowsFooter={data.totals}
          columnsFooter={[
            {
              id: "dateOfReservation",
            },
            {
              id: "PAXProyected",
              format: (el) => {
                return <div className="text-right">{el}</div>;
              },
            },
            {
              id: "PAXConfirmed",
              format: (el) => {
                return <div className="text-right">{el}</div>;
              },
            },
            {
              id: "PAXPending",
              format: (el) => {
                return <div className="text-right">{el}</div>;
              },
            },
            {
              id: "PAXEliminated",
              format: (el) => {
                return <div className="text-right">{el}</div>;
              },
            },
            {
              id: "PAXNoAsisted",
              format: (el) => {
                return <div className="text-right">{el}</div>;
              },
            },
            {
              id: "PAXReleased",
              format: (el) => {
                return <div className="text-right">{el}</div>;
              },
            },
            {
              id: "PAXAssistedBruto",
              format: (el) => {
                return <div className="text-right">{el}</div>;
              },
            },
            {
              id: "PAXAssistedNeto",
              format: (el) => {
                return <div className="text-right">{el}</div>;
              },
            },
            {
              id: "PagoXConsumo",
              format: (el) => {
                return <div className="text-right">{formatMoney(el)}</div>;
              },
            },
          ]}
          load={load}
          error={false}
          disableButtons={false}
          pagination={true}
          rowsPerPagination={100}
        />
      </ContainerInput>

      {data.result.length > 0 && !load && (
        <ContainerInput className="mt-2 px-4 w-auto">
          <TableResume
            justBySeller={dataFilter?.userId > 0}
            paxMonth={data.totals[0].PAXAssistedNeto}
            comissionPerPerson={data.totals[0].comissionPerPerson}
            comissionGained={formatMoney(data.totals[0].comissionGained, "CLP")}
          />
        </ContainerInput>
      )}
    </Template>
  );
};

export default PAXProductivity;
