import * as React from "react";

import { Grid, InputAdornment } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import { useForm, Controller } from "react-hook-form";

import { add_clientes_carta } from "../../../services/clientes.services";

import Input from "../../../components/Forms/Input";
import LoadingPage from "../../../components/LoadingPage";
import dayjs from "dayjs";
import { formatPhoneNumber } from "../../../functions/formatPhoneNumber";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SaveUserData = (props) => {
  let { open, setOpen, empresa, handleSetDataUser, dataUrl } = props;

  const [load, setLoad] = React.useState(false);

  let initialValues = {
    numtelf_client: "",
    name_client: "",
    ape_client: "",
    ...dataUrl,
  };

  const { control, handleSubmit } = useForm({
    defaultValues: initialValues,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async (data) => {
    try {
      setLoad(true);
      const response = await add_clientes_carta({ ...data, empresa });
      setLoad(false);

      if (response) {
        const date = dayjs().format("YYYY-MM-DD HH:mm:ss");

        handleSetDataUser({ ...data, createdAt: date });

        localStorage.setItem(
          "cart_user",
          JSON.stringify({ ...data, createdAt: date })
        );

        handleClose();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Dialog
      fullWidth
      open={open}
      TransitionComponent={Transition}
      maxWidth={"sm"}
      keepMounted
      //   onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <LoadingPage open={load} />
      <form onSubmit={handleSubmit(handleSave)}>
        <DialogTitle style={{ borderBottom: "1px solid #cfcfcf" }}>
          ¡Queremos conocerte!
        </DialogTitle>
        <DialogContent className="dialogContainer">
          <br />
          <Grid container>
            <Grid item xs={12}>
              <Controller
                name="name_client"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Input
                    required
                    value={value}
                    onChange={onChange}
                    type="text"
                    title="Nombre"
                    style={{ marginBottom: "15px" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="ape_client"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Input
                    required
                    value={value}
                    onChange={onChange}
                    type="text"
                    title="Apellido"
                    style={{ marginBottom: "15px" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="numtelf_client"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Input
                    inputProps={{
                      maxLength: 9,
                      minLength: 9,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {formatPhoneNumber()}
                        </InputAdornment>
                      ),
                    }}
                    required
                    value={value}
                    onChange={onChange}
                    type="tel"
                    title="Teléfono"
                    onInput={(e) => {
                      parseInt(e.target.value) > 0
                        ? (e.target.value = Math.max(
                            0,
                            parseInt(e.target.value)
                          )
                            .toString()
                            .slice(0, 9))
                        : parseInt(e.target.value);
                    }}
                    min={9}
                    style={{ marginBottom: "15px" }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ borderTop: "1px solid #cfcfcf" }}>
          {/* <Button color="inherit" variant={"text"} onClick={handleClose}>
          Salir
        </Button> */}
          <Button type="submit" color="secondary" variant="contained">
            Confirmar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SaveUserData;
