/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { useHistory } from "react-router-dom";

import { Divider, IconButton, Button } from "@mui/material";
import { Add, Search } from "@mui/icons-material/";

import Template from "../../components/Template";
import LoadingPage from "../../components/LoadingPage";

import { checkToken, getUser } from "../../services/auth.services";
import ContainerInput from "../../components/ContainerInput";

import { get_wallet_merchantes } from "../../services/wallet.services";

import InputDefault from "../../atomic/atoms/input.atoms";

import TableMerchantes from "../../components/Tables/TableMerchantes";
import DialogWallet from "./DialogWallet";

const formatNumber = (n) => {
  return n === "" || n === 0 ? n : Number(n).toLocaleString();
};

const FilterMerchantes = (props) => {
  const { onOpenAddModal, onSearch } = props;

  const { control, handleSubmit } = useForm({
    defaultValues: {
      searchNombre: ""
    },
  });
  return (
    <form onSubmit={handleSubmit(onSearch)} autoComplete="off">
      <div className="mb-4 w-full grid grid-cols-12 gap-2">
        <div className="col-span-8 md:col-span-4">
          <Controller
            name="searchNombre"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputDefault
                loading={false}
                fullWidth
                value={value}
                onChange={onChange}
                title={"Buscar merchante"}
                type={"text"}
              />
            )}
          />
        </div>
        <div className="col-span-4 md:col-span-3 flex gap-3">
          <IconButton
            type="submit"
            color="default"
            className="ml-auto col-span-1"
          >
            <Search />
          </IconButton>
        </div>
      </div>
    </form>
  );
}

const MetaMerchantes = () => {
  let history = useHistory();
  const [loadPage, setLoadPage] = useState(false);
  const [dataMerchantes, setDataMerchantes] = useState({ load: false, data: [] });
  const [metaComission, setMetaComission] = useState(0);
  const [comission, setCommision] = useState(0);
  const [currency, setCurrency] = useState("");


  const [openAdd, setOpenAdd] = useState(false);
  const [dataViewData, setDataViewData] = useState({});

  const getMerchantes = async () => {
    const data = JSON.parse(localStorage.getItem("user_vm"));
    const id = data.empresa.id_empsede;
    setLoadPage(true);
    try {
      const response = await get_wallet_merchantes(id);
      setDataMerchantes({ data: response, load: false });
      setLoadPage(false);
    } catch (err) {
      setLoadPage(false);
      console.log(err.message);
    }
  };

  const calculateTotalComission = (merchantes) => {
    const totalComission = merchantes.reduce((sum, item) => sum + item.total_comission, 0);
    setCommision(totalComission);
  };

  const getUserData = async () => {
    const data = JSON.parse(localStorage.getItem("user_vm"));
    const com_empsede = data.empresa.comision_empsede;
    const currency = data.empresa.moneda_empsede;
    setMetaComission(com_empsede);
    setCurrency(currency);
  }

  const handleViewData = (data) => {
    setDataViewData(data);
    setOpenAdd(true);
  };

  const onSearch = async (data) => {
    setLoadPage(true);
    const Storage = JSON.parse(localStorage.getItem("user_vm"));
    const id = Storage.empresa.id_empsede;
    const res = await get_wallet_merchantes(id);
    setLoadPage(false);

    let newData = res;

     if (data.searchNombre){
       newData = res.filter(
         (el) =>
           el.usu_nombre.toLowerCase().includes(data.searchNombre.toLowerCase()) ||
           el.usu_nombre.toLowerCase().includes(data.searchNombre.toLowerCase())
       )
     }
     setDataMerchantes({ data: newData, load: false });
  }

  useEffect(() => {
    getMerchantes();
    getUserData();
  }, []);

  useEffect(() => {
    if (dataMerchantes.data.length > 0) {
      calculateTotalComission(dataMerchantes.data);
    }
  }, [dataMerchantes]);

  useEffect(() => {
    if (!checkToken()) {
      history.push("/");
      return null;
    }
  }, []);
  return (
    <Template title="Meta de Merchantes">
      <LoadingPage open={loadPage} setOpen={setLoadPage} />
      <FilterMerchantes onOpenAddModal={setOpenAdd} onSearch={onSearch} />
      <ContainerInput
        style={{
          textAlign: "center",
          borderRadius: "0px",
          padding: "0px",
          marginTop: "20px",
        }}
      >
        <TableMerchantes
          rows={dataMerchantes.data}
          setRow={(el) => setDataMerchantes({ ...dataMerchantes, data: el })}
          handleViewData={handleViewData}
        />
      </ContainerInput>
      <DialogWallet
        open={openAdd}
        setOpen={setOpenAdd}
        handleGetSucursales={getMerchantes}
        data={dataViewData}
        setData={setDataViewData}
      />
      <ContainerInput style={{ textAlign: "center", marginTop: "20px", fontSize:"16px", minWidth: "20rem", width:"auto", display:"flex", justifyContent:"space-between", padding:"20px 40px"}}>
        <div className="flex flex-col">
          <h1 className="font-bold text-blue-500 md:text-2xl sm:text-1xl"> $ {formatNumber(metaComission)}</h1>
          <p className="text-gray-500">Meta de Merchantes</p>
        </div>
        
        <Divider orientation="vertical" variant="middle" flexItem />

        <div className="flex flex-col">
          <h1 className="font-bold text-green-500 md:text-2xl sm:text-1xl"> $ {formatNumber(comission)}</h1>
          <p className="text-gray-500">Actualmente llevan</p>
        </div>

        <Divider orientation="vertical" variant="middle" flexItem />

        {metaComission > comission && (
          <div className="flex flex-col">
            <h1 className="font-bold text-green-500 md:text-2xl sm:text-1xl"> $ {formatNumber(metaComission - comission)}</h1>
            <p className="text-gray-500">Restantes para llegar a la meta</p>
          </div>
        )}

        { comission >= metaComission && (
          <div className="flex flex-col">
            <h1 className="font-bold text-green-500 md:text-2xl sm:text-1xl">{comission} {currency}</h1>
            <p className="text-gray-500">Se ha llegado a la meta!</p>
          </div>
        )}
      </ContainerInput>
        
    </Template>
  )
}

export default MetaMerchantes;
