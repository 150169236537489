/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";

import { get_categoria } from "../services/categoria.services";

const useCategoriaProducto = () => {
  const [dataCategoriaProducto, setDataCategoriaProducto] = React.useState({
    data: [],
    load: false,
    err: false,
  });

  React.useEffect(() => {
    async function obtainDataCategoriaProducto() {
      try {
        setDataCategoriaProducto({
          ...dataCategoriaProducto,
          load: true,
          data: [],
          err: false,
        });

        let response = await get_categoria();

        setDataCategoriaProducto({
          ...dataCategoriaProducto,
          load: false,
          data: response.map((el) => ({
            value: el.cat_id,
            label: el.cat_nombre,
          })),
          err: false,
        });
        
      } catch (err) {
        console.log(err);
        setDataCategoriaProducto({
          ...dataCategoriaProducto,
          load: false,
          data: [],
          err: true,
        });
      }
    }

    obtainDataCategoriaProducto();

    return null;
  }, []);

  return {
    dataCategoriaProducto,
  };
};

export default useCategoriaProducto;
