import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { Button, ClickAwayListener, Divider } from "@mui/material";

import { AccountCircle, Password, Settings } from "@mui/icons-material";

import { getUser, logOut } from "../services/auth.services";

const MenuInfo = () => {
  let history = useHistory();

  const [open, setOpen] = useState(false);

  let user = getUser();

  let initLetras = "";

  const letrasUserName = user.name ? user.name : null;

  if (letrasUserName.split(" ").length === 2) {
    initLetras =
      letrasUserName.split(" ")[0][0] + letrasUserName.split(" ")[1][0];
  } else if (letrasUserName.split(" ").length === 1) {
    initLetras = letrasUserName.split(" ")[0][0];
  } else {
    initLetras = "AD";
  }

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  //HANDLE

  const handleLogOut = () => {
    logOut();

    history.push("/");
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        {/* <div className={classes.sectionDesktop}> */}
        <div>
          <Button color="inherit" onClick={handleClick} className="!p-0 !m-0">
            <div className="hidden md:block">{user.name}</div>
            <div className="circleName w-9">{initLetras}</div>
          </Button>
        </div>
        {open ? (
          <div className="MenuInfo">
            <div className="_HeaderMenu">
              <div className="circle circleName">{initLetras}</div>
              <div className="_NameUser uppercase">{user.name}</div>
              <div className="_EmailUser">{user.username}</div>

              <Divider className="_Divider" />
              <div
                style={{
                  width: "100%",
                  margin: "auto",
                  textAlign: "center",
                }}
              >
                <Link to="/settings/my-profile">
                  <Button variant="text">
                    <AccountCircle style={{ marginRight: "5px" }} />
                    Mi perfil
                  </Button>
                </Link>
              </div>
              <div
                style={{
                  width: "100%",
                  margin: "auto",
                  textAlign: "center",
                }}
              >
                <Link to="/settings/change-password">
                  <Button variant="text">
                    <Password style={{ marginRight: "5px" }} />
                    Cambiar contraseña
                  </Button>
                </Link>
              </div>

              {/* SOLO ADMINISTRADORES */}
              {user.id_perfil === 4 || user.id_perfil === 16 ? (
                <>
                  <div
                    style={{
                      width: "100%",
                      margin: "auto",
                      textAlign: "center",
                    }}
                  >
                    <Link to="/settings/home">
                      <Button variant="text">
                        <Settings style={{ marginRight: "5px" }} />
                        Configuración
                      </Button>
                    </Link>
                  </div>
                </>
              ) : null}

              {user.id_perfil === 4 || user.id_perfil === 16 ? (
                <>
                  {/* <div
                    style={{
                      width: "100%",
                      margin: "auto",
                      textAlign: "center",
                    }}
                  >
                    <Link to="/sucursales">
                      <Button variant="text">
                        <Store style={{ marginRight: "5px" }} />
                        Sucursales
                      </Button>
                    </Link>
                  </div> */}
                </>
              ) : null}

              {user.id_perfil === 4 || user.id_perfil === 16 ? (
                <>
                  {/* <div
                    style={{
                      width: "100%",
                      margin: "auto",
                      textAlign: "center",
                    }}
                  >
                    <Link to="/meta-merchantes">
                      <Button variant="text">
                        <AttachMoney style={{ marginRight: "5px" }} />
                        Meta de Merchantes
                      </Button>
                    </Link>
                  </div> */}
                </>
              ) : null}

              <Divider className="_Divider" />

              <Button
                onClick={() => handleLogOut()}
                className="_BtnLogout"
                variant="contained"
              >
                Cerrar sesión
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    </ClickAwayListener>
  );
};

export default MenuInfo;
