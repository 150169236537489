import * as React from "react";
import { Link } from "react-router-dom";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import { LocalPrintshop } from "@mui/icons-material";
import { CircularProgress, IconButton, Typography } from "@mui/material";

import "moment/locale/es";
import Moment from "react-moment";

import NotFound from "../../../atomic/organisms/NotFound.organisms";
import { getUser } from "../../../services/auth.services";
import { nameCamarero } from "../../../functions/nameCamarero";

const id_admin = 4;
const { id_perfil } = getUser() || "";
const camarero = nameCamarero();

const columns = [
  {
    id: "codigo_pedido",
    label: "Código pedido",
    minWidth: 120,
    align: "left",
    price: false,
    joinName: false,
    date: false,
  },
  {
    id: "usu_nombre",
    label: camarero,
    minWidth: 30,
    align: "left",
    price: false,
    joinName: false,
    date: false,
    view: true,
  },
  {
    id: "codigo_mesa",
    label: "Código mesa",
    minWidth: 20,
    align: "left",
    price: false,
    joinName: false,
    date: false,
  },

  {
    id: "rowsProductos",
    label: "Cantidad productos",
    minWidth: 40,
    align: "left",
    price: false,
    joinName: false,
    date: false,
  },
  {
    id: "nombre_cliente",
    id2: "apepaterno_cliente",
    id3: "apematerno_cliente",
    label: "Cliente",
    minWidth: 160,
    align: "left",
    price: false,
    joinName: true,
    date: false,
  },
  {
    id: "fechareg_pedido",
    label: "Fecha",
    minWidth: 120,
    align: "left",
    price: false,
    joinName: false,
    date: true,
  },
  {
    id: "usu_nombre",
    label: nameCamarero(),
    minWidth: 60,
    align: "left",
    price: false,
    joinName: false,
    date: false,
    view: id_perfil === id_admin,
  },
];

/*
  TABLE 
*/

const TableBodyComponent = (props) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  let { handleActionPrint, dataPedido } = props;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const toUpperCaseFilter = (d) => {
    if (!d) {
      return d;
    }

    if (d === "") {
      return d;
    }

    return d.toUpperCase();
  };

  React.useEffect(() => {
    setPage(0);
  }, []);

  if (dataPedido.load) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress color="inherit" /> <br />
        <Typography component="h5" variant="h5" style={{ textAlign: "center" }}>
          Cargando...
        </Typography>
      </div>
    );
  }

  if (dataPedido.err) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <NotFound title="¡Ocurrió un problema!" />
      </div>
    );
  }

  if (dataPedido.data.length === 0 && !dataPedido.load) {
    return <NotFound texto="No se encontró registros." />;
  }

  return (
    <>
      <TableContainer sx={{ maxHeight: 640 }}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {columns.map((column, idx) => {
                if (column.id === "usu_nombre") {
                  if (column.view) {
                    <TableCell
                      key={idx}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>;
                  } else {
                    return null;
                  }
                }

                return (
                  <TableCell
                    key={idx}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                );
              })}
              <TableCell align="center">Imprimir</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {dataPedido.data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => {
                return (
                  <TableRow hover tabIndex={-1} key={idx}>
                    {columns.map((column, idx) => {
                      const value = row[column.id];

                      if (column.date) {
                        return (
                          <TableCell key={idx} align={column.align}>
                            <Moment
                              filter={toUpperCaseFilter}
                              format="DD/MMM/YYYY HH:mm"
                            >
                              {value}
                            </Moment>
                          </TableCell>
                        );
                      }

                      if (column.joinName) {
                        const value2 = row[column.id2];
                        const value3 = row[column.id3];

                        return (
                          <TableCell key={idx} align={column.align}>
                            {value} {value2} {value3}
                          </TableCell>
                        );
                      }

                      if (column.id === "codigo_mesa") {
                        return (
                          <TableCell key={idx} align={column.align}>
                            #{value}
                          </TableCell>
                        );
                      }

                      if (column.id === "codigo_pedido") {
                        return (
                          <TableCell key={idx} align={column.align}>
                            <Link
                              to={`orders/view/${row.codigo_pedido}`}
                              style={{
                                textDecoration: "revert",
                                color: "#0c0cb8",
                              }}
                            >
                              {value}
                            </Link>
                          </TableCell>
                        );
                      }

                      if (column.id === "usu_nombre") {
                        if (column.view) {
                          <TableCell key={idx} align={column.align}>
                            #{value}
                          </TableCell>;
                        } else {
                          return null;
                        }
                      }

                      return (
                        <TableCell key={idx} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                    <TableCell style={{ textAlign: "center" }}>
                      <IconButton
                        style={{ background: "green", color: "white" }}
                        size="large"
                        onClick={() => handleActionPrint(row)}
                      >
                        <LocalPrintshop />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage="Filas por página"
        rowsPerPageOptions={[10, 25, 100, { value: -1, label: "Todo" }]}
        component="div"
        count={dataPedido.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default TableBodyComponent;
