import { useEffect, useState } from "react";

import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";

import { Controller, useForm } from "react-hook-form";
import InputDefault from "../../../../atomic/atoms/input.atoms";
import {
  add_empresa,
  update_empresa,
} from "../../../../services/empresa.services";
import toast from "react-hot-toast";

const initalValues = {
  idEmp: null,
  nombreEmp: "",
  typeEmp: "",
  colorPrimary: "",
  colorSecondary: "",
};

const ModalCompany = (props) => {
  const { open, setOpen, selected, setSelected, callBack } = props;

  const [load, setLoad] = useState(false);

  const { control, handleSubmit, watch, reset, setValue } = useForm({
    defaultValues: initalValues,
  });

  const onAction = async (data) => {
    setLoad(true);

    try {

      if (data.idEmp) {
        await update_empresa(data, selected.id_emp);
        await callBack();

        toast.success("Empresa modificada correctamente");
        setLoad(false);
        setOpen(false);
        return;
      }

      await add_empresa(data);
      await callBack();
      toast.success("Empresa creada correctamente");

      setOpen(false);

      setLoad(false);
    } catch (err) {
      setLoad(false);
      console.log(err);
    }
  };

  useEffect(() => {
    if (open) {
      if (selected?.id_emp && selected?.id_emp > 0) {
        reset({
          ...initalValues,
          idEmp: selected.id_emp,
          nombreEmp: selected.nom_emp,
          typeEmp: selected.type_emp,
          colorPrimary: selected.color_primary,
          colorSecondary: selected.color_secondary,
        });
      } else {
        reset({
          ...initalValues,
        });
      }
    } else {
      reset(initalValues);
      setSelected(null);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth={"sm"}
      aria-describedby="dialog-company"
    >
      <form onSubmit={handleSubmit(onAction)} autoComplete="off">
        <DialogTitle>
          {watch("idEmp") ? "Editar" : "Agregar"} empresa
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={`${load && "animate-pulse"} `}>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Controller
                name="nombreEmp"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    required
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"Nombre"}
                    type={"text"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controller
                name="typeEmp"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    required
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"Tipo"}
                    type={"select"}
                    options={[
                      { value: "normal", label: "Normal" },
                      { value: "colegio", label: "Colegio" },
                    ]}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="flex gap-2 w-full">
                <input
                  className="w-full h-[35px] border-0 rounded-lg p-0 m-0"
                  type="color"
                  value={watch("colorPrimary")}
                  onChange={(e) => setValue("colorPrimary", e.target.value)}
                />
                <Controller
                  name="colorPrimary"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputDefault
                      required
                      loading={false}
                      value={value}
                      onChange={onChange}
                      title={"Color primario"}
                      disabled
                      type={"text"}
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="flex gap-2 w-full">
                <input
                  className="w-full h-[35px] border-0 rounded-lg p-0 m-0"
                  type="color"
                  value={watch("colorSecondary")}
                  onChange={(e) => setValue("colorSecondary", e.target.value)}
                />
                <Controller
                  name="colorSecondary"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputDefault
                      required
                      loading={false}
                      value={value}
                      onChange={onChange}
                      title={"Color secundario"}
                      disabled
                      type={"text"}
                    />
                  )}
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disable={load.toString()}
            color="inherit"
            variant="text"
            onClick={() => {
              setOpen(false);
            }}
          >
            Salir
          </Button>
          <Button
            color="secondary"
            variant="contained"
            type="submit"
            disable={load.toString()}
          >
            Aceptar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalCompany;
