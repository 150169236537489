import { actionTypes } from "../actions/index";

const initialState = {
  loading: false,
  error: false,
  openModalView: false,
  clientes: [],
  clientID: "",
};

const reducerObject = (state, payload) => ({
  [actionTypes.updateCliente]: {
    ...state,
    clientes: payload,
  },
  [actionTypes.updateLoading]: {
    ...state,
    loading: payload,
  },
  [actionTypes.openModalView]: {
    ...state,
    openModalView: payload,
  },
  [actionTypes.clientSelected]: {
    ...state,
    clientID: payload,
  },
});

export const reducer_historialCLiente = (state = initialState, action) => {
  if (reducerObject(state)[action.type]) {
    return reducerObject(state, action.payload)[action.type];
  } else {
    return state;
  }
};
