import * as React from "react";

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

import { Delete, Edit, Settings } from "@mui/icons-material";
import NotFound from "../../atomic/organisms/NotFound.organisms";

const formatNumber = (n) => {
  return n === "" || n === 0 ? n : Number(n).toLocaleString();
};

const TableSucursales = ({
  rows,
  setRow,
  handleViewData,
  handleViewDataEliminar,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (rows.length === 0) {
    return <NotFound texto="No se encontró registros." />;
  }

  return (
    <>
      <TableContainer sx={{ maxHeight: 550 }}>
        <Table stickyHeader aria-label="sticky table" size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell align={"left"} style={{ minWidth: 150 }}>
                Nombre Empresa
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 150 }}>
                Nombre Sede
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 190 }}>
                Telefono
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 100 }}>
                Whatsapp
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 80 }}>
                Ubicación
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 100 }}>
                Región
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 100 }}>
                Comuna
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 100 }}>
                Alias
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 100 }}>
                Meta de Comision
              </TableCell>
              <TableCell
                colSpan={2}
                align={"center"}
                style={{ minWidth: 100, fontSize: 21, color: "#5c5c5c" }}
              >
                <Settings />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                    <TableCell align={"left"}>
                      {row?.nom_emp}
                    </TableCell>
                    <TableCell align={"left"} className="uppercase">
                      {row?.nom_empsede}
                    </TableCell>
                    <TableCell align={"left"}>{row.telf_empsede}</TableCell>
                    <TableCell align={"left"}>{row.wsp_empsede}</TableCell>
                    <TableCell align={"left"}>
                      {row.ubication_empsede}
                    </TableCell>
                    <TableCell align={"left"}>{row.region_empsede}</TableCell>
                    <TableCell align={"left"}>{row.comuna_empsede}</TableCell>
                    <TableCell align={"left"}>{row.alias_empsede}</TableCell>
                    <TableCell align={"left"}>
                      {" "}
                      $ {formatNumber(row.comision_empsede)}
                    </TableCell>
                    <TableCell align={"center"}>
                      <IconButton
                        color="warning"
                        onClick={() => handleViewData(row)}
                      >
                        <Edit />
                      </IconButton>
                    </TableCell>
                    <TableCell align={"center"}>
                      <IconButton
                        color="error"
                        onClick={() => handleViewDataEliminar(row)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage="Filas por página"
        rowsPerPageOptions={[10, 25, 100, { value: -1, label: "Todo" }]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default TableSucursales;
