import * as React from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
} from "@mui/material";

import { actionTypes } from "../../../redux/producto/actions";

import { useSelector, useDispatch } from "react-redux";

import { Settings, Edit, Delete } from "@mui/icons-material";
import NotFound from "../../../atomic/organisms/NotFound.organisms";

// import Moment from "react-moment";
// import "moment/locale/es";

// const toUpperCaseFilter = (d) => {
//   if (!d) {
//     return d;
//   }

//   if (d === "") {
//     return d;
//   }

//   return d.toUpperCase();
// };

function formatNumber(n) {
  n = String(n).replace(/\D/g, "");

  return n === "" ? n : Number(n).toLocaleString();
}

const TableMarcas = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const dispatch = useDispatch();

  const { dataProducto } = useSelector((state) => state.reducer_producto);

  const rows = dataProducto.data;
  const loading = dataProducto.load;

  const onOpenEdit = (data) =>
    dispatch({
      type: actionTypes.selectedDataProductoEdit,
      payload: data,
    });

  const onOpenDelete = (data) =>
    dispatch({
      type: actionTypes.selectedDataProductoDelete,
      payload: data,
    });

  /* HANDLES */

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEdit = (data) => {
    onOpenEdit(data);
  };

  const handleDelete = async (data) => {
    onOpenDelete(data);
  };

  if (loading) {
    return (
      <div>
        <h3>Cargando...</h3>
      </div>
    );
  }

  if (rows.length === 0) {
    return <NotFound texto="No se encontró registros." />;
  }

  return (
    <>
      <TableContainer sx={{ maxHeight: 550 }}>
        <Table stickyHeader aria-label="sticky table" size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell align={"left"} style={{ minWidth: 10 }}>
                Código
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 280 }}>
                Nombre
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 100 }}>
                Precio
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 150 }}>
                Marca
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 150 }}>
                Categoría
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 100 }}>
                Unidad medida
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 100 }}>
                Valor medida
              </TableCell>
              <TableCell
                colSpan={2}
                align={"center"}
                style={{ width: 90, fontSize: 21, color: "#5c5c5c" }}
              >
                <Settings />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                  <TableCell align={"left"}>{row.prod_id}</TableCell>
                  <TableCell align={"left"}>{row.prod_nombre}</TableCell>
                  <TableCell align={"left"}>
                    {formatNumber(row.prod_precio)}
                  </TableCell>
                  <TableCell align={"left"}>{row.marc_nombre}</TableCell>
                  <TableCell align={"left"}>{row.cat_nombre}</TableCell>
                  <TableCell align={"left"}>{row.name_medida}</TableCell>
                  <TableCell align={"left"}>{row.value_medida}</TableCell>

                  <TableCell align={"center"} width={35}>
                    <IconButton color="warning" onClick={() => handleEdit(row)}>
                      <Edit />
                    </IconButton>
                  </TableCell>
                  <TableCell align={"center"} width={35}>
                    <IconButton color="error" onClick={() => handleDelete(row)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage="Filas por página"
        rowsPerPageOptions={[10, 25, 100, { value: -1, label: "Todo" }]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default TableMarcas;
