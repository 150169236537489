import * as React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
} from "@mui/material";

import Input from "../../../../components/Forms/Input";

import { Controller, useForm } from "react-hook-form";
import LoadingPage from "../../../../components/LoadingPage";
import {
  update_discount,
  update_discount_product,
} from "../../../../services/pedidos.services";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogCoverApply = (props) => {
  let {
    open,
    setOpen,
    infoPedido,
    idProductSelected,
    dataProducts,
    setInfoPedido,
    statusCover,
    onChangeStatusCoverProduct,
  } = props;

  let { data } = infoPedido;

  const initialValues = {
    numDiscount: 5000,
    commentDiscount: "",
    discountType: "COVER",
    amountCover: 0,
    productDiscount: 0,
    formBenefit: "normal",
    id_ticketBenefit: null,
  };

  const product = dataProducts?.data.find(
    (el) => el.id_plapedido === idProductSelected
  );

  const { control, setValue, handleSubmit, watch } = useForm({
    defaultValues: initialValues,
  });

  const [errDialog, setErrDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmitDialog = async (data) => {
    handleClose();

    setLoading(true);

    let inputOrderCover = null;
    let inputOrderCoverProduct = null;
    let typeProduct = null;
    let numDiscount = null;

    if (statusCover === "apply") {
      typeProduct = "COVER";
      numDiscount = parseFloat(data.numDiscount);

      inputOrderCover = {
        ...data,
        codigo_pedido: infoPedido.data.codigo_pedido,
        discountType: typeProduct,
        numDiscount: 0,
      };

      inputOrderCoverProduct = {
        idPlapedido: idProductSelected,
        numDiscount,
        typeDiscount: typeProduct,
      };
    } else {
      typeProduct = null;
      numDiscount = null;

      inputOrderCover = {
        ...data,
        codigo_pedido: infoPedido.data.codigo_pedido,
        discountType: null,
        numDiscount,
      };

      inputOrderCoverProduct = {
        idPlapedido: idProductSelected,
        numDiscount,
        typeDiscount: typeProduct,
      };
    }

    // cover la orden
    const responseOrder = await update_discount(inputOrderCover);

    // cover el producto
    await update_discount_product(inputOrderCoverProduct);

    // update local state
    onChangeStatusCoverProduct({ idProductSelected, typeProduct, numDiscount });

    setLoading(false);

    if (responseOrder.affectedRows === 1) {
      setInfoPedido({
        ...infoPedido.data,
        // descuento_pedido: data.numDiscount,
        descuento_pedido: 0,
        observaciondescuento_pedido: data.commentDiscount,
        tipodescuento_pedido: data.discountType,
        // cover_pedido: data.amountCover,
        cover_pedido: 0,
        form_benefit: data.formBenefit,
        id_ticketBenefit: data.id_ticketBenefit,
      });
    }
  };

  // const handleCalculateCover = () => {
  //   setValue("numDiscount", priceCover * watch("amountCover"));
  // };

  // React.useEffect(() => {
  //   handleCalculateCover();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [watch("amountCover")]);

  const handleCalculateProductDiscount = () => {
    const discount = precioTotal - watch("numDiscount");
    setValue("productDiscount", discount < 0 ? 0 : discount);
  };

  const precioTotal = product?.precio_plato * product?.amount_plapedido;

  React.useEffect(() => {
    handleCalculateProductDiscount();
  }, [watch("numDiscount")]);

  // handleCalculateCover();

  React.useEffect(() => {
    setErrDialog(false);

    if (open) {

      setValue("discountType", "COVER");
      setValue(
        "numDiscount",
        data.descuento_pedido || initialValues.numDiscount
      );
      setValue("commentDiscount", data.observaciondescuento_pedido);
      setValue("amountCover", data.cover_pedido || 0);
      setValue("formBenefit", data.form_benefit || "normal");
      setValue("id_ticketBenefit", data.id_ticketbenefit || null);
      setValue("productDiscount", data.productDiscount || 0);

      handleCalculateProductDiscount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  if (loading) {
    return <LoadingPage open={true} />;
  }

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      maxWidth={"sm"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form onSubmit={handleSubmit(handleSubmitDialog)} autoComplete="off">
        <DialogTitle id="alert-dialog-title">
          {statusCover === "apply" ? "Aplicar" : "Remover"} COVER a{" "}
          {product?.nombre_plato || "producto"}
        </DialogTitle>
        <DialogContent>
          {statusCover === "apply" ? (
            <Grid container spacing={1} style={{ marginTop: 10 }}>
              {/* <Grid item xs={12}>
              <Controller
                name="amountCover"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Input
                    required
                    value={value}
                    onChange={onChange}
                    options={optionCover}
                    type="select"
                    title="Cantidad de covers"
                  />
                )}
              />
            </Grid> */}

              <Grid item xs={6}>
                <Input
                  value={precioTotal || 0}
                  type="text"
                  title="Precio actual"
                  disabled
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name="numDiscount"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      required
                      value={value}
                      onChange={onChange}
                      type="number"
                      title="Descuento"
                      inputProps={{
                        min: 0,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                {/* <Input
                required
                value={product?.precio_plato - watch("numDiscount")}
                type="text"
                title="Precio con descuento"
              /> */}

                <Controller
                  name="productDiscount"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      required
                      disabled
                      value={value}
                      type="text"
                      title="Precio con descuento"
                      inputProps={{
                        min: 0,
                      }}
                    />
                  )}
                />
              </Grid>
              {/* <Grid item xs={12}>
              <Controller
                name="commentDiscount"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Input
                    value={value}
                    onChange={onChange}
                    type="textarea"
                    title="Comentario"
                    minRows={3}
                  />
                )}
              />
            </Grid> */}
            </Grid>
          ) : (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <span style={{ fontSize: 12 }}>
                  ¿Estás seguro de remover el cover?
                </span>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="text" color="inherit">
            Cerrar
          </Button>
          <Button
            type="submit"
            disabled={loading}
            variant="contained"
            color="success"
          >
            Aceptar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogCoverApply;
