import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import * as React from "react";

import {
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import { Search } from "@mui/icons-material";

import { Controller, useForm } from "react-hook-form";
import Input from "../../../../../../components/Forms/Input";

import { AddCircle, Delete } from "@mui/icons-material";
import useCategoriaProducto from "../../../../../../hooks/useCategoriaProducto";
import useMarca from "../../../../../../hooks/useMarca";
import {
  get_producto,
  search_producto,
} from "../../../../../../services/productos.services";

import { useDispatch, useSelector } from "react-redux";
import { actionTypesPlato } from "../../../../../../redux/plato/actions";

import NotFound from "../../../../../../atomic/organisms/NotFound.organisms";
import { formatMoney } from "../../../../../../functions/formatMoney";
import { get_medidas } from "../../../../../../services/menu.services";

const columns = [
  {
    id: "prod_nombre",
    label: "Nombre producto",
    minWidth: 30,
    align: "left",
    price: false,
  },
  {
    id: "prod_descripcion",
    label: "Descripción",
    minWidth: 60,
    align: "left",
    price: false,
  },
  {
    id: "marc_nombre",
    label: "Marca",
    minWidth: 80,
    align: "left",
    price: false,
  },
  {
    id: "cat_nombre",
    label: "Categoría",
    minWidth: 80,
    align: "left",
    price: false,
  },
  {
    id: "prod_precio",
    label: "Precio",
    minWidth: 60,
    align: "right",
    price: true,
  },
];

/*
  TABLE LIST PRODUCT
*/

const TableHeadComponent = () => {
  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox"> */}
        {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          /> */}
        {/* </TableCell> */}
        {columns.map((column, idx) => (
          <TableCell
            key={idx}
            align={column.align}
            style={{ minWidth: column.minWidth }}
          >
            {column.label}
          </TableCell>
        ))}
        <TableCell align="center"></TableCell>
      </TableRow>
    </TableHead>
  );
};

const TableBodyComponent = (data) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  let { dataInsumo, dataSelected, handleSelectedRow } = data;

  const handleAddTmp = (param) => {
    handleSelectedRow({ ...param, prod_medida: 0, prod_medida_value: 0 });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    setPage(0);
  }, []);

  if (dataInsumo.load) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress color="inherit" /> <br />
        <Typography component="h5" variant="h5" style={{ textAlign: "center" }}>
          Cargando...
        </Typography>
      </div>
    );
  }

  if (dataInsumo.err) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <NotFound title="¡Ocurrió un problema!" />
      </div>
    );
  }

  if (dataInsumo.length === 0) {
    return <NotFound texto="No se encontró registros." />;
  }

  const isSelected = (name) => {
    return dataSelected.find((item) => item.prod_id === name);
  };

  return (
    <>
      <TableContainer sx={{ maxHeight: 640 }}>
        <Table stickyHeader aria-label="sticky table" size="medium">
          <TableHeadComponent />

          <TableBody>
            {dataInsumo.data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => {
                const isItemSelected = isSelected(row.prod_id);
                // const labelId = `table-td-${idx}`;

                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                    {columns.map((column, idx) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={idx} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : column.price
                            ? formatMoney(value)
                            : value}
                        </TableCell>
                      );
                    })}
                    <TableCell padding="checkbox">
                      <IconButton
                        disabled={isItemSelected ? true : false}
                        color="success"
                        size="large"
                        onClick={() => handleAddTmp(row)}
                      >
                        <AddCircle />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage="Filas por página"
        rowsPerPageOptions={[10, 25, 100, { value: -1, label: "Todo" }]}
        component="div"
        count={dataInsumo.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

/*
  TMP TABLE
*/

const TmpAddRow = (props) => {
  let { data, setData, handleRemoveRow, dataMedidas } = props;

  const handleChange = (val) => {
    const { name, value } = val.target;

    let newValue = 0;

    if (value > 0) {
      if (name === "prod_medida") {
        newValue = parseInt(value);
      } else {
        newValue = parseFloat(value);
      }
    } else {
      newValue = "";
    }

    data[name] = newValue;

    setData(data);
  };

  return (
    <TableRow hover role="checkbox" tabIndex={-1}>
      <TableCell align={"left"}>{data.prod_nombre}</TableCell>
      <TableCell align={"left"}>{data.prod_descripcion}</TableCell>
      <TableCell align={"left"}>{formatMoney(data.prod_precio)}</TableCell>
      <TableCell align={"left"}>{data.marc_nombre}</TableCell>
      <TableCell align={"left"}>{data.cat_nombre}</TableCell>
      <TableCell align={"left"}>
        <TextField
          fullWidth
          select
          variant="outlined"
          name="prod_medida"
          value={data.prod_medida}
          onChange={handleChange}
          className={`InputDefault`}
          SelectProps={{
            native: true,
          }}
        >
          <option value="">[Medida]</option>
          {dataMedidas.map((option, idx) => (
            <option key={idx} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
      </TableCell>
      <TableCell align={"left"}>
        <TextField
          fullWidth
          name="prod_medida_value"
          variant="outlined"
          className={`InputDefault`}
          type={"number"}
          value={data.prod_medida_value}
          onChange={handleChange}
          step="any"
        />
      </TableCell>
      <TableCell align={"left"}>
        <IconButton color="error" onClick={() => handleRemoveRow(data)}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

const TableTmpAdd = (props) => {
  let { dataMedidas, data, setData, numSelected, handleRemoveRow } = props;

  if (numSelected === 0) {
    return false;
  }

  let rows = data;

  const handleSetData = (newData) => {
    let filterIndex = data.findIndex((el) => el.prod_id === newData.prod_id);
    data[filterIndex] = newData;
    setData(data);
  };

  return (
    <>
      <Paper variant="outlined" style={{ padding: 10, background: "#f7f7f7" }}>
        {numSelected > 0 && (
          <Typography color="inherit" variant="subtitle1" component="div">
            Insumos seleccionados: {numSelected}
          </Typography>
        )}
      </Paper>
      <Paper
        variant="outlined"
        style={{ padding: 10, background: "#f7f7f7", marginTop: 10 }}
      >
        <Table stickyHeader aria-label="sticky table" size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell align={"left"} style={{ minWidth: 150 }}>
                Nombre de insumo
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 150 }}>
                Descripción
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 150 }}>
                Precio
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 150 }}>
                Marca
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 150 }}>
                Categoría
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 150 }}>
                Medida
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 150 }}>
                Cantidad
              </TableCell>
              <TableCell align={"left"}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, idx) => (
              <TmpAddRow
                data={row}
                setData={handleSetData}
                dataMedidas={dataMedidas}
                handleRemoveRow={handleRemoveRow}
                key={idx}
              />
            ))}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

const TableInsumos = () => {
  const dispatch = useDispatch();

  const { paramSelected_product } = useSelector((state) => state.reducer_plato);

  const { dataCategoriaProducto } = useCategoriaProducto();
  const { dataMarca } = useMarca();

  const [dataInsumo, setDataInsumos] = React.useState({
    data: [],
    load: false,
    err: false,
  });

  const [dataMedidas, setDataMedida] = React.useState({
    data: [],
    load: false,
    err: false,
  });

  const { control, handleSubmit } = useForm({
    defaultValues: {
      prod_nombre: "",
      marc_id: "",
      cat_id: "",
    },
  });

  const onSetParamSelected = (data) =>
    dispatch({
      type: actionTypesPlato.setParamSelected_product,
      payload: data,
    });

  const handleSearchProduct = async (data) => {
    try {
      setDataInsumos({ ...dataInsumo, load: true, data: [], err: false });
      let response = await search_producto(data);

      setDataInsumos({
        ...dataInsumo,
        load: false,
        data: response,
        err: false,
      });
    } catch (err) {
      setDataInsumos({ ...dataInsumo, load: false, data: [], err: true });
      console.log(err);
    }
  };

  React.useEffect(() => {
    async function obtainMedidas() {
      try {
        setDataMedida({ load: true, data: [], err: false });
        let response = await get_medidas();

        setDataMedida({
          load: false,
          data: response.map((el) => ({
            label: el.name_medida,
            value: el.id_medida,
          })),
          err: false,
        });
      } catch (err) {
        setDataMedida({ load: false, data: [], err: true });
        console.log(err);
      }
    }

    obtainMedidas();
  }, []);

  const handleSelectedRow = (data) => {
    onSetParamSelected([...paramSelected_product, { ...data }]);
  };

  const handleSetSelected = (data) => {
    onSetParamSelected(data);
  };

  const handleRemoveRow = (data) => {
    const newData = paramSelected_product.filter(
      (item) => item.prod_id !== data.prod_id
    );
    onSetParamSelected(newData);
  };

  React.useEffect(() => {
    async function obtainDataInsumo() {
      try {
        setDataInsumos({ ...dataInsumo, load: true, data: [], err: false });
        let response = await get_producto();

        setDataInsumos({
          ...dataInsumo,
          load: false,
          data: response,
          err: false,
        });
      } catch (err) {
        console.log(err);
        setDataInsumos({ ...dataInsumo, load: false, data: [], err: true });
      }
    }

    obtainDataInsumo();

    return null;
  }, []);

  return (
    <>
      <Paper variant="outlined" style={{ padding: 10 }}>
        <form onSubmit={handleSubmit(handleSearchProduct)} autoComplete="off">
          <Grid container spacing={1}>
            <Grid item xs={7}>
              <Controller
                name="prod_nombre"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Input
                    fullWidth
                    value={value}
                    onChange={onChange}
                    type="text"
                    title="Nombre producto"
                  />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Controller
                name="cat_id"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    select
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                    className={`InputDefault`}
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option value="">[Categoría]</option>
                    {dataCategoriaProducto.data.map((option, idx) => (
                      <option key={idx} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            {/* <Grid item xs={2}>
              <Controller
                name="marc_id"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    select
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                    className={`InputDefault`}
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option value="">[Marca]</option>
                    {dataMarca.data.map((option, idx) => (
                      <option key={idx} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                )}
              />
            </Grid> */}
            <Grid item xs={1} style={{ textAlign: "center" }}>
              <IconButton
                type="submit"
                size="large"
                color="primary"
                style={{ background: "#eee" }}
              >
                <Search />
              </IconButton>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <TableBodyComponent
        dataInsumo={dataInsumo}
        dataSelected={paramSelected_product}
        handleSelectedRow={handleSelectedRow}
      />

      <TableTmpAdd
        numSelected={paramSelected_product.length}
        data={paramSelected_product}
        setData={handleSetSelected}
        handleRemoveRow={handleRemoveRow}
        dataMedidas={dataMedidas.data}
      />
    </>
  );
};

export default TableInsumos;
