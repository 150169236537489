import dayjs from "dayjs";
import React, { useEffect } from "react";

import CustomTable from "../../atomic/organisms/Table.organisms";
import ContainerInput from "../../components/ContainerInput";
import Template from "../../components/Template";

import { get_quotation } from "../../services/quotation.services";

import ModalQuotationDelete from "./modal-delete.quotation";
import ModalQuotation from "./modal.quotation";

import {
  ArrowDownward,
  ArrowUpward,
  Check,
  Folder,
  FileCopy,
} from "@mui/icons-material";

import { Button } from "@mui/material";
import { interestCalc, interestNivel, statusNivel } from "./function.quotation";
import ModalQuotationFinish from "./modal-finish.quotation";
import FiltersQuotation from "./ui/FilterQuotation.ui";

import { config } from "../../config";

dayjs.locale("es");

const Quotation = () => {
  const [data, setData] = React.useState([]);
  const [load, setLoad] = React.useState(false);
  const [error, setError] = React.useState(false);

  const [openModal, setOpenModal] = React.useState(false);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [openModalApprove, setOpenModalApprove] = React.useState(false);

  const [selected, setSelected] = React.useState(null);

  const onSearchData = async (value) => {
    const response = await fetchData();

    let filterData = response;

    if (value.searchName) {
      filterData = filterData.filter((el) => {
        if (el.name_quotation && el.lastname_quotation) {
          el.name_quotation = `${el.name_quotation} ${el.lastname_quotation}`;
        }

        return (
          el.cod_quotation.includes(value.searchName) ||
          el.name_quotation
            .toLowerCase()
            .includes(value.searchName.toLowerCase()) ||
          el.lastname_quotation
            .toLowerCase()
            .includes(value.searchName.toLowerCase()) ||
          el.email_quotation
            .toLowerCase()
            .includes(value.searchName.toLowerCase()) ||
          el.phone_quotation.includes(value.searchName)
        );
      });
    }

    if (value.dateRange.length > 0) {
      filterData = filterData.filter((el) => {
        const date = dayjs(el.date_quotation).utc();
        return (
          date.isAfter(dayjs(value.dateRange[0]).utc()) &&
          date.isBefore(dayjs(value.dateRange[1]).utc())
        );
      });
    }

    if (value.idEvent) {
      filterData = filterData.filter(
        (el) => el.id_event === Number(value.idEvent)
      );
    }

    if (value.idTypeEvent) {
      filterData = filterData.filter(
        (el) => el.id_typeevent === Number(value.idTypeEvent)
      );
    }

    if (value.userId) {
      filterData = filterData.filter(
        (el) => el.usu_id === Number(value.userId)
      );
    }

    setData(filterData);
  };

  const fetchData = async () => {
    try {
      setLoad(true);
      const response = await get_quotation();

      setData(response);

      return response;
    } catch (error) {
      setError(true);
    } finally {
      setLoad(false);
    }
  };

  const handleApprove = async (el) => {
    let newData = [...data].find((item) => item.id_quotation === el);

    setSelected(newData);
    setOpenModalApprove(true);
  };

  useEffect(() => {
    // Fetch data
    fetchData();
  }, []);
  return (
    <Template title="Cotización">
      {error && (
        <ContainerInput className="p-0 mb-5">
          ¡Ocurrió un problema!
        </ContainerInput>
      )}

      <FiltersQuotation onOpenAddModal={setOpenModal} onSearch={onSearchData} />

      <ContainerInput className="p-0">
        <CustomTable
          columns={[
            {
              id: "cod_quotation",
              label: "Código",
              minWidth: 100,
              align: "left",
              redirect: (el) => `/quotation/${el}`,
            },
            {
              id: "date_quotation",
              label: "Fec. de evento",
              minWidth: 120,
              align: "left",
              format: (el) => {
                return dayjs(el).utc().format("DD/MM/YYYY");
              },
            },
            {
              id: "name_quotation",
              label: "Nombres",
              minWidth: 150,
              align: "left",
            },
            {
              id: "lastname_quotation",
              label: "Apellidos",
              minWidth: 150,
              align: "left",
            },

            {
              id: "phone_quotation",
              label: "Teléfono",
              minWidth: 150,
              align: "left",
              format: (el) => {
                return (
                  <div className="text-blue-500">{el?.replace(/\s+/g, "")}</div>
                );
              },
            },
            {
              id: "tracking",
              label: "Último seguimiento",
              minWidth: 180,
              align: "left",
              format: (row) => {
                const length = row?.length;
                const lastTracking = row?.slice(length - 1, length);
                const nameLastTracking = lastTracking[0]?.name_actionphase;

                if (!nameLastTracking) {
                  return (
                    <div className="flex items-center">Sin seguimiento</div>
                  );
                }

                return (
                  <div className="flex items-center">{nameLastTracking}</div>
                );
              },
            },
            {
              id: "tracking",
              label: "Nivel de interés",
              minWidth: 150,
              align: "left",
              format: (row) => (
                <div className="flex items-center gap-2">
                  <p className="text-sm text-blue-900">
                    {interestNivel(interestCalc(row).number)}
                  </p>
                  <p className="text-xs text-gray-500">
                    {row.length > 0 ? (
                      interestCalc(row)?.behavior === "up" ? (
                        <ArrowUpward />
                      ) : (
                        <ArrowDownward />
                      )
                    ) : null}
                  </p>
                </div>
              ),
            },
            {
              id: "status_quotation",
              label: "Estado",
              minWidth: 100,
              align: "left",
              format: (row) => (
                <div className="flex items-center">{statusNivel(row)}</div>
              ),
            },
            {
              id: "usu_nombre",
              label: "Usuario",
              minWidth: 150,
              align: "left",
              format: (row) => (
                <div className="flex items-center uppercase">{row}</div>
              ),
            },
            {
              id: "name_event",
              label: "Evento",
              minWidth: 80,
              align: "left",
            },
            {
              id: "name_typeevent",
              label: "Tipo",
              minWidth: 140,
              align: "left",
            },
            {
              id: "namecompany_quotation",
              label: "Colegio/empresa",
              minWidth: 180,
              align: "left",
            },
            {
              id: "name_typecontact",
              label: "Contacto",
              minWidth: 180,
              align: "left",
            },

            {
              id: "email_quotation",
              label: "E-mail",
              minWidth: 150,
              align: "left",
            },

            {
              id: "regioncontact_quotation",
              label: "Región",
              minWidth: 250,
              align: "left",
            },
            {
              id: "comunacontact_quotation",
              label: "Comuna",
              minWidth: 120,
              align: "left",
            },
            {
              id: "amountpeople_quotation",
              label: "Número personas",
              minWidth: 160,
              align: "left",
            },
            {
              id: "urlfiledrive_quotation",
              label: "Carpeta",
              minWidth: 50,
              align: "center",
              format: (item) => {
                if (!item)
                  return (
                    <div className={`text-gray-400 cursor-auto`}>
                      <Folder />
                    </div>
                  );

                return (
                  <a
                    href={item}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`text-blue-500`}
                  >
                    <Folder />
                  </a>
                );
              },
            },
            {
              id: "filecontract_quotation",
              label: "Contrato",
              minWidth: 50,
              align: "center",
              format: (item) => {
                if (!item)
                  return (
                    <div className={`text-gray-400 cursor-auto`}>
                      <FileCopy />
                    </div>
                  );

                const url = `${config.doUrlSpace}/quotation/${item}`;

                return (
                  <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`text-blue-500`}
                  >
                    <FileCopy />
                  </a>
                );
              },
            },
            {
              id: "id_quotation",
              label: "Finalizar",
              minWidth: 50,
              align: "center",
              format: (el) => {
                const newData = [...data].find(
                  (item) => item.id_quotation === el
                );

                if (newData?.status_quotation !== "aprobado") {
                  return null;
                }

                return (
                  <Button
                    disabled={newData?.planning}
                    variant="contained"
                    color={`success`}
                    size="small"
                    title="Finalizar"
                    onClick={() => handleApprove(el)}
                  >
                    <Check />
                  </Button>
                );
              },
            },
          ]}
          rows={data}
          load={load}
          error={false}
          disableButtons={false}
          actionEdit={(element) => {
            setSelected(element);
            setOpenModal(true);
          }}
          actionDelete={(element) => {
            setSelected(element);
            setOpenModalDelete(true);
          }}
          pagination={true}
        />
      </ContainerInput>

      <ModalQuotation
        open={openModal}
        selected={selected}
        setSelected={setSelected}
        setOpen={setOpenModal}
        callBack={() => {
          fetchData();
        }}
      />

      <ModalQuotationDelete
        open={openModalDelete}
        selected={selected}
        setSelected={setSelected}
        setOpen={setOpenModalDelete}
        callBack={() => {
          fetchData();
        }}
      />

      {openModalApprove && (
        <ModalQuotationFinish
          open={openModalApprove}
          selectedId={selected?.id_quotation}
          setSelected={setSelected}
          setOpen={setOpenModalApprove}
          callBack={() => {
            return fetchData();
          }}
        />
      )}
    </Template>
  );
};

export default Quotation;
