import axios from "axios";
import { config } from "../config";
import { axiosHeader } from "../functions/axiosHeader";

const { baseurl } = config;

export const get_area_pedidos = () => {
  const url = `${baseurl}/api/area_pedidos/get/`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};
