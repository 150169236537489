import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";

import "moment/locale/es";

import { Close, Search } from "@mui/icons-material";
import {
  Backdrop,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import Moment from "react-moment";
import InputDefault from "../../atomic/atoms/input.atoms";
import NotFound from "../../atomic/organisms/NotFound.organisms";
import LoadingPage from "../../components/LoadingPage";
import { fetch_visitors } from "../../services/geolocation.services";

const toUpperCaseFilter = (d) => {
  if (!d) {
    return d;
  }

  if (d === "") {
    return d;
  }

  return d.toUpperCase();
};

const DialogVisitor = ({ open, setOpen }) => {
  const [visitors, setVisitors] = React.useState([]);
  const [filterVisitors, setFilterVisitors] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [isLoad, setIsLoad] = React.useState(false);
  const [options, setOptions] = React.useState({
    country: [],
    region: [],
    city: [],
  });

  const initialValues = {
    region: "",
    country: "",
    city: "",
    date1: "",
    date2: "",
  };

  const { control, handleSubmit } = useForm({
    defaultValues: initialValues,
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const searchFilter = (params) => {
    let newData = visitors;

    if (params.region !== "") {
      newData = newData.filter((item) => {
        return item.region_visitor === params.region;
      });
    }

    if (params.city !== "") {
      newData = newData.filter((item) => {
        return item.city_visitor === params.city;
      });
    }

    setFilterVisitors(newData);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchVisitors = async () => {
    try {
      setIsLoad(true);
      const response = await fetch_visitors();
      setIsLoad(false);

      setVisitors(response);
      setFilterVisitors(response);
    } catch (err) {}
  };

  React.useEffect(() => {
    if (open) {
      async function obtain() {
        await fetchVisitors();

        setOptions({
          country: [
            ...new Set(filterVisitors.map((item) => item.country_visitor)),
          ],
          region: [...new Set(filterVisitors.map((item) => item.city_visitor))],
          city: [...new Set(filterVisitors.map((item) => item.region_visitor))],
        });
      }

      obtain();
    }
  }, [open]);

  if (isLoad) {
    return <LoadingPage open={isLoad} />;
  }

  if (filterVisitors.length === 0) {
    return (
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <NotFound texto="No se encontró registros." />
      </Backdrop>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth={"lg"}
    >
      <DialogTitle id="alert-dialog-title">
        Cantidad de visitantes del link de reserva
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(searchFilter)} autoComplete="off">
          <br />
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <Controller
                name="region"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputDefault
                    value={value}
                    onChange={onChange}
                    options={options.region.map((el) => {
                      return { value: el, label: el };
                    })}
                    type="select"
                    title="Región"
                  />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Controller
                name="city"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputDefault
                    value={value}
                    onChange={onChange}
                    options={options.city.map((el) => {
                      return { value: el, label: el };
                    })}
                    type="select"
                    title="Ciudad"
                  />
                )}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton
                type="submit"
                style={{
                  background: "#6f6fff",
                  color: "white",
                }}
              >
                <Search />
              </IconButton>
            </Grid>
          </Grid>
          <br />
        </form>

        <TableContainer>
          <Table
            size="small"
            style={{
              border: "1px #eee solid",
              position: "sticky",
              top: 0,
              background: "white",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ background: "#eee", textAlign: "center" }}>
                  Tipo
                </TableCell>
                <TableCell style={{ background: "#eee", textAlign: "center" }}>
                  Región
                </TableCell>
                <TableCell style={{ background: "#eee", textAlign: "center" }}>
                  Ciudad
                </TableCell>
                <TableCell style={{ background: "#eee", textAlign: "center" }}>
                  País
                </TableCell>
                <TableCell style={{ background: "#eee", textAlign: "center" }}>
                  Fecha
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterVisitors.map((row, index) => (
                <TableRow key={index}>
                  <TableCell style={{ textAlign: "left" }}>
                    {toUpperCaseFilter(row.type_visitor)}
                  </TableCell>
                  <TableCell style={{ textAlign: "left" }}>
                    {toUpperCaseFilter(row.region_visitor)}
                  </TableCell>
                  <TableCell style={{ textAlign: "left" }}>
                    {toUpperCaseFilter(row.city_visitor)}
                  </TableCell>
                  <TableCell style={{ textAlign: "left" }}>
                    {toUpperCaseFilter(row.country_visitor)}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Moment
                      filter={toUpperCaseFilter}
                      format="DD/MMM/YYYY HH:mm:ss"
                    >
                      {row.createdat_visitor}
                    </Moment>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage={`Cantidad de registros: ${filterVisitors.length}`}
          rowsPerPageOptions={[
            0,
            30,
            60,
            90,
            120,
            { value: -1, label: "Todo" },
          ]}
          component="div"
          count={filterVisitors.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="text" color="inherit">
          Salir
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogVisitor;
