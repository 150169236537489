import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { delete_garzones } from "../../services/garzones.services";

import toast, { Toaster } from "react-hot-toast";
import { nameCamarero } from "../../functions/nameCamarero";

const DialogEliminargarzones = ({ open, setOpen, data, handleGetGarzones }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const hanndleDeleteGarzones = async (id) => {
    try {
      let { affectedRows } = await delete_garzones(id);

      if (affectedRows === 1) {
        toast.success(`${nameCamarero()} eliminado correctamente`);
        setOpen(false);
        handleGetGarzones();
      } else {
        toast.error(`No se pudo eliminar el ${nameCamarero(false)}`);
        setOpen(false);
      }
    } catch (err) {
      console.log(err.message);
      toast.error(`Hubo un error al eliminar al ${nameCamarero(false)}`);
      setOpen(false);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Eliminar"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Estas seguro de eliminar a {data.nombre_garzones}{" "}
            {data.apellido_garzones} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="text" color="inherit">
            Cerrar
          </Button>
          <Button
            onClick={() => {
              hanndleDeleteGarzones(data.id_garzones);
            }}
            variant="contained"
            color="error"
            autoFocus
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogEliminargarzones;
