import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useAppContext } from "./state.consumption";

import dayjs from "dayjs";
import "moment/locale/es";
import NotFound from "../../atomic/organisms/NotFound.organisms";
import ContainerInput from "../../components/ContainerInput";

const CustomTable = ({ rows, isLoad }) => {
  const styleFirstColumn = {
    fontSize: 12,
    position: "sticky",
    left: 0,
    background: "#fafafd",
    borderRight: "1px #c5bbdc solid",
  };

  if (isLoad) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress color="inherit" /> <br />
        <Typography component="h5" variant="h5" style={{ textAlign: "center" }}>
          Cargando...
        </Typography>
      </div>
    );
  }

  if (rows.length === 0) {
    return <NotFound texto="No se encontró registros." />;
  }

  return (
    <>
      <TableContainer sx={{ maxHeight: 650 }}>
        <Table stickyHeader aria-label="sticky table" size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell align={"left"} style={{ minWidth: 200 }}></TableCell>
              {rows.head.map((row, idx) => (
                <TableCell
                  key={idx}
                  align={"left"}
                  style={{
                    minWidth: 100,
                    fontSize: 12,
                    fontWeight:
                      dayjs().format("dd") === row.substring(0, 2) && "bold",
                    color:
                      dayjs().format("dd") === row.substring(0, 2) && "red",
                  }}
                >
                  {row}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.body.map((row, idx) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                <TableCell align={"left"} style={styleFirstColumn}>
                  {row.nombre_plato}
                </TableCell>

                {row.data.map((data, idx) => (
                  <TableCell
                    key={idx}
                    align={"right"}
                    style={{
                      fontSize: 12,
                      color: dayjs().format("dd") === data.day && "red",
                    }}
                  >
                    {data?.tot}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const TableViewFour = () => {
  const state = useAppContext();

  return (
    <ContainerInput
      style={{
        marginTop: 20,
        marginBottom: 20,
        padding: "0px",
        textAlign: "center",
        borderRadius: "0px",
      }}
    >
      <CustomTable rows={state.dataProjectionWeek} isLoad={state.isLoadData} />
    </ContainerInput>
  );
};

export default TableViewFour;
