/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import ContainerInput from "../../components/ContainerInput";
import Template from "../../components/Template";

import LoadingPage from "../../components/LoadingPage";

import DialogCrudMesas from "./DialogCrudMesas";
import DialogEliminarMesas from "./DialogEliminarMesas";

import { get_mesas } from "../../services/mesas.services";

import { Button, Grid } from "@mui/material";
import { Add } from "@mui/icons-material/";

import { checkToken } from "../../services/auth.services";
import DialogViewQRCode from "./DialogViewQRCode";
import ListMesas from "./ListMesas";

const Mesas = () => {
  const history = useHistory();

  const [loadPage, setLoadPage] = useState(false);

  const [dataMesas, setDataMesas] = useState({ load: false, data: [] });
  const [openAdd, setOpenAdd] = useState(false);

  const [openEliminarMesas, setOpenEliminarMesas] = useState(false);
  const [openViewCodeQR, setOpenViewCodeQR] = useState(false);

  const [dataViewData, setDataViewData] = useState({});
  const [dataViewEliminarData, setDataViewEliminarData] = useState("");
  const [dataViewCodeQRData, setDataViewCodeQRData] = useState({});

  //obtain the user data

  const get_mesas_data = async () => {
    setLoadPage(true);
    try {
      let response = await get_mesas();

      setDataMesas({ ...dataMesas, data: response });
      setLoadPage(false);
    } catch (err) {
      setLoadPage(false);
      console.log(err.message);
    }
  };

  const handleViewData = (data) => {
    setOpenAdd(true);
    setDataViewData(data);
  };

  const handleViewDataEliminar = (data) => {
    setOpenEliminarMesas(true);
    setDataViewEliminarData(data);
  };

  const handleViewQr = (data) => {
    setOpenViewCodeQR(true);
    setDataViewCodeQRData(data);
  };

  useEffect(() => {
    get_mesas_data();
  }, []);

  useEffect(() => {
    if (!checkToken()) {
      history.push("/");
      return null;
    }
  }, []);

  return (
    <Template title="Reservas">
      <LoadingPage open={loadPage} setOpen={setLoadPage} />
      <Grid container spacing={2} style={{ marginBottom: 20 }}>
        <Grid
          item
          xs={12}
          sm={11}
          lg={11}
          style={{
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        ></Grid>
        <Grid
          item
          xs={12}
          sm={1}
          lg={1}
          style={{
            textAlign: "right",
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Button
            fullWidth
            onClick={() => {
              setOpenAdd(true);
              setDataViewData({});
            }}
            className="button-default"
          >
            <Add />
          </Button>
        </Grid>
      </Grid>
      {/* <form onSubmit={handleSearch_User}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={5} lg={5}>
            <TextField
              defaultValue={setSearchUser.texto}
              fullWidth
              name="texto"
              label="Buscar usuario por nombre o email"
              className={`InputDefault ${classes.size}`}
              onChange={(e) =>
                setSearchUser({
                  ...searchUser,
                  texto: e.target.value,
                })
              }
              //   InputProps={{
              //     type: "search",
              //     startAdornment: (
              //       <InputAdornment position="end">
              //         <Search />
              //       </InputAdornment>
              //     ),
              //   }}
            />
          </Grid>

          <Grid item xs={2} sm={1} lg={1} style={{ textAlign: "center" }}>
            <IconButton
              fullWidth
              type="submit"
              aria-label="search"
              size="large"
              style={{ marginLeft: "5px" }}
            >
              <Search fontSize="inherit" />
            </IconButton>
          </Grid>
        </Grid>
      </form> */}
      <ContainerInput
        style={{
          marginTop: 20,
          marginBottom: 20,
          padding: "35px 10px",
          textAlign: "center",
        }}
      >
        {/* <TableMesas
          rows={dataMesas.data}
          handleViewData={handleViewData}
          handleViewDataEliminar={handleViewDataEliminar}
        /> */}

        <ListMesas
          rowsMesas={dataMesas}
          openViewData={handleViewData}
          openViewDataEliminar={handleViewDataEliminar}
          openViewQr={handleViewQr}
        />
      </ContainerInput>
      <DialogCrudMesas
        open={openAdd}
        setOpen={setOpenAdd}
        handleGetMesas={get_mesas_data}
        data={dataViewData}
      />
      <DialogEliminarMesas
        open={openEliminarMesas}
        setOpen={setOpenEliminarMesas}
        data={dataViewEliminarData}
        handleGetMesas={get_mesas_data}
      />
      <DialogViewQRCode
        open={openViewCodeQR}
        setOpen={setOpenViewCodeQR}
        data={dataViewCodeQRData}
      />
    </Template>
  );
};

export default Mesas;
