/* eslint-disable no-unused-vars */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import {
  get_mesas,
  add_mesas,
  update_mesas,
  delete_mesas,
} from "../../services/mesas.services";

import toast, { Toaster } from "react-hot-toast";

const DialogEliminarUser = ({ open, setOpen, data, handleGetMesas }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const hanndleDeleteMesas = async (id) => {
    try {
      let { affectedRows } = await delete_mesas(id);

      if (affectedRows === 1) {
        toast.success("Mesa eliminada correctamente");
        setOpen(false);
        handleGetMesas();
      } else {
        toast.error("No se pudo eliminar la Mesa");
        setOpen(false);
      }
    } catch (err) {
      console.log(err.message);
      toast.error("Hubo un error al eliminar la Mesa");
      setOpen(false);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Eliminar"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Estas seguro de eliminar a {data.codigo_mesa} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="text" color="inherit">
            Cerrar
          </Button>
          <Button
            onClick={() => {
              hanndleDeleteMesas(data.id_mesa);
            }}
            variant="contained"
            color="error"
            autoFocus
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogEliminarUser;
