/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import * as React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import { Grid } from "@mui/material/";

import { useForm } from "react-hook-form";

import { Close } from "@mui/icons-material";

import toast from "react-hot-toast";

import { delete_categoria } from "../../../../../services/categoria_plato.services";

const CrudDeleteCategoria = ({
  categorySelected = null,
  open = false,
  setOpen = () => {},
  callBack = () => {},
}) => {
  const { reset, handleSubmit } = useForm({
    defaultValues: {},
  });

  const handleModal = (el) => {
    setOpen(el);
  };

  const [loading, setLoading] = React.useState(false);

  const handleDelete = async (data) => {
    try {
      setLoading(true);
      await delete_categoria(data.id_categoriapla);
      setLoading(false);

      toast.success("La categoría fue eliminada correctamente.");

      
      handleModal(false);
      await callBack();
    } catch (err) {
      setLoading(false);
      toast.error("Hubo un error al eliminar la categoría.");
    }
  };

  React.useEffect(() => {
    open && reset(categorySelected);
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => handleModal(false)}
      fullWidth
      maxWidth={"sm"}
    >
      <form onSubmit={handleSubmit(handleDelete)} autoComplete="off">
        <DialogTitle>
          Importante!
          <IconButton
            aria-label="close"
            onClick={() => handleModal(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <h3 className="text-center text-gray-600 text-xl pt-5 pb-5 animate-bounce">
              Cargando...
            </h3>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} lg={12}>
                ¿Está seguro que deseas eliminar la categoría{" "}
                <b className="uppercase text-red-600">{categorySelected?.nombre_categoriapla}</b>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="info"
            variant="text"
            disabled={loading}
            onClick={() => {
              handleModal(false);
            }}
          >
            Salir
          </Button>
          <Button
            type="submit"
            color="error"
            variant="contained"
            disabled={loading}
          >
            Eliminar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CrudDeleteCategoria;
