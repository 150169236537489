/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import * as React from "react";

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material/";

import moment from "moment";

import { Alert, Collapse } from "@mui/material";

import { Cancel, Check, Close } from "@mui/icons-material";

import LoadingPage from "../../../components/LoadingPage";
import { getTickets } from "../../../services/ticket.services";
// import { deleteCampaign } from "../../../services/ticket.services";

const ErrorMsg = ({ wrong, setWrong, message }) => {
  return (
    <Collapse in={wrong}>
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setWrong(false);
            }}
          >
            <Close fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
        severity="error"
      >
        {message}
      </Alert>
    </Collapse>
  );
};

const TableTickets = (props) => {
  const rows = props.data;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <TableContainer sx={{ maxHeight: 550 }}>
        <Table stickyHeader aria-label="sticky table" size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell align={"left"} style={{ minWidth: 30 }}>
                Código
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 150 }}>
                Nombres
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 100 }}>
                Número
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 100 }}>
                E-mail
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 90 }}>
                Fecha registro
              </TableCell>
              <TableCell align={"center"} style={{ minWidth: 50 }}>
                Canjeado
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                  <TableCell align={"left"}>#{row.id_ticket}</TableCell>
                  <TableCell align={"left"}>
                    {row.name_ticket} {row.lastname_ticket}
                  </TableCell>
                  <TableCell align={"left"}>{row.number_ticket}</TableCell>
                  <TableCell align={"left"}>{row.email_ticket}</TableCell>
                  <TableCell align={"left"}>
                    {row.createdat_campaign
                      ? moment(row.createdat_campaign).format(
                          "DD/MM/YYYY HH:mm"
                        )
                      : ""}
                  </TableCell>
                  <TableCell align={"center"}>
                    {row.interchanged_ticket ? <Check /> : <Cancel />}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage="Filas por página"
        rowsPerPageOptions={[10, 25, 100, { value: -1, label: "Todo" }]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

const DialogTicketCampaign = (props) => {
  let { open, setOpen, idCampaign } = props;

  const [wrong, setWrong] = React.useState({ open: false, message: "" });

  const [loading, setLoading] = React.useState(false);
  const [dataTickets, setDataTickets] = React.useState([]);

  const handleModal = (value) => {
    setOpen(value);
  };

  const obtainTickets = async (id) => {
    try {
      setLoading(true);
      const response = await getTickets(id);
      setLoading(false);
      setDataTickets(response);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  React.useEffect(() => {
    if (open && idCampaign) {
      obtainTickets(idCampaign);
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => handleModal(false)}
        fullWidth
        maxWidth={"md"}
      >
        <LoadingPage open={loading} />

        <DialogTitle
          style={{
            position: "sticky",
            top: 0,
            background: "white",
            zIndex: 5,
          }}
        >
          Tickets
          <IconButton
            aria-label="close"
            onClick={() => handleModal(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <br />
          <ErrorMsg
            wrong={wrong.open}
            setWrong={(el) => setWrong({ ...wrong, open: el })}
            message={wrong.message}
          />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} lg={12}>
              <TableTickets data={dataTickets} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          style={{ background: "#fff", position: "sticky", bottom: 0 }}
        >
          <Button
            color="info"
            variant="text"
            onClick={() => {
              handleModal(false);
            }}
          >
            Salir
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogTicketCampaign;
