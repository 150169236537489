import * as React from "react";

import {
  Slide,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  TextField,
  Grid,
} from "@mui/material";

import { toast } from "react-hot-toast";

import { getUser } from "../../../../../services/auth.services";
import { get_motivos } from "../../../../../services/pedidos.services";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref || false} {...props} />;
});

const DialogCloseAccount = (props) => {
  let { callback, open, setOpen } = props;

  const [values, setValues] = React.useState({
    password: "",
    comment: "",
    motive: null,
  });

  const [motive, setMotive] = React.useState([]);

  const [attemptNumber, setAttemptNumber] = React.useState(0);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { value, name } = e.target;

    setValues({ ...values, [name]: value });
  };

  const handleConfirm = (e) => {
    e.preventDefault();

    if (values.password === `${getUser().username}`) {
      toast.success("La contraseña es correcta, se eliminó la cuenta.", {
        position: "top-center",
      });

      callback(values);
      handleClose();

      return;
    }

    setAttemptNumber(attemptNumber + 1);

    if (attemptNumber >= 3) {
      toast.error("Si no conoces la contraseña, solicita al administrador.", {
        position: "top-center",
      });

      return;
    }

    toast.error("La contraseña es incorrecta.", { position: "top-center" });
  };

  const handleGetMotive = async () => {
    try {
      const response = await get_motivos();

      const newResponse = response.map((el) => ({
        label: el.nombre_motivo,
        value: el.id_motivo,
      }));

      setMotive(newResponse);
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    handleGetMotive();
  }, []);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth
      onClose={handleClose}
      maxWidth={"xs"}
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form onSubmit={handleConfirm}>
        <DialogTitle id="alert-dialog-title">
          ¿Seguro de cancelar la cuenta?
        </DialogTitle>
        <DialogContent>
          <br />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                size="medium"
                fullWidth
                label="Contraseña"
                type="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                className={`InputDefault`}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                variant="outlined"
                value={values.motive}
                name="motive"
                onChange={handleChange}
                className={`InputDefault`}
                required
                SelectProps={{
                  native: true,
                }}
              >
                <option value="">[Motivo]</option>
                {motive.map((option, idx) => (
                  <option key={idx} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-multiline-static"
                label="Comentarios / observaciones"
                multiline
                rows={3}
                name="comment"
                value={values.comment}
                onChange={handleChange}
                className={`InputDefault`}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="text" color="inherit">
            Cerrar
          </Button>
          <Button type="submit" variant="contained" color="error">
            Confirmar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogCloseAccount;
