import { Typography, CircularProgress } from "@mui/material";
import NotFound from "../../../../atomic/organisms/NotFound.organisms";

const Products = (props) => {
  let { err, load } = props.data;

  if (load) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress color="inherit" /> <br />
        <Typography component="h5" variant="h5" style={{ textAlign: "center" }}>
          Cargando...
        </Typography>
      </div>
    );
  }

  if (err) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <NotFound title="¡Ocurrió un problema!" />
      </div>
    );
  }

  return (
    <>
      {props.nav}
      {props.render}
    </>
  );
};

export default Products;
