import React from "react";

import { useStoreQuotation } from "../useStoreQuotation";
import PDFMatrimonyContract from "./pdf-contract/matrimonyContract.pdf";
import PDFAnotherContract from "./pdf-contract/anotherContract.pdf";

const ViewAndDownloadContractFile = () => {
  const dataContract = useStoreQuotation((state) => state.dataContract);

  const eventId = dataContract?.idEvent;

  const isMatrimony = eventId === 1;

  if (isMatrimony) {
    return (
      <div className="relative w-full h-[600px]">
        <PDFMatrimonyContract dataContract={dataContract} />
      </div>
    );
  }

  return (
    <div className="relative w-full h-[600px]">
      <PDFAnotherContract dataContract={dataContract} />
    </div>
  );
};

export default ViewAndDownloadContractFile;
