import axios from "axios";
import { config } from "../config";

import { axiosHeader } from "../functions/axiosHeader";

const { baseurl } = config;

export const get_capacidad = (id) => {
  const url = `${baseurl}/api/capacidad/get/`;

  return axios
    .get(url, axiosHeader({ empsede: id }))
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const update_capacidad = (params) => {
  const url = `${baseurl}/api/capacidad/update/`;

  return axios
    .put(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

/**
 * CAPACIDAD V2
 */

export const add_capacidad = (params, id) => {
  const url = `${baseurl}/api/capacidad/v2/add/`;

  return axios
    .post(url, params, axiosHeader({ empsede: id }))
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const updatev2_capacidad = (params) => {
  const url = `${baseurl}/api/capacidad/v2/update/`;

  return axios
    .put(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deletev2_capacidad = (id) => {
  const url = `${baseurl}/api/capacidad/v2/delete/${id}`;

  return axios
    .delete(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getv2_capacidad = (idEmpSede) => {
  const url = `${baseurl}/api/capacidad/v2/get`;

  return axios
    .get(url, axiosHeader({ empsede: idEmpSede }))
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};
