import axios from "axios";
import { config } from "../config";

import { axiosHeader } from "../functions/axiosHeader";

const { baseurl } = config;

/**
 * CAMPAIGN
 */

export const getTypeBenefit = (params) => {
  const url = `${baseurl}/api/typeBenefit/${params || ""}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const addTypeBenefit = (params) => {
  const url = `${baseurl}/api/typeBenefit/create`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const updateTypeBenefit = (id_typeBenefit, params) => {
  const url = `${baseurl}/api/typeBenefit/update/${id_typeBenefit}`;

  return axios
    .patch(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deleteTypeBenefit = (id_typeBenefit) => {
  const url = `${baseurl}/api/typeBenefit/delete/${id_typeBenefit}`;

  return axios
    .delete(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};
