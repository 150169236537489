import * as React from "react";

import { Controller, useForm } from "react-hook-form";

import InputDefault from "../../../../../atomic/atoms/input.atoms";


import { Search } from "@mui/icons-material";
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import NotFound from "../../../../../atomic/organisms/NotFound.organisms";
import { onCheckIsValid } from "../../../../../functions/checkValidBenefit";
import { formatMoney } from "../../../../../functions/formatMoney";
import { getUser } from "../../../../../services/auth.services";
import { getTicketBenefitId } from "../../../../../services/ticketBenefit.services";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref || false} {...props} />;
});

const TableBenefit = ({ rows, isLoad }) => {
  if (isLoad) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress color="inherit" /> <br />
        <Typography component="h5" variant="h5" style={{ textAlign: "center" }}>
          Cargando...
        </Typography>
      </div>
    );
  }

  if (rows === "") {
    return <NotFound texto="No se encontró registros." />;
  }

  return (
    <>
      <TableContainer
        sx={{ maxHeight: 550 }}
        style={{
          border: "1px solid #e0e0e0",
        }}
      >
        <Table stickyHeader aria-label="sticky table" size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell align={"left"} style={{ minWidth: 150 }}>
                Nombre
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 140 }}>
                Tipo beneficio
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 140 }}>
                Fec. de apertura
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 140 }}>
                Fec. de cierre
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 120 }}>
                Valor de beneficio
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 140 }}>
                Estado
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover role="checkbox" tabIndex={-1}>
              <TableCell align={"left"}>{rows.name_benefit}</TableCell>
              <TableCell align={"left"}>{rows.name_typebenefit}</TableCell>
              <TableCell align={"left"}>
                {dayjs(rows.startdate_benefit).format("DD/MM/YYYY") ||
                  "Sin fecha de apertura"}
              </TableCell>
              <TableCell align={"left"}>
                {dayjs(rows.enddate_benefit).format("DD/MM/YYYY") ||
                  "Sin fecha de cierre"}
              </TableCell>
              <TableCell align={"left"}>
                {rows.typevalue_benefit === "percent"
                  ? rows.value_benefit
                  : formatMoney(rows.value_benefit)}
                {rows.typevalue_benefit === "percent" ? "%" : ""}
              </TableCell>
              <TableCell align={"left"}>
                {rows?.status_benefit === "Activo" ? (
                  <Chip label={rows?.status_benefit} color="success" />
                ) : (
                  <Chip label={rows?.status_benefit} color="error" />
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const DialogBenefit = (props) => {
  const { open, setOpen } = props;
  const [rows, setRows] = React.useState("");
  const [isLoad, setIsLoad] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      codigo: "",
    },
  });

  const onSubmit = async (params) => {
    const idEmpSede = getUser()?.empresa?.id_empsede;

    setIsLoad(true);
    const response = await getTicketBenefitId(params?.codigo);
    setIsLoad(false);

    if (response?.id_empsede === idEmpSede) {
      const statusBenefit = onCheckIsValid(response);
      setRows({ ...response, status_benefit: statusBenefit });

      return;
    }

    setRows("");
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth
      onClose={handleClose}
      maxWidth={"md"}
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogTitle id="alert-dialog-title">Beneficios</DialogTitle>
        <DialogContent>
          <br />
          <Grid container spacing={1}>
            <Grid item xs={11}>
              <Controller
                name="codigo"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputDefault
                    required
                    fullWidth
                    value={value}
                    onChange={onChange}
                    type="text"
                    title={"Buscar por código"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton type="submit">
                <Search />
              </IconButton>
            </Grid>
          </Grid>
          <br />
          <TableBenefit rows={rows} isLoad={isLoad} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="text" color="inherit">
            Cerrar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogBenefit;
