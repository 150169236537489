/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import * as React from "react";

import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import { Close, NotificationsActive } from "@mui/icons-material";

import {
  Checkbox,
  CircularProgress,
  Table,
  TableCell,
  TableRow,
} from "@mui/material";
import "moment/locale/es";
import { getTask, updateTask } from "../../services/task.services";
import dayjs from "dayjs";

const StatusTask = (props) => {
  const { dateTask } = props;

  const date = dayjs(dateTask);
  const today = dayjs();

  const nameStatus = date.isAfter(today) ? "Pendiente" : "Vencida";

  return (
    <div className={`text-white text-xs font-semibold rounded-full px-2 py-1 uppercase tracking-wide text-center ${nameStatus === "Pendiente" ? "bg-orange-500" : "bg-red-500"}`}>
      {nameStatus}
    </div>
  );
};

const DialogViewDataReminder = ({ open, setOpen, data }) => {
  const [task, setTask] = React.useState([]);
  const [load, setLoad] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const fetchTask = async (name) => {
    const response = await getTask({
      name,
    });

    setTask(response);
  };

  const handleComplete = async (element, id) => {
    setLoad(true);
    if (element.target.checked) {
      await updateTask(id, {
        status_task: "done",
      });
    } else {
      await updateTask(id, {
        status_task: "doing",
      });
    }

    await fetchTask(data?.id_reserva);
    setLoad(false);
  };

  React.useEffect(() => {
    if (open) {
      fetchTask(data?.id_reserva);
    }
  }, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
        <DialogTitle className="text-blue-900 text-xl font-semibold flex gap-2 items-center">
          <NotificationsActive /> Recordatorio
          <div>
            {load && (
              <CircularProgress
                size={20}
                color="secondary"
                variant="indeterminate"
              />
            )}
          </div>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {task.length === 0 && (
            <div className="text-center">
              <h3 className="font-bold">No hay tareas</h3>
            </div>
          )}

          <Table
            size="medium"
            aria-label="simple table"
            className="border border-slate-200"
          >
            {task.map((el) => (
              <TableRow key={el.id_task}>
                <TableCell>
                  <div className="flex items-center gap-2">
                    <div>{el?.name_task.replace(/^#\d+\s-\s/, "").trim()}</div>
                    {el?.status_task !== "done" && (
                      <StatusTask dateTask={el?.date_task} />
                    )}{" "}
                  </div>

                  <div className="text-xs text-slate-500">
                    {dayjs(el?.date_task).format("dddd, DD MMMM YYYY")}
                  </div>
                  <div className="text-xs text-slate-500"></div>
                </TableCell>
                <TableCell className="">
                  <Checkbox
                    disabled={load}
                    color="primary"
                    value={el?.status_task === "done"}
                    checked={el?.status_task === "done"}
                    onChange={(element) => handleComplete(element, el?.id_task)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DialogViewDataReminder;
