/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import TemplateLogin from "../../components/TemplateLogin";

import Logo from "../../assets/images/logo.png";

import { Typography } from "@mui/material";

import { update_reserva } from "../../services/reservas.services";

const ReservaFinal = () => {
  let { id } = useParams();

  const handleCancelReserva = async () => {
    try {
      let response = await update_reserva({
        id,
        status: "cancelado",
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleCancelReserva();
  }, []);

  return (
    <TemplateLogin title="Login">
      <img
        className="Logo_Login"
        src={Logo}
        alt="Logo"
        style={{ marginBottom: 20 }}
      />
      <Typography variant="h6" className="Texto_Login">
        Acabamos de cancelar tu reserva.
      </Typography>
      <div className="Inputs_Container">
        <p>
          Gracias por confiar en nosotros, en breve recibirás un correo
          confirmando tu cancelación.
        </p>
        <p>Si no recibes el mensaje, puedes contactarnos.</p>
        <br />
        <br />
        <Link to="/reservar" style={{ textDecoration: "revert" }}>
          Volver a reservar.
        </Link>
      </div>
    </TemplateLogin>
  );
};

export default ReservaFinal;
