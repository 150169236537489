import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import { Grid } from "@mui/material/";

import Input from "../../../../../components/Forms/Input";

import { Controller, useForm } from "react-hook-form";

import { Close } from "@mui/icons-material";

import toast from "react-hot-toast";

import {
  add_categoria,
  update_categoria_plato,
} from "../../../../../services/categoria_plato.services";

const FormCartaSecondary = (
  categorySelected,
  handleAdd,
  handleModal,
  loading
) => {
  const initialState = {
    estado_categoriapla: "",
    fechareg_categoriapla: "",
    id_categoriapla: null,
    id_empsede: null,
    nombre_categoriapla: "",
    orden_categoriapla: "",
  };

  const { control, reset, handleSubmit } = useForm({
    defaultValues: initialState,
  });

  const FormCarta = () => {
    if (loading) {
      return (
        <h3 className="text-center text-gray-600 text-xl pt-5 pb-5 animate-bounce">
          Cargando...
        </h3>
      );
    }

    return (
      <form
        onSubmit={handleSubmit(handleAdd)}
        autoComplete="off"
        className="pt-2"
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} lg={12}>
            <Controller
              name="nombre_categoriapla"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Input
                  required
                  value={value}
                  onChange={onChange}
                  type="text"
                  title="Nombre"
                />
              )}
            />
          </Grid>
        </Grid>
        <input type="submit" hidden />
      </form>
    );
  };

  return {
    Reset: (data) => {
      if (!data) {
        return reset(initialState);
      }

      reset(data);
    },
    Submit: handleSubmit,
    Form: (
      <>
        <DialogTitle>
          {categorySelected?.id_categoriapla
            ? "Editar categoría"
            : "Agregar nueva categoría"}
          <IconButton
            aria-label="close"
            onClick={() => handleModal(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormCarta />
        </DialogContent>
        <DialogActions>
          <Button
            color="info"
            variant="text"
            disabled={loading}
            onClick={() => {
              handleModal(false);
            }}
          >
            Salir
          </Button>
          <Button
            type="submit"
            color="success"
            variant="contained"
            disabled={loading}
            onClick={handleSubmit(handleAdd)}
          >
            Aceptar
          </Button>
        </DialogActions>
      </>
    ),
  };
};

const CrudFormCartaSecondary = ({
  categorySelected = null,
  open = false,
  setOpen = () => {},
  onRefresh = () => {},
}) => {
  const [loading, setLoading] = useState(false);

  const handleModal = (el) => {
    setOpen(el);
  };

  const handleAdd = async (data) => {
    try {
      setLoading(true);

      if (data?.id_categoriapla) {
        const { response } = await update_categoria_plato(data);
        setLoading(false);

        if (response.affectedRows !== 1) {
          toast.error("No se pudo modificar la categoría.");
          return;
        }

        toast.success("Categoría modificado correctamente.");

        Reset();
        handleModal(false);

        await onRefresh();
        return;
      }

      await add_categoria(data, 2);
      setLoading(false);

      toast.success("Categoría creada correctamente.");

      Reset();
      handleModal(false);
      await onRefresh();
    } catch (err) {
      setLoading(false);
      toast.error("Hubo un error al crear la categoría");
    }
  };

  const { Form, Reset } = FormCartaSecondary(
    categorySelected,
    handleAdd,
    handleModal,
    loading
  );

  useEffect(() => {
    open && Reset(categorySelected);
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => handleModal(false)}
      fullWidth
      maxWidth={"sm"}
    >
      {Form}
    </Dialog>
  );
};

export default CrudFormCartaSecondary;
