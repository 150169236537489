/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import ContainerInput from "../../components/ContainerInput";
import Template from "../../components/Template";

import LoadingPage from "../../components/LoadingPage";

import { styled } from "@mui/system";

import TablePlazas from "../../components/Tables/TablePlazas";

import DialogCrudPlazasSelected from "./DialogCrudPlazasSelected";
import DialogEliminarPlazas from "./DialogEliminarPlazas";

import { get_asignacion } from "../../services/plazas.services";

import { get_garzones } from "../../services/garzones.services";
import { get_mesas } from "../../services/mesas.services";

import { Button, Grid } from "@mui/material";

import { Add } from "@mui/icons-material/";

import { checkToken } from "../../services/auth.services";

const useStyles = styled((theme) => ({
  size: {
    width: "100%",
  },
}));

const Plazas = () => {
  let history = useHistory();

  const classes = useStyles();
  const [loadPage, setLoadPage] = useState(false);

  const [openAdd, setOpenAdd] = useState(false);

  const [dataPlazas, setDataPlazas] = useState({ load: false, data: [] });
  const [dataMesas, setDataMesas] = useState({ load: false, data: [] });
  const [dataGarzones, setDataGarzones] = useState({ load: false, data: [] });

  const [openEliminarPlaza, setOpenEliminarPlaza] = useState(false);

  const [dataViewData, setDataViewData] = useState({});
  const [dataViewEliminarData, setDataViewEliminarData] = useState("");

  //obtain the user data

  const get_plaza_data = async () => {
    try {
      let response = await get_asignacion();

      setDataPlazas({ ...dataPlazas, data: response });
    } catch (err) {
      setLoadPage(false);
      console.log(err.message);
    }
  };

  const get_mesas_data = async () => {
    try {
      let response = await get_mesas();

      setDataMesas({ ...dataMesas, data: response });
    } catch (err) {
      setLoadPage(false);
      console.log(err.message);
    }
  };

  const get_garzones_data = async () => {
    try {
      let response = await get_garzones();

      setDataGarzones({ ...dataGarzones, data: response });
    } catch (err) {
      setLoadPage(false);
      console.log(err.message);
    }
  };

  const handleViewData = (data) => {
    setOpenAdd(true);
    setDataViewData(data);
  };

  const handleViewDataEliminar = (data) => {
    setOpenEliminarPlaza(true);
    setDataViewEliminarData(data);
  };

  //   useEffect(async () => {
  //     let response = await get_capacidad();
  //     reset(response);
  //   }, [reset]);

  useEffect(async () => {
    setLoadPage(true);
    await get_plaza_data();
    await get_mesas_data();
    await get_garzones_data();
    setLoadPage(false);
  }, []);

  useEffect(() => {
    if (!checkToken()) {
      history.push("/");
      return null;
    }
  }, []);

  return (
    <Template title="Reservas">
      <LoadingPage open={loadPage} setOpen={setLoadPage} />

      <Grid container spacing={2} style={{ marginBottom: 20 }}>
        <Grid
          item
          xs={12}
          sm={11}
          lg={11}
          style={{
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        ></Grid>
        <Grid
          item
          xs={12}
          sm={1}
          lg={1}
          style={{
            textAlign: "right",
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Button
            fullWidth
            onClick={() => {
              setOpenAdd(true);
              setDataViewData({});
            }}
            className="button-default"
          >
            <Add />
          </Button>
        </Grid>
      </Grid>

      <ContainerInput
        style={{
          marginTop: 20,
          marginBottom: 20,
          padding: "35px 10px",
          textAlign: "center",
        }}
      >
        <TablePlazas
          rows={dataPlazas.data}
          handleViewData={handleViewData}
          handleViewDataEliminar={handleViewDataEliminar}
          handleRedirect={(el) => history.push(`/plaza/${el}`)}
        />
      </ContainerInput>

      {/* <DialogCrudPlazas
        open={openAdd}
        setOpen={setOpenAdd}
        handleGetPlaza={get_plaza_data}
        data={dataViewData}
      /> */}

      <DialogEliminarPlazas
        open={openEliminarPlaza}
        setOpen={setOpenEliminarPlaza}
        data={dataViewEliminarData}
        handleGetPlaza={get_plaza_data}
      />

      <DialogCrudPlazasSelected
        open={openAdd}
        setOpen={setOpenAdd}
        handleGetPlazaDetalle={get_plaza_data}
        data={dataViewData}
        setData={setDataViewData}
        dataMesas={dataMesas}
        dataGarzones={dataGarzones}
      />
    </Template>
  );
};

export default Plazas;
