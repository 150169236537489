import * as React from "react";

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

import { Delete, Edit, Settings } from "@mui/icons-material";
import NotFound from "../../../atomic/organisms/NotFound.organisms";
import { nameCamarero } from "../../../functions/nameCamarero";

const TableMesas = ({ rows, handleDeleteMesaGarzon, handleEdit }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (rows.length === 0) {
    return <NotFound texto="No se encontró registros." />;
  }

  return (
    <>
      <TableContainer sx={{ maxHeight: 550 }}>
        <Table stickyHeader aria-label="sticky table" size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell align={"left"} style={{ minWidth: 150 }}>
                {nameCamarero()}
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 150 }}>
                Mesas
              </TableCell>
              <TableCell
                colSpan={2}
                align={"center"}
                style={{ width: 90, fontSize: 21, color: "#5c5c5c" }}
              >
                <Settings />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                  <TableCell align={"left"}>{row.usu_nombre}</TableCell>
                  <TableCell align={"left"}>{row.mesas}</TableCell>
                  <TableCell align={"center"} width={35}>
                    <IconButton color="warning" onClick={() => handleEdit(row)}>
                      <Edit />
                    </IconButton>
                  </TableCell>
                  <TableCell align={"center"} width={35}>
                    <IconButton
                      color="error"
                      onClick={() => handleDeleteMesaGarzon(row.usu_id)}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage="Filas por página"
        rowsPerPageOptions={[10, 25, 100, { value: -1, label: "Todo" }]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default TableMesas;
