import * as React from "react";

import { Grid } from "@mui/material";

import "./style.print.account.scss";

import moment from "moment";
import "moment/locale/es";

import { formatMoney, tipMoney } from "../../../../../functions/formatMoney";
import { nameCamarero } from "../../../../../functions/nameCamarero";
import { signalMoney } from "../../../../../functions/signalMoney";
import { getUser } from "../../../../../services/auth.services";

function SubTotal(data) {
  let total = 0;

  for (let i = 0; i < data.length; i++) {
    const subTotalPlato = data[i].precio_plato * data[i].amount_plapedido;

    const typeDiscountPlato = data[i]?.tipodescuento_plapedido || " ";

    const discountPlato = data[i].descuento_plapedido || 0;

    if (typeDiscountPlato === "PORCENTAJE") {
      const percent = subTotalPlato * (discountPlato / 100);
      total += subTotalPlato - percent;
    } else if (typeDiscountPlato === "PRECIO") {
      total += subTotalPlato - discountPlato;
    } else if (typeDiscountPlato === "COVER") {
      const result = subTotalPlato - discountPlato;
      total += result < 0 ? 0 : result;
    } else {
      total += subTotalPlato;
    }
  }

  return total;
}

function ExtraDiscount(subTotal, data) {
  if (data.id_reserva || data.id_reserva > 0) {
    const discount = data.monto_reserva || 0;

    return {
      textAmountExtraDiscount: `ABONO RESERVA`,
      amountExtraTotal: discount,
    };
  }

  const amountExtraDiscount = 0;
  const textAmountExtraDiscount = `PROPINA ${amountExtraDiscount}%`;
  const amountExtraTotal = subTotal * (amountExtraDiscount / 100);

  return {
    textAmountExtraDiscount,
    amountExtraTotal,
  };
}

const DialogPrintAccount = React.forwardRef((props, ref) => {
  const { infoPedido, dataProducts } = props;

  const data = infoPedido.data;
  const dataProduct = dataProducts.data;

  if (!data) {
    return null;
  }

  const isAbooking = data?.id_reserva && data?.id_reserva > 0;

  const subTotal = SubTotal(dataProduct);

  const discount = data.descuento_pedido;
  const typeDiscount = data.tipodescuento_pedido;
  const { textAmountExtraDiscount, amountExtraTotal } = ExtraDiscount(
    subTotal,
    data
  );

  let discountTotal = 0;

  if (typeDiscount === "PORCENTAJE") {
    discountTotal = discount ? subTotal - subTotal * (discount / 100) : 0;
  } else if (typeDiscount === "PRECIO") {
    discountTotal = discount ? subTotal - discount : 0;
  } else if (typeDiscount === "COVER") {
    const result = subTotal - discount;
    discountTotal = result < 0 ? 0 : result;
  } else {
    discountTotal = subTotal;
  }

  const subTotalAmountExtra = !isAbooking
    ? subTotal + amountExtraTotal
    : subTotal - amountExtraTotal;

  const subTotalDiscountExtra = !isAbooking
    ? discountTotal + amountExtraTotal
    : discountTotal - amountExtraTotal;

  const amountLiberados = !isAbooking ? 0 : data.amountliberados_pedido;
  const subTotalLiberados = !isAbooking ? 0 : data.priceliberados_pedido;

  const total =
    discountTotal === 0
      ? subTotalAmountExtra - discountTotal - subTotalLiberados
      : subTotalDiscountExtra - subTotalLiberados;

  const newTotal = total < 0 ? 0 : total;

  return (
    <div ref={ref}>
      <div className="containerPrint">
        <Grid container spacing={1}>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            ============================================================
          </Grid>
          <Grid item xs={12}>
            {getUser().empresa.nom_emp} <br />
            {getUser().empresa.direction_emp}
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            ============================================================
          </Grid>
          <Grid item xs={6}>
            COD. PEDIDO: {data.codigo_pedido}
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            {moment(data.fechareg_pedido).format("DD/MM/YYYY HH:mm")}
          </Grid>
          {/* <Grid item xs={6}>
            COD. MESA: {!data.codigo_mesa ? "DIRECTO" : `#${data.codigo_mesa}`}
          </Grid> */}
          {/* <Grid item xs={6} style={{ textAlign: "right" }}>
            COD RESERVA: {data.id_reserva || "-"}
          </Grid> */}
          <Grid item xs={12}>
            {/* GARZÓN:{" "} */}
            <span style={{ textTransform: "uppercase" }}>
              {nameCamarero()}: {data.usu_nombre}
            </span>
          </Grid>
          <Grid item xs={12}>
            NOMBRE CLIENTE:{" "}
            <span style={{ textTransform: "uppercase" }}>
              {data?.fullname_pedido || "-"}
            </span>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            ============================================================
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <table style={{ width: "100%" }}>
            <thead>
              <td>CANTIDAD</td>
              <td>DESCRIPCIÓN</td>
              <td>SUBTOTAL</td>
              <td>DESCUENTO</td>
              <td>IMPORTE</td>
            </thead>
            <tbody>
              {dataProduct.map((item, index) => {
                let _totalPlato = 0;

                const subTotalPalto = item.precio_plato * item.amount_plapedido;
                const discountPlato = item.descuento_plapedido || 0;
                const typeDiscountPlato = item.tipodescuento_plapedido || "";

                if (typeDiscountPlato === "PORCENTAJE") {
                  _totalPlato =
                    subTotalPalto - subTotalPalto * (discountPlato / 100);
                } else if (typeDiscountPlato === "PRECIO") {
                  _totalPlato = subTotalPalto - discountPlato;
                } else if (typeDiscountPlato === "COVER") {
                  const result = subTotalPalto - discountPlato;
                  _totalPlato += result < 0 ? 0 : result;
                } else {
                  _totalPlato = subTotalPalto;
                }

                const discount = item.tipodescuento_plapedido
                  ? signalMoney(
                      item.tipodescuento_plapedido,
                      item.descuento_plapedido
                    )
                  : signalMoney(0);

                return (
                  <tr key={index}>
                    <td>{item.amount_plapedido}</td>
                    <td>{item.nombre_plato}</td>
                    <td>
                      {formatMoney(item.precio_plato * item.amount_plapedido)}
                    </td>
                    <td>{discount}</td>
                    <td>{formatMoney(_totalPlato)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Grid>

        <Grid item xs={12}>
          <div style={{ width: "max-content", margin: "15px 0px auto auto" }}>
            <table>
              <tr>
                <td>CONSUMO:</td>
                <td>{formatMoney(subTotal)}</td>
              </tr>
              <tr>
                <td>
                  MENOS EL DESCUENTO DEL (
                  {typeDiscount ? signalMoney(typeDiscount, discount) : "0 CLP"}
                  )
                </td>
                <td>{formatMoney(discountTotal)}</td>
              </tr>
              {/* <tr>
                <td>{textAmountExtraDiscount}:</td>
                <td>{formatMoney(amountExtraTotal)}</td>
              </tr> */}
              {isAbooking && (
                <tr>
                  <td>LIBERADOS {amountLiberados}:</td>
                  <td>{formatMoney(subTotalLiberados)}</td>
                </tr>
              )}
              <tr>
                <td>TOTAL:</td>
                <td>{formatMoney(newTotal)}</td>
              </tr>
            </table>
          </div>
        </Grid>
      </div>
    </div>
  );
});

export default DialogPrintAccount;
