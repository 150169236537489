import { Delete, Edit, People, QrCode } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";

import NotFound from "../../atomic/organisms/NotFound.organisms";

const ListMesas = (props) => {
  let { rowsMesas, openViewData, openViewDataEliminar, openViewQr } = props;

  if (rowsMesas.load) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress color="inherit" /> <br />
        <Typography component="h5" variant="h5" style={{ textAlign: "center" }}>
          Cargando...
        </Typography>
      </div>
    );
  }

  if (rowsMesas.data === 0) {
    return <NotFound texto="No se encontró registros." />;
  }

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={1}>
      {rowsMesas.data.map((row, idx) => (
        <Grid key={idx} item xs={12} sm={6} md={4} lg={4} zeroMinWidth>
          <Paper
            variant="outlined"
            style={{
              textAlign: "center",
              padding: 10,
            }}
          >
            <Typography component={"h6"} variant={"h6"}>
              Mesa #{row.codigo_mesa}
            </Typography>
            <div className="persons">
              <div>
                <People style={{ verticalAlign: "bottom", fontSize: "20px" }} />
                {row.cantidad_mesa} max.
              </div>
            </div>
            <br />
            <Divider />
            <Grid container spacing={1} style={{ marginTop: 5 }}>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => openViewData(row)}
                >
                  <Edit />
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="outlined"
                  color="error"
                  fullWidth
                  onClick={() => openViewDataEliminar(row)}
                >
                  <Delete />
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="outlined"
                  color="error"
                  fullWidth
                  onClick={() => openViewQr(row)}
                >
                  <QrCode />
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default ListMesas;
