import axios from "axios";
import { config } from "../config";

import { axiosHeader } from "../functions/axiosHeader";

const { baseurl } = config;

/**
 * CAMPAIGN
 */

export const getCampaignById = (id_campaign) => {
  const url = `${baseurl}/api/ticket/campaign/get/${id_campaign}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getCampaigns = () => {
  const url = `${baseurl}/api/ticket/campaign/get`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getTypeCampaign = () => {
  const url = `${baseurl}/api/ticket/campaign/type`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const updateCampaign = (id_campaign, params) => {
  const url = `${baseurl}/api/ticket/campaign/update/${id_campaign}`;

  return axios
    .patch(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deleteCampaign = (id_campaign) => {
  const url = `${baseurl}/api/ticket/campaign/delete/${id_campaign}`;

  return axios
    .delete(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const addCampaign = (params) => {
  const url = `${baseurl}/api/ticket/campaign/add`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

/**
 * TICKET
 */

export const addTicket = async (params) => {
  const url = `${baseurl}/api/ticket/ticket/add`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getTickets = (id) => {
  const url = `${baseurl}/api/ticket/ticket/get/${id}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};
