import { Typography } from "@mui/material";

import { useEffect, useState } from "react";
import { tot_sale } from "../../../services/pedidos.services";
import CardReport from "../CardReport";

import { formatMoney } from "../../../functions/formatMoney";
import { getUser } from "../../../services/auth.services";

const Propinas = (props) => {
  const { socket, style } = props;

  const [totSale, setTotSale] = useState({
    load: true,
    err: false,
    data: [],
  });

  const handleTotSale = async (params) => {
    if (params.id_empsede === getUser().empresa.id_empsede) {
      try {
        const response = await tot_sale();
        setTotSale({
          load: false,
          err: false,
          data: response,
        });
      } catch (err) {
        setTotSale({ load: false, err: true, data: [] });
        console.log(err);
      }
    }
  };

  // refresca todos los pedidos
  useEffect(() => {
    if (!socket) {
      return;
    }

    socket.on("server:refreshOrders", handleTotSale);
    return () => {
      socket.off("server:refreshOrders", handleTotSale);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleTotSale({ id_empsede: getUser().empresa.id_empsede });
  }, []);

  return (
    <CardReport style={style} title={"Total de ventas"} dataReport={totSale}>
      {totSale.data[0] && (
        <Typography variant="h5" component="div">
          {formatMoney(totSale?.data[0]?.tot_consumo || 0)}
        </Typography>
      )}
    </CardReport>
  );
};

export default Propinas;
