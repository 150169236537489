import axios from "axios";
import { config } from "../config";

import { axiosHeader } from "../functions/axiosHeader";

const { baseurl } = config;

export const get_plazas = () => {
  const url = `${baseurl}/api/plazas/get/`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const add_plazas = (params) => {
  const url = `${baseurl}/api/plazas/add/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const update_plazas = (params, id) => {
  const url = `${baseurl}/api/plazas/update/${id}/`;

  return axios
    .put(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const delete_plazas = (id) => {
  const url = `${baseurl}/api/plazas/delete/${id}/`;

  return axios
    .delete(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_plazas_by_id = (id) => {
  const url = `${baseurl}/api/plazas/get/${id}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_asignacion = () => {
  const url = `${baseurl}/api/plazas/get_asignacion/`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

/* ASIGNACION DE PLAZA */

export const add_asignacion = (params) => {
  const url = `${baseurl}/api/plazas/add_asignacion/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_asignacion_id = (id) => {
  const url = `${baseurl}/api/plazas/get_asignacion_id/${id}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const update_asignacion = (params) => {
  const url = `${baseurl}/api/plazas/update_asignacion`;

  return axios
    .put(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const delete_asignacion = (id) => {
  const url = `${baseurl}/api/plazas/delete_asignacion/${id}/`;

  return axios
    .delete(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};
