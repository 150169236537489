import * as React from "react";
import {
  Slide,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Grid,
  Divider,
  CircularProgress,
  Typography,
  Paper,
  TextField,
} from "@mui/material";
import { People } from "@mui/icons-material";

import LoadingPage from "../../../components/LoadingPage";

import { get_user_today } from "../../../services/user.services";
import { add_mesa_garzon } from "../../../services/mesas.services";

import { useForm } from "react-hook-form";

import toast from "react-hot-toast";
import NotFound from "../../../atomic/organisms/NotFound.organisms";
import { nameCamarero } from "../../../functions/nameCamarero";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref || false} {...props} />;
});

const TableMesas = (props) => {
  let { selectedTable, handleSetSelectedTable } = props;

  let { data, err, load } = props.data;

  if (load) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress color="inherit" /> <br />
        <Typography component="h5" variant="h5" style={{ textAlign: "center" }}>
          Cargando...
        </Typography>
      </div>
    );
  }

  if (err) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <NotFound title="¡Ocurrió un problema!" />
      </div>
    );
  }

  if (data.length === 0 && load === false) {
    return <NotFound texto="No se encontró registros." />;
  }

  // if (modeCrud === "edit") {
  //   data = data.map((elMesa) => {
  //     const newMesa = selectedTable.find(
  //       (el) => el.codigo_mesa === elMesa.codigo_mesa
  //     );
  //     if (newMesa) {
  //       return { ...newMesa, ocupado_mesa: 0 };
  //     }
  //     return { ...elMesa };
  //   });
  //   console.log(data);
  // }

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={1}
      style={{ marginTop: 10 }}
    >
      {data.map((el, idx) => {
        const isSelected = selectedTable.some(
          (elTable) => elTable.id_mesa === el.id_mesa
        );

        // if (modeCrud === "add") {
        return (
          parseInt(el.ocupado_mesa) === 0 && (
            <Grid key={idx} item xs={12} sm={4} md={3} lg={3} zeroMinWidth>
              <Paper
                variant="outlined"
                style={{ border: isSelected ? "1px red solid" : "" }}
                className={`mesaIcon ${
                  selectedTable.id_mesa === el.id_mesa && "mesaSelected"
                } `}
                onClick={() => handleSetSelectedTable(el)}
              >
                <Typography component={"h6"} variant={"h6"}>
                  {!el.codigo_mesa ? "Directo" : `Mesa #${el.codigo_mesa}`}
                </Typography>
                <Divider />
                <div className="persons">
                  <div>
                    <People
                      style={{ verticalAlign: "bottom", fontSize: "20px" }}
                    />{" "}
                    {el.cantidad_mesa} max.
                  </div>
                </div>
              </Paper>
            </Grid>
          )
        );
        // }

        // return (
        //   <Grid key={idx} item xs={12} sm={4} md={3} lg={3} zeroMinWidth>
        //     <Paper
        //       variant="outlined"
        //       style={{ border: isSelected ? "1px red solid" : "" }}
        //       className={`mesaIcon ${
        //         selectedTable.id_mesa === el.id_mesa && "mesaSelected"
        //       } `}
        //       onClick={() => handleSetSelectedTable(el)}
        //     >
        //       <Typography component={"h6"} variant={"h6"}>
        //         Mesa #{el.codigo_mesa}
        //       </Typography>
        //       <Divider />
        //       <div className="persons">
        //         <div>
        //           <People
        //             style={{ verticalAlign: "bottom", fontSize: "20px" }}
        //           />{" "}
        //           {el.cantidad_mesa} max.
        //         </div>
        //       </div>
        //     </Paper>
        //   </Grid>
        // );
      })}
    </Grid>
  );
};

const DialogAssignTable = (props) => {
  let { open, setOpen, modeCrud, refreshList, dataMesasGarzon } = props;
  let { dataTable } = props.dataTable;

  const [user, setUser] = React.useState([]);

  let { selectedTable, setSelectedTable } = props.selected.table;
  let { selectedUser, setSelectedUser } = props.selected.user;

  const [loadPage, setLoadPage] = React.useState(false);

  const { handleSubmit } = useForm({
    defaultValues: {},
  });

  const handleSetSelectedTable = (element) => {
    const filterTable = selectedTable.findIndex(
      (el) => el.id_mesa === element.id_mesa
    );

    if (filterTable >= 0) {
      const newSelectedTable = selectedTable.filter(
        (el, idx) => idx !== filterTable
      );

      setSelectedTable(newSelectedTable);

      return;
    }

    const newSelectedTable = [...selectedTable, element];

    setSelectedTable(newSelectedTable);
  };

  const handleLoad = (value) => {
    setLoadPage(value);
  };

  const handleSubmitDialog = async () => {
    if (selectedTable.length === 0) {
      toast.error("Es necesario seleccionar mesas.");
      return;
    }

    if (selectedUser === "") {
      toast.error(`Es necesario seleccionar un ${nameCamarero()}.`);
      return;
    }

    try {
      handleLoad(true);

      const newSelectedTable = selectedTable.map((el) => ({
        id_mesa: el.id_mesa,
      }));

      const input = {
        usu_id: selectedUser,
        mesas: newSelectedTable,
      };

      await add_mesa_garzon(input);

      setSelectedTable([]);
      setSelectedUser("");

      toast.success("Mesas asignadas correctamente.");
      handleClose();
      handleLoad(false);

      await refreshList();
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeUser = async (element) => {
    const { value } = element.target;
    setSelectedUser(value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    const handleGetUser = async () => {
      try {
        const response = await get_user_today();

        let formatUser;

        if (modeCrud === "add") {
          formatUser = response
            .map((el) => {
              const existUser = dataMesasGarzon.some(
                (elMG) => elMG.usu_id === el.usu_id
              );

              if (!existUser) {
                return {
                  id: el.usu_id,
                  label: el.usu_nombre,
                };
              }

              return null;
            })
            .filter(Boolean);
        } else {
          formatUser = response
            .map((el) => {
              if (selectedUser === el.usu_id) {
                return {
                  id: el.usu_id,
                  label: el.usu_nombre,
                };
              }

              return null;
            })
            .filter(Boolean);
        }

        setUser(formatUser);
      } catch (err) {
        console.log(err);
      }
    };

    if (open) {
      handleGetUser();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // React.useEffect(() => {
  //   if (open) {
  //     setSelectedTable([]);
  //     setSelectedUser("");
  //   }
  // }, [open]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth
      onClose={handleClose}
      maxWidth={"sm"}
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <LoadingPage open={loadPage} setOpen={setLoadPage} />
      <form onSubmit={handleSubmit(handleSubmitDialog)} autoComplete="off">
        <DialogTitle id="alert-dialog-title">
          {modeCrud === "add" ? "Asignar mesas" : "Editar mesas"}
        </DialogTitle>
        <DialogContent>
          <br />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                variant="outlined"
                value={selectedUser}
                onChange={handleChangeUser}
                className={`InputDefault`}
                SelectProps={{
                  native: true,
                }}
              >
                <option value="">[{nameCamarero()}]</option>
                {user.map((option, idx) => (
                  <option key={idx} value={option.id}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <TableMesas
            modeCrud={modeCrud}
            data={dataTable}
            selectedTable={selectedTable}
            handleSetSelectedTable={handleSetSelectedTable}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="text" color="inherit">
            Cerrar
          </Button>
          <Button type="submit" variant="contained" color="success">
            Confirmar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogAssignTable;
