/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import TemplateLogin from "../../components/TemplateLogin";

import FormularioReservar from "./Formulario_reserva";

import { Typography } from "@mui/material";
import { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import NotFound from "../../atomic/organisms/NotFound.organisms";
import Head from "../../components/Head";
import LoadingPage from "../../components/LoadingPage";
import useEmpresa from "../../hooks/useEmpresa";
import { save_counter } from "../../services/geolocation.services";

function removeSpacesAndLowerCase(text) {
  if (text) {
    // Remove spaces
    const textWithoutSpaces = text.replace(/\s/g, "");

    // Convert to lowercase
    return textWithoutSpaces.toLowerCase();
  }
}

const Html = (props) => {
  const { title } = props;
  return (
    <div className="carta">
      <Toaster />
      <Head>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content="POS" />
        <meta property="og:title" content={title} />
      </Head>
      <div className="container-carta">{props.children}</div>
    </div>
  );
};

const Add_reserva = () => {
  let history = useHistory();

  let { sucursal } = useParams();

  const dataEmpresa = useEmpresa(sucursal, "alias");

  const logo = dataEmpresa.data?.logo_emp || "/";
  const logoSplit = logo.split("/");
  const logoLength = Number(logoSplit.length) - 1;
  const logoName = logoSplit[logoLength];

  const handleTerminar = (estado, pax) => {
    history.push(
      `/reserva_final/${removeSpacesAndLowerCase(dataEmpresa?.data?.nom_emp)}/${
        dataEmpresa?.data?.alias_empsede
      }/${estado}/${pax}`
    );
  };

  const handleSaveCpunter = async (link, type, item) => {
    try {
      const data = {
        id_emp: item?.id_emp,
        id_empsede: item?.id_empsede,
        type,
      };

      await save_counter(data);
    } catch (err) {}

    // redirect to link with target _blank
    // window.open(link, "_blank");
  };

  
  useEffect(() => {
    if (dataEmpresa.data) {
      handleSaveCpunter(
        dataEmpresa.data.link_emp,
        "RESERVAS",
        dataEmpresa.data
      );
    }
  }, [dataEmpresa.data]);

  if (dataEmpresa.load === true) {
    return (
      <Html title={"Reservar"}>
        <LoadingPage open={true} />
      </Html>
    );
  }

  if (!dataEmpresa.data) {
    return (
      <Html title={"Reservar"}>
        <div
          style={{
            textAlign: "center",
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
            display: "flex",
            height: "100%",
            alignItems: "center",
          }}
        >
          <div style={{ margin: "auto" }}>
            <NotFound title="No se encontró la carta" />
          </div>
        </div>
      </Html>
    );
  }

  return (
    <TemplateLogin
      title="Reservar"
      styleBody={{
        display: "block",
        height: "auto",
        paddingTop: "30px",
        paddingBottom: "30px",
      }}
    >
      <img
        className="Logo_Login"
        src={require(`../../../src/assets/images/logos/${logoName}`)}
        alt="Logo"
        style={{ marginBottom: 20, width: 150 }}
      />
      <Typography variant="h6" className="Texto_Login">
        Reservar en {dataEmpresa.data.nom_emp}
      </Typography>

      <div className="Inputs_Container">
        <FormularioReservar
          finished={handleTerminar}
          client={true}
          id_empsede={dataEmpresa?.data?.id_empsede}
        />
      </div>
    </TemplateLogin>
  );
};

export default Add_reserva;
