/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import Dialog from "@mui/material/Dialog";

import { Close } from "@mui/icons-material";
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography
} from "@mui/material";

import Insumos from "../../producto";

const DialogFoodInputs = ({ open, setOpen }) => {
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
        <AppBar sx={{ position: "sticky", top: 0 }} className="AppHeader">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpen(false)}
              aria-label="close"
            >
              <Close />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Mantenedor de Insumos
            </Typography>
          </Toolbar>
        </AppBar>
        <div className="p-6">
          <Insumos />
        </div>
      </Dialog>
    </>
  );
};

export default DialogFoodInputs;
