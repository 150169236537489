import { Grid } from "@mui/material";
import { forwardRef, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import InputDefault from "../../atomic/atoms/input.atoms";
import { useAppContext } from "./state.benefit";

const FormBenefit = forwardRef((props, ref) => {
  const state = useAppContext();

  const { control, handleSubmit, reset } = useForm({
    defaultValues: props.defaultValues,
  });

  const dataTypeBenefit = state.dataTypeBenefit.data;
  const loadTypeBenefit = state.dataTypeBenefit.loading;

  const newDataTypeBenefit = dataTypeBenefit.map((item) => {
    return {
      value: item.id_typebenefit,
      label: item.name_typebenefit,
    };
  });

  const onSubmit = (data) => {
    // action to do with data from form
    props.action(data);

    // reset form
    reset(props.defaultValues);
  };

  useEffect(() => {
    reset(props.defaultValues);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultValues]);

  return (
    <form ref={ref} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                required
                fullWidth
                value={value}
                onChange={onChange}
                type="text"
                title={"Buscar por nombre"}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="startDate"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                required
                fullWidth
                value={value}
                onChange={onChange}
                type="date"
                title={"Fecha de inicio"}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="endDate"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                required
                fullWidth
                value={value}
                onChange={onChange}
                type="date"
                title={"Fecha de finalización"}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="idTypeBenefit"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                required
                loading={loadTypeBenefit}
                fullWidth
                value={value}
                onChange={onChange}
                type="select"
                title={"Tipo de beneficio"}
                options={newDataTypeBenefit}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="typeValue"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                required
                fullWidth
                value={value}
                onChange={onChange}
                type="select"
                title={"Tipo de valor"}
                options={[
                  {
                    value: "percent",
                    label: "Porcentaje",
                  },
                  {
                    value: "money",
                    label: "Dinero",
                  },
                ]}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="value"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                required
                fullWidth
                value={value}
                onChange={onChange}
                type="number"
                title={"Valor"}
              />
            )}
          />
        </Grid>
      </Grid>
      <input type="submit" hidden />
    </form>
  );
});

export default FormBenefit;
