const useReservas = () => {
  const fechaInicioDeMes = () => {
    const fechaInicio = new Date();
    return new Date(fechaInicio.getFullYear(), fechaInicio.getMonth(), 1);
  };

  const obtenerFechaFinDeMes = () => {
    const fechaFin = new Date();
    // Iniciar en este año, el siguiente mes, en el día 0 (así que así nos regresamos un día)
    return new Date(fechaFin.getFullYear(), fechaFin.getMonth() + 1, 0);
  };

  const formatearFecha = (fecha) => {
    const mes = fecha.getMonth() + 1;
    const dia = fecha.getDate();
    return `${fecha.getFullYear()}-${(mes < 10 ? "0" : "").concat(mes)}-${(dia <
    10
      ? "0"
      : ""
    ).concat(dia)}`;
  };

  // return {
  //   fechaInicio: getUser().id_perfil === 7 ? fechaActual() : fechaInicioDeMes(),
  //   fechaFin:
  //     getUser().id_perfil === 7 ? fechaActual() : obtenerFechaFinDeMes(),
  //   formatearFecha,
  // };

  return {
    fechaInicio: fechaInicioDeMes(),
    fechaFin: obtenerFechaFinDeMes(),
    formatearFecha,
  };
};

export default useReservas;
