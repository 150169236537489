import { Alert, Button, InputAdornment } from "@mui/material";

import { Controller, useForm } from "react-hook-form";
import Logo from "../../../assets/images/logo.png";
import InputDefault from "../../../atomic/atoms/input.atoms";
import { formatPhoneNumber } from "../../../functions/formatPhoneNumber";
import { useAppContext } from "../state.myreservations";
import LoadingPage from "../../../components/LoadingPage";

const LoginMyReservations = () => {
  const state = useAppContext();

  const { control, watch, handleSubmit } = useForm({
    defaultValues: {
      numberPhone: "",
    },
  });

  if (state.loadingLogin) {
    return (
      <div style={{ padding: 50 }}>
        <LoadingPage open={true} />
      </div>
    );
  }

  return (
    <div>
      <img
        className="Logo_Login"
        src={Logo}
        alt="Logo"
        style={{ marginBottom: 20, width: 150 }}
      />

      {state.numberNotFound && (
        <Alert severity="warning" style={{ marginBottom: 20 }}>
          No se encontró reservas con el número de teléfono ingresado.
        </Alert>
      )}

      <form
        className="FormMyReservation"
        onSubmit={handleSubmit(state.onLogin)}
      >
        <div className="FormMyReservation__title">Mis reservas</div>
        <div className="FormMyReservation__subtitle">
          Para poder ver tus reservas debes iniciar sesión con tu número
          teléfonico.
        </div>
        <div className="FormMyReservation__input">
          <Controller
            name="numberPhone"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                inputProps={{
                  maxLength: 9,
                  minLength: 9,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {formatPhoneNumber()}
                    </InputAdornment>
                  ),
                }}
                required
                value={value}
                onChange={onChange}
                type="tel"
                title="Teléfono"
                onInput={(e) => {
                  parseInt(e.target.value) > 0
                    ? (e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 9))
                    : parseInt(e.target.value);
                }}
                min={9}
              />
            )}
          />
        </div>
        <div className="FormMyReservation__input">
          <Button className="button-default" type="submit">
            Iniciar
          </Button>
        </div>
      </form>
    </div>
  );
};

export default LoginMyReservations;
