import React, { useState } from "react";

import { useHistory } from "react-router-dom";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import { ShoppingCart } from "@mui/icons-material";
import { useForm } from "react-hook-form";

import toast from "react-hot-toast";

import { Controller } from "react-hook-form";

import Input from "../../../components/Forms/Input";
import LoadingPage from "../../../components/LoadingPage";

import { get_pedido_id } from "../../../services/pedidos.services";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/*
    MAIN COMPONENT
*/

const SearchPedido = (props) => {
  let { open, setOpen } = props;

  let history = useHistory();

  const [loadPage, setLoadPage] = useState(false);

  let initialValues = {
    codigo_pedido: "",
  };

  const { control, handleSubmit } = useForm({
    defaultValues: initialValues,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleCheckPedido = async (data) => {
    handleClose();

    try {
      setLoadPage(true);
      let response = await get_pedido_id(data.codigo_pedido);
      setLoadPage(false);

      if (response === "") {
        toast.error("El código que ingresaste no existe.");
        return null;
      }

      history.push(`/carta/pedido/${data.codigo_pedido}`);
    } catch (err) {
      setLoadPage(false);
      console.log(err);
    }
  };

  return (
    <Dialog
      fullWidth
      open={open}
      TransitionComponent={Transition}
      maxWidth={"sm"}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <LoadingPage open={loadPage} setOpen={setLoadPage} />
      <form onSubmit={handleSubmit(handleCheckPedido)} autoComplete="off">
        <DialogTitle style={{ borderBottom: "1px solid #cfcfcf" }}>
          Buscar pedido
        </DialogTitle>
        <DialogContent className="dialogContainer">
          <br />
          <Controller
            name="codigo_pedido"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                required
                value={value}
                onChange={onChange}
                type="text"
                title="Código de pedido"
              />
            )}
          />
        </DialogContent>

        <DialogActions style={{ borderTop: "1px solid #cfcfcf" }}>
          <Button color="inherit" variant={"text"} onClick={handleClose}>
            Salir
          </Button>
          <Button color={"secondary"} variant={"contained"} type="submit">
            Aceptar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SearchPedido;
