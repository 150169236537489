import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Moment from "react-moment";
import LoadingPage from "../../../components/LoadingPage";
import { formatMoney } from "../../../functions/formatMoney";
import { useAppContext } from "../state.myreservations";

const toUpperCaseFilter = (d) => {
  if (!d) {
    return d;
  }

  if (d === "") {
    return d;
  }

  return d.toUpperCase();
};

const TableMyReservations = () => {
  const { data, loading } = useAppContext();

  const rows = data;

  if (loading) {
    return <LoadingPage open={true} />;
  }

  return (
    <>
      <TableContainer sx={{ maxHeight: 550 }}>
        <Table stickyHeader aria-label="sticky table" size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell align={"left"} style={{ minWidth: 50 }}>
                Código
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 180 }}>
                Empresa
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 130 }}>
                Fecha reserva
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 100 }}>
                Estado
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 130 }}>
                Monto reserva
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 80 }}>
                PAX
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 100 }}>
                Programa
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, idx) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                <TableCell align={"left"}>#{row.id_reserva}</TableCell>
                <TableCell align={"left"}>
                  {row.nom_emp} - {row.nom_empsede}
                </TableCell>
                <TableCell align={"left"}>
                  <Moment filter={toUpperCaseFilter} format="DD/MMM/YYYY">
                    {/* {formatDate(row.fechareserva_reserva)} */}
                    {row.fechareserva_reserva}
                  </Moment>
                </TableCell>
                <TableCell align={"left"}>
                  {row.estado_reserva === "cancelado" ? (
                    <Chip
                      label={row.estado_reserva.toUpperCase()}
                      color="error"
                    />
                  ) : row.estado_reserva === "confirmado" ||
                    row.estado_reserva === "re confirmado" ? (
                    <Chip
                      label={row.estado_reserva.toUpperCase()}
                      color="success"
                    />
                  ) : row.estado_reserva === "en espera" ? (
                    <Chip
                      label={row.estado_reserva.toUpperCase()}
                      color="info"
                    />
                  ) : (
                    <Chip
                      label={row.estado_reserva.toUpperCase()}
                      color="warning"
                    />
                  )}
                </TableCell>
                <TableCell align={"left"}>
                  {formatMoney(row.monto_reserva ? row.monto_reserva : 0)}
                </TableCell>
                <TableCell align={"left"}>{row.pax_reserva}</TableCell>
                <TableCell
                  align={"left"}
                  style={{ textTransform: "capitalize" }}
                >
                  {row.pograma_reserva}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TableMyReservations;
