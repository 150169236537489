/* eslint-disable no-unused-vars */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import toast, { Toaster } from "react-hot-toast";
import QRCode from "react-qr-code";
import { getUser } from "../../services/auth.services";
import useEmpresa from "../../hooks/useEmpresa";
import { getUrlFront } from "../../functions/utilidades.function";

function downloadBlob(blob, filename) {
  const objectUrl = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = objectUrl;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  setTimeout(() => URL.revokeObjectURL(objectUrl), 5000);
}

const DialogViewQRCode = ({ open, setOpen, data, handleGetMesas }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const dataEmpresa = useEmpresa(getUser().empresa.id_empsede, "id");

  const url = getUrlFront();

  const valueLink = `${url}/carta/${dataEmpresa.data.alias_empsede}?sede=${
    getUser().empresa.id_empsede
  }&mesa=${data.id_mesa}`;

  const svgRef = React.useRef();

  const downloadSVG = React.useCallback((codigo_mesa) => {
    const svg = svgRef.current.innerHTML;
    const blob = new Blob([svg], { type: "image/svg+xml" });
    downloadBlob(blob, `qr_mesa_${codigo_mesa}.svg`);
  }, []);

  return (
    <>
      <Dialog
        fullWidth
        open={open}
        maxWidth={"sm"}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Mesa #{data.codigo_mesa}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div
              style={{
                height: "auto",
                margin: "0 auto",
                maxWidth: 500,
                width: "100%",
              }}
              ref={svgRef}
            >
              <QRCode
                size={250}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={valueLink}
                viewBox={`0 0 256 256`}
              />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => downloadSVG(data.codigo_mesa)}
            variant="contained"
            color="secondary"
          >
            Descargar
          </Button>

          <Button onClick={handleClose} variant="text" color="inherit">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogViewQRCode;
