import { formatMoney } from "./formatMoney";

export function signalMoney(tipeDiscount, discount) {
  let signal = "";

  if (tipeDiscount === "PORCENTAJE") {
    signal = `${discount}%`;
  } else if (tipeDiscount === "COVER") {
    signal = formatMoney(discount);
  } else if (tipeDiscount === "PRECIO") {
    signal = formatMoney(discount);
  }

  return signal;
}
