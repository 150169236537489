import { actionTypes } from "../actions/index";

const initialState = {
  loading: false,
  error: false,
  dataCategoria: { load: false, err: false, data: [] },
  dataSelected: {
    cat_id: "",
    cat_nombre: "",
  },
  openModalCRUD: false,
  openModalDELETE: false,
};

const reducerObject = (state, payload) => ({
  [actionTypes.getDataCategoria]: {
    ...state,
    dataCategoria: payload,
  },
  [actionTypes.selectedDataCategoriaEdit]: {
    ...state,
    dataSelected: payload,
    openModalCRUD: true,
  },
  [actionTypes.selectedDataCategoriaDelete]: {
    ...state,
    dataSelected: payload,
    openModalDELETE: true,
  },
  [actionTypes.updateDialogEditCategoria]: {
    ...state,
    openModalCRUD: payload,
    dataSelected: { cat_id: "", cat_nombre: "" },
  },
  [actionTypes.updateDialogDelete]: {
    ...state,
    openModalDELETE: payload,
    dataSelected: { cat_id: "", cat_nombre: "" },
  },
  [actionTypes.updateLoad]: {
    ...state,
    loading: payload,
  },
});

export const reducer_categoria = (state = initialState, action) => {
  if (reducerObject(state)[action.type]) {
    return reducerObject(state, action.payload)[action.type];
  } else {
    return state;
  }
};