/* eslint-disable react-hooks/exhaustive-deps */

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import { Grid, Tab, Tabs } from "@mui/material/";
import Input from "../../components/Forms/Input";

import { useForm } from "react-hook-form";

import { Close } from "@mui/icons-material";

import toast from "react-hot-toast";

import {
  upload_reserva,
  update_reserva_form,
} from "../../services/reservas.services";

import { get_mesa_x_day } from "../../services/mesas.services";

import FormularioReservar from "./Formulario_reserva";

import { getUser } from "../../services/auth.services";
import FormTask from "../tasks/ui/form.task";

const DialogViewDataEditar = ({
  open,
  setOpen,
  data,
  handleChangeStatus,
  handleReload,
}) => {

  const [valueTabs, setValueTabs] = React.useState(0);

  const [fileUpload, setFileUpload] = React.useState("");
  const [montoUpload, setMontoUpload] = React.useState("");
  const [imagen_reserva, setImagen_reserva] = React.useState("");

  const [dataMesas, setDataMesas] = React.useState([]);

  const { Form, submitForm, loading } = FormTask({
    callBack: () => {
      setOpen(false);
    },
    initTask: undefined,
  });

  let initialValues = {
    id_mesa: "",
  };

  const { reset } = useForm({
    defaultValues: initialValues,
  });

  const [load, setLoad] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValueTabs(newValue);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async (e) => {
    e.preventDefault();

    try {
      let formData = new FormData();

      formData.append("imagen_reserva", fileUpload);
      formData.append("monto_reserva", montoUpload);
      formData.append("id_reserva", data.id_reserva);

      setLoad(true);

      const response = await upload_reserva(formData);
      setImagen_reserva(response.url);

      setLoad(false);
      await handleChangeStatus(data.id_reserva, "confirmado");

      handleClose();
      handleReload && handleReload();
    } catch (err) {
      setLoad(false);
      console.log(err);
    }
  };

  const handleUpdate = async (data) => {
    try {
      let response = await update_reserva_form(data);

      if (response) {
        toast.success("Reserva modificada correctamente");
        setOpen(false);
        reset(initialValues);
        handleReload && handleReload();
      } else {
        toast.error("No se pudo modificar la reserva");
        setOpen(false);
        reset(initialValues);
      }
    } catch (err) {
      console.log(err.message);
      toast.error("Hubo un problema al modificar la reserva");
      setOpen(false);
    }
  };

  // OBTAIN DATA

  const obtain_mesa = async () => {
    try {
      let response = await get_mesa_x_day({ fecha: data.fechareserva_reserva });

      //quita las mesas que ya estan en uso pero si le pertenece a la reserva se queda en la lista
      let newReponse = response.filter(
        (x) => !x.existe_mesa || x.id_mesa === data.id_mesa
      );

      setDataMesas(
        newReponse.map((item) => ({
          value: item.id_mesa,
          label: `#${item.codigo_mesa} - (Capacidad mesa ${item.cantidad_mesa})`,
        }))
      );
    } catch (err) {
      setLoad(false);
      console.log(err.message);
    }
  };

  React.useEffect(async () => {
    if (open) {
      setLoad(true);
      setImagen_reserva(data.imagen_reserva);
      setMontoUpload(data.monto_reserva);

      await obtain_mesa();

      reset(data);
      setLoad(false);
    }
  }, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>
          Editar la reserva
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Tabs value={valueTabs} onChange={handleChange} centered>
            <Tab label="Datos" />
            <Tab label="Abono" />
            <Tab label="Agendar" />
          </Tabs>
          <br />

          {valueTabs === 0 && (
            <FormularioReservar
              finished={handleUpdate}
              initialValues={data}
              id_usuario={getUser().id}
              id_empsede={getUser()?.empresa?.id_empsede}
              insert={false}
            />
          )}
          {valueTabs === 1 &&
            (load ? (
              <h2
                style={{
                  paddingTop: 20,
                  paddingBottom: 20,
                  textAlign: "center",
                }}
              >
                Cargando...
              </h2>
            ) : (
              <form onSubmit={handleSave} autoComplete="off">
                <Grid container spacing={2}>
                  {/* {imagen_reserva !== null ? (
                    <Grid item xs={12} sm={12} lg={12}>
                      <iframe
                        style={{ width: "100%", height: "300px" }}
                        title="iframe"
                        src={`${imagen_reserva}`}
                      ></iframe>
                    </Grid>
                  ) : null}
                  <Grid item xs={12} sm={12} lg={12}>
                    <Input
                      name="imagen_reserva"
                      onChange={(e) => setFileUpload(e.target.files[0])}
                      type="file"
                    />
                  </Grid> */}
                  <Grid item xs={12} sm={12} lg={12}>
                    <Input
                      name="monto_reserva"
                      value={montoUpload}
                      onChange={(e) => setMontoUpload(e.target.value)}
                      type="number"
                      title="Monto"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <Button
                      fullWidth
                      color="info"
                      variant="contained"
                      type="submit"
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            ))}

          {valueTabs === 2 && (
            <>
              <Form />
              <div className="w-full flex justify-end">
                <button
                  disabled={loading}
                  type="button"
                  onClick={submitForm}
                  className="bg-blue-900 rounded-md p-2 text-white text-sm hover:bg-blue-800 transition duration-300 ease-in-out w-28"
                >
                  Aceptar
                </button>
              </div>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DialogViewDataEditar;
