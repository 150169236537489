import { Grid, Paper, Skeleton } from "@mui/material";
import NotFound from "../../../atomic/organisms/NotFound.organisms";

const CategoryProductReserva = (props) => {
  const { data, err, load } = props.data;

  const { handleSelectedCategory, categorySelected } = props;

  if (load) {
    return (
      <Grid container spacing={1} style={{ marginTop: 10 }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Skeleton animation="wave" height={80} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Skeleton animation="wave" height={80} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Skeleton animation="wave" height={80} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Skeleton animation="wave" height={80} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Skeleton animation="wave" height={80} />
        </Grid>
      </Grid>
    );
  }

  if (err) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <NotFound title="¡Ocurrió un problema!" />
      </div>
    );
  }

  if (data.length === 0 && !load) {
    return <NotFound texto="No se encontró registros." />;
  }

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={1}
      style={{ marginTop: 10 }}
    >
      <Grid item xs={12} sm={12} md={12} lg={12} zeroMinWidth>
        Categorías
      </Grid>
      {data.map((el, idx) => (
        <Grid key={idx} item xs={12} sm={12} md={12} lg={12} zeroMinWidth>
          <Paper
            className="cntCategory"
            variant="outlined"
            style={{
              backgroundColor:
                categorySelected === el.id_categoriapla ? "#e5e5e5" : "#fff",
              color: categorySelected === el.id_categoriapla ? "#000" : "#000",
            }}
            onClick={() => handleSelectedCategory(el.id_categoriapla)}
          >
            {el.nombre_categoriapla}
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default CategoryProductReserva;
