import { Controller, useForm } from "react-hook-form";
import InputDefault from "../../../atomic/atoms/input.atoms";
import { useEffect, useState } from "react";
import { addTask, updateTask } from "../../../services/task.services";
import toast from "react-hot-toast";

const initialValues = {
  id_task: "",
  name_task: "",
  description_task: "",
  date_task: "",
  status_task: "todo",
  type_task: "event",
};

const FormTask = ({ callBack, initTask }) => {
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, watch, reset } = useForm({
    defaultValues: initialValues,
  });

  const isEdit = watch("id_task") !== "";
  const typeDateInput =
    watch("type_task") === "event" ? "datetime-local" : "date";

  const handleAdd = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        await addTask(data);
        resolve("Success");
      } catch (err) {
        reject(new Error("Error"));
      }
    });
  };

  const handleUpdate = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        await updateTask(data.id_task, data);
        resolve("Success");
      } catch (err) {
        reject(new Error("Error"));
      }
    });
  };

  const onSubmit = (data) => {
    setLoading(true);

    const handleAction = isEdit ? handleUpdate : handleAdd;

    const SUCCESS_MESSAGE = isEdit
      ? "Se editó la agenda con éxito"
      : "Se agregó la agenda con éxito";

    toast.promise(handleAction(data), {
      loading: "Cargando...",
      success: () => {
        callBack && callBack();
        setLoading(false);
        return SUCCESS_MESSAGE;
      },
      error: (err) => {
        return "Ops! Ocurrió un error al crear la agenda";
      },
    });
  };

  const submitForm = () => {
    const submit = document.querySelector("#btnSubmitForm[type='submit']");
    submit.click();
  };

  const Form = () => (
    <form id="formTask" onSubmit={handleSubmit(onSubmit)} className="w-full">
      <div className="grid grid-cols-12 gap-3 pt-4">
        <div className="col-span-6">
          <Controller
            name="name_task"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                required
                value={value}
                onChange={onChange}
                type="text"
                title="Nombre"
              />
            )}
          />
        </div>
        <div className="col-span-6">
          <Controller
            name="type_task"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                required
                fullWidth
                value={value}
                onChange={onChange}
                type="select"
                title={"Tipo"}
                options={[
                  {
                    value: "task",
                    label: "Tarea",
                  },
                  {
                    value: "event",
                    label: "Evento",
                  },
                ]}
              />
            )}
          />
        </div>
        <div className="col-span-12">
          <Controller
            name="description_task"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                required
                value={value}
                onChange={onChange}
                type="textarea"
                title="Descripción"
                minRows={3}
              />
            )}
          />
        </div>
        <div className="col-span-12">
          <Controller
            name="date_task"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                required
                value={value}
                onChange={onChange}
                type={typeDateInput}
              />
            )}
          />
        </div>
      </div>
      <button
        id="btnSubmitForm"
        disabled={loading}
        type="submit"
        className="none"
      />
    </form>
  );

  useEffect(() => {
    reset(initTask || initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initTask]);

  return { Form, submitForm, loading };
};

export default FormTask;
