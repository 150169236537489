/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import * as React from "react";

import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import { Grid } from "@mui/material/";

import { Alert, Collapse } from "@mui/material";

import Input from "../../../../components/Forms/Input";

import { Controller, useForm } from "react-hook-form";

import { Close } from "@mui/icons-material";

import { actionTypes } from "../../../../redux/marca/actions";

import toast from "react-hot-toast";

import { useDispatch, useSelector } from "react-redux";

import { add_marca, update_marca } from "../../../../services/marca.services";

const ErrorMsg = ({ wrong, setWrong, message }) => {
  return (
    <Collapse in={wrong}>
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setWrong(false);
            }}
          >
            <Close fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
        severity="error"
      >
        {message}
      </Alert>
    </Collapse>
  );
};

const DialogCrud = () => {
  const dispatch = useDispatch();
  const { dataSelected, openModalCRUD } = useSelector(
    (state) => state.reducer_marca
  );

  const { control, reset, handleSubmit } = useForm({
    defaultValues: dataSelected,
  });

  const [wrong, setWrong] = React.useState({ open: false, message: "" });

  const onOpen = (data) =>
    dispatch({
      type: actionTypes.updateDialogEdit,
      payload: data,
    });

  const onLoad = (data) =>
    dispatch({
      type: actionTypes.updateLoad,
      payload: data,
    });

  const handleModal = (value) => {
    onOpen(value);
  };

  // const toUpperCaseFilter = (d) => {
  //   if (!d) {
  //     return d;
  //   }

  //   if (d === "") {
  //     return d;
  //   }

  //   return d.toUpperCase();
  // };

  const handleAdd = async (data) => {
    try {
      let response;
      onLoad(true);
      if (data.marc_id) {
        response = await update_marca(data, data.id_garzones);
        if (response.affectedRows === 1) {
          toast.success("Marca modificado correctamente");
        } else {
          toast.error("No se pudo modificar la marca");
        }
      } else {
        response = await add_marca(data);
        if (response.affectedRows === 1) {
          toast.success("Marca creada correctamente");
        } else {
          toast.error("No se pudo crear la marca");
        }
      }

      onLoad(false);

      handleModal(false);
    } catch (err) {
      console.error(err);
      toast.error("Hubo un error al crear la marca");
      onLoad(false);
    }
  };

  React.useEffect(() => {
    if (openModalCRUD) {
      reset(dataSelected);
    }
  }, [openModalCRUD]);

  return (
    <>
      <Dialog
        open={openModalCRUD}
        onClose={() => handleModal(false)}
        fullWidth
        maxWidth={"sm"}
      >
        <form onSubmit={handleSubmit(handleAdd)} autoComplete="off">
          <DialogTitle>
            {dataSelected.marc_id ? "Editar" : "Agregar"}
            <IconButton
              aria-label="close"
              onClick={() => handleModal(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <br />
            <ErrorMsg
              wrong={wrong.open}
              setWrong={(el) => setWrong({ ...wrong, open: el })}
              message={wrong.message}
            />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} lg={12}>
                <Controller
                  name="marc_nombre"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      required
                      value={value}
                      onChange={onChange}
                      type="text"
                      title="Nombre"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="info"
              variant="text"
              onClick={() => {
                handleModal(false);
              }}
            >
              Salir
            </Button>
            <Button type="submit" color="success" variant="contained">
              Aceptar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default DialogCrud;
