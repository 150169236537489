import * as React from "react";

import {
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

import { Delete, Edit, Settings } from "@mui/icons-material";

import NotFound from "../../atomic/organisms/NotFound.organisms";
import { nameCamarero } from "../../functions/nameCamarero";
import { updateActive } from "../../services/auth.services";

// import Moment from "react-moment";
// import "moment/locale/es";

const TableUser = ({
  rows,
  setRow,
  handleViewData,
  handleViewDataEliminar,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleActive = async (value) => {
    const idx = rows.findIndex((el) => el.usu_id === value.usu_id);

    rows[idx] = { ...rows[idx], usu_active: value.usu_active === 1 ? 0 : 1 };

    const data = {
      usu_id: rows[idx].usu_id,
      usu_active: rows[idx].usu_active,
    };

    setRow(rows);

    await updateActive(data);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (rows.length === 0) {
    return <NotFound texto="No se encontró registros." />;
  }

  return (
    <>
      <TableContainer sx={{ maxHeight: 550 }}>
        <Table stickyHeader aria-label="sticky table" size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell align={"left"} style={{ minWidth: 150 }}>
                Empresa
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 150 }}>
                Sucursal
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 150 }}>
                Nombres
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 190 }}>
                Correo electrónico
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 100 }}>
                Usuario
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 100 }}>
                Tipo
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 100 }}>
                Activar / desactivar
              </TableCell>
              <TableCell
                colSpan={2}
                align={"center"}
                style={{ minWidth: 100, fontSize: 21, color: "#5c5c5c" }}
              >
                <Settings />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                    <TableCell align={"left"}>{row?.nom_emp}</TableCell>
                    <TableCell align={"left"}>{row?.nom_empsede}</TableCell>
                    <TableCell align={"left"} className="uppercase">
                      {row?.usu_nombre}
                    </TableCell>
                    <TableCell align={"left"}>{row.usu_email}</TableCell>
                    <TableCell align={"left"}>{row.usu_username}</TableCell>
                    <TableCell align={"left"}>
                      {row.tipousu_id === 1
                        ? nameCamarero()
                        : row.tipousu_nombre}
                    </TableCell>
                    <TableCell align={"left"}>
                      <Checkbox
                        onChange={() => handleActive(row)}
                        checked={row.usu_active === 1 ? true : false}
                      />
                    </TableCell>
                    <TableCell align={"center"}>
                      <IconButton
                        color="warning"
                        onClick={() => handleViewData(row)}
                      >
                        <Edit />
                      </IconButton>
                    </TableCell>
                    <TableCell align={"center"}>
                      <IconButton
                        color="error"
                        onClick={() => handleViewDataEliminar(row)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage="Filas por página"
        rowsPerPageOptions={[10, 25, 100, { value: -1, label: "Todo" }]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default TableUser;
