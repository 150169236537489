import { createContext, useContext, useEffect, useState } from "react";
import {
  fetch_myreservation,
  login_myreservation,
  update_myreservation,
} from "../../services/reservas.services";

import { useHistory, useParams } from "react-router-dom";

const AppContext = createContext({
  /* Values */
  data: [],
  numberNotFound: false,
  loadingLogin: false,
  loading: false,
  status: "",
  /* Functions */
  onLogin: () => {},
  onFetchData: () => {},
});

export default function MyReservationState({ children }) {
  const history = useHistory();
  const [data, setData] = useState([]);

  let { id, id_reserva, status } = useParams();

  const [loading, setLoading] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [numberNotFound, setNumberNotFound] = useState(false);

  const onLogin = async (data) => {
    setNumberNotFound(false);

    try {
      setLoadingLogin(true);
      const response = await login_myreservation(data);
      setLoadingLogin(false);

      if (response?.id_cliente || response?.id_cliente > 0) {
        setNumberNotFound(false);
        history.push("/my-reservations/" + response?.id_cliente);
        return;
      }

      setNumberNotFound(true);
    } catch (err) {}
  };

  const onFetchData = async (id) => {
    try {
      setLoading(true);
      const response = await fetch_myreservation(id);
      setLoading(false);

      setData(response);
    } catch (err) {}
  };

  // const onFetchDataReserva = async (id) => {
  //   try {
  //     setLoading(true);
  //     const response = await fetch_myreservation_by_reserva(id);
  //     setLoading(false);

  //     setDataReserva(response);
  //   } catch (err) {}
  // };

  const onUpdateStatusReserva = async (id, status) => {
    try {
      setLoading(true);
      const newStatus = status === "confirm" ? "confirmado" : "cancelado";
      await update_myreservation(id, newStatus);
      setLoading(false);
    } catch (err) {}
  };

  useEffect(() => {
    if (id) {
      onFetchData(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id_reserva) {
      onUpdateStatusReserva(id_reserva, status);
    }
  }, [id_reserva]);

  // useEffect(() => {
  //   if (id_reserva) {
  //     onFetchDataReserva(id_reserva);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [id_reserva]);

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <AppContext.Provider
      value={{
        data,
        numberNotFound,
        loading,
        loadingLogin,
        status,
        onLogin,
        onFetchData,
        onUpdateStatusReserva,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
