import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Template from "../../components/Template";

import { checkToken } from "../../services/auth.services";

import DialogViewClientData from "./dialogViewDataClient.clientHistory";
import HistorySearch from "./search.clientHistory";
import ClientHistoryState from "./state.clientHistory";
import HistoryTable from "./table.clientHistory";

const ClientHistory = (props) => {
  let history = useHistory();
  useEffect(() => {
    if (!checkToken()) {
      history.push("/");
      return null;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ClientHistoryState>
      <Template title="Clientes">
        <HistorySearch />
        <HistoryTable />
        <DialogViewClientData />
      </Template>
    </ClientHistoryState>
  );
};

export default ClientHistory;
