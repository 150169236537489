import { S3Client } from "@aws-sdk/client-s3";
import { config } from "../config";

const { doRegion, doSecretKey, doAccessKey, doEndPoint } = config;

export const s3Client = new S3Client({
  endpoint: doEndPoint,
  region: doRegion,
  credentials: { accessKeyId: doAccessKey, secretAccessKey: doSecretKey },
});
