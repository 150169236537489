import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(10),
    border: "1px solid #dadde9",
    marginTop: "-60px",
  },
}));

const CustomTooltipHtml = ({ text, ...props }) => {
  return (
    <div>
      <HtmlTooltip
        title={
          <>
            <Typography color="inherit">{text || ""}</Typography>
          </>
        }
      >
        {props.children}
      </HtmlTooltip>
    </div>
  );
};

export default CustomTooltipHtml;
