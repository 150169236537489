import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useAppContext } from "./state.consumption";

import dayjs from "dayjs";
import "moment/locale/es";
import NotFound from "../../atomic/organisms/NotFound.organisms";
import ContainerInput from "../../components/ContainerInput";

import { formatMoney } from "../../functions/formatMoney";

const CustomTable = ({ rows, isLoad }) => {
  const styleFirstColumn = {
    fontSize: 12,
    position: "sticky",
    left: 0,
    background: "#fafafd",
    borderRight: "1px #c5bbdc solid",
  };

  const styleSecondColumn = {
    fontSize: 12,
    background: "#fafafd",
    borderRight: "1px #c5bbdc solid",
    textAlign: "center",
  };

  if (isLoad) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress color="inherit" /> <br />
        <Typography component="h5" variant="h5" style={{ textAlign: "center" }}>
          Cargando...
        </Typography>
      </div>
    );
  }

  if (rows.length === 0) {
    return <NotFound texto="No se encontró registros." />;
  }

  return (
    <>
      <TableContainer sx={{ maxHeight: 650 }}>
        <Table stickyHeader aria-label="sticky table" size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell align={"left"} style={{ minWidth: 250 }}></TableCell>
              <TableCell align={"left"} style={{ minWidth: 70 }}></TableCell>
              {rows.head.map((row, idx) => (
                <TableCell
                  key={idx}
                  align={"left"}
                  style={{
                    minWidth: 180,
                    fontSize: 12,
                    color: dayjs().format("DD/MM/YYYY") === row && "red",
                  }}
                >
                  {row}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.body.map((row, idx) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                <TableCell align={"left"} style={styleFirstColumn}>
                  {row.nombre_plato}
                </TableCell>
                <TableCell align={"left"} style={styleSecondColumn}>
                  {row.cantidad_plato}
                </TableCell>

                {row.data.map((data, idx) => (
                  <TableCell key={idx} align={"left"} style={{ fontSize: 12 }}>
                    <Grid container>
                      <Grid item xs={12}>
                        Tot. consumo: <b>{data.tot_consumo}</b>
                      </Grid>
                      <Grid item xs={12}>
                        Precio x Consumo:{" "}
                        <b>{formatMoney(data.precioXconsumo)}</b>
                      </Grid>
                      <Grid item xs={12}>
                        Costo x Consumo: <b>{formatMoney(data.costoXconsumo)}</b>
                      </Grid>
                      <Grid item xs={12}>
                        Utilidad: <b>{formatMoney(data.utilidad)}</b>
                      </Grid>
                    </Grid>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const TableViewThree = () => {
  const state = useAppContext();

  return (
    <ContainerInput
      style={{
        marginTop: 20,
        marginBottom: 20,
        padding: "0px",
        textAlign: "center",
        borderRadius: "0px",
      }}
    >
      <CustomTable rows={state.data} isLoad={state.isLoadData} />{" "}
    </ContainerInput>
  );
};

export default TableViewThree;
