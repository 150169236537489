import { Card, CardContent, Chip, Grid } from "@mui/material";
import ListProduct from "./ListProduct";
import "dayjs/locale/es";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import {
  AccessTime,
  NotificationsActive,
  People,
  RoomService,
  TableRestaurant,
} from "@mui/icons-material";

// Agrega el complemento 'utc' a dayjs
dayjs.extend(utc);

const CardProduct = (props) => {
  let { data, handleChangeStatus, disabledButton = false, timeMax } = props;

  // Configura dayjs para usar el idioma español
  dayjs.locale("es");

  const dateActual = dayjs.utc();
  const dateTope = dayjs.utc("2023-08-24");

  const enabledTimeMax = timeMax && timeMax >= 0;
  let timeRegProx = "";

  // se cambio la fecha de registro del pedido por la fecha de creacion del pedido
  const datePedido = dateActual.isAfter(dateTope)
    ? dayjs.utc(data.createdAt)
    : dayjs.utc(data.fechareg_pedido);

  if (enabledTimeMax) {
    timeRegProx = datePedido.add(timeMax, "m").format("HH:mm");
  } else {
    timeRegProx = datePedido.format("HH:mm");
  }

  const timeRefInit = datePedido.format("HH:mm");

  return (
    <Card className="CardItemPedido" style={{ minWidth: "285px" }}>
      <div className="CardHeadPedido">
        <Grid container>
          <Grid item xs={9} style={{ textAlign: "left", padding: 0 }}>
            <div>
              <RoomService style={{ verticalAlign: "middle" }} />
              {data.codigo_pedido}
            </div>
            <div>
              {enabledTimeMax && (
                <Chip
                  icon={<NotificationsActive />}
                  size="small"
                  label={`Tiempo entrega ${timeRegProx}`}
                  variant="outlined"
                />
              )}
            </div>
          </Grid>
          <Grid item xs={3} style={{ textAlign: "right" }}>
            <div>
              {data.codigo_mesa ? (
                <>
                  <TableRestaurant style={{ verticalAlign: "middle" }} />{" "}
                  {data.codigo_mesa}{" "}
                </>
              ) : (
                <div style={{ marginBottom: 5, fontWeight: "bold" }}>
                  Directo
                </div>
              )}
            </div>
            <div style={{ fontSize: 14, fontWeight: 600 }}>
              <Chip
                icon={<AccessTime />}
                size="small"
                label={timeRefInit}
                variant="outlined"
              />
            </div>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "left" }}>
            {data.nombre_cliente && (
              <>
                <People style={{ verticalAlign: "middle" }} />{" "}
                <span style={{ textTransform: "uppercase" }}>
                  {data.nombre_cliente} {data.apepaterno_cliente}
                </span>
              </>
            )}
          </Grid>
        </Grid>
      </div>
      <CardContent style={{ padding: 5 }}>
        <ListProduct
          handleChangeStatus={handleChangeStatus}
          data={data.productos}
          id_pedido={data.codigo_pedido}
          disabledButton={disabledButton}
        />
      </CardContent>
    </Card>
  );
};

export default CardProduct;
