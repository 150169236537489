import React, { useEffect, useState } from "react";

import { Box, Paper, Tab, Tabs } from "@mui/material";

import TitleStep from "../Component/TitleStep";

import { get_mesa_disponible } from "../../../../services/pedidos.services";

import { Divider } from "@mui/material";
import "../style.mesas.scss";
import TableMesas from "./TableMesas";

const TabsMoodTable = (props) => {
  let { value, setValue, handleSetSelectedTable } = props;

  const handleChange = (event, newValue) => {
    let data = {};

    if (newValue === 0) {
      data = {
        id_mesa: "",
        codigo_mesa: "",
        amount_people: 0,
      };
    } else {
      data = {
        id_mesa: null,
        codigo_mesa: "Directo",
        amount_people: 0,
      };
    }

    setValue(newValue);
    handleSetSelectedTable(data);
  };

  return (
    <Box>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="visible arrows tabs example"
        centered
      >
        <Tab label="Mesas" selected={true} />
        <Tab label="Pedido directo" />
      </Tabs>
    </Box>
  );
};

// const TabsMesas = (props) => {
//   let { value, setValue } = props;

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   return (
//     <Box>
//       <Tabs
//         value={value}
//         onChange={handleChange}
//         variant="scrollable"
//         scrollButtons
//         aria-label="visible arrows tabs example"
//         sx={{
//           [`& .${tabsClasses.scrollButtons}`]: {
//             "&.Mui-disabled": { opacity: 0.1 },
//           },
//         }}
//       >
//         <Tab label="Todas las mesas" />
//         <Tab label="Mesas libres" />
//         <Tab label="Mesas ocupadas" />
//       </Tabs>
//     </Box>
//   );
// };

const Mesas = (props) => {
  let { selected, dataTable, setDataTable, handleSetSelectedOnlyMesa } = props;

  const selectedTable = selected.mesa;

  const [moodTable, setMoodTable] = useState(0);

  const handleSetSelectedTable = (element) => {
    if (element.ocupado_mesa) {
      return null;
    }

    handleSetSelectedOnlyMesa({
      ...selected.mesa,
      id_mesa: element.id_mesa,
      codigo_mesa: element.codigo_mesa,
      id_reserva: element.id_reserva,
      amount_people: 0,
    });
  };

  useEffect(() => {
    const getMesas = async () => {
      try {
        setDataTable({ data: [], load: true, err: false });
        const response = await get_mesa_disponible();
        setDataTable({ data: response, load: false, err: false });
      } catch (err) {
        console.log(err);
        setDataTable({ data: [], load: false, err: true });
      }
    };

    getMesas(); /* FETCH TODAS LAS MESAS */
  }, []);

  return (
    <>
      <br />
      <Paper variant="outlined" style={{ padding: 5 }}>
        <TabsMoodTable
          value={moodTable}
          setValue={setMoodTable}
          handleSetSelectedTable={handleSetSelectedTable}
        />

        {moodTable === 0 ? (
          <>
            <Divider />
            <TableMesas
              data={dataTable}
              selectedTable={selectedTable}
              handleSetSelectedTable={handleSetSelectedTable}
            />
          </>
        ) : null}
      </Paper>
    </>
  );
};

export default Mesas;
