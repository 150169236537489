import { Close } from "@mui/icons-material";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import moment from "moment";
import { useEffect, useState } from "react";
import FormTask from "./form.task";

dayjs.extend(utc);

const DialogTask = ({ open, setOpen, task, callBack }) => {
  const [initTask, setInitTask] = useState({});

  const { Form, submitForm, loading } = FormTask({
    callBack: () => {
      callBack();
      setOpen(false);
    },
    initTask,
  });

  const isEdit = initTask?.id_task && initTask?.id_task !== "";

  useEffect(() => {
    if (open && task) {
      const inputDate =
        task?.type_task === "event"
          ? moment.utc(task?.date_task).format("YYYY-MM-DDTHH:mm:ss")
          : moment.utc(task?.date_task).format("YYYY-MM-DD");

      setInitTask({
        id_task: task?.id_task || "",
        name_task: task?.title || "",
        description_task: task?.description_task || "",
        date_task: task?.date_task ? inputDate : "",
        status_task: task?.status_task || "todo",
        type_task: task?.type_task || "",
      });

      return;
    }

    setInitTask(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth={"sm"}
      aria-describedby="dialog-select-sucursal"
    >
      <DialogTitle className="text-blue-900 text-xl font-semibold">
        {isEdit ? "Editar" : "Crear"} agenda
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent className="!px-4 !py-0">
        <Form />
      </DialogContent>
      <DialogActions>
        <button
          disabled={loading}
          type="button"
          onClick={() => setOpen(false)}
          className="text-blue-900 rounded-md p-2 text-sm hover:text-blue-800 transition duration-300 ease-in-out w-28"
        >
          Salir
        </button>
        <button
          disabled={loading}
          type="button"
          onClick={submitForm}
          className="bg-blue-900 rounded-md p-2 text-white text-sm hover:bg-blue-800 transition duration-300 ease-in-out w-28"
        >
          Aceptar
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogTask;
