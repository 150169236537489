/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";


import { checkToken } from "../../services/auth.services";
import { get_marca } from "../../services/marca.services";

import ContainerInput from "../../components/ContainerInput";
import DialogDelete from "./Dialog/ConfirmDelete/index";
import DialogCrud from "./Dialog/CrudForm";
// import LoadingPage from "../../components/LoadingPage";

import Table from "./Table/index";

import { Button, Grid } from "@mui/material";

import { Add } from "@mui/icons-material";

import { useDispatch, useSelector } from "react-redux";
import { actionTypes } from "../../redux/marca/actions";

const Marca = () => {
  let history = useHistory();

  const dispatch = useDispatch();

  const { dataMarca, loading } = useSelector((state) => state.reducer_marca);

  const onOpen = (data) =>
    dispatch({
      type: actionTypes.updateDialogEdit,
      payload: data,
    });

  const onGet = (data) =>
    dispatch({
      type: actionTypes.getDataMarca,
      payload: data,
    });

  useEffect(() => {
    async function obtainDataMarca() {
      try {
        onGet({ ...dataMarca, load: true, data: [], err: false });
        let response = await get_marca();

        onGet({ ...dataMarca, load: false, data: response, err: false });
      } catch (err) {
        console.log(err);
        onGet({ ...dataMarca, load: false, data: [], err: true });
      }
    }

    if (!loading) {
      obtainDataMarca();
    }

    return null;
  }, [loading]);

  useEffect(() => {
    if (!checkToken()) {
      history.push("/");
      return null;
    }
  }, []);

  return (
    <>
      <Grid container spacing={2} style={{ marginBottom: 20 }}>
        <Grid
          item
          xs={12}
          sm={11}
          lg={11}
          style={{
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        ></Grid>
        <Grid
          item
          xs={12}
          sm={1}
          lg={1}
          style={{
            textAlign: "right",
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Button
            fullWidth
            onClick={() => {
              onOpen(true);
            }}
            className="button-default"
          >
            <Add />
          </Button>
        </Grid>
      </Grid>

      <ContainerInput className="!p-0">
        <Table />
      </ContainerInput>
      <DialogCrud />
      <DialogDelete />
    </>
  );
};

export default Marca;
