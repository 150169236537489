import "moment/locale/es";
import { createContext, useContext, useEffect, useState } from "react";

// import { useDebounce } from "../../hooks/useDebounce";

// import {
//   addMyTicket,
//   deleteMyTicket,
//   getMyTicket,
//   updateMyTicket,
// } from "../../services/benefit.services";
// import { getTypeMyTicket } from "../../services/typeMyTicket.service";

import { getUser } from "../../services/auth.services";
import { getTicketByUserId } from "../../services/ticketBenefit.services";

const initDefaultValues = {};

const AppContext = createContext({
  /* Values */
  data: [],
  isLoadData: false,
  openModal: false,
  defaultValues: initDefaultValues,
  /* Methods */
  handleSearch: (value) => {},
  handleModal: () => {},
  handleAdd: (value) => {},
  handleUpdate: (id_benefit, value) => {},
  handleDelete: (id_benefit) => {},
  handleAction: (value) => {},
  handleSelected: (value) => {},
  handleReset: () => {},
});
export default function MyTicketState({ children }) {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [defaultValues, setDefaultValues] = useState(initDefaultValues);

  const idUsu = getUser()?.id;

  // const [name, setName] = useState("");
  // const debouncedFilter = useDebounce(name || "", 500);

  async function handleSearch(params) {
    setLoading(true);

    const urlParams = `?code=${params?.code}&startDate=${
      params?.date_start || ""
    }&endDate=${params?.date_end || ""}&redeem=${params?.redeem || ""}`;

    const response = await getTicketByUserId(idUsu, urlParams);
    setData(response || []);

    setLoading(false);
  }

  async function handleGet() {
    setLoading(true);
    const response = await getTicketByUserId(idUsu);
    setData(response || []);
    setLoading(false);
  }

  const handleModal = (openStatus) => {
    setOpenModal(openStatus);
  };

  useEffect(() => {
    handleGet();
  }, []);

  return (
    <AppContext.Provider
      value={{
        /* Values */
        data,
        isLoadData: loading,
        openModal,
        defaultValues,
        /* Methods */
        handleSearch,
        handleModal,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
