export const interestCalc = (data) => {
  if (data.length === 0) {
    return {
      number: null,
      behavior: null,
    };
  }

  const lastData = data[data.length - 1];
  const interest = lastData.type_interest_tracking;

  if (data.length > 1) {
    const beforeData = data[data.length - 2];

    if (beforeData.type_interest_tracking > lastData.type_interest_tracking) {
      return {
        number: interest,
        behavior: "down",
      };
    }

    if (beforeData.type_interest_tracking < lastData.type_interest_tracking) {
      return {
        number: interest,
        behavior: "up",
      };
    }
  }

  return {
    number: interest,
    behavior: "up",
  };
};

export const interestNivel = (nivelNumber) => {
  if (nivelNumber === 5) {
    return (
      <div className="bg-green-500 text-white text-xs font-semibold rounded-full px-2 py-1 uppercase tracking-wide text-center">
        Muy Alto
      </div>
    );
  }

  if (nivelNumber === 4) {
    return (
      <div className="bg-blue-500 text-white text-xs font-semibold rounded-full px-2 py-1 uppercase tracking-wide text-center">
        Alto
      </div>
    );
  }

  if (nivelNumber === 3) {
    return (
      <div className="bg-yellow-500 text-white text-xs font-semibold rounded-full px-2 py-1 uppercase tracking-wide text-center">
        Medio
      </div>
    );
  }

  if (nivelNumber === 2) {
    return (
      <div className="bg-orange-500 text-white text-xs font-semibold rounded-full px-2 py-1 uppercase tracking-wide text-center">
        Bajo
      </div>
    );
  }

  if (nivelNumber === 1) {
    return (
      <div className="bg-red-500 text-white text-xs font-semibold rounded-full px-2 py-1 uppercase tracking-wide text-center">
        Muy Bajo
      </div>
    );
  }

  return (
    <div className="bg-gray-500 text-white text-xs font-semibold rounded-full px-2 py-1 uppercase tracking-wide text-center">
      Sin Nivel
    </div>
  );
};

export const statusNivel = (status = "pendiente") => {
  if (status === "pendiente") {
    return (
      <div className="bg-yellow-500 text-white text-xs font-semibold rounded-full px-2 py-1 uppercase tracking-wide text-center">
        Pendiente
      </div>
    );
  }

  if (status === "aprobado") {
    return (
      <div className="bg-green-500 text-white text-xs font-semibold rounded-full px-2 py-1 uppercase tracking-wide text-center">
        Aprobado
      </div>
    );
  }

  if (status === "cancelado") {
    return (
      <div className="bg-red-500 text-white text-xs font-semibold rounded-full px-2 py-1 uppercase tracking-wide text-center">
        Rechazado
      </div>
    );
  }

  return (
    <div className="bg-yellow-500 text-white text-xs font-semibold rounded-full px-2 py-1 uppercase tracking-wide text-center">
      Pendiente
    </div>
  );
};
