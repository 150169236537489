import React, { useEffect } from "react";

import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import { useForm } from "react-hook-form";
import { delete_quotation } from "../../services/quotation.services";

import { toast } from "react-hot-toast";

const initalValues = {
  idQuotation: null,
  codQuotation: "",
};

const ModalQuotationDelete = (props) => {
  const { open, setOpen, selected, setSelected, callBack } = props;

  const { handleSubmit, reset, watch } = useForm({
    defaultValues: initalValues,
  });

  const [load, setLoad] = React.useState(false);

  const onAction = async (data) => {
    setLoad(true);

    await delete_quotation(data?.idQuotation);

    await callBack();

    toast.success("Se elimino la cotización correctamente", {
      position: "top-center",
    });

    setLoad(false);
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      if (selected?.id_quotation && selected?.id_quotation > 0) {
        reset({
          ...initalValues,
          idQuotation: Number(selected.id_quotation),
          codQuotation: selected?.cod_quotation,
        });
      }
    } else {
      setSelected(null);
      reset(initalValues);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth={"sm"}
      aria-describedby="dialog-benefit"
    >
      <form onSubmit={handleSubmit(onAction)} autoComplete="off">
        <DialogTitle>
          Eliminar cotización
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={`${load && "animate-pulse"} `}>
          <br />
          ¿Estas seguro de eliminar la cotización {watch("codQuotation")} ?
        </DialogContent>
        <DialogActions>
          <Button
            disable={load.toString()}
            color="inherit"
            variant="text"
            onClick={() => {
              setOpen(false);
            }}
          >
            Salir
          </Button>
          <Button
            color="secondary"
            variant="contained"
            type="submit"
            disable={load.toString()}
          >
            Eliminar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default ModalQuotationDelete;
