import axios from "axios";

import { config } from "../config";
import { axiosHeader } from "../functions/axiosHeader";

const { baseurl } = config;

export const getMyIp = () => {
  const url = `${baseurl}/api/visitor-counter/get`;

  return axios
    .get(url)
    .then((response) => response)
    .catch((err) => {
      throw new Error(err);
    });
};

export const save_counter = (params) => {
  const url = `${baseurl}/api/visitor-counter/save`;

  return axios
    .post(url, params)
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const fetch_visitors = () => {
  const url = `${baseurl}/api/visitor-counter/fetch`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};
