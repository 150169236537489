import axios from "axios";
import { config } from "../config";

import { axiosHeader } from "../functions/axiosHeader";

const { baseurl } = config;

export const getWallet = () => {
  const url = `${baseurl}/api/wallet/get`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const updateWallet = (data) => {
  const url = `${baseurl}/api/wallet/update`;

  return axios
    .patch(url, data, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_wallet_merchantes = (id_empsede) => {
  const url = `${baseurl}/api/wallet/wallet_merchantes/${id_empsede}`;
  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_wallet_merchante = (usu_id) => {
  const url = `${baseurl}/api/wallet/wallet_merchante/${usu_id}`;
  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};
