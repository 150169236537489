/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import * as React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import { Grid } from "@mui/material/";

import { Alert, Collapse } from "@mui/material";

import Input from "../../../../components/Forms/Input";

import { Controller, useForm } from "react-hook-form";

import { Close } from "@mui/icons-material";

import { actionTypes } from "../../../../redux/producto/actions";

import toast from "react-hot-toast";

import { useDispatch, useSelector } from "react-redux";

import useCategoriaProducto from "../../../../hooks/useCategoriaProducto";
import useMarca from "../../../../hooks/useMarca";

import { get_medidas } from "../../../../services/menu.services";
import {
  add_producto,
  update_producto,
} from "../../../../services/productos.services";

const ErrorMsg = ({ wrong, setWrong, message }) => {
  return (
    <Collapse in={wrong}>
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setWrong(false);
            }}
          >
            <Close fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
        severity="error"
      >
        {message}
      </Alert>
    </Collapse>
  );
};

const DialogCrud = () => {
  const dispatch = useDispatch();
  const { dataSelected, openModalCRUD } = useSelector(
    (state) => state.reducer_producto
  );

  const { control, reset, handleSubmit } = useForm({
    defaultValues: dataSelected,
  });

  const [wrong, setWrong] = React.useState({ open: false, message: "" });

  const { dataMarca } = useMarca();
  const { dataCategoriaProducto } = useCategoriaProducto();

  const [dataMedidas, setDataMedida] = React.useState({
    data: [],
    load: false,
    err: false,
  });

  const onOpen = (data) =>
    dispatch({
      type: actionTypes.updateDialogEdit,
      payload: data,
    });

  const onLoad = (data) =>
    dispatch({
      type: actionTypes.updateLoad,
      payload: data,
    });

  const handleModal = (value) => {
    onOpen(value);
  };

  const handleAdd = async (data) => {
    // console.log(data);
    // return;

    try {
      onLoad(true);
      if (data.prod_id) {
        let { response } = await update_producto(data);

        if (response.affectedRows === 1) {
          toast.success("Producto modificado correctamente");
        } else {
          toast.error("No se pudo modificar el producto");
        }
      } else {
        let { response } = await add_producto(data);

        if (response.affectedRows === 1) {
          toast.success("Producto creada correctamente");
        } else {
          toast.error("No se pudo crear el producto");
        }
      }

      onLoad(false);

      handleModal(false);
    } catch (err) {
      console.error(err);
      toast.error("Hubo un error al crear el producto");
      onLoad(false);
    }
  };

  React.useEffect(() => {
    async function obtainMedidas() {
      try {
        setDataMedida({ load: true, data: [], err: false });
        let response = await get_medidas();

        setDataMedida({
          load: false,
          data: response.map((el) => ({
            label: el.name_medida,
            value: el.id_medida,
          })),
          err: false,
        });
      } catch (err) {
        setDataMedida({ load: false, data: [], err: true });
        console.log(err);
      }
    }

    obtainMedidas();
  }, []);

  React.useEffect(() => {
    if (openModalCRUD) {
      reset(dataSelected);
    }
  }, [openModalCRUD]);

  return (
    <>
      <Dialog
        open={openModalCRUD}
        onClose={() => handleModal(false)}
        fullWidth
        maxWidth={"sm"}
      >
        <form onSubmit={handleSubmit(handleAdd)} autoComplete="off">
          <DialogTitle>
            {dataSelected.marc_id ? "Editar" : "Agregar"}
            <IconButton
              aria-label="close"
              onClick={() => handleModal(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <br />
            <ErrorMsg
              wrong={wrong.open}
              setWrong={(el) => setWrong({ ...wrong, open: el })}
              message={wrong.message}
            />

            <Grid container spacing={1}>
              <Grid item xs={12} sm={8} lg={8}>
                <Controller
                  name="prod_nombre"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      required
                      value={value || ""}
                      onChange={onChange}
                      type="text"
                      title="Nombre"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={5} lg={5}>
                <Controller
                  name="prod_precio"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      required
                      value={value || ""}
                      onChange={onChange}
                      type="number"
                      title="Precio"
                      step="any"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={10} lg={10}>
                <Controller
                  name="cat_id"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      select
                      required
                      value={value || ""}
                      onChange={onChange}
                      type="select"
                      options={dataCategoriaProducto.data}
                      title="Categoría"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={10} lg={10}>
                <Controller
                  name="marc_id"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      select
                      value={value || ""}
                      onChange={onChange}
                      type="select"
                      options={dataMarca.data}
                      title="Marca"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={10} lg={10}>
                <Controller
                  name="id_medida"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      select
                      required
                      value={value || ""}
                      onChange={onChange}
                      type="select"
                      options={dataMedidas.data}
                      title="Unidad de medida"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={5} lg={5}>
                <Controller
                  name="value_medida"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      required
                      value={value || ""}
                      onChange={onChange}
                      type="number"
                      title="Valor"
                      step="any"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Controller
                  name="prod_descripcion"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      value={value || ""}
                      onChange={onChange}
                      type="textarea"
                      title="Descripción"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="info"
              variant="text"
              onClick={() => {
                handleModal(false);
              }}
            >
              Salir
            </Button>
            <Button type="submit" color="success" variant="contained">
              Aceptar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default DialogCrud;
