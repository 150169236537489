import React from "react";
import { useSelector } from "react-redux";

import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

import { formatMoney } from "../../../../../functions/formatMoney";

const ItemRow = (props) => {
  let { idx, el } = props;

  return (
    <TableRow
      key={idx}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell align="left">
        <Typography
          variant="overline"
          component="h6"
          style={{
            color: "#008001",
            fontWeight: "600",
            lineHeight: "inherit",
          }}
        >
          {el.nombre_plato} {el.totAmount}
        </Typography>
        <br />

        <strong style={{ fontSize: 15 }}>
          {formatMoney(el.totAmount * el.precio_plato)}
        </strong>
      </TableCell>
      <TableCell align="left">x{el.totAmount}</TableCell>
    </TableRow>
  );
};

const ListProducts = () => {
  const { products, priceProducts } = useSelector(
    (state) => state.reducer_carta
  );

  return (
    <>
      <Typography component={"h6"} variant="h6">
        Productos:
      </Typography>
      <Table aria-label="simple table" style={{ border: "1px solid #d6d6d6" }}>
        <TableBody>
          {products.map((el, idx) => (
            <ItemRow key={idx} el={el} idx={idx} />
          ))}
        </TableBody>
      </Table>
      <Table
        aria-label="simple table"
        style={{ border: "1px solid #d6d6d6", marginTop: 7 }}
      >
        <TableBody>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell align="left">
              <strong>Total</strong>
            </TableCell>
            <TableCell align="left">
              <strong>{formatMoney(priceProducts)}</strong>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};
export default ListProducts;
