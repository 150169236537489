import React from "react";

import Head from "./Head";

import { Toaster } from "react-hot-toast";

const Login = (props) => {
  let { title, styleBody, head = "", logo = null } = props;

  return (
    <div className="container">
      <Toaster />
      <Head>
        <title>{`${title} | Ubuntu`}</title>
        {head}
      </Head>
      <main className="Login">
        <div className="Login__Body" style={styleBody}>
          {logo && (
            <div style={{ textAlign: "center", marginBottom: 15 }}>
              <img src={logo} width="150" alt="logo" />
            </div>
          )}
          <div className="Login_Container">{props.children}</div>
        </div>
      </main>
    </div>
  );
};

export default Login;
