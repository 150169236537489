import React from "react";

import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

const ModalAccountInfoMerchante = (props) => {
  const { open, setOpen, selected } = props;

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth={"sm"}
      aria-describedby="dialog-benefit"
    >
      <DialogTitle>
        Información de la cuenta de merchante
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className="">
          <div className="text-center bg-slate-500 p-1 rounded-lg text-white mb-2">
            <h3>{selected?.usu_nombre ?? "Sin información"}</h3>
          </div>
          <div className="text-center bg-slate-500 p-1 rounded-lg text-white mb-2">
            <h3>{selected?.usu_bank ?? "Sin información"}</h3>
          </div>
          <div className="text-center bg-slate-500 p-1 rounded-lg text-white mb-2">
            <h3>{selected?.usu_bank_type ?? "Sin información"}</h3>
          </div>
          <div className="text-center bg-slate-500 p-1 rounded-lg text-white mb-2">
            <h3>{selected?.usu_bank_number_account ?? "Sin información"}</h3>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="inherit"
          variant="text"
          onClick={() => {
            setOpen(false);
          }}
        >
          Salir
        </Button>
        <Button color="secondary" variant="contained" type="submit">
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ModalAccountInfoMerchante;
