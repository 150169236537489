/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

import { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import Head from "../../components/Head";
import { getQueryParams } from "../../functions/getQueryParams";
import { get_empresas } from "../../services/empresa.services";
import VerdeMostazaTemplate from "./templateReservarVerdeMostaza";

import IMAGE_FX from "../../assets/images/verde/final1.png";
import MESSAGE from "../../assets/images/verde/final2.png";

import { Facebook, Instagram } from "@mui/icons-material";

import { motion } from "framer-motion";

const Html = (props) => {
  const { title } = props;
  return (
    <div className="carta">
      <Toaster />
      <Head>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content="POS" />
        <meta property="og:title" content={title} />
      </Head>
      <div id="root">{props.children}</div>
    </div>
  );
};

const fadeIn = (direction, delay) => {
  return {
    hidden: {
      y: direction === "up" ? 80 : direction === "down" ? -80 : 0,
      opacity: 0,
      x: direction === "left" ? 80 : direction === "right" ? -80 : 0,
    },
    show: {
      y: 0,
      x: 0,
      opacity: 1,
      transition: {
        type: "tween",
        duration: 0.5,
        delay: delay,
        ease: [0.25, 0.25, 0.25, 0.75],
      },
    },
  };
};

const FinalReserva = () => {
  let { empresa } = useParams();
  const queryParams = getQueryParams();

  const [dataSucursales, setDataSucursales] = useState(null);

  const obtainSucursales = async () => {
    try {
      setDataSucursales([]);

      const response = await get_empresas(empresa);
      const findSucursal = response.find(
        (item) =>
          item.alias_empsede.trim().split("_")[1] ===
          queryParams.get("name").trim()
      );

      setDataSucursales(findSucursal);
    } catch (err) {}
  };

  useEffect(() => {
    obtainSucursales();
  }, []);

  return (
    <Html>
      <Head>
        <title>{`Reservar | Verde Mostaza`}</title>
        {/* Meta Pixel Code */}
        <script>
          {`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '448307170025205');
        fbq('track', 'Purchase', {
          value: 7000,
          currency: 'CLP'
          });
      `}
        </script>
        <noscript>
          {`
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src="https://www.facebook.com/tr?id=448307170025205&ev=PageView&noscript=1"
          alt=""
        />
      `}
        </noscript>
        {/* End Meta Pixel Code */}
      </Head>
      <main>
        <Toaster />
        <VerdeMostazaTemplate
          selectedSucursal={dataSucursales?.id_empsede}
          confettiShow={true}
        >
          <div className="template-carta--body__size">
            <div className="final">
              <motion.div
                variants={fadeIn("down", 0.3)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.6 }}
                className="final--card"
              >
                <div className="final--card__title">
                  <h1>¡Gracias!</h1>
                  <h2>Tu reserva ya está registrada</h2>
                </div>
                <div className="final--card__body">
                  <img src={IMAGE_FX} alt="imagSe" className="background" />
                  <img src={MESSAGE} alt="message" className="message" />
                </div>
              </motion.div>
              <motion.div
                variants={fadeIn("down", 0.5)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.6 }}
                className="final--rrss"
              >
                <h3>Síguenos en nuestras redes</h3>
                <ul className="final--rrss__list">
                  <li>
                    <Instagram />{" "}
                    <a
                      href="https://www.instagram.com/pubverdemostaza/?hl=es"
                      target="_blank"
                      without
                      rel="noreferrer"
                    >
                      pubverdemostaza
                    </a>
                  </li>
                  <li>
                    <Facebook />{" "}
                    <a
                      href="https://www.facebook.com/PubVerdeMostaza/"
                      target="_blank"
                      without
                      rel="noreferrer"
                    >
                      pubverdemostaza
                    </a>
                  </li>
                </ul>
              </motion.div>
            </div>
          </div>
        </VerdeMostazaTemplate>
      </main>
    </Html>
  );
};

export default FinalReserva;
