/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";

import "./style.count.scss";

import { Button } from "@mui/material";
import InputDefault from "../../../../../../../atomic/atoms/input.atoms";

export default function Counter({
  addCount,
  maxCount,
  valueTotAmount = 1,
  ...props
}) {
  const [count, setCount] = useState(valueTotAmount);

  const increment = () => {
    let newCount = Number(count) + 1;

    if (maxCount) {
      newCount = newCount > maxCount ? count : newCount;
    }

    setCount(newCount);
    addCount(newCount);
  };

  const decrement = () => {
    let newCount = Number(count) - 1;
    setCount(newCount < 1 ? 1 : newCount);
    addCount(newCount);
  };

  const handleChange = (e) => {
    const number = e.target.value > 0 ? e.target.value : 1;

    setCount(number);
    addCount(number);
  };

  return (
    <div className="counter" {...props}>
      <Button
        variant="outlined"
        className="counter__decrement"
        onClick={() => decrement()}
        color="error"
      >
        -
      </Button>
      {/* <div className="counter__number">{count}</div> */}
      <input
        autoFocus
        className="p-0 w-20 bg-slate-200 text-center"
        value={count}
        onChange={handleChange}
        type="number"
        title="Cantidad"
      />
      <Button
        variant="outlined"
        className="counter__increment"
        onClick={() => increment()}
        color="error"
      >
        +
      </Button>
    </div>
  );
}
