import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputDefault from "../../atomic/atoms/input.atoms";
import { get_sucursales_by_id } from "../../services/sucursal.services";

import { InfoOutlined } from "@mui/icons-material";
import { getUser } from "../../services/auth.services";
import { Button, CircularProgress } from "@mui/material";
import { updatePlanning } from "../../services/planning.services";

const initalValues = {
  idPlanning: 0,
  usuId: 0,
  idQuotation: 0,
  idEvent: 0,
  idTypeEvent: 0,
  nameBoyfriendQuotation: "",
  nameGirlfriendQuotation: "",
  valuePerPerson: 0,
  minBasePAXPerPerson: 0,
  nameLocalEvent: "",
  ceremonyTime: "",
  eventHoursQuotation: 0,
  nameOfChildren: "",
  nameTypeEvent: "",
  nameEvent: "",
  nameFatherOfBoyFriend: "",
  nameMotherOfBoyFriend: "",
  nameFatherOfGirlFriend: "",
  nameMotherOfGirlFriend: "",
};

const GeneralsData = ({ data }) => {
  console.log(data);

  const [dataSucursales, setDataSucursales] = useState([]);

  const { control, reset, watch, setValue, handleSubmit } = useForm({
    defaultValues: initalValues,
  });

  const [load, setLoad] = useState(false);

  const onSubmit = async (data) => {
    try {
      console.log("data", data);

      setLoad(true);
      await updatePlanning(data, data.idPlanning);
      setLoad(false);
    } catch (err) {
      setLoad(false);
      console.log(err);
    }
  };

  useEffect(() => {
    const getSucursales = async () => {
      try {
        const response = await get_sucursales_by_id(getUser()?.empresa?.id_emp);

        setDataSucursales(
          response.map((item) => ({
            value: item.nom_empsede,
            label: item.nom_empsede,
          }))
        );
      } catch (err) {
        console.log(err.message);
      }
    };

    getSucursales();
  }, []);

  useEffect(() => {
    if (!data || data.length === 0) {
      return;
    }

    reset({
      ...initalValues,
      idPlanning: Number(data?.id_planning) || 0,
      idQuotation: Number(data?.id_quotation) || 0,
      idEvent: Number(data?.id_event) || 0,
      idTypeEvent: Number(data?.id_typeevent) || 0,
      nameBoyfriend: data?.nameBodyFriend_planning || "",
      nameGirlfriend: data?.nameGirlFriend_planning || "",
      valuePerPerson: data?.valueperperson_quotation || 0,
      minBasePAXPerPerson: data?.minbasepaxperperson_quotation || 0,
      nameLocalEvent: data?.nameLocalEvent_planning || "",
      nameTypeEvent: data?.name_typeevent || "",
      nameEvent: data?.name_event || "",
      ceremonyTime: data?.ceremonyTime_planning || "",
      nameOfChildren: data?.nameChildren_planning || "",
      nameFatherOfBoyFriend: data?.nameFatherBodyFriend_planning || "",
      nameMotherOfBoyFriend: data?.nameMotherBodyFriend_planning || "",
      nameFatherOfGirlFriend: data?.nameFatherGirlFriend_planning || "",
      nameMotherOfGirlFriend: data?.nameMotherGirlFriend_planning || "",
    });

    return () => reset(initalValues);
  }, [data]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="mb-4 w-full grid grid-cols-12 gap-2">
          {Number(watch("idEvent")) === 1 && (
            <>
              <div className="col-span-12 mb-2">
                <p className="text-sm font-semibold">Datos de los novios</p>
              </div>
              <div className="col-span-12 md:col-span-6">
                <Controller
                  name="nameBoyfriend"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputDefault
                      disabled={load}
                      loading={false}
                      fullWidth
                      value={value}
                      onChange={onChange}
                      title={"Novio"}
                      required
                    />
                  )}
                />
              </div>
              <div className="col-span-12 md:col-span-6">
                <Controller
                  name="nameGirlfriend"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputDefault
                      disabled={load}
                      loading={false}
                      fullWidth
                      value={value}
                      onChange={onChange}
                      title={"Novia"}
                      required
                    />
                  )}
                />
              </div>
              <div className="col-span-12 mb-2 md:col-span-6">
                <div className="w-full grid grid-cols-12 gap-2">
                  <div className="col-span-12 mb-1">
                    <p className="text-sm font-semibold">Padres del novio</p>
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <Controller
                      name="nameFatherOfBoyFriend"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputDefault
                          disabled={load}
                          loading={false}
                          fullWidth
                          value={value}
                          onChange={onChange}
                          title={"Nombre del padre"}
                          required
                        />
                      )}
                    />
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <Controller
                      name="nameMotherOfBoyFriend"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputDefault
                          disabled={load}
                          loading={false}
                          fullWidth
                          value={value}
                          onChange={onChange}
                          title={"Nombre de la madre"}
                          required
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-12 mb-2 md:col-span-6">
                <div className="w-full grid grid-cols-12 gap-2">
                  <div className="col-span-12 mb-1">
                    <p className="text-sm font-semibold">Padres de la novia</p>
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <Controller
                      name="nameFatherOfGirlFriend"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputDefault
                          disabled={load}
                          loading={false}
                          fullWidth
                          value={value}
                          onChange={onChange}
                          title={"Nombre del padre"}
                          required
                        />
                      )}
                    />
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <Controller
                      name="nameMotherOfGirlFriend"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputDefault
                          disabled={load}
                          loading={false}
                          fullWidth
                          value={value}
                          onChange={onChange}
                          title={"Nombre de la madre"}
                          required
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-12">
                <p className="text-sm font-semibold">Nombre de los hijos</p>
              </div>
              <div className="col-span-12 md:col-span-12">
                <p className="text-xs text-gray-500 mb-2">
                  <InfoOutlined className="mr-1" /> Si tiene hijos, ingrese el
                  nombre de los hijos separados por comas. (Ejemplo: Juan,
                  Pedro, Carlos)
                </p>
                <Controller
                  name="nameOfChildren"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputDefault
                      disabled={load}
                      loading={false}
                      fullWidth
                      value={value}
                      onChange={onChange}
                      title={"Nombre del hijo o de los hijos"}
                    />
                  )}
                />
              </div>
            </>
          )}

          <div className="col-span-12">
            <p className="text-sm font-semibold">Datos del evento</p>
          </div>
          <div className="col-span-12 md:col-span-6">
            <Controller
              name="nameLocalEvent"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputDefault
                  disabled={load}
                  required
                  loading={false}
                  fullWidth
                  value={value}
                  onChange={onChange}
                  title={"Salón de evento"}
                  type={"select"}
                  options={dataSucursales}
                />
              )}
            />
          </div>
          <div className="col-span-12 md:col-span-6">
            <Controller
              name="ceremonyTime"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputDefault
                  disabled={load}
                  loading={false}
                  fullWidth
                  value={value}
                  onChange={onChange}
                  type={"time"}
                  title={"Horario de la ceremonia iglesia"}
                />
              )}
            />
          </div>

          <div className="col-span-12 md:col-span-6">
            <Controller
              name="valuePerPerson"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InputDefault
                  disabled
                  value={value}
                  onChange={onChange}
                  title={"Valor por persona"}
                  type={"number"}
                />
              )}
            />
          </div>
          <div className="col-span-12 md:col-span-6">
            <Controller
              name="minBasePAXPerPerson"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InputDefault
                  disabled
                  value={value}
                  onChange={onChange}
                  title={"Base PAX por persona"}
                  type={"number"}
                />
              )}
            />
          </div>
          <div className="col-span-12 md:col-span-12 flex justify-end">
            {load && (
              <div className="mr-4 flex items-center">
                <CircularProgress size={25} color="inherit" />
              </div>
            )}
            <Button
              disabled={load}
              variant="contained"
              color="secondary"
              className="ml-auto w-full md:w-auto"
              type="submit"
            >
              Guardar
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default GeneralsData;
