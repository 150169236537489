/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

import TemplateLogin from "../../components/TemplateLogin";

import { Typography } from "@mui/material";

import { useParams } from "react-router";

import { Button, TextField } from "@mui/material";
import toast from "react-hot-toast";
import { addSurvey, getSurveyById } from "../../services/survey.services";
import AllQuestion from "./components/allQuestion";

import NotFound from "../../atomic/organisms/NotFound.organisms";
import LoadingPage from "../../components/LoadingPage";

const SurveyPage = () => {
  const params = useParams();

  const idPedido = params.id_pedido;

  const [error, setError] = useState(false);
  const [answered, setAnswered] = useState(false);

  const [dataQuestions, setDataQuestions] = useState([]);
  const [observationQuestion, setObservationQuestion] = useState("");
  const [infoSurvey, setInfoSurvey] = useState({
    apeMaClient: null,
    apePaClient: null,
    colorPrimary: null,
    colorSecondary: null,
    logo: null,
    nomClient: null,
    nomEmp: null,
    nomEmpsede: null,
    id_pedido: null,
    id_cliente: null,
    id_emp: null,
    id_empsede: null,
  });

  const [loadPage, setLoadPage] = useState(false);

  const handleUpdateAnswer = (idx, newAnswer) => {
    let newQuestions = [...dataQuestions];
    newQuestions[idx].answer = newAnswer;

    setDataQuestions(newQuestions);
  };

  const handleSubmit = async () => {
    try {
      const newPedido = dataQuestions
        .filter((el) => el.categoryQuestion === 3)
        .map((el) => ({
          categoryQuestion: el.categoryQuestion,
          categoryOrder: "SATISFACTION",
          answer: el.answer,
          textresponse: null,
        }));

      if (observationQuestion !== "") {
        newPedido.push({
          categoryQuestion: 3,
          categoryOrder: "COMMENT",
          answer: null,
          textresponse: observationQuestion,
        });
      }

      const newJson = {
        infoPedido: {
          id_emp: infoSurvey.id_emp,
          id_empsede: infoSurvey.id_empsede,
          id_pedido: infoSurvey.id_pedido,
          id_cliente: infoSurvey.id_cliente,
        },
        pedido: newPedido,
        products: dataQuestions
          .filter((el) => el.categoryQuestion === 2)
          .map((el) => ({
            categoryQuestion: el.categoryQuestion,
            id: 292,
            answer: el.answer,
          })),
        garzon: dataQuestions
          .filter((el) => el.categoryQuestion === 1)
          .map((el) => ({
            categoryQuestion: el.categoryQuestion,
            id: 292,
            answer: el.answer,
          })),
      };

      setLoadPage(true);
      await addSurvey(newJson);
      setLoadPage(false);

      setAnswered(true);

      toast.success("Se envío correctamente.", {
        duration: 6000,
      });
    } catch (err) {
      toast.error("Error al enviar.", {
        duration: 6000,
      });
    }
  };

  const getQuestions = async (id) => {
    try {
      setLoadPage(true);
      const response = await getSurveyById(id);
      setLoadPage(false);

      setAnswered(response.answered);

      setInfoSurvey(response.infoSurvey);

      response.garzon && response.platos.push(response.garzon);
      response.generic && response.platos.push(response.generic);

      const newPlatos = response.platos.map((el) => ({
        ...el,
        answer: 2,
      }));

      setDataQuestions(newPlatos.reverse());
    } catch (err) {
      setError(true);
      setLoadPage(false);
      console.log(err);
    }
  };

  useEffect(() => {
    getQuestions(idPedido);
  }, [idPedido]);

  if (error) {
    return (
      <>
        <LoadingPage open={loadPage} setOpen={setLoadPage} />

        <TemplateLogin
          title="Cuestionario"
          styleBody={{
            display: "block",
            height: "auto",
            paddingTop: "15px",
            padingBottom: "5px",
            width: "80%",
            fontSize: 14,
          }}
        >
          <NotFound texto="No se encontró registros." />
        </TemplateLogin>
      </>
    );
  }

  if (answered) {
    return (
      <>
        <LoadingPage open={loadPage} setOpen={setLoadPage} />
        <TemplateLogin
          title="Cuestionario"
          styleBody={{
            display: "block",
            height: "auto",
            paddingTop: "15px",
            padingBottom: "5px",
            width: "80%",
            fontSize: 14,
          }}
          logo={infoSurvey.logo}
        >
          <Typography variant="h5" component="h5" className="Texto_Login">
            ¡Hola!
          </Typography>

          <br />

          <Typography
            variant="inherit"
            component="p"
            style={{ textAlign: "left" }}
          >
            {infoSurvey.nomClient && (
              <>
                {infoSurvey.nomClient}: <br />
                <br />
              </>
            )}
            Tu opinión es importante para nosotros, en {infoSurvey.nomEmp}{" "}
            sucursal {infoSurvey.nomEmpsede}, estamos contentos de que hayas
            respondido nuestra encuesta.
            <br />
            <br />
            Esperamos con ansias que regreses nuevamente y disfrutes de nuestra
            experiencia.
          </Typography>
        </TemplateLogin>
      </>
    );
  }

  return (
    <>
      <LoadingPage open={loadPage} setOpen={setLoadPage} />
      <TemplateLogin
        title="Cuestionario"
        styleBody={{
          display: "block",
          height: "auto",
          paddingTop: "15px",
          padingBottom: "5px",
          width: "80%",
          fontSize: 14,
        }}
        logo={infoSurvey.logo}
      >
        <Typography variant="h5" component="h5" className="Texto_Login">
          ¡Hola!
        </Typography>

        <br />

        <Typography
          variant="inherit"
          component="p"
          style={{ textAlign: "left" }}
        >
          {infoSurvey.nomClient && (
            <>
              {infoSurvey.nomClient}: <br />
              <br />
            </>
          )}
          Nos encantaría conocer tu opinión sobre {
            infoSurvey.nomEmp
          } sucursal {infoSurvey.nomEmpsede}, queremos saber cómo te fue para
          seguir mejorando.
          <br />
          <br />
          En base a tu última experiencia: <br />
          <br />
        </Typography>

        <AllQuestion data={dataQuestions} setAnswer={handleUpdateAnswer} />

        <TextField
          className="InputDefault"
          id="outlined-multiline-static"
          multiline
          placeholder="Déjanos un comentario..."
          rows={4}
          variant="outlined"
          fullWidth
          onChange={(el) => setObservationQuestion(el.target.value)}
        />
        <br />
        <br />

        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={() => handleSubmit()}
        >
          Enviar
        </Button>
      </TemplateLogin>
    </>
  );
};

export default SurveyPage;
