import { Close } from "@mui/icons-material";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import { Controller, useForm } from "react-hook-form";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { useEffect, useState } from "react";
import InputDefault from "../../../atomic/atoms/input.atoms";
import { rescheduleTask } from "../../../services/task.services";

import toast from "react-hot-toast";

dayjs.extend(utc);

const initialValues = {
  id_task: "",
  name_task: "",
  description_task: "",
  date_task: "",
  status_task: "todo",
  type_task: "event",
};

const DialogRescheduleTask = ({ open, setOpen, task, callBack }) => {
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, watch, reset } = useForm({
    defaultValues: initialValues,
  });

  const typeDateInput =
    watch("type_task") === "event" ? "datetime-local" : "date";

  const handleReschedule = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        await rescheduleTask(data.id_task, data);
        resolve("Success");
      } catch (err) {
        reject(new Error("Error"));
      }
    });
  };

  const onSubmit = (data) => {
    setLoading(true);

    toast.promise(handleReschedule(data), {
      loading: "Cargando...",
      success: () => {
        setLoading(false);
        setOpen(false);
        callBack && callBack();
        return "Se ha reagendado la tarea correctamente";
      },
      error: (err) => {
        setLoading(false);
        return "Ops! Ocurrió un error al reagendar";
      },
    });

    setLoading(true);
  };

  useEffect(() => {
    if (open) {
      reset({
        id_task: task?.id_task || "",
        type_task: task?.type_task || "",
        status_task: task?.status_task || "",
        name_task: task?.name_task || "",
        description_task: task?.description_task || "",
      });

      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth={"xs"}
      aria-describedby="dialog-select-sucursal"
    >
      <form id="formTask" onSubmit={handleSubmit(onSubmit)} className="w-full">
        <DialogTitle className="text-blue-900 text-xl font-semibold">
          Reagenda tu tarea
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent className="!px-4 !py-0">
          <div className="grid grid-cols-12 gap-3 pt-4">
            <div className="col-span-12">
              <Controller
                name="date_task"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputDefault
                    required
                    value={value}
                    onChange={onChange}
                    type={typeDateInput}
                  />
                )}
              />
            </div>
            <div className="col-span-12">
              <Controller
                name="description_task"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputDefault
                    required
                    value={value}
                    onChange={onChange}
                    type="textarea"
                    title="Descripción"
                    minRows={3}
                  />
                )}
              />
            </div>
          </div>
        </DialogContent>

        <DialogActions className="mt-5">
          {/* <button
            disabled={loading}
            type="button"
            onClick={() => setOpen(false)}
            className="text-blue-900 rounded-md p-2 text-sm hover:text-blue-800 transition duration-300 ease-in-out w-28"
          >
            Salir
          </button> */}
          <button
            disabled={loading}
            type="submit"
            className="bg-blue-900 rounded-md p-2 text-white text-sm hover:bg-blue-800 transition duration-300 ease-in-out w-28"
          >
            Aceptar
          </button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogRescheduleTask;
