/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Template from "../../components/Template";

import { checkToken, getUser } from "../../services/auth.services";
import { get_pedido } from "../../services/pedidos.services";

import LoadingPage from "../../components/LoadingPage";
import DialogApertura from "./Apertura";
import TablePedido from "./TablePedido";

import { get_caja } from "../../services/caja.services";

import { getUserCurrently } from "../../services/auth.services";

import { Button, Grid, TextField } from "@mui/material";
import "moment/locale/es";
import { groupBy } from "../../functions/groupBy";
import { nameSockets } from "../../functions/nameSockets";

const Orders = (props) => {
  const history = useHistory();
  const { socket } = props;

  const [loadPage, setLoadPage] = useState(false);
  const [openMesa, setOpenMesa] = useState(false);
  const [userData, setUserData] = useState(false);
  const [dataPedido, setDataPedido] = useState({
    load: false,
    err: false,
    data: [],
  });
  const [cajaData, setCajaData] = useState(true);
  const [valueFilter, setValueFilter] = useState("");
  const [tmpDataPedidos, setTmpDataPedidos] = useState({
    load: false,
    err: false,
    data: [],
    dataOriginal: [],
  });

  const usu_id = getUser().id;

  const handleAperturaCaja = async () => {
    try {
      const param = "oneday";

      const response = await get_caja(param);

      // const apertura = response.length > 0 ? true : false;
      setCajaData(response[0] ? response[0] : false);
    } catch (err) {
      setCajaData(false);
      console.log(err);
    }
  };

  const handlePedidoTransform = (data) => {
    return groupBy(data, "codigo_mesa");
  };

  const handleGetPedido = async () => {
    try {
      setLoadPage(true);
      setDataPedido({ load: true, err: false, data: [] });

      const response = await get_pedido("progress");

      const newResponse = response.filter((el) => el.usu_id === usu_id);

      setDataPedido({
        load: false,
        err: false,
        data: handlePedidoTransform(newResponse),
      });

      setTmpDataPedidos({
        load: false,
        err: false,
        data: handlePedidoTransform(newResponse),
        dataOriginal: response,
      });

      setLoadPage(false);
    } catch (err) {
      setDataPedido({ load: false, err: true, data: [] });
      setLoadPage(false);
      console.log(err);
    }
  };

  const handleGetUser = async () => {
    const response = await getUserCurrently();
    setUserData(response);
  };

  const handlerFilterPedido = (value) => {
    const newPedido = dataPedido.data.filter((el) => el.label === value);
    setTmpDataPedidos({ load: false, err: false, data: newPedido });
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setValueFilter(value);

    if (value === "") {
      handleGetPedido();
    } else {
      handlerFilterPedido(value);
    }
  };

  const onRedirect = (cod) => {
    history.push(`/orders/add/${cod}`);
  };

  const isCloseCajaMain = !cajaData || cajaData?.status_caja === "CERRADO";
  const isNotActiveToday = userData && userData.activeToday === 0;

  const handlerRefreshOrders = (elements) => {
    if (elements.id_empsede === getUser().empresa.id_empsede) {
      const newResponse = elements.data.filter((el) => el.usu_id === usu_id);
      setDataPedido({
        data: handlePedidoTransform(newResponse),
        load: false,
        err: false,
      });
    }
  };

  // refresca todos los pedidos
  useEffect(() => {
    socket.on(nameSockets.MAIN.client.refreshOrders, handlerRefreshOrders);
    return () => {
      socket.off(nameSockets.MAIN.client.refreshOrders, handlerRefreshOrders);
    };
  }, []);

  useEffect(() => {
    socket.on(nameSockets.SECONDARY.client.refreshOrders, handlerRefreshOrders);
    return () => {
      socket.off(
        nameSockets.SECONDARY.client.refreshOrders,
        handlerRefreshOrders
      );
    };
  }, []);

  //CHECK TOKEN
  useEffect(() => {
    if (!checkToken()) {
      history.push("/");
      return null;
    }

    handleGetUser();
    handleAperturaCaja();
    handleGetPedido();
  }, []);

  return (
    <Template title="Pedidos">
      <LoadingPage open={loadPage} setOpen={setLoadPage} />
      <Grid
        container
        spacing={2}
        style={{
          marginBottom: 10,
          position: "sticky",
          top: "74px",
          background: "white",
          zIndex: 3,
        }}
      >
        <Grid
          item
          xs={12}
          sm={9}
          lg={9}
          style={{
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <TextField
            fullWidth
            disabled={isCloseCajaMain || isNotActiveToday}
            select
            variant="outlined"
            value={valueFilter}
            onChange={handleChange}
            className={`InputDefault`}
            required
            SelectProps={{
              native: true,
            }}
          >
            <option value="">[Mesa]</option>
            {dataPedido.data.map((option, idx) => (
              <option key={idx} value={option.label}>
                {option.label}
              </option>
            ))}
          </TextField>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          lg={3}
          style={{
            textAlign: "right",
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Button
            fullWidth
            className="button-default"
            onClick={() => setOpenMesa(true)}
          >
            Crear pedido
          </Button>
        </Grid>
      </Grid>

      <br />

      <TablePedido dataPedido={tmpDataPedidos} />

      <DialogApertura
        dataPedido={tmpDataPedidos.dataOriginal}
        isCloseCajaMain={isCloseCajaMain}
        isNotActiveToday={isNotActiveToday}
        open={openMesa}
        setOpen={setOpenMesa}
        socket={socket}
        onRedirect={onRedirect}
      />
    </Template>
  );
};

export default Orders;
