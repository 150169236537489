
import TemplateLogin from "../../components/TemplateLogin";
import LoginMyReservations from "./components/LoginMyReservations";

import MyReservationState from "./state.myreservations";

import "./style.myreservations.scss";

const MyReservation = () => {
  return (
    <MyReservationState>
      <TemplateLogin
        title="Mis reservas"
        styleBody={{
          display: "block",
          height: "auto",
          paddingTop: "30px",
          paddingBottom: "30px",
        }}
      >
        <LoginMyReservations />
      </TemplateLogin>
    </MyReservationState>
  );
};

export default MyReservation;
