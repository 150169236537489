import { NavigateNext } from "@mui/icons-material";
import { Breadcrumbs, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { useAppContext } from "./state.ticketBenefit";

const TicketBredCrumb = () => {
  const state = useAppContext();

  return (
    <div style={{ marginTop: 20, marginBottom: 10 }}>
      <Stack spacing={2}>
        <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
          style={{ color: "rgb(51, 5, 141)" }}
        >
          <Link to="/benefit">Todos los beneficios</Link>
          <span aria-current="page">
            {state.dataBenefit?.data?.name_benefit}
          </span>
        </Breadcrumbs>
      </Stack>
    </div>
  );
};

export default TicketBredCrumb;
