/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import * as React from "react";

import { Grid } from "@mui/material/";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import { Alert, Collapse } from "@mui/material";

import { useForm } from "react-hook-form";

import { Close } from "@mui/icons-material";

import { actionTypes } from "../../../../../redux/menu/actions";
import { actionTypesPlato } from "../../../../../redux/plato/actions";

import toast from "react-hot-toast";

import { useDispatch, useSelector } from "react-redux";

import { get_menu } from "../../../../../services/menu.services";
import { delete_plato } from "../../../../../services/plato.services";

const ErrorMsg = ({ wrong, setWrong, message }) => {
  return (
    <Collapse in={wrong}>
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setWrong(false);
            }}
          >
            <Close fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
        severity="error"
      >
        {message}
      </Alert>
    </Collapse>
  );
};

const DialogDelete = () => {
  const dispatch = useDispatch();
  const { dataSelected, openDelete, dataPlato } = useSelector(
    (state) => state.reducer_plato
  );
  const { dataSelected_categoria } = useSelector((state) => state.reducer_menu);

  const { reset, handleSubmit } = useForm({
    defaultValues: dataSelected,
  });

  const [wrong, setWrong] = React.useState({ open: false, message: "" });

  const onOpen = (data) =>
    dispatch({
      type: actionTypesPlato.openModalDelete,
      payload: data,
    });

  const onLoad = (data) =>
    dispatch({
      type: actionTypesPlato.setLoading,
      payload: data,
    });

  const onGet = (data) =>
    dispatch({
      type: actionTypesPlato.getDataPlato,
      payload: data,
    });

  const onGetMenu = (data) =>
    dispatch({
      type: actionTypes.getDataMenu,
      payload: data,
    });

  const onSetDataSelected = (data) =>
    dispatch({
      type: actionTypesPlato.setDataSelected,
      payload: data,
    });

  const handleModal = (value) => {
    onOpen(value);

    if (!value) {
      onSetDataSelected({
        ...dataSelected,
        id_plato: "",
        nombre_plato: "",
        precio_plato: "",
        descripcion_plato: "",
      });
    }
  };

  const handleDelete = async (data) => {
    try {
      onGet({ ...dataPlato, load: true, data: [], err: false });
      onLoad(true);
      handleModal(false);

      let { response, platos } = await delete_plato(data.id_plato);
      if (response.affectedRows === 1) {
        toast.success("El plato fue eliminado correctamente");

        const filter = platos.filter(
          (el) => el.id_categoriapla === dataSelected_categoria.id_categoriapla
        );

        obtainDataMenu();
        onGet({ ...dataPlato, load: false, data: filter, err: false });
      } else {
        toast.error("No se pudo eliminar el plato");
      }

      onLoad(false);
    } catch (err) {
      console.error(err);
      toast.error("Hubo un error al eliminar el producto");
      onLoad(false);
    }
  };

  async function obtainDataMenu() {
    try {
      let response = await get_menu();
      onGetMenu({ load: false, data: response, err: false });
    } catch (err) {
      console.log(err);
      onGetMenu({ load: false, data: [], err: true });
    }
  }

  React.useEffect(() => {
    if (openDelete) {
      reset(dataSelected);
    }
  }, [openDelete]);

  return (
    <>
      <Dialog
        open={openDelete}
        onClose={() => handleModal(false)}
        fullWidth
        maxWidth={"sm"}
      >
        <form onSubmit={handleSubmit(handleDelete)} autoComplete="off">
          <DialogTitle>
            Importante!
            <IconButton
              aria-label="close"
              onClick={() => handleModal(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <ErrorMsg
              wrong={wrong.open}
              setWrong={(el) => setWrong({ ...wrong, open: el })}
              message={wrong.message}
            />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} lg={12}>
                ¿Está seguro que deseas eliminar el plato?
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="info"
              variant="text"
              onClick={() => {
                handleModal(false);
              }}
            >
              Salir
            </Button>
            <Button type="submit" color="error" variant="contained">
              Eliminar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default DialogDelete;
