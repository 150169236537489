/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

import ContainerInput from "../../components/ContainerInput";
import Template from "../../components/Template";

import LoadingPage from "../../components/LoadingPage";

import DialogCrudPlazasSelected from "./DialogCrudPlazasSelected";

import TablePlazaSelected from "../../components/Tables/TablePlazaSelected";

import { get_garzones } from "../../services/garzones.services";
import { get_mesas } from "../../services/mesas.services";
import {
  get_asignacion,
  get_asignacion_id,
  get_plazas_by_id,
} from "../../services/plazas.services";

import { Button, Grid, IconButton } from "@mui/material";

import { Add, ArrowBackIosNew } from "@mui/icons-material/";

import { checkToken } from "../../services/auth.services";

const PlazaSelected = () => {
  let { id } = useParams();

  let history = useHistory();

  const [loadPage, setLoadPage] = useState(false);

  const [dataPlazas, setDataPlazas] = useState({ load: false, data: [] });
  const [dataMesas, setDataMesas] = useState({ load: false, data: [] });
  const [dataGarzones, setDataGarzones] = useState({ load: false, data: [] });

  const [eventsPlaza, setEventsPlaza] = useState([]);

  const [openAdd, setOpenAdd] = useState(false);

  //   const [openEliminarPlaza, setOpenEliminarPlaza] = useState(false);

  const [dataViewData, setDataViewData] = useState("");
  //   const [dataViewEliminarData, setDataViewEliminarData] = useState("");

  //   const [openViewDataByDate, setOpenViewDataByDate] = useState(false);

  const handleGetAsignacionID = async (id) => {
    setLoadPage(true);
    try {
      let respsonse = await get_asignacion_id(id);

      setDataViewData(respsonse);

      setLoadPage(false);
    } catch (err) {
      console.log(err);
      setLoadPage(false);
    }
  };

  const get_plaza = async () => {
    try {
      let response = await get_plazas_by_id(id);

      setDataPlazas({ ...dataPlazas, data: response[0] });
    } catch (err) {
      setLoadPage(false);
      console.log(err.message);
    }
  };

  const get_plaza_detalle = async () => {
    try {
      let response = await get_asignacion(id);
      setEventsPlaza(response);
    } catch (err) {
      setLoadPage(false);
      console.log(err.message);
    }
  };

  const get_mesas_data = async () => {
    try {
      let response = await get_mesas();

      setDataMesas({ ...dataMesas, data: response });
    } catch (err) {
      setLoadPage(false);
      console.log(err.message);
    }
  };

  const get_garzones_data = async () => {
    try {
      let response = await get_garzones();

      setDataGarzones({ ...dataGarzones, data: response });
    } catch (err) {
      setLoadPage(false);
      console.log(err.message);
    }
  };

  useEffect(async () => {
    setLoadPage(true);
    await get_plaza();
    await get_mesas_data();
    await get_garzones_data();
    await get_plaza_detalle();
    setLoadPage(false);
  }, []);

  useEffect(() => {
    if (!checkToken()) {
      history.push("/");
      return null;
    }
  }, []);

  return (
    <Template title="Reservas">
      <LoadingPage open={loadPage} setOpen={setLoadPage} />

      <Grid container spacing={2} style={{ marginBottom: 20 }}>
        <Grid
          item
          xs={12}
          sm={11}
          lg={11}
          style={{
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <Link to="/plazas">
            <IconButton>
              <ArrowBackIosNew />
            </IconButton>
          </Link>

          <label htmlFor="" style={{ fontSize: 18 }}>
            {dataPlazas.data.nombre_plaza}
          </label>
        </Grid>
        <Grid
          item
          xs={12}
          sm={1}
          lg={1}
          style={{
            textAlign: "right",
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Button
            fullWidth
            onClick={() => {
              setOpenAdd(true);
              setDataViewData("");
            }}
            className="button-default"
          >
            <Add />
          </Button>
        </Grid>
      </Grid>

      <ContainerInput
        style={{
          marginTop: 20,
          marginBottom: 20,
          padding: "35px 10px",
          textAlign: "center",
        }}
      >
        <TablePlazaSelected
          rows={eventsPlaza}
          setOpenAdd={setOpenAdd}
          handleGetAsignacionID={handleGetAsignacionID}
        />
      </ContainerInput>

      <DialogCrudPlazasSelected
        id_plaza={id}
        open={openAdd}
        setOpen={setOpenAdd}
        handleGetPlazaDetalle={get_plaza_detalle}
        data={dataViewData}
        dataMesas={dataMesas}
        dataGarzones={dataGarzones}
      />
    </Template>
  );
};

export default PlazaSelected;
