import * as React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import InputDefault from "../../../atomic/atoms/input.atoms";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref || false} {...props} />;
});

const DialogAddProductAll = (props) => {
  let { callback, open, dataPlatos, setOpen } = props;

  const [rowSelected, setRowSelected] = React.useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = (e) => {
    e.preventDefault();

    const payload = rowSelected.map((el) => ({
      amount: el.amount <= 0 ? 0 : Number(el.amount),
      id: el.value,
    }));

    handleClose();
    callback(payload);
  };

  const handleChangeAmount = (e, id) => {
    const { value, name } = e.target;

    const index = rowSelected.findIndex((el) => el.value === id);
    const newRows = [...rowSelected];
    newRows[index][name] = value;
    setRowSelected(newRows);
  };

  React.useEffect(() => {
    setRowSelected(dataPlatos.map((item) => ({ ...item, amount: 0 })));
  }, [dataPlatos]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth
      onClose={handleClose}
      maxWidth={"sm"}
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form onSubmit={handleConfirm}>
        <DialogTitle id="alert-dialog-title">
          Agregar stock a platos de fondo
        </DialogTitle>
        <DialogContent>
          <br />
          {rowSelected.length === 0 ? (
            <h3 style={{ textAlign: "center" }}>No encontró registros =(</h3>
          ) : (
            <TableContainer
              sx={{ maxHeight: 550 }}
              style={{
                border: "1px solid #e0e0e0",
              }}
            >
              <Table stickyHeader aria-label="sticky table" size={"small"}>
                <TableHead>
                  <TableRow>
                    <TableCell align={"left"}>Plato</TableCell>
                    <TableCell align={"center"}>Cantidad</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowSelected.map((item, index) => (
                    <TableRow hover key={index} tabIndex={-1}>
                      <TableCell align={"left"}>{item.label}</TableCell>
                      <TableCell align={"right"}>
                        <InputDefault
                          fullWidth
                          label="Cantidad"
                          name="amount"
                          value={item.amount}
                          onChange={(e) => {
                            const inputValue = Number(e.target.value);
                            handleChangeAmount(
                              {
                                target: {
                                  name: e.target.name,
                                  value:
                                    Number(e.target.value) <= 0
                                      ? 0
                                      : inputValue,
                                },
                              },
                              item.value
                            );
                          }}
                          variant="outlined"
                          size="small"
                          type="number"
                          inputProps={{
                            min: 0,
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="text" color="inherit">
            Cerrar
          </Button>
          <Button type="submit" variant="contained" color="error">
            Agregar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogAddProductAll;
