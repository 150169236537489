/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// import ListCategories from "./ListCategories";
import NotFound from "../atomic/organisms/NotFound.organisms";

import { formatMoney } from "../functions/formatMoney";

const AcordionCartaComponent = (props) => {
  let { data, dataEmpresa } = props;

  const rows = data.data;
  const load = data.load;
  const err = data.err;

  const colorPrimary = dataEmpresa.color_primary;
  const moneda = dataEmpresa.moneda_empsede;

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (load) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress color="inherit" /> <br />
        <Typography component="h5" variant="h5" style={{ textAlign: "center" }}>
          Cargando...
        </Typography>
      </div>
    );
  }

  if (err) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <NotFound title="¡Ocurrió un problema!" />
      </div>
    );
  }

  return (
    <>
      {rows.map((el, idx) => (
        <Fragment key={idx}>
          <Accordion
            expanded={expanded === el.id_categoriapla}
            onChange={handleChange(el.id_categoriapla)}
            style={{
              marginBottom: 5,
              boxShadow: "none",
              borderRadius: 8,
              border:
                expanded === el.id_categoriapla
                  ? `1px ${colorPrimary} solid`
                  : "1px #e0e0e0 solid",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${idx}-panel1bh-header`}
              id={`${idx}-panel1bh-header`}
              style={{ width: "100%", padding: "5px 15px" }}
            >
              <Typography sx={{ width: "100%" }}>
                {el.nombre_categoriapla} ({el.platos.length})
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {el.platos.map((elP, idxP) => {
                if (elP.amount_proagotado === 0) {
                  return null;
                }

                return (
                  <Fragment key={idxP}>
                    <Card
                      style={{
                        marginBottom: 5,
                        boxShadow: "none",
                        border: "1px #e0e0e0 solid",
                      }}
                    >
                      <CardContent>
                        <Typography
                          variant="inherit"
                          component="b"
                          style={{ color: colorPrimary }}
                        >
                          {elP.nombre_plato}
                        </Typography>{" "}
                        <br />
                        <Typography
                          variant="overline"
                          style={{ lineHeight: 1, color: "#616060" }}
                        >
                          {elP.descripcion_plato}
                        </Typography>
                        <br />
                        <br />
                        <Typography
                          style={{
                            lineHeight: 1,
                            fontSize: "20px",
                            textAlign: "right",
                            color: "#535353",
                          }}
                        >
                          {formatMoney(elP.precio_plato, moneda)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Fragment>
                );
              })}
            </AccordionDetails>
          </Accordion>
        </Fragment>
      ))}
    </>
  );
};

export default AcordionCartaComponent;
