import { Grid } from "@mui/material";
import { forwardRef, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import InputDefault from "../../atomic/atoms/input.atoms";
import { useAppContext } from "./state.ticketBenefit";

const FormBenefit = forwardRef((props, ref) => {
  const state = useAppContext();

  const { control, handleSubmit, reset } = useForm({
    defaultValues: props.defaultValues,
  });

  const newDataUser = state.dataUser.map((item) => {
    return {
      value: item.usu_id,
      label: item.usu_nombre,
    };
  });

  const onSubmit = (data) => {
    // action to do with data from form
    props.action(data);

    // reset form
    reset(props.defaultValues);
  };

  useEffect(() => {
    reset(props.defaultValues);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultValues]);

  return (
    <form ref={ref} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <Controller
            name="idUsu"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                required
                fullWidth
                value={value}
                onChange={onChange}
                type="select"
                title={"Promotores"}
                options={newDataUser}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="quantity"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                required
                fullWidth
                value={value || ""}
                onChange={onChange}
                type="number"
                title={"Cantidad de tickets"}
              />
            )}
          />
        </Grid>
      </Grid>
      <input type="submit" hidden />
    </form>
  );
});

export default FormBenefit;
