import React, { useState, useEffect } from "react";

import { Button } from "@mui/material";

import Template from "../../components/Template";
import ContainerInput from "../../components/ContainerInput";
import ToDayBox from "../../components/ToDayBox";

import DialogAssignTable from "./Dialog/DialogAssignTable";
import TableMesas from "./Table/TableMesas";

import {
  get_mesa_garzon,
  delete_mesa_garzon,
  get_mesa_garzon_today,
} from "../../services/mesas.services";

import toast from "react-hot-toast";

const MesasGarzones = (props) => {
  //   const { socket } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [loadPage, setLoadPage] = useState(false);

  const [modeCrud, setModeCrud] = useState("add");
  const [dataMesasGarzon, setDataMesaGarzon] = useState([]);
  const [selectedTable, setSelectedTable] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState("");

  const [dataTable, setDataTable] = React.useState({ data: [] });

  const handleGetMesaGarzon = async () => {
    try {
      setLoadPage(true);
      const response = await get_mesa_garzon();
      setLoadPage(false);
      setDataMesaGarzon(response);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteMesaGarzon = async (id) => {
    try {
      await delete_mesa_garzon(id);
      await handleGetMesaGarzon();

      toast.success("Mesas eliminadas correctamente.");
    } catch (err) {
      console.log(err);
    }
  };

  const handleEdit = async (data) => {
    const mesas = data.mesas.split(",");

    setLoadPage(true);
    const dataTableToday = await get_mesa_garzon_today();
    setLoadPage(false);

    const newMesas = mesas.map((elMesa) => {
      return {
        ...dataTableToday.find((el) => el.codigo_mesa === elMesa),
      };
    });

    const newDataTable = dataTableToday.map((elMesa) => {
      const newMesa = newMesas.find(
        (el) => el.codigo_mesa === elMesa.codigo_mesa
      );

      if (newMesa) {
        return { ...newMesa, ocupado_mesa: 0 };
      }

      return { ...elMesa };
    });

    setDataTable({ data: newDataTable, load: false, err: false });

    setModeCrud("edit");

    setSelectedUser(data.usu_id);
    setSelectedTable(newMesas);

    handleDialog(true);
  };

  const handleAdd = async () => {
    setLoadPage(true);
    const dataTableToday = await get_mesa_garzon_today();
    setLoadPage(false);

    setDataTable({ data: dataTableToday, load: false, err: false });

    setModeCrud("add");

    setSelectedUser("");
    setSelectedTable([]);

    handleDialog(true);
  };

  const handleDialog = (value) => {
    setOpenDialog(value);
  };

  // OBTAIN SERVICES
  const handleGetMesas = async () => {
    try {
      setDataTable({ data: [], load: true, err: false });
      const response = await get_mesa_garzon_today();
      setDataTable({ data: response, load: false, err: false });
    } catch (err) {
      console.log(err);
      setDataTable({ data: [], load: false, err: true });
    }
  };

  useEffect(() => {
    handleGetMesaGarzon();
    handleGetMesas();
  }, []);

  useEffect(() => {
    // if (!openDialog) {
    //   handleGetMesas();
    // }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialog]);

  return (
    <Template title="Inicio">
      <ToDayBox open={loadPage} setOpen={setLoadPage}>
        <div style={{ textAlign: "right" }}>
          <Button
            variant="contained"
            color="success"
            onClick={() => handleAdd()}
          >
            Agregar
          </Button>
        </div>
        <br />
        <ContainerInput
          style={{
            padding: "5px 15px",
          }}
        >
          <TableMesas
            rows={dataMesasGarzon}
            handleDeleteMesaGarzon={handleDeleteMesaGarzon}
            handleEdit={handleEdit}
          />
        </ContainerInput>
      </ToDayBox>

      <DialogAssignTable
        modeCrud={modeCrud}
        refreshList={handleGetMesaGarzon}
        dataMesasGarzon={dataMesasGarzon}
        open={openDialog}
        setOpen={setOpenDialog}
        dataTable={{ dataTable, setDataTable }}
        selected={{
          table: {
            selectedTable,
            setSelectedTable,
          },
          user: {
            selectedUser,
            setSelectedUser,
          },
        }}
      />
    </Template>
  );
};

export default MesasGarzones;
