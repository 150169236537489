import { useHistory } from "react-router-dom";

import FormReserva from "./formReserva";

import "../../assets/styles/templateReservas.scss";

import IMAGE_1 from "../../assets/images/verde/image1.png";
import IMAGE_2 from "../../assets/images/verde/image2.png";
import IMAGE_3 from "../../assets/images/verde/image3.png";
import IMAGE_4 from "../../assets/images/verde/image4.png";

import PEOPLE_1 from "../../assets/images/verde/people1.png";
import PEOPLE_2 from "../../assets/images/verde/people2.png";
import PEOPLE_3 from "../../assets/images/verde/people3.png";
import PEOPLE_4 from "../../assets/images/verde/people4.png";

import { motion } from "framer-motion";

import VerdeMostazaTemplate from "./templateReservarVerdeMostaza";


const fadeIn = (direction, delay) => {
  return {
    hidden: {
      y: direction === "up" ? 80 : direction === "down" ? -80 : 0,
      opacity: 0,
      x: direction === "left" ? 80 : direction === "right" ? -80 : 0,
    },
    show: {
      y: 0,
      x: 0,
      opacity: 1,
      transition: {
        type: "tween",
        duration: 0.5,
        delay: delay,
        ease: [0.25, 0.25, 0.25, 0.75],
      },
    },
  };
};

const ReservaVerdeMostaza = ({
  idEmp,
  selectedSucursal,
  nameSelectedSucursal,
}) => {
  const history = useHistory();

  return (
    <VerdeMostazaTemplate selectedSucursal={selectedSucursal}>
      <div className="template-carta--body__size">
        <div className="list-images">
          <motion.div
            variants={fadeIn("up", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.6 }}
            className="card-special"
          >
            <img src={IMAGE_1} alt="image1" className="image1" />
            <img src={IMAGE_2} alt="image2" className="image2" />
            <img src={IMAGE_3} alt="image3" className="image3" />
            <img src={IMAGE_4} alt="image4" className="image4" />
            <h3>NO TE PIERDAS DE</h3>
          </motion.div>
          <ul>
            <li>
              <motion.div
                variants={fadeIn("up", 0.5)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.6 }}
                className="card-image"
              >
                <img src={PEOPLE_1} alt="image1" />
                <div className="card-image--title">
                  <h3>UN KARAOKE DIFERENTE</h3>
                </div>
              </motion.div>
            </li>
            <li>
              <motion.div
                variants={fadeIn("up", 0.8)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.6 }}
                className="card-image"
              >
                <img src={PEOPLE_2} alt="image1" />
                <div className="card-image--title">
                  <h3>MÁS QUE UNA VIESTA UN VIAJE</h3>
                </div>
              </motion.div>
            </li>
            <li>
              <motion.div
                variants={fadeIn("up", 1)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.6 }}
                className="card-image"
              >
                <img src={PEOPLE_3} alt="image1" />
                <div className="card-image--title">
                  <h3>FESTEJEMOS JUNTOS TU CUMPLE</h3>
                </div>
              </motion.div>
            </li>
            <li>
              <motion.div
                variants={fadeIn("up", 1.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.6 }}
                className="card-image"
              >
                <img src={PEOPLE_4} alt="image1" />
                <div className="card-image--title">
                  <h3>QUE VUELVAN LOS LENTES</h3>
                </div>
              </motion.div>
            </li>
          </ul>
        </div>

        <motion.div
          variants={fadeIn("down", 1.4)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: true, amount: 0.1 }}
          className="form-inputs"
        >
          <FormReserva
            idEmp={idEmp}
            idEmpSede={selectedSucursal}
            callback={() => {
              history.push(
                `/reservar/final/verdemostaza?name=${nameSelectedSucursal}`
              );
            }}
          />
        </motion.div>
      </div>
    </VerdeMostazaTemplate>
  );
};

export default ReservaVerdeMostaza;
