import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";

import { pay_type } from "../../../services/pedidos.services";
import CardReport from "../CardReport";

import { formatMoney } from "../../../functions/formatMoney";
import { getUser } from "../../../services/auth.services";

const Propinas = (props) => {
  const { style, socket } = props;
  const [payType, setPayType] = useState({
    load: true,
    err: false,
    data: [],
  });

  const handlePayType = async (params) => {
    if (params?.id_empsede === getUser().empresa?.id_empsede) {
      try {
        const response = await pay_type();
        const newReponse = response.filter(
          (payType) => payType.tipodepago_pedido !== null
        );

        setPayType({
          load: false,
          err: false,
          data: newReponse,
        });
      } catch (err) {
        setPayType({ load: false, err: true, data: [] });
        console.log(err);
      }
    }
  };

  // refresca todos los pedidos
  useEffect(() => {
    if (!socket) {
      return;
    }

    try {
      socket.on("server:refreshOrders", handlePayType);
      return () => {
        socket.off("server:refreshOrders", handlePayType);
      };
    } catch (err) {
      console.log(err);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handlePayType({ id_empsede: getUser().empresa.id_empsede });
  }, []);

  return (
    <CardReport style={style} title={"Formas de pago"} dataReport={payType}>
      <Grid container style={{ fontSize: "13px" }}>
        {payType.data.map((el, idx) => {
          return (
            <React.Fragment key={idx}>
              <Grid item xs={8} style={{ textAlign: "left" }}>
                {el.tipodepago_pedido} ({el.tot_pago})
              </Grid>
              <Grid item xs={4} style={{ textAlign: "right" }}>
                {formatMoney(el.pagototal_pedido ? el.pagototal_pedido : 0)}
              </Grid>
              <Grid item xs={12}>
                <hr style={{ opacity: "0.3", margin: "2px" }} />
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    </CardReport>
  );
};

export default Propinas;
