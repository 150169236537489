import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import CustomTable from "../../atomic/organisms/Table.organisms";
import ContainerInput from "../../components/ContainerInput";
import Template from "../../components/Template";
import { getWallet } from "../../services/wallet.services";

import { Search } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import InputDefault from "../../atomic/atoms/input.atoms";
import { get_user } from "../../services/user.services";
import BasicDateRangePicker from "../quotation/ui/BasicDateRangePicker.quotation";
import ModalPayMerchante from "./ui/ModalPay.pay-merchante";
import ModalAccountInfoMerchante from "./ui/Modal-accountinfo-merchante";

const ID_PERFIL_MERCHANT = 14;

export const formatNumber = (n) => {
  return n === "" || n === 0 ? n : Number(n).toLocaleString();
};

const calcToPay = (data) => {
  return (
    (Number(data.quantity_sold) - Number(data.quantity_released)) *
    Number(data.monto_comission)
  );
};

const statusComponent = (status) => {
  return (
    <div
      className={`px-2 py-1 text-center rounded-full text-xs font-semibold ${
        status === "pendiente"
          ? "bg-yellow-200 text-yellow-800"
          : "bg-green-200 text-green-800"
      }`}
    >
      {status.toUpperCase()}
    </div>
  );
};

const Filters = (props) => {
  const { onSearch, onOpenPay, isEnablebuttonOnPay } = props;

  const { control, handleSubmit } = useForm({
    defaultValues: {
      searchName: "",
      dateRange: [],
      usuId: null,
      status: "pendiente",
    },
  });

  const [dataUser, setDataUser] = useState([]);

  async function handleGetUsers() {
    const response = await get_user();
    const newResponse = response
      .filter((el) => el.tipousu_id === ID_PERFIL_MERCHANT)
      .map((el) => {
        return {
          ...el,
          value: el.usu_id,
          label: el?.usu_nombre.toUpperCase(),
        };
      });

    setDataUser(newResponse || []);
  }

  useEffect(() => {
    handleGetUsers();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSearch)} autoComplete="off">
      <div className="mb-4 w-full grid grid-cols-12 gap-2">
        <div className="col-span-2">
          <Controller
            name="status"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                value={value}
                onChange={onChange}
                title={"Estado"}
                type={"select"}
                options={[
                  { value: "pendiente", label: "Pendiente" },
                  { value: "pagado", label: "Pagado" },
                ]}
              />
            )}
          />
        </div>
        <div className="col-span-3">
          <Controller
            name="usuId"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                value={value}
                onChange={onChange}
                title={"Merchante"}
                type={"select"}
                options={dataUser}
              />
            )}
          />
        </div>
        <div className="col-span-2">
          <Controller
            name="searchName"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                value={value}
                onChange={onChange}
                type={"text"}
                title={"Buscar por código"}
              />
            )}
          />
        </div>
        <div className="col-span-4 flex gap-3">
          <Controller
            name="dateRange"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <BasicDateRangePicker value={value} onChange={onChange} />
            )}
          />

          <IconButton
            type="submit"
            color="default"
            className="ml-auto col-span-1 !text-blue-800 !bg-gray-100"
          >
            <Search />
          </IconButton>
        </div>

        <div className="col-span-1">
          <Button
            variant="contained"
            disabled={!isEnablebuttonOnPay}
            color="primary"
            fullWidth
            className="ml-auto"
            onClick={() => onOpenPay()}
          >
            Pagar
          </Button>
        </div>
      </div>
    </form>
  );
};

const WalletHistoryPage = () => {
  const [dataWallet, setDataWallet] = React.useState([]);
  const [load, setLoad] = React.useState(false);
  const [sumTotal, setSumTotal] = React.useState(0);
  const [openPay, setOpenPay] = React.useState(false);
  const [tmpFilter, setTmpFilter] = React.useState({});
  const [openAccountInfo, setOpenAccountInfo] = React.useState(false);
  const [selectedMerchante, setSelectedMerchante] = React.useState({
    usu_bank: null,
    usu_bank_type: null,
    usu_bank_number_account: null,
    usu_nombre: null,
    usu_email: null,
    usu_direccion: null,
    usu_phone: null,
    usu_rut: null,
    usu_comission_value: null,
  });

  const fetchDataWallet = async () => {
    try {
      setLoad(true);
      const data = await getWallet();

      setLoad(false);

      const newData = data
        .map((el) => {
          return {
            ...el,
            selected: false,
            canBeSelectable: el.status_wallet === "pendiente",
            quantity_to_pay: calcToPay(el),
            name_reserva: `${el.nombre_reserva || ""} ${
              el.apellido_paterno_reserva || ""
            } ${el.apellido_materno_reserva || ""}`,
            people_served:
              Number(el.quantity_sold) - Number(el.quantity_released),
          };
        })
        .filter((el) => el.status_wallet === "pendiente");

      setDataWallet(newData);

      const total = newData.reduce((acc, el) => {
        return acc + el.quantity_to_pay;
      }, 0);

      setSumTotal(total);
    } catch (error) {
      console.error("Error en fetchDataWallet: ", error);
    }
  };

  const onSearchData = async (value) => {
    // save filter in tmpFilter state to use it in the future
    setTmpFilter(value);

    const response = await getWallet();

    const newResponse = response.map((el) => {
      return {
        ...el,
        selected: false,
        canBeSelectable: el.status_wallet === "pendiente",
        quantity_to_pay: calcToPay(el),
        name_reserva: `${el.nombre_reserva || ""} ${
          el.apellido_paterno_reserva || ""
        } ${el.apellido_materno_reserva || ""}`,
      };
    });

    let filterData = newResponse;

    if (value?.status) {
      filterData = filterData.filter((el) => el.status_wallet === value.status);
    }

    if (value?.searchName) {
      filterData = filterData.filter((el) =>
        el.id_reserva.toString().includes(value.searchName.toLowerCase())
      );
    }

    if (value?.usuId) {
      filterData = filterData.filter((el) => el.usu_id === Number(value.usuId));
    }

    if (value?.dateRange && value?.dateRange.length > 0) {
      filterData = filterData.filter((el) => {
        const date = dayjs(el.created_at).utc();
        return (
          date.isAfter(dayjs(value.dateRange[0]).utc()) &&
          date.isBefore(dayjs(value.dateRange[1]).utc())
        );
      });
    }

    setDataWallet(filterData);

    const total = filterData.reduce((acc, el) => {
      return acc + el.quantity_to_pay;
    }, 0);

    setSumTotal(total);
  };

  const onRowSelected = (row) => {
    setDataWallet((prev) => {
      return prev.map((el) => {
        if (el.id_reserva === row.id_reserva) {
          return {
            ...el,
            selected: !el.selected,
          };
        }

        return el;
      });
    });
  };

  const onSelectedMerchante = (el) => {
    const removeSpacesAndLowerCase = (text) =>
      text && text !== "" && text.trim().toUpperCase().replace(/\s/g, "");

    const searchMerchante = dataWallet.find((elW) => {
      return (
        removeSpacesAndLowerCase(elW.usu_nombre) ===
        removeSpacesAndLowerCase(el)
      );
    });

    setSelectedMerchante({
      usu_bank: searchMerchante?.usu_bank,
      usu_bank_type: searchMerchante?.usu_bank_type,
      usu_bank_number_account: searchMerchante?.usu_bank_number_account,
      usu_nombre: searchMerchante?.usu_nombre,
      usu_email: searchMerchante?.usu_email,
      usu_direccion: searchMerchante?.usu_direccion,
      usu_phone: searchMerchante?.usu_phone,
      usu_rut: searchMerchante?.usu_rut,
      usu_comission_value: searchMerchante?.usu_comission_value,
    });

    setOpenAccountInfo(true);
  };

  const thereAreSelected = dataWallet.some((el) => el.selected);

  useEffect(() => {
    fetchDataWallet();
  }, []);

  return (
    <Template title="Reporte" style={{ position: "relative" }}>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-12">
          <Filters
            onSearch={onSearchData}
            isEnablebuttonOnPay={thereAreSelected}
            onOpenPay={() => setOpenPay(true)}
          />
        </div>

        <div className="col-span-12 md:col-span-12">
          <ContainerInput className="p-0">
            <CustomTable
              onRowSelected={onRowSelected}
              columns={[
                {
                  id: "id_reserva",
                  label: "Código",
                  minWidth: 80,
                  align: "left",
                },
                {
                  id: "usu_nombre",
                  label: "Merchante",
                  minWidth: 80,
                  align: "left",
                  format: (el) => {
                    return (
                      <div
                        className="text-blue-500 cursor-pointer hover:underline flex align-middle items-center"
                        onClick={() => onSelectedMerchante(el)}
                      >
                        {el.toUpperCase()}
                      </div>
                    );
                  },
                },
                {
                  id: "quantity_sold",
                  label: "Personas vendidas",
                  minWidth: 80,
                  align: "left",
                },
                {
                  id: "quantity_released",
                  label: "Personas liberadas",
                  minWidth: 80,
                  align: "left",
                },
                {
                  id: "monto_comission",
                  label: "Comisión",
                  minWidth: 80,
                  align: "left",
                  format: (el) => {
                    return `$ ${formatNumber(el)}`;
                  },
                },
                {
                  id: "quantity_to_pay",
                  label: "Cantidad a pagar",
                  minWidth: 150,
                  align: "left",
                  format: (el) => {
                    return `$ ${formatNumber(el)}`;
                  },
                },
                {
                  id: "status_wallet",
                  label: "Estado",
                  minWidth: 150,
                  align: "left",
                  format: (el) => {
                    return statusComponent(el);
                  },
                },
                {
                  id: "date_paid",
                  label: "Fecha de pago",
                  minWidth: 100,
                  align: "left",
                  format: (el) => {
                    return el
                      ? dayjs(el).utc().format("DD/MM/YYYY HH:mm")
                      : "N/A";
                  },
                },
                {
                  id: "created_at",
                  label: "Fecha creación",
                  minWidth: 160,
                  align: "left",
                  format: (el) => {
                    return dayjs(el).utc().format("DD/MM/YYYY");
                  },
                },
              ]}
              rows={dataWallet}
              load={load}
              error={false}
              pagination={true}
            />
          </ContainerInput>
          <ContainerInput className="p-3 mt-2">
            <div className="flex justify-end">
              <p className="text-sm font-semibold">Total: </p>
              <p className="text-sm">$ {formatNumber(sumTotal)}</p>
            </div>
          </ContainerInput>
        </div>
      </div>

      <ModalPayMerchante
        open={openPay}
        selected={dataWallet.filter((el) => el.selected)}
        setSelected={null}
        setOpen={setOpenPay}
        callBack={() => {
          onSearchData({ ...tmpFilter, status: "pendiente" });
        }}
      />

      <ModalAccountInfoMerchante
        open={openAccountInfo}
        setOpen={setOpenAccountInfo}
        selected={selectedMerchante}
      />
    </Template>
  );
};

export default WalletHistoryPage;
