import React from "react";

import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import { toast } from "react-hot-toast";
import { update_quotation } from "../../services/quotation.services";

const ModalQuotationApprove = (props) => {
  const { open, setOpen, selected, callBack } = props;

  const [load, setLoad] = React.useState(false);

  const onAction = async () => {
    setLoad(true);

    const idQuotation = selected?.id_quotation;

    await update_quotation({ statusQuotation: "aprobado" }, idQuotation);

    await callBack();

    toast.success("Se aprobó la cotización correctamente.", {
      position: "top-center",
    });

    setLoad(false);
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth={"sm"}
      aria-describedby="dialog-benefit"
    >
      <DialogTitle>
        Aprobar cotización
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={`${load && "animate-pulse"} `}>
        <br />
        <p>
          ¿Está seguro de aprobar la cotización{" "}
          <strong>{selected?.cod_quotation}</strong>?
        </p>
      </DialogContent>
      <DialogActions>
        <Button
          disable={load.toString()}
          color="inherit"
          variant="text"
          onClick={() => {
            setOpen(false);
          }}
        >
          Salir
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={onAction}
          disable={load.toString()}
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ModalQuotationApprove;
