/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Template from "../../components/Template";

import { checkToken } from "../../services/auth.services";
import { get_pedido_cocinero } from "../../services/pedidos.services";

import ContainerInput from "../../components/ContainerInput";
import LoadingPage from "../../components/LoadingPage";
import CardProduct from "./Components/CardProduct";

import { Grid } from "@mui/material";

import "./style.cookordes.scss";

import NotFound from "../../atomic/organisms/NotFound.organisms";

const hideProductsFinished = (dataProducts) => {
  const newDataProducts = [];

  dataProducts.forEach((item) => {
    const rowProduct = item.productos.length;

    if (rowProduct === 0) {
      return;
    }

    const rowSearchProductFinished = item.productos.filter(
      (el) => el.status_plapedido === "TERMINADO"
    ).length;

    if (rowProduct !== rowSearchProductFinished) {
      return;
    }

    newDataProducts.push(item);
  });

  return newDataProducts;
};

const Cook = (props) => {
  const { dataPedido } = props;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <ContainerInput
          style={{
            marginTop: 20,
            marginBottom: 20,
            padding: "20px 10px",
            width: "100%",
            overflow: "auto",
          }}
        >
          <div className="Grid-container-Pedidos">
            {dataPedido.data.length === 0 ? (
              <div style={{ textAlign: "center" }}>
                <NotFound texto="No se encontró registros." />
              </div>
            ) : (
              <div style={{ display: "inline-flex" }}>
                {dataPedido.data.map((el, idx) => {
                  return (
                    <div
                      key={idx}
                      className="GridItemPedido"
                      style={{ width: 350, marginRight: "15px" }}
                    >
                      <CardProduct
                        handleChangeStatus={() => {}}
                        data={el}
                        disabledButton={false}
                        timeMax={30}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </ContainerInput>
      </Grid>
    </Grid>
  );
};

const CookOrders = () => {
  let history = useHistory();

  const [loadPage, setLoadPage] = useState(false);

  const [dataPedido, setDataPedido] = useState({
    load: false,
    err: false,
    data: [],
  });

  const handleGetPedido = async () => {
    try {
      setDataPedido({ ...dataPedido, load: true, err: false }); /* load */
      const response = await get_pedido_cocinero(null);
      const newResponse = hideProductsFinished(response);
      setDataPedido({ load: false, err: false, data: newResponse }); /* data */
    } catch (err) {
      setDataPedido({ load: false, err: true, data: [] });
      setLoadPage(false);
      console.log(err);
    }
  };

  const getPedidos = async () => {
    setLoadPage(true);
    await handleGetPedido();
    setLoadPage(false);
  };

  //CHECK TOKEN
  useEffect(() => {
    if (!checkToken()) {
      history.push("/");
      return null;
    }

    getPedidos();
  }, []);

  return (
    <Template title="Historial de pedidos">
      <LoadingPage open={loadPage} setOpen={setLoadPage} />
      <Cook dataPedido={dataPedido} />
    </Template>
  );
};

export default CookOrders;
