/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import * as React from "react";

import Chip from "@mui/material/Chip";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material/";

import { Close } from "@mui/icons-material";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import "moment/locale/es";

dayjs.extend(utc);

const getStatusData = (data) => {
  if (data.length === 0) {
    return;
  }

  let paxPending = 0;
  let paxWaiting = 0;
  let paxConfirmed = 0;

  const pending = data.filter((el) => el.estado_reserva === "pendiente").length;
  const waiting = data.filter((el) => el.estado_reserva === "en espera").length;
  const confirmed = data.filter(
    (el) =>
      el.estado_reserva === "confirmado" ||
      el.estado_reserva === "re confirmado"
  ).length;

  data.forEach((el) => {
    if (el.estado_reserva === "pendiente") {
      paxPending += el.pax_reserva;
    }
    if (el.estado_reserva === "en espera") {
      paxWaiting += el.pax_reserva;
    }
    if (
      el.estado_reserva === "confirmado" ||
      el.estado_reserva === "re confirmado"
    ) {
      paxConfirmed += el.pax_reserva;
    }
  });

  return {
    waiting: `${waiting} - (${paxWaiting} PAX)`,
    pending: `${pending} - (${paxPending} PAX)`,
    confirmed: `${confirmed} - (${paxConfirmed} PAX)`,
  };
};

const DialogViewDataForDay = (props) => {
  const {
    dateSelected,
    open,
    setOpen,
    dataViewDataByDate,
    dataViewDataTotals,
    dataCapacity,
    TableComponent,
    isLoading,
  } = props;

  const [loading, setLoading] = React.useState(false);

  const [statusData, setSatusData] = React.useState({
    waiting: 0,
    pending: 0,
    confirmed: 0,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const capacity = dataCapacity
    ? dataCapacity.find(
        (el) =>
          dayjs.utc(el.date_capacidadate).format("YYYY-MM-DD") === dateSelected
      )?.quantity_capacidate
    : 0;

  const currentCapacity = dataViewDataTotals.reduce((acc, el) => {
    return acc + (el.estado_reserva !== "cancelado" ? el.pax_reserva : 0);
  }, 0);

  React.useEffect(() => {
    if (open) {
      const response = getStatusData(dataViewDataTotals);
      setSatusData(response);
    }
  }, [open, dataViewDataTotals]);

  React.useEffect(() => {
    if (isLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isLoading]);

  if (loading) {
    return (
      <Dialog open={open} onClose={handleClose} maxWidth={""}>
        <div
          style={{
            display: "grid",
            placeContent: "center",
            height: "100%",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <CircularProgress style={{ color: "#e0004d", marginBottom: 5 }} />
            <div style={{ fontSize: "18px", color: "#e0004d" }}>Cargando</div>
          </div>
        </div>
      </Dialog>
    );
  }

  if (!capacity) {
    return (
      <Dialog open={open} onClose={handleClose} maxWidth={""}>
        <div
          style={{
            display: "grid",
            placeContent: "center",
            height: "100%",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <div style={{ fontSize: "18px" }}>
              <h2>
                <strong>¡Atención!</strong>
              </h2>
              Este día no cuenta con capacidad, por favor seleccione otro día o
              agregue una capacidad.
              <br />
              <br />
              <Button
                type="button"
                variant="contained"
                color="secondary"
                onClick={handleClose}
                style={{
                  display: "block",
                  margin: "auto",
                }}
              >
                Salir
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth={""}>
        <DialogTitle
          style={{
            background: "#eee",
            marginBottom: 12,
            borderBottom: "1px solid #e0e0e0",
          }}
        >
          Datos de la fecha
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Table
            size="small"
            style={{
              border: "1px #eee solid",
              position: "sticky",
              top: 0,
              background: "white",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ background: "#eee", textAlign: "center" }}>
                  Capacidad Disponible
                </TableCell>
                <TableCell style={{ background: "#eee", textAlign: "center" }}>
                  Capacidad Máxima
                </TableCell>
                <TableCell style={{ background: "#eee", textAlign: "center" }}>
                  Capacidad Actual
                </TableCell>
                <TableCell style={{ background: "#eee", textAlign: "center" }}>
                  En espera
                </TableCell>
                <TableCell style={{ background: "#eee", textAlign: "center" }}>
                  Pendiente
                </TableCell>
                <TableCell style={{ background: "#eee", textAlign: "center" }}>
                  Confirmado
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={{ textAlign: "center" }}>
                  <Chip label={capacity - currentCapacity} color="primary" />
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  <Chip label={capacity} color="error" />
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  <Chip label={currentCapacity} color="secondary" />
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  <Chip label={statusData?.waiting || 0} color="info" />
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  <Chip label={statusData?.pending || 0} color="warning" />
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  <Chip label={statusData?.confirmed || 0} color="success" />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <br />
          {TableComponent && (
            <div style={{ border: "1px solid #e0e0e0" }}>
              <TableComponent data={dataViewDataByDate} />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="info"
            variant="text"
            onClick={() => {
              setOpen(false);
            }}
          >
            Salir
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogViewDataForDay;
