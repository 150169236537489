import Head from "../../components/Head";
import LoadingPage from "../../components/LoadingPage";

import MyReservationState, { useAppContext } from "./state.myreservations";

import "./style.myreservations.scss";

const MainStatus = () => {
  const state = useAppContext();

  if (state.loading) {
    return <LoadingPage open={true} />;
  }

  return (
    <main className="Login">
      <div className="Login__Body">
        <div className="Login_Container">
          <h1>
            Su reserva fue{" "}
            {state.status === "confirm" ? (
              <span
                style={{
                  color: "green",
                }}
              >
                confirmada
              </span>
            ) : (
              <span
                style={{
                  color: "red",
                }}
              >
                cancelada
              </span>
            )}{" "}
            correctamente.
          </h1>
        </div>
      </div>
    </main>
  );
};

const MyReservationStatus = () => {
  const title = "Estado de mi reserva";

  return (
    <MyReservationState>
      <div className="container">
        <Head>
          <title>{`${title} | Ubuntu`}</title>
        </Head>

        <MainStatus />
      </div>
    </MyReservationState>
  );
};

export default MyReservationStatus;
