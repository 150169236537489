import dayjs from "dayjs";
import React, { useEffect } from "react";
import CustomTable from "../../../atomic/organisms/Table.organisms";
import ContainerInput from "../../../components/ContainerInput";
import Template from "../../../components/Template";

import { Add, Search } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import InputDefault from "../../../atomic/atoms/input.atoms";
import { get_data_company_all } from "../../../services/empresa.services";

import ModalCompany from "./ui/modal.company";
import ModalCompanyDelete from "./ui/modal.company.delete";

const Filters = (props) => {
  const { onSearch, onOpenAddModal } = props;

  const { control, handleSubmit } = useForm({
    defaultValues: {
      searchName: "",
    },
  });

  return (
    <form onSubmit={handleSubmit(onSearch)} autoComplete="off">
      <div className="mb-4 w-full grid grid-cols-12 gap-2">
        <div className="col-span-5 flex gap-1">
          <Controller
            name="searchName"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                value={value}
                onChange={onChange}
                type={"text"}
                fullWidth
                title={"Buscar por nombre"}
              />
            )}
          />
          <IconButton type="submit" color="default" className="ml-0">
            <Search />
          </IconButton>
        </div>

        <div className="col-span-12 flex justify-end mb-4">
          <Button
            color="primary"
            className="button-default ml-auto w-full md:w-auto"
            onClick={() => onOpenAddModal(true)}
          >
            Crear <Add />
          </Button>
        </div>
      </div>
    </form>
  );
};

const CompanyPage = () => {
  const [dataCompany, setDataCompany] = React.useState([]);
  const [load, setLoad] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [selected, setSelected] = React.useState(null);

  const fetchDataCompany = async () => {
    try {
      setLoad(true);
      const data = await get_data_company_all();

      setLoad(false);

      setDataCompany(data);
    } catch (error) {
      setLoad(false);
      console.error("Error en fetchDataCompany: ", error);
    }
  };

  const onSearchData = async (value) => {
    const response = await get_data_company_all();

    let filterData = response;

    if (value.searchName) {
      filterData = filterData.filter((el) =>
        el.nom_emp
          .toString()
          .toLowerCase()
          .includes(value.searchName.toLowerCase())
      );
    }

    setDataCompany(filterData);
  };

  useEffect(() => {
    fetchDataCompany();
  }, []);

  return (
    <Template title="Reporte" style={{ position: "relative" }}>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-12">
          <Filters
            onSearch={onSearchData}
            onOpenAddModal={(el) => setOpenModal(el)}
          />
        </div>

        <div className="col-span-12 md:col-span-12">
          <ContainerInput className="p-0">
            <CustomTable
              columns={[
                {
                  id: "id_emp",
                  label: "Código",
                  minWidth: 80,
                  align: "left",
                },
                {
                  id: "nom_emp",
                  label: "Nombre",
                  minWidth: 150,
                  align: "left",
                  format: (el) => {
                    return el.toUpperCase();
                  },
                },
                {
                  id: "type_emp",
                  label: "Tipo",
                  minWidth: 150,
                  align: "left",
                  format: (el) => {
                    return el.toUpperCase();
                  },
                },
                {
                  id: "color_primary",
                  label: "Color primario",
                  minWidth: 150,
                  align: "left",
                  format: (el) => {
                    if (!el) {
                      return "Sin color";
                    }

                    return (
                      <div
                        style={{
                          background: el,
                          color: "white",
                          padding: 2,
                          borderRadius: 4,
                        }}
                      >
                        {el.toUpperCase()}
                      </div>
                    );
                  },
                },
                {
                  id: "color_secondary",
                  label: "Color secundario",
                  minWidth: 150,
                  align: "left",
                  format: (el) => {
                    if (!el) {
                      return "Sin color";
                    }

                    return (
                      <div
                        style={{
                          background: el,
                          color: "white",
                          padding: 2,
                          borderRadius: 4,
                        }}
                      >
                        {el.toUpperCase()}
                      </div>
                    );
                  },
                },
                {
                  id: "created_at",
                  label: "Fecha creación",
                  minWidth: 160,
                  align: "left",
                  format: (el) => {
                    if (!el) return "-";

                    return dayjs(el).utc().format("DD/MM/YYYY");
                  },
                },
              ]}
              rows={dataCompany}
              load={load}
              error={false}
              pagination={true}
              actionEdit={(element) => {
                setSelected(element);
                setOpenModal(true);
              }}
              actionDelete={(element) => {
                setSelected(element);
                setOpenModalDelete(true);
              }}
            />
          </ContainerInput>
        </div>
      </div>

      <ModalCompany
        open={openModal}
        selected={selected}
        setSelected={setSelected}
        setOpen={setOpenModal}
        callBack={() => {
          fetchDataCompany();
        }}
      />

      <ModalCompanyDelete
        open={openModalDelete}
        selected={selected}
        setSelected={setSelected}
        setOpen={setOpenModalDelete}
        callBack={() => {
          fetchDataCompany();
        }}
      />
    </Template>
  );
};

export default CompanyPage;
