import React from "react";

import NotFoundReg from "../atoms/notfoundreg.atoms";

const NotFound = ({ title, ...props }) => {
  return (
    <div {...props} style={{ textAlign: "center", padding: 50 }}>
      <NotFoundReg title={title} />
    </div>
  );
};

export default NotFound;
