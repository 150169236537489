import { actionTypes } from "../actions/index";

const initialState = {
  loading: false,
  error: false,
  dataMenu: { load: false, err: false, data: [] },
  openAddEdit_categoria: false,
  openDelete_categoria: false,
  openPlatos_categoria: false,
  dataSelected_categoria: {
    id_categoriapla: "",
    nombre_categoriapla: "",
  },
  paramSelected_plato: [],
};

const reducerObject = (state, payload) => ({
  [actionTypes.getDataMenu]: {
    ...state,
    dataMenu: payload,
  },
  [actionTypes.setLoading_categoria]: {
    ...state,
    loading: payload,
  },
  [actionTypes.openModalAddEdit_categoria]: {
    ...state,
    openAddEdit_categoria: payload,
  },
  [actionTypes.openModalDelete_categoria]: {
    ...state,
    openDelete_categoria: payload,
  },
  [actionTypes.openModalPlatos_categoria]: {
    ...state,
    openPlatos_categoria: payload,
  },
  [actionTypes.setDataSelected_categoria]: {
    ...state,
    dataSelected_categoria: payload,
  },
  [actionTypes.setParamSelected_plato]: {
    ...state,
    paramSelected_plato: payload,
  },
});

export const reducer_menu = (state = initialState, action) => {
  if (reducerObject(state)[action.type]) {
    return reducerObject(state, action.payload)[action.type];
  } else {
    return state;
  }
};
