import React from "react";

const Container = (props) => {
  return (
    <div className="Container" {...props}>
      {props.children}
    </div>
  );
};

export default Container;
