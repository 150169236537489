import * as React from "react";

import {
  Slide,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Grid,
  Typography,
} from "@mui/material";

import Input from "../../../../../components/Forms/Input";
import { aperturar_caja } from "../../../../../services/caja.services";
import LoadingPage from "../../../../../components/LoadingPage";

import { getUser } from "../../../../../services/auth.services";

import { useForm, Controller } from "react-hook-form";

import toast from "react-hot-toast";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref || false} {...props} />;
});

const DialogAperturar = (props) => {
  let { open, setOpen, handleCajaRefresh } = props;

  const [errDialog, setErrDialog] = React.useState(false);
  const [loadPage, setLoadPage] = React.useState(false);

  const initialValues = {
    totapertura_caja: 0,
  };

  const { control, handleSubmit } = useForm({
    defaultValues: initialValues,
  });

  const handleLoad = (value) => {
    setLoadPage(value);
  };

  const handleServiceAperturar = async (data) => {
    try {
      const response = await aperturar_caja(data);

      if (response.affectedRows !== 1) {
        return { status: "error" };
      }

      return { status: "success" };
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmitDialog = async (data) => {
    try {
      handleLoad(true);
      const response = await handleServiceAperturar({
        usu_id: getUser().id,
        ...data,
      });
      handleLoad(false);

      if (response.status === "error") {
        setErrDialog("Error al abrir la caja");
        return;
      }

      handleClose();
      handleCajaRefresh(true);
      toast.success("Caja abierta con éxito");
    } catch (err) {
      toast.error("Ocurrió un problema.");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth
      onClose={handleClose}
      maxWidth={"sm"}
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <LoadingPage open={loadPage} setOpen={setLoadPage} />
      <form onSubmit={handleSubmit(handleSubmitDialog)} autoComplete="off">
        <DialogTitle id="alert-dialog-title">Aperturar Caja</DialogTitle>
        <DialogContent>
          <br />

          <Grid container spacing={1}>
            {errDialog && (
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography
                  variant="h6"
                  component={"h6"}
                  style={{ color: "red" }}
                >
                  Error al aperturar :(
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Controller
                name="totapertura_caja"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Input
                    required
                    value={value}
                    onChange={onChange}
                    type="number"
                    title="Cantidad aperturar"
                    inputProps={{
                      min: 0,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="text" color="inherit">
            Cerrar
          </Button>
          <Button type="submit" variant="contained" color="success">
            Confirmar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogAperturar;
