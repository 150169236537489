import { create } from "zustand";
import { update_quotation } from "../../services/quotation.services";
import { addPlanning } from "../../services/planning.services";

import toast from "react-hot-toast";

import { s3Client } from "../../libs/s3Client";
import { v4 as uuidv4 } from "uuid";
import { PutObjectCommand } from "@aws-sdk/client-s3";

const handleSubmitContract = async (data, set) => {
  let requiredFields = [
    "idEvent",
    "dateOfEvent",
    "nameQuotation",
    "lastNameQuotation",
    "rutQuotation",
    "directionQuotation",
    "valuePerPerson",
    "minBasePAXPerPerson",
    "claspType",
    "usuId",
  ];

  if (data.idEvent === 1) {
    // add attrbutes in requiredFields
    requiredFields.push("nameBoyfriendQuotation");
    requiredFields.push("rutBoyfriendQuotation");
    requiredFields.push("dateofBirthBoyfriendQuotation");
    requiredFields.push("phoneBoyfriendQuotation");
    requiredFields.push("addressBoyfriendQuotation");

    requiredFields.push("nameGirlfriendQuotation");
    requiredFields.push("rutGirlfriendQuotation");
    requiredFields.push("dateofBirthGirlfriendQuotation");
    requiredFields.push("phoneGirlfriendQuotation");
    requiredFields.push("addressGirlfriendQuotation");
    requiredFields.push("idTypeEvent");
  }

  if (data.idEvent === 2) {
    requiredFields.push("idTypeEvent");
  }

  if (data.idEvent !== 1) {
    requiredFields.push("feePayment");
  }

  let isRequired = false;

  requiredFields.forEach((field) => {
    if (!data[field]) {
      isRequired = true;
    }
  });

  if (isRequired) {
    toast.error("Debe de completar todos los campos requeridos");
    return;
  }

  set({ loading: true });

  await update_quotation(data, data.idQuotation);

  set((state) => ({ step: state.step + 1 }));

  set({ loading: false });
};

const handleUploadContractFile = async (data, set) => {
  if (!data?.fileContractQuotation) {
    toast.error("Debe de subir el contrato de cotización");

    return;
  }

  const file = data?.fileContractQuotation[0];
  const fileExtension = file.name.split(".").pop();
  const fileName = `${uuidv4()}.${fileExtension}`;

  const bucketParams = {
    Bucket: "ubuntu-storage",
    Key: `quotation/${fileName}`,
    Body: file,
    ACL: "public-read",
  };

  try {
    set({ loading: true });
    await s3Client.send(new PutObjectCommand(bucketParams));
    await addPlanning({ ...data, fileContractQuotation: fileName });
    set({ loading: false });

    set((state) => ({ step: state.step + 1 }));
  } catch (err) {
    console.log(err);
    set({ loading: false });
  }
};

const handleControlSteps = (get, set) => {
  const step = get().step;
  const data = get().dataContract;

  const dataFileContract = get().dataFileContract;

  if (step === 0) {
    handleSubmitContract(data, set);
  }

  if (step === 1) {
    set((state) => ({ step: state.step + 1 }));
  }

  if (step === 2) {
    handleUploadContractFile(
      { ...data, fileContractQuotation: dataFileContract },
      set
    );
  }
};

export const useStoreQuotation = create((set, get) => ({
  step: 0,
  loading: false,
  dataContract: null,
  dataFileContract: null,
  setDataContract: (data) => set({ dataContract: data }),
  setDataFileContract: (data) => set({ dataFileContract: data }),
  submitContract: () => handleSubmitContract,

  incrementStep: () => handleControlSteps(get, set),
  decrementStep: () => set((state) => ({ step: state.step - 1 })),
  resetStep: () => set({ step: 0 }),
}));
