import React from "react";
import Template from "../../components/Template";

import BenefitModal from "./modal.benefit";
import BenefitModalDelete from "./modalDelete.benefit";
import BenefitSearch from "./search.benefit";
import BenefitState from "./state.benefit";
import BenefitTable from "./table.benefit";

const Benefit = () => {
  return (
    <BenefitState>
      <Template title="Beneficios">
        <BenefitSearch />
        <BenefitTable />
        <BenefitModal />
        <BenefitModalDelete />
      </Template>
    </BenefitState>
  );
};

export default Benefit;
