import React, { useEffect, useState } from "react";

import { getUser } from "../../services/auth.services";

import { Grid } from "@mui/material";

import "./style.menu.scss";

import useEmpresa from "../../hooks/useEmpresa";

import { get_menu } from "../../services/menu.services";
import CategoryMenu from "./_ui/CategoryMenu";
import LinkCarta from "./_ui/LinkCarta.menu";
import ProductMenu from "./_ui/ProductMenu";

const MenuSecondary = () => {
  const dataEmpresa = useEmpresa(getUser().empresa.id_empsede, "id");

  const [categorySelected, setCategorySelected] = useState(null);
  const [platoSelected, setPlatoSelected] = useState({});

  const [dataMenu, setDataMenu] = useState([]);
  const [loading, setLoading] = useState(false);

  const onCategorySelected = async (value) => {
    setCategorySelected(value);
  };

  async function obtainDataMenu() {
    try {
      setLoading(true);
      const response = await get_menu(2);
      setLoading(false);

      setDataMenu(response);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }

  useEffect(() => {
    obtainDataMenu();

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid container spacing={2} style={{ marginBottom: 20 }}>
        <Grid
          item
          xs={12}
          sm={11}
          lg={11}
          style={{
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <LinkCarta dataEmpresa={dataEmpresa} />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={4}>
          <CategoryMenu
            onClearSelected={() => {
              setPlatoSelected(null);
              setCategorySelected(null);
            }}
            onSelected={onCategorySelected}
            onRefreshDataMenu={obtainDataMenu}
            dataMenu={dataMenu}
            loading={loading}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <ProductMenu
            onRefreshDataCategory={obtainDataMenu}
            categorySelected={categorySelected}
            dataSelected={platoSelected}
            setDataSelected={setPlatoSelected}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default MenuSecondary;
