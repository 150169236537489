import Typography from "@mui/material/Typography";

const TitleStep = (props) => {
  return (
    <div {...props}>
      <Typography
        component={"h6"}
        variant={"h6"}
        style={{ textAlign: "center" }}
      >
        {props.children}
      </Typography>
    </div>
  );
};

export default TitleStep;
