import { Typography } from "@mui/material";
import RadioGroupRating from "./radioGroupRating";

const Question = (props) => {
  let { number, question, answer, setAnswer } = props;

  return (
    <>
      <Typography
        variant="inherit"
        component={"p"}
        style={{ textAlign: "left" }}
      >
        {number}.- {question}
      </Typography>
      <br />
      <RadioGroupRating number={number} answer={answer} setAnswer={setAnswer} />
      <br />
    </>
  );
};

export default Question;
