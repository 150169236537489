/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Template from "../../components/Template";

import { checkToken } from "../../services/auth.services";

import ContainerInput from "../../components/ContainerInput";

import { Grid, Button } from "@mui/material";

import { Add } from "@mui/icons-material";

import { getCampaigns, getTypeCampaign } from "../../services/ticket.services";
import { getUser } from "../../services/auth.services";

import TableCampaign from "./components/table.campaign";
import LoadingPage from "../../components/LoadingPage";

import DialogCrudCampaign from "./components/dialogcrud.campaign";
import DialogDeleteCampaign from "./components/delete.campaign";
import DialogTicketCampaign from "./components/ticket.campaign";

import useEmpresa from "../../hooks/useEmpresa";

const Campaign = () => {
  let history = useHistory();

  const [dataCampaign, setDataCampaign] = useState({
    load: false,
    data: [],
    err: false,
  });

  const [dataTypeCampaign, setDataTypeCampaign] = useState({
    load: false,
    data: [],
    err: false,
  });

  const [loading, setLoading] = useState(false);

  const [openCrud, setOpenCrud] = useState(false);
  const [openTickets, setOpenTickets] = useState(false);

  const [openDelete, setOpenDelete] = useState(false);

  const dataEmpresa = useEmpresa(getUser().empresa.id_empsede, "id");

  const initilValuesData = {
    createdat_campaign: null,
    durationdate_campaign: null,
    id_campaign: null,
    id_emp: null,
    id_empsede: null,
    id_typecampaign: null,
    maxnumberticket_campaign: null,
    observations_campaign: null,
    requirements_campaign: null,
    title_campaign: null,
    titleticket_campaign: null,
  };

  const [dataSelected, setDataSelected] = useState(initilValuesData);

  const handleOpenModal = (type, value) => {
    if (type === "add") {
      setDataSelected(initilValuesData);
      setOpenCrud(true);
    }

    if (type === "edit") {
      setDataSelected(value);
      setOpenCrud(true);
    }

    if (type === "delete") {
      setDataSelected(value);
      setOpenDelete(true);
    }

    if (type === "showTickets") {
      setDataSelected(value);
      setOpenTickets(true);
    }
  };

  async function obtainDataCampaign() {
    try {
      setLoading(true);
      setDataCampaign({ load: true, data: [], err: false });
      let response = await getCampaigns();
      setLoading(false);

      setDataCampaign({ load: false, data: response, err: false });
    } catch (err) {
      console.log(err);
      setLoading(false);
      setDataCampaign({ load: false, data: [], err: true });
    }
  }

  async function obtainTypeCampaign() {
    try {
      setDataTypeCampaign({ load: true, data: [], err: false });
      let response = await getTypeCampaign();

      const newResponse = response.map((el) => ({
        value: el.id_typecampaign,
        label: el.name_typecampaign,
      }));

      setDataTypeCampaign({ load: false, data: newResponse, err: false });
    } catch (err) {
      console.log(err);
      setDataTypeCampaign({ load: false, data: [], err: true });
    }
  }

  useEffect(() => {
    obtainTypeCampaign();
    obtainDataCampaign();

    return null;
  }, []);

  useEffect(() => {
    if (!checkToken()) {
      history.push("/");
      return null;
    }
  }, []);

  return (
    <Template title="Campaña">
      <LoadingPage open={loading} />
      <Grid container spacing={2} style={{ marginBottom: 20 }}>
        <Grid
          item
          xs={12}
          sm={11}
          lg={11}
          style={{
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        ></Grid>
        <Grid
          item
          xs={12}
          sm={1}
          lg={1}
          style={{
            textAlign: "right",
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Button
            fullWidth
            onClick={() => {
              handleOpenModal("add", null);
            }}
            className="button-default"
          >
            <Add />
          </Button>
        </Grid>
      </Grid>

      <ContainerInput
        style={{
          marginTop: 20,
          marginBottom: 20,
          padding: "35px 10px",
          textAlign: "center",
        }}
      >
        <TableCampaign
          data={dataCampaign.data}
          load={dataCampaign.load}
          handleOpenModal={handleOpenModal}
        />
      </ContainerInput>

      <DialogCrudCampaign
        open={openCrud}
        setOpen={setOpenCrud}
        dataSelected={dataSelected}
        dataTypeCampaign={dataTypeCampaign.data}
        infoTicket={{
          alias_empsede: dataEmpresa.data.alias_empsede || null,
          color_primary: dataEmpresa.data.color_primary || null,
          color_secondary: dataEmpresa.data.color_secondary || null,
          comuna_empsede: dataEmpresa.data.comuna_empsede || null,
          created_at: dataEmpresa.data.created_at || null,
          direction_empsede: dataEmpresa.data.direction_empsede || null,
          id_emp: dataEmpresa.data.id_emp || null,
          id_empsede: dataEmpresa.data.id_empsede || null,
          logo_emp: dataEmpresa.data.logo_emp || null,
          nom_emp: dataEmpresa.data.nom_emp || null,
          nom_empsede: dataEmpresa.data.nom_empsede || null,
          telf_empsede: dataEmpresa.data.telf_empsede || null,
          ubication_empsede: dataEmpresa.data.ubication_empsede || null,
          urlbi_emp: dataEmpresa.data.urlbi_emp || null,
          wsp_empsede: dataEmpresa.data.wsp_empsede || null,
        }}
        callback={obtainDataCampaign}
      />
      <DialogDeleteCampaign
        open={openDelete}
        setOpen={setOpenDelete}
        dataSelected={dataSelected}
        callback={obtainDataCampaign}
      />
      <DialogTicketCampaign
        open={openTickets}
        setOpen={setOpenTickets}
        idCampaign={dataSelected.id_campaign}
      />
    </Template>
  );
};

export default Campaign;
