import "moment/locale/es";
import { createContext, useContext, useEffect, useState } from "react";

// import { useDebounce } from "../../hooks/useDebounce";

import {
  addTicketBenefit,
  deleteTicketBenefit,
  getTicketBenefit,
  updateTicketBenefit,
} from "../../services/ticketBenefit.services";
import { get_user } from "../../services/user.services";
import { getBenefitById } from "../../services/benefit.services";

const initDefaultValues = {
  id: 0,
  idUsu: 0,
  idBenefit: 0,
  quantity: 0,
  isRedeemed: false,
  nameUsu: "",
};

const AppContext = createContext({
  /* Values */
  data: [],
  dataUser: [],
  dataBenefit: {
    data: [],
    loading: false,
  },
  isLoadData: false,
  openModal: false,
  openModalRedeem: false,
  defaultValues: initDefaultValues,
  /* Methods */
  handleSearch: (value) => {},
  handleModal: () => {},
  handleModalDelete: () => {},
  handleAdd: (value) => {},
  handleUpdate: (id_benefit, value) => {},
  handleDelete: (id_benefit) => {},
  handleAction: (value) => {},
  handleSelected: (value) => {},
  handleReset: () => {},
});

export default function TicketBenefitState({ idTicketBenefit, children }) {
  const [data, setData] = useState([]);
  const [dataUser, setDataUser] = useState([]);

  const [dataBenefit, setDataBenefit] = useState({
    data: [],
    loading: false,
  });

  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalRedeem, setOpenModalRedeem] = useState(false);
  const [defaultValues, setDefaultValues] = useState({
    initDefaultValues,
  });

  // const [name, setName] = useState("");
  // const debouncedFilter = useDebounce(name || "", 500);

  async function handleSearch(params) {
    setLoading(true);

    const urlParams = `?idUsu=${params?.idUsu}&startDate=${
      params?.startDate || ""
    }&endDate=${params?.endDate || ""}`;

    const response = await getTicketBenefit(idTicketBenefit, urlParams);
    setData(response || []);

    setLoading(false);
  }

  async function handleGet() {
    setLoading(true);
    const response = await getTicketBenefit(idTicketBenefit);

    setData(response || []);
    setLoading(false);
  }

  useEffect(() => {
    handleGet();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleAction(params) {
    try {
      handleModal(false);

      let i = 0;
      setLoading(true);
      while (i < params.quantity) {
        await addTicketBenefit({
          idUsu: parseInt(params.idUsu),
          idBenefit: parseInt(idTicketBenefit),
        });
        i++;
      }
      await handleGet();
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleUpdate(id_benefit, params) {
    setLoading(true);
    await updateTicketBenefit(id_benefit, {
      isRedeemed: true,
    });
    await handleGet();
    setLoading(false);
  }

  async function handleDelete(id_benefit) {
    setLoading(true);
    await deleteTicketBenefit(id_benefit);
    await handleGet();
    setLoading(false);
  }

  useEffect(() => {
    async function handleGetUsers() {
      setLoading(true);
      const response = await get_user();
      const newResponse = response.filter((el) => el.tipousu_id === 11);

      setDataUser(newResponse || []);
      setLoading(false);
    }

    handleGetUsers();
  }, []);

  const handleSelected = (type, id) => {
    type === "redeem" ? handleModalRedeem(true) : handleModalDelete(true);

    const filter = data.find((item) => item.id_ticketbenefit === id);

    setDefaultValues({
      ...initDefaultValues,
      id,
      isRedeemed: filter?.isredeemed_ticketbenefit,
      nameUsu: filter?.usu_nombre,
    });
  };

  const handleReset = () => {
    setDefaultValues(initDefaultValues);
  };

  const handleModal = (openStatus) => {
    setOpenModal(openStatus);
  };

  const handleModalDelete = (openStatus) => {
    setOpenModalDelete(openStatus);
  };

  const handleModalRedeem = (openStatus) => {
    setOpenModalRedeem(openStatus);
  };

  useEffect(() => {
    async function handleGetBenefit() {
      setDataBenefit({
        data: [],
        loading: true,
      });

      const response = await getBenefitById(idTicketBenefit);

      setDataBenefit({
        data: response,
        loading: false,
      });
    }

    handleGetBenefit();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppContext.Provider
      value={{
        /* Values */
        data,
        dataUser,
        dataBenefit,
        isLoadData: loading,
        openModal,
        openModalDelete,
        openModalRedeem,
        defaultValues,
        /* Methods */
        handleSearch,
        handleUpdate,
        handleDelete,
        handleAction,
        handleSelected,
        handleReset,
        handleModal,
        handleModalDelete,
        handleModalRedeem,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
