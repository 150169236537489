export const nameCamarero = (uppercase = true) => {
  const userData = JSON.parse(localStorage.getItem("user_vm"));

  const NAME_CAMARERO = userData?.empresa?.moneda_empsede || "Garzón";

  if (NAME_CAMARERO === "CLP") {
    return uppercase ? `Garzón` : `garzón`;
  }

  if (NAME_CAMARERO === "PEN") {
    return uppercase ? `Camarero` : `camarero`;
  }

  return uppercase ? `Garzón` : `garzón`;
};
