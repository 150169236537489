import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputDefault from "../../../atomic/atoms/input.atoms";
import { get_user_sellers } from "../../../services/user.services";

const FiltersReservationSellers = ({
  onSearchData,
  onOpenMetaDialog,
  initialState,
  onLoadData,
}) => {
  const ID_PERFIL_SELLER = 6;
  const ID_ADMIN = 4;

  const [dataUser, setDataUser] = useState([]);

  const { control, handleSubmit } = useForm({
    defaultValues: initialState,
  });

  const onSearch = (data) => {
    onSearchData({ ...data, userId: Number(data.userId) });
  };

  useEffect(() => {
    async function handleGetUsers() {
      const response = await get_user_sellers();
      const newResponse = response
        .filter(
          (el) =>
            el.tipousu_id === ID_PERFIL_SELLER || el.tipousu_id === ID_ADMIN
        )
        .map((el) => {
          return {
            ...el,
            value: el.usu_id,
            label: el?.usu_nombre.toUpperCase(),
          };
        });

      setDataUser(newResponse || []);
    }

    handleGetUsers();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSearch)} autoComplete="off">
      <div className="mb-4 w-full grid grid-cols-12 gap-2">
        <div className="col-span-3">
          <Controller
            name="userId"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                value={value}
                onChange={onChange}
                type="select"
                title={"Vendedor"}
                options={dataUser}
              />
            )}
          />
        </div>
        <div className="col-span-4">
          <Controller
            name="filterMonth"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                required
                value={value}
                onChange={onChange}
                type="month"
                title={"Mes"}
              />
            )}
          />
        </div>
        <div className="col-span-3">
          <Button
            variant="contained"
            color="primary"
            className="ml-auto w-full md:w-auto"
            type="submit"
            disabled={onLoadData}
          >
            Buscar
          </Button>
        </div>
        {/* <div className="col-span-12 flex justify-end mb-4">
          <Button
            variant="contained"
            color="secondary"
            className="ml-auto w-full md:w-auto"
            type="button"
            onClick={() => onOpenMetaDialog()}
          >
            Metas
          </Button>
        </div> */}
      </div>
    </form>
  );
};

export default FiltersReservationSellers;
