import { Add, Search } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import InputDefault from "../../atomic/atoms/input.atoms";
import { useAppContext } from "./state.ticketBenefit";

const TicketBenefitSearch = () => {
  const state = useAppContext();

  const newDataUser = state.dataUser.map((item) => {
    return {
      value: item.usu_id,
      label: item.usu_nombre,
    };
  });

  const { control, handleSubmit } = useForm({
    defaultValues: {
      startDate: "",
      endDate: "",
      idUsu: "",
    },
  });

  const onOpenModal = () => {
    state.handleModal(true);
  };

  return (
    <form onSubmit={handleSubmit(state.handleSearch)} autoComplete="off">
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Controller
            name="idUsu"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                fullWidth
                value={value}
                onChange={onChange}
                type="select"
                title={"Promotores"}
                options={newDataUser}
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            name="startDate"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                fullWidth
                value={value}
                onChange={onChange}
                type="date"
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            name="endDate"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                fullWidth
                value={value}
                onChange={onChange}
                type="date"
              />
            )}
          />
        </Grid>
        <Grid item xs={1} style={{ textAlign: "left", display: "inline-flex" }}>
          <IconButton
            title="Buscar"
            type="submit"
            size="large"
            color="primary"
            style={{ background: "#eee", marginRight: "10px" }}
          >
            <Search />
          </IconButton>
          <IconButton
            title="Agregar"
            size="large"
            color="secondary"
            onClick={onOpenModal}
            style={{ background: "#eee", marginRight: "10px" }}
          >
            <Add />
          </IconButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default TicketBenefitSearch;
