import dayjs from "dayjs";
import React from "react";
import { Calendar, dayjsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

import "dayjs/locale/es";
import utc from "dayjs/plugin/utc";

const localizer = dayjsLocalizer(dayjs);

dayjs.extend(utc);
dayjs.locale({
  weekStart: 1,
  name: "es",
  weekdays: "Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado".split("_"),
  weekdaysShort: "Dom_Lun_Mar_Mié_Jue_Vie_Sáb".split("_"),
  weekdaysMin: "Do_Lu_Ma_Mi_Ju_Vi_Sá".split("_"),
  months:
    "Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre".split(
      "_"
    ),
  monthsShort: "Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic".split("_"),
});

const CalendarReservas = ({
  isSellerExternal,
  setOpenViewDataByDate,
  handleOpenCapacity,
  handleGetDateToday,
  events,
  daysActivated,
}) => {
  const PERCENTAGE_CAPACITY = 95;

  const getDayCapacity = (date) => {
    const formattedDate = dayjs.utc(date).format("YYYY-MM-DD");

    return daysActivated.find(
      (day) =>
        dayjs.utc(day.date_capacidadate).format("YYYY-MM-DD") === formattedDate
    );
  };

  const isDayAvailable = (date, events) => {
    const formattedDate = dayjs.utc(date).format("YYYY-MM-DD");

    const eventsFind =
      events &&
      events?.find(
        (elE) => dayjs.utc(elE.date).format("YYYY-MM-DD") === formattedDate
      );

    const day = daysActivated.find(
      (day) =>
        dayjs.utc(day.date_capacidadate).format("YYYY-MM-DD") === formattedDate
    );

    if (day?.quantity_capacidate) {
      const percentCapacity = eventsFind?.total_reservas
        ? (eventsFind?.total_reservas * 100) / day?.quantity_capacidate
        : 0;

      if (percentCapacity <= PERCENTAGE_CAPACITY) {
        return {
          color: "orange",
        };
      }

      if (percentCapacity > PERCENTAGE_CAPACITY) {
        return {
          color: "#009b22",
        };
      }

      return {
        color: "#009b22",
      };
    }

    return false;
  };

  const isThereDaysActivated = (date) => {
    const formattedDate = dayjs.utc(date).format("YYYY-MM-DD");

    return daysActivated.find(
      (day) =>
        dayjs.utc(day.date_capacidadate).format("YYYY-MM-DD") === formattedDate
    );
  };

  const dayPropGetter = (date) => {
    const day = isDayAvailable(date, events);

    if (day) {
      return {
        style: {
          backgroundColor: day?.color,
        },
      };
    }

    return {
      style: {
        backgroundColor: "#ffeb8f",
        pointerEvents: "none",
      },
    };
  };

  return (
    <Calendar
      localizer={{
        ...localizer,
      }}
      views={["month"]}
      defaultView="month"
      dayPropGetter={dayPropGetter}
      messages={{
        week: "Semana",
        work_week: "Semana de trabajo",
        day: "Día",
        month: "Mes",
        previous: "Atrás",
        next: "Después",
        today: "Hoy",
        agenda: "El Diario",
        showMore: (total) => `+${total} más`,
      }}
      events={events.map((event) => {
        return {
          title: `${event.total_reservas} de ${
            getDayCapacity(event.date)?.quantity_capacidate || 0
          } pax`,
          start: dayjs.utc(event.date).add(1, "day").toDate(),
          end: dayjs.utc(event.date).add(1, "day").toDate(),
        };
      })}
      onSelectEvent={async (event) => {
        await handleGetDateToday(
          dayjs.utc(event.start).subtract(1, "day").format("YYYY-MM-DD")
        );
        setOpenViewDataByDate(true);
      }}
      onSelectSlot={async (slotInfo) => {
        if (isSellerExternal) {
          return;
        }

        if (!isDayAvailable(slotInfo.start)) {
          handleOpenCapacity({
            date: dayjs.utc(slotInfo.start).format("YYYY-MM-DD"),
          });
          return;
        }

        if (isThereDaysActivated(slotInfo.start)) {
          const response = isThereDaysActivated(slotInfo.start);

          handleOpenCapacity({
            idCapacity: response.id_capacidadate,
            date: dayjs.utc(slotInfo.start).format("YYYY-MM-DD"),
            quantityCapacity: response.quantity_capacidate,
          });

          return;
        }
      }}
      selectable
      style={{ height: 500 }}
    />
  );
};

export default CalendarReservas;
