import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Template from "../../components/Template";

import { checkToken } from "../../services/auth.services";

import ConsumptionComponent from "./component.consumption";

const Consumption = () => {
  const history = useHistory();

  useEffect(() => {
    if (!checkToken()) {
      history.push("/");
      return null;
    }
  }, [history]);

  return (
    <Template title="Consumos">
      <ConsumptionComponent />
    </Template>
  );
};

export default Consumption;
