import {
  Document,
  Font,
  Image,
  Page,
  PDFViewer,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";

import watermarkImage from "../../../../assets/images/mark_dh.png";
import signatureImage from "../../../../assets/images/sign.png";

import { formatDate } from "@fullcalendar/core/index.js";
import dayjs from "dayjs";
import RobotoBold from "../../../../assets/fonts/Roboto/Roboto-Bold.ttf";
import RobotoItalic from "../../../../assets/fonts/Roboto/Roboto-Italic.ttf";
import Roboto from "../../../../assets/fonts/Roboto/Roboto-Regular.ttf";

import { formatMoney } from "../../../../functions/formatMoney.js";

import { styleContract } from "./style.js";

Font.register({
  family: "Roboto",
  fonts: [
    { src: Roboto },
    { src: RobotoBold, fontStyle: "bold", fontWeight: 600 },
    { src: RobotoItalic, fontStyle: "italic" },
  ],
});

const PDFAnotherContract = ({ dataContract }) => {
  let {
    nameSubsidiary = "",
    valuePerPerson = null,
    eventHoursQuotation = null,
    dateOfEvent = null,
    nameQuotation = null,
    lastNameQuotation = null,
    rutQuotation = null,
    phoneQuotation = null,
    nameCompanyQuotation = null,
    addressCompanyQuotation = null,
    nameTypeEvent = null,
    nameEvent = null,
    minBasePAXPerPerson = null,
    feePayment = [],
  } = dataContract;

  let fullNameQuotation = "";
  let fromEventHours = "";
  let toEventHours = "";

  const DEFAULT_VALUE = "______________________________";

  nameQuotation = nameQuotation?.toUpperCase() || DEFAULT_VALUE;
  fullNameQuotation =
    nameQuotation && lastNameQuotation
      ? nameQuotation?.toUpperCase() + " " + lastNameQuotation?.toUpperCase()
      : DEFAULT_VALUE;
  rutQuotation = rutQuotation?.toUpperCase() || DEFAULT_VALUE;

  nameSubsidiary = nameSubsidiary?.toUpperCase() || DEFAULT_VALUE;

  valuePerPerson = valuePerPerson ? formatMoney(valuePerPerson, "CLP") : "";
  minBasePAXPerPerson = minBasePAXPerPerson || DEFAULT_VALUE;

  fromEventHours = dateOfEvent
    ? dayjs(dateOfEvent).format("HH:mm")
    : DEFAULT_VALUE;
  toEventHours = dateOfEvent
    ? dayjs(dateOfEvent).add(eventHoursQuotation, "hour").format("HH:mm")
    : DEFAULT_VALUE;

  dateOfEvent = dateOfEvent ? formatDate(dateOfEvent) : DEFAULT_VALUE;

  nameCompanyQuotation = nameCompanyQuotation?.toUpperCase() || DEFAULT_VALUE;
  addressCompanyQuotation =
    addressCompanyQuotation?.toUpperCase() || DEFAULT_VALUE;
  nameTypeEvent = nameTypeEvent?.toUpperCase() || DEFAULT_VALUE;
  nameEvent = nameEvent?.toUpperCase() || DEFAULT_VALUE;

  eventHoursQuotation = eventHoursQuotation || DEFAULT_VALUE;
  feePayment = feePayment.map((el) => ({
    value_feepayment: formatMoney(el.value_feepayment, "CLP"),
    date_feepayment: dayjs(el.date_feepayment).format("MMMM YYYY"),
  }));

  return (
    <PDFViewer style={{ width: "100%", height: "100%" }}>
      <Document subject="Contrato de prestación de servicios">
        <Page size="A4" style={styleContract.page} wrap={false} debug={false}>
          <View style={styleContract.watermarkContainer} fixed={true}>
            <Image src={watermarkImage} style={styleContract.watermark} />
          </View>
          <View style={styleContract.section}>
            {/* Title */}
            <View style={{ marginBottom: 20 }}>
              <Text style={styleContract.textTitle}>
                CONTRATO DE PRESTACIÓN DE SERVICIOS
              </Text>
            </View>

            <View>
              <Text style={styleContract.text}>
                En Olmué, {dayjs().format("DD MMMM YYYY")}, comparecen el
                Comercial Dejando Huella Ltda. RUT N° 7676.422-2, representado
                por Don Pedro Olivares Maturana, chileno, titular de la cédula
                de identidad N°13.994.073-3, Ingeniero Civil Industrial, ambos
                con domicilio en Calle Bulnes 1800, Olmué, Región de Valparaíso,
                en adelante denominado "Oferente", y {fullNameQuotation},
                titular de la cédula de identidad N° {rutQuotation}, de
                nacionalidad Chilena, con número telefónico: +56 9
                {phoneQuotation} representante del Colegio:{" "}
                {nameCompanyQuotation}, curso {nameTypeEvent}, ubicado en{" "}
                {addressCompanyQuotation}, en adelante denominado "Mandante",
                acuerdan lo siguiente:
              </Text>
            </View>

            <View>
              <Text style={styleContract.textBold}>PRIMERO:</Text>
              <Text style={styleContract.text}>
                El Oferente es una empresa dedicada a la realización de eventos,
                para este contrato en particular de evento de {nameEvent}, en
                centro de Eventos Dejando Huella {nameSubsidiary}
              </Text>
            </View>

            <View>
              <Text style={styleContract.textBold}>SEGUNDO:</Text>
              <Text style={styleContract.text}>
                Mediante este contrato, el Mandante contrata al Centro de
                Eventos "DEJANDO HUELLA" para la prestación de servicios
                detallados en la cláusula precedente.
              </Text>
            </View>

            <View>
              <Text style={styleContract.textBold}>TERCERO:</Text>
              <Text style={styleContract.text}>
                Con el propósito de ejecutar el presente contrato, Pedro
                Olivares Maturana se compromete a proporcionar los servicios
                detallados en el programa de la Fiesta de {nameEvent} (adjunto
                en formato PDF de 3 páginas). Por su parte, el Mandante asume el
                compromiso de cancelar por los servicios recibidos en virtud de
                este acuerdo.
              </Text>
            </View>

            <View>
              <Text style={styleContract.textBold}>CUARTO:</Text>
              <Text style={styleContract.text}>
                El precio acordado por el servicio ofrecido por el Oferente será
                de {valuePerPerson} por persona, con una base mínima de{" "}
                {minBasePAXPerPerson} invitados. El pago se realizará de la
                siguiente manera:
              </Text>
              {feePayment.map((el, idx) => (
                <Text
                  key={idx}
                  style={{ ...styleContract.text, padding: 0, margin: 0 }}
                >
                  * {el.date_feepayment}: abono de {el.value_feepayment} pesos.
                </Text>
              ))}
              <Text style={{ ...styleContract.text, padding: 0, margin: 0 }}>
                * La diferencia restante deberá ser cancelada 15 días antes del
                evento, momento en el cual se confirmará la cantidad exacta de
                invitados.
              </Text>
            </View>
          </View>
        </Page>
        <Page size="A4" style={styleContract.page} wrap={false} debug={false}>
          <View style={styleContract.watermarkContainer}>
            <Image src={watermarkImage} style={styleContract.watermark} />
          </View>
          <View style={styleContract.section}>
            <View>
              <Text style={styleContract.textBold}>QUINTO:</Text>
              <Text style={styleContract.text}>
                El Mandante cancelará un monto de $100.000 pesos de reserva, el
                cual no será reembolsable. Esta tarifa incluye los gastos
                asociados con la reserva de la fecha acordada y garantiza el
                mantenimiento de los precios pactados. Asimismo, asegura que el
                lugar esté disponible exclusivamente para la Mandante en la
                fecha establecida, y cubre los costos administrativos y de
                organización relacionados con la preparación del evento.
              </Text>
            </View>
            <View>
              <Text style={styleContract.textBold}>SEXTO:</Text>
              <Text style={styleContract.text}>
                La confirmación final del número de participantes deberá
                realizarse dos semanas antes del evento. En caso de que la
                cantidad de participantes sea menor que la base mínima acordada,
                Dejando Huella podrá ajustar el valor cotizado, facturando de
                acuerdo con esta última confirmación.
              </Text>
            </View>
            <View>
              <Text style={styleContract.textBold}>SÉPTIMO:</Text>
              <Text style={styleContract.text}>
                En caso de declararse estado de emergencia u otro evento
                extraordinario, imprevisible e irresistible que impida la
                realización del evento en la fecha originalmente acordada, los
                contratantes quedan exonerados de pagos adicionales no
                establecidos en este contrato y tendrán la posibilidad de
                suspender el evento, ajustando la fecha a un momento adecuado
                dentro del mismo año. Cualquier cambio de fecha a años
                siguientes podría implicar variaciones basadas en el Índice de
                Precios al Consumidor (IPC).
              </Text>
            </View>
            <View>
              <Text style={styleContract.textBold}>OCTAVO:</Text>
              <Text style={styleContract.text}>
                El presente contrato se regirá para el evento con fecha:{" "}
                {dayjs(dateOfEvent).format("DD/MM/YYYY")} con hora entre las{" "}
                {fromEventHours} hrs y {toEventHours} hrs. (modificable), evento
                de {eventHoursQuotation} hrs continuas.
              </Text>
            </View>
            <View>
              <Text style={styleContract.textBold}>NOVENO:</Text>
              <Text style={styleContract.text}>
                Las partes declaran su compromiso de cumplir con las
                obligaciones establecidas en este contrato y en el documento
                adjunto, en aras de garantizar el éxito del evento y las
                relaciones comerciales entre ambas partes. En fe de lo cual,
                firman el presente contrato
              </Text>
            </View>
            <View style={styleContract.table}>
              <View style={styleContract.tableRow}>
                <View style={styleContract.tableCell}>
                  <View style={{ textAlign: "left" }}>
                    <Text>Firma</Text>
                    <Text>RUT:</Text>
                  </View>
                </View>
              </View>
              <View style={styleContract.tableRow}>
                <View style={{ ...styleContract.tableCellSign, marginTop: 2 }}>
                  <Image
                    style={{
                      width: "40%",
                      margin: "auto",
                    }}
                    src={signatureImage}
                  />
                </View>
              </View>
              <View style={styleContract.tableRow}>
                <View style={{ ...styleContract.tableCell, marginTop: 0 }}>
                  <Text>PEDRO OLIVARES MATURANA</Text>
                  <Text>RUT: 13.994.073-3</Text>
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default PDFAnotherContract;
