/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";

// import { CalendarMonth, Download } from "@mui/icons-material";
// import { Button } from "@mui/material";

import { Controller, useForm } from "react-hook-form";

import { Add, Close } from "@mui/icons-material";

// import { PDFDownloadLink } from "@react-pdf/renderer";
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import InputDefault from "../../../atomic/atoms/input.atoms";
import {
  get_event,
  get_type_event,
} from "../../../services/quotation.services";
import { get_sucursales_by_id } from "../../../services/sucursal.services";
import { get_user } from "../../../services/user.services";

// import ContractDH from "../../components/PDFDocuments/contract.pdfdocument";

const initalValues = {
  usuId: null,
  idQuotation: null,
  valuePerPerson: null,
  minBasePAXPerPerson: null,
  claspType: "",
  dateOfEvent: "",
  rutQuotation: "",
  birthdateQuotation: "",
  directionQuotation: "",
  idEvent: 0,
  idTypeEvent: 0,
  nameQuotation: "",
  lastNameQuotation: "",
  emailQuotation: "",
  phoneQuotation: "",
  nameCompanyQuotation: "",
  addressCompanyQuotation: "",
  fileContractQuotation: "",
  nameBoyfriendQuotation: "",
  rutBoyfriendQuotation: "",
  dateofBirthBoyfriendQuotation: "",
  phoneBoyfriendQuotation: "",
  addressBoyfriendQuotation: "",
  nameGirlfriendQuotation: "",
  rutGirlfriendQuotation: "",
  dateofBirthGirlfriendQuotation: "",
  phoneGirlfriendQuotation: "",
  addressGirlfriendQuotation: "",
  nameSubsidiary: "",
  eventHoursQuotation: null,
  nameTypeEvent: "",
  nameEvent: "",
  feePayment: [],
};

const ContractFormDH = ({ selected, setDataContract }) => {
  const { control, reset, watch, setValue } = useForm({
    defaultValues: initalValues,
  });

  const [dataUser, setDataUser] = React.useState([]);
  const [typeEvent, setTypeEvent] = React.useState([]);
  const [event, setEvent] = React.useState([]);
  const [dataSucursales, setDataSucursales] = React.useState([]);

  const ID_PERFIL_PLANNER = 18;

  const dataClaspType = [
    {
      value: "En terreno",
      label: "En terreno",
    },
    {
      value: "Post visita",
      label: "Post visita",
    },
    {
      value: "Online",
      label: "Online",
    },
  ];

  const INIT_VALUES_PAYMENT = [
    {
      date_feepayment: dayjs().format("YYYY-MM"),
      value_feepayment: 100000,
    },
    {
      date_feepayment: dayjs().format("YYYY-MM"),
      value_feepayment: 200000,
    },
    {
      date_feepayment: dayjs().format("YYYY-MM"),
      value_feepayment: 350000,
    },
  ];

  const onDeleteRowPayment = (index) => {
    if (watch("feePayment").length === 1) {
      return;
    }

    setValue(
      "feePayment",
      watch("feePayment").filter((el, idx) => idx !== index)
    );
  };

  useEffect(() => {
    setDataContract(watch());
  }, [watch()]);

  useEffect(() => {
    if (selected) {
      if (selected?.id_quotation && selected?.id_quotation > 0) {
        reset({
          ...initalValues,
          idQuotation: Number(selected.id_quotation),
          codQuotation: selected?.cod_quotation,
          idEvent: Number(selected?.id_event),
          idTypeEvent: Number(selected?.id_typeevent),
          nameQuotation: selected?.name_quotation,
          lastNameQuotation: selected?.lastname_quotation,
          nameCompanyQuotation: selected?.namecompany_quotation,
          addressCompanyQuotation: selected?.addresscompany_quotation,
          fileContractQuotation: selected?.filecontract_quotation,
          usuId: selected?.usu_id,
          valuePerPerson: selected?.valueperperson_quotation,
          minBasePAXPerPerson: selected?.minbasepaxperperson_quotation,
          claspType: selected?.clasptype_quotation,
          rutQuotation: selected?.rut_quotation,
          directionQuotation: selected?.direction_quotation,
          // birthdateQuotation: dayjs
          //   .utc(selected?.birthdate_quotation)
          //   .format("YYYY-MM-DD"),
          dateOfEvent: dayjs
            .utc(selected.date_quotation)
            .format("YYYY-MM-DD HH:mm"),
          nameBoyfriendQuotation: selected?.nameboyfriend_quotation,
          rutBoyfriendQuotation: selected?.rutboyfriend_quotation,
          dateofBirthBoyfriendQuotation:
            selected?.dateofbirthboyfriend_quotation &&
            dayjs
              .utc(selected?.dateofbirthboyfriend_quotation)
              .format("YYYY-MM-DD"),
          phoneBoyfriendQuotation: selected?.phoneboyfriend_quotation,
          addressBoyfriendQuotation: selected?.addressboyfriend_quotation,
          nameGirlfriendQuotation: selected?.namegirlfriend_quotation,
          rutGirlfriendQuotation: selected?.rutgirlfriend_quotation,
          dateofBirthGirlfriendQuotation:
            selected?.dateofbirthgirlfriend_quotation &&
            dayjs
              .utc(selected?.dateofbirthgirlfriend_quotation)
              .format("YYYY-MM-DD"),
          phoneGirlfriendQuotation: selected?.phonegirlfriend_quotation,
          addressGirlfriendQuotation: selected?.addressgirlfriend_quotation,
          nameSubsidiary: selected?.nameSubsidiary_quotation,
          eventHoursQuotation: selected?.eventhours_quotation,
          emailQuotation: selected?.email_quotation,
          phoneQuotation: selected?.phone_quotation,
          nameTypeEvent: selected?.name_typeevent,
          nameEvent: selected?.name_event,
          feePayment:
            selected?.feePayment && selected?.feePayment.length > 0
              ? selected?.feePayment.map((el) => ({
                  ...el,
                  date_feepayment: dayjs(el.date_feepayment).format("YYYY-MM"),
                }))
              : INIT_VALUES_PAYMENT,
        });
      }
    } else {
      reset(initalValues);
    }
  }, [selected]);

  useEffect(() => {
    const fetchDataEvent = async () => {
      const response = await get_event();

      const newResponse = response.map((el) => ({
        value: el.id_event,
        label: el.name_event,
      }));

      setEvent(newResponse);
    };

    fetchDataEvent();
  }, [watch("idEvent")]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await get_type_event();
      const newResponse = response
        .filter((el) => el.id_event === Number(watch("idEvent")))
        .map((el) => ({
          value: el.id_typeevent,
          label: el.name_typeevent,
        }));

      setTypeEvent(newResponse);

      newResponse.length === 0 && setValue("idTypeEvent", null);
    };

    if (watch("idEvent") > 0) {
      fetchData();
    }
  }, [watch("idEvent")]);

  useEffect(() => {
    async function handleGetUsers() {
      const response = await get_user();
      const newResponse = response
        .filter((el) => el.tipousu_id === ID_PERFIL_PLANNER)
        .map((el) => {
          return {
            ...el,
            value: el.usu_id,
            label: el?.usu_nombre.toUpperCase(),
          };
        });

      setDataUser(newResponse || []);
    }

    if (selected) {
      handleGetUsers();
    }
  }, [selected]);

  useEffect(() => {
    const getSucursales = async () => {
      try {
        const data = JSON.parse(localStorage.getItem("user_vm"));
        const id_empresa = data.empresa.id_emp;

        const response = await get_sucursales_by_id(id_empresa);

        setDataSucursales(
          response.map((item) => ({
            value: item.nom_empsede,
            label: item.nom_empsede,
          }))
        );
      } catch (err) {
        console.log(err.message);
      }
    };

    getSucursales();
  }, []);

  return (
    <form
      // onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <div className="mb-4 w-full grid grid-cols-12 gap-2">
        <div className="col-span-12">
          <p className="text-sm font-semibold">Datos del evento</p>
        </div>

        <div className="col-span-6">
          <input type="hidden" name="nameEvent" value={typeEvent?.value} />
          <Controller
            name="idEvent"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputDefault
                required
                loading={false}
                fullWidth
                value={value}
                onChange={onChange}
                title={"Evento"}
                type={"select"}
                options={event}
              />
            )}
          />
        </div>

        {typeEvent.length > 0 && (
          <div className="col-span-6">
            <input
              type="hidden"
              name="nameTypeEvent"
              value={typeEvent?.value}
            />
            <Controller
              name="idTypeEvent"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputDefault
                  required
                  loading={false}
                  fullWidth
                  value={value}
                  onChange={onChange}
                  title={"Tipo de evento"}
                  type={"select"}
                  options={typeEvent}
                />
              )}
            />
          </div>
        )}
        {(Number(watch("idEvent")) === 2 || Number(watch("idEvent")) === 3) && (
          <>
            <div className="col-span-12">
              <Controller
                name="nameCompanyQuotation"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    required
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"Nombre colegio/empresa"}
                  />
                )}
              />
            </div>
            <div className="col-span-12">
              <Controller
                name="addressCompanyQuotation"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    required
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"Dirección colegio/empresa"}
                  />
                )}
              />
            </div>
          </>
        )}
        <div className="col-span-12 flex gap-2">
          <Controller
            name="dateOfEvent"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                value={value}
                onChange={onChange}
                title={"Fecha de evento"}
                type={"datetime-local"}
              />
            )}
          />

          <div className="col-span-12 flex gap-2">
            <Controller
              name="eventHoursQuotation"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InputDefault
                  value={value}
                  onChange={onChange}
                  title={"Horas del evento"}
                  type={"number"}
                />
              )}
            />
          </div>

          {/* <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpenCalendar(true)}
          >
            <CalendarMonth />
          </Button> */}
        </div>
        <div className="col-span-12 flex gap-2">
          <Controller
            name="nameSubsidiary"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputDefault
                required
                loading={false}
                fullWidth
                value={value}
                onChange={onChange}
                title={"Salon de evento"}
                type={"select"}
                options={dataSucursales}
              />
            )}
          />
        </div>
        <div className="col-span-12">
          <Controller
            name="usuId"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                value={value}
                onChange={onChange}
                title={"Planificador"}
                type={"select"}
                options={dataUser}
              />
            )}
          />
        </div>
        <div className="col-span-6">
          <Controller
            name="valuePerPerson"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                required
                value={value}
                onChange={onChange}
                title={"Valor por persona"}
                type={"number"}
              />
            )}
          />
        </div>
        <div className="col-span-6">
          <Controller
            name="minBasePAXPerPerson"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                required
                value={value}
                onChange={onChange}
                title={"Base PAX por persona"}
                type={"number"}
              />
            )}
          />
        </div>

        {Number(watch("idEvent")) === 1 && (
          <>
            <div className="col-span-12">
              <p className="text-sm font-semibold">Datos del novio</p>
            </div>
            <div className="col-span-6">
              <Controller
                name="nameBoyfriendQuotation"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"Nombres"}
                  />
                )}
              />
            </div>
            <div className="col-span-6">
              <Controller
                name="rutBoyfriendQuotation"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"RUT"}
                    type={"text"}
                  />
                )}
              />
            </div>
            <div className="col-span-6">
              <Controller
                name="dateofBirthBoyfriendQuotation"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"Fecha de nacimiento"}
                    type={"date"}
                  />
                )}
              />
            </div>
            <div className="col-span-6 flex gap-2">
              <div className="text-sm self-center ">+569</div>
              <Controller
                name="phoneBoyfriendQuotation"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"Teléfono"}
                    type={"text"}
                  />
                )}
              />
            </div>
            <div className="col-span-12">
              <Controller
                name="addressBoyfriendQuotation"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"Dirección"}
                    type={"text"}
                  />
                )}
              />
            </div>
          </>
        )}

        {Number(watch("idEvent")) === 1 && (
          <>
            <div className="col-span-12">
              <p className="text-sm font-semibold">Datos de la novia</p>
            </div>

            <div className="col-span-6">
              <Controller
                name="nameGirlfriendQuotation"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"Nombre novia"}
                  />
                )}
              />
            </div>
            <div className="col-span-6">
              <Controller
                name="rutGirlfriendQuotation"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"RUT"}
                    type={"text"}
                  />
                )}
              />
            </div>
            <div className="col-span-6">
              <Controller
                name="dateofBirthGirlfriendQuotation"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"Fecha de nacimiento"}
                    type={"date"}
                  />
                )}
              />
            </div>
            <div className="col-span-6 flex gap-2">
              <div className="text-sm self-center ">+569</div>
              <Controller
                name="phoneGirlfriendQuotation"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"Teléfono"}
                    type={"text"}
                  />
                )}
              />
            </div>
            <div className="col-span-12">
              <Controller
                name="addressGirlfriendQuotation"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"Dirección"}
                    type={"text"}
                  />
                )}
              />
            </div>
          </>
        )}

        <div className="col-span-12">
          <p className="text-sm font-semibold">Datos del cliente</p>
        </div>

        <div className="col-span-12">
          <Controller
            name="rutQuotation"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                value={value}
                onChange={onChange}
                title={"RUT"}
                type={"text"}
              />
            )}
          />
        </div>
        <div className="col-span-6">
          <Controller
            name="nameQuotation"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputDefault
                required
                loading={false}
                fullWidth
                value={value}
                onChange={onChange}
                title={"Nombres"}
              />
            )}
          />
        </div>
        <div className="col-span-6">
          <Controller
            name="lastNameQuotation"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputDefault
                required
                loading={false}
                fullWidth
                value={value}
                onChange={onChange}
                title={"Apellidos"}
              />
            )}
          />
        </div>
        <div className="col-span-12">
          <Controller
            name="directionQuotation"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                value={value}
                onChange={onChange}
                title={"Dirección"}
                type={"text"}
              />
            )}
          />
        </div>
        <div className="col-span-6">
          <Controller
            name="emailQuotation"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                value={value}
                onChange={onChange}
                title={"Correo electrónico"}
                type={"text"}
              />
            )}
          />
        </div>
        <div className="col-span-6">
          <Controller
            name="phoneQuotation"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                value={value}
                onChange={onChange}
                title={"Teléfono"}
                type={"text"}
              />
            )}
          />
        </div>

        {/* <div className="col-span-12">
          <Controller
            name="birthdateQuotation"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                value={value}
                onChange={onChange}
                title={"Fecha de nacimiento"}
                type={"date"}
              />
            )}
          />
        </div> */}

        <div className="col-span-12">
          <Controller
            name="claspType"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                required
                value={value}
                onChange={onChange}
                title={"Tipo de cierre"}
                type={"select"}
                options={dataClaspType}
              />
            )}
          />
        </div>

        <div className="col-span-12">
          <p className="text-sm font-semibold">Formas de pago</p>
        </div>
        <div className="col-span-12">
          <Table
            aria-label="sticky table"
            size={"small"}
            className="w-full border border-gray-200"
          >
            <TableHead>
              <TableRow>
                <TableCell colSpan={4} className="!text-center">
                  Cantidad de pagos
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {watch("feePayment").map((el, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      <InputDefault
                        required
                        fullWidth
                        type="month"
                        value={el.date_feepayment}
                        onChange={(e) => {
                          const value = e.target.value;

                          setValue(
                            "feePayment",
                            watch("feePayment").map((el, idx) => {
                              if (idx === index) {
                                return {
                                  ...el,
                                  date_feepayment: value,
                                };
                              }

                              return el;
                            })
                          );
                        }}
                        title={`Fecha`}
                      />
                    </TableCell>
                    <TableCell>
                      <InputDefault
                        required
                        fullWidth
                        value={el.value_feepayment}
                        onChange={(e) => {
                          const value = e.target.value;

                          setValue(
                            "feePayment",
                            watch("feePayment").map((el, idx) => {
                              if (idx === index) {
                                return {
                                  ...el,
                                  value_feepayment: value,
                                };
                              }

                              return el;
                            })
                          );
                        }}
                        type="number"
                        title={`Valor`}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => onDeleteRowPayment(index)}
                        disabled={watch("feePayment").length === 1}
                      >
                        <Close />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableCell className="!text-center !p-0" colSpan={4}>
                <Button
                  color="info"
                  variant="text"
                  className="w-full !bg-slate-100 hover:bg-slate-700 text-white"
                  fullWidth
                  onClick={() => {
                    setValue("feePayment", [
                      ...watch("feePayment"),
                      {
                        date_feepayment: dayjs().format("YYYY-MM"),
                        value_feepayment: 0,
                      },
                    ]);
                  }}
                >
                  <Add />
                  Agregar
                </Button>
              </TableCell>
            </TableBody>
          </Table>
        </div>
      </div>
    </form>
  );
};

export default ContractFormDH;
