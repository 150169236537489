export const formatPhoneNumber = (number = "", moneda = "CLP") => {
  const userData = JSON.parse(localStorage.getItem("user_vm"));

  const PHONE_NUMBER = userData?.empresa?.moneda_empsede || moneda;

  if (!PHONE_NUMBER) {
    return `+56${number}`;
  }

  if (PHONE_NUMBER === "CLP") {
    return `+56${number}`;
  }

  if (PHONE_NUMBER === "PEN") {
    return `+51${number}`;
  }

  return number;
};
