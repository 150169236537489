import "../carta.style.scss";

// import PropTypes from "prop-types";

import { AppBar, Toolbar, Typography } from "@mui/material";

const HeadCarta = (props) => {
  let { image = null, actionButtons = null } = props;

  const logo = image ?? "/";
  const logoSplit = logo.split("/");
  const logoLength = Number(logoSplit.length) - 1;
  const logoName = logoSplit[logoLength];

  return (
    <AppBar className="headCarta">
      <Toolbar>
        {logoName && (
          <img
            src={require(`../../../../src/assets/images/logos/${logoName}`)}
            alt="logo"
            style={{ width: 80, height: 40 }}
          />
        )}

        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1 }}
        ></Typography>

        <div>{actionButtons}</div>
      </Toolbar>
    </AppBar>
  );
};

export default HeadCarta;
