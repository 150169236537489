import * as React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Slide,
  Typography,
} from "@mui/material";

import { AttachMoney, Payment } from "@mui/icons-material";

import ItemInput from "../../Components/ItemInput";
import Keyboard from "../../Components/KeyBoard";

import "./style.payaccount.scss";

import toast from "react-hot-toast";
import { formatMoney, tipMoney } from "../../../../../functions/formatMoney";

function formatNumber(n) {
  return n === "" || n === 0 ? n : Number(n).toLocaleString();
}

function SubTotal(data) {
  let total = 0;

  for (let i = 0; i < data.length; i++) {
    const subTotalPlato = data[i].precio_plato * data[i].amount_plapedido;
    const typeDiscountPlato = data[i].tipodescuento_plapedido
      ? data[i].tipodescuento_plapedido
      : "";
    const discountPlato = data[i].descuento_plapedido
      ? data[i].descuento_plapedido
      : "";

    if (typeDiscountPlato === "PORCENTAJE") {
      total += subTotalPlato - subTotalPlato * (discountPlato / 100);
    } else if (typeDiscountPlato === "PRECIO") {
      total += subTotalPlato - discountPlato;
    } else if (typeDiscountPlato === "COVER") {
      const result = subTotalPlato - discountPlato;
      total += result < 0 ? 0 : result;
    } else {
      total += subTotalPlato;
    }
  }

  return total;
}

function SignalMoney() {
  const userData = JSON.parse(localStorage.getItem("user_vm"));

  const moneda_empsede = userData?.empresa?.moneda_empsede || "CLP";

  let signal = "";

  if (moneda_empsede === "CLP") {
    signal = (
      <AttachMoney style={{ fontSize: 34, display: "block", margin: "auto" }} />
    );
  }

  if (moneda_empsede === "PEN") {
    signal = (
      <Typography style={{ fontSize: 23, fontWeight: "700" }}>S/.</Typography>
    );
  }

  return signal;
}

function ExtraDiscount(subTotal, data) {
  if (data.id_reserva || data.id_reserva > 0) {
    const discount = data.monto_reserva || 0;

    return {
      textAmountExtraDiscount: `MENOS EL ABONO RESERVA`,
      amountExtraTotal: discount,
    };
  }

  const amountExtraDiscount = 0;
  const textAmountExtraDiscount = `CON PROP. INCLUIDA DEL ${amountExtraDiscount}%`;
  const amountExtraTotal = subTotal * (amountExtraDiscount / 100);

  return {
    textAmountExtraDiscount,
    amountExtraTotal,
  };
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogPayAccount = (props) => {
  let { open, setOpen, dataProducts, infoPedido, callback } = props;

  const dataProduct = dataProducts.data;
  const data = infoPedido.data;

  const [moodPay, setMoodPay] = React.useState("TARJETA");
  const [saldo, setSaldo] = React.useState({
    EFECTIVO: 0,
    TARJETA: 0,
  });

  const [saldoTotal, setSaldoTotal] = React.useState(0);
  const [errDialog, setErrDialog] = React.useState(false);

  /**
   * @description: Funcion para todo el proceso de pago
   **/

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeMood = (value) => {
    setMoodPay(value);
  };

  const handleSetSaldo = (num) => {
    setSaldo({
      ...saldo,
      [moodPay]: num,
    });
  };

  const isAbooking = data?.id_reserva && data?.id_reserva > 0;

  const consumoTotal = SubTotal(dataProduct);

  const discount = data.descuento_pedido;
  const typeDiscount = data.tipodescuento_pedido;
  const { textAmountExtraDiscount, amountExtraTotal } = ExtraDiscount(
    consumoTotal,
    data
  );

  let discountTotal = 0;

  if (typeDiscount === "PORCENTAJE") {
    discountTotal = discount
      ? consumoTotal - consumoTotal * (discount / 100)
      : 0;
  } else if (typeDiscount === "PRECIO") {
    discountTotal = discount ? consumoTotal - discount : 0;
  } else if (typeDiscount === "COVER") {
    discountTotal = discount ? consumoTotal - discount : 0;
  } else {
    discountTotal = consumoTotal;
  }

  const subTotalAmountExtra = !isAbooking
    ? consumoTotal + amountExtraTotal
    : consumoTotal - amountExtraTotal;

  const subTotalDiscountExtra = !isAbooking
    ? discountTotal + amountExtraTotal
    : discountTotal - amountExtraTotal;

  const total =
    discountTotal === 0
      ? subTotalAmountExtra - discountTotal
      : subTotalDiscountExtra;

  const subTotalLiberados = !isAbooking ? 0 : data.priceliberados_pedido;

  const totalPagar = !isAbooking
    ? discountTotal - subTotalLiberados
    : discountTotal - amountExtraTotal - subTotalLiberados;

  const newTotal = total < 0 ? 0 : total;

  const discountNumber =
    typeDiscount === "PORCENTAJE"
      ? `${discount}%`
      : typeDiscount === "PRECIO"
      ? `${formatNumber(discount)} CLP`
      : typeDiscount === "COVER"
      ? `${formatNumber(discount)} CLP`
      : "0 CLP";

  const handleSubmitPayAccount = async () => {
    if (moodPay === "") {
      toast.error("Seleccionar alguna opción de pago.");
      return;
    }

    if (saldoTotal < totalPagar) {
      toast.error("El saldo no puede ser menor al total a pagar.");
      return;
    }

    const propinaTotal = !isAbooking
      ? parseFloat(saldoTotal) - discountTotal
      : 0;

    handleClose();

    let { status } = await callback({
      saldoEfectivo: parseFloat(saldo.EFECTIVO),
      saldoTarjeta: parseFloat(saldo.TARJETA),
      saldoTotal: parseFloat(saldoTotal),
      consumoTotal: consumoTotal,
      propinaTotal: propinaTotal,
      tipoPago: moodPay,
    });

    if (status === "error") {
      setErrDialog("Error al aplicar descuento");
    }
  };

  React.useEffect(() => {
    setErrDialog(false);

    setSaldo({
      EFECTIVO: 0,
      TARJETA: 0,
    });
  }, [open]);

  React.useEffect(() => {
    const saldoTotal = parseFloat(saldo.EFECTIVO) + parseFloat(saldo.TARJETA);
    setSaldoTotal(saldoTotal);
  }, [saldo]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      maxWidth={"sm"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Pagar cuenta</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          {errDialog && (
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography
                variant="h6"
                component={"h6"}
                style={{ color: "red" }}
              >
                Error al pagar la cuenta =(
              </Typography>
            </Grid>
          )}
          <Grid item xs={6}>
            <Paper
              variant="outlined"
              className={`moodPay ${
                moodPay === "EFECTIVO" && "isSelectedMoodPay"
              }`}
              onClick={() => handleChangeMood("EFECTIVO")}
            >
              <SignalMoney />
              EFECTIVO
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper
              variant="outlined"
              className={`moodPay ${
                moodPay === "TARJETA" && "isSelectedMoodPay"
              }`}
              onClick={() => handleChangeMood("TARJETA")}
            >
              <Payment style={{ fontSize: 30 }} /> <br />
              TARJETA
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Keyboard handleAction={handleSetSaldo} />
          </Grid>
          <Grid item xs={6}>
            <ItemInput label="CONSUMO" value={`${formatMoney(consumoTotal)}`} />
          </Grid>
          <Grid item xs={6}>
            <ItemInput
              label={`CON DESCUENTO DEL ${discountNumber} `}
              value={`${formatMoney(discountTotal)}`}
            />
          </Grid>
          {isAbooking && (
            <Grid item xs={12}>
              <ItemInput
                label="TOTAL LIBERADOS"
                value={`${formatMoney(subTotalLiberados)}`}
                fullWidth
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <ItemInput
              label="TOTAL PAGAR"
              value={`${formatMoney(totalPagar)}`}
              fullWidth
            />
          </Grid>
          {/* <Grid item xs={6}>
            <ItemInput
              label={textAmountExtraDiscount}
              value={`${formatMoney(newTotal)}`}
              fullWidth
            />
          </Grid> */}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <ItemInput
              label="SALDO EFECTIVO"
              value={`${formatMoney(saldo.EFECTIVO)}`}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <ItemInput
              label="SALDO TARJETA"
              value={`${formatMoney(saldo.TARJETA)}`}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <ItemInput
              label="SALDO TOTAL"
              value={`${formatMoney(saldoTotal)}`}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="text" color="inherit">
          Cerrar
        </Button>
        <Button
          onClick={handleSubmitPayAccount}
          variant="contained"
          color="success"
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogPayAccount;
