import { Grid, TextField } from "@mui/material";
import { nameCamarero } from "../../../../functions/nameCamarero";

const FilterPedido = (props) => {
  const { valueFilter, handleChange, dataTable, dataGarzon } = props;

  const camarero = nameCamarero();

  return (
    <Grid
      container
      spacing={1}
      style={{
        padding: "0px 10px",
        position: "sticky",
        top: "-15px",
        background: "white",
        zIndex: 3,
      }}
    >
      <Grid item xs>
        <TextField
          fullWidth
          select
          variant="outlined"
          value={valueFilter.idMesa}
          name="idMesa"
          onChange={handleChange}
          className={`InputDefault`}
          required
          SelectProps={{
            native: true,
          }}
        >
          <option value="">[Mesa]</option>
          {dataTable.map((option, idx) => (
            <option key={idx} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
      </Grid>
      <Grid item xs>
        <TextField
          fullWidth
          select
          variant="outlined"
          value={valueFilter.idGarzon}
          name="idGarzon"
          onChange={handleChange}
          className={`InputDefault`}
          required
          SelectProps={{
            native: true,
          }}
        >
          <option value="">[{camarero}]</option>
          {dataGarzon.map((option, idx) => (
            <option key={idx} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );
};

export default FilterPedido;
