/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";

import { get_marca } from "../services/marca.services";

const useMarca = () => {
  const [dataMarca, setdataMarca] = React.useState({
    data: [],
    load: false,
    err: false,
  });

  React.useEffect(() => {
    async function obtainDataMarca() {
      try {
        setdataMarca({ load: true, data: [], err: false });
        let response = await get_marca();

        setdataMarca({
          load: false,
          data: response.map((el) => ({
            value: el.marc_id,
            label: el.marc_nombre,
          })),
          err: false,
        });
      } catch (err) {
        console.log(err);
        setdataMarca({ load: false, data: [], err: true });
      }
    }

    obtainDataMarca();

    return null;
  }, []);

  return {
    dataMarca,
  };
};

export default useMarca;
