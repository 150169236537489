/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Template from "../../components/Template";

import { checkToken, getUser } from "../../services/auth.services";
import {
  best_seller,
  best_seller_tip,
  best_selling_category,
  best_selling_product,
  best_selling_table,
  get_pedido,
  tot_cover,
  tot_sale,
} from "../../services/pedidos.services";

import ContainerInput from "../../components/ContainerInput";
import LoadingPage from "../../components/LoadingPage";
import DialogCerrar from "./Dialog/DialogCerrar";
import TablePedido from "./TablePedido";
// import DialogApertura from "./Apertura";

import { get_caja } from "../../services/caja.services";

import { PointOfSale } from "@mui/icons-material";
import { Alert, Button, Card, Grid, Typography } from "@mui/material";

import CardReport from "./CardReport";

import "moment/locale/es";
import Moment from "react-moment";

import { formatMoney } from "../../functions/formatMoney";
import { nameCamarero } from "../../functions/nameCamarero";
import Apertura from "./BoxData/apertura";
import FormaPago from "./BoxData/formaPago";
import Propinas from "./BoxData/propinas";

const CloseBox = (props) => {
  let history = useHistory();
  //let { socket } = props;

  const [loadPage, setLoadPage] = useState(false);
  const [dataPedido, setDataPedido] = useState({
    load: true,
    err: false,
    data: [],
  });
  const [cajaData, setCajaData] = useState(false);
  const [openCerrar, setOpenCerrar] = useState(false);

  const [bestSellingProduct, setBestSellingProduct] = useState({
    load: true,
    err: false,
    data: [],
  });

  const [bestSellingCategory, setBestSellingCategory] = useState({
    load: true,
    err: false,
    data: [],
  });

  const [bestSellingTable, setBestSellingTable] = useState({
    load: true,
    err: false,
    data: [],
  });

  const [bestSeller, setBestSeller] = useState({
    load: true,
    err: false,
    data: [],
  });

  const [bestSellerTip, setBestSellerTip] = useState({
    load: true,
    err: false,
    data: [],
  });

  const [coverData, setCoverData] = useState({
    load: true,
    err: false,
    data: [],
  });

  const [totSale, setTotSale] = useState({
    load: true,
    err: false,
    data: [],
  });

  const handleGetPedido = async () => {
    try {
      setLoadPage(true);

      // const response = await get_pedido_all();
      const response = await get_pedido("finished");

      setDataPedido({ load: false, err: false, data: response }); /* data */
      setLoadPage(false);
    } catch (err) {
      setDataPedido({ load: false, err: true, data: [] });
      setLoadPage(false);
    }
  };

  const handleCerrarCaja = () => {
    setOpenCerrar(true);
  };

  /**
   * @description: Funcion para obtener los reportes
   */

  const handleAperturaCaja = async () => {
    try {
      const param = "oneday";

      const response = await get_caja(param);

      setCajaData(response[0] ? response[0] : false);
    } catch (err) {
      setCajaData(false);
    }
  };

  const handleBestSellingProduct = async () => {
    try {
      const response = await best_selling_product();
      setBestSellingProduct({
        load: false,
        err: false,
        data: response,
      });
    } catch (err) {
      setBestSellingProduct({ load: false, err: true, data: [] });
    }
  };

  const handleTotSale = async () => {
    try {
      const response = await tot_sale();
      setTotSale({
        load: false,
        err: false,
        data: response,
      });
    } catch (err) {
      setTotSale({ load: false, err: true, data: [] });
    }
  };

  const handleBestSellingCategory = async () => {
    try {
      const response = await best_selling_category();
      setBestSellingCategory({
        load: false,
        err: false,
        data: response,
      });
    } catch (err) {
      setBestSellingCategory({ load: false, err: true, data: [] });
    }
  };

  const handleBestSellingTable = async () => {
    try {
      const response = await best_selling_table();

      setBestSellingTable({
        load: false,
        err: false,
        data: response,
      });
    } catch (err) {
      setBestSellingTable({ load: false, err: true, data: [] });
    }
  };

  const handleBestSeller = async () => {
    try {
      const response = await best_seller();
      setBestSeller({
        load: false,
        err: false,
        data: response,
      });
    } catch (err) {
      setBestSeller({ load: false, err: true, data: [] });
    }
  };

  const handleBestSellerTip = async () => {
    try {
      const response = await best_seller_tip();

      setBestSellerTip({
        load: false,
        err: false,
        data: response,
      });
    } catch (err) {
      setBestSellerTip({ load: false, err: true, data: [] });
    }
  };

  const handleCover = async () => {
    try {
      const response = await tot_cover();

      setCoverData({
        load: false,
        err: false,
        data: response,
      });
    } catch (err) {
      setCoverData({ load: false, err: true, data: [] });
    }
  };

  useEffect(() => {
    function handleReport() {
      handleBestSellingProduct();
      handleTotSale();
      handleBestSellingCategory();
      handleBestSellingTable();
      handleBestSeller();
      handleBestSellerTip();
      handleCover();
    }

    handleReport();

    handleAperturaCaja();
  }, []);

  //CHECK TOKEN
  useEffect(() => {
    if (!checkToken()) {
      history.push("/");
      return null;
    }

    handleGetPedido();
  }, []);

  if (!cajaData) {
    return (
      <Template title="Cierre de caja" classmaincontainer="mainContainerTmp">
        <LoadingPage open={loadPage} setOpen={setLoadPage} />

        <ContainerInput
          style={{
            textAlign: "center",
            height: "100%",
            background: "transparent",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center", margin: "auto" }}>
            <PointOfSale style={{ fontSize: 40 }} /> <br />
            <Typography component={"h5"} variant={"h5"}>
              No existe caja abierta para el dia de hoy.
            </Typography>
          </div>
        </ContainerInput>
      </Template>
    );
  }

  if (cajaData && cajaData.status_caja === "CERRADO") {
    return (
      <Template title="Cierre de caja" classmaincontainer="mainContainerTmp">
        <LoadingPage open={loadPage} setOpen={setLoadPage} />

        <ContainerInput
          style={{
            textAlign: "center",
            height: "100%",
            background: "transparent",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center", margin: "auto" }}>
            <Alert severity="warning">
              La caja se encuentrá cerrada, no se puede realizar ninguna acción.
              <br />
              Hora de cierre:{" "}
              <Moment format="HH:mm:ss">{cajaData.created_cierre_caja}</Moment>
            </Alert>
          </div>
        </ContainerInput>
      </Template>
    );
  }

  return (
    <Template title="Cierre de caja">
      <LoadingPage open={loadPage} setOpen={setLoadPage} />
      <Grid container spacing={1}>
        <Grid item xs={9}></Grid>
        <Grid item xs={3}>
          {cajaData.status_caja === "ABIERTO" && (
            <Button
              fullWidth
              className="button-success"
              onClick={() => handleCerrarCaja()}
              style={{ height: "100%" }}
              variant="contained"
              color="success"
            >
              Cerrar caja
            </Button>
          )}

          {cajaData && cajaData.status_caja === "CERRADO" && (
            <Button
              fullWidth
              className="button-success"
              style={{ height: "100%", opacity: 0.3 }}
              disabled={true}
              variant="contained"
              color="success"
            >
              Caja cerrada
            </Button>
          )}
        </Grid>

        <Grid item xs={3}>
          <Apertura />
        </Grid>
        {/**
         * MENOS PARA DH
         **/}
        {getUser().empresa.id_emp !== 4 && (
          <Grid item xs={3}>
            <Propinas />
          </Grid>
        )}

        <Grid item xs={3}>
          <CardReport
            title={"Total consumo"}
            dataReport={{
              data: totSale.data,
              load: totSale.load,
              err: false,
            }}
          >
            <Typography variant="h5" component="div">
              {formatMoney(totSale.data[0]?.tot_consumo || 0)}
            </Typography>
          </CardReport>
        </Grid>
        {getUser().empresa.id_emp !== 4 && (
          <Grid item xs={3}>
            <CardReport
              title={"Total consumo + entrada"}
              dataReport={{
                data: totSale,
                load: totSale.load,
                err: false,
              }}
            >
              <Typography variant="h5" component="div">
                {formatMoney(
                  (totSale.data[0]?.tot_consumo || 0) +
                    coverData.data?.tot_cover * 7000
                )}
              </Typography>
            </CardReport>
          </Grid>
        )}
        <Grid item xs={4}>
          <FormaPago />
        </Grid>
        <Grid item xs={4}>
          <CardReport
            title={"Lo más consumido hoy"}
            dataReport={bestSellingProduct}
          >
            <Grid container style={{ fontSize: "13px" }}>
              {bestSellingProduct.data.map((el, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <Grid item xs={10} style={{ textAlign: "left" }}>
                      {el.nombre_plato}
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: "right" }}>
                      {el.tot_plato}
                    </Grid>
                    <Grid item xs={12}>
                      <hr style={{ opacity: "0.3", margin: "2px" }} />
                    </Grid>
                  </React.Fragment>
                );
              })}
            </Grid>
          </CardReport>
        </Grid>
        <Grid item xs={4}>
          <Card variant="outlined" style={{ borderRadius: 12 }}>
            <CardReport
              title={"Ventas por categoría"}
              dataReport={bestSellingCategory}
            >
              <Grid container style={{ fontSize: "13px" }}>
                {bestSellingCategory.data.map((el, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      <Grid item xs={10} style={{ textAlign: "left" }}>
                        {el.nombre_categoriapla}
                      </Grid>
                      <Grid item xs={2} style={{ textAlign: "right" }}>
                        {el.tot_plato}
                      </Grid>
                      <Grid item xs={12}>
                        <hr style={{ opacity: "0.3", margin: "2px" }} />
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </Grid>
            </CardReport>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <CardReport title={"Mesa más activa"} dataReport={bestSellingTable}>
            <Grid container style={{ fontSize: "13px" }}>
              {bestSellingTable.data.map((el, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <Grid item xs={10} style={{ textAlign: "left" }}>
                      #{el.codigo_mesa}
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: "right" }}>
                      {el.tot_mesa}
                    </Grid>
                    <Grid item xs={12}>
                      <hr style={{ opacity: "0.3", margin: "2px" }} />
                    </Grid>
                  </React.Fragment>
                );
              })}
            </Grid>
          </CardReport>
        </Grid>
        <Grid item xs={4}>
          <CardReport
            title={`Monto de venta por ${nameCamarero(false)}`}
            dataReport={bestSeller}
          >
            <Grid container style={{ fontSize: "13px" }}>
              {bestSeller.data.map((el, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <Grid item xs={8} style={{ textAlign: "left" }}>
                      {el.usu_nombre}
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: "right" }}>
                      {formatMoney(el.pagoxconsumo_pedido)}
                    </Grid>
                    <Grid item xs={12}>
                      <hr style={{ opacity: "0.3", margin: "2px" }} />
                    </Grid>
                  </React.Fragment>
                );
              })}
            </Grid>
          </CardReport>
        </Grid>
        <Grid item xs={4}>
          <Card variant="outlined" style={{ borderRadius: 12 }}>
            <CardReport
              title={`Monto de propina por ${nameCamarero(false)}`}
              dataReport={bestSellerTip}
            >
              <Grid container style={{ fontSize: "13px" }}>
                {bestSellerTip.data.map((el, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      <Grid item xs={8} style={{ textAlign: "left" }}>
                        {el.usu_nombre}
                      </Grid>
                      <Grid item xs={4} style={{ textAlign: "right" }}>
                        {formatMoney(el.pagopropina_pedido)}
                      </Grid>
                      <Grid item xs={12}>
                        <hr style={{ opacity: "0.3", margin: "2px" }} />
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </Grid>
            </CardReport>
          </Card>
        </Grid>

        {/**
         * only for company Verde mostaza (2)
         */}
        {getUser().empresa.id_emp === 2 && (
          <Grid item xs={4}>
            <CardReport title={`Cover del día`} dataReport={coverData}>
              <Grid container style={{ fontSize: "13px" }}>
                <Grid item xs={8} style={{ textAlign: "left" }}>
                  Cantidad total
                </Grid>
                <Grid item xs={4} style={{ textAlign: "right" }}>
                  {coverData.data?.tot_cover || 0}
                </Grid>
                <Grid item xs={8} style={{ textAlign: "left" }}>
                  Descuento total
                </Grid>
                <Grid item xs={4} style={{ textAlign: "right" }}>
                  {formatMoney(coverData.data?.descuenTot_cover || 0)}
                </Grid>
                <Grid item xs={12}>
                  <hr style={{ opacity: "0.3", margin: "2px" }} />
                </Grid>
              </Grid>
            </CardReport>
          </Grid>
        )}
      </Grid>

      <ContainerInput
        style={{
          marginTop: 10,
          marginBottom: 20,
          padding: "24px 10px",
        }}
      >
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Detalle de pedidos
        </Typography>
        <TablePedido
          dataPedido={dataPedido}
          style={{ border: "1px #eee solid" }}
        />
      </ContainerInput>

      <DialogCerrar
        open={openCerrar}
        setOpen={setOpenCerrar}
        handleCajaRefresh={() => handleAperturaCaja()}
      />
    </Template>
  );
};

export default CloseBox;
