import dayjs from "dayjs";
import React, { useEffect } from "react";
import CustomTable from "../../atomic/organisms/Table.organisms";
import ContainerInput from "../../components/ContainerInput";
import Template from "../../components/Template";
import { getWallet } from "../../services/wallet.services";
import { Alert } from "@mui/material";

const formatNumber = (n) => {
  return n === "" || n === 0 ? n : Number(n).toLocaleString();
};

const calcToPay = (data) => {
  return (
    (Number(data.quantity_sold) - Number(data.quantity_released)) *
    Number(data.monto_comission)
  );
};

const WalletPage = () => {
  const [dataWallet, setDataWallet] = React.useState([]);
  const [load, setLoad] = React.useState(false);
  const [totPay, setTotPay] = React.useState(0);

  const fetchDataWallet = async () => {
    try {
      setLoad(true);
      const data = await getWallet();

      setLoad(false);

      const newData = data
        .map((el) => {
          return {
            ...el,
            quantity_to_pay: calcToPay(el),
            name_reserva: `${el.nombre_reserva || ""} ${
              el.apellido_paterno_reserva || ""
            } ${el.apellido_materno_reserva || ""}`,
          };
        })
        .filter((el) => el.status_wallet === "pendiente");

      setTotPay(
        newData.reduce((acc, el) => {
          return acc + el.quantity_to_pay;
        }, 0)
      );

      setDataWallet(newData);
    } catch (error) {
      console.error("Error en fetchDataWallet: ", error);
    }
  };

  useEffect(() => {
    fetchDataWallet();
  }, []);

  return (
    <Template title="Reporte" style={{ position: "relative" }}>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-4">
          <ContainerInput style={{ padding: 10 }}>
            <h1 className="text-xl">Dinero en tu billetera:</h1>
            <h1 className="text-3xl font-bold">$ {formatNumber(totPay)}</h1>
          </ContainerInput>
        </div>
        <Alert severity="info" className="col-span-12 md:col-span-8">
          <p>
            En esta sección podrás ver el dinero que tienes en tu billetera
            virtual, el cual se compone de las comisiones generadas por las
            ventas, recuerda que la fecha de pago es el día 5 de cada mes.
          </p>
        </Alert>
        <div className="col-span-12 md:col-span-12">
          <h2 className="mb-4">
            <span className="text-2xl font-bold">
              {
                dataWallet.filter((el) => el.status_wallet === "pendiente")
                  .length
              }
            </span>{" "}
            CUENTAS POR PAGAR
          </h2>
          <ContainerInput className="p-0">
            <CustomTable
              columns={[
                {
                  id: "id_reserva",
                  label: "Código",
                  minWidth: 80,
                  align: "left",
                },
                {
                  id: "name_reserva",
                  label: "Nombre",
                  minWidth: 150,
                  align: "left",
                  format: (el) => {
                    return el.toUpperCase();
                  },
                },
                {
                  id: "quantity_to_pay",
                  label: "Cantidad a pagar",
                  minWidth: 150,
                  align: "left",
                  format: (el) => {
                    return `$ ${formatNumber(el)}`;
                  },
                },
                {
                  id: "pax_reserva",
                  label: "PAX reserva",
                  minWidth: 150,
                  align: "left",
                },
                {
                  id: "created_at",
                  label: "Fecha creación",
                  minWidth: 160,
                  align: "left",
                  format: (el) => {
                    return dayjs(el).utc().format("DD/MM/YYYY");
                  },
                },
              ]}
              rows={dataWallet}
              load={load}
              error={false}
              pagination={true}
            />
          </ContainerInput>
        </div>
      </div>
    </Template>
  );
};

export default WalletPage;
