import { Close } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import toast from "react-hot-toast";
import Keyboard from "../../checker/Components/Components/KeyBoard";

export default function DialogRequestPassword({ open, setOpen, callback }) {
  const handleClose = () => {
    setOpen(false);
  };

  const onCheckPwd = (number) => {
    const PWD_CORRECT = "1234";

    if (number === PWD_CORRECT) {
      callback(true);
      handleClose();

      return;
    }

    toast.error("La contraseña es incorrecta.", {
      position: "top-center",
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      keepMounted
      maxWidth={"xs"}
      fullWidth
    >
      <DialogTitle>
        Necesitamos que coloques tu contraseña{" "}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 10,
            top: 14,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Keyboard handleAction={(number) => onCheckPwd(number)} />
      </DialogContent>
      {/* <DialogActions>
        <Button variant="contained" color="error" onClick={handleClose}>
          Salir
        </Button>
      </DialogActions> */}
    </Dialog>
  );
}
