import {
  Breadcrumbs,
  Chip,
  Grid,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

import ContainerInput from "../../../../components/ContainerInput";

import { useDispatch, useSelector } from "react-redux";
import { actionTypes } from "../../../../redux/pedido/actions";

import {
  AddShoppingCart,
  ArrowBack,
  RemoveShoppingCart,
} from "@mui/icons-material";
import NotFound from "../../../../atomic/organisms/NotFound.organisms";
import { formatMoney } from "../../../../functions/formatMoney";
import { getUser } from "../../../../services/auth.services";

const NavBack = (props) => {
  return (
    <Breadcrumbs aria-label="breadcrumb" style={{ padding: "5px 5px 0px" }}>
      {props.children}
    </Breadcrumbs>
  );
};

// const ItemRow = (props) => {
//   let { idx, item, handleAddProduct } = props;

//   return (
//     <ListItem key={idx} className="listMiniCart__listitem">
//       <Grid container spacing={1}>
//         <Grid item className="imageItem" xs={12} sm={8} lg={8}>
//           <div className="container__title">
//             <div className="__name">{item.nombre_plato}</div>
//             <div className="__category">{item.nombre_categoriapla}</div>
//             <div className="__price">
//               <Currency
//                 currency="CLP"
//                 quantity={item.precio_plato}
//                 // quantity={item.precio_plato * item.totAmount}
//                 pattern="$ ##,### "
//               />
//             </div>
//           </div>
//         </Grid>
//         <Grid item className="countItem" xs={12} sm={4} lg={4}>
//           <div className="container__count">
//             <InputCount
//               style={{ width: "100%" }}
//               addCount={(el) => handleAddProduct(item, el)}
//               //   valueTotAmount={item.totAmount}
//               valueTotAmount={0}
//             />
//           </div>
//         </Grid>
//       </Grid>
//     </ListItem>
//   );
// };

const StockChip = (props) => {
  let { amount } = props;

  if (amount === null) {
    return null;
  }

  if (parseInt(amount) <= 0) {
    return <Chip label={`AGOTADO`} color="error" size="small" />;
  }

  return <Chip label={`DISPONIBLE ${amount}`} color="success" size="small" />;
};

const ButtonActive = (props) => {
  let { row, amount, isActive, handleDeleteProduct, handleAddProduct } = props;

  if (amount === 0) {
    return (
      <IconButton color="inherit" disabled>
        <RemoveShoppingCart />
      </IconButton>
    );
  }

  return isActive ? (
    <IconButton
      color="inherit"
      style={{ background: "red", color: "white" }}
      onClick={() => handleDeleteProduct(row)}
    >
      <RemoveShoppingCart />
    </IconButton>
  ) : (
    <IconButton
      color="inherit"
      style={{ background: "green", color: "white" }}
      onClick={() => handleAddProduct(row)}
    >
      <AddShoppingCart />
    </IconButton>
  );
};

const ItemRow = (props) => {
  let { idx, el, handleAddProduct, handleDeleteProduct, productSession } =
    props;

  let isActive = productSession.some((el2) => el2.id_plato === el.id_plato);

  return (
    <TableRow
      key={idx}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell align="left">
        <Typography
          variant="overline"
          component="h6"
          style={{
            color: "#008001",
            fontWeight: "600",
            lineHeight: "inherit",
          }}
        >
          {el.nombre_plato}
        </Typography>
        <Typography
          variant="inherit"
          component="label"
          style={{
            color: "red",
            fontWeight: "400",
            lineHeight: "inherit",
            fontSize: "12px",
          }}
        >
          {el.nombre_categoriapla}
        </Typography>{" "}
        <br />
        <StockChip amount={el.amount_proagotado} />
        <br />
        <br />
        {el.descripcion_plato ? (
          <>
            <Typography variant="inherit" component="p">
              {el.descripcion_plato}
            </Typography>
            <br />
          </>
        ) : null}
        <strong style={{ fontSize: 15 }}>{formatMoney(el.precio_plato)}</strong>
      </TableCell>

      <TableCell align="right">
        <ButtonActive
          isActive={isActive}
          amount={el.amount_proagotado}
          handleDeleteProduct={handleDeleteProduct}
          handleAddProduct={handleAddProduct}
          row={el}
        />
      </TableCell>
    </TableRow>
  );
};

const ListProduct = (props) => {
  let { data } = props.data;

  let { inputSearch, fullProduct, handleBackProduct } = props;

  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.reducer_pedido);
  const ID_DH_RCS = 4;
  const ID_DH_GALA = 7;

  const isDH =
    getUser()?.empresa?.id_emp === ID_DH_RCS ||
    getUser()?.empresa?.id_emp === ID_DH_GALA;

  const onAddProduct = (row, count) => {
    dispatch({
      type: actionTypes.addProduct,
      payload: { row, count },
    });
  };

  const onDeleteProduct = (index) => {
    dispatch({
      type: actionTypes.deleteProduct,
      payload: { idx_delete: index },
    });
  };

  const handleAddProduct = (product) => {
    const amount = isDH ? 0 : 1;

    onAddProduct(product, amount);
  };

  const handleDeleteProduct = (product) => {
    let findIdxProduct = products.findIndex(
      (el) => el.id_plato === product.id_plato
    );

    onDeleteProduct(findIdxProduct);
  };

  /*
    CARGA INICIAL, SI NO HAY PRODUCTOS NO MUESTRA NADA
  */

  if (fullProduct === "") {
    return null;
  }

  /*
    SI NO ENCUENTRA PRODUCTOS MOSTRAR MENSAJE
  */

  if (fullProduct === "notfound") {
    return (
      <ContainerInput
        style={{
          marginTop: 10,
          marginBottom: 20,
          padding: "15px 10px",
          textAlign: "center",
        }}
      >
        <NotFound texto="No se encontró registros." />
        <Link onClick={() => handleBackProduct()}>regresar.</Link>
      </ContainerInput>
    );
  }

  return (
    <ContainerInput
      style={{
        marginTop: 10,
        marginBottom: 20,
        padding: "15px 10px",
      }}
    >
      <NavBack>
        <IconButton onClick={() => handleBackProduct()} size="small">
          <ArrowBack />
        </IconButton>

        {inputSearch.category !== "" && (
          <Typography color="text.primary">{inputSearch.category}</Typography>
        )}

        {inputSearch.text !== "" && (
          <Typography
            color="text.primary"
            style={{ textTransform: "uppercase" }}
          >
            {inputSearch.text}
          </Typography>
        )}
      </NavBack>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={1}
        style={{ marginTop: 10 }}
      >
        <Table aria-label="simple table">
          <TableBody>
            {data.map((el, idx) => (
              <ItemRow
                key={idx}
                el={el}
                idx={idx}
                productSession={products}
                handleAddProduct={handleAddProduct}
                handleDeleteProduct={handleDeleteProduct}
              />
            ))}
          </TableBody>
        </Table>
      </Grid>
    </ContainerInput>
  );
};

export default ListProduct;
