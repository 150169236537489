import * as React from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
} from "@mui/material";

import { Settings, Edit, Delete, Visibility } from "@mui/icons-material";

import NotFound from "../../../atomic/organisms/NotFound.organisms";
import moment from "moment";

const TableCampaign = (props) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const rows = props.data;
  const loading = props.load;

  const { handleOpenModal } = props;

  /* HANDLES */

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEdit = (data) => {
    const newData = {
      ...data,
      id_typecampaign: data.id_typecampaign.id_typecampaign,
      durationdate_campaign: data.durationdate_campaign
        ? moment(data.durationdate_campaign).format("YYYY-MM-DD")
        : null,
    };

    handleOpenModal("edit", newData);
  };

  const handleDelete = async (data) => {
    handleOpenModal("delete", data);
  };

  const handleTicket = async (data) => {
    handleOpenModal("showTickets", data);
  };

  if (loading) {
    return (
      <div>
        <h3>Cargando...</h3>
      </div>
    );
  }

  if (rows.length === 0) {
    return <NotFound texto="No se encontró registros." />;
  }

  return (
    <>
      <TableContainer sx={{ maxHeight: 550 }}>
        <Table stickyHeader aria-label="sticky table" size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell align={"left"} style={{ minWidth: 200 }}>
                Título
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 150 }}>
                Ticket
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 140 }}>
                Máximo Tickets
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 120 }}>
                Tipo
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 150 }}>
                Fecha vencimiento
              </TableCell>
              <TableCell
                colSpan={3}
                align={"center"}
                style={{ fontSize: 21, color: "#5c5c5c" }}
              >
                <Settings />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                  <TableCell align={"left"}>
                    <a
                      href={`#/ticket/${row.id_campaign}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "blue",
                        textDecoration: "revert",
                      }}
                    >
                      {row.title_campaign}
                    </a>
                  </TableCell>
                  <TableCell align={"left"}>
                    {row.titleticket_campaign}
                  </TableCell>
                  <TableCell align={"left"}>
                    {row.maxnumberticket_campaign}
                  </TableCell>
                  <TableCell align={"left"}>
                    {row.id_typecampaign.name_typecampaign}
                  </TableCell>
                  <TableCell align={"left"}>
                    {row.durationdate_campaign
                      ? moment(row.durationdate_campaign).format(
                          "DD/MM/YYYY HH:mm"
                        )
                      : ""}
                  </TableCell>
                  <TableCell align={"center"} width={25}>
                    <IconButton color="warning" onClick={() => handleEdit(row)}>
                      <Edit />
                    </IconButton>
                  </TableCell>
                  <TableCell align={"center"} width={25}>
                    <IconButton color="error" onClick={() => handleDelete(row)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                  <TableCell align={"center"} width={25}>
                    <IconButton
                      color="primary"
                      onClick={() => handleTicket(row)}
                    >
                      <Visibility />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage="Filas por página"
        rowsPerPageOptions={[10, 25, 100, { value: -1, label: "Todo" }]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default TableCampaign;
