/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import Template from "../../components/Template";
import LoadingPage from "../../components/LoadingPage";

import { checkToken } from "../../services/auth.services";
import ContainerInput from "../../components/ContainerInput";

import { get_sucursales } from "../../services/sucursal.services";
import TableSucursales from "../../components/Tables/TableSucursales";
import DialogCrudSucursal from "./DialogCrudSucursal";

import InputDefault from "../../atomic/atoms/input.atoms";

import { Button, IconButton } from "@mui/material";
import { Add, Search } from "@mui/icons-material/";
import DialogEliminarSucursal from "./DialogEliminarSucursal";

const FilterSucursales = (props) => {
  const { onOpenAddModal, onSearch } = props;

  const { control, handleSubmit } = useForm({
    defaultValues: {
      searchName: "",
      searchAlias: "",
    },
  });
  return (
    <form onSubmit={handleSubmit(onSearch)} autoComplete="off">
      <div className="mb-4 w-full grid grid-cols-12 gap-2">
        <div className="col-span-8 md:col-span-4">
          <Controller
            name="searchName"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputDefault
                loading={false}
                fullWidth
                value={value}
                onChange={onChange}
                title={"Buscar sucursal"}
                type={"text"}
              />
            )}
          />
        </div>
        <div className="col-span-4 md:col-span-3 flex gap-3">
          <IconButton
            type="submit"
            color="default"
            className="ml-auto col-span-1"
          >
            <Search />
          </IconButton>
        </div>
        <div
          className="col-span-12 md:col-span-5 flex justify-end
        "
        >
          <Button
            color="primary"
            onClick={() => onOpenAddModal(true)}
            className="button-default ml-auto w-full md:w-auto"
          >
            Crear <Add />
          </Button>
        </div>
      </div>
    </form>
  );
};

const Sucursales = (props) => {
  const { type = "normal" } = props;

  let history = useHistory();
  const [loadPage, setLoadPage] = useState(false);
  const [dataSucursales, setDataSucursales] = useState({
    load: false,
    data: [],
  });

  const [openAdd, setOpenAdd] = useState(false);
  const [openEliminarSucursal, setOpenEliminarSucursal] = useState(false);

  const [dataViewData, setDataViewData] = useState({});
  const [dataViewEliminarData, setDataViewEliminarData] = useState("");

  const getSucursales = async () => {
    const data = JSON.parse(localStorage.getItem("user_vm"));
    const id_empresa = data.empresa.id_emp;
    setLoadPage(true);
    try {
      let response = await get_sucursales(id_empresa);
      response = response.filter((el) => el.type_emp === type);
      setDataSucursales({ data: response, load: false });
      setLoadPage(false);
    } catch (err) {
      setLoadPage(false);
      console.log(err.message);
    }
  };

  const handleViewData = (data) => {
    setDataViewData(data);
    setOpenAdd(true);
  };

  const handleViewDataEliminar = (data) => {
    setOpenEliminarSucursal(true);
    setDataViewEliminarData(data);
  };

  const onSearch = async (data) => {
    setLoadPage(true);
    const Storage = JSON.parse(localStorage.getItem("user_vm"));
    const id_empresa = Storage.empresa.id_emp;
    let res = await get_sucursales(id_empresa);
    res = res.filter((el) => el.type_emp === type);

    setLoadPage(false);

    let newData = res;

    if (data.searchName) {
      newData = res.filter(
        (el) =>
          el.nom_empsede
            .toLowerCase()
            .includes(data.searchName.toLowerCase()) ||
          el.alias_empsede.toLowerCase().includes(data.searchName.toLowerCase())
      );
    }
    setDataSucursales({ data: newData, load: false });
  };

  useEffect(() => {
    getSucursales();
  }, []);

  useEffect(() => {
    if (!checkToken()) {
      history.push("/");
      return null;
    }
  }, []);
  return (
    <Template title="Sucursales">
      <LoadingPage open={loadPage} setOpen={setLoadPage} />

      <FilterSucursales onOpenAddModal={setOpenAdd} onSearch={onSearch} />
      <ContainerInput
        style={{
          textAlign: "center",
          borderRadius: "0px",
          padding: "0px",
          marginTop: "20px",
        }}
      >
        <TableSucursales
          rows={dataSucursales.data}
          setRow={(el) => setDataSucursales({ ...dataSucursales, data: el })}
          handleViewData={handleViewData}
          handleViewDataEliminar={handleViewDataEliminar}
        />
      </ContainerInput>
      <DialogCrudSucursal
        open={openAdd}
        setOpen={setOpenAdd}
        handleGetSucursales={getSucursales}
        data={dataViewData}
        setData={setDataViewData}
      />

      <DialogEliminarSucursal
        open={openEliminarSucursal}
        setOpen={setOpenEliminarSucursal}
        data={dataViewEliminarData}
        handleGetSucursales={getSucursales}
      />
    </Template>
  );
};

export default Sucursales;
