import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import { Close } from "@mui/icons-material";
import { Tab, Tabs } from "@mui/material/";

import FormularioReservar from "./Formulario_reserva";

import { getUser } from "../../services/auth.services";

import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import FormTask from "../tasks/ui/form.task";

const DialogReservar = ({
  open,
  setOpen,
  initialValues,
  callBack,
  idEmpSede,
  resetInitialValues,
}) => {
  const [valueTabs, setValueTabs] = useState(0);

  const { Form, submitForm, loading } = FormTask({
    callBack: () => {
      setOpen(false);
    },
    initTask: undefined,
  });

  const handleChange = (event, newValue) => {
    setValueTabs(newValue);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTerminar = () => {
    toast.success("Se agrego la reserva correctamente.");
    setOpen(false);

    callBack();
  };

  useEffect(() => {
    if (!open) {
      resetInitialValues();
    }
  }, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth>
        <DialogTitle>
          Reservar
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Tabs value={valueTabs} onChange={handleChange} centered>
            <Tab label="Reservar" />
            <Tab label="Agendar" />
          </Tabs>
          <br />

          {valueTabs === 0 && (
            <FormularioReservar
              finished={handleTerminar}
              id_usuario={getUser().id}
              id_empsede={idEmpSede}
              initialValues={initialValues}
            />
          )}

          {valueTabs === 1 && (
            <>
              <Form />
              <div className="w-full flex justify-end">
                <button
                  disabled={loading}
                  type="button"
                  onClick={submitForm}
                  className="bg-blue-900 rounded-md p-2 text-white text-sm hover:bg-blue-800 transition duration-300 ease-in-out w-28"
                >
                  Aceptar
                </button>
              </div>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DialogReservar;
