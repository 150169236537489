import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Template from "../../../components/Template";

import { checkToken, getUser } from "../../../services/auth.services";

import {
  get_pedido_id,
  get_pedido_producto,
  saveSplit_account,
} from "../../../services/pedidos.services";

import ContainerInput from "../../../components/ContainerInput";
import LoadingPage from "../../../components/LoadingPage";

import { Add, ArrowBack } from "@mui/icons-material";
import {
  BottomNavigation,
  BottomNavigationAction,
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { useReactToPrint } from "react-to-print";

import "./style.ver.scss";

import "../../checker/Components/AcctionButtons/style.acctionbutton.scss";

import DataPedido from "../../checker/Components/DataPedido";

import { CallSplit } from "@mui/icons-material";
import toast from "react-hot-toast";
import PrintAccount from "../PrintAccount";
import ListProduct from "./Components/ListProduct";
import ListProductCuadro from "./Components/ListProductCuadro";

import { formatMoney } from "../../../functions/formatMoney";
import DialogSplitAccount from "../../checker/Components/Dialog/DialogSplitAccount";
import DialogCoverApply from "./Components/DialogCoverApply";

function SubTotal(data) {
  let total = 0;
  for (let i = 0; i < data.length; i++) {
    const discount = data[i].descuento_plapedido || 0;
    const precio = data[i].precio_plato * data[i].amount_plapedido;
    const result = precio - discount;
    total += result < 0 ? 0 : result;
  }

  return total;
}

const NavBack = (props) => {
  let { handleBack, info } = props;
  return (
    <Breadcrumbs
      {...props}
      className="navBackCartProduct"
      aria-label="breadcrumb"
    >
      <IconButton onClick={() => handleBack()} size="small">
        <ArrowBack />
      </IconButton>

      <Typography color="text.primary">
        {/* {info.nombre_cliente} {info.apepaterno_cliente} */}
        {info.codigo_pedido}
      </Typography>
    </Breadcrumbs>
  );
};

const Ver = (props) => {
  const history = useHistory();
  const { id_pedido } = useParams();

  const { socket } = props;

  const INITIALVALUES_PEDIDO = {
    cantidad_mesa: "",
    codigo_mesa: "",
    codigo_pedido: "",
    estado_reserva: "",
    fechareg_pedido: "",
    fechareserva_reserva: "",
    id_mesa: "",
    id_pedido: "",
    id_estadopedido: "",
    nombre_estadopedido: "",
    nombre_cliente: "",
    apepaterno_cliente: "",
    apematerno_cliente: "",
  };

  const [dataPedido, setDataPedido] = useState(INITIALVALUES_PEDIDO);

  const [valueNavigation, setValueNavigation] = useState(null);
  const [mobile, setMobile] = useState(false);

  const [openDialogCoverApply, setOpenDialogCoverApply] = useState(false);

  const [loadPage, setLoadPage] = useState(false);
  const [openApplyDiscount, setOpenApplyDiscount] = useState(false);
  const [dataProducts, setDataProducts] = useState({
    data: [],
    load: false,
    err: false,
  });
  const [idProductSelected, setIdProductSelected] = useState(null);
  const [statusCover, setStatusCover] = useState("apply");

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedProductsAll, setSelectedProductsAll] = useState(false);

  const componentRefPrint = useRef();

  const handleBack = () => {
    history.goBack();
  };

  const handleAddProducts = () => {
    history.push(`/orders/add/${id_pedido}`);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRefPrint.current,
    documentTitle: "Pedidos",
    onAfterPrint: async () => {
      console.log("imprimiendo after");
    },
  });

  const handleSplitAccount = () => {
    setOpenApplyDiscount(true);
  };

  const handleSaveSplitAccount = async (data) => {
    try {
      const json = {
        infoPedido: {
          id_pedido: dataPedido.id_pedido,
          id_reserva: dataPedido.id_reserva,
          id_cliente: dataPedido.id_cliente,
          id_mesa: dataPedido.id_mesa,
          usu_id: dataPedido.usu_id,
          codigo_pedido: dataPedido.codigo_pedido,
        },
        ...data,
      };

      setLoadPage(true);

      let response = await saveSplit_account(json);

      if (response === true) {
        await fetchGet();

        toast.success("Cuenta dividida correctamente.");

        // refrescar todos los productos para cocina
        socket.emit("client:refreshProducts", getUser().empresa.id_empsede);

        // refrescar todos los productos de ese pedido (ID, CODIGO)
        socket.emit("client:refreshProductStatus", {
          id_empsede: getUser().empresa.id_empsede,
          cod: dataPedido.codigo_pedido,
        });
      } else {
        toast.error("Error al dividir la cuenta, intentalo de nuevo.");
      }

      setLoadPage(false);
    } catch (err) {
      setLoadPage(false);
      console.log(err);
    }
  };

  const handleActionPrint = () => {
    if (selectedProducts.length === 0) {
      toast.error("Es necesario seleccionar productos para imprimir.");
      return;
    }

    handlePrint();
  };

  const handleGetPedido = async (id) => {
    try {
      setLoadPage(true);
      const response = await get_pedido_id(id);
      setLoadPage(false);

      if (response === "") {
        history.push("/orders");
        return null;
      }

      setDataPedido(response);
    } catch (err) {
      setLoadPage(false);
      console.log(err);
    }
  };

  const handleGetProducts = async (id_pedido) => {
    try {
      setDataProducts({ data: [], load: true, err: false });
      const response = await get_pedido_producto(id_pedido);

      setDataProducts({ data: response, load: false, err: false });
    } catch (err) {
      setDataProducts({ data: [], load: false, err: true });
      console.log(err);
    }
  };

  const handleUpdateState = (id, status) => {
    let newDataProduct = dataProducts.data;

    const newIndex = newDataProduct.findIndex((el) => el.id_plapedido === id);
    newDataProduct[newIndex].status_plapedido = status;

    setDataProducts({ ...dataProducts, data: newDataProduct });
  };

  const handleSelected = (data, e) => {
    const selected = e.target.checked
      ? [...selectedProducts, data]
      : selectedProducts.filter(
          (item) => item.id_plapedido !== data.id_plapedido
        );

    setSelectedProducts(selected);
  };

  const handleSelectedAll = () => {
    setSelectedProductsAll(!selectedProductsAll);
    setSelectedProducts(!selectedProductsAll ? dataProducts.data : []);
  };

  const fetchGet = async () => {
    await handleGetPedido(id_pedido);
    await handleGetProducts(id_pedido);
  };

  const handleCoverApply = (id, status) => {
    setIdProductSelected(id);
    setStatusCover(status);

    setOpenDialogCoverApply(true);
  };

  const onChangeStatusCoverProduct = (params) => {
    setDataProducts((item) => {
      return {
        ...item,
        data: item.data.map((item) => {
          if (item.id_plapedido === params.idProductSelected) {
            item.descuento_plapedido = params.numDiscount;
            item.tipodescuento_plapedido = params.typeProduct;
          }

          return item;
        }),
      };
    });
  };

  const total = SubTotal(dataProducts.data);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 600) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    socket.on("server:refreshProductStatus", (response) => {
      if (
        response.codigo_pedido === id_pedido &&
        response.id_empsede === getUser().empresa.id_empsede
      ) {
        setDataProducts({ data: response.data, load: false, err: false });
      }
    });

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!checkToken()) {
      history.push("/");
      return null;
    }

    fetchGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!checkToken()) {
      history.push("/");
      return null;
    }
  }, [history]);

  return (
    <Template title="Ver pedido">
      <LoadingPage open={loadPage} setOpen={setLoadPage} />

      <NavBack handleBack={handleBack} info={dataPedido} />

      {parseInt(getUser().id_perfil) === 1 && !mobile && (
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={12} sm={"auto"}>
            <Button
              variant="outlined"
              className={`acctionButtons addProduct ${
                dataPedido.id_estadopedido <= 3 ? "" : "disabled"
              }`}
              onClick={() => handleAddProducts()}
              disabled={dataPedido.id_estadopedido <= 3 ? false : true}
            >
              <Add /> Agregar productos
            </Button>
          </Grid>

          {dataPedido?.typecaja_pedido === 1 && (
            <Grid item xs={12} sm={"auto"}>
              <Button
                variant="outlined"
                className={`acctionButtons splitAccount ${
                  dataPedido.id_estadopedido <= 3 ? "" : "disabled"
                }`}
                onClick={() => handleSplitAccount()}
                disabled={dataPedido.id_estadopedido <= 3 ? false : true}
              >
                <CallSplit /> Dividir nueva cuenta
              </Button>
            </Grid>
          )}
          {/* {getUser().empresa.id_emp && (
            <Grid item xs={12} sm={"auto"}>
              <Button
                variant="outlined"
                className={`acctionButtons  ${
                  dataPedido.id_estadopedido <= 3 ? "" : "disabled"
                }`}
                onClick={() => handleCoverApply()}
                disabled={dataPedido.id_estadopedido <= 3 ? false : true}
              >
                <WineBar /> Aplicar COVER
              </Button>
            </Grid>
          )} */}
        </Grid>
      )}

      <ContainerInput
        style={{
          marginTop: 10,
          marginBottom: 20,
          padding: "15px 10px",
        }}
      >
        <DataPedido
          infoPedido={{ data: dataPedido, load: false, err: false }}
        />
      </ContainerInput>

      {parseInt(getUser().id_perfil) === 9 &&
        (dataPedido.id_estadopedido !== 4 ||
          dataPedido.id_estadopedido !== 5) && (
          <div style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleActionPrint()}
            >
              Imprimir
            </Button>
          </div>
        )}

      {/* SOLO PARA EL QUE IMPRIME SE CAMBIA LA FORMA */}
      {parseInt(getUser().id_perfil) === 9 ? (
        <ListProduct
          handleUpdateState={handleUpdateState}
          dataProducts={dataProducts}
          selectedProductsAll={selectedProductsAll}
          handleSelected={handleSelected}
          handleSelectedAll={handleSelectedAll}
          selectedProducts={selectedProducts}
          socket={socket}
        />
      ) : (
        <div
          style={{
            marginBottom: mobile ? 110 : 0,
          }}
        >
          <ListProductCuadro
            mobile={mobile}
            handleCoverApply={handleCoverApply}
            handleUpdateState={handleUpdateState}
            dataProducts={dataProducts}
            socket={socket}
          />
        </div>
      )}
      {/* </ContainerInput> */}

      <div style={{ display: "none" }}>
        <PrintAccount
          open={false}
          ref={componentRefPrint}
          dataProducts={{ data: selectedProducts }}
          dataProductsCocina={{ data: null }}
          dataProductsBartender={{ data: null }}
          dataProductsPasteleria={{ data: null }}
          dataProductsEntrada={{ data: null }}
          infoPedido={{ data: dataPedido }}
        />
      </div>

      <ContainerInput
        style={{
          padding: "15px 10px",
          position: "fixed",
          bottom: !mobile ? "15px" : "60px",
          right: "7px",
          width: "200px",
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        Total: {formatMoney(total)}
      </ContainerInput>

      {mobile && (
        <>
          <div
            style={{
              margin: "68px",
            }}
          ></div>
          <Paper
            sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
            elevation={3}
          >
            <BottomNavigation
              showLabels
              value={valueNavigation}
              onChange={(event, newValue) => {
                setValueNavigation(newValue);
              }}
            >
              <BottomNavigationAction
                label="Agregar productos"
                icon={<Add />}
                className={`acctionButtons addProductNavigation ${
                  dataPedido.id_estadopedido <= 3 ? "" : "disabled"
                }`}
                onClick={() => handleAddProducts()}
                disabled={dataPedido.id_estadopedido <= 3 ? false : true}
              />
              <BottomNavigationAction
                label="Dividir cuenta"
                icon={<CallSplit />}
                className={`acctionButtons splitAccountNavigation ${
                  dataPedido.id_estadopedido <= 3 ? "" : "disabled"
                }`}
                onClick={() => handleSplitAccount()}
                disabled={dataPedido.id_estadopedido <= 3 ? false : true}
              />
              {/**
               * only for company Verde mostaza (2)
               * */}
              {/* {getUser().empresa.id_emp  && (
                <BottomNavigationAction
                  label="Aplicar cover"
                  icon={<WineBar />}
                  onClick={() => handleCoverApply()}
                />
              )} */}
            </BottomNavigation>
          </Paper>
        </>
      )}

      <DialogSplitAccount
        infoPedido={{ data: dataPedido }}
        dataProducts={dataProducts}
        open={openApplyDiscount}
        setOpen={setOpenApplyDiscount}
        callback={handleSaveSplitAccount}
      />

      <DialogCoverApply
        infoPedido={{ data: dataPedido }}
        dataProducts={dataProducts}
        setInfoPedido={setDataPedido}
        open={openDialogCoverApply}
        setOpen={setOpenDialogCoverApply}
        idProductSelected={idProductSelected}
        statusCover={statusCover}
        onChangeStatusCoverProduct={onChangeStatusCoverProduct}
      />
    </Template>
  );
};

export default Ver;
