/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import Template from "../../components/Template";

import { checkToken, getUser } from "../../services/auth.services";
import {
  get_pedido_print,
  get_product_print,
} from "../../services/pedidos.services";

import ContainerInput from "../../components/ContainerInput";
import LoadingPage from "../../components/LoadingPage";
import TablePedido from "./TablePedidosPrint";

import PrintAccount from "./PrintAccount";

import { ProductionQuantityLimits, Search } from "@mui/icons-material";
import {
  Grid,
  IconButton,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useReactToPrint } from "react-to-print";
import InputDefault from "../../atomic/atoms/input.atoms";
import useGarzones from "../../hooks/useGarzones";
import useTables from "../../hooks/useTables";
import DialogRequestPassword from "../cookorders/Components/DialogRequestPassword";
import DialogConsumos from "./DialogConsumos";

const FONDOS = {
  cuartoParrilla: "Cuarto parrilla",
  cuartoCaliente: "Cuarto caliente",
  cuartoFrio: "Cuarto frío",
};

const Orders = (props) => {
  let history = useHistory();
  let { socket } = props;

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      id_mesa: "",
      id_usu: "",
    },
  });

  const [loadPage, setLoadPage] = useState(false);

  const { dataGarzon } = useGarzones();
  const { dataTable } = useTables();

  const [dataPedido, setDataPedido] = useState({
    load: false,
    err: false,
    data: [],
  });

  const [infoPedido, setInfoPedido] = useState({
    data: "",
    load: false,
    err: false,
  });

  const [dataProductsBartender, setDataProductsBartender] = useState({
    data: [],
    load: false,
    err: false,
  });

  const [dataProductsCocina, setDataProductsCocina] = useState({
    data: [],
    load: false,
    err: false,
  });

  const [dataProductsEntrada, setDataProductsEntrada] = useState({
    data: [],
    load: false,
    err: false,
  });

  const [dataProductsPasteleria, setDataProductsPasteleria] = useState({
    data: [],
    load: false,
    err: false,
  });

  const [openModalConsumos, setOpenModalConsumos] = useState(false);
  const [openModalRequestPassword, setOpenModalRequestPassword] =
    useState(false);
  const [typeRequestPassword, setTypeRequestPassword] = useState("consumos");

  const [openSpeedDial, setOpenSpeedDial] = useState(false);

  const componentRefPrint = useRef();

  const handleGetPedido = async () => {
    try {
      setDataPedido({ load: true, err: false, data: [] });
      const response = await get_pedido_print();
      setDataPedido({ load: false, err: false, data: response });
    } catch (err) {
      setDataPedido({ load: false, err: true, data: [] });
    }
  };

  const handleGetProductToPrint = async (codigo_pedido) => {
    try {
      return get_product_print(codigo_pedido);
    } catch (err) {}
  };

  const handlePrint = useReactToPrint({
    content: () => componentRefPrint.current,
    documentTitle: "Pedidos",
    onAfterPrint: async () => {
      console.log("imprimiendo after");
    },
  });

  const handleActionPrint = async (element) => {
    setInfoPedido({ ...infoPedido, data: element });

    const response = await handleGetProductToPrint(element.codigo_pedido);

    const { dataBartender, dataCocina, dataPasteleria, dataEntrada } = response;

    setDataProductsBartender({ load: false, err: true, data: dataBartender });
    setDataProductsCocina({ load: false, err: true, data: dataCocina });
    setDataProductsPasteleria({ load: false, err: true, data: dataPasteleria });
    setDataProductsEntrada({ load: false, err: true, data: dataEntrada });

    handlePrint();
  };

  const handleSearch = async (data) => {
    setDataPedido({ data: [], load: true, err: false });
    let newDataPedido = await get_pedido_print();

    if (data.id_mesa === "" && data.id_usu === "") {
      setDataPedido({ data: newDataPedido, load: false, err: false });
      return;
    }

    if (data.id_mesa !== "") {
      newDataPedido = newDataPedido.filter(
        (element) => element.id_mesa === parseInt(data.id_mesa)
      );
    }

    if (data.id_usu !== "") {
      newDataPedido = newDataPedido.filter(
        (element) => element.usu_id === parseInt(data.id_usu)
      );
    }

    setDataPedido({ data: newDataPedido, load: false, err: false });
  };

  const handleRequestPassword = (type) => {
    setOpenModalRequestPassword(true);

    if (type === typeRequestPassword) {
      setTypeRequestPassword("consumos");
    }
  };

  const handleCheckPassword = async (value) => {
    if (value) {
      setOpenModalConsumos(true);

      return;
    }
  };

  // refresca todos los pedidos
  useEffect(() => {
    const handlerRefreshOrders = (elements) => {
      if (elements.id_empsede === getUser().empresa.id_empsede) {
        reset();
        setDataPedido({ data: elements.data, load: false, err: false });
      }
    };

    socket.on("server:refreshOrders", handlerRefreshOrders);
    return () => {
      socket.off("server:refreshOrders", handlerRefreshOrders);
    };
  }, []);

  //CHECK TOKEN
  useEffect(() => {
    if (!checkToken()) {
      history.push("/");
      return null;
    }

    handleGetPedido();
  }, []);

  // useEffect(() => {
  //   setFilterPedido(dataPedido.data);
  // }, [dataPedido]);

  return (
    <Template title="Pedidos">
      <LoadingPage open={loadPage} setOpen={setLoadPage} />

      <form onSubmit={handleSubmit(handleSearch)} autoComplete="off">
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <Controller
              name="id_mesa"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InputDefault
                  fullWidth
                  value={value}
                  onChange={onChange}
                  type="select"
                  title={"Mesas"}
                  options={dataTable?.data || []}
                />
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <Controller
              name="id_usu"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InputDefault
                  fullWidth
                  value={value}
                  onChange={onChange}
                  type="select"
                  title={"Usuarios"}
                  options={dataGarzon?.data || []}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={1}
            style={{ textAlign: "left", display: "inline-flex" }}
          >
            <IconButton
              title="Buscar"
              type="submit"
              size="large"
              color="primary"
              style={{ background: "#eee", marginRight: "10px" }}
            >
              <Search />
            </IconButton>
          </Grid>
        </Grid>
      </form>

      <ContainerInput
        style={{
          padding: 0,
        }}
      >
        <TablePedido
          handleActionPrint={handleActionPrint}
          dataPedido={dataPedido}
        />
      </ContainerInput>

      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        sx={{ position: "absolute", bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
        onClose={() => setOpenSpeedDial(false)}
        onOpen={() => setOpenSpeedDial(true)}
        open={openSpeedDial}
        color="secondary"
      >
        <SpeedDialAction
          key={"Consumos"}
          icon={<ProductionQuantityLimits />}
          tooltipTitle={"Consumos"}
          tooltipOpen
          onClick={() => {
            setOpenSpeedDial(false);

            handleRequestPassword("consumos");
          }}
        />
      </SpeedDial>

      <DialogRequestPassword
        open={openModalRequestPassword}
        setOpen={setOpenModalRequestPassword}
        callback={handleCheckPassword}
      />

      <DialogConsumos open={openModalConsumos} setOpen={setOpenModalConsumos} />

      <div style={{ display: "none" }}>
        <PrintAccount
          open={false}
          ref={componentRefPrint}
          dataProducts={{ data: null }}
          dataProductsCocina={dataProductsCocina}
          dataProductsBartender={dataProductsBartender}
          dataProductsPasteleria={dataProductsPasteleria}
          dataProductsEntrada={dataProductsEntrada}
          dataFondosCocina={
            dataProductsCocina.data
              ? {
                  cuartoParrilla: dataProductsCocina.data.filter(
                    (element) => element.fondo_plato === FONDOS.cuartoParrilla
                  ),
                  cuartoCaliente: dataProductsCocina.data.filter(
                    (element) => element.fondo_plato === FONDOS.cuartoCaliente
                  ),
                  cuartoFrio: dataProductsCocina.data.filter(
                    (element) => element.fondo_plato === FONDOS.cuartoFrio
                  ),
                }
              : null
          }
          infoPedido={infoPedido}
        />
      </div>
    </Template>
  );
};

export default Orders;
