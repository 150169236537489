import React, { useEffect, useState } from "react";
import Template from "../../components/Template";

import ContainerInput from "../../components/ContainerInput";
import LoadingPage from "../../components/LoadingPage";

import SearchProduct from "../../atomic/organisms/SearchAutocomplete.organisms";
import TableSoldOut from "../../atomic/organisms/Table.organisms";

import { get_plato } from "../../services/plato.services";
import {
  get_agotadoproducto,
  add_agotadoproducto,
  delete_agotadoproducto,
} from "../../services/agotadoproducto.services";

const SoldOut = (props) => {
  //   const { socket } = props;

  const [loadPage, setLoadPage] = useState(false);
  const [platos, setPlatos] = useState([]);
  const [productSoldOut, setProductSoldOut] = useState([]);
  const columnTable = [
    {
      id: "id_plato",
      label: "Código del plato",
      minWidth: 130,
      align: "left",
      date: false,
    },
    {
      id: "nombre_plato",
      label: "Nombre del plato",
      minWidth: 130,
      align: "left",
      date: false,
    },
  ];

  const handleLoadPage = (value) => {
    setLoadPage(value);
  };

  const handleValueAutocomplete = async (value) => {
    try {
      handleLoadPage(true);
      const response = await add_agotadoproducto(value);
      handleLoadPage(false);

      if (response) {
        setProductSoldOut(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleGetProducts = async () => {
    try {
      const response = await get_plato();

      const formatPlato = response.map((el) => ({
        id: el.id_plato,
        label: el.nombre_plato,
      }));

      setPlatos(formatPlato);
    } catch (err) {
      console.log(err);
    }
  };

  const handleGetSoldoutProducts = async () => {
    try {
      handleLoadPage(true);
      const response = await get_agotadoproducto();
      handleLoadPage(false);
      setProductSoldOut(response);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteSoldOut = async (row) => {
    try {
      handleLoadPage(true);
      const response = await delete_agotadoproducto(row.id_proagotado);
      setProductSoldOut(response);

      handleLoadPage(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleGetSoldoutProducts();
    handleGetProducts();
  }, []);

  return (
    <Template title="Inicio">
      <LoadingPage open={loadPage} setOpen={setLoadPage} />

      <SearchProduct
        options={platos}
        label={"Buscar producto"}
        setValue={handleValueAutocomplete}
      />
      <br />
      <ContainerInput
        style={{
          padding: "5px 15px",
        }}
      >
        <TableSoldOut
          rows={productSoldOut}
          columns={columnTable}
          actionDelete={handleDeleteSoldOut}
        />
      </ContainerInput>
    </Template>
  );
};

export default SoldOut;
