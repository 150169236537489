import axios from "axios";
import { config } from "../config";
import { axiosHeader } from "../functions/axiosHeader";

const { baseurl } = config;

export const get_sucursales = () => {
  const url = `${baseurl}/api/sucursal/get_sucursales`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
}

export const get_sucursales_by_id = (id_emp) => {
  const url = `${baseurl}/api/sucursal/get_sucursales/${id_emp}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
}

export const add_sucursal = (params) => {
  const url = `${baseurl}/api/sucursal/add_sucursales`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
}

export const update_sucursal = (params, id_emp) => {
  const url = `${baseurl}/api/sucursal/update_sucursales/${id_emp}`;

  return axios
    .put(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
}

export const delete_sucursal = (id_empsede) => {
  const url = `${baseurl}/api/sucursal/delete_sucursales/${id_empsede}`;

  return axios
    .delete(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
}
