import * as React from "react";

import {
  Slide,
  TextField,
  Dialog,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Grid,
  Badge,
} from "@mui/material";

import { Search, ShoppingCart } from "@mui/icons-material";

import CloseIcon from "@mui/icons-material/Close";

import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { actionTypes } from "../../../../../redux/pedido/actions";

import Input from "../../../../../components/Forms/Input";
import LoadingPage from "../../../../../components/LoadingPage";
import CategoryProduct from "../../../../orders/Crear/Components/CategoryProduct";
import ListProduct from "../../../../orders/Crear/Components/ListProduct";
import DialogCart from "../../../../orders/Crear/Components/Cart";
import ContainerInput from "../../../../../components/ContainerInput";

import { getUser } from "../../../../../services/auth.services";
import { get_carta } from "../../../../../services/carta.services";
import {
  search_products,
  get_pedido_id,
} from "../../../../../services/pedidos.services";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref || false} {...props} />;
});

const NavSearch = (props) => {
  let { control, handleSubmit, handleSearchProduct } = props;

  const [dataCategoriaProducto, setDataCategoriaProducto] = React.useState({
    load: false,
    err: false,
    data: [],
  });

  const handleGetCategories = async () => {
    try {
      setDataCategoriaProducto({ load: true, err: false, data: [] });
      const response = await get_carta(getUser().empresa.id_empsede);
      setDataCategoriaProducto({
        load: false,
        err: false,
        data: response.map((el) => ({
          value: el.id_categoriapla,
          label: el.nombre_categoriapla,
        })),
      });
    } catch (err) {
      setDataCategoriaProducto({ load: false, err: true, data: [] });
      console.log(err);
    }
  };

  React.useEffect(() => {
    handleGetCategories();
  }, []);

  return (
    <ContainerInput className="navSearchProduct">
      <form
        onSubmit={handleSubmit(handleSearchProduct)}
        autoComplete="off"
        style={{ marginTop: 10 }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <Controller
              name="text"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Input
                  value={value}
                  onChange={onChange}
                  type="text"
                  title="Buscar por nombre"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={10} md={4} lg={4}>
            <Controller
              name="category"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  select
                  variant="outlined"
                  value={value}
                  onChange={onChange}
                  className={`InputDefault`}
                  SelectProps={{
                    native: true,
                  }}
                >
                  <option value="">[Categoría]</option>
                  {dataCategoriaProducto.data.map((option, idx) => (
                    <option key={idx} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2} md={1} lg={1} style={{ display: "flex" }}>
            <Button fullWidth variant="contained" color="warning" type="submit">
              <Search />
            </Button>
          </Grid>
        </Grid>
      </form>
    </ContainerInput>
  );
};

const DialogAddProduct = (props) => {
  const { callback, infoPedido, open, setOpen } = props;

  const { codigo_pedido, typecaja_pedido } = infoPedido.data;

  const INITIALVALUES_PEDIDO = {
    cantidad_mesa: "",
    codigo_mesa: "",
    codigo_pedido: "",
    estado_reserva: "",
    fechareg_pedido: "",
    fechareserva_reserva: "",
    id_mesa: "",
    id_pedido: "",
    nombre_estadopedido: "",
    nombre_cliente: "",
    apepaterno_cliente: "",
    apematerno_cliente: "",
  };

  const INITIALVALUES_SEARCH_PRODUCT = {
    text: "",
    category: "",
  };

  const [dataCategories, setDataCategories] = React.useState({
    load: false,
    err: false,
    data: [],
  });

  const [inputSearch, setInputSearch] = React.useState(
    INITIALVALUES_SEARCH_PRODUCT
  );

  const [loadPage, setLoadPage] = React.useState(false);
  const [dataPedido, setDataPedido] = React.useState(INITIALVALUES_PEDIDO);
  const [openCart, setOpenCart] = React.useState(false);

  const [dataProducts, setDataProducts] = React.useState({
    load: false,
    err: false,
    data: [],
    full: "",
  });

  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.reducer_pedido);

  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues: INITIALVALUES_SEARCH_PRODUCT,
  });

  /**
   * @description: REDUX
   */

  const onClearProductCart = () => {
    dispatch({
      type: actionTypes.clearProduct,
    });
  };

  /**
   * @description: FUNCTIONS
   */

  const handleDialogCart = (param) => {
    setOpenCart(param);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBackProduct = () => {
    setInputSearch(INITIALVALUES_SEARCH_PRODUCT);
    setDataProducts({ load: false, err: false, data: [], full: "" });

    setValue("category", "");
    setValue("text", "");
  };

  const handleSelectedCategory = (element) => {
    setValue("category", element);

    const jsonSearch = watch();

    handleSearchProduct(jsonSearch);
  };

  const handleGetPedido = async (id) => {
    try {
      setLoadPage(true);
      let response = await get_pedido_id(id);
      setLoadPage(false);

      setDataPedido(response);
    } catch (err) {
      setLoadPage(false);
      console.log(err);
    }
  };

  const handleGetCategories = async () => {
    try {
      setDataCategories({ load: true, err: false, data: [] });
      const response = await get_carta(getUser().empresa.id_empsede, typecaja_pedido);
      setDataCategories({
        load: false,
        err: false,
        data: response,
      });
    } catch (err) {
      setDataCategories({ load: false, err: true, data: [] });
      console.log(err);
    }
  };

  const handleClearCart = () => {
    onClearProductCart();
  };

  const handleCreatePedido = async () => {
    try {
      let jsonProduct = products.map((el) => ({
        id_plato: el.id_plato,
        amount: el.totAmount,
        texto: "",
      }));

      let json = {
        codigo_pedido,
        id_pedido: dataPedido.id_pedido,
        products: jsonProduct,
      };

      handleClose(); // cerrar dialogo

      await callback(json);
    } catch (err) {
      setLoadPage(false);
      console.log(err);
    }
  };

  /**
   * @description Funcion para buscar productos
   */

  const handleSearchProduct = async (data) => {
    if (data.category !== "") {
      let { nombre_categoriapla } = dataCategories.data.find(
        (el) => el.id_categoriapla === parseInt(data.category)
      );
      setInputSearch({ ...data, category: nombre_categoriapla });
    } else {
      setInputSearch(data);
    }

    try {
      setLoadPage(true);
      setDataProducts({ load: true, err: false, data: [], full: "" });
      const response = await search_products(data);
      setDataProducts({
        load: false,
        err: false,
        data: response,
        full: response.length > 0 ? "full" : "notfound",
      });
      setLoadPage(false);
    } catch (err) {
      setInputSearch(INITIALVALUES_SEARCH_PRODUCT);
      setDataProducts({ load: false, err: true, data: [], full: "" });
      console.log(err);
    }
  };

  /**
   * @description Variables
   */

  const fullProduct = dataProducts.full;
  const amuntCart = products.length;

  React.useEffect(() => {
    if (open) {
      handleGetPedido(codigo_pedido);
      handleGetCategories();
      handleClearCart();
      handleBackProduct();
    }
  }, [open]);

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
      >
        <AppBar sx={{ position: "sticky", top: 0 }} className="AppHeader">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleClose(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            {/* <Typography>Productos</Typography> */}
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Agregar productos al pedido {codigo_pedido}
            </Typography>
            <Button
              variant="contained"
              color="warning"
              onClick={() => handleDialogCart(true)}
            >
              <Badge badgeContent={amuntCart} color="success">
                <ShoppingCart />
              </Badge>
            </Button>
          </Toolbar>
        </AppBar>
        <div style={{ padding: 20 }}>
          <LoadingPage open={loadPage} setOpen={setLoadPage} />
          {/* <NavSearch
            control={control}
            handleSubmit={handleSubmit}
            handleSearchProduct={handleSearchProduct}
          /> */}

          <CategoryProduct
            handleSelectedCategory={handleSelectedCategory}
            data={dataCategories}
            fullProduct={fullProduct}
          />
          <ListProduct
            data={dataProducts}
            inputSearch={inputSearch}
            fullProduct={fullProduct}
            handleBackProduct={handleBackProduct}
          />
        </div>
        <DialogCart
          open={openCart}
          setOpen={setOpenCart}
          handleCreatePedido={handleCreatePedido}
        />
      </Dialog>
    </>
  );
};

export default DialogAddProduct;
