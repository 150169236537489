import { useEffect, useState } from "react";
import { get_subsidaries } from "../services/empresa.services";

const useSubsidiary = (id) => {
  const [subsidiary, setSubsidiary] = useState([]);
  const [load, setLoad] = useState(false);

  const getSubsidiary = async () => {
    try {
      setLoad(true);
      const response = await get_subsidaries(id);
      setLoad(false);
      setSubsidiary(response);
    } catch (error) {
      setLoad(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getSubsidiary();
  }, []);

  return {
    data: subsidiary,
    load,
  };
};

export default useSubsidiary;
