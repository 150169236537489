import React from "react";

import "./style.atoms.scss";

import { Typography } from "@mui/material";

const NotFoundReg = ({ title = "No se encontró registros.", ...props }) => {
  return (
    <div
      {...props}
      style={{
        textAlign: "center",
        margin: "auto",
      }}
    >
      <Typography component="h5" variant="h5" className="errMono">
        ¯\_(ツ)_/¯
      </Typography>
      <br />
      <Typography component="h6" variant="h6">
        {title}
      </Typography>
    </div>
  );
};

export default NotFoundReg;
