import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";

import {
  AccessTime,
  Check,
  Close,
  Delete,
  Description,
  Edit,
  Title,
  Schedule
} from "@mui/icons-material";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import toast from "react-hot-toast";

import { useState } from "react";
import { completedTask } from "../../../services/task.services";

dayjs.extend(utc);

const DialogTaskView = ({
  open,
  setOpen,
  task,
  onEdit,
  onReschedule,
  onDelete,
  callBack,
}) => {
  const [loading, setLoading] = useState(false);

  const isDone = task?.status_task === "done";

  const handleTaskCompleted = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        await completedTask(data.id_task, data);
        resolve("Success");
      } catch (err) {
        reject(new Error("Error"));
      }
    });
  };

  const handleComplete = () => {
    toast.promise(handleTaskCompleted(task), {
      loading: "Cargando...",
      success: () => {
        setOpen(false);
        setLoading(false);
        callBack && callBack();
        return "Se ha completado la agenda correctamente";
      },
      error: (err) => {
        setOpen(true);
        return "Ops! Ocurrió un error al completar la agenda";
      },
    });
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth={"sm"}
      aria-describedby="dialog-select-sucursal"
    >
      <DialogContent>
        <ul
          className="
          flex
          justify-end
          items-center
          gap-1
        "
        >
          <li>
            <IconButton
              disabled={isDone}
              aria-label="close"
              onClick={() => {
                onDelete(task);
              }}
            >
              <Delete />
            </IconButton>
          </li>
          <li>
            <IconButton
              // disabled={isDone}
              aria-label="close"
              onClick={() => {
                setOpen(false);
                onReschedule(task, "reschedule");
              }}
            >
              <Schedule />
            </IconButton>
          </li>
          <li>
            <IconButton
              disabled={isDone}
              aria-label="close"
              onClick={() => {
                setOpen(false);
                onEdit(task, "edit");
              }}
            >
              <Edit />
            </IconButton>
          </li>
          <li>
            <IconButton aria-label="close" onClick={() => setOpen(false)}>
              <Close />
            </IconButton>
          </li>
        </ul>
        <div className="grid grid-cols-12 gap-3 pt-1">
          <div className="col-span-12  flex items-center gap-2">
            <Title />
            <h1 className="text-xl m-0 p-0">{task?.title}</h1>
          </div>
          <div className="col-span-12 flex items-center gap-2">
            <AccessTime />
            <div>
              <p className="text-sm m-0 p-0">
                {dayjs.utc(task?.date_task).format("dddd, D [de] MMMM")}
              </p>
              <p className="text-xs text-gray-600 m-0 p-0">
                {dayjs.utc(task?.date_task).format("HH:mm")}
              </p>
            </div>
          </div>
          <div className="col-span-12 flex items-center gap-2">
            <Description />
            <p className="text-sm m-0 p-0">{task?.description_task}</p>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="border-t-2">
        {isDone && (
          <div className="flex items-center text-blue-900 rounded-md p-2  text-sm hover:text-blue-800 transition duration-300 ease-in-out w-fit">
            <Check className="text-sm" /> Completado
          </div>
        )}
        {!isDone && (
          <button
            onClick={() => handleComplete()}
            disabled={loading}
            type="button"
            className="flex items-center bg-blue-900 rounded-md p-2 text-white text-sm hover:bg-blue-800 transition duration-300 ease-in-out w-fit"
          >
            {/* <Check /> Completada */}
            Completada
          </button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DialogTaskView;
