import axios from "axios";
import { config } from "../config";
import { axiosHeader } from "../functions/axiosHeader";

const { baseurl } = config;

export const logOut = () => {
  localStorage.removeItem("tokenAccess_vm");
  localStorage.removeItem("user_vm");
  return true;
};

export const recoverPassword = async (data) => {
  try {
    return await axios.post(`${baseurl}/api/auth/recover/`, data);
  } catch {
    return false;
  }
};

export const changePassword = async (data) => {
  try {
    return await axios.post(`${baseurl}/api/auth/update-password/`, data);
  } catch {
    return false;
  }
};

export const resetPassword = async (data) => {
  try {
    return await axios.post(`${baseurl}/api/auth/reset/`, data);
  } catch {
    return false;
  }
};

export const login = async (data) => {
  try {
    const response = await axios.post(`${baseurl}/api/auth/login/`, {
      username: data.username,
      password: data.password,
    });

    if (response.data.status === 200) {
      const { token, user } = response.data.body;
      return { token, user };
    } else {
      return false;
    }
  } catch {
    return false;
  }
};

export const getUser = () => {
  try {
    const response = JSON.parse(localStorage.getItem("user_vm"));

    return response;
  } catch {
    console.log("Error");
  }
};

export const getUserCurrently = async () => {
  try {
    const response = await axios.get(
      `${baseurl}/api/auth/getuser/${getUser().username}`
    );

    return response.data.body;
  } catch (err) {
    return false;
  }
};

export const getToken = () => {
  return localStorage.getItem("tokenAccess_vm");
};

export const setUser = (token) => {
  localStorage.setItem("user_vm", JSON.stringify(token));
};

export const checkToken = async () => {
  try {
    await axios.post(`${baseurl}/api/auth/checktoken/`, {
      token: `${localStorage.getItem("tokenAccess_vm")}`,
    });

    return true;
  } catch (err) {
    logOut();

    return false;
  }
};

export const updateToken = async (token, id) => {
  try {
    await axios.put(`${baseurl}/api/auth/updateToken/${id}`, {
      token,
    });

    return true;
  } catch (err) {
    logOut();

    return false;
  }
};

export const updateActive = async (data) => {
  const url = `${baseurl}/api/user/update_active/${data.usu_id}`;

  return axios
    .put(url, data, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const updateActiveToday = async (data) => {
  const url = `${baseurl}/api/user/update_active_today/${data.usu_id}`;

  return axios
    .put(url, data, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};
