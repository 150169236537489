import * as React from "react";

import {
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { Save } from "@mui/icons-material";

import "moment/locale/es";
import Moment from "react-moment";

// import moment from "moment";

import NotFound from "../../../../atomic/organisms/NotFound.organisms";
import { formatMoney, signalMoney } from "../../../../functions/formatMoney";
import { getUser } from "../../../../services/auth.services";
import { update_plato_product } from "../../../../services/pedidos.services";

const ftotalPlato = (typeDiscountPlato, subTotalPlato, discountPlato) => {
  let _totalPlato = 0;

  if (typeDiscountPlato === "PORCENTAJE") {
    _totalPlato = subTotalPlato - subTotalPlato * (discountPlato / 100);
  } else if (typeDiscountPlato === "PRECIO") {
    _totalPlato = subTotalPlato - discountPlato;
  } else {
    _totalPlato = subTotalPlato;
  }

  return _totalPlato;
};

const ItemTable = (props) => {
  let { estadoPedido, row, setRow, idx, clickAction, isSelected } = props;

  // const timeAgo = moment(row.fechareg_plapedido).fromNow();
  const isItemSelected = isSelected(row.id_plapedido);

  const typeDiscountPlato = row.tipodescuento_plapedido
    ? row.tipodescuento_plapedido
    : "";

  const subTotalPlato = row.precio_plato * row.amount_plapedido;
  const discountPlato = row.descuento_plapedido ? row.descuento_plapedido : "";

  const _totalPlato = ftotalPlato(
    typeDiscountPlato,
    subTotalPlato,
    discountPlato
  );

  const [totalPlato, setTotalPlato] = React.useState(_totalPlato);

  const [_discount, _setDiscount] = React.useState(discountPlato);

  const disabledSave = discountPlato === "" && typeDiscountPlato === "";

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRow(idx, name, value);
  };

  const handleChangeDiscount = (e) => {
    const { value } = e.target;
    _setDiscount(value);
  };

  const handleSave = async () => {
    const _totalPlato = ftotalPlato(
      typeDiscountPlato,
      subTotalPlato,
      _discount
    );

    setTotalPlato(_totalPlato);

    const json = {
      id_plapedido: row.id_plapedido,
      discount: _discount,
      tipyDiscount: typeDiscountPlato,
    };

    handleChange({
      target: {
        name: "descuento_plapedido",
        value: _discount,
      },
    });

    await update_plato_product(json);
  };

  React.useEffect(() => {
    if (typeDiscountPlato === "") {
      handleChange({
        target: {
          name: "descuento_plapedido",
          value: "",
        },
      });

      handleSave();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeDiscountPlato]);

  return (
    <React.Fragment>
      <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
        <TableCell align={"left"}>
          <Checkbox
            color="primary"
            checked={isItemSelected}
            inputProps={{
              "aria-labelledby": idx,
            }}
            onClick={clickAction}
            disabled={estadoPedido <= 3 ? false : true}
          />
        </TableCell>
        <TableCell align={"left"}>{row.nombre_plato}</TableCell>
        {/* <TableCell align={"left"}>
          <div
            className={`_estado ${
              row.status_plapedido === "TERMINADO"
                ? "__terminado"
                : row.status_plapedido === "EN PROCESO"
                ? "__proceso"
                : "__ingreso"
            } `}
          >
            {row.status_plapedido}
          </div>
        </TableCell> */}
        {/* <TableCell align={"left"}>{row.nombre_area}</TableCell> */}
        <TableCell align={"left"}>
          <Moment format="HH:mm">{row.fechareg_plapedido}</Moment>
        </TableCell>
        <TableCell align={"left"}>x{row.amount_plapedido}</TableCell>
        <TableCell align={"left"}>{formatMoney(row.precio_plato)}</TableCell>
        <TableCell align={"left"}>{formatMoney(subTotalPlato)}</TableCell>
        <TableCell>
          <div style={{ display: "flex" }}>
            <select
              name="tipodescuento_plapedido"
              onChange={handleChange}
              value={typeDiscountPlato}
              style={{ width: "60px" }}
            >
              <option value="">[Tipo]</option>
              <option value="PORCENTAJE">%</option>
              <option value="PRECIO">{signalMoney()}</option>
            </select>
            <input
              disabled={typeDiscountPlato === "" ? true : false}
              name="descuento_plapedido"
              onChange={handleChangeDiscount}
              type="text"
              value={_discount}
              style={{ width: "60px" }}
            />
            <button disabled={disabledSave} onClick={() => handleSave()}>
              <Save />
            </button>
          </div>
        </TableCell>
        <TableCell align={"left"}>{formatMoney(totalPlato)}</TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const SeeProduct = (props) => {
  let {
    infoPedido,
    dataProducts,
    setDataProducts,
    selectedDelete,
    handleSelectedDelete,
  } = props;

  const rows = dataProducts.data;
  const loading = dataProducts.load;

  const { data } = infoPedido;

  const estadoPedido = data.id_estadopedido;

  if (loading) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress color="inherit" /> <br />
        <Typography component="h5" variant="h5" style={{ textAlign: "center" }}>
          Cargando...
        </Typography>
      </div>
    );
  }

  if (rows.length === 0) {
    return <NotFound texto="No se encontró registros." />;
  }

  const handleClick = (event, value) => {
    const selectedIndex = selectedDelete.indexOf(value);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedDelete, value);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedDelete.slice(1));
    } else if (selectedIndex === selectedDelete.length - 1) {
      newSelected = newSelected.concat(selectedDelete.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedDelete.slice(0, selectedIndex),
        selectedDelete.slice(selectedIndex + 1)
      );
    }

    handleSelectedDelete(newSelected);
  };

  const handleSetDiscount = async (idx, name, value) => {
    try {
      const newDataProduct = [...dataProducts.data];
      newDataProduct[idx] = { ...newDataProduct[idx], [name]: value };

      setDataProducts(newDataProduct);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table" size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 20 }}></TableCell>
              <TableCell
                align={"left"}
                style={{ minWidth: 170, fontWeight: "bold" }}
              >
                Nombre
              </TableCell>
              {/* <TableCell
                align={"left"}
                style={{ minWidth: 80, fontWeight: "bold" }}
              >
                Estado
              </TableCell> */}
              {/* <TableCell
                align={"left"}
                style={{ minWidth: 147, fontWeight: "bold" }}
              >
                Área
              </TableCell> */}
              <TableCell
                align={"left"}
                style={{ minWidth: 80, fontWeight: "bold" }}
              >
                Hora
              </TableCell>
              <TableCell
                align={"left"}
                style={{ minWidth: 100, fontWeight: "bold" }}
              >
                Cantidad
              </TableCell>
              <TableCell
                align={"left"}
                style={{ minWidth: 110, fontWeight: "bold" }}
              >
                Precio
              </TableCell>
              <TableCell
                align={"left"}
                style={{ minWidth: 110, fontWeight: "bold" }}
              >
                Subtotal
              </TableCell>
              <TableCell
                align={"left"}
                style={{ minWidth: 110, fontWeight: "bold" }}
              >
                Descuento
              </TableCell>
              <TableCell
                align={"left"}
                style={{ minWidth: 110, fontWeight: "bold" }}
              >
                Total
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, idx) => {
              const isSelected = (value) =>
                selectedDelete.indexOf(value) !== -1;

              return (
                <ItemTable
                  idx={idx}
                  key={idx}
                  row={row}
                  setRow={handleSetDiscount}
                  clickAction={(event) => handleClick(event, row.id_plapedido)}
                  isSelected={isSelected}
                  estadoPedido={estadoPedido}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default SeeProduct;
