import React from "react";

import { Restaurant } from "@mui/icons-material";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { nameCamarero } from "../../../../functions/nameCamarero";
import "../../../orders/Ver/style.ver.scss";

const columns = [
  {
    id: "codigo_mesa",
    label: "Mesa",
    minWidth: 60,
    align: "left",
    price: false,
    joinName: false,
    date: false,
  },
  {
    id: "codigo_pedido",
    label: "Código",
    minWidth: 130,
    align: "left",
    price: false,
    joinName: false,
    date: false,
  },
  {
    id: "usu_nombre",
    label: nameCamarero(),
    minWidth: 60,
    align: "left",
    price: false,
    joinName: false,
    date: false,
    view: true,
  },
  {
    id: "nombre_estadopedido",
    label: "Estado",
    minWidth: 90,
    align: "left",
    price: false,
    joinName: false,
    date: false,
  },
];

const ItemAcordion = (props) => {
  const { data, pedidoSelected, handleSelectedOrder } = props;

  return (
    <TableContainer>
      <Table stickyHeader aria-label="sticky table" size="small">
        <TableHead>
          <TableRow>
            {columns.map((column, idx) => {
              return (
                <TableCell
                  key={idx}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map((row, idx) => {
            return (
              <TableRow
                hover
                tabIndex={-1}
                key={idx}
                className={`rowOrder ${
                  row.codigo_pedido === pedidoSelected && "selectedOrder"
                } `}
                onClick={() => handleSelectedOrder(row)}
              >
                {columns.map((column, idx) => {
                  const value = row[column.id];

                  if (column.id === "codigo_mesa") {
                    return (
                      <TableCell key={idx} align={column.align}>
                        {!value ? "Directo" : `#${value}`}
                      </TableCell>
                    );
                  }

                  if (column.id === "codigo_pedido" && !row.id_reserva) {
                    return (
                      <TableCell key={idx} align={column.align}>
                        {value}
                      </TableCell>
                    );
                  }

                  if (column.id === "codigo_pedido" && row.id_reserva) {
                    return (
                      <TableCell key={idx} align={column.align}>
                        <b>RESERVA {row.id_reserva} </b> <br/>
                        <label>{row.nombre_reserva} {row.apellido_paterno_reserva} {row.apellido_materno_reserva}</label>
                      </TableCell>
                    );
                  }

                  if (column.id === "nombre_estadopedido") {
                    let classStatus;

                    if (value === "Terminado") {
                      classStatus = "__terminado";
                    } else if (value === "En proceso") {
                      classStatus = "__proceso";
                    } else if (value === "Pre cuenta") {
                      classStatus = "__precuenta";
                    } else {
                      classStatus = "__ingreso";
                    }

                    if (value === "Ingresado" || value === "En proceso") {
                      return (
                        <TableCell key={idx} align={column.align}>
                          <div className={`_estado __terminado`}>Terminado</div>
                        </TableCell>
                      );
                    }

                    return (
                      <TableCell key={idx} align={column.align}>
                        <div className={`_estado ${classStatus} `}>{value}</div>
                      </TableCell>
                    );
                  }

                  return (
                    <TableCell key={idx} align={column.align}>
                      {column.format && typeof value === "number"
                        ? column.format(value)
                        : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ListPedido = (props) => {
  const {
    loadListPedidos,
    newDataPedido,
    pedidoSelected,
    handleSelectedOrder,
  } = props;

  if (loadListPedidos) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress color="inherit" /> <br />
        <Typography component="h5" variant="h5" style={{ textAlign: "center" }}>
          Cargando...
        </Typography>
      </div>
    );
  }

  if (newDataPedido.length === 0 && !loadListPedidos) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <Restaurant style={{ fontSize: 38 }} />
        <br />
        <Typography
          component="label"
          variant="label"
          style={{ textAlign: "center" }}
        >
          Aún no hay pedidos.
        </Typography>{" "}
      </div>
    );
  }

  return (
    <ItemAcordion
      pedidoSelected={pedidoSelected}
      handleSelectedOrder={handleSelectedOrder}
      data={newDataPedido}
    />
  );
};

export default ListPedido;
