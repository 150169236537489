import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";

import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import { actionTypes } from "../../redux/historialCliente/actions/index";

import { get_historialCliente_id } from "../../services/historialCliente.services";

import "moment/locale/es";
import Moment from "react-moment";

import { formatMoney } from "../../functions/formatMoney";
import { formatPhoneNumber } from "../../functions/formatPhoneNumber";

const toUpperCaseFilter = (d) => {
  if (!d) {
    return d;
  }

  if (d === "") {
    return d;
  }

  return d.toUpperCase();
};

const DialogViewData = () => {
  const dispatch = useDispatch();

  const { clientID, openModalView } = useSelector(
    (state) => state.reducer_historialCLiente
  );

  const [dataClient, setDataClient] = React.useState([]);

  const onClose = () => {
    dispatch({ type: actionTypes.openModalView, payload: false });
    dispatch({ type: actionTypes.clientSelected, payload: "" });
  };

  React.useEffect(() => {
    if (clientID !== "") {
      const obtainDataClient = async () => {
        try {
          const response = await get_historialCliente_id(clientID);
          setDataClient(response);
        } catch (error) {
          console.log(error);
        }
      };

      obtainDataClient();
    }

    return () => null;
  }, [clientID]);

  return (
    <>
      <Dialog
        open={openModalView}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"lg"}
      >
        <DialogTitle id="alert-dialog-title">Historial</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Table
              aria-label="simple table"
              size="small"
              style={{ border: "1px #eee solid" }}
            >
              <TableHead>
                <TableRow style={{ background: "#eee" }}>
                  <TableCell>Código</TableCell>
                  <TableCell>Nombres</TableCell>
                  <TableCell>Correo</TableCell>
                  <TableCell>Teléfono</TableCell>
                  <TableCell>Fecha reserva</TableCell>
                  <TableCell>Estado</TableCell>
                  <TableCell>Región/comuna</TableCell>
                  <TableCell>Monto</TableCell>
                  <TableCell>PAX</TableCell>
                  <TableCell>Motivo</TableCell>
                  <TableCell>Programa</TableCell>
                  <TableCell>Nombre cumpleañero</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataClient.map((row, idx) => (
                  <TableRow
                    key={idx}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      #{row.id_reserva}
                    </TableCell>
                    <TableCell>
                      {row.nombre_reserva} {row.apellido_paterno_reserva}{" "}
                      {row.apellido_materno_reserva}
                    </TableCell>
                    <TableCell>{row.correo_reserva}</TableCell>
                    <TableCell>{formatPhoneNumber(row.telefono_reserva)}</TableCell>
                    <TableCell>
                      <Moment filter={toUpperCaseFilter} format="DD/MMM/YYYY">
                        {row.fechareserva_reserva}
                      </Moment>
                    </TableCell>
                    <TableCell>
                      {row.estado_reserva === "cancelado" ? (
                        <Chip
                          label={row.estado_reserva.toUpperCase()}
                          color="error"
                        />
                      ) : row.estado_reserva === "confirmado" ||
                        row.estado_reserva === "re confirmado" ? (
                        <Chip
                          label={row.estado_reserva.toUpperCase()}
                          color="success"
                        />
                      ) : row.estado_reserva === "en espera" ? (
                        <Chip
                          label={row.estado_reserva.toUpperCase()}
                          color="info"
                        />
                      ) : (
                        <Chip
                          label={row.estado_reserva.toUpperCase()}
                          color="warning"
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {row.region} {row.comunas}
                    </TableCell>
                    <TableCell>{formatMoney(row.monto_reserva || 0)}</TableCell>
                    <TableCell>{row.pax_reserva}</TableCell>
                    <TableCell>{row.motivo_reserva}</TableCell>
                    <TableCell>
                      {toUpperCaseFilter(row.pograma_reserva)}
                    </TableCell>
                    <TableCell>{row.nombrecumpleanera_reserva}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="text" color="inherit">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogViewData;
