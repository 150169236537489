import { Call, Room, WhatsApp } from "@mui/icons-material";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Grid,
    Typography,
} from "@mui/material";
import LoadingPage from "../../components/LoadingPage";
import { getUrlFront } from "../../functions/utilidades.function";

const ListSucursales = (__xData) => {
  const { data, load } = __xData.data;

  function removeSpacesAndLowerCase(text) {
    // Remove spaces
    const textWithoutSpaces = text.replace(/\s/g, "");

    // Convert to lowercase
    return textWithoutSpaces.toLowerCase();
  }

  const handleRedirect = async (link, type, item) => {
    // try {
    //   const data = {
    //     id_emp: item?.id_emp,
    //     id_empsede: item?.id_empsede,
    //     type,
    //   };

    //   await save_counter(data);
    // } catch (err) {}

    // redirect to link with target _blank

    window.open(link, "_blank");
  };

  if (load) {
    return <LoadingPage open={true} />;
  }

  return (
    <ul
      style={{
        listStyle: "none",
        margin: 0,
        padding: 0,
      }}
    >
      {data.map((item, index) => {
        const listButtons = [
          {
            title: "LLAMAR",
            icon: <Call />,
            link: `tel:${item?.telf_empsede}`,
          },
          {
            title: "WHATSAPP",
            icon: <WhatsApp />,
            link: item?.wsp_empsede,
          },
          {
            title: "UBICACIÓN",
            icon: <Room />,
            link: item?.ubication_empsede,
          },
        ];

        const linkReserva = `${getUrlFront()}/reservar/${removeSpacesAndLowerCase(
          item?.nom_emp
        )}/${item?.alias_empsede}`;

        const linkCarta = `${getUrlFront()}/carta/${
          item?.alias_empsede
        }?user=free`;

        return (
          <li
            key={index}
            style={{
              marginBottom: "10px",
            }}
          >
            <Card variant="outlined">
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {item?.nom_empsede}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {item?.comuna_empsede} - {item?.direction_empsede} <br />
                </Typography>

                <br />
                <Grid container spacing={2}>
                  {listButtons.map((xitem, idx2) => (
                    <Grid item xs={12} sm={4} md={4} key={idx2}>
                      <Button
                        size="small"
                        color="info"
                        fullWidth
                        variant="outlined"
                        onClick={() => {
                          handleRedirect(xitem.link, "link");
                        }}
                      >
                        {xitem.icon} {xitem.title}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
              <CardActions style={{ textAlign: "center" }}>
                <Button
                  size="small"
                  className="button-default"
                  style={{ margin: "auto" }}
                  onClick={() => {
                    handleRedirect(linkReserva, "RESERVAS", item);
                  }}
                >
                  Reservar
                </Button>
                <Button
                  size="small"
                  className="button-default"
                  style={{ margin: "auto" }}
                  onClick={() => {
                    handleRedirect(linkCarta, "CARTA", item);
                  }}
                >
                  Ver la carta
                </Button>
              </CardActions>
            </Card>
          </li>
        );
      })}
    </ul>
  );
};

export default ListSucursales;
