import axios from "axios";
import { config } from "../config";

import { axiosHeader } from "../functions/axiosHeader";

const { baseurl } = config;

export const get_historialCliente = () => {
  const url = `${baseurl}/api/historialCliente/get/`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_historialCliente_id = (id) => {
  const url = `${baseurl}/api/historialCliente/get/${id}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getHistoryClient = () => {
  const url = `${baseurl}/api/historialCliente/v2/get/`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getHistoryClientById = (id) => {
  const url = `${baseurl}/api/historialCliente/v2/get/${id}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const searchHistoryClient = (params) => {
  const url = `${baseurl}/api/historialCliente/v2/search/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};
