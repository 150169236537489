import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getUser } from "../services/auth.services";

const PrivateRoute = ({ component: Component, socket = null, ...rest }) => {
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) =>
        getUser() ? (
          <Component socket={socket} {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default PrivateRoute;
