/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";

import { Controller, useForm } from "react-hook-form";

const initalValues = {
  idQuotation: null,
  fileContractQuotation: null,
};

const ContractFormUploadContractFile = ({ setDataFileContract }) => {
  const { control, reset, watch } = useForm({
    defaultValues: initalValues,
  });

  useEffect(() => {
    setDataFileContract(watch().fileContractQuotation);
  }, [watch()]);

  return (
    <form autoComplete="off">
      <div className="mb-4 w-full grid grid-cols-12 gap-2">
        <div className="col-span-12">
          <p className="text-sm font-semibold">Adjuntar contrato</p>
        </div>
        <div className="col-span-12">
          <Controller
            name="fileContractQuotation"
            control={control}
            render={({ field }) => (
              <input
                type="file"
                onChange={(e) => field.onChange(e.target.files)}
              />
            )}
          />
        </div>
        {/* <input type="submit" hidden /> */}
      </div>
    </form>
  );
};

export default ContractFormUploadContractFile;
