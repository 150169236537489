import * as React from "react";

import { Room } from "@mui/icons-material";
import {
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import LoadingPage from "../../../../components/LoadingPage";

import { change_status_product } from "../../../../services/pedidos.services";

import { getUser } from "../../../../services/auth.services";

import toast from "react-hot-toast";

import "moment/locale/es";
import Moment from "react-moment";
import NotFound from "../../../../atomic/organisms/NotFound.organisms";
import { formatMoney } from "../../../../functions/formatMoney";

// import moment from "moment";

function SubTotal(data) {
  let total = 0;
  for (let i = 0; i < data.length; i++) {
    total += data[i].precio_plato * data[i].amount_plapedido;
  }

  return total;
}

const ItemTable = (props) => {
  const { selectedProducts, handleSelected, handleAction, row, idx } = props;

  return (
    <React.Fragment>
      <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
        {parseInt(getUser().id_perfil) === 9 && (
          <TableCell align={"left"}>
            <Checkbox
              checked={selectedProducts.some(
                (el) => el.id_plapedido === row.id_plapedido
              )}
              onChange={(e) => handleSelected(row, e)}
            />
          </TableCell>
        )}
        <TableCell align={"left"}>{row.nombre_plato}</TableCell>
        {/* <TableCell align={"left"}>
          <div
            className={`_estado ${
              row.status_plapedido === "TERMINADO"
                ? "__terminado"
                : row.status_plapedido === "EN PROCESO"
                ? "__proceso"
                : "__ingreso"
            } `}
          >
            {row.status_plapedido}
          </div>
        </TableCell> */}
        <TableCell align={"left"}>
          <Moment format="HH:mm">{row.fechareg_plapedido}</Moment>
          {/* ({timeAgo}) */}
        </TableCell>
        <TableCell align={"left"}>x{row.amount_plapedido}</TableCell>
        <TableCell align={"left"}>{formatMoney(row.precio_plato)}</TableCell>
        <TableCell align={"left"}>
          {formatMoney(row.precio_plato * row.amount_plapedido)}
        </TableCell>
        {parseInt(getUser().id_perfil) === 1 && (
          <TableCell align={"left"}>
            {row.status_plapedido === "ENTREGADO" ||
            row.status_plapedido === "IMPRESO" ? (
              <IconButton disabled title="Llego el producto!">
                <Room />
              </IconButton>
            ) : (
              <IconButton
                style={{ background: "green", color: "white" }}
                title="Llego el producto!"
                onClick={() => handleAction(row.id_plapedido)}
              >
                <Room />
              </IconButton>
            )}
          </TableCell>
        )}
      </TableRow>
    </React.Fragment>
  );
};

const FormTable = (props) => {
  let {
    selectedProductsAll,
    handleSelectedAll,
    rows,
    handleUpdateStatus,
    selectedProducts,
    handleSelected,
  } = props;
  return (
    <TableContainer>
      <Table stickyHeader aria-label="sticky table" size={"small"}>
        <TableHead>
          <TableRow>
            {parseInt(getUser().id_perfil) === 9 && (
              <TableCell align={"left"}>
                <Checkbox
                  checked={selectedProductsAll}
                  onChange={() => handleSelectedAll()}
                />
              </TableCell>
            )}
            <TableCell
              align={"left"}
              style={{ minWidth: 170, fontWeight: "bold" }}
            >
              Nombre
            </TableCell>
            {/* <TableCell
         align={"left"}
         style={{ minWidth: 80, fontWeight: "bold" }}
       >
         Estado
       </TableCell> */}
            <TableCell
              align={"left"}
              style={{ minWidth: 190, fontWeight: "bold" }}
            >
              Hora
            </TableCell>
            <TableCell
              align={"left"}
              style={{ minWidth: 100, fontWeight: "bold" }}
            >
              Cantidad
            </TableCell>
            <TableCell
              align={"left"}
              style={{ minWidth: 110, fontWeight: "bold" }}
            >
              Precio
            </TableCell>
            <TableCell
              align={"left"}
              style={{ minWidth: 110, fontWeight: "bold" }}
            >
              Total
            </TableCell>
            {parseInt(getUser().id_perfil) === 1 && (
              <TableCell
                align={"left"}
                style={{ fontWeight: "bold" }}
              ></TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, idx) => (
            <ItemTable
              idx={idx}
              key={idx}
              row={row}
              handleAction={handleUpdateStatus}
              selectedProducts={selectedProducts}
              handleSelected={handleSelected}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ListProduct = (props) => {
  const {
    selectedProducts,
    selectedProductsAll,
    handleSelected,
    handleSelectedAll,
    handleUpdateState,
    dataProducts,
  } = props;

  const rows = dataProducts.data;
  const loading = dataProducts.load;
  const [loadPage, setLoadPage] = React.useState(false);

  if (loading) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress color="inherit" /> <br />
        <Typography component="h5" variant="h5" style={{ textAlign: "center" }}>
          Cargando...
        </Typography>
      </div>
    );
  }

  if (rows.length === 0) {
    return <NotFound texto="No se encontró registros." />;
  }

  const total = SubTotal(rows);

  const handleUpdateStatus = async (id) => {
    try {
      const json = {
        id_plapedido: id,
        status_plapedido: "ENTREGADO",
      };

      setLoadPage(true);
      await change_status_product(json);
      handleUpdateState(id, "ENTREGADO");
      toast.success("Se actualizó correctamente.");
      setLoadPage(false);
    } catch (err) {
      toast.error("Hubo problemas al actualizar.");
      console.log(err);
    }
  };

  return (
    <>
      <LoadingPage open={loadPage} setOpen={setLoadPage} />
      <FormTable
        handleUpdateStatus={handleUpdateStatus}
        selectedProductsAll={selectedProductsAll}
        handleSelectedAll={handleSelectedAll}
        rows={rows}
        selectedProducts={selectedProducts}
        handleSelected={handleSelected}
      />
      <br />
      <Grid container spacing={1}>
        <Grid item xs={9}></Grid>
        <Grid item xs={1}>
          <b>Total:</b>
        </Grid>
        <Grid item xs={2} style={{ textAlign: "right" }}>
          {formatMoney(total)}
        </Grid>
      </Grid>
    </>
  );
};

export default ListProduct;
