import * as React from "react";

import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

import NotFound from "../../../../atomic/organisms/NotFound.organisms";
import { formatMoney, tipMoney } from "../../../../functions/formatMoney";
import { signalMoney } from "../../../../functions/signalMoney";

function SubTotal(data) {
  let total = 0;

  for (let i = 0; i < data.length; i++) {
    const subTotalPlato = data[i].precio_plato * data[i].amount_plapedido;
    const typeDiscountPlato = data[i].tipodescuento_plapedido || "";
    const discountPlato = parseFloat(data[i].descuento_plapedido) || 0;

    if (typeDiscountPlato === "PORCENTAJE") {
      total += subTotalPlato - subTotalPlato * (discountPlato / 100);
    } else if (typeDiscountPlato === "PRECIO") {
      total += subTotalPlato - discountPlato;
    } else if (typeDiscountPlato === "COVER") {
      const result = subTotalPlato - discountPlato;
      total += result < 0 ? 0 : result;
    } else {
      total += subTotalPlato;
    }
  }

  return total;
}

function ExtraDiscount(subTotal, data) {
  if (data.id_reserva || data.id_reserva > 0) {
    const discount = data.monto_reserva || 0;

    return {
      textAmountExtraDiscount: `ABONO RESERVA`,
      amountExtraTotal: discount,
    };
  }

  const amountExtraDiscount = 0;
  const textAmountExtraDiscount = `PROPINA ${amountExtraDiscount}%`;
  const amountExtraTotal = subTotal * (amountExtraDiscount / 100);

  return {
    textAmountExtraDiscount,
    amountExtraTotal,
  };
}

const TotCount = (props) => {
  const { dataProducts, infoPedido, handleUpdateLiberados } = props;

  const { data } = infoPedido;

  const rows = dataProducts.data;
  const loading = dataProducts.load;

  const onUpdateLiberados = (e) => {
    const amount = e.target.value;
    const price = Number(infoPedido?.data?.asistentvalue_reserva) * amount;
    handleUpdateLiberados(amount, price);
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress color="inherit" /> <br />
        <Typography component="h5" variant="h5" style={{ textAlign: "center" }}>
          Cargando...
        </Typography>
      </div>
    );
  }

  if (rows.length === 0) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <NotFound title="No se puedo calcular el total." />
      </div>
    );
  }

  const isAbooking = data?.id_reserva && data?.id_reserva > 0;

  const subTotal = SubTotal(rows);

  const discount = data.descuento_pedido;
  const typeDiscount = data.tipodescuento_pedido;
  const { textAmountExtraDiscount, amountExtraTotal } = ExtraDiscount(
    subTotal,
    data
  );

  let discountTotal = 0;

  if (typeDiscount === "PORCENTAJE") {
    const percent = subTotal * (discount / 100);
    discountTotal = discount ? subTotal - percent : 0;
  } else if (typeDiscount === "PRECIO") {
    discountTotal = discount ? subTotal - discount : 0;
  } else if (typeDiscount === "COVER") {
    const result = subTotal - discount;
    discountTotal = result < 0 ? 0 : result;
  } else {
    discountTotal = discount ? subTotal - discount : 0;
  }

  const subTotalAmountExtra = !isAbooking
    ? subTotal + amountExtraTotal
    : subTotal - amountExtraTotal;

  const subTotalDiscountExtra = !isAbooking
    ? discountTotal + amountExtraTotal
    : discountTotal - amountExtraTotal;

  const subTotalLiberados = !isAbooking ? 0 : data.priceliberados_pedido;

  const total =
    discountTotal === 0
      ? subTotalAmountExtra - discountTotal - subTotalLiberados
      : subTotalDiscountExtra - subTotalLiberados;

  const newTotal = total < 0 ? 0 : total;

  return (
    <>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table" size={"small"}>
          <TableBody>
            <TableRow hover role="checkbox" tabIndex={-1}>
              <TableCell align={"left"}>SUBTOTAL</TableCell>
              <TableCell align={"left"}>{formatMoney(subTotal)}</TableCell>
            </TableRow>
            <TableRow hover role="checkbox" tabIndex={-1}>
              <TableCell align={"left"}>
                DESCUENTO {signalMoney(typeDiscount, discount)}
              </TableCell>
              <TableCell align={"left"}>{formatMoney(discount)}</TableCell>
            </TableRow>
            {/* <TableRow hover role="checkbox" tabIndex={-1}>
              <TableCell align={"left"}>{textAmountExtraDiscount}</TableCell>
              <TableCell align={"left"}>
                {formatMoney(amountExtraTotal)}
              </TableCell>
            </TableRow> */}
            {isAbooking && (
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableCell align={"left"}>
                  LIBERADOS{" "}
                  <select
                    value={data.amountliberados_pedido}
                    onChange={onUpdateLiberados}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                    <option value={8}>8</option>
                    <option value={9}>9</option>
                    <option value={10}>10</option>
                  </select>
                </TableCell>
                <TableCell align={"left"}>
                  {formatMoney(data.priceliberados_pedido)}
                </TableCell>
              </TableRow>
            )}
            <TableRow hover role="checkbox" tabIndex={-1}>
              <TableCell align={"left"}>
                <b>TOTAL</b>
              </TableCell>
              <TableCell align={"left"}>
                <b>{formatMoney(newTotal)}</b>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TotCount;
