import {
  Document,
  Font,
  Image,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";

import watermarkImage from "../../assets/images/mark_dh.png";
import signatureImage from "../../assets/images/sign.png";

import RobotoBold from "../../assets/fonts/Roboto/Roboto-Bold.ttf";
import RobotoItalic from "../../assets/fonts/Roboto/Roboto-Italic.ttf";
import Roboto from "../../assets/fonts/Roboto/Roboto-Regular.ttf";

Font.register({
  family: "Roboto",
  fonts: [
    { src: Roboto },
    { src: RobotoBold, fontStyle: "bold", fontWeight: 600 },
    { src: RobotoItalic, fontStyle: "italic" },
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: "70px 80px",
    backgroundColor: "#fff",
    flexDirection: "row",
  },
  section: {
    margin: 0,
    padding: 0,
    flexGrow: 1,
  },
  watermark: {
    position: "absolute",
    zIndex: -1,
    width: "100%",
    height: "100%",
  },
  watermarkContainer: {
    position: "absolute",
    zIndex: -1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  text: {
    fontSize: 10,
    color: "#000 !important",
    textAlign: "justify",
    marginBottom: 18,
    lineHeight: 1.8,
    fontFamily: "Roboto",
    fontStyle: "normal",
    width: "auto",
  },
  textBold: {
    fontFamily: "Roboto",
    fontStyle: "bold",
    fontSize: 10,
    width: "auto",
    height: "auto",
    marginBottom: 5,
  },
  textTitle: {
    fontFamily: "Roboto",
    fontStyle: "bold",
    fontSize: 12,
    margin: "auto",
  },
  viewTextParagraph: {},
  table: {
    display: "table",
    width: "100%",
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    gap: 10,
  },
  tableCell: {
    margin: "auto",
    width: "50%",
    marginTop: 30,
    fontSize: 10,
    borderTopWidth: 1,
    borderStyle: "solid",
    borderColor: "#000",
    textAlign: "center",
    padding: 8,
    position: "relative",
  },
  tableCellSign: {
    margin: "auto",
    width: "50%",
    marginTop: 30,
    fontSize: 10,
    textAlign: "center",
    position: "relative",
  },
});

const ContractDH = () => (
  <div className="absolute top-0 left-0 right-0 bottom-0">
    <PDFViewer style={{ width: "100%", height: "100%" }}>
      <Document subject="Contrato de prestación de servicios">
        <Page size="A4" style={styles.page} wrap={false} debug={false}>
          <View style={styles.watermarkContainer} fixed={true}>
            <Image src={watermarkImage} style={styles.watermark} />
          </View>
          <View style={styles.section}>
            {/* Title */}
            <View style={{ marginBottom: 20 }}>
              <Text style={styles.textTitle}>
                CONTRATO DE PRESTACIÓN DE SERVICIOS
              </Text>
            </View>
            <View>
              <Text style={styles.text}>
                En Olmué, _____________ 2024, entre Comercial Dejando Huella
                Ltda. RUT N°76.766.422-2, representado por Don Pedro Olivares
                Maturana, nacionalidad chilena, con cédula identidad
                N°13.994.073-3, profesión Ingeniero Civil Industrial, ambos
                domiciliados en calle Bulnes 1800 Olmué, región de Valparaíso,
                en adelante denominado "Oferente", y (
                <Text style={styles.textBold}>Novia</Text>, nombres y apellidos)
                _______________________________________, con Cédula de Identidad
                N°_______________, de nacionalidad chilena, fecha de nacimiento:
                ____/___/____ domiciliada en
                ______________________________________con número telefónico: +56
                9______________ y (novio) _____________________________________,
                con cédula de Identidad N°_______________, de nacionalidad
                chilena, fecha de nacimiento: ____/___/____ domiciliado en:
                _______________________________ con número telefónico: +56
                9________________ en adelante los “Mandantes”, se ha convenido
                lo siguiente:
              </Text>
            </View>
            <View>
              <Text style={styles.textBold}>PRIMERO:</Text>
              <Text style={styles.text}>
                El Oferente es una empresa dedicada a la realización de eventos,
                para este contrato en particular de evento de Matrimonio, en
                centro de eventos Dejando Huella ____________________
              </Text>
            </View>

            <View>
              <Text style={styles.textBold}>SEGUNDO:</Text>
              <Text style={styles.text}>
                Por el presente instrumento, el Mandante contrata al Centro de
                Eventos “DEJANDO HUELLA” para la prestación de servicios
                detallados en la cláusula precedente: programa Todo Incluido de
                Matrimonio
              </Text>
            </View>

            <View>
              <Text style={styles.textBold}>TERCERO:</Text>
              <Text style={styles.text}>
                Con el propósito de ejecutar el presente contrato, Pedro
                Olivares Maturana se compromete a proporcionar los servicios
                detallados en el programa de Matrimonios (formato PDF de 3
                páginas). Por su parte, el Mandante asume el compromiso de
                cancelar por los servicios recibidos en virtud de este acuerdo.
              </Text>
            </View>

            <View>
              <Text style={styles.textBold}>CUARTO:</Text>
              <Text style={styles.text}>
                El precio del servicio ofrecido por el Oferente será de
                $___________ por persona, estableciendo una base mínima de _____
                personas adultas. El pago inicial del 10% del total se realizará
                dentro de los 2 meses posteriores a la contratación del servicio
                o, en su defecto, en la fecha de la degustación (lo que ocurra
                primero). El 90% restante del saldo pendiente deberá abonarse
                como máximo 20 días antes del evento. Se otorgará un descuento
                del 50% sobre el valor de los adultos a los niños de 6 a 12
                años, sin que estos últimos sean considerados parte de la base
                acordada.
              </Text>
            </View>
          </View>
        </Page>
        <Page size="A4" style={styles.page} wrap={false} debug={false}>
          <View style={styles.watermarkContainer}>
            <Image src={watermarkImage} style={styles.watermark} />
          </View>
          <View style={styles.section}>
            <View>
              <Text style={styles.textBold}>QUINTO:</Text>
              <Text style={styles.text}>
                Los Mandantes cancelarán un monto de $100.000 pesos de reserva,
                el cual no será reembolsable. Esta tarifa incluye los gastos
                asociados con la reserva de la fecha acordada y garantiza el
                mantenimiento de los precios pactados. Asimismo, asegura que el
                lugar esté disponible exclusivamente para los Mandantes en la
                fecha establecida, y cubre los costos administrativos y de
                organización relacionados con la preparación del evento.
              </Text>
            </View>
            <View>
              <Text style={styles.textBold}>SEXTO:</Text>
              <Text style={styles.text}>
                La última confirmación de participantes, se debe hacer 2 semanas
                antes de la realización del evento; si la cantidad de
                participantes disminuye (Base mínima), Dejando Huella podrá
                ajustar el valor cotizado; se facturará de acuerdo a esta última
                ratificación.
              </Text>
            </View>
            <View>
              <Text style={styles.textBold}>SÉPTIMO:</Text>
              <Text style={styles.text}>
                En caso de declararse estado de emergencia u otro evento
                extraordinario, imprevisible e irresistible que impida la
                realización del evento en la fecha originalmente acordada, los
                contratantes quedan exonerados de pagos adicionales no
                establecidos en este contrato y tendrán la posibilidad de
                suspender el evento, ajustando la fecha a un momento adecuado
                dentro del mismo año. Cualquier cambio de fecha a años
                siguientes podría implicar variaciones basadas en el Índice de
                Precios al Consumidor (IPC).
              </Text>
            </View>
            <View>
              <Text style={styles.textBold}>OCTAVO:</Text>
              <Text style={styles.text}>
                El presente contrato se regirá para el evento con fecha:
                ____/____/________ con hora entre las ___hrs y ___hrs.
                (modificable), evento de __ hrs continuas.
              </Text>
            </View>
            <View>
              <Text style={styles.textBold}>NOVENO:</Text>
              <Text style={styles.text}>
                Declaran los comparecientes que con motivo de este convenio y de
                las relaciones comerciales que se desarrollarán entre las
                partes, de forma que se compromete por sí a cumplir en carácter
                obligatorio el servicio convenido, lo que es estrictamente
                necesario para dar cabal cumplimiento a este contrato.
              </Text>
            </View>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCell}>
                  <View style={{ textAlign: "left" }}>
                    <Text>Firma (Novio)</Text>
                    <Text>RUT:</Text>
                  </View>
                </View>
                <View style={styles.tableCell}>
                  <View style={{ textAlign: "left" }}>
                    <Text>Firma (Novia)</Text>
                    <Text>RUT:</Text>
                  </View>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={{ ...styles.tableCellSign, marginTop: 2 }}>
                  <Image
                    style={{
                      width: "40%",
                      margin: "auto",
                    }}
                    src={signatureImage}
                  />
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={{ ...styles.tableCell, marginTop: 0 }}>
                  <Text>PEDRO OLIVARES MATURANA</Text>
                  <Text>RUT: 13.994.073-3</Text>
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  </div>
);

export default ContractDH;
