import * as React from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
} from "@mui/material";

import { actionTypes } from "../../redux/historialCliente/actions/index";

import { useSelector, useDispatch } from "react-redux";

import { Settings, Visibility } from "@mui/icons-material";

import Moment from "react-moment";
import "moment/locale/es";
import NotFound from "../../atomic/organisms/NotFound.organisms";
import { formatPhoneNumber } from "../../functions/formatPhoneNumber";

const toUpperCaseFilter = (d) => {
  if (!d) {
    return d;
  }

  if (d === "") {
    return d;
  }

  return d.toUpperCase();
};

const TableHistorialCliente = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const dispatch = useDispatch();

  const { clientes, loading } = useSelector(
    (state) => state.reducer_historialCLiente
  );

  const rows = clientes;

  const onOpenModalView = (data) => {
    dispatch({ type: actionTypes.clientSelected, payload: data.id_cliente });
    dispatch({ type: actionTypes.openModalView, payload: true });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (loading) {
    return (
      <div>
        <h3>Cargando...</h3>
      </div>
    );
  }

  if (rows.length === 0) {
    return <NotFound texto="No se encontró registros." />;
  }

  return (
    <>
      <TableContainer sx={{ maxHeight: 550 }}>
        <Table stickyHeader aria-label="sticky table" size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell align={"left"} style={{ minWidth: 110 }}>
                Total visitas
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 190 }}>
                Nombre completo
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 150 }}>
                Correo
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 150 }}>
                Teléfono
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 150 }}>
                Última visita
              </TableCell>
              <TableCell
                colSpan={1}
                align={"center"}
                style={{ width: 90, fontSize: 21, color: "#5c5c5c" }}
              >
                <Settings />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                  <TableCell align={"left"}>
                    <b>{row.tot_visitas}</b>
                  </TableCell>
                  <TableCell align={"left"}>
                    {row.nombre_cliente} {row.apepaterno_cliente}{" "}
                    {row.apematerno_cliente}
                  </TableCell>
                  <TableCell align={"left"}>{row.correo_cliente}</TableCell>
                  <TableCell align={"left"}>{formatPhoneNumber(row.telf_cliente)}</TableCell>
                  <TableCell align={"left"}>
                    <Moment filter={toUpperCaseFilter} format="DD/MMM/YYYY">
                      {row.ultima_visita}
                    </Moment>{" "}
                  </TableCell>
                  <TableCell align={"center"} width={35}>
                    <IconButton
                      color="error"
                      onClick={() => onOpenModalView(row)}
                    >
                      <Visibility />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage="Filas por página"
        rowsPerPageOptions={[10, 25, 100, { value: -1, label: "Todo" }]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default TableHistorialCliente;
