import React, { useEffect, useState } from "react";

import { Controller, useForm } from "react-hook-form";

import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import { ArrowBack } from "@mui/icons-material";

import { Search } from "@mui/icons-material/";

import TitleStep from "../Component/TitleStep";

import LoadingPage from "../../../../components/LoadingPage";

import ClientClasification from "../../../../atomic/atoms/clientClasification.atoms";
import NotFound from "../../../../atomic/organisms/NotFound.organisms";
import { formatPhoneNumber } from "../../../../functions/formatPhoneNumber";
import {
  add_clientes,
  search_cliente,
} from "../../../../services/clientes.services";

const dateCliente = () => {
  const fechaInicioDeMes = () => {
    const fechaInicio = new Date();
    return new Date(fechaInicio.getFullYear(), fechaInicio.getMonth(), 1);
  };

  const obtenerFechaFinDeMes = () => {
    const fechaFin = new Date();
    // Iniciar en este año, el siguiente mes, en el día 0 (así que así nos regresamos un día)
    return new Date(fechaFin.getFullYear(), fechaFin.getMonth() + 1, 0);
  };

  const formatearFecha = (fecha) => {
    const mes = fecha.getMonth() + 1;
    const dia = fecha.getDate();
    return `${fecha.getFullYear()}-${(mes < 10 ? "0" : "").concat(mes)}-${(dia <
    10
      ? "0"
      : ""
    ).concat(dia)}`;
  };

  return {
    fechaInicio: fechaInicioDeMes(),
    fechaFin: obtenerFechaFinDeMes(),
    formatearFecha,
  };
};

/*
  TABLE LIST PRODUCT
*/

const TableCliente = (props) => {
  const { dataCliente, selectedCliente, handleSetSelectedCliente } = props;

  if (dataCliente.load) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress color="inherit" /> <br />
        <Typography component="h5" variant="h5" style={{ textAlign: "center" }}>
          Cargando...
        </Typography>
      </div>
    );
  }

  if (dataCliente.err) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <NotFound title="¡Ocurrió un problema!" />
      </div>
    );
  }

  if (dataCliente.data.length === 0) {
    return <NotFound texto="No se encontró registros." />;
  }

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      {dataCliente.data.map((el, idx) => {
        const isItemSelected = selectedCliente.id_cliente === el.id_cliente;

        return (
          <Grid key={idx} item xs={12} sm={6} md={4} lg={4} zeroMinWidth>
            <Paper
              variant="outlined"
              className={`hover:bg-slate-100 ${isItemSelected && "mesaSelected"} `}
              style={{ cursor: "pointer" }}
              onClick={() => handleSetSelectedCliente(el)}
            >
              <div className="text-center bg-slate-200">
                <Typography
                  component={"h6"}
                  variant={"h6"}
                  style={{ color: isItemSelected ? "green" : null }}
                  className="font-semibold uppercase"
                >
                  {el.nombre_cliente} {el.apepaterno_cliente}{" "}
                  {el.apematerno_cliente}
                </Typography>
              </div>
              <Divider />
              <div style={{ padding: 8, fontSize: 13 }}>
                <div>
                  <b>Teléfono:</b> <br />
                  <label>
                    {el.telf_cliente ? formatPhoneNumber(el.telf_cliente) : "-"}
                  </label>
                </div>
                <br />
                <div>
                  <b>Correo eletrónico:</b>
                  <br />
                  <label>{el.correo_cliente ? el.correo_cliente : "-"}</label>
                </div>
                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                  }}
                >
                  <ClientClasification type={el.clasificacion_cliente} />
                </div>
              </div>
            </Paper>
          </Grid>
        );
      })}
    </Grid>
  );
};

const Cliente = (props) => {
  let { dataCliente, setDataCliente, selected, handleSetSelected } = props;

  const STATUS_CLIENTE = ["SEARCH", "ADD", "ADDED"];

  const INITIAL_VALUES = {
    id_cliente: "",
    nombre_cliente: "",
    apepaterno_cliente: "",
    apematerno_cliente: "",
    telf_cliente: "",
    correo_cliente: "",
  };

  const { control, reset, handleSubmit } = useForm({
    defaultValues: INITIAL_VALUES,
  });

  const [loadPage, setLoadPage] = useState(false);
  const [optionCliente, setOptionCliente] = useState("SEARCH");

  const { fechaInicio, fechaFin, formatearFecha } = dateCliente();

  const [searchCliente, setSearchCliente] = useState({
    texto: "",
    estado: "pendiente",
    fecha1: formatearFecha(fechaInicio),
    fecha2: formatearFecha(fechaFin),
  });

  const selectedCliente = selected.cliente;

  const handleLoadPage = (value) => {
    setLoadPage(value);
  };

  const handleSearchCliente = async () => {
    setLoadPage(true);
    setDataCliente({
      load: true,
      data: [],
      err: false,
    });
    try {
      let response = await search_cliente(searchCliente);
      setDataCliente({
        load: false,
        data: response,
        err: false,
      });
      setLoadPage(false);
    } catch (err) {
      console.log(err);
      setDataCliente({
        load: false,
        data: [],
        err: true,
      });
      setLoadPage(false);
    }
  };

  const handleSetSelectedCliente = (element) => {
    if (element.id_cliente === selected.cliente.id_cliente) {
      return handleSetSelected(INITIAL_VALUES, "cliente");
    }

    let dataCliente = {
      ...selected.cliente,
      id_cliente: element.id_cliente,
      nombre_cliente: element.nombre_cliente,
      apepaterno_cliente: element.apepaterno_cliente,
      apematerno_cliente: element.apematerno_cliente,
      telf_cliente: element.telf_cliente,
      correo_cliente: element.correo_cliente,
      clasificacion_cliente: element.clasificacion_cliente,
    };

    handleSetSelected(dataCliente, "cliente");
  };

  const handleOptionCliente = (el) => {
    setOptionCliente(el);

    if (el === "SEARCH") {
      handleSetSelectedCliente(INITIAL_VALUES);
      reset(INITIAL_VALUES);

      handleSearchCliente();
    }
  };

  const handleAddCliente = async (data) => {
    try {
      handleLoadPage(true);

      const { insertId } = await add_clientes(data);

      handleSetSelectedCliente({ ...data, id_cliente: insertId }, "cliente");
      handleLoadPage(false);
      handleOptionCliente(STATUS_CLIENTE[2]);
    } catch (err) {
      handleLoadPage(false);
      console.log(err);
    }
  };

  useEffect(() => {
    /* FETCH TODOS LOS CLIENTES */
    // handleSearchCliente();
    handleSetSelectedCliente(INITIAL_VALUES);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (optionCliente === STATUS_CLIENTE[0]) {
    return (
      <>
        {/* <TitleStep>Selecciona el cliente</TitleStep>
        <br /> */}

        <LoadingPage open={loadPage} setOpen={setLoadPage} />
        <form onSubmit={handleSubmit(handleSearchCliente)}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={10} lg={10}>
              <TextField
                defaultValue={searchCliente.texto}
                fullWidth
                name="texto"
                label="Buscar por nombre..."
                className={`InputDefault`}
                onChange={(e) =>
                  setSearchCliente({
                    ...searchCliente,
                    texto: e.target.value,
                  })
                }
                InputProps={{
                  type: "search",
                  endAdornment: (
                    <IconButton
                      type="submit"
                      aria-label="search"
                      position="end"
                    >
                      <Search fontSize="inherit" />
                    </IconButton>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2} lg={2} style={{ display: "flex" }}>
              <Button
                fullWidth
                variant="contained"
                className="button-add"
                type="button"
                onClick={() => handleOptionCliente(STATUS_CLIENTE[1])}
              >
                Nuevo
              </Button>
            </Grid>
          </Grid>
        </form>
        <br />
        <Paper variant="outlined" style={{ padding: 5 }}>
          <TableCliente
            dataCliente={dataCliente}
            selectedCliente={selectedCliente}
            handleSetSelectedCliente={handleSetSelectedCliente}
          />
        </Paper>
      </>
    );
  }

  if (optionCliente === STATUS_CLIENTE[1]) {
    return (
      <>
        <TitleStep>
          <IconButton color="default" style={{ marginRight: 5 }}>
            <ArrowBack onClick={() => handleOptionCliente(STATUS_CLIENTE[0])} />
          </IconButton>
          Agregar cliente
        </TitleStep>
        <br />
        <Paper variant="outlined" style={{ padding: 5 }}>
          <LoadingPage open={loadPage} setOpen={setLoadPage} />
          <form onSubmit={handleSubmit(handleAddCliente)}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} lg={12}>
                <Controller
                  name="nombre_cliente"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      fullWidth
                      required
                      value={value}
                      onChange={onChange}
                      type="text"
                      label="Nombre"
                      size="small"
                      className={`InputDefault`}
                    />
                  )}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6} lg={6}>
                <Controller
                  name="apepaterno_cliente"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      required
                      fullWidth
                      value={value}
                      onChange={onChange}
                      type="text"
                      label="Apellido paterno"
                      size="small"
                      className={`InputDefault`}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <Controller
                  name="apematerno_cliente"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      required
                      fullWidth
                      value={value}
                      onChange={onChange}
                      type="text"
                      label="Apellido materno"
                      size="small"
                      className={`InputDefault`}
                    />
                  )}
                />
              </Grid> */}
              <Grid item xs={12} sm={6} lg={6}>
                <Controller
                  name="telf_cliente"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      fullWidth
                      inputProps={{
                        maxLength: 9,
                        minLength: 9,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {formatPhoneNumber()}
                          </InputAdornment>
                        ),
                      }}
                      value={value}
                      onChange={onChange}
                      type="text"
                      label="Teléfono"
                      size="small"
                      className={`InputDefault`}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 9);
                      }}
                      min={9}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <Controller
                  name="correo_cliente"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      fullWidth
                      value={value}
                      onChange={onChange}
                      type="email"
                      label="E-mail"
                      size="small"
                      className={`InputDefault`}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                lg={12}
                style={{ textAlign: "center" }}
              >
                <Button
                  variant="contained"
                  color="warning"
                  style={{ width: "30%", minWidth: "max-content" }}
                  type="submit"
                >
                  Agregar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </>
    );
  }

  return (
    <>
      <TitleStep>
        {/* <IconButton color="default" style={{ marginRight: 5 }}>
          <ArrowBack onClick={() => handleOptionCliente(STATUS_CLIENTE[0])} />
        </IconButton> */}
        ¡Se agregó el cliente!
      </TitleStep>
      <br />
      <Paper variant="outlined" style={{ padding: "15px 5px" }}>
        <LoadingPage open={loadPage} setOpen={setLoadPage} />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} lg={12}>
            <Typography variant="inherit" component="strong">
              Nombres:
            </Typography>{" "}
            {selectedCliente.nombre_cliente}
          </Grid>
          <Grid item xs={12} sm={12} lg={12}>
            <Typography variant="inherit" component="strong">
              Apellidos:
            </Typography>{" "}
            {selectedCliente.apepaterno_cliente}{" "}
            {selectedCliente.apematerno_cliente}
          </Grid>
          <Grid item xs={12} sm={12} lg={12}>
            <Typography variant="inherit" component="strong">
              Teléfono:
            </Typography>{" "}
            +59 {selectedCliente.telf_cliente}
          </Grid>
          <Grid item xs={12} sm={12} lg={12}>
            <Typography variant="inherit" component="strong">
              E-Mail:
            </Typography>{" "}
            {selectedCliente.correo_cliente}
          </Grid>
        </Grid>
      </Paper>
      <br />
      <p>Para continuar presione siguiente.</p>
    </>
  );
};

export default Cliente;
