import React from "react";
import Template from "../../components/Template";

// import MyTicketModal from "./modal.myTickets";
// import MyTicketModalDelete from "./modalDelete.myTickets";
import MyTicketSearch from "./search.myTickets";
import MyTicketState from "./state.myTickets";
import MyTicketTable from "./table.myTickets";

const MyTicket = () => {
  return (
    <MyTicketState>
      <Template title="Mis Tickets">
        <MyTicketSearch />
        <MyTicketTable />
      </Template>
    </MyTicketState>
  );
};

export default MyTicket;
