/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";

import {
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from "@mui/material/";

import { Alert, Collapse } from "@mui/material";

import Input from "../../components/Forms/Input";

import { useForm, Controller } from "react-hook-form";

import { Close } from "@mui/icons-material";

import toast, { Toaster } from "react-hot-toast";

import {
  add_garzones,
  get_garzones,
  update_garzones,
  delete_garzones,
} from "../../services/garzones.services";
import { nameCamarero } from "../../functions/nameCamarero";

const ErrorMsg = ({ wrong, setWrong, message }) => {
  return (
    <Collapse in={wrong}>
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setWrong(false);
            }}
          >
            <Close fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
        severity="error"
      >
        {message}
      </Alert>
    </Collapse>
  );
};

const DialogCrudGarzones = ({ open, setOpen, data, handleGetGarzones }) => {
  let initialValues = {
    nombre_garzones: "",
    apellido_garzones: "",
    correo_garzones: "",
  };

  const { control, reset, watch, setValue, handleSubmit } = useForm({
    defaultValues: initialValues,
  });

  const [wrong, setWrong] = React.useState({ open: false, message: "" });

  const handleClose = () => {
    setOpen(false);
  };

  // const toUpperCaseFilter = (d) => {
  //   if (!d) {
  //     return d;
  //   }

  //   if (d === "") {
  //     return d;
  //   }

  //   return d.toUpperCase();
  // };

  const handleAddGarzones = async (data) => {
    try {
      let response;

      if (data.id_garzones) {
        response = await update_garzones(data, data.id_garzones);
        if (response.affectedRows === 1) {
          toast.success(`${nameCamarero()} modificado correctamente`);
          setOpen(false);
          reset(initialValues);
        } else {
          toast.error(`No se pudo modificar el ${nameCamarero()}`);
          setOpen(false);
          reset(initialValues);
        }
      } else {
        response = await add_garzones(data);

        if (response.affectedRows === 1) {
          toast.success(`${nameCamarero()} creado correctamente`);
          setOpen(false);
          reset(initialValues);
        } else {
          toast.error(`No se pudo crear el ${nameCamarero()}`);
          setOpen(false);
          reset(initialValues);
        }
      }

      handleGetGarzones();
    } catch (err) {
      console.log(err.message);
      toast.error(`Hubo un error al crear el ${nameCamarero()}`);
      setOpen(false);
    }
  };

  // OBTAIN DATA

  // const obtain_comuna = async () => {
  //   try {
  //     let response = await get_comunas();
  //     setDataComuna(response);
  //   } catch (err) {
  //     console.log(err.message);
  //   }
  // };

  // const obtain_banco = async () => {
  //   try {
  //     let response = await get_bancos();
  //     setDataBanco(response);
  //   } catch (err) {
  //     console.log(err.message);
  //   }
  // };

  React.useEffect(() => {
    if (open) {
      //console.log(open);
      // obtain_comuna();
      // obtain_banco();

      reset(data);
    }
  }, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"sm"}>
        <form onSubmit={handleSubmit(handleAddGarzones)} autoComplete="off">
          <DialogTitle>
            {data.id_garzones ? "Editar" : "Agregar"}
            <IconButton
              aria-label="close"
              onClick={() => handleClose()}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <br />
            <ErrorMsg
              wrong={wrong.open}
              setWrong={(el) => setWrong({ ...wrong, open: el })}
              message={wrong.message}
            />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} lg={12}>
                <Controller
                  name="nombre_garzones"
                  control={control}
                  // rules={{ required: "First name required" }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      required
                      value={value}
                      onChange={onChange}
                      type="text"
                      title="Nombre"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Controller
                  name="apellido_garzones"
                  control={control}
                  // rules={{ required: "First name required" }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      required
                      value={value}
                      onChange={onChange}
                      type="text"
                      title="Apellido"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Controller
                  name="correo_garzones"
                  control={control}
                  // rules={{ required: "First name required" }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      required
                      value={value}
                      onChange={onChange}
                      type="email"
                      title="Correo electrónico"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="info"
              variant="text"
              onClick={() => {
                setOpen(false);
              }}
            >
              Salir
            </Button>
            <Button type="submit" color="success" variant="contained">
              Aceptar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default DialogCrudGarzones;
