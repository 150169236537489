import * as React from "react";

import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Slide,
  TextField,
  Typography,
} from "@mui/material";

import NotFound from "../../../../../atomic/organisms/NotFound.organisms";
import { nameCamarero } from "../../../../../functions/nameCamarero";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref || false} {...props} />;
});

const TablePedidos = (props) => {
  const { selectedPedido, handleSetSelectedPedido } = props;

  const { data, err, load } = props.data;

  if (load) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress color="inherit" /> <br />
        <Typography component="h5" variant="h5" style={{ textAlign: "center" }}>
          Cargando...
        </Typography>
      </div>
    );
  }

  if (err) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <NotFound title="¡Ocurrió un problema!" />
      </div>
    );
  }

  if (data.length === 0 && load === false) {
    return <NotFound texto="No se encontró registros." />;
  }

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={1}
      style={{ marginTop: 10 }}
    >
      {data.map((el, idx) => {
        const isSelected = selectedPedido.some(
          (elTable) => elTable.id_pedido === el.id_pedido
        );
        return (
          <Grid key={idx} item xs={12} sm={4} md={4} lg={4} zeroMinWidth>
            <Paper
              variant="outlined"
              style={{
                textAlign: "center",
                padding: 10,
                cursor: "pointer",
                border: isSelected ? "2px red solid" : "2px #eee solid",
              }}
              onClick={() => handleSetSelectedPedido(el)}
            >
              <Typography component={"h6"} variant={"h6"}>
                {el.codigo_pedido}
              </Typography>
              <Divider />
              <div style={{ textAlign: "left", padding: "10px 0px" }}>
                <Typography component={"div"} variant={"inherit"}>
                  {el.id_reserva ? (
                    <>
                      Reserva #{el.id_reserva} | {el.usu_nombre}
                    </>
                  ) : (
                    <>
                      Mesa #{el.codigo_mesa} | {el.usu_nombre}
                    </>
                  )}
                </Typography>
                <Typography component={"div"} variant={"inherit"}>
                  Total productos: {el.rowsProductos}
                </Typography>
              </div>
            </Paper>
          </Grid>
        );
      })}
    </Grid>
  );
};

const DialogJoinTable = (props) => {
  let { open, setOpen, infoPedido, dataPedido, callback } = props;

  const pedidoActual = infoPedido.data;

  const newDataPedido = {
    ...dataPedido,
    data: dataPedido.data.filter(
      (el) => el.id_pedido !== pedidoActual.id_pedido
    ),
  };

  const [selectedPedido, setSelectedPedido] = React.useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSetSelectedPedido = (element) => {
    const filterTable = selectedPedido.findIndex(
      (el) => el.id_pedido === element.id_pedido
    );

    if (filterTable >= 0) {
      const newSelectedPedido = selectedPedido.filter(
        (el, idx) => idx !== filterTable
      );

      setSelectedPedido(newSelectedPedido);

      return;
    }

    const newSelectedPedido = [...selectedPedido, element];
    setSelectedPedido(newSelectedPedido);
  };

  const handleJoinPedido = () => {
    const json = {
      pedidoActual,
      pedidosUnir: selectedPedido,
    };

    callback(json);

    handleClose();
  };

  React.useEffect(() => {
    if (open) {
      setSelectedPedido([]);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth
      onClose={handleClose}
      maxWidth={"md"}
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Unir pedidos</DialogTitle>
      <DialogContent>
        <Alert variant="outlined" severity="info">
          Recuerde que este paso es irrebersible, todos los productos de los
          pedidos seleccionados se unirán a este pedido, y los pedidos
          seleccionados se eliminaran.
        </Alert>
        <br />

        {/* <Typography variant="h6">Pedido actual:</Typography>
        <br /> */}
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              value={`${pedidoActual.codigo_pedido}`}
              className={`InputDefault`}
              label="Código de pedido"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={`${pedidoActual.usu_nombre}`}
              label={nameCamarero()}
              className={`InputDefault`}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={`Mesa #${pedidoActual.codigo_mesa}`}
              label="Mesa"
              className={`InputDefault`}
              fullWidth
            />
          </Grid>
        </Grid>
        <br />
        <Typography variant="h6">Selecciona los pedidos a unir:</Typography>
        <TablePedidos
          data={newDataPedido}
          selectedPedido={selectedPedido}
          handleSetSelectedPedido={handleSetSelectedPedido}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="text" color="inherit">
          Cerrar
        </Button>
        <Button
          onClick={handleJoinPedido}
          variant="contained"
          color="success"
          disabled={selectedPedido.length === 0}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogJoinTable;
