import { Avatar, Chip } from "@mui/material";
import CustomTooltipHtml from "./tooltipHtml.atom";

// NUEVO: 'Nuevo',
// SIN_CLASIFICACION: 'Sin clasificación',
// FRECUENTE: 'Frecuente',
// OCASIONAL: 'Ocasional',
// PERDIDO: 'Perdido',
// RECUPERADO: 'Recuperado',

const obtainClasification = (type) => {
  switch (type) {
    case "Nuevo":
      return {
        backgroundColor: {
          background: "#b170ff",
          color: "white",
        },
        text: "El cliente nos visitó por primera vez.",
      };
    case "Frecuente":
      return {
        backgroundColor: {
          background: "#1fa61f",
          color: "white",
        },
        text: "El cliente viene en menos de 30 días.",
      };
    case "Ocasional":
      return {
        backgroundColor: {
          background: "#f4b646",
          color: "#575757",
        },
        text: "El cliente viene entre 30 a 90 días.",
      };
    case "Perdido":
      return {
        backgroundColor: {
          background: "#ff5151",
          color: "white",
        },
        text: "El cliente no volvió a visitarnos desde hace más de 90 días.",
      };
    case "Recuperado":
      return {
        backgroundColor: {
          background: "#2fa9fc",
          color: "white",
        },
        text: "El cliente estuvo como perdido y volvió a visitarnos.",
      };
    case "Sin clasificación":
      return {
        backgroundColor: {
          background: "#969696",
          color: "#ffffff",
        },
        text: "El cliente aún no obtiene una clasificación.",
      };
    default:
      return {
        backgroundColor: {
          background: "#969696",
          color: "#ffffff",
        },
        text: "Cliente sin clasificación.",
      };
  }
};

const ClientClasification = (props) => {
  const { type } = props;
  const firstLetter = type ? type.charAt(0) : "S";

  const { backgroundColor, text } = obtainClasification(type);

  return (
    <CustomTooltipHtml text={text}>
      <Chip
        avatar={<Avatar>{firstLetter}</Avatar>}
        label={type || "Sin calificación"}
        style={backgroundColor}
      />
    </CustomTooltipHtml>
  );
};

export default ClientClasification;
