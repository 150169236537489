import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const LoadingPage = ({ open }) => {
  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    <Backdrop
      className="loadPage"
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      // onClick={handleClose}
    >
      <div style={{ textAlign: "center" }}>
        <CircularProgress style={{ color: "#e0004d", marginBottom: 5 }} />
        <div style={{ fontSize: "18px", color: "#e0004d" }}>Cargando</div>
      </div>
    </Backdrop>
  );
};

export default LoadingPage;
