import React from "react";

import { Controller, useForm } from "react-hook-form";

import TemplateLogin from "../../components/TemplateLogin";

import Logo from "../../assets/images/logo.png";
import Input from "../../components/Forms/Input";

import { recoverPassword } from "../../services/auth.services";

import { Alert, Typography } from "@mui/material";

import { toast } from "react-hot-toast";

import { LoadingButton } from "@mui/lab";
import { motion } from "framer-motion";

import { Link } from "react-router-dom";

const fadeIn = (direction, delay) => {
  return {
    hidden: {
      y: direction === "up" ? 80 : direction === "down" ? -80 : 0,
      opacity: 0,
      x: direction === "left" ? 80 : direction === "right" ? -80 : 0,
    },
    show: {
      y: 0,
      x: 0,
      opacity: 1,
      transition: {
        type: "tween",
        duration: 0.5,
        delay: delay,
        ease: [0.25, 0.25, 0.25, 0.75],
      },
    },
  };
};

export const RenderForgotPassword = () => {
  const initialValues = {
    username: "",
  };

  const { control, handleSubmit, reset } = useForm({
    defaultValues: initialValues,
  });

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const handleRecoverPassword = async (data) => {
    try {
      setLoading(true);
      const response = await recoverPassword(data);
      setLoading(false);

      if (response.status === 200) {
        toast.success(
          "Se ha enviado un correo con las instrucciones para recuperar tu contraseña",
          {
            duration: 6000,
          }
        );

        setSuccess(true);

        reset();
      } else {
        toast.error("Ha ocurrido un error, por favor intente nuevamente");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Ha ocurrido un error, por favor intente nuevamente");
    }
  };

  return (
    <TemplateLogin title="Login">
      <motion.div
        variants={fadeIn("up", 0.5)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: true, amount: 0.6 }}
        className="text-center Logo_Login !w-full !mb-4"
      >
        <img
          src={Logo}
          alt="Logo"
          className="w-40 mx-auto object-cover object-center"
        />

        <Typography variant="h5" className="Texto_Login">
          Recuperar contraseña
        </Typography>
      </motion.div>

      {success && (
        <Alert variant="standard" color="success" className="mb-5 text-left">
          Se ha enviado un correo con las instrucciones para recuperar tu
          contraseña, por favor revisa tu bandeja de entrada o spam y sigue las
          instrucciones para recuperar tu contraseña correctamente.
        </Alert>
      )}

      <div>
        <form onSubmit={handleSubmit(handleRecoverPassword)} autoComplete="off">
          <motion.div
            variants={fadeIn("down", 0.9)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.6 }}
            className="text-center Logo_Login"
          >
            <Controller
              name="username"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Input
                  required
                  disabled={loading}
                  value={value}
                  onChange={onChange}
                  type="text"
                  title="Usuario de inicio de sesión"
                  style={{ marginBottom: "15px" }}
                />
              )}
            />
          </motion.div>

          <motion.div
            variants={fadeIn("down", 1.5)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.6 }}
            className="text-right Logo_Login"
          >
            <LoadingButton
              className="!w-32"
              loading={loading}
              type="submit"
              color="secondary"
              variant="contained"
              loadingIndicator="Cargando..."
            >
              Aceptar
            </LoadingButton>
          </motion.div>
        </form>
      </div>
      <motion.div
        variants={fadeIn("down", 1.5)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: true, amount: 0.6 }}
        className="text-center mt-10 !w-full !mb-4"
      >
        <Link
          to="/"
          className="text-blue-500 hover:text-blue-700 cursor-pointer underline"
        >
          Volver al inicio de sesión
        </Link>
      </motion.div>
    </TemplateLogin>
  );
};

export default RenderForgotPassword;
