import axios from "axios";
import { config } from "../config";

import { axiosHeader } from "../functions/axiosHeader";

const { baseurl } = config;

export const get_categoria = () => {
  const url = `${baseurl}/api/categoria_plato/get/`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const add_categoria = (params, type = 1) => {
  const url = `${baseurl}/api/categoria_plato/add/${type}`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const update_categoria_plato = (params) => {
  const url = `${baseurl}/api/categoria_plato/update/`;

  return axios
    .put(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const delete_categoria = (id) => {
  const url = `${baseurl}/api/categoria_plato/delete/${id}/`;

  return axios
    .delete(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const add_categoria_plato = (params) => {
  const url = `${baseurl}/api/categoria_plato/platos/add/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const delete_categoria_plato = (id) => {
  const url = `${baseurl}/api/categoria_plato/platos/delete/${id}/`;

  return axios
    .delete(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};
