import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";

import { delete_user } from "../../services/user.services";

import toast from "react-hot-toast";

const DialogEliminarUser = ({ open, setOpen, data, handleGetUsuarios }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const hanndleDeleteUser = async (id) => {
    try {
      let { affectedRows } = await delete_user(id);

      if (affectedRows === 1) {
        toast.success("Usuario eliminado correctamente");
        setOpen(false);
        handleGetUsuarios();
      } else {
        toast.error("No se pudo eliminar el usuario");
        setOpen(false);
      }
    } catch (err) {
      console.log(err.message);
      toast.error("Hubo un error al eliminar al usuario");
      setOpen(false);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Eliminar"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Estas seguro de eliminar a {data.usu_nombre} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="text" color="inherit">
            Cerrar
          </Button>
          <Button
            onClick={() => {
              hanndleDeleteUser(data.usu_id);
            }}
            variant="contained"
            color="error"
            autoFocus
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogEliminarUser;
