/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import MenuIcon from "@mui/icons-material/Menu";
import { Button, IconButton, Menu, MenuItem, Toolbar } from "@mui/material";

import MenuInfo from "../components/MenuInfo";

import {
  AccountCircle,
  ChangeCircleOutlined,
  Lock,
  Password,
} from "@mui/icons-material";

import { getUser, logOut } from "../services/auth.services";

const Header = ({
  activeBar,
  setActiveBar,
  activeBarMobile,
  setActiveBarMobile,
  setOpenSelectSucursal,
}) => {
  let history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const logo = getUser()?.empresa?.logo_emp;
  const logoSplit = logo ? logo.split("/") : 0;
  const logoLength = Number(logoSplit.length) - 1;
  const logoName = logoSplit[logoLength];

  const sede = getUser().empresa.nom_empsede || "";
  const id_perfil = getUser().id_perfil || "";
  const id_empsede = getUser().id_empsede || 1;

  const isChangeCompany =
    getUser()?.id_perfil === 16 ||
    getUser()?.id_perfil === 14 ||
    getUser()?.id_perfil === 6 ||
    getUser()?.id_perfil === 4 ||
    getUser()?.id_perfil === 13
      ? true
      : false;

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleRedirection = (type) => {
    if (type === "settings") {
      history.push("/settings/profile");
      return null;
    }

    if (type === "password") {
      history.push("/settings/change-password");
      return null;
    }

    if (type === "usuarios") {
      history.push("/settings/users");
      return null;
    }

    if (type === "logout") {
      logOut();
      history.push("/");
      return null;
    }
  };

  const mobileMenuId = "primary-search-account-menu-mobile";

  const handleActiveMenuBar = () => {
    let active = activeBar === true ? false : true;
    let activeMobile = activeBarMobile === true ? false : true;

    localStorage.setItem(
      "activeMenuBar",
      JSON.stringify({ activeDesktop: active, activeMobile: activeMobile })
    );

    setActiveBar(active);
    setActiveBarMobile(activeMobile);
  };

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={() => handleRedirection("settings")}>
        <AccountCircle />
        Mi perfil
      </MenuItem>
      <MenuItem onClick={() => handleRedirection("password")}>
        <Password />
        Cambiar contraseña
      </MenuItem>
      <MenuItem onClick={() => handleRedirection("logout")}>
        <Lock /> Cerrar sesión
      </MenuItem>
    </Menu>
  );

  return (
    <div className="Header">
      {/* <AppBar position="static"> */}
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleActiveMenuBar}
        >
          <MenuIcon />
        </IconButton>
        {isChangeCompany && (
          <Button
            aria-label="change of company"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={() => setOpenSelectSucursal(true)}
            color="secondary"
            variant="outlined"
            className="!mr-3"
          >
            <ChangeCircleOutlined />{" "}
            {id_empsede < 0 && (
              <span className="ml-2">Seleccionar empresa</span>
            )}
          </Button>
        )}
        {logoName && (
          <Link
            to="/home"
            className="ml-0"
            style={{
              display: "flex",
              verticalAlign: "middle",
              alignItems: "center",
              maxHeight: 80,
            }}
          >
            <img
              src={require(`../../src/assets/images/logos/${logoName}`)}
              alt="logo"
              style={{
                width: 50,
                height: 50,
                objectFit: "contain",
                padding: 0,
              }}
            />
            <div
              className="uppercase"
              style={{
                fontSize: 13,
                marginLeft: 5,
                color: "#33058d",
                fontWeight: 600,
                display: "flex",
              }}
            >
              <span className="hidden md:flex">
                {getUser().empresa.nom_emp} - {"  "}
              </span>
              <span className="flex">{sede}</span>
            </div>
          </Link>
        )}

        <div style={{ flexGrow: 1 }} />

        <MenuInfo />

        {/* <div className="sectionMobile">
          <IconButton
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleMobileMenuOpen}
            color="inherit"
          >
            <MoreIcon />
          </IconButton>
        </div> */}
      </Toolbar>

      {/* {renderMobileMenu} */}
      {/* {renderMenu} */}
    </div>
  );
};

export default Header;
