import * as React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
  TextField,
} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref || false} {...props} />;
});

const DialogEditSoldOut = (props) => {
  let { callback, rowSelected, open, setOpen } = props;

  const [dialogRowSelected, setDialogRowSelected] = React.useState({});

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { value, name } = e.target;

    setDialogRowSelected({ ...dialogRowSelected, [name]: value });
  };

  const handleConfirm = (e) => {
    e.preventDefault();

    callback(dialogRowSelected);
  };

  React.useEffect(() => {
    setDialogRowSelected(rowSelected);
  }, [rowSelected]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth
      onClose={handleClose}
      maxWidth={"xs"}
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form onSubmit={handleConfirm}>
        <DialogTitle id="alert-dialog-title">
          Editar {dialogRowSelected.nombre_plato}
        </DialogTitle>
        <DialogContent>
          <br />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                label="Cantidad"
                name="amount_proagotado"
                value={dialogRowSelected.amount_proagotado}
                onChange={(e) => {
                  const inputValue = Number(e.target.value);
                  handleChange({
                    target: {
                      name: e.target.name,
                      value: Number(e.target.value) <= 0 ? 0 : inputValue,
                    },
                  });
                }}
                className={`InputDefault`}
                type="number"
                inputProps={{
                  min: 0,
                }}
                required
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="text" color="inherit">
            Cerrar
          </Button>
          <Button type="submit" variant="contained" color="error">
            Editar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogEditSoldOut;
