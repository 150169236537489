import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
dayjs.extend(weekOfYear);

const TOT_WEEK = 7;

export const fechaFormat = (fecha) => {
  return dayjs(fecha).format("YYYY-MM-DD");
};

export const fechaLastMonths = (month = 1) => {
  let fechaActual = new Date();

  fechaActual.setMonth(fechaActual.getMonth() - month);

  return {
    dateIni: fechaFormat(fechaActual),
    dateEnd: fechaFormat(new Date()),
  };
};

export const fullDayName = (day) => {
  const daysOfWeek = {
    Lu: "Lunes",
    Ma: "Martes",
    Mi: "Miércoles",
    Ju: "Jueves",
    Vi: "Viernes",
    Sa: "Sábado",
    Do: "Domingo",
  };

  return daysOfWeek[day];
};

export function formatHorizontalDate(
  data,
  dataPlatos,
  params,
  sortValue = "desc"
) {
  const newData = [];

  const date1 = dayjs(params.dateIni);
  const date2 = dayjs(params.dateEnd);

  let totConsumo = data.reduce((acc, item) => acc + item.tot_consumo, 0);
  let currentDate = date1;

  const listDate = [];

  while (currentDate.isBefore(date2) || currentDate.isSame(date2)) {
    const formatDate = dayjs(currentDate).format("DD/MM/YYYY");
    listDate.push(formatDate);

    currentDate = currentDate.add(1, "day");
  }

  dataPlatos.forEach((itemPlato) => {
    const dataPlatoDate = [];

    const date1 = dayjs(params.dateIni);
    const date2 = dayjs(params.dateEnd);

    let currentDate = date1;
    let totAmountPlato = 0;

    while (currentDate.isBefore(date2) || currentDate.isSame(date2)) {
      const formattedDate = currentDate;

      const filerData = data.find(
        (item) =>
          dayjs(item.date).utc().format("DD/MM/YYYY") ===
            formattedDate.utc().format("DD/MM/YYYY") &&
          item.id_plato === itemPlato.id_plato
      );

      if (!filerData) {
        const totConsumo = 0;
        const precioXconsumo = itemPlato.precio_plato * totConsumo;
        const costoXconsumo = 0 * totConsumo;
        const utilidad = precioXconsumo - costoXconsumo;

        dataPlatoDate.push({
          // date: currentDate,
          id_plato: itemPlato.id_plato,
          tot_consumo: 0,
          precio_plato: itemPlato.precio_plato,
          precioXconsumo,
          costoXconsumo,
          utilidad,
        });
      } else {
        const totConsumo = filerData.tot_consumo;
        const precioXconsumo = itemPlato.precio_plato * totConsumo;
        const costoXconsumo = 0 * totConsumo;
        const utilidad = precioXconsumo - costoXconsumo;

        dataPlatoDate.push({
          // date: dayjs(filerData.date),
          id_plato: filerData.id_plato,
          tot_consumo: filerData.tot_consumo,
          precio_plato: itemPlato.precio_plato,
          precioXconsumo,
          costoXconsumo,
          utilidad,
        });
      }

      totAmountPlato += filerData?.tot_consumo || 0;

      currentDate = currentDate.add(1, "day");
    }

    newData.push({
      id_plato: itemPlato.id_plato,
      nombre_plato: itemPlato.nombre_plato,
      cantidad_plato: totAmountPlato,
      cantidad_plato_percent: (totAmountPlato * 100) / totConsumo,
      data: dataPlatoDate,
    });
  });

  /**
   * FOOTER
   */

  let footerCurrentDate = date1;
  let indexFooter = 0;
  let dataFooter = [];

  function calculateTotal(data, indexFooter) {
    return data.reduce((acc, item) => {
      return acc + item.data[indexFooter].tot_consumo;
    }, 0);
  }

  while (footerCurrentDate.isBefore(date2) || footerCurrentDate.isSame(date2)) {
    const tot = calculateTotal(newData, indexFooter);

    dataFooter.push({
      tot_consumo: tot,
    });

    footerCurrentDate = footerCurrentDate.add(1, "day");
    indexFooter = indexFooter + 1;
  }

  const footerData = {
    id_plato: 0,
    nombre_plato: "Total",
    cantidad_plato: totConsumo,
    data: dataFooter,
  };

  const sortAscNewData = newData.sort((a, b) => {
    if (sortValue === "desc") {
      return b.cantidad_plato - a.cantidad_plato;
    }

    return a.cantidad_plato - b.cantidad_plato;
  });

  return { body: [...sortAscNewData], head: listDate, footer: footerData };
}

export function formatProjection(dataPlatos, dataConsumption, params) {
  const nameDaysSpanish = {
    Monday: "Lunes",
    Tuesday: "Martes",
    Wednesday: "Miércoles",
    Thursday: "Jueves",
    Friday: "Viernes",
    Saturday: "Sabádo",
    Sunday: "Domingo",
  };

  const listDate = [nameDaysSpanish[params.nameDay]];

  const platoDate = [];

  dataPlatos.forEach((itemPlato) => {
    const date1 = dayjs();
    const date2 = date1.subtract(TOT_WEEK, "week");

    let currentDate = date1;

    let totConsumo = 0;

    while (currentDate.isAfter(date2) || currentDate.isSame(date2)) {
      const nameDayShort = nameDaysSpanish[params.nameDay].slice(0, 2);
      const formattedDate = currentDate;

      if (dayjs(currentDate).format("dddd") === nameDayShort) {
        const searchDay = dataConsumption.find(
          (el) =>
            dayjs(el.date).format("YYYY-MM-DD") ===
              formattedDate.format("YYYY-MM-DD") &&
            el.id_plato === itemPlato.id_plato
        );

        totConsumo += searchDay?.tot_consumo || 0;
      }

      // Resta un día a la fecha actual en cada iteración
      currentDate = currentDate.subtract(1, "day");
    }

    const promConsumo = (totConsumo / TOT_WEEK).toFixed(2);

    platoDate.push({
      id_plato: itemPlato.id_plato,
      nombre_plato: itemPlato.nombre_plato,
      data: [
        {
          tot: promConsumo,
          day: nameDaysSpanish[params.nameDay],
        },
      ],
    });
  });

  return {
    head: listDate,
    body: platoDate,
  };
}

export function formatProjectionWeek(dataPlatos, dataConsumption, params) {
  const platoDate = [];

  dataPlatos.forEach((itemPlato) => {
    const date1 = dayjs();
    const date2 = date1.subtract(TOT_WEEK, "week");

    let currentDate = date1;

    let totConsumo = 0;

    while (currentDate.isAfter(date2) || currentDate.isSame(date2)) {
      const numberWeek = currentDate.week();

      const searchDay = dataConsumption.find(
        (el) =>
          el.week_number === numberWeek && el.id_plato === itemPlato.id_plato
      );

      totConsumo += searchDay?.tot_consumo || 0;

      // Resta un día a la fecha actual en cada iteración
      currentDate = currentDate.subtract(1, "week");
    }

    const promConsumo = (totConsumo / TOT_WEEK).toFixed(2);

    platoDate.push({
      id_plato: itemPlato.id_plato,
      nombre_plato: itemPlato.nombre_plato,
      data: [
        {
          tot: promConsumo * params.amountWeek,
          day: "Total",
        },
      ],
    });
  });

  return {
    head: ["Total"],
    body: platoDate,
  };
}

export function formatPrint(tabValue, data) {
  if (data.length === 0) {
    return [];
  }

  const newData = [];

  if (tabValue === 1) {
    data?.body.forEach((itemProduct) => {
      let data = {
        Codigo: itemProduct.id_plato,
        Nombre: itemProduct.nombre_plato,
      };

      itemProduct.data.forEach((el) => {
        data = {
          ...data,
          [`${fullDayName(el.day)}`]: el.tot,
        };
      });

      newData.push(data);
    });
  } else {
    data?.body.forEach((itemProduct) => {
      let data = {
        Codigo: itemProduct.id_plato,
        Nombre: itemProduct.nombre_plato,
      };

      itemProduct.data.forEach((el) => {
        data = {
          ...data,
          [`${fullDayName(el.day)}`]: el.prom,
        };
      });

      newData.push(data);
    });
  }

  return newData;
}
