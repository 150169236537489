import dayjs from "dayjs";
import React, { useEffect } from "react";
import CustomTable from "../../atomic/organisms/Table.organisms";
import ContainerInput from "../../components/ContainerInput";
import Template from "../../components/Template";
import { getWallet } from "../../services/wallet.services";

import { Search } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import InputDefault from "../../atomic/atoms/input.atoms";
import BasicDateRangePicker from "../quotation/ui/BasicDateRangePicker.quotation";

const formatNumber = (n) => {
  return n === "" || n === 0 ? n : Number(n).toLocaleString();
};

const calcToPay = (data) => {
  return (
    (Number(data.quantity_sold) - Number(data.quantity_released)) *
    Number(data.monto_comission)
  );
};

const Filters = (props) => {
  const { onSearch } = props;

  const { control, handleSubmit } = useForm({
    defaultValues: {
      searchName: "",
      dateRange: [],
    },
  });

  return (
    <form onSubmit={handleSubmit(onSearch)} autoComplete="off">
      <div className="mb-4 w-full grid grid-cols-12 gap-2">
        <div className="col-span-3">
          <Controller
            name="searchName"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                value={value}
                onChange={onChange}
                type={"text"}
                title={"Buscar por código"}
              />
            )}
          />
        </div>
        <div className="col-span-4 flex gap-3">
          <Controller
            name="dateRange"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <BasicDateRangePicker value={value} onChange={onChange} />
            )}
          />

          <IconButton
            type="submit"
            color="default"
            className="ml-auto col-span-1"
          >
            <Search />
          </IconButton>
        </div>
        {/* <div className="col-span-1">
          <IconButton
            type="submit"
            color="default"
            className="ml-auto col-span-1"
          >
            <Search />
          </IconButton>
        </div> */}
        {/* <div className="col-span-1">
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className="ml-auto"
            onClick={() => onOpenAddModal(true)}
          >
            Agregar
          </Button>
        </div> */}
      </div>
    </form>
  );
};

const WalletHistoryPage = () => {
  const [dataWallet, setDataWallet] = React.useState([]);
  const [load, setLoad] = React.useState(false);
  const [sumTotal, setSumTotal] = React.useState(0);

  const fetchDataWallet = async () => {
    try {
      setLoad(true);
      const data = await getWallet();

      setLoad(false);

      const newData = data
        .map((el) => {
          return {
            ...el,
            quantity_to_pay: calcToPay(el),
            name_reserva: `${el.nombre_reserva || ""} ${
              el.apellido_paterno_reserva || ""
            } ${el.apellido_materno_reserva || ""}`,
            people_served:
              Number(el.quantity_sold) - Number(el.quantity_released),
          };
        })
        .filter((el) => el.status_wallet === "pagado");
      setDataWallet(newData);

      const total = newData.reduce((acc, el) => {
        return acc + el.quantity_to_pay;
      }, 0);

      setSumTotal(total);
    } catch (error) {
      console.error("Error en fetchDataWallet: ", error);
    }
  };

  const onSearchData = async (value) => {
    const response = await getWallet();

    const newResponse = response
      .map((el) => {
        return {
          ...el,
          quantity_to_pay: calcToPay(el),
          name_reserva: `${el.nombre_reserva || ""} ${
            el.apellido_paterno_reserva || ""
          } ${el.apellido_materno_reserva || ""}`,
        };
      })
      .filter((el) => el.status_wallet === "pagado");

    let filterData = newResponse;

    if (value.searchName) {
      filterData = filterData.filter((el) =>
        el.id_reserva.toString().includes(value.searchName.toLowerCase())
      );
    }

    if (value.dateRange.length > 0) {
      filterData = filterData.filter((el) => {
        const date = dayjs(el.created_at).utc();
        return (
          date.isAfter(dayjs(value.dateRange[0]).utc()) &&
          date.isBefore(dayjs(value.dateRange[1]).utc())
        );
      });
    }

    setDataWallet(filterData);

    const total = filterData.reduce((acc, el) => {
      return acc + el.quantity_to_pay;
    }, 0);

    setSumTotal(total);
  };

  useEffect(() => {
    fetchDataWallet();
  }, []);

  return (
    <Template title="Reporte" style={{ position: "relative" }}>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-12">
          <Filters onSearch={onSearchData} />
        </div>

        <div className="col-span-12 md:col-span-12">
          <ContainerInput className="p-0">
            <CustomTable
              columns={[
                {
                  id: "id_reserva",
                  label: "Código",
                  minWidth: 80,
                  align: "left",
                },
                {
                  id: "name_reserva",
                  label: "Nombre",
                  minWidth: 150,
                  align: "left",
                  format: (el) => {
                    return el.toUpperCase();
                  },
                },
                {
                  id: "quantity_to_pay",
                  label: "Cantidad pagada",
                  minWidth: 150,
                  align: "left",
                  format: (el) => {
                    return `$ ${formatNumber(el)}`;
                  },
                },
                {
                  id: "pax_reserva",
                  label: "PAX reserva",
                  minWidth: 150,
                  align: "left",
                },
                {
                  id: "people_served",
                  label: "PAX atendidos",
                  minWidth: 150,
                  align: "left",
                },
                {
                  id: "date_paid",
                  label: "Fecha pagada",
                  minWidth: 160,
                  align: "left",
                  format: (el) => {
                    if (!el) return "-";
                    return dayjs(el).utc().format("DD/MM/YYYY");
                  },
                },
                {
                  id: "created_at",
                  label: "Fecha creación",
                  minWidth: 160,
                  align: "left",
                  format: (el) => {
                    return dayjs(el).utc().format("DD/MM/YYYY");
                  },
                },
              ]}
              rows={dataWallet}
              load={load}
              error={false}
              pagination={true}
            />
          </ContainerInput>
          <ContainerInput className="p-3 mt-2">
            <div className="flex justify-end">
              <p className="text-sm font-semibold">Total: </p>
              <p className="text-sm">
                $ {formatNumber(sumTotal)}
              </p>
            </div>
          </ContainerInput>
        </div>
      </div>
    </Template>
  );
};

export default WalletHistoryPage;
