const FooterMessage = () => {
  const messages = [
    "🚨 La plataforma entrará en modo soporte a partir del 22 de agosto. 🚨",
    "Si tienes problema con la platforma, envíanos un correo a atencion.soporte.ubuntu@gmail.com",
    "🔔 Recuerda cambiar tu contraseña regularmente para mayor seguridad. 🔔",
    "🚨 La plataforma entrará en modo soporte a partir del 22 de agosto. 🚨",
    "Si tienes problema con la platforma, envíanos un correo a atencion.soporte.ubuntu@gmail.com",
    "🔔 Recuerda cambiar tu contraseña regularmente para mayor seguridad. 🔔",
    "🚨 La plataforma entrará en modo soporte a partir del 22 de agosto. 🚨",
    "Si tienes problema con la platforma, envíanos un correo a atencion.soporte.ubuntu@gmail.com",
    "🔔 Recuerda cambiar tu contraseña regularmente para mayor seguridad. 🔔",
  ];

  return null;

  return (
    <div className="fixed bottom-0 bg-purple-900 w-full p-2 text-white hidden sm:block">
      <div className="relative flex overflow-x-hidden">
        {/* <button className="absolute left-0 top-0 bottom-0 flex items-center justify-center w-5 text-purple-900 bg-white">
          x
        </button> */}
        <div className="animate-marquee whitespace-nowrap">
          {messages.map((message, index) => (
            <span className="text-sm mx-64" key={index}>
              {message}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FooterMessage;
