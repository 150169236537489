import "./style.acctionbutton.scss";

import {
  Add,
  CallSplit,
  Cancel,
  Close,
  Discount,
  FolderOpen,
  PointOfSale,
  Print
} from "@mui/icons-material";

import { Button, Grid } from "@mui/material";
import "./style.acctionbutton.scss";

// const estado_pedido = {
//   1: "Ingresado",
//   2: "Cocinando",
//   3: "Terminado",
//   4: "Pre cuenta",
//   5: "Terminado",
// };

const ButtonAction = (props) => {
  let { action, label, className, icon, disabled } = props;
  return (
    <Button
      variant="outlined"
      fullWidth
      className={`acctionButtons ${className} ${disabled ? "disabled" : ""}`}
      size="small"
      disabled={disabled}
      onClick={() => action()}
    >
      {icon} {label}
    </Button>
  );
};

const ActionsButtons = (props) => {
  let { infoPedido } = props;

  const {
    handleOpenOrder,
    handleCancelProduct,
    handleAddProduct,
    handleApplyDiscount,
    handleSplitAccount,
    handlePrintAccount,
    handleInfoClient,
    handlePayAccount,
    handleCloseOrder,
    handleChangeTable,
    handleChangeGarzon,
    handleJoinTable,
  } = props.actions;

  const id_estado = infoPedido.id_estadopedido;

  const jsonActions = [
    {
      action: handleOpenOrder,
      label: "Abrir cuenta",
      icon: <FolderOpen style={{ marginRight: 2, fontSize: 30 }} />,
      disabled:
        id_estado === 1 || id_estado === 2 || id_estado === 3 ? true : false,
      className: "newOrder",
    },
    {
      action: handleCloseOrder,
      label: "Cerrar cuenta",
      icon: <Cancel style={{ marginRight: 2, fontSize: 30 }} />,
      disabled: false,
      className: "cancelOrder",
    },
    {
      action: handlePrintAccount,
      label: "Imprimir cuenta",
      icon: <Print style={{ marginRight: 2, fontSize: 30 }} />,
      disabled: id_estado < 3 ? true : false,
      className: "printAccount",
    },
    {
      action: handleSplitAccount,
      label: "Dividir cuenta",
      icon: <CallSplit style={{ marginRight: 2, fontSize: 30 }} />,
      disabled: id_estado === 3 ? false : true,
      className: "splitAccount",
    },
    {
      action: handlePayAccount,
      label: "Pagar cuenta",
      icon: <PointOfSale style={{ marginRight: 2, fontSize: 30 }} />,
      disabled: id_estado === 4 ? false : true,
      className: "payAccount",
    },
    {
      action: handleAddProduct,
      label: "Agregar productos",
      icon: <Add style={{ marginRight: 2, fontSize: 30 }} />,
      disabled:
        id_estado === 1 || id_estado === 2 || id_estado === 3 ? false : true,
      className: "addProduct",
    },
    {
      action: handleCancelProduct,
      label: "Eliminar productos",
      icon: <Close style={{ marginRight: 2, fontSize: 30 }} />,
      disabled:
        id_estado === 1 || id_estado === 2 || id_estado === 3 ? false : true,
      className: "cancelProduct",
    },
    {
      action: handleApplyDiscount,
      label: "Aplicar descuento",
      icon: <Discount style={{ marginRight: 2, fontSize: 30 }} />,
      disabled: id_estado === 3 ? false : true,
      className: "applyDiscount",
    },
    // {
    //   action: handleInfoClient,
    //   label: "Datos cliente",
    //   icon: <Accessibility style={{ marginRight: 2, fontSize: 30 }} />,
    //   disabled: false,
    //   className: "infoClient",
    // },
    // {
    //   action: handleChangeTable,
    //   label: "Cambiar mesa",
    //   icon: <TableBar style={{ marginRight: 2, fontSize: 30 }} />,
    //   disabled:
    //     id_estado === 1 || id_estado === 2 || id_estado === 3 ? false : true,
    //   className: "changeTable",
    // },
    // {
    //   action: handleChangeGarzon,
    //   label: `Cambiar ${nameCamarero(false)}`,
    //   icon: <Person style={{ marginRight: 2, fontSize: 30 }} />,
    //   disabled:
    //     id_estado === 1 || id_estado === 2 || id_estado === 3 ? false : true,
    //   className: "changeGarzon",
    // },
    // {
    //   action: handleJoinTable,
    //   label: "Unir pedido",
    //   icon: <JoinFull style={{ marginRight: 2, fontSize: 30 }} />,
    //   disabled:
    //     id_estado === 1 || id_estado === 2 || id_estado === 3 ? false : true,
    //   className: "joinTable",
    // },
  ];

  return (
    <Grid container spacing={1}>
      {jsonActions.map((action, index) => (
        <Grid item xs={3} key={index}>
          <ButtonAction {...action} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ActionsButtons;
