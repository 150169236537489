/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import * as React from "react";

import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import { Close } from "@mui/icons-material";

import { Grid } from "@mui/material";
import "moment/locale/es";
import { getUser } from "../../services/auth.services";
import { get_carta } from "../../services/carta.services";
import { search_products } from "../../services/pedidos.services";
import CategoryProductReserva from "./Components/categorymenu.reserva";
import ProductMenuReserva from "./Components/productmenu.reserva";
import {
  add_menu_reserva,
  get_menu_reserva,
} from "../../services/reservas.services";

const DialogMenu = ({ open, setOpen, data }) => {
  const [loadPage, setLoadPage] = React.useState(false);
  const [categorySelected, setCategorySelected] = React.useState(false);
  const [dataPlatoReserva, setDataPlatoReserva] = React.useState([]);

  const [dataCategories, setDataCategories] = React.useState({
    load: false,
    err: false,
    data: [],
  });

  const [dataProducts, setDataProducts] = React.useState({
    load: false,
    err: false,
    data: [],
    full: "",
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleFetch = async () => {
    try {
      const response = await get_menu_reserva(data.id_reserva);

      setDataPlatoReserva(response);
    } catch (err) {}
  };

  const handleGetCategories = async () => {
    try {
      setDataCategories({ load: true, err: false, data: [] });
      const response = await get_carta(getUser().empresa.id_empsede);
      setDataCategories({
        load: false,
        err: false,
        data: response,
      });
    } catch (err) {
      setDataCategories({ load: false, err: true, data: [] });
      console.log(err);
    }
  };

  const handleSearchProduct = async (data) => {
    try {
      setCategorySelected(data);

      setLoadPage(true);
      setDataProducts({ load: true, err: false, data: [], full: "" });
      const response = await search_products({ category: data });

      setDataProducts({
        load: false,
        err: false,
        data: response,
        full: response.length > 0 ? "full" : "notfound",
      });
      setLoadPage(false);
    } catch (err) {
      setDataProducts({ load: false, err: true, data: [], full: "" });
      console.log(err);
    }
  };

  const handleSelectedCategory = (data) => {
    handleSearchProduct(data);
  };

  const handleRefresh = () => {
    setDataProducts({ load: false, err: false, data: [], full: "" });
    setDataPlatoReserva([]);
    setCategorySelected(false);
  };

  const handleAddEditDeleteProduct = async (element) => {
    try {
      const params = {
        data: element,
        id_reserva: data?.id_reserva,
      };

      await add_menu_reserva(params);
    } catch (err) {}
  };

  const fullProduct = dataProducts.full;

  React.useEffect(() => {
    if (open) {
      handleFetch();
      handleGetCategories();

      handleRefresh();
      return;
    }
  }, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
        <DialogTitle>
          Agregar platos
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <CategoryProductReserva
                handleSelectedCategory={handleSelectedCategory}
                categorySelected={categorySelected}
                data={dataCategories}
                fullProduct={fullProduct}
              />
            </Grid>
            <Grid item xs={8}>
              <ProductMenuReserva
                data={dataProducts}
                dataPlatoReserva={dataPlatoReserva}
                setDataPlatoReserva={setDataPlatoReserva}
                loading={loadPage}
                onAddProduct={handleAddEditDeleteProduct}
                onDeleteProduct={handleAddEditDeleteProduct}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="info"
            variant="text"
            onClick={() => {
              setOpen(false);
            }}
          >
            Salir
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogMenu;
