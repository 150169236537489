import * as React from "react";

import TextField from "@mui/material/TextField";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";

import moment from "moment";

const days = ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"];

moment.updateLocale("es", {
  week: {
    dow: 1,
  },
  weekdaysMin: days,
  weekdaysShort: days,
  weekdays: days,
  months: [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio ",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ],
  monthsShort: [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun ",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ],
  longDateFormat: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "DD/MM/YYYY",
    LL: "D [de] MMMM [de] YYYY",
    LLL: "D [de] MMMM [de] YYYY HH:mm",
    LLLL: "dddd, D [de] MMMM [de] YYYY HH:mm",
  },
  relativeTime: {
    future: "en %s",
    past: "hace %s",
    s: "segundos",
    m: "un minuto",
    mm: "%d minutos",
    h: "una hora",
    hh: "%d horas",
    d: "un día",
    dd: "%d días",
    M: "un mes",
    MM: "%d meses",
    y: "un año",
    yy: "%d años",
  },
});

const disablePastDates = (date) => {
  const firstDayOfMonth = moment().startOf("month");

  return date.isBefore(firstDayOfMonth, "day");
};

const disableDaysNotEnabled = (date, daysCapacidad) => {
  if (!daysCapacidad || daysCapacidad.length === 0) {
    return null;
  }

  return (
    !daysCapacidad.find(
      (item) =>
        moment.utc(item.date_capacidadate).format("YYYY-MM-DD") ===
        moment.utc(date).format("YYYY-MM-DD")
    )?.quantity_capacidate > 0
  );
};

const DateReserva = ({ value, setValue, daysCapacidad }) => {
  const valueData = value;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        value={moment(valueData)}
        sx={{
          width: "100%",
          "& .MuiButtonBase-root": {
            color: "black",
            fontWeight: "600",
            fontSize: "13px",
          },
        }}
        shouldDisableDate={(date) =>
          disablePastDates(date) || disableDaysNotEnabled(date, daysCapacidad)
        }
        disable
        onChange={(newValue) => {
          setValue(newValue.format("YYYY-MM-DD").toString());
        }}
        renderInput={(params) => (
          <TextField
            sx={{
              ".MuiInputBase-input": { padding: 0, fontWeight: "bold" },
            }}
            fullWidth
            required
            {...params}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DateReserva;
