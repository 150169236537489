import React, { useCallback, useEffect, useState } from "react";

import { Controller, useForm } from "react-hook-form";

import Input from "../../components/Forms/Input";

import toast from "react-hot-toast";

import { Close } from "@mui/icons-material";
import {
  Alert,
  Button,
  Chip,
  Collapse,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import {
  add_reserva,
  getTotDay,
  get_garzon,
  get_group,
} from "../../services/reservas.services";

import useClients from "../../hooks/useClients";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import LoadingPage from "../../components/LoadingPage";
import { formatPhoneNumber } from "../../functions/formatPhoneNumber";
import { nameCamarero } from "../../functions/nameCamarero";
import {
  getComunas,
  getHowDidyouKnowList,
} from "../../functions/utilidades.function";
import useSubsidiary from "../../hooks/useSubsidiary";
import { getUser } from "../../services/auth.services";
import { getv2_capacidad } from "../../services/capacidad.services";
import { get_clientes_id } from "../../services/clientes.services";
import { get_mesa_x_day } from "../../services/mesas.services";
import DateReserva from "./DateReserva";
import GroupReserva from "./GroupReserva";

dayjs.extend(utc);

const formatDate = (date) => {
  if (!date) {
    return null;
  }

  let dFormat = date.split("T");

  return dFormat[0];
};

const ErrorMsg = ({ wrong, setWrong, message }) => {
  return (
    <Collapse in={wrong}>
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setWrong(false);
            }}
          >
            <Close fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
        severity="error"
      >
        {message}
      </Alert>
    </Collapse>
  );
};

const TableIndicatorCapacity = (props) => {
  const { capacity, currentCapacity, availableCapacity } = props;

  return (
    <Table
      size="small"
      style={{
        border: "1px #eee solid",
        position: "sticky",
        top: 0,
        background: "white",
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell style={{ background: "#eee", textAlign: "center" }}>
            Capacidad Disponible
          </TableCell>
          <TableCell style={{ background: "#eee", textAlign: "center" }}>
            Capacidad Máxima
          </TableCell>
          <TableCell style={{ background: "#eee", textAlign: "center" }}>
            Capacidad Actual
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell style={{ textAlign: "center" }}>
            <Chip label={availableCapacity} color="success" />
          </TableCell>
          <TableCell style={{ textAlign: "center" }}>
            <Chip label={capacity} color="error" />
          </TableCell>
          <TableCell style={{ textAlign: "center" }}>
            <Chip label={currentCapacity} color="secondary" />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const FormularioReserva = ({
  finished,
  id_usuario,
  id_empsede,
  initialValues,
  insert = true,
  client = false,
}) => {
  const valuesForm = {
    id_reserva: "",
    id_usuario: "",
    id_cliente: "",
    region: "Valparaíso",
    comunas: "",
    fechareserva_reserva: "",
    horareserva_reserva: "",
    nombre_reserva: "",
    apellido_paterno_reserva: "",
    apellido_materno_reserva: "",
    telefono_reserva: "9",
    correo_reserva: "",
    direccion_reserva: "",
    pax_reserva: "",
    estado_reserva: "confirmado",
    pograma_reserva: "",
    motivo_reserva: "",
    financing_reserva: "",
    observacion_reserva: "",
    nombrecumpleanera_reserva: "",
    detalle_reserva: "",
    id_rservagrupa: "",
    clubname_reserva: "",
    institutename_reserva: "",
    asistentvalue_reserva: "",
    listotrosdh_reserva: "",
    listdh_reserva: "",
    howDidyouKnow_reserva: "",
    howDidyouKnowOtros_reserva: "",
    leadId: "",
  };

  const { control, reset, watch, setValue, handleSubmit } = useForm({
    defaultValues: valuesForm,
  });

  const [wrong, setWrong] = useState({ open: false, message: "" });
  const [dataMesas, setDataMesas] = useState([]);
  const [dataGarzon, setDataGarzon] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clasificacionCliente, setClasificacionCliente] = useState("");
  const [dataProgramas, setDataProgramas] = useState([]);
  const [dataGroup, setDataGroup] = useState([]);
  const [capacityDays, setCapacityDays] = useState([]);

  const comunas = getComunas();
  const howDidyouKnowList = getHowDidyouKnowList();

  const [openCumpleanera, setOpenCumpleanera] = useState(false);
  const [currentCapacity, setCurrentCapacity] = useState(0);
  const [isNew, setIsNew] = useState(false);

  const dataClients = useClients("telf", id_empsede) || [];

  const subsidaries = useSubsidiary(getUser()?.empresa?.id_emp);

  const dataSubsidiaries = subsidaries.data.map((item) => ({
    value: item?.id_empsede || "",
    label: item?.nom_empsede || "",
  }));

  const handleReservar = async (data, e) => {
    if (!data.fechareserva_reserva || data.fechareserva_reserva === "") {
      toast.error("La fecha de reserva es obligatorio (*)");

      setWrong({
        ...wrong,
        open: true,
        message: "La fecha de reserva es obligatorio",
      });
      return;
    }

    if (data.telefono_reserva.length < 9) {
      toast.error("El número telefónico tiene que tener 9 caracteres (*)");

      setWrong({
        ...wrong,
        open: true,
        message: "El número telefónico tiene que tener 9 caracteres",
      });
      return;
    }

    setLoading(true);
    setWrong(false);

    if (insert) {
      try {
        const { message } = await add_reserva(
          {
            ...data,
            id_usuario: id_usuario || 310,
          },
          Number(id_empsede)
        );

        //envia a la pantalla de confirmacion
        finished && finished(message, data.pax_reserva);

        reset({ ...initialValues, ...valuesForm });

        setLoading(false);
        setWrong(false);
        e.target.reset();
      } catch (err) {
        setLoading(false);
        setWrong(true);
      }
    } else {
      finished(data);
    }
  };

  const handleUpdateParams = async (data) => {
    /**
     * when is empty
     */
    if (!data) {
      setValue("id_cliente", "");
      setValue("correo_reserva", "");
      setValue("direccion_reserva", "");
      setValue("nombre_reserva", "");
      setValue("apellido_paterno_reserva", "");
      setValue("apellido_materno_reserva", "");
      setValue("telefono_reserva", "");
      setValue("region", "");
      setValue("comunas", "");
      setValue("leadId", "");

      return;
    }

    /**
     * when email not found
     */

    if (data.new) {
      setValue("telefono_reserva", data.labelNew);
      setValue("id_cliente", "");
      setValue("nombre_reserva", "");
      setValue("apellido_paterno_reserva", "");
      setValue("apellido_materno_reserva", "");
      setValue("correo_reserva", "");
      setValue("direccion_reserva", "");
      setValue("region", "");
      setValue("comunas", "");
      setValue("leadId", "");

      setIsNew(true);
      return;
    }

    /**
     * when search email exist
     */

    const response = await get_clientes_id(data.value);

    setClasificacionCliente(
      response?.clasificacion_cliente || "Sin calificación"
    );

    setValue("id_cliente", response.id_cliente);
    setValue("correo_reserva", response.correo_cliente);
    setValue("direccion_reserva", response.address_cliente);
    setValue("nombre_reserva", response.nombre_cliente);
    setValue("apellido_paterno_reserva", response.apepaterno_cliente);
    setValue("apellido_materno_reserva", response.apematerno_cliente);
    setValue("telefono_reserva", response.telf_cliente);
    setValue("region", response?.region_cliente ?? "");
    setValue("comunas", response?.comuna_cliente ?? "");

    setIsNew(false);
    // setValue("fechareserva_reserva", currentValues.fechareserva_reserva);
  };

  const handleGetDateToday = async (fecha) => {
    try {
      return await getTotDay(
        {
          fecha,
        },
        id_empsede
      );
    } catch (err) {}
  };

  const capacityMaxDays = useCallback(() => {
    return (
      capacityDays.find(
        (item) =>
          dayjs.utc(item.date_capacidadate).format("YYYY-MM-DD") ===
          watch("fechareserva_reserva")
      )?.quantity_capacidate || 0
    );
  }, [currentCapacity, watch("fechareserva_reserva")]);

  const availableCapacity = useCallback(() => {
    return (
      capacityMaxDays() +
      (initialValues?.pax_reserva || 0) -
      (currentCapacity + watch("pax_reserva"))
    );
  }, [capacityMaxDays(), currentCapacity, initialValues?.pax_reserva]);

  const nameProgram =
    getUser()?.empresa?.id_emp === 4 ? "Motivo de visita" : "Programas";
  const motiveBooking =
    getUser()?.empresa?.id_emp === 4 ? "Otro motivo" : "Otro programa";

  useEffect(() => {
    const handleFetchGroup = async () => {
      try {
        const response = await get_group();
        setDataGroup(response);
      } catch (err) {}
    };

    // SOLO PARA EL CLIENTE 4 (DOÑA ANITA)
    getUser()?.empresa.id_emp === 4 && !client && handleFetchGroup();
  }, []);

  /* handleObtainCapacidad */

  // useEffect(() => {
  //   const handleObtainCapacidad = async () => {
  //     try {
  //       const response = await get_capacidad(id_empsede);
  //       setCapacidad(response);
  //     } catch (err) {}
  //   };

  //   handleObtainCapacidad();

  //   return () => setCapacidad([]);
  // }, []);

  useEffect(() => {
    const handleObtainCapacityDays = async () => {
      try {
        setLoading(true);
        const response = await getv2_capacidad(id_empsede);
        setCapacityDays(response);
        setLoading(false);
      } catch (err) {}
    };

    handleObtainCapacityDays();

    return () => setCapacityDays([]);
  }, []);

  useEffect(() => {
    const handleObtainCurrentCapacity = async () => {
      try {
        if (watch("fechareserva_reserva")) {
          const capacityToday = await handleGetDateToday(
            watch("fechareserva_reserva")
          );

          const cCapacity = capacityToday.reduce((acc, el) => {
            return (
              acc + (el.estado_reserva !== "cancelado" ? el.pax_reserva : 0)
            );
          }, 0);

          setCurrentCapacity(cCapacity);
        }
      } catch (err) {}
    };

    handleObtainCurrentCapacity();
  }, [watch("fechareserva_reserva")]);

  const allComunas = comunas.find(
    (comuna) => comuna.region === watch("region")
  ) || {
    comunas: [],
  };

  const filter = createFilterOptions();

  useEffect(() => {
    if (watch("pograma_reserva") === "cumpleanos") {
      setOpenCumpleanera(true);
    } else {
      setOpenCumpleanera(false);
      setValue("nombrecumpleanera_reserva", "");
    }

    return () => setOpenCumpleanera(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("pograma_reserva")]);

  useEffect(() => {
    if (initialValues !== undefined) {
      reset({
        ...valuesForm,
        ...initialValues,
        nombre_reserva: initialValues?.nombre_cliente || "",
        apellido_paterno_reserva: initialValues?.apepaterno_cliente || "",
        apellido_materno_reserva: initialValues?.apematerno_cliente || "",
        correo_reserva: initialValues?.correo_cliente || "",
        direccion_reserva: initialValues?.address_cliente || "",
        region: initialValues?.region || [],
        comunas: initialValues?.comunas || [],
        telefono_reserva: initialValues?.telf_cliente || "",
        pograma_reserva: initialValues?.pograma_reserva || "",
        asistentvalue_reserva: initialValues?.asistentvalue_reserva || 0,
        observacion_reserva: initialValues?.observacion_reserva || "",
        pax_reserva: initialValues?.pax_reserva || 0,
        lead_id: initialValues?.lead_id || "",
      });
    }else{
      reset(valuesForm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  /* handleObtainMesa */

  useEffect(() => {
    const handleObtainMesa = async () => {
      try {
        let response = await get_mesa_x_day({
          fecha: watch("fechareserva_reserva"),
        });

        //quita las mesas que ya estan en uso pero si le pertenece a la reserva se queda en la lista
        let newReponse = response.filter(
          (x) => !x.existe_mesa || x.id_mesa === valuesForm.id_mesa
        );

        setDataMesas(
          newReponse.map((item) => ({
            value: item.id_mesa,
            label: `#${item.codigo_mesa} - (Capacidad mesa ${item.cantidad_mesa})`,
          }))
        );
      } catch (err) {}
    };

    const handleObtainGarzon = async () => {
      try {
        let response = await get_garzon();

        setDataGarzon(
          response.map((item) => ({
            value: item.id,
            label: item.label,
          }))
        );
      } catch (err) {}
    };

    if (!insert) {
      handleObtainMesa();
      handleObtainGarzon();
    }

    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let programas = [];

    if (getUser()?.empresa?.id_emp === 4) {
      programas = [
        {
          value: "cumpleanos",
          label: "Cumpleaños",
        },
        {
          value: "aniversario",
          label: "Aniversario",
        },
        {
          value: "recreacion",
          label: "Recreación",
        },
        {
          value: "Otros",
          label: "Otros",
        },
      ];
    } else {
      programas = [
        {
          value: "cumpleanos",
          label: "Cumpleaños",
        },
        {
          value: "aniversario",
          label: "Aniversario",
        },
        {
          value: "recreacion",
          label: "Recreación",
        },
        {
          value: "Otros",
          label: "Otros",
        },
      ];
    }

    setDataProgramas(programas);

    setIsNew(false);
  }, []);

  if (loading === true) {
    return <LoadingPage open={true} />;
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(handleReservar)}
        autoComplete="off"
        style={{ textAlign: "left" }}
      >
        <ErrorMsg
          wrong={wrong.open}
          setWrong={(el) => setWrong({ ...wrong, open: el })}
          message={wrong.message}
        />
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} lg={12}>
            <div
              style={{
                textAlign: "center",
              }}
            >
              Selecciona tu fecha de reserva
            </div>
            <Controller
              name="fechareserva_reserva"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <DateReserva
                  daysCapacidad={capacityDays}
                  value={formatDate(value)}
                  setValue={onChange}
                />
              )}
            />
          </Grid>
        </Grid>

        {watch("fechareserva_reserva") && (
          <>
            <Grid container spacing={2}>
              {!client && (
                <Grid item xs={12} sm={12} lg={12}>
                  <TableIndicatorCapacity
                    capacity={capacityMaxDays()}
                    currentCapacity={currentCapacity}
                    availableCapacity={
                      availableCapacity() >= 0 ? availableCapacity() : 0
                    }
                  />
                </Grid>
              )}

              {client && (
                <Grid item xs={12} sm={12} lg={12}>
                  <Alert severity="warning">
                    Capacidad disponible:{" "}
                    {availableCapacity() >= 0 ? availableCapacity() : 0}
                  </Alert>
                </Grid>
              )}

              <Grid item xs={12} sm={12} lg={12}>
                {!client ? (
                  <Autocomplete
                    value={watch("telefono_reserva")}
                    options={dataClients}
                    freeSolo
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      const isExisting = options.some(
                        (option) => inputValue === option.label
                      );

                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          value: inputValue,
                          label: `Agregar nuevo: "${inputValue}"`,
                          labelNew: inputValue,
                          new: true,
                        });

                        // setIsNew(true);
                        return filtered;
                      }

                      // setIsNew(false);
                      return filtered;
                    }}
                    // isOptionEqualToValue={(option, value) => true === true}
                    onChange={(event, newValue) => {
                      handleUpdateParams(newValue);
                      return;
                    }}
                    renderInput={(params) => (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          style={{ marginRight: "5px" }}
                        >
                          +56
                        </Typography>
                        <Input
                          inputProps={{
                            maxLength: 9,
                            minLength: 9,
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {formatPhoneNumber()}
                              </InputAdornment>
                            ),
                          }}
                          {...params}
                          type="tel"
                          title="Teléfono"
                          onInput={(e) => {
                            parseInt(e.target.value) > 0
                              ? (e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 9))
                              : parseInt(e.target.value);
                          }}
                          min={9}
                        />
                      </div>
                    )}
                  />
                ) : (
                  <Controller
                    name="telefono_reserva"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        inputProps={{
                          maxLength: 9,
                          minLength: 9,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {formatPhoneNumber()}
                            </InputAdornment>
                          ),
                        }}
                        required
                        value={value}
                        onChange={onChange}
                        type="tel"
                        title="Teléfono"
                        onInput={(e) => {
                          parseInt(e.target.value) > 0
                            ? (e.target.value = Math.max(
                                0,
                                parseInt(e.target.value)
                              )
                                .toString()
                                .slice(0, 9))
                            : parseInt(e.target.value);
                        }}
                        min={9}
                      />
                    )}
                  />
                )}
              </Grid>
              {!client && !isNew && watch("telefono_reserva").length > 1 && (
                <Grid item xs={12} sm={12} lg={12}>
                  <Input
                    readOnly
                    value={clasificacionCliente}
                    type="text"
                    title="Clasificación"
                  />
                </Grid>
              )}

              {!client && isNew && (
                <>
                  <Grid item xs={12} sm={6} lg={6}>
                    <Controller
                      name="howDidyouKnow_reserva"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          fullWidth
                          select
                          variant="outlined"
                          value={value}
                          onChange={onChange}
                          className={`InputDefault`}
                          SelectProps={{
                            native: true,
                          }}
                        >
                          <option value="">¿Cómo nos conocio?</option>
                          {howDidyouKnowList.map((option, idx) => (
                            <option key={idx} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={6}>
                    {watch("howDidyouKnow_reserva") === "Otro" && (
                      <Controller
                        name="howDidyouKnowOtros_reserva"
                        control={control}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <Input
                            required
                            value={value}
                            onChange={onChange}
                            type="text"
                            title="Otros"
                          />
                        )}
                      />
                    )}
                  </Grid>
                </>
              )}

              <Grid item xs={12} sm={12} lg={12}>
                <Controller
                  name="nombre_reserva"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      required
                      value={value}
                      onChange={onChange}
                      type="text"
                      title="Nombres"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <Controller
                  name="apellido_paterno_reserva"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      required
                      value={value}
                      onChange={onChange}
                      type="text"
                      title="Apellidos paterno"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <Controller
                  name="apellido_materno_reserva"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      required
                      value={value}
                      onChange={onChange}
                      type="text"
                      title="Apellidos materno"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Controller
                  name="correo_reserva"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      value={value}
                      onChange={onChange}
                      type="email"
                      title="Correo electrónico"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Controller
                  name="direccion_reserva"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      value={value}
                      onChange={onChange}
                      type="text"
                      title="Dirección completa"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <Controller
                  name="region"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      fullWidth
                      select
                      required
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                      className={`InputDefault`}
                      SelectProps={{
                        native: true,
                      }}
                    >
                      <option value="">[Region]</option>
                      {comunas.map((option, idx) => (
                        <option key={idx} value={option.region}>
                          {option.region}
                        </option>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <Controller
                  name="comunas"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      required
                      fullWidth
                      select
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                      className={`InputDefault`}
                      SelectProps={{
                        native: true,
                      }}
                    >
                      <option value="">[Comuna]</option>
                      {allComunas.comunas.map((option, idx) => (
                        <option key={idx} value={option}>
                          {option}
                        </option>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
            </Grid>

            <Divider style={{ marginTop: 8, marginBottom: 8 }} />

            <Grid container spacing={2}>
              {getUser()?.empresa.id_emp === 4 && !client && (
                <>
                  <Grid item xs={12} sm={7} lg={7}>
                    <Controller
                      name="id_rservagrupa"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <GroupReserva
                          data={dataGroup}
                          setData={setDataGroup}
                          value={
                            dataGroup.find(
                              (item) =>
                                item.id_rservagrupa === value &&
                                item.id_rservagrupa !== ""
                            )?.name_rservagrupa || ""
                          }
                          onChange={onChange}
                        />
                      )}
                    />
                  </Grid>
                  {(watch("id_rservagrupa") === 12 ||
                    watch("id_rservagrupa") === 18 ||
                    watch("id_rservagrupa") === 17) && (
                    <Grid item xs={12} sm={5} lg={5}>
                      <Controller
                        name="institutename_reserva"
                        control={control}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <Input
                            required
                            value={value}
                            onChange={onChange}
                            type="text"
                            title="Nombre de la institución"
                          />
                        )}
                      />
                    </Grid>
                  )}
                </>
              )}

              {getUser()?.empresa.id_emp === 4 &&
                watch("id_rservagrupa") === 12 && (
                  <>
                    <Grid item xs={12} sm={6} lg={6}>
                      <Controller
                        name="listdh_reserva"
                        control={control}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <TextField
                            fullWidth
                            select
                            variant="outlined"
                            value={value}
                            onChange={onChange}
                            className={`InputDefault`}
                            SelectProps={{
                              native: true,
                            }}
                          >
                            <option value="">[Lista]</option>
                            <option value={"cam"}>CAM</option>
                            <option value={"ucam"}>UCAM</option>
                            <option value={"cm"}>CM</option>
                            <option value={"taller"}>TALLER</option>
                            <option value={"unco"}>UNCO</option>
                            <option value={"jjvv"}>JJVV</option>
                            <option value={"otros"}>OTROS</option>
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                      {watch("listdh_reserva") === "otros" && (
                        <Controller
                          name="listotrosdh_reserva"
                          control={control}
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <Input
                              required
                              value={value}
                              onChange={onChange}
                              type="text"
                              title="Otros"
                            />
                          )}
                        />
                      )}
                    </Grid>
                  </>
                )}

              {getUser()?.empresa.id_emp === 4 && (
                <Grid item xs={12} sm={12} lg={12}>
                  <Controller
                    name="financing_reserva"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        fullWidth
                        select
                        variant="outlined"
                        value={value}
                        onChange={onChange}
                        className={`InputDefault`}
                        SelectProps={{
                          native: true,
                        }}
                      >
                        <option value="">[Financiamiento]</option>
                        <option value={"recurso-propio"}>Recurso propio</option>
                        <option value={"recurso-concursable"}>
                          Recurso concursable
                        </option>
                      </TextField>
                    )}
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={5} lg={5}>
                <Controller
                  name="pograma_reserva"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      required
                      fullWidth
                      value={value}
                      onChange={onChange}
                      type="select"
                      title={nameProgram}
                      options={dataProgramas}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={7} lg={7}>
                {watch("pograma_reserva") === "Otros" && (
                  <Controller
                    name="motivo_reserva"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        value={value}
                        onChange={onChange}
                        type="text"
                        title={motiveBooking}
                      />
                    )}
                  />
                )}

                {openCumpleanera && (
                  <Controller
                    name="nombrecumpleanera_reserva"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        value={value}
                        onChange={onChange}
                        type="text"
                        title="Nombre de la cumpleañera"
                      />
                    )}
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={6} lg={6}>
                <Controller
                  name="pax_reserva"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      required
                      value={value}
                      onChange={(e) => {
                        // const inputValue = parseInt(e.target.value);
                        // if (!isNaN(inputValue) && inputValue > 0) {
                        //   onChange(inputValue);
                        // }
                        const inputValue = Number(e.target.value);
                        onChange(Number(e.target.value) <= 0 ? 0 : inputValue);
                      }}
                      type="number"
                      title="Cantidad de asistentes"
                    />
                  )}
                />
              </Grid>
              {getUser()?.empresa.id_emp === 4 && !client && (
                <Grid item xs={12} sm={6} lg={6}>
                  <Controller
                    name="asistentvalue_reserva"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        required
                        value={value}
                        onChange={onChange}
                        type="number"
                        title="Valor de asistente"
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>

            <Divider style={{ marginTop: 8, marginBottom: 8 }} />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} lg={12}>
                <Controller
                  name="observacion_reserva"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      value={value}
                      onChange={onChange}
                      type="textarea"
                      title="¿Desea realizar alguna observación?"
                      rows={5}
                    />
                  )}
                />
              </Grid>
            </Grid>

            {!insert && <Divider style={{ marginTop: 8, marginBottom: 8 }} />}

            {!insert && (
              <>
                <Grid item xs={12} sm={12} lg={12}>
                  <Controller
                    name="id_mesa"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        value={value}
                        onChange={onChange}
                        type="select"
                        title="Mesas"
                        options={dataMesas}
                      />
                    )}
                  />
                </Grid>
              </>
            )}

            {!insert && (
              <>
                <Grid item xs={12} sm={12} lg={12} style={{ marginTop: 10 }}>
                  <Controller
                    name="id_garzon"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        value={value}
                        onChange={onChange}
                        type="select"
                        title={nameCamarero()}
                        options={dataGarzon}
                      />
                    )}
                  />
                </Grid>
              </>
            )}

            {!insert && (
              <Grid item xs={12} sm={12} lg={12} style={{ marginTop: 10 }}>
                <Controller
                  name="id_empsede"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      fullWidth
                      value={value}
                      onChange={onChange}
                      type="select"
                      title="Sede"
                      options={dataSubsidiaries}
                    />
                  )}
                />
              </Grid>
            )}

            {!insert && (
              <>
                <Grid item xs={12} sm={12} lg={12} style={{ marginTop: 10 }}>
                  <Controller
                    name="estado_reserva"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        fullWidth
                        select
                        variant="outlined"
                        value={value}
                        onChange={onChange}
                        className={`InputDefault`}
                        SelectProps={{
                          native: true,
                        }}
                      >
                        <option value={"pendiente"}>Pendiente</option>
                        <option value={"en espera"}>En espera</option>
                        <option value={"cancelado"}>Cancelado</option>
                        <option value={"confirmado"}>Confirmado</option>
                      </TextField>
                    )}
                  />
                </Grid>
              </>
            )}

            <Divider style={{ marginTop: 8, marginBottom: 8 }} />

            {client ? (
              <Button
                type="submit"
                disabled={availableCapacity() < 0}
                className="button-default"
                style={{
                  width: "30%",
                  marginTop: "20px",
                  display: "block",
                  margin: "auto",
                  opacity: availableCapacity() < 0 ? 0.5 : 1,
                }}
              >
                Aceptar
              </Button>
            ) : (
              <div className="block mt-5 text-center w-full">
                <Button
                  type="submit"
                  className="button-default w-60 m-auto"
                  disabled={availableCapacity() < 0}
                  style={{
                    opacity: availableCapacity() < 0 ? 0.5 : 1,
                  }}
                >
                  Aceptar
                </Button>

                {availableCapacity() < 0 && watch("id_reserva") === "" && (
                  <p
                    className="
                    text-center
                    text-red-500
                    text-xs
                    justify-center
                    mt-2
                   "
                  >
                    Capacidad no disponible
                  </p>
                )}
              </div>
            )}
          </>
        )}
      </form>
    </>
  );
};

export default FormularioReserva;
