export const formatMoney = (quantity = 0, moneda = "CLP") => {
  const userData = JSON.parse(localStorage.getItem("user_vm"));

  const moneda_empsede = userData?.empresa?.moneda_empsede || moneda;

  const formatCLP = new Intl.NumberFormat("es-CL", {
    currency: "CLP",
    style: "currency",
  });

  const formatPEN = new Intl.NumberFormat("es-PE", {
    currency: "PEN",
    style: "currency",
  });

  /**
   * if not exist moneda_empsede, return CLP
   */

  if (!moneda_empsede) {
    return formatCLP.format(quantity);
  }

  /**
   * if moneda_empsede is CLP, return CLP
   */

  if (moneda_empsede === "CLP") {
    return formatCLP.format(quantity);
  }

  /**
   * if moneda_empsede is PEN, return PEN
   */

  if (moneda_empsede === "PEN") {
    return formatPEN.format(quantity);
  }

  return quantity;
};

export const signalMoney = (type = "signal") => {
  const userData = JSON.parse(localStorage.getItem("user_vm"));

  const moneda_empsede = userData?.empresa?.moneda_empsede || "CLP";

  let signal = "";

  if (moneda_empsede === "CLP") {
    signal = type === "signal" ? "$" : moneda_empsede;
  }

  if (moneda_empsede === "PEN") {
    signal = type === "signal" ? "S/" : moneda_empsede;
  }

  return signal;
};

export const tipMoney = () => {
  const userData = JSON.parse(localStorage.getItem("user_vm"));

  const tip_amount = userData?.empresa?.moneda_empsede || "CLP";

  let tip = 0;

  if (tip_amount === "CLP") {
    tip = 10;
  }

  if (tip_amount === "PEN") {
    tip = 0;
  }

  return tip;
};
