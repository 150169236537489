/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Template from "../../components/Template";

import { checkToken, getUser } from "../../services/auth.services";
import { get_finished_today } from "../../services/pedidos.services";

import TablePedido from "./TablePedidosFinished";
import ContainerInput from "../../components/ContainerInput";
import LoadingPage from "../../components/LoadingPage";
import DialogApertura from "./Apertura";

const Orders = (props) => {
  let history = useHistory();
  let { socket } = props;

  const [loadPage, setLoadPage] = useState(false);
  const [openMesa, setOpenMesa] = useState(false);
  const [dataPedido, setDataPedido] = useState({
    load: false,
    err: false,
    data: [],
  });

  const handleGetPedido = async () => {
    try {
      const usu_id = getUser().id;
      setLoadPage(true);
      setDataPedido({ load: true, err: false, data: [] }); /* load */

      const response = await get_finished_today();
      const newResponse = response.filter((el) => el.usu_id === usu_id);

      setDataPedido({ load: false, err: false, data: newResponse }); /* data */
      setLoadPage(false);
    } catch (err) {
      setDataPedido({ load: false, err: true, data: [] });
      setLoadPage(false);
      console.log(err);
    }
  };

  // refresca todos los pedidos
  useEffect(() => {
    const handlerRefreshOrders = (elements) => {
      if (elements.id_empsede === getUser().empresa.id_empsede) {
        handleGetPedido();
      }
    };

    socket.on("server:refreshOrders", handlerRefreshOrders);
    return () => {
      socket.off("server:refreshOrders", handlerRefreshOrders);
    };
  }, []);

  //CHECK TOKEN
  useEffect(() => {
    if (!checkToken()) {
      history.push("/");
      return null;
    }

    handleGetPedido();
  }, []);

  return (
    <Template title="Pedidos">
      <LoadingPage open={loadPage} setOpen={setLoadPage} />

      <ContainerInput
        style={{
          marginTop: 20,
          marginBottom: 20,
          padding: "35px 10px",
          textAlign: "center",
        }}
      >
        <TablePedido dataPedido={dataPedido} />
      </ContainerInput>

      <DialogApertura
        open={openMesa}
        setOpen={setOpenMesa}
        handleGetPedido={handleGetPedido}
        socket={socket}
      />
    </Template>
  );
};

export default Orders;
