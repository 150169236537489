import * as React from "react";

import { Grid, Paper } from "@mui/material";

import { ArrowBack } from "@mui/icons-material";

const Keyboard = (props) => {
  let { handleAction } = props;

  const [number, setNumber] = React.useState(0);

  const handleChange = (value) => {
    let newNumber = 0;

    if (number === 0) {
      newNumber = value;
      setNumber(value);
      return;
    }

    newNumber = `${number}${value}`;
    setNumber(newNumber);
  };

  const handleBack = () => {
    if (
      number.length === undefined ||
      number.length === 1 ||
      number.length === 0
    ) {
      setNumber(0);
      return;
    }

    const newNumber = number.slice(0, -1);

    setNumber(newNumber);
  };

  const handleEnter = () => {
    handleAction(number);
    setNumber(0);
  };

  React.useEffect(() => {
    setNumber(0);
  }, []);

  return (
    <Grid className="KeyBoardNumber" container spacing={0}>
      <Grid item xs={12}>
        <Paper variant="outlined" className={`KeyBoardNumber__tot`}>
          {number}
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper
          variant="outlined"
          className={`KeyBoardNumber__numb`}
          onClick={() => handleChange(9)}
        >
          9
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper
          variant="outlined"
          className={`KeyBoardNumber__numb`}
          onClick={() => handleChange(8)}
        >
          8
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper
          variant="outlined"
          className={`KeyBoardNumber__numb`}
          onClick={() => handleChange(7)}
        >
          7
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper
          variant="outlined"
          className={`KeyBoardNumber__numb`}
          onClick={() => handleChange(6)}
        >
          6
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper
          variant="outlined"
          className={`KeyBoardNumber__numb`}
          onClick={() => handleChange(5)}
        >
          5
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper
          variant="outlined"
          className={`KeyBoardNumber__numb`}
          onClick={() => handleChange(4)}
        >
          4
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper
          variant="outlined"
          className={`KeyBoardNumber__numb`}
          onClick={() => handleChange(3)}
        >
          3
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper
          variant="outlined"
          className={`KeyBoardNumber__numb`}
          onClick={() => handleChange(2)}
        >
          2
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper
          variant="outlined"
          className={`KeyBoardNumber__numb`}
          onClick={() => handleChange(1)}
        >
          1
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper
          variant="outlined"
          className={`KeyBoardNumber__numb`}
          onClick={() => handleChange(0)}
        >
          0
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper
          variant="outlined"
          className={`KeyBoardNumber__numb`}
          onClick={() => handleChange(".")}
        >
          .
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper
          variant="outlined"
          className={`KeyBoardNumber__numb`}
          style={{ padding: "6px 5px" }}
          onClick={() => handleBack()}
        >
          <ArrowBack style={{ fontSize: 28 }} />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper
          variant="outlined"
          className={`KeyBoardNumber__numb`}
          style={{ fontWeight: "bold", background: "#eee" }}
          onClick={() => handleEnter()}
        >
          ENTER
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Keyboard;
