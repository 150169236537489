import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Template from "../../../components/Template";

import { checkToken, getUser } from "../../../services/auth.services";

import { motion } from "framer-motion";

import LOGO from "../../../assets/images/logo-logotipo.png";

const fadeIn = (direction, delay) => {
  return {
    hidden: {
      y: direction === "up" ? 80 : direction === "down" ? -80 : 0,
      opacity: 0,
      x: direction === "left" ? 80 : direction === "right" ? -80 : 0,
    },
    show: {
      y: 0,
      x: 0,
      opacity: 1,
      transition: {
        type: "tween",
        duration: 0.5,
        delay: delay,
        ease: [0.25, 0.25, 0.25, 0.75],
      },
    },
  };
};

const SettingsHome = (props) => {
  let history = useHistory();

  const dataUser = getUser().name || "";

  useEffect(() => {
    if (!checkToken()) {
      history.push("/");
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Template title="Configuración | Inicio">
      <motion.div
        variants={fadeIn("up", 0.5)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: true, amount: 0.6 }}
      >
        <img
          src={LOGO}
          alt="logo"
          className="w-40 mx-auto object-cover object-center"
        />
      </motion.div>

      <div className="flex items-center justify-center p-2 ">
        <motion.div
          variants={fadeIn("up", 0.8)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: true, amount: 0.6 }}
          className="text-center"
        >
          <h1 className="text-3xl font-semibold mb-4 text-purple-800 m-0 p-0">
            ¡Hola{" "}
            <span className="font-bold underline uppercase">{dataUser}</span>,
            bienvenido al panel de configuración de{" "}
            <span className="text-rose-500">Equipo Ubuntu</span> !
          </h1>
          <p className="text-md mb-8 text-pretty text-gray-700">
            Descubre un nuevo nivel de eficiencia y control con el panel de
            configuración de{" "}
            <span className="text-rose-500 font-medium	">Equipo Ubuntu</span>, tu
            plataforma de gestión empresarial.
          </p>
        </motion.div>
      </div>
    </Template>
  );
};

export default SettingsHome;
