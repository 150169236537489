export const groupBy = (arr) => {
  const initialValue = {};

  const group = arr.reduce((acc, cval) => {
    const myAttribute = cval.typecaja_pedido === 1 ? "Principal" : "Secundario";

    acc[myAttribute] = [...(acc[myAttribute] || []), cval];
    return acc;
  }, initialValue);

  const tmp = [];

  Object.entries(group).forEach(([key, value]) => {
    tmp.push({ label: key, rows: value });
  });

  return tmp;
};
