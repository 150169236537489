import { actionTypes } from "../actions/index";

let initialSelected = {
  prod_id: "",
  cat_id: "",
  marc_id: "",
  prod_descripcion: "",
  prod_nombre: "",
  prod_precio: "",
};

const initialState = {
  loading: false,
  error: false,
  dataProducto: { load: false, err: false, data: [] },
  dataSelected: initialSelected,
  openModalCRUD: false,
  openModalDELETE: false,
};

const reducerObject = (state, payload) => ({
  [actionTypes.getDataProducto]: {
    ...state,
    dataProducto: payload,
  },
  [actionTypes.selectedDataProductoEdit]: {
    ...state,
    dataSelected: { ...initialSelected, ...payload },
    openModalCRUD: true,
  },
  [actionTypes.selectedDataProductoDelete]: {
    ...state,
    dataSelected: { ...initialSelected, ...payload },
    openModalDELETE: true,
  },
  [actionTypes.updateDialogEdit]: {
    ...state,
    openModalCRUD: payload,
    dataSelected: initialSelected,
  },
  [actionTypes.updateDialogDelete]: {
    ...state,
    openModalDELETE: payload,
    dataSelected: initialSelected,
  },
  [actionTypes.updateLoad]: {
    ...state,
    loading: payload,
  },
});

export const reducer_producto = (state = initialState, action) => {
  if (reducerObject(state)[action.type]) {
    return reducerObject(state, action.payload)[action.type];
  } else {
    return state;
  }
};
