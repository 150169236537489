import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Alert, CircularProgress } from "@mui/material";

export default function HorizontalLinearStepper({
  steps = [],
  activeStep = 0,
  incrementStep = () => {},
  decrementStep = () => {},
  resetStep = () => {},
  loading = false,
}) {
  //   const [activeStep, setActiveStep] = React.useState(0);
  //   const [skipped, setSkipped] = React.useState(new Set());

  //   const isStepOptional = (step) => {
  //     return step === 1;
  //   };

  //   const isStepSkipped = (step) => {
  //     return skipped.has(step);
  //   };

  const handleNext = () => {
    incrementStep();
    // let newSkipped = skipped;
    // if (isStepSkipped(activeStep)) {
    //   newSkipped = new Set(newSkipped.values());
    //   newSkipped.delete(activeStep);
    // }
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // setSkipped(newSkipped);
  };

  const handleBack = () => {
    decrementStep();
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    resetStep();
    // setActiveStep(0);
  };

  //   const handleSkip = () => {
  //     if (!isStepOptional(activeStep)) {
  //       // You probably want to guard against something like this,
  //       // it should never occur unless someone's actively trying to break something.
  //       throw new Error("You can't skip a step that isn't optional.");
  //     }

  //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //     setSkipped((prevSkipped) => {
  //       const newSkipped = new Set(prevSkipped.values());
  //       newSkipped.add(activeStep);
  //       return newSkipped;
  //     });
  //   };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          //   if (isStepOptional(index)) {
          //     labelProps.optional = (
          //       <Typography variant="caption">Optional</Typography>
          //     );
          //   }

          //   if (isStepSkipped(index)) {
          //     stepProps.completed = false;
          //   }

          return (
            <Step key={index} {...stepProps}>
              <StepLabel {...labelProps}>{label.title}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <div className="p-8">
            <h1 className="text-3xl font-bold mb-4 text-slate-700">¡Felicidades!</h1>
            <p className="text-sm">
              Se ha completado la cotización, se acaba de crear una
              Planificación, puedes cerrar la ventana o presionar el botón de abajo para salir.
            </p>
          </div>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button variant="contained" color="success" onClick={handleReset}>
              Salir
            </Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Alert severity="info" className="mb-4 w-full mt-4">
            {steps[activeStep].description}
          </Alert>

          {loading ? (
            <div className="flex justify-center items-center p-8 gap-2 ">
              <CircularProgress color="inherit" size={20} /> Cargando...
            </div>
          ) : (
            <>{steps[activeStep].component()}</>
          )}

          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="secondary"
              variant="outlined"
              disabled={activeStep === 0 || loading}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Atrás
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {/* {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )} */}

            <Button
              disabled={loading}
              variant={"contained"}
              color={activeStep === steps.length - 1 ? "success" : "secondary"}
              onClick={() => handleNext(steps[activeStep]?.action || null)}
            >
              {activeStep === steps.length - 1 ? "Finalizar" : "Siguiente"}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}
