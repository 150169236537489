import * as React from "react";

import {
  AppBar,
  Button,
  //   TextField,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import ContainerInput from "../../../../../components/ContainerInput";
import TableProduct from "./Components/TableProduct";
import TableProductMain from "./Components/TableProductMain";

import { ArrowRightAlt, Refresh } from "@mui/icons-material";
import "./style.splitaccount.scss";

import { toast } from "react-hot-toast";
import { numberToLetter } from "../../../../../functions/numberToLetter";

const numCountAccount = [
  2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30,
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref || false} {...props} />;
});

const Accounts = (props) => {
  let {
    idx,
    value,
    handleAddProductOfMainProduct,
    handleRemoveProductofAccount,
    splitProducts,
    setSplitProducts,
  } = props;

  let newSplitProducts = [...splitProducts];

  const handleChange = (e) => {
    e.preventDefault();
    const { value } = e.target;

    newSplitProducts[idx].data = [...splitProducts[idx].data];
    newSplitProducts[idx].extraTextCodigo = value;

    setSplitProducts(newSplitProducts);
  };

  return (
    <ContainerInput style={{ marginBottom: 20 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} style={{ padding: "8px 20px", display: "inherit" }}>
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: 5 }}
            onClick={() => handleAddProductOfMainProduct(idx)}
          >
            <ArrowRightAlt />
          </Button>
          <Typography variant="h6">Cuenta N° {idx + 2} </Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <div style={{ width: "95%", margin: "auto" }}>
            <TextField
              value={`${value.codigo_pedido}`}
              label="Código de pedido"
              name="codigo_pedido"
              className={`InputDefault`}
              readOnly
              inputProps={{ maxLength: 20 }}
              style={{ width: "60%", margin: "auto" }}
            />
            <TextField
              value={`${value.extraTextCodigo || ""}`}
              label="Adicionar texto"
              name="extra_codigo_pedido"
              className={`InputDefault`}
              onChange={(e) => handleChange(e)}
              inputProps={{ maxLength: 10 }}
              style={{ width: "40%", margin: "auto" }}
            />
          </div>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "-10px" }}>
          <TableProduct
            dataProducts={value.data}
            handleRemoveProducto={(element) =>
              handleRemoveProductofAccount(element, idx)
            }
          />
        </Grid>
      </Grid>
    </ContainerInput>
  );
};

const DialogSplitAccount = (props) => {
  const { callback, dataProducts, infoPedido, open, setOpen } = props;

  const { codigo_pedido, id_reserva } = infoPedido.data;

  // estado principal de la lista de productos
  const [dataProductsMain, setDataProductsMain] = React.useState([]);

  // estado de todas las cuentas
  const [splitProducts, setSplitProducts] = React.useState([]);

  const [countAccount, setCountAccount] = React.useState(2);

  const [selectedProduct, setSelectedProduct] = React.useState([]);

  // colocar si es true o false cuando la cantidad de productos es igual a la cantidad del estado de la cuenta
  const isSame = dataProductsMain.length === dataProducts.data.length;

  // const dateToday = new Date();
  // const len = 8;
  // const pattern = "0";

  const handleClose = () => {
    setOpen(false);
  };

  const handleReset = () => {
    handleInitalSplit(countAccount);
  };

  const handleSelectedProduct = (value) => {
    setSelectedProduct(value);
  };

  /**
   * @description Funcion para agregar mas cuentas
   * @param {*} element
   */

  const handleChangeCountAccount = (element) => {
    const { target } = element;
    setCountAccount(target.value);

    handleInitalSplit(target.value);
  };

  /**
   * @description Funcion para iniciar el split de productos
   * @param {*} count
   */

  const handleInitalSplit = (count) => {
    const newCount = parseInt(count);
    // ESTADO DE PRODUCTO PRINCIPAL
    setDataProductsMain(dataProducts.data);

    // LIMPIAR ESTADO DE PRODUCTOS
    setSplitProducts([]);

    // AGREGAR MAS ESTADOS DE PRODUCTOS DE ACUERDO SE INICIO LA CUENTA
    for (let i = 1; i < newCount; i++) {
      setSplitProducts((prev) => [
        ...prev,
        {
          codigo_pedido: "",
          data: [],
        },
      ]);
    }
  };

  /**
   * @description Funcion para agregar nueva cuenta
   * @param {*} idx
   */

  const handleAddProductOfMainProduct = (idx) => {
    const itemAreSelected = selectedProduct.length;
    let newProducts = [];

    let newSplitProducts = [...splitProducts];
    let newDataProductsMain = [...dataProductsMain];

    const fecha = new Date();

    const formatoMap = {
      hhh: fecha.getHours(),
      hmm: fecha.getMinutes(),
      hss: fecha.getSeconds(),
      dd: fecha.getDate(),
      mm: fecha.getMonth() + 1,
      yy: parseInt(fecha.getFullYear().toString().slice(-2)),
      yyyy: fecha.getFullYear(),
    };

    let newCodigoPedido = `${codigo_pedido}(${numberToLetter(idx)}${
      formatoMap.hmm
    })`;

    if (itemAreSelected === 0) {
      toast.error("Debe seleccionar un producto");

      return;
    }

    if (newDataProductsMain.length === itemAreSelected) {
      toast.error("Tiene que quedar un producto en la cuenta N° 1");

      return;
    }

    /**
     * @description Agregar productos a la cuenta seleccionada
     * @param {*} idx
     */

    selectedProduct.forEach((value) => {
      const newSplit = dataProducts.data.find(
        (el) => el.id_plapedido === value
      );

      newProducts.push(newSplit);
    });

    newSplitProducts[idx].data = [...splitProducts[idx].data, ...newProducts];
    newSplitProducts[idx].codigo_pedido = newCodigoPedido;

    setSplitProducts(newSplitProducts);

    /**
     * @description Eliminar productos de la cuenta principal
     * @param {*} idx
     */

    let removeValFromIndex = [];

    selectedProduct.forEach((value) => {
      const newSplit = newDataProductsMain.findIndex(
        (el) => el.id_plapedido === value
      );

      // juntar todos los indices de los productos eliminados
      removeValFromIndex.push(newSplit);
    });

    removeValFromIndex
      .sort(function (a, b) {
        return b - a;
      })
      .forEach(function (index) {
        newDataProductsMain.splice(index, 1);
      });

    setDataProductsMain(newDataProductsMain);

    // resetear los productos seleccionados
    setSelectedProduct([]);
  };

  /**
   * @description Funcion para eliminar un producto de la cuenta y pasar a la principal
   * @param {*} idx
   * @param {*} params
   */

  const handleRemoveProductofAccount = (params, idx) => {
    let newSplitProducts = [...splitProducts];

    // eliminar producto de la cuenta seleccionada

    const indexProduct = newSplitProducts[idx].data.findIndex(
      (el) => el.id_plapedido === params.id_plapedido
    );

    newSplitProducts[idx].data.splice(indexProduct, 1);

    setSplitProducts(newSplitProducts);

    // agregamos el producto eliminar a la cuenta principal
    setDataProductsMain((prev) => [...prev, params]);
  };

  const handleSave = () => {
    if (isSame) {
      toast.error("No ha realizado una división de productos.");
      return;
    }

    handleClose();
    callback({ accountMain: dataProductsMain, accountSplit: splitProducts });
  };

  React.useEffect(() => {
    if (open) {
      handleInitalSplit(countAccount);
    }
  }, [open]);

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
      >
        <AppBar sx={{ position: "sticky", top: 0 }} className="AppHeader">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleClose(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Dividir la orden {codigo_pedido}
            </Typography>
            Para confirmar presiona
            <Button
              variant="contained"
              color="warning"
              style={{ marginLeft: 5 }}
              onClick={() => handleSave()}
            >
              Aceptar
            </Button>
          </Toolbar>
        </AppBar>
        <div className="container-split">
          <div className="container-split__left">
            <ContainerInput
              style={{
                textAlign: "left",
                height: "100%",
              }}
            >
              <div style={{ padding: 10 }}>
                <Typography variant="h6">Cuenta N° 1 </Typography>
              </div>
              <Divider />
              <TableProductMain
                selectedProduct={selectedProduct}
                handleSelected={handleSelectedProduct}
                dataProducts={dataProductsMain}
              />
            </ContainerInput>
          </div>
          <div className="container-split__right">
            <ContainerInput
              style={{
                marginBottom: 20,
                position: "sticky",
                top: 0,
                padding: "12px 20px",
                zIndex: 10,
              }}
            >
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={8}
                  style={{ alignItems: "center", display: "flex" }}
                >
                  Dividir en:
                  <select
                    name="countAccount"
                    style={{ marginLeft: "5px" }}
                    value={countAccount}
                    onChange={handleChangeCountAccount}
                    disabled={isSame === true ? false : true}
                  >
                    {numCountAccount.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </Grid>
                <Grid item xs={4} style={{ textAlign: "right" }}>
                  <Button
                    variant="contained"
                    color="error"
                    disabled={isSame}
                    onClick={() => handleReset()}
                  >
                    <Refresh />
                  </Button>
                </Grid>
              </Grid>
            </ContainerInput>

            {splitProducts.map((el, idx) => (
              <Accounts
                key={idx}
                idx={idx}
                value={el}
                handleAddProductOfMainProduct={handleAddProductOfMainProduct}
                handleRemoveProductofAccount={handleRemoveProductofAccount}
                setSplitProducts={setSplitProducts}
                splitProducts={splitProducts}
              />
            ))}
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default DialogSplitAccount;
