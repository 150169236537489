import axios from "axios";
import { config } from "../config";

import { axiosHeader } from "../functions/axiosHeader";

const { baseurl } = config;

/**
 * CAMPAIGN
 */

export const getTicketBenefit = (id, params = "") => {
  const url = `${baseurl}/api/ticketBenefit/benefit/${id}${params}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getTicketByUserId = (id, params = "") => {
  const url = `${baseurl}/api/ticketBenefit/user/${id}${params}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const addTicketBenefit = (params) => {
  const url = `${baseurl}/api/ticketBenefit/create`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const updateTicketBenefit = (id_ticketBenefit, params) => {
  const url = `${baseurl}/api/ticketBenefit/update/${id_ticketBenefit}`;

  return axios
    .put(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deleteTicketBenefit = (id_ticketBenefit) => {
  const url = `${baseurl}/api/ticketBenefit/delete/${id_ticketBenefit}`;

  return axios
    .delete(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getTicketBenefitId = (id) => {
  const url = `${baseurl}/api/ticketBenefit/${id}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};
