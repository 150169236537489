/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";

import "./style.count.scss";

import { Button } from "@mui/material";

export default function Counter({ addCount, valueTotAmount = 1, ...props }) {
  const [count, setCount] = useState(valueTotAmount);

  const increment = () => {
    let newCount = count + 1;
    setCount(newCount);
    addCount(newCount);
  };

  const decrement = () => {
    let newCount = count - 1;
    setCount(newCount < 1 ? 1 : newCount);
    addCount(newCount);
  };

  return (
    <div className="counter" {...props}>
      <Button
        variant="outlined"
        className="counter__decrement"
        onClick={() => decrement()}
        color="error"
      >
        -
      </Button>
      <Button
        variant="outlined"
        className="counter__increment"
        onClick={() => increment()}
        color="error"
      >
        +
      </Button>

      <div className="counter__number">{count}</div>
    </div>
  );
}
