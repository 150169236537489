import { Close } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";

import { useEffect, useState } from "react";
import InputDefault from "../../atomic/atoms/input.atoms";
import { getUser, setUser } from "../../services/auth.services";
import {
  get_empresa_id,
  get_empresa_sucursal,
} from "../../services/empresa.services";
import LoadingPage from "../LoadingPage";

const DialogSelectSucursal = ({ open, setOpen }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [dataCompanies, setDataCompanies] = useState([]);

  const isRoot = getUser()?.id_perfil === 16;

  const titleDialog = isRoot
    ? "Selecciona la empresa y la sucursal"
    : "Selecciona la sucursal";

  const { control, watch, handleSubmit, setValue } = useForm({
    defaultValues: {
      id_emp: "",
      id_empsede: "",
    },
  });

  const dataSucursales = watch("id_emp")
    ? dataCompanies.find((item) => item.value === parseInt(watch("id_emp")))
        ?.sucursales
    : [];

  const handleSave = async (data) => {
    setLoading(true);
    const response = await get_empresa_id(data?.id_empsede);
    setUser({ ...getUser(), empresa: response });
    setLoading(false);
    setOpen(false);

    // reload page
    history.go(0);
  };

  useEffect(() => {
    const handleGetCompanies = async () => {
      try {
        setLoad(true);
        const response = await get_empresa_sucursal();
        setLoad(false);
        setDataCompanies(response);
      } catch (err) {
        console.log(err);
      }
    };

    const handleOrchestrate = async () => {
      await handleGetCompanies();

      setValue("id_emp", getUser()?.empresa?.id_emp || "");
      setValue("id_empsede", getUser()?.empresa?.id_empsede || "");
    };

    if (open) {
      handleOrchestrate();
    }
  }, [open]);

  if (loading) {
    return <LoadingPage open={true} />;
  }

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth={"sm"}
      aria-describedby="dialog-select-sucursal"
    >
      <form onSubmit={handleSubmit(handleSave)} autoComplete="off">
        <DialogTitle>
          {titleDialog}
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {load && <LoadingPage open={true} />}

          <Grid container spacing={2}>
            {isRoot && (
              <Grid item xs={12} sm={6}>
                <Controller
                  name="id_emp"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputDefault
                      required
                      loading={false}
                      fullWidth
                      value={value}
                      onChange={onChange}
                      type="select"
                      title={"Empresa"}
                      options={dataCompanies}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <Controller
                name="id_empsede"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    required
                    disabled={!watch("id_emp")}
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    type="select"
                    title={"Sucursal"}
                    options={dataSucursales || []}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" variant="text" onClick={() => setOpen(false)}>
            Salir
          </Button>
          <Button color="secondary" variant="contained" type="submit">
            Aceptar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogSelectSucursal;
