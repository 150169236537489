import { TextField, Typography } from "@mui/material";

import "./style.components.scss";

const ItemInput = ({ label, value, ...props }) => {
  return (
    <div className="ItemInput">
      <Typography variant="inherit" component="span" className="span">
        {label}:
      </Typography>
      <TextField
        {...props}
        value={value === undefined || !value === "" ? "" : value}
        type="text"
        size="small"
        className={`InputDefault`}
        readOnly
        variant="outlined"
      />
    </div>
  );
};

export default ItemInput;
