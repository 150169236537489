import axios from "axios";
import { config } from "../config";

import { axiosHeader } from "../functions/axiosHeader";

const { baseurl } = config;

export const addTask = (params) => {
  const url = `${baseurl}/api/tasks/create`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const updateTask = (id, params) => {
  const url = `${baseurl}/api/tasks/update/${id}`;

  return axios
    .put(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const rescheduleTask = (id, params) => {
  const url = `${baseurl}/api/tasks/reschedule/${id}`;

  return axios
    .put(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const completedTask = (id, params) => {
  const url = `${baseurl}/api/tasks/completed/${id}`;

  return axios
    .put(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getTask = (params) => {
  const { name } = params || {};

  const url = `${baseurl}/api/tasks/get?name=${name || ""}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deleteTask = (id) => {
  const url = `${baseurl}/api/tasks/delete/${id}`;

  return axios
    .delete(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};
