import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";

import CardReport from "../closebox/CardReport";
import { useAppContext } from "./state.orderHistory";

import { formatMoney } from "../../functions/formatMoney";
import { nameCamarero } from "../../functions/nameCamarero";
import { getUser } from "../../services/auth.services";

const ContentIndicator = ({ data, isLoad }) => {
  const {
    bestSellingProduct,
    bestSellingCategory,
    bestSellingTable,
    bestSeller,
    bestSellerTip,
    payType,
    totSale,
    totApertura,
    totCover,
  } = data;

  console.log(totSale);
  
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={3}>
          <CardReport
            title={"Apertura"}
            dataReport={{
              data: totApertura.tot_apertura,
              load: isLoad,
              err: false,
            }}
          >
            <Typography variant="h5" component="div">
              {formatMoney(totApertura.tot_apertura || 0)}
            </Typography>
          </CardReport>
        </Grid>
        <Grid item xs={12} sm={3}>
          <CardReport
            title={"Total de propinas"}
            dataReport={{
              data: totSale,
              load: isLoad,
              err: false,
            }}
          >
            <Typography variant="h5" component="div">
              {formatMoney(
                totSale[0]?.tot_propina ? totSale[0]?.tot_propina : 0
              )}
            </Typography>
          </CardReport>
        </Grid>
        <Grid item xs={12} sm={3}>
          <CardReport
            title={"Total consumo"}
            dataReport={{
              data: totSale,
              load: isLoad,
              err: false,
            }}
          >
            <Typography variant="h5" component="div">
              {formatMoney(totSale[0]?.tot_consumo || 0)}
            </Typography>
          </CardReport>
        </Grid>
        <Grid item xs={12} sm={3}>
          <CardReport
            title={"Total consumo + entrada"}
            dataReport={{
              data: totSale,
              load: isLoad,
              err: false,
            }}
          >
            <Typography variant="h5" component="div">
              {formatMoney((totSale[0]?.tot_consumo || 0) + (totCover?.tot_cover * 7000))}
            </Typography>
          </CardReport>
        </Grid>
        <Grid item xs={12} sm={4}>
          <CardReport
            title={"Lo más consumido"}
            dataReport={{
              data: bestSellingProduct,
              load: isLoad,
              err: false,
            }}
          >
            <Grid container style={{ fontSize: "13px" }}>
              {bestSellingProduct.map((el, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <Grid item xs={10} style={{ textAlign: "left" }}>
                      {el.nombre_plato}
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: "right" }}>
                      {el.tot_plato}
                    </Grid>
                    <Grid item xs={12}>
                      <hr style={{ opacity: "0.3", margin: "2px" }} />
                    </Grid>
                  </React.Fragment>
                );
              })}
            </Grid>
          </CardReport>
        </Grid>
        <Grid item xs={12} sm={4}>
          <CardReport
            title={"Ventas por categoría"}
            dataReport={{
              data: bestSellingCategory,
              load: isLoad,
              err: false,
            }}
          >
            <Grid container style={{ fontSize: "13px" }}>
              {bestSellingCategory.map((el, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <Grid item xs={10} style={{ textAlign: "left" }}>
                      {el.nombre_categoriapla}
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: "right" }}>
                      {el.tot_plato}
                    </Grid>
                    <Grid item xs={12}>
                      <hr style={{ opacity: "0.3", margin: "2px" }} />
                    </Grid>
                  </React.Fragment>
                );
              })}
            </Grid>
          </CardReport>
        </Grid>
        <Grid item xs={12} sm={4}>
          <CardReport
            title={"Mesa más activa"}
            dataReport={{
              data: bestSellingTable,
              load: isLoad,
              err: false,
            }}
          >
            <Grid container style={{ fontSize: "13px" }}>
              {bestSellingTable.map((el, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <Grid item xs={10} style={{ textAlign: "left" }}>
                      #{el.codigo_mesa}
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: "right" }}>
                      {el.tot_mesa}
                    </Grid>
                    <Grid item xs={12}>
                      <hr style={{ opacity: "0.3", margin: "2px" }} />
                    </Grid>
                  </React.Fragment>
                );
              })}
            </Grid>
          </CardReport>
        </Grid>
        <Grid item xs={12} sm={4}>
          <CardReport
            title={`Monto de venta por ${nameCamarero(false)}`}
            dataReport={{
              data: bestSeller,
              load: isLoad,
              err: false,
            }}
          >
            <Grid container style={{ fontSize: "13px" }}>
              {bestSeller.map((el, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <Grid item xs={8} style={{ textAlign: "left" }}>
                      {el.usu_nombre}
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: "right" }}>
                      {formatMoney(el.pagoxconsumo_pedido)}
                    </Grid>
                    <Grid item xs={12}>
                      <hr style={{ opacity: "0.3", margin: "2px" }} />
                    </Grid>
                  </React.Fragment>
                );
              })}
            </Grid>
          </CardReport>
        </Grid>
        <Grid item xs={12} sm={4}>
          <CardReport
            title={`Monto de propina por ${nameCamarero(false)}`}
            dataReport={{
              data: bestSellerTip,
              load: isLoad,
              err: false,
            }}
          >
            <Grid container style={{ fontSize: "13px" }}>
              {bestSellerTip.map((el, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <Grid item xs={8} style={{ textAlign: "left" }}>
                      {el.usu_nombre}
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: "right" }}>
                      {formatMoney(el.pagopropina_pedido)}
                    </Grid>
                    <Grid item xs={12}>
                      <hr style={{ opacity: "0.3", margin: "2px" }} />
                    </Grid>
                  </React.Fragment>
                );
              })}
            </Grid>
          </CardReport>
        </Grid>
        <Grid item xs={12} sm={4}>
          <CardReport
            title={"Formas de pago"}
            dataReport={{
              data: payType,
              load: isLoad,
              err: false,
            }}
          >
            <Grid container style={{ fontSize: "13px" }}>
              {payType.map((el, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <Grid item xs={8} style={{ textAlign: "left" }}>
                      {el.tipodepago_pedido} ({el.tot_pago})
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: "right" }}>
                      {formatMoney(
                        el.pagototal_pedido ? el.pagototal_pedido : 0
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <hr style={{ opacity: "0.3", margin: "2px" }} />
                    </Grid>
                  </React.Fragment>
                );
              })}
            </Grid>
          </CardReport>
        </Grid>
        {getUser().empresa.id_emp === 2 && (
          <Grid item xs={12} sm={4}>
            <CardReport
              title={"Cover"}
              dataReport={{
                data: totCover,
                load: isLoad,
                err: false,
              }}
            >
              <Grid container style={{ fontSize: "13px" }}>
                <Grid item xs={8} style={{ textAlign: "left" }}>
                  Cantidad total
                </Grid>
                <Grid item xs={4} style={{ textAlign: "right" }}>
                  {totCover?.tot_cover || 0}
                </Grid>
                <Grid item xs={8} style={{ textAlign: "left" }}>
                  Descuento total
                </Grid>
                <Grid item xs={4} style={{ textAlign: "right" }}>
                  {formatMoney(totCover.descuenTot_cover || 0)}
                </Grid>
                <Grid item xs={12}>
                  <hr style={{ opacity: "0.3", margin: "2px" }} />
                </Grid>
              </Grid>
            </CardReport>
          </Grid>
        )}
      </Grid>
    </>
  );
};

const HistoryModal = () => {
  const state = useAppContext();

  return (
    <Dialog
      open={state.openModal}
      onClose={() => state.handleModal()}
      fullWidth
      maxWidth={"md"}
    >
      <DialogTitle>
        Datos de la caja #{state.selected.id_caja}
        <IconButton
          aria-label="close"
          onClick={() => state.handleModal()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ContentIndicator
          data={state.dataIndicators}
          isLoad={state.idLoadDataIndicator}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="inherit"
          variant="text"
          onClick={() => {
            state.handleModal();
          }}
        >
          Salir
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default HistoryModal;
