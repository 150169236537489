import { Grid, Typography, Paper, Skeleton } from "@mui/material";
import NotFound from "../../../../atomic/organisms/NotFound.organisms";

import ContainerInput from "../../../../components/ContainerInput";

const CategoryProduct = (props) => {
  const { data, err, load } = props.data;

  const { fullProduct, handleSelectedCategory } = props;

  /*
    SI NO ENCUENTRA NINGUN REGISTRO O ESTA LLENO TODOS LOS PRODUCTOS DESAPARECE
  */

  if (fullProduct === "notfound" || fullProduct === "full") {
    return null;
  }

  if (load) {
    return (
      <ContainerInput
        style={{
          marginTop: 10,
          marginBottom: 20,
          padding: "25px 10px",
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={1}
          style={{ marginTop: 10 }}
        >
          <Grid item xs={12} sm={4} md={3} lg={3} zeroMinWidth>
            <Skeleton animation="wave" height={100} />
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={3} zeroMinWidth>
            <Skeleton animation="wave" height={100} />
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={3} zeroMinWidth>
            <Skeleton animation="wave" height={100} />
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={3} zeroMinWidth>
            <Skeleton animation="wave" height={100} />
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={3} zeroMinWidth>
            <Skeleton animation="wave" height={100} />
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={3} zeroMinWidth>
            <Skeleton animation="wave" height={100} />
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={3} zeroMinWidth>
            <Skeleton animation="wave" height={100} />
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={3} zeroMinWidth>
            <Skeleton animation="wave" height={100} />
          </Grid>
        </Grid>
      </ContainerInput>
    );
  }

  if (err) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <NotFound title="¡Ocurrió un problema!" />
      </div>
    );
  }

  if (data.length === 0 && !load) {
    return <NotFound texto="No se encontró registros." />;
  }

  return (
    <ContainerInput
      style={{
        marginTop: 10,
        marginBottom: 20,
        padding: "15px 10px",
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={1}
        style={{ marginTop: 10 }}
      >
        {data.map((el, idx) => (
          <Grid key={idx} item xs={12} sm={4} md={3} lg={3} zeroMinWidth>
            <Paper
              className="cntCategory"
              variant="outlined"
              onClick={() => handleSelectedCategory(el.id_categoriapla)}
            >
              {el.nombre_categoriapla}
            </Paper>
          </Grid>
        ))}
      </Grid>
    </ContainerInput>
  );
};

export default CategoryProduct;
