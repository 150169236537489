import { Close } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";

import InputDefault from "../../atomic/atoms/input.atoms";

const dataCompanies = [
  {
    value: 2,
    label: "Verde Mostaza",
    name: "verdemostaza",
    sucursales: [
      {
        value: 2,
        label: "Villa Alemana",
        name: "aromos",
      },
      {
        value: 3,
        label: "Valparaíso",
        name: "santosossa",
      },
    ],
  },
];

const DialogSubsidiarySelect = ({ id_emp, open, setOpen }) => {
  const history = useHistory();

  const { control, watch, handleSubmit } = useForm({
    defaultValues: {
      id_emp,
      id_empsede: 0,
    },
  });

  const dataSucursales = id_emp
    ? dataCompanies.find((item) => item.value === id_emp)?.sucursales
    : [];

  const handleSave = async (data) => {
    const nameCompany = dataCompanies.find(
      (item) => item.value === parseInt(data.id_emp)
    ).name;

    const nameSubsidiary = dataCompanies
      .find((item) => item.value === parseInt(data.id_emp))
      ?.sucursales.find(
        (item) => item.value === parseInt(data.id_empsede)
      ).name;

    history.push(`/reservar/${nameCompany}?name=${nameSubsidiary}`);

    // reload page
    history.go(0);
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth={"sm"}
      aria-describedby="dialog-select-sucursal"
    >
      <form onSubmit={handleSubmit(handleSave)} autoComplete="off">
        <DialogTitle>
          Selecciona la sucursal
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Controller
                name="id_empsede"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputDefault
                    required
                    disabled={!watch("id_emp")}
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    type="select"
                    title={"Sucursal"}
                    options={dataSucursales || []}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" variant="text" onClick={() => setOpen(false)}>
            Salir
          </Button>
          <Button color="secondary" variant="contained" type="submit">
            Aceptar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogSubsidiarySelect;
