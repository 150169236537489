import React from "react";

import { TextField } from "@mui/material";

const InputDefault = ({ title, value, options, type, ...props }) => {
  if (type === "textarea") {
    return (
      <TextField
        style={{ padding: "0px !important", width: "100%" }}
        fullWidth
        label={title}
        variant="outlined"
        multiline
        className={`InputDefault `}
        value={value}
        {...props}
      />
    );
  }

  if (type === "select") {
    return (
      <TextField
        select
        // label={title}
        variant="outlined"
        value={value}
        className={`InputDefault`}
        SelectProps={{
          native: true,
        }}
        
        {...props}
      >
        <option value="">[{title}]</option>
        {options.map((option, idx) => (
          <option key={idx} value={option.value}>
            {option.label}
          </option>
        ))}
      </TextField>
    );
  }

  return (
    <TextField
      {...props}
      fullWidth
      label={title}
      variant="outlined"
      className={`InputDefault`}
      value={value}
      type={type}
    />
  );
};

export default InputDefault;
