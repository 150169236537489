import React, { useEffect, useState } from "react";

import ContainerInput from "../../../components/ContainerInput";

import { Add, Delete, Edit, Settings } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import { get_plato_secondary } from "../../../services/plato.services";

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

import NotFound from "../../../atomic/organisms/NotFound.organisms";
import { formatMoney } from "../../../functions/formatMoney";
import CrudDeleteProduct from "../producto/Dialog/ConfirmDelete/CrudDeleteProduct";
import CrudFormPlatoSecondary from "../producto/Dialog/CrudForm/CrudFormPlatoSecondary";

// import Moment from "react-moment";
// import "moment/locale/es";

const ListProduct = (props) => {
  const { dataPlato, onOpenProduct, setDataSelected, onOpenProductDelete } =
    props;

  const rows = dataPlato.data;
  const loading = dataPlato.load;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleEdit = (value) => {
    onOpenProduct(true);
    setDataSelected(value);
  };

  const handleDelete = (value) => {
    onOpenProductDelete(true);
    setDataSelected(value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (loading) {
    return (
      <h3 className="text-center text-gray-600 text-xl pt-20 pb-20 animate-pulse">
        Cargando...
      </h3>
    );
  }

  if (rows.length === 0) {
    return <NotFound texto="No se encontró registros." />;
  }

  return (
    <>
      <TableContainer sx={{ maxHeight: 550 }}>
        <Table stickyHeader aria-label="sticky table" size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell
                align={"left"}
                style={{ minWidth: 150, fontWeight: "bold" }}
              >
                Nombre
              </TableCell>
              <TableCell
                align={"left"}
                style={{ minWidth: 100, fontWeight: "bold" }}
              >
                Precio
              </TableCell>
              <TableCell
                colSpan={2}
                align={"center"}
                style={{ width: 90, fontSize: 21, color: "#5c5c5c" }}
              >
                <Settings />
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                  <TableCell align={"left"}>{row.nombre_plato}</TableCell>
                  <TableCell align={"left"}>
                    {formatMoney(row.precio_plato)}
                  </TableCell>
                  <TableCell align={"center"} width={35}>
                    <IconButton color="warning" onClick={() => handleEdit(row)}>
                      <Edit />
                    </IconButton>
                  </TableCell>
                  <TableCell align={"center"} width={35}>
                    <IconButton color="error" onClick={() => handleDelete(row)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage="Filas por página"
        rowsPerPageOptions={[10, 25, 100, { value: -1, label: "Todo" }]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

const ProductMenu = ({
  categorySelected,
  onRefreshDataCategory,
  dataSelected,
  setDataSelected,
}) => {
  const [openCrudForm, setOpenCrudForm] = useState(false);
  const [openCrudFormDelete, setOpenCrudFormDelete] = useState(false);

  const [dataPlato, setDataPlato] = useState({
    data: [],
    load: false,
  });

  const onOpenProduct = (data) => {
    setOpenCrudForm(data);
  };

  const handleOpenDeleteProduct = (data) => {
    setOpenCrudFormDelete(data);
  };

  const isActiveButton = !categorySelected;

  async function obtainDataPlato() {
    const id = categorySelected?.id_categoriapla;
    if (!id) return;

    try {
      setDataPlato({ data: [], load: true });
      const data = await get_plato_secondary(id);
      setDataPlato({ data, load: false });
    } catch (err) {
      console.log(err);
    }
  }

  async function onRefresh() {
    await onRefreshDataCategory();
    await obtainDataPlato();
  }

  useEffect(() => {
    obtainDataPlato();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorySelected]);

  return (
    <>
      <Grid container spacing={2} style={{ marginBottom: 20 }}>
        <Grid
          item
          xs={12}
          sm={6}
          lg={6}
          style={{
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          {categorySelected && (
            <>
              <h3 className="text-purple-800 font-medium text-lg uppercase p-0 m-0">
                {categorySelected.nombre_categoriapla}
              </h3>
            </>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          lg={6}
          style={{
            textAlign: "right",
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            disabled={isActiveButton}
            style={{
              opacity: `${isActiveButton ? "0.5" : "1"} !important`,
            }}
            onClick={() => {
              onOpenProduct(true);
              setDataSelected({
                id_categoriapla: categorySelected.id_categoriapla,
              });
            }}
          >
            Agregar <Add />
          </Button>
        </Grid>
      </Grid>
      <ContainerInput
        style={{
          marginTop: 10,
          marginBottom: 10,
          textAlign: "center",
          padding: 0,
        }}
      >
        <ListProduct
          setDataSelected={setDataSelected}
          dataPlato={dataPlato}
          onOpenProduct={onOpenProduct}
          onOpenProductDelete={handleOpenDeleteProduct}
        />
      </ContainerInput>

      <CrudFormPlatoSecondary
        open={openCrudForm}
        setOpen={setOpenCrudForm}
        dataSelected={dataSelected}
        categorySelected={categorySelected}
        onRefresh={onRefresh}
      />

      <CrudDeleteProduct
        open={openCrudFormDelete}
        setOpen={handleOpenDeleteProduct}
        dataSelected={dataSelected}
        callBack={onRefresh}
      />
    </>
  );
};

export default ProductMenu;
