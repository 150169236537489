/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import { Grid } from "@mui/material/";

import Input from "../../../../../components/Forms/Input";

import { Controller, useForm } from "react-hook-form";

import { Close } from "@mui/icons-material";

import toast from "react-hot-toast";

import {
  add_plato,
  update_plato,
} from "../../../../../services/plato.services";

const FormPlatoSecondary = (
  dataSelected,
  categorySelected,
  handleAdd,
  handleModal,
  loading
) => {
  const initialState = {
    descripcion_plato: "",
    id_categoriapla: null,
    nombre_plato: "",
    precio_plato: 0,
  };

  const { control, reset, handleSubmit } = useForm({
    defaultValues: initialState,
  });

  const FormPlato = () => {
    if (loading) {
      return (
        <h3 className="text-center text-gray-600 text-xl pt-5 pb-5 animate-bounce">
          Cargando...
        </h3>
      );
    }

    return (
      <form
        onSubmit={handleSubmit(handleAdd)}
        autoComplete="off"
        className="pt-2"
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} lg={12}>
            <Controller
              name="nombre_plato"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Input
                  required
                  value={value}
                  onChange={onChange}
                  type="text"
                  title="Nombre"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={12}>
            <Controller
              name="precio_plato"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Input
                  required
                  value={value}
                  onChange={onChange}
                  type="number"
                  title="Precio"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={12}>
            <Controller
              name="descripcion_plato"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Input
                  value={value}
                  onChange={onChange}
                  type="textarea"
                  title="Descripción"
                  minRows={3}
                />
              )}
            />
          </Grid>
        </Grid>
        <input type="submit" hidden />
      </form>
    );
  };

  return {
    Reset: (data) => {
      if (!data) {
        return reset({ ...initialState, data });
      }

      reset(data);
    },
    Submit: handleSubmit,
    Form: (
      <>
        <DialogTitle>
          {dataSelected?.id_plato
            ? "Editar"
            : `Agregar en ${categorySelected?.nombre_categoriapla}`}
          <IconButton
            aria-label="close"
            onClick={() => handleModal(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormPlato />
        </DialogContent>
        <DialogActions>
          <Button
            color="info"
            variant="text"
            disabled={loading}
            onClick={() => {
              handleModal(false);
            }}
          >
            Salir
          </Button>
          <Button
            type="submit"
            color="success"
            variant="contained"
            disabled={loading}
            onClick={handleSubmit(handleAdd)}
          >
            Aceptar
          </Button>
        </DialogActions>
      </>
    ),
  };
};

const CrudFormPlatoSecondary = ({
  open = false,
  setOpen = () => {},
  dataSelected,
  categorySelected,
  onRefresh = () => {},
}) => {
  const [loading, setLoading] = useState(false);

  const handleModal = (value) => {
    setOpen(value);
  };

  const handleAdd = async (data) => {
    try {
      setLoading(true);

      if (data?.id_plato) {
        await update_plato(data);

        toast.success("Plato modificado correctamente");

        Reset();
        await onRefresh();

        handleModal(false);
        setLoading(false);

        return;
      }

      await add_plato(
        { id_categoriapla: categorySelected?.id_categoriapla, ...data },
        2
      );

      toast.success("Plato creada correctamente");

      Reset();
      await onRefresh();

      setLoading(false);
      handleModal(false);
    } catch (err) {
      toast.error("Hubo un error al crear el plato");
      setLoading(false);
    }
  };

  const { Form, Reset } = FormPlatoSecondary(
    dataSelected,
    categorySelected,
    handleAdd,
    handleModal,
    loading
  );

  useEffect(() => {
    open && Reset(dataSelected);
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => handleModal(false)}
        fullWidth
        maxWidth={"sm"}
      >
        {Form}
      </Dialog>
    </>
  );
};

export default CrudFormPlatoSecondary;
