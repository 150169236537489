import {
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
import * as React from "react";
import { useAppContext } from "./state.consumption";

import dayjs from "dayjs";
import "moment/locale/es";
import NotFound from "../../atomic/organisms/NotFound.organisms";
import ContainerInput from "../../components/ContainerInput";

const CustomTable = ({ rows, isLoad, sortRecords, onChangeSort }) => {
  const styleFirstColumn = {
    fontSize: 12,
    position: "sticky",
    left: 0,
    background: "#fafafd",
    borderRight: "1px #c5bbdc solid",
  };

  const styleSecondColumn = {
    fontSize: 12,
    background: "#fafafd",
    borderRight: "1px #c5bbdc solid",
    textAlign: "center",
  };

  if (isLoad) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress color="inherit" /> <br />
        <Typography component="h5" variant="h5" style={{ textAlign: "center" }}>
          Cargando...
        </Typography>
      </div>
    );
  }

  if (rows.length === 0) {
    return <NotFound texto="No se encontró registros." />;
  }
  
  return (
    <>
      <TableContainer sx={{ maxHeight: 650 }}>
        <Table stickyHeader aria-label="sticky table" size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell align={"left"} style={{ minWidth: 200 }}></TableCell>
              <TableCell align={"center"} style={{ minWidth: 70 }}>
                {sortRecords === "desc" ? (
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => onChangeSort("asc")}
                  >
                    <ArrowUpward />
                  </IconButton>
                ) : (
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => onChangeSort("desc")}
                  >
                    <ArrowDownward />
                  </IconButton>
                )}
              </TableCell>
              {rows.head.map((row, idx) => (
                <TableCell
                  key={idx}
                  align={"left"}
                  style={{
                    minWidth: 70,
                    fontSize: 12,
                    color: dayjs().format("DD/MM/YYYY") === row && "red",
                  }}
                >
                  {row}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.body.map((row, idx) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                <TableCell align={"left"} style={styleFirstColumn}>
                  {row.nombre_plato}
                </TableCell>
                <TableCell align={"left"} style={styleSecondColumn}>
                  {row.cantidad_plato}{" "}
                  {row.cantidad_plato_percent > 0 &&
                    `(${row.cantidad_plato_percent.toFixed(2)}%)`}
                </TableCell>

                {row.data.map((data, idx) => (
                  <TableCell key={idx} align={"right"} style={{ fontSize: 12 }}>
                    {data.tot_consumo}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter
            style={{
              position: "sticky",
              bottom: 0,
              borderTop: "1px red solid",
              backgroundColor: "#fbfafd",
            }}
          >
            <TableRow>
              <TableCell align={"left"} style={styleFirstColumn}>
                Total
              </TableCell>
              <TableCell align={"left"} style={styleSecondColumn}>
                {rows.footer?.cantidad_plato}
              </TableCell>
              {rows.footer.data.map((data, idx) => (
                <TableCell key={idx} align={"right"} style={{ fontSize: 12 }}>
                  {data?.tot_consumo}
                </TableCell>
              ))}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

const ConsumptionTable = () => {
  const state = useAppContext();

  // const state = useAppContext();

  // const handleChange = (event, newValue) => {
  //   state.changeTab(newValue);
  // };

  return (
    <ContainerInput
      style={{
        marginTop: 20,
        marginBottom: 20,
        padding: "0px",
        textAlign: "center",
        borderRadius: "0px",
      }}
    >
      <CustomTable
        rows={state.data}
        isLoad={state.isLoadData}
        sortRecords={state.sortValue}
        onChangeSort={state.changeSort}
      />
    </ContainerInput>
  );
};

export default ConsumptionTable;
