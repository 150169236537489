import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import * as React from "react";

import { add_sucursal, update_sucursal } from "../../services/sucursal.services";
import { getComunas } from "../../functions/utilidades.function";
import { get_all_compaines } from "../../services/empresa.services";

import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import { Alert, Collapse, Grid } from "@mui/material";

import Input from "../../components/Forms/Input";

import { Controller, useForm } from "react-hook-form";

import { Close } from "@mui/icons-material";

import toast from "react-hot-toast";

import LoadingPage from "../../components/LoadingPage";

const ErrorMsg = ({ wrong, setWrong, message }) => {
  return (
    <Collapse in={wrong}>
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setWrong(false);
            }}
          >
            <Close fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
        severity="error"
      >
        {message}
      </Alert>
    </Collapse>
  );
};

const DialogCrudSucursal = ({
  open,
  setOpen,
  data,
  setData,
  handleGetSucursales
}) => {
  const initialValues = {
    id_emp: "",
    nom_empsede: "",
    telf_empsede: "",
    wsp_empsede: "",
    ubication_empsede: "",
    region_empsede: "",
    comuna_empsede: "",
    alias_empsede: "",
    comision_empsede: ""
  };

  const { control, reset, handleSubmit, watch } = useForm({
    defaultValues: initialValues,
  });

  const [wrong, setWrong] = React.useState({ open: false, message: "" });
  const [openLoad, setOpenLoad] = React.useState(false);
  const [dataRegiones, setDataRegiones] = React.useState([]);
  const [regiones, setRegiones] = React.useState([]);
  const [comunas, setComunas] = React.useState([]);
  const [companies, setCompanies] = React.useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddSucursal = async (data) => {
    try {
      setOpenLoad(true);
      let response;

      if (data.id_empsede) {
        response = await update_sucursal(data, data.id_empsede);
        if (response.affectedRows === 1) {
          toast.success("Sucursal modificada correctamente");
          setOpen(false);
          reset(initialValues);
        } else {
          toast.error("No se pudo modificar la sucursal");
          setOpen(false);
          reset(initialValues);
        }
      } else {
        response = await add_sucursal(data);

        if (response.affectedRows === 1) {
          toast.success("Sucursal creada correctamente");
          setOpen(false);
          reset(initialValues);
        } else if (response.affectedRows === 2) {
          toast.error("El nombre de la sucursal no se encuentra disponible");
        } else {
          toast.error("No se pudo crear la sucursal");
          setOpen(false);
          reset(initialValues);
        }
      }

      setOpenLoad(false);
      handleGetSucursales();
    } catch (err) {
      setOpenLoad(false);

      console.log(err.message);
      toast.error("Hubo un error al crear la sucursal");
      setOpen(false);
    }
  };

  const handleGetRegiones = async () => {
    const regiones = await getComunas();
    setRegiones(regiones.map((el) => ({
      label: el.region,
      value: el.region,
    })));
    setDataRegiones(regiones);
  }

  const handleGetEmpresas = async () => {
    const response = await get_all_compaines();
    setCompanies(response.map((el) => ({
      label: el.nom_emp, 
      value: el.id_emp,
    })));
  }


  React.useEffect(() => {
    handleGetRegiones();
    handleGetEmpresas();
    if (open) {
       if (data.id_empsede === "") {
         reset(initialValues);
       }

      reset({ ...data, initialValues });
    } else {
      reset(initialValues);
      setData({});
    }
  }, [open]);

  const region_empsede_watch = watch("region_empsede");

  React.useEffect(() => {
    if (region_empsede_watch) {
      const region = dataRegiones.find((reg) => reg.region === region_empsede_watch);
      setComunas(region ? region.comunas.map((comuna) => ({ label: comuna, value: comuna })) : []);
    } else {
      setComunas([]);
    }
  }, [region_empsede_watch, regiones]);
  

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"sm"}>
        <LoadingPage open={openLoad} />
        <form onSubmit={handleSubmit(handleAddSucursal)} autoComplete="off">
          <DialogTitle>
            {data.id_empsede ? "Modificar sede" : "Nueva sede"}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <br />
            <ErrorMsg
              wrong={wrong.open}
              setWrong={(el) => setWrong({ ...wrong, open: el })}
              message={wrong.message}
            />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} lg={12}>
                <Controller
                  name="id_emp"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      select
                      required
                      value={value}
                      onChange={onChange}
                      options={companies}
                      type="select"
                      title="Empresa"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Controller
                  name="nom_empsede"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      required
                      value={value}
                      onChange={onChange}
                      type="text"
                      title="Nombre"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={6}>
                <Controller
                  name="telf_empsede"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      required
                      value={value}
                      onChange={onChange}
                      type="text"
                      title="Telefono"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={6}>
                <Controller
                  name="wsp_empsede"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      value={value}
                      onChange={onChange}
                      type="text"
                      title="Whatsapp"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={12} style={{ display: "flex" }}>
                <Controller
                  name="ubication_empsede"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      value={value}
                      onChange={onChange}
                      type="text"
                      title="Ubicación"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={6}>
                <Controller
                  name="region_empsede"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      select
                      required
                      value={value}
                      onChange={onChange}
                      options={regiones}
                      type="select"
                      title="Región"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={6}>
                <Controller
                  name="comuna_empsede"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      select
                      required
                      value={value}
                      onChange={onChange}
                      options={comunas}
                      type="select"
                      title="Comuna"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Controller
                  name="alias_empsede"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      required
                      value={value}
                      onChange={onChange}
                      type="text"
                      title="Alias"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Controller
                  name="comision_empsede"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      required
                      value={value}
                      onChange={onChange}
                      type="number"
                      title="Meta de Comision"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="info"
              variant="text"
              onClick={() => {
                setOpen(false);
              }}
            >
              Salir
            </Button>
            <Button type="submit" color="success" variant="contained">
              Agregar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default DialogCrudSucursal;
